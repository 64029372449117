import videoImage from '../../../../../../static/images/homePage/resources/Videos.png'
import userManual from '../../../../../../static/images/homePage/resources/UserManual.png'
import resourceImage from '../../../../../../static/images/homePage/resources/Resources.png'

export default [
  {
    title: "Visit our Video Gallery",
    description: "Our video gallery features short step-by-step tutorials to help you learn more about our products",
    buttonText: "View Videos",
    image: videoImage,
    link: "/training/videos",
    appSpecific: false,
  },
  {
    title: "Check out our User Manual",
    description: "Easily navigate through the different parts of our application to find what you are looking for",
    buttonText: "View Manual",
    image: userManual,
    link: "training/manual/landingPage",
    appSpecific: false,
  },
  {
    title: "Take a look at our Resources",
    description: "Our resources library stores documents to download such as the Data Preparation",
    buttonText: "View Resources",
    image: resourceImage,
    link: "/training/resources",
    appSpecific: false,
  }
]