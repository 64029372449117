import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Logs extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Well Logs
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>Logs</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: For information regarding which logs are required, recommended, and optional, as well as how to export logs from Petrel, please refer to the document “Geological Data Preparation (Updated Oct2019).docx.”<br/>
          &ensp;• Utility: Well logs are used in many steps of the evaluation including (but necessarily limited to) Engineering Analytics, Data Processing, Generate Net Logs & Calculate Zone Averages, Drainage Analysis, and Collect Workover Opportunities & Rank. Some of the most important uses of logs are to find remaining pay behind pipe and evaluate the drainage of existing production. Therefore, missing well logs will lead to uncertainty in the opportunity inventory. For example, if an existing well does not have well logs but has production, then the tool will not be able to properly evaluate drainage from this well, nor will this be used in certain forecast methods that use thickness (e.g., net pay thickness) normalization.<br/>
          &ensp;• File Format: LAS files<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Logs)
