import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Icon from '@material-ui/core/Icon'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

const CommentSearch = (props) => {
  const {
    searchText,
    submitSearch,
    handleChangeSearch,
  } = props

  return (
    <form onSubmit={submitSearch}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputBase
            placeholder="Search Message or Name..."
            name="search"
            value={searchText}
            onChange={handleChangeSearch}
            fullWidth
            startAdornment={(
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default CommentSearch
