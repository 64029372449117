import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom' 
import Modal from 'react-responsive-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import API from '../../../lib/api-store'
import defaultMap from '../../../../static/images/default-map.png'
import { withStyles } from '@material-ui/core/styles'

import ContinueModal from '../../common/ContinueModal/ContinueModal'
import ProjectCreator from './ProjectCreator/ProjectCreator'
import ProjectEditor from './ProjectEditor/ProjectEditor'
import ProjectCollaborators from './ProjectCollaborators/ProjectCollaborators'
import Badge from '@material-ui/core/Badge'
import ReactTooltip from 'react-tooltip'

import './ProjectSelection.scss'

const modalClass = 'qri-modal'
const modalCloseIconClass = 'qri-modal__close-button'

const Collaborators = withStyles((theme) => ({
  badge: {
    top: '10px',
    right: '10px',
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    width: '25px',
    height: '25px',
    borderRadius: '13px',
    fontSize: '0.8em',
    fontWeight: 'bold',
    backgroundColor: '#CCE4FF',
    color: '#0077FF',
  }
}))(Badge)

@autobind class ProjectSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: [],
      sharedProjects: [],
      supportProjects: [],
      isModalOpen: false,
      modalType: '',
      selectedProject: null,
      readOnly: false,
      licenseOptions: [],
      err: null,
      disabledTooltip: '.',
    }
  }


  componentDidMount() {
    this.fetchAllProjects()
    this.fetchLicenses()
  }

  fetchAllProjects() {
    const { userId } = this.props

    API.loadProjects(userId)
      .then((projects) => {
        const { userProjects, sharedProjects, supportProjects } = projects

        console.log('PROJS', projects)
        this.setState({
          projects: userProjects,
          sharedProjects: sharedProjects,
          supportProjects
        })
      })
  }

  fetchLicenses() {
    const { match, userId } = this.props
    const { application } = match.params

    API.getLicenses()
      .then((licenses) => {   
        console.log('LICENSES', licenses)
        let licenseOptions = licenses.filter(i => i.applications.includes(application))
        let disabledTooltip = ''
        let noPriv = false

        console.log(licenseOptions)
        licenseOptions = licenseOptions.filter(i => (i.currentUsers.find(j => j.id == userId) && i.currentUsers.find(j => j.id == userId).projectPrivileges == true) || userId == i.accountAdminId || userId == i.licenseAdminId)
    
        if (licenseOptions.length == 0) {
          disabledTooltip= 'This account does not have workspace creation privileges on any licenses.'
          noPriv = true
        }

        licenseOptions = licenseOptions.filter(i => i.workspaceCount < i.workspaceLimit)
        if (!noPriv && licenseOptions.length == 0) {
          disabledTooltip = 'All licenses which you have been assigned workspace creation privilege have reached their workspace limit.  Contact us to increase tier limits.'
        }


        licenseOptions = licenseOptions.map(i => ({ 
          label: i.name, value: i._id 
        }))

        this.setState({
          licenseOptions,
          disabledTooltip
        })
      })
  }
  closeModal() {



    this.setState({
      isModalOpen: false,
      err: null,
    })
  }

  handleProjectNameInput(e) {
    const { value } = e.target
    this.setState({
      newProjectName: value
    })
  }

  handleBasinInput(e) {
    const { value } = e.target
    this.setState({
      newProjectBasin: value
    })
  }

  handleRegionInput(e) {
    const { value } = e.target
    this.setState({
      newProjectRegion: value
    })
  }

  handleCountryInput(e) {
    const { value } = e.target
    this.setState({
      newProjectCountry: value
    })
  }

  handleObjectiveInput(e) {
    const { value } = e.target
    this.setState({
      newProjectObjective: value
    })
  }

  handleNewProject(name, basin, region, country, objective, license) {
    let { projects } = this.state

    API.createNewProject(name, basin, region, country, objective, license)
      .then(r => {
        if (r.err) {
          this.setState({
            err: r.err
          })
        } else {
          const newProjects = [...projects]
          newProjects.push(r)
          this.setState({
            projects: newProjects,
          })
          this.closeModal()
        }
      })
  }

  handleDeleteProject(projectId) {
    let { projects } = this.state

    API.deleteProject(projectId)
      .then(r => {
        const newProjects = projects.filter(i => i.projectId !== projectId)

        this.setState({
          projects: newProjects,
        })
        this.closeModal()
      })
  }

  handleEditProject(projectId, name, basin, region, country, objective) {
    let { projects } = this.state

    API.editProject(projectId, name, basin, region, country, objective)
      .then(r => {
        const newProjects = [...projects]
        const index = newProjects.findIndex(i => i.projectId === projectId)
        newProjects[index].name = name
        newProjects[index].basin = basin
        newProjects[index].region = region
        newProjects[index].country = country
        newProjects[index].objective = objective

        this.setState({
          projects: newProjects,
        })
        this.closeModal()
      })
  }

  makeNewProjectButton(disabled, disabledTooltip) {
    console.log('this', disabledTooltip)
    if (disabled) {
      return (
        <React.Fragment>
          <div data-tip={disabledTooltip} className="project-container" style={{background: '#DADADB', cursor: 'default'}} > 
            <div className="button-outer" style={{cursor: 'default'}}>
                <FontAwesomeIcon icon="plus"  style={{color: '#DADADB', cursor: 'default'}} className="icon" />
            </div>
          </div>
        </React.Fragment>
      )      
    }


    return (
      <div className="project-container" onClick={() => this.setState({isModalOpen: true, modalType: 'new', selectedProject: null, readOnly: false})}> 
        <div className="button-outer">
            <FontAwesomeIcon icon="plus" className="icon" />
        </div>
      </div>
    )
  }

  handleProjectClick(projectId) {
    const { match } = this.props
    const { application } = match.params

    this.props.history.push(`/${application}/projects/${projectId}/cases`)
  }

  handleEditClick(e, projectId, isShared) {
    e.stopPropagation()

    this.setState({ 
      isModalOpen: true,
      modalType: 'edit',
      selectedProject: projectId,
      readOnly: isShared
    })
  }

  makeProjects(isShared, isSupport) {
    const { projects, sharedProjects, supportProjects, isModalOpen, deleteModalOpen } = this.state
    const { match } = this.props
    const { application } = match.params
    console.log('p', projects)
    console.log('sp', sharedProjects)
    console.log('asdf', supportProjects)
    console.log('isSupport', isSupport)
    const projectsToDisplay = isSupport ? supportProjects : isShared ? sharedProjects : projects

    console.log('isShared', isShared, 'isSupport', isSupport)
    return projectsToDisplay.map(project => {

      let url = defaultMap

      if (project.region && project.country) {

        let regionText = project.region.split(' ').join('+')
        let countryText = project.country.split(' ').join('+')


        url = `https://maps.googleapis.com/maps/api/staticmap?center=${regionText},${countryText}&zoom=13&size=376x266&maptype=terrain&key=AIzaSyAXp2aS3WYTaJcfXToRurAweDWr626UtiA`  
      }

      return (
        <div key={project.projectId} className="project-container"> 
          <div className="inner-container" onClick={() => this.handleProjectClick(project.projectId)}>
            <div className='inner-header'>
              <span className='name'>{project.name}</span>
              <Collaborators className='collaborators' badgeContent={project.collaboratorCount || "0"} data-tip="Collaborators on Workspace"/>
            </div>
            <div className='map'>
              <img className="map-image" src={url} />
            </div>
            <div className='bottom-container'>
              <NavLink className='view-button' to={`/${application}/projects/${project.projectId}/cases`}>  
                <FontAwesomeIcon icon="eye" className="button-icon" />
                <span className='text'>Open</span>
              </NavLink>
              <div className='settings' onClick={(e) => this.handleEditClick(e, project.projectId,  isShared)}>
                <FontAwesomeIcon icon="cog" className="settings-icon" />
              </div> 
            </div>
          </div>
          <ReactTooltip effect="solid" />
        </div>
      )
    })
  }

  setModalType(type) {
    this.setState({ 
      modalType: type
    })
  }

  toggleDeleteModal(){
    const { deleteModalOpen } = this.state
    this.setState({
      deleteModalOpen: !deleteModalOpen,
      isModalOpen: deleteModalOpen
    })
  }

  deleteModalContinue(){
    const { selectedProject } = this.state
    this.handleDeleteProject(selectedProject)
    this.toggleDeleteModal()
  }

  makeModal() {
    const { modalType, selectedProject, projects, sharedProjects, readOnly, licenseOptions, err } = this.state
    const projectsToDisplay = readOnly ? sharedProjects : projects

    const project = selectedProject ? projectsToDisplay.find(i => i.projectId === selectedProject) : null

    if (modalType === 'new') {
      return <ProjectCreator
        handleNewProject={this.handleNewProject}
        selectedProject={selectedProject}
        licenseOptions={licenseOptions}
        err={err}
      /> 
    } else if (modalType === 'edit') {
      return <ProjectEditor
        toggleDeleteModal={this.toggleDeleteModal}
        handleEditProject={this.handleEditProject}
        selectedProject={selectedProject}
        setModalType={this.setModalType}
        project={project}
        disabled={readOnly}
      />    
    }
    //colab
    return <ProjectCollaborators
      selectedProject={selectedProject}
      project={project}
      setModalType={this.setModalType}
      disabled={readOnly}
    />
  }

  render() {
    const { projects, isModalOpen, modalType, deleteModalOpen, licenseOptions, disabledTooltip } = this.state
    const { user } = this.props

    const isSupport = user.supportTeam

    let disabledCreation = (licenseOptions.length == 0)


    return (
      <div className='Project'>
        <div className='title-container'>
          <div className='title'>
            Workspaces
          </div>
        </div>
        <div className='header'>
          Your Workspaces
        </div>
        <div className='projects'>
          {this.makeNewProjectButton(disabledCreation, disabledTooltip)}
          {this.makeProjects(false)}
        </div>
        <div className='header'>
          Workspaces Shared With You
        </div>  
        <div className='projects'>
          {this.makeProjects(true)}
        </div>
        { isSupport && (<>
          <div className="header">
            Workspaces Shared With Support
          </div>
          <div className='projects'>
            {this.makeProjects(true, true)}
          </div>
        </>)}
        <Modal
          classNames={{ modal: modalClass, closeIcon: modalCloseIconClass }}
          open={isModalOpen}
          center
          onClose={this.closeModal}
        >
          { this.makeModal() }
        </Modal>
        <ContinueModal
          showContinueModal={deleteModalOpen}
          onModalCancel={this.toggleDeleteModal}
          onModalContinue={this.deleteModalContinue}
          header={'Delete Workspace'}
          children={'Are you sure you want to delete this workspace?  Your collaborators may have created cases or datasets inside and will lose access to those if you proceed.'}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
  user: state.get('user').toJS()
})

export default withRouter(connect(mapStateToProps, null)(ProjectSelection))
