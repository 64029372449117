import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Execution extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Sweet Spots Execution Steps
        </div>
        <div className="content-header"> 
          Step 1: Initialization
        </div>
        <div className="content-row"> 
          Same as for the Recompletions module.
        </div>
        <div className="content-header"> 
          Step 2: Engineering Analytics
        </div>
        <div className="content-row"> 
          Same as for the Recompletions module.
        </div>
        <div className="content-row"> 
          For further information, please refer to the pdf manual subsection <b>Engineering Analytics</b>.
        </div>
        <div className="content-header"> 
          Step 3: Log/Zone Analytics
        </div>
        <div className="content-row"> 
          Same as for the Recompletions module.
        </div>
        <div className="content-header"> 
          Step 4: Attribute Mapping
        </div>
        <div className="content-row"> 
          Same as for the Recompletions module.
        </div>
        <div className="content-header"> 
          Step 5: Drainage Analysis
        </div>
        <div className="content-row"> 
          Same as for the Recompletions module.
        </div>
        <div className="content-row"> 
          For further information, please refer to the pdf manual subsection <b>Drainage Analysis (Recompletion and Sweet Spots Modules)</b>.
        </div>
        <div className="content-header"> 
          Step 6: Map Generation
        </div>
        <div className="content-row"> 
          This step creates various grids that will be useful for the next step (RPOS modeling). This includes:
        </div>
        <div className="content-row"> 
          &ensp;• <b>Spacing grid</b>: Defines no-go cells (or areas where placing a well conflicts with other existing wells) based on the user-specified spacing settings.<br/>
          &ensp;• <b>Target Thickness Index grid</b>: Specifies areas within each zone that have a target thickness (gross thickness within the oil window or standoff above/below the oil-water contact/gas-oil contact) above a certain user-specified threshold. This target thickness is created by taking into account the structure maps for the top and base of the zones, the fluid contacts for each zone, and any additional contact standoff constraints specified by the user.<br/>
          &ensp;• <b>Distance-To-Fault Index grid</b>: Specifies areas within each zone that meet a certain distance threshold from existing fault traces in the same zone. The user can specify whether proximity to faults is advantageous or disadvantageous.
        </div>
        <div className="content-header"> 
          Step 7: Sweetspot Ranking
        </div>
        <div className="content-row"> 
          This step creates a sweet spot map, which SRO calls a relative probability of success (RPOS) map, that essentially ranks different areas according to various input attributes. It is important to understand that this is a relative probability used for ranking, not an absolute measure of success. This process starts by mapping RPOS for every zone independently using various user-specified input maps for the zone. For example, a RPOS map for a particular zone might be mapped using any number of zone mappable attributes such as: HCPT, permeability, recent water cut, drainage index maps, and proximity to faults, and so on. Next, a global RPOS map is created by averaging the individual zone RPOS maps.
        </div>
        <div className="content-row"> 
          In this step, you can also specify the target zones for the new sweet spot opportunities.
        </div>
        <div className="content-row"> 
          For further information regarding the forecast methods used to generate the IP, please refer to the pdf manual subsection <b>Sweet Spot Ranking (Sweet Spots Module)</b>.
        </div>
        <div className="content-header"> 
          Step 8: Target Search
        </div>
        <div className="content-row"> 
          In this step, the global RPOS map is used together with the spacing grid to identify locations that maximize RPOS while honoring the spacing grid constraints. This step also identifies the candidate target zones for every location and collects various types of geo-engineering attributes for every target zone.
        </div>
        <div className="content-row"> 
          This step also contains zone-level target filters where various zone level attributes will be filtered to constrain the final sweet spot targets.
        </div>
        <div className="content-header"> 
          Step 9: Production Forecast
        </div>
        <div className="content-row"> 
          As with the recompletions module, this step uses the selected forecast method to generate a prediction for each opportunity’s initial production (IP) rate. It also estimates an EUR using the predicted IP and the decline parameters aggregated from the neighborhood wells. As with every module, this forecast is zone-specific. Therefore, if a location has multiple target zones, each target zone will have a separate forecast generated for it.
        </div>
        <div className="content-row"> 
          For further information regarding the forecast methods used to generate the IP, please refer to the pdf manual subsection <b>Production Forecast</b>.
        </div>
        <div className="content-header"> 
          Step 10: Opportunity Filtering & Finalization
        </div>
        <div className="content-row"> 
          The logic is same as <b>Step 8: Opportunity Filtering</b> for the Recompletions module. However, for the sweet spots module, only predicted IP is used for the well-level filtering functionality. All other filters are applied at the zone-level in <b>Step 8: Target Search</b>.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Execution)
