import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const selectedTabClass = 'selected'

@autobind class CaseHeader extends Component {
  render() {
    const { tab, caseTabName,
      sharedTabName, favoritesTabName, handleClickTab, match } = this.props
    let { cases, sharedCases, favoriteCases } = this.props

    const { projectId } = match.params
    cases = cases.filter(i => !!i.projectId && i.projectId === projectId)
    sharedCases = sharedCases.filter(i => !!i.projectId && i.projectId === projectId)
    favoriteCases = favoriteCases.filter(i => !!i.projectId && i.projectId === projectId)
    return (
      <div className="case-header">
        <div
          className={`tab-option ${tab === caseTabName ? selectedTabClass : ''}`}
          onClick={() => handleClickTab(caseTabName)}
          onKeyPress={null}
          role="button"
          tabIndex="0"
        >
          <div className="tab-option-icon-background">
            <FontAwesomeIcon icon="user" className="tab-option-icon" />
          </div>
          <span className="tab-option-text">
            My Cases (
            { cases.length }
            )
          </span>
        </div>
        <div
          className={`tab-option ${tab === sharedTabName ? selectedTabClass : ''}`}
          onClick={() => handleClickTab(sharedTabName)}
          onKeyPress={null}
          role="button"
          tabIndex="0"
        >
          <div className="tab-option-icon-background">
            <FontAwesomeIcon icon="users" className="tab-option-icon" />
          </div>
          <span className="tab-option-text">
            Shared Cases (
            { sharedCases.length }
            )
          </span>
        </div>
        <div
          className={`tab-option ${tab === favoritesTabName ? selectedTabClass : ''}`}
          onClick={() => handleClickTab(favoritesTabName)}
          onKeyPress={null}
          role="button"
          tabIndex="0"
        >
          <div className="tab-option-icon-background">
            <FontAwesomeIcon icon="heart" className="tab-option-icon" />
          </div>
          <span className="tab-option-text">
            Favorites (
            { favoriteCases.length }
            )
          </span>
        </div>
      </div>
    )
  }
}


export default withRouter(CaseHeader)
