import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import { Numeric, Integer } from '../../../../lib/formatters'

@autobind class WellCatalogTable extends PureComponent {
  handleClick(e, name) {
    console.log(click)
  }

  render() {
    const { data } = this.props

    const plotData = Object.keys(data).map((key) => {
      const obj = data[key]

      return {
        name: obj.name,
        x: obj.x,
        y: obj.y,
        ip: obj.ip,
        eur: obj.eur,
        md: obj.md.length > 0 ? obj.md[0] : 0,
      }
    })

    return (
      <div className="table2">
        <ReactTable
          className="-striped -highlight"
          columns={
            [{
              Header: 'Well ID',
              accessor: 'name',
              minWidth: 80,
              filterable: true,
            }, {
              Header: 'x',
              accessor: 'x',
              minWidth: 80,
              Cell: Integer,
            }, {
              Header: 'y',
              accessor: 'y',
              minWidth: 80,
              Cell: Integer,
            }, {
              Header: 'ip',
              accessor: 'ip',
              minWidth: 80,
              Cell: Numeric,
            }, {
              Header: 'eur',
              accessor: 'eur',
              minWidth: 80,
              Cell: Numeric,
            }, {
              Header: 'md',
              accessor: 'md',
              minWidth: 80,
              Cell: Integer,
            }]
          }
          showPagination={false}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id]).indexOf(filter.value) > -1
              : true;
          }}
          data={plotData}
          pageSize={Object.keys(data).length}
        />
      </div>
    )
  }
}

export default WellCatalogTable
