import autobind from 'autobind-decorator'
import React, { Component } from 'react'
import _, { find } from 'underscore'
import Select from 'react-select'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatasetHeader from './DatasetHeader'
import DatasetPublishModal from './DatasetPublishModal'
import GroupSelector from '../../../common/GroupSelector/GroupSelector'
import GeologicalModels from '../../../common/GeologicalModels/GeologicalModels'
import FileUploader from '../../../common/FileUploader/FileUploader'
import ContinueModal from '../../../common/ContinueModal/ContinueModal'
import FileDescriptionDrawer from '../Components/DescriptionDrawer/FileDescriptionDrawer'

import { uploadFiles, startValidatingDataset, getFiles } from '../../../../services/fileService'

import { 
  getDataset,
  changeModuleForVersion,
  changeSelectedGeoModelForVersion,
  createNewVersion,
  generateDownloadVersionUrl,
} from '../../../../services/datasetService'

import {
  GroupConfiguration,
  StatusConfigurations,
} from '../../../../lib/datasetMaps/groupConfigurations'

import {
  MODULE_METHODS,
  MODULE_OPTIONS
} from '../../../../lib/datasetMaps/moduleRequirements'

import './DatasetFileManager.scss'
import DatasetValidationDrawer from '../DatasetValidation/DatasetValidationDrawer'
import FileInformationDrawer from '../../../common/FileInformationDrawer/FileInformationDrawer'
import AlertBar from '../../../common/AlertBar/AlertBar'
import API from '../../../../lib/api-store'

import { IconButton, Tooltip, Toolbar } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const updateGroupsFileRequirements = MODULE_METHODS.updateGroupsFileRequirements


const READ_ONLY_MESSAGE = '(READ ONLY) This version is no longer editable unless duplicated as a new dataset. You can view this dataset on the dataset dashboard.'
const LATEST_VERSION_MESSAGE = 'This is the latest version and it is valid, any changes will automatically create a new version'
const VALIDATION_RUNNING_MESSAGE = 'Validation is currently running on this dataset, changes are not allowed until it finishes'

const baseClass = 'dataset-file-manager'

const topBarClass = `${baseClass}__top-bar`
const topBarButtonsClass = `${topBarClass}__buttons`
const selectClass = `${topBarClass}__select`
const emptySpaceClass = `${topBarClass}__empty-space`

const fileContentClass = `${baseClass}__file-content`

const groupSelectorContainerClass = `${fileContentClass}__group-selector-container`

const drawerButtonDrawerClass = `${fileContentClass}__drawer`
const drawerButtonClass = `${fileContentClass}__drawer--button`

const fileUploaderContainerClass = `${fileContentClass}__uploader-container`
const learnMoreClass = `${fileUploaderContainerClass}__learn-more`
const requiredLegendClass = `${fileUploaderContainerClass}__required-legend`

const validationContainerClass = `${fileContentClass}__validation-container`
const validationOpenClass = `${validationContainerClass}--open`

const modalClass = 'qri-modal'
const modalCloseIconClass = 'qri-modal__close-button'

@autobind class DatasetFileManager extends Component {
  constructor(props) {
    super(props)
    this.GroupConfiguration = new GroupConfiguration()
    const selectedGroupId = this.GroupConfiguration.groupIds.WELL_INFO
    const selectedGroup = this.GroupConfiguration.configuration[selectedGroupId]

    const storedFilesByCategory = this.GroupConfiguration.categoryNames.reduce((acc, categoryName) => {
      acc[categoryName] = []
      return acc
    }, {})

    const uploadingFiles = this.GroupConfiguration.categoryNames.reduce((acc, categoryName) => {
      acc[categoryName] = []
      return acc
    }, {})

    const failedUploads = this.GroupConfiguration.categoryNames.reduce((acc, categoryName) => {
      acc[categoryName] = []
      return acc
    }, {})

    this.state = {
      dataset: null,
      groups: this.GroupConfiguration.configuration,
      selectedGroupId,
      selectedGroup,
      storedFilesByCategory,
      uploadingFiles,
      readOnly: false,
      datasetLoading: false,
      attemptingValidation: false,
      failedUploads,
      geoModelsOpen: false,
      showContinueModal: false,
      showGeoContinueModal: false,
      showErrorModal: false,
      errorCode: "This is an error header.",
      errorMessage: "This is an error message.",
      geoModelsOpen: false,
      license: null,
      project: null,
      validationDrawerType: null,
    }
  }

  componentDidMount() {
    this.loadDataset().then(() => {
      const valid = this.state.selectedVersion.valid
      if(!valid){
        this.createDatabasePinger()
      }
    })
    this.fetchLicense()
  }

  //TODO - project is being passed in as a prop, but for sake of speed im also loading it on mount
  fetchLicense() {
    const { match, project } = this.props
    const { projectId } = match.params

    API.getProject(projectId)
      .then(project => {
        API.getLicense(project.license)
          .then(license => {
            console.log('tsetestest', license)
            this.setState({
              license,
              project
            })
          })
      })
  }


  componentWillUnmount(){
    this.removeDatabasePinger()
  }

  createDatabasePinger() {
    this.databasePinger = setInterval(() => this.loadDataset(true), 30000)
  }

  removeDatabasePinger() {
    clearInterval(this.databasePinger)
  }

  onClickGroup(selectedGroupId, selectedGroup, selectedParentGroup) {
    this.setState({
      selectedGroupId,
      selectedGroup,
      selectedParentGroup,
    })
  }

  //Error Drawer Stuff

  closeFileInformationDrawer() {
    this.setState({ fileInformationDrawerOpen: false })
  }

  openFileInformationDrawer(e, errors, uploadParameters, name) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      fileInformationDrawerErrors: errors,
      fileInformationDrawerUploadParameters: uploadParameters,
      fileInformationDrawerOpen: true,
      fileInformationDrawerFileName: name,
    })
  }

  closeFileDescriptionDrawer() {
    this.setState({ fileDescriptionDrawerOpen: false })
  }

  openFileDescriptionDrawer(e, category) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      fileDescriptionDrawerOpen: true,
      fileDescriptionDrawerCategory: category,
    })
  }
  //

  

  toggleGeoModels(boolean){
    this.setState({ geoModelsOpen: boolean })
  }

  toggleSelectedGeoModel(geoModel, bypass){
    const { 
      groups,
      readOnly,
      dataset,
      selectedVersion,
      storedFilesByCategory,
      selectedModules
    } = this.state
    const datasetId  = dataset._id
    const { versionId, valid } = selectedVersion

    if(!geoModel || readOnly){
      return
    }

    if(valid && !bypass){
      this.renderGeoContinueModal()
      setStoredGeo(geoModel)
      return
    }

    if(geoModel === '3D') {
      this.onClickGroup('THREE_D_MODEL', groups.GEOLOGICAL_MODELS.subgroups.THREE_D_MODEL, groups.GEOLOGICAL_MODELS.category)
    } else if (geoModel === '2.5D') {
      this.onClickGroup('ISOCHORE_GRIDS', groups.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups.ISOCHORE_GRIDS, groups.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.category)
    }

    const base = new GroupConfiguration()
    base.addCustomGrids(selectedVersion.customGrids)
    const newGroups = updateGroupsFileRequirements(selectedModules, geoModel, base)
    const readyToValidate = this.updateAllGroupStates(newGroups, storedFilesByCategory, selectedModules)
    this.GroupConfiguration.updateWithRequirements(newGroups)
    
    const selectedGeoModelComplete = this.isSelectedGeoModelComplete(newGroups, geoModel)
    this.setState({ 
      groups: this.GroupConfiguration.configuration,
      readyToValidate,
      selectedGeoModel: geoModel,
      selectedVersion: {
        ...this.state.selectedVersion,
        selectedGeoModel: geoModel
      },
      selectedGeoModelComplete,
    })
    //Modifying selected geo version directly does the same thing as reloading the dataset after updating it in MongoDB
    //This is faster and prevents a whitescreen bug if the user clicks on a different group before the dataset reloads.

    changeSelectedGeoModelForVersion(datasetId, versionId, geoModel)
  }

  isSelectedGeoModelComplete(groups, selectedGeoModel) {
    if(selectedGeoModel === '3D'){
      const geoGroup = groups.GEOLOGICAL_MODELS.subgroups.THREE_D_MODEL
      const hasModelFile = geoGroup.hasFiles
      return hasModelFile
    }else if(selectedGeoModel === '2.5D'){
      const geoGroup = groups.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL
      const requiredSurfacesHaveFiles = Object.values(geoGroup.subgroups).reduce((hasRequired, surface) => {
        const required = !!surface.categories[0].alternatives || surface.categories[0].required
        surface.categories[0].required = required
        if(!required){
          return hasRequired
        } else {
          hasRequired = surface.hasFiles ? hasRequired : false
          surface.state = (surface.hasFiles && surface.allFilesValid)? StatusConfigurations.COMPLETE : StatusConfigurations.INCOMPLETE
          return hasRequired
        }

      }, true)

      return requiredSurfacesHaveFiles

    }
  }

  renderGeoContinueModal() {

    const header = "Alert: New Version"
    const children = (<>
      <span>This version is valid and therefore locked.</span>
      <br />
      <span>Changing the selected geological model will create a new version.</span>
    </>)
    const onContinue = this.onGeoModalContinue

    this.renderCustomModal(header, children, onContinue)

  }

  onGeoModalContinue() {
    const datasetId = this.state.dataset._id
    createNewVersion(datasetId).then(res => {
      return this.loadDataset()
    }).then(() => {
      this.toggleSelectedGeoModel(storedGeo, true)
      setStoredGeo(null)
    })

  }

  onGeoModalCancel() {
    this.setState({ showGeoContinueModal: false })
  }

  handleGeologicalModelsGroupClick(){

    const selectedGeoModel = this.state.selectedVersion.selectedGeoModel || '3D'
    const groups = this.state.groups

    this.toggleGeoModels(true)

    if(selectedGeoModel === '3D') {
      this.onClickGroup('THREE_D_MODEL', groups.GEOLOGICAL_MODELS.subgroups.THREE_D_MODEL, groups.GEOLOGICAL_MODELS.category)
    } else if (selectedGeoModel === '2.5D'){
      this.onClickGroup('ISOCHORE_GRIDS', groups.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups.ISOCHORE_GRIDS, groups.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.category)
    }

  }

  determineSelectedGeoModelForValidVersion(dataset, selectedVersion) {
    //These are passed in vs accessed from state because
    //On initial render, they are not in state yet.

    const { versionId, hasCategories, valid } = selectedVersion
    const datasetId  = dataset._id

    //This function is for:
    //If an old version was validated before geological models were implemented,
    //Determine which should be displayed.
    if(!valid) {
      return null
    }
    const selectedGeoModel = hasCategories.Model_Data ? '3D' : '2.5D'

    //Toggle selectedVersion.selected geo model.
    //Send it to the database
    //Return the selectedGeoModel to be added to component state.  (in loadDataset method.)

    selectedVersion.selectedGeoModel = selectedGeoModel
    changeSelectedGeoModelForVersion(datasetId, versionId, selectedGeoModel)
    return selectedGeoModel
  }

  onClickVersionView(datasetId, versionId) {
    const { history, match } = this.props
    const { projectId, application } = match.params

    if (!versionId) {
      history.push(`/${application}/projects/${projectId}/dataset/${datasetId}`)
    } else {
      history.push(`/${application}/projects/${projectId}/dataset/${datasetId}/version/${versionId}`)
    }

    setTimeout(this.loadDataset, 50)
  }

  async onValidationButtonClick() {
    const { dataset, selectedVersion, readOnly } = this.state
    if (readOnly) {
      return;
    }

    if (!selectedVersion.validationRunning) {
      await this.setState({ datasetLoading: true, attemptingValidation: true})
      const response = await startValidatingDataset(dataset._id)
      if(!response.ok){
        const header = `${response.status} - ${response.statusText}`
        const children = `Failed to start validation`
        const onContinue = () => 1
        return this.setState({
          datasetLoading: false,
          attemptingValidation: false,
        }, () => this.renderCustomModal(header, children, onContinue))
      } else {
        await this.setState({ datasetLoading: false, attemptingValidation: false})
        return this.loadDataset()
      }
    }

  }

  onValidationDrawerButtonClick() {
    let { validationIsOpen } = this.state
    validationIsOpen = !validationIsOpen
    this.setState({ validationIsOpen, validationDrawerType: null })
  }

  onWarningButtonClick() {
    this.setState({ validationIsOpen: true, validationDrawerType: 'warnings' })
  }

  onFileModalContinue() {
    this.onFiles(storedFilesForUpload, storedFileCategory, storedUploadParameters, true)
    eraseStoredFiles()
    this.setState({
      showContinueModal: false,
    })
  }

  onFileModalCancel() {
    eraseStoredFiles()
    this.setState({
      showContinueModal: false,
    })
  }

  toggleErrorModal() {
    this.setState({ showErrorModal: !this.state.showErrorModal})
  }

  onFiles(files, fileCategory, uploadParameters, bypass, uniqueParams) {
    const { selectedParentGroup, uploadingFiles, readOnly, selectedVersion, failedUploads } = this.state
    const { match } = this.props
    const { datasetId } = match.params

    let relativeFileCategory = fileCategory
    let subcategory = null

    if (selectedVersion.valid && !bypass) {
      storeFiles(files, fileCategory, uploadParameters)
      this.setState({
        showContinueModal: true,
      })
      return;
    }

    if (readOnly) {
      return;
    }

    if (selectedParentGroup) {
      relativeFileCategory = selectedParentGroup
      subcategory = fileCategory
    }

    const removableUploads = []
    const uploads = uploadFiles(datasetId, files, relativeFileCategory, subcategory, uploadParameters, uniqueParams)
    const allUploadPromises = uploads.map((upload) => {
      console.log('upload', upload)
      uploadingFiles[upload.file.fileCategory].push(upload);
      
      upload.promise.then(() => {
        removableUploads.push(upload)
      }).catch((x) => {
        removableUploads.push(upload)
        failedUploads[upload.file.fileCategory].push(upload)
        //this will allow for continued execution of the all promise
        return true
      })

      return upload.promise
    })
    this.setState({ uploadingFiles, failedUploads })
    Promise.all(allUploadPromises).finally(() => {
      // console.log('removed uploads')
      // removableUploads.forEach((upload) => {
      //   const indexOFUpload = uploadingFiles[upload.file.fileCategory].indexOf(upload)
      //   uploadingFiles[upload.file.fileCategory].splice(indexOFUpload, 1)
      // })
      
      this.loadDataset().then(() => {
        removableUploads.forEach((upload) => {
          const indexOFUpload = uploadingFiles[upload.file.fileCategory].indexOf(upload)
          uploadingFiles[upload.file.fileCategory].splice(indexOFUpload, 1)
        })
        this.setState({ uploadingFiles })
      })
    })
  }

  onModuleFilterChange(e) {
    const { 
      dataset,
      groups,
      selectedGroupId,
      selectedParentGroup,
      storedFilesByCategory,
      versionId,
      readOnly,
      isLatestVersion,
      selectedVersion
    } = this.state
    const { datasetId } = this.props.match.params
    const moduleArray = !!e ? createModuleArray(e) : []

    let base = new GroupConfiguration()
    base.addCustomGrids(selectedVersion.customGrids)
    const newGroups = updateGroupsFileRequirements(moduleArray, selectedVersion.selectedGeoModel, base)
    let readyToValidate = this.updateAllGroupStates(newGroups, storedFilesByCategory, moduleArray)
    this.GroupConfiguration.updateWithRequirements(newGroups)

    this.setState({
      selectedModules: moduleArray,
      groups: this.GroupConfiguration.configuration,
      selectedGroup: findGroup(newGroups, selectedGroupId),
      storedFilesByCategory,
      readyToValidate,
    })

    const latestAndValid = isLatestVersion && selectedVersion.valid
    if(!readOnly && !latestAndValid){
      changeModuleForVersion(datasetId, versionId, moduleArray)
    }
    console.log('MODULE CHANGE', this.GroupConfiguration.configuration)
  }


  updateAllGroupStates(groups, storedFilesByCategory, selectedModules) {
    
    let readyToPublish = true
    Object.keys(groups).forEach((groupKey) => {
      const group = groups[groupKey]
      if (group.categories) {
        group.state = getGroupState(group, storedFilesByCategory)
      } else if (group.subgroups) {
        group.state = this.updateAllGroupStates(group.subgroups, storedFilesByCategory, selectedModules) ? StatusConfigurations.COMPLETE : StatusConfigurations.INCOMPLETE
      }
  
      if (group.state !== StatusConfigurations.COMPLETE) {
        readyToPublish = false
      }
    })

    if(!selectedModules || selectedModules.length === 0){
      readyToPublish = false
    }


    return readyToPublish
  }

  renderUploadWarningModal(callback, argumentArray) {
    const header = "Warning"
    const children = "Changing the selected group may abort your current uploads.  To cancel click X. To proceed click continue."
    const onContinue = () => callback(...argumentArray)
    console.log('RENDER UPLOAD WARNING ARGUMENTS:', argumentArray)
    this.renderCustomModal(header, children, onContinue)
  }

  renderCustomModal(header, children, onContinue){

    const customModalCancel = () => {
      this.setState({ customModalOpen: false })
    }

    const customModalContinue = () => {
      onContinue()
      customModalCancel()
    }

    this.setState({
      customModalOpen: true,
      customModalHeader: header,
      customModalChildren: children,
      customModalContinue,
      customModalCancel,
      validationIsOpen: false,
    })
  }


  loadDataset(pinger) {
    const self = this
    const { match } = this.props
    let { versionId } = match.params
    const { datasetId } = match.params
    const {
      uploadingFiles,
      failedUploads,
    } = this.state
    let { selectedGroupId } = this.state
    let readOnly = false
    let selectedVersion
    let isLatestVersion = true

    this.setState({ datasetLoading: true })

    return getDataset(datasetId).then(async function sucessfulGetFile(dataset) {
      

      //if no version was provided, let's set it to latestVersion
      if (!versionId) {
        versionId = dataset.latestVersion.versionId
        selectedVersion = dataset.latestVersion
      } else {
        //if a versionId was provided in the url, it is read only
        isLatestVersion = false
        readOnly = true
        selectedVersion = dataset.versions.find((version) => {
          return version.versionId === versionId
        })
        selectedVersion.files = await getFiles(selectedVersion.files, datasetId, versionId)
      }

      if (selectedVersion.validationRunning) {
        readOnly = true
      }
      //Injecting custom groups into this.GroupConfiguration
      if(selectedVersion.customGrids && selectedVersion.customGrids.length > 0){
        self.GroupConfiguration.addCustomGrids(selectedVersion.customGrids)
      }

      //Below is converting old ".moduleType" to new ".moduleTypes" values
      const convertToCurrent = {
        recompletion: 'RECOMPLETIONS',
        new_drills: 'NEW_DRILLS',
        horizontal: 'HORIZONTALS'
      }

      const selectedModule = convertToCurrent[selectedVersion.moduleType] || 'RECOMPLETIONS'
      const selectedModules = selectedVersion.moduleTypes || [selectedModule]
      

      //Clone files and sort them into their categories.
      const fileList = _.clone(selectedVersion.files)

      //Create new category names
      const storedFilesByCategory = self.GroupConfiguration.categoryNames.reduce((acc, categoryName) => {
        let removable = []
        acc[categoryName] = fileList.reduce((fileAcc, file, index) => {
          if (file.fileCategory === categoryName || file.gridCategory === categoryName) {
            fileAcc.push(file)
            //removable.push(index)
          }
          return fileAcc
        }, [])

        return acc
      }, {})

      //These two are important if there are custom grids.  Only reason they're here.
      let baseUploadingFiles = self.GroupConfiguration.categoryNames.reduce((acc, categoryName) => {
        acc[categoryName] = []
        return acc
      }, {})
      const newUploadingFiles = {
        ...baseUploadingFiles,
        ...uploadingFiles,
      } 

      let baseFailedFiles = self.GroupConfiguration.categoryNames.reduce((acc, categoryName) => {
        acc[categoryName] = []
        return acc
      }, {})
      const newFailedFiles = {
        ...baseFailedFiles,
        ...newFailedFiles,
      }
      //This code was used to update old 'module type' to new 'module types' and can probably be deleted
      //WY 5/17/21
      if(!selectedVersion.moduleTypes && !!selectedVersion.moduleType){
        changeModuleForVersion(datasetId, versionId, selectedModules)
      }

      //Refactored the code so GroupConfiguration is now a class constructor
      //Man this code kinda sucks.  I'll try to walk you through it.

        //Make a new group config and add custom grids
      let base = new GroupConfiguration()
      base.addCustomGrids(selectedVersion.customGrids)
      //These functions check if categories are required then flag them approprietly.
      const newGroups = updateGroupsFileRequirements(selectedModules, selectedVersion.selectedGeoModel, base)
      let readyToValidate = self.updateAllGroupStates(newGroups, storedFilesByCategory, selectedModules)
      //Update the GC with new data.
      self.GroupConfiguration.updateWithRequirements(newGroups)


      // --- Checking for out of memory error --- //

      let outOfMemory = checkForOutOfMemory(selectedVersion)

      //Selected group should be the same if it's any file category besides geological models.
      //If geological models is selected, we need to change selectedGroup to what geoModel is selected.

      const selectedGeoModel = selectedVersion.selectedGeoModel || self.determineSelectedGeoModelForValidVersion(dataset, selectedVersion)
      let selectedGroup = findGroup(newGroups, selectedGroupId)
      if(selectedGroup === undefined) selectedGroup = findGroup(newGroups, 'ISOCHORE_GRIDS')
      const selectedGeoModelComplete = self.isSelectedGeoModelComplete(newGroups, selectedGeoModel) //this modifies newGroups.

      //Validation button should only show if selectedGeoModel is complete.
      //const selectedGeoModelComplete = self.isSelectedGeoModelComplete(newGroups, selectedGeoModel, storedFilesByCategory, false)
       
      let validationIsOpen = pinger ? false : undefined
      if(selectedVersion.validationAttempted && !selectedVersion.validationRunning && outOfMemory && !pinger){
        const header = 'OUT OF MEMORY'
        const children = 'The Matlab engine crashed performing the validation of this dataset.  Please wait and retry validation at a later time.  If this continues please contact support.'
        const onContinue = () => 1
        self.renderCustomModal(header, children, onContinue)
        validationIsOpen = false
      }
      // --- end --- //

      return self.setState({
        groups: self.GroupConfiguration.configuration,
        selectedGroup,
        dataset,
        versionId,
        selectedVersion,
        readOnly,
        selectedModules,
        isLatestVersion,
        storedFilesByCategory,
        readyToValidate,
        datasetLoading: false,
        selectedGeoModel,
        selectedGeoModelComplete,
        uploadingFiles: newUploadingFiles,
        failedUploads: newFailedFiles,
        validationIsOpen: selectedVersion.validationAttempted || selectedVersion.validationRunning        
      }, () => {
        return Promise.resolve()
      })
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
  }

  render() {
    const {
      dataset,
      groups,
      selectedGroupId,
      selectedGroup,
      validationIsOpen,
      validationDrawerType,
      storedFilesByCategory,
      failedUploads,
      uploadingFiles,
      selectedModules,
      isLatestVersion,
      readyToValidate,
      readOnly,
      selectedVersion,
      versionId,
      showContinueModal,
      showGeoContinueModal,
      showErrorModal,
      errorCode,
      errorMessage,
      datasetLoading,
      attemptingValidation,
      selectedParentGroup,
      geoModelsOpen,
      selectedGeoModel,
      selectedGeoModelComplete,
      fileInformationDrawerOpen,
      fileInformationDrawerErrors,
      fileInformationDrawerUploadParameters,
      fileInformationDrawerFileName,
      fileDescriptionDrawerOpen,
      fileDescriptionDrawerCategory,
      license
    } = this.state
    const stateProject = this.state.project
    const { project, history } = this.props
    const showDataset = !!dataset

    //Calculating height of container div, taking into account 40px change when Alert Bar is visible.
    //This fixed a scrolling issue.
    let hasAlert = showDataset && (readOnly || selectedVersion.validationRunning || selectedVersion.valid)
    const heightCalc = hasAlert ? 'calc(100% - 150px)' : 'calc(100% - 110px)'

    //Stylng of React-Select component.
    const customStyles = {
      control: base => ({
        ...base,
        minWidth: '373px'
      }),
    }


    let unableToValidateDueToLicense = license?.jobHoursUsed >= license?.jobHourLimit || license?.jobsUsed >= license?.jobLimit || project?.jobHoursUsed >= project?.jobHourLimit || project?.jobsUsed >= project?.jobLimit
    if (license && project) {
      console.log(license.jobHoursUsed, license.jobHourLimit, license.jobsUsed, license.jobLimit)
      console.log(unableToValidateDueToLicense)  
    }
    
    console.log('this is the state', validationDrawerType)

    return (
      <>
        <DatasetHeader project={project}
          dataset={dataset} 
          selectedVersion={selectedVersion} 
          onClickView={this.onClickVersionView}
          datasetLoading={datasetLoading}
        />
        {readOnly && selectedVersion.validationRunning && (
          <AlertBar>
            <span>{VALIDATION_RUNNING_MESSAGE}</span>
          </AlertBar>
        )}
        {readOnly && !selectedVersion.validationRunning && (
          <AlertBar>
            <span>{READ_ONLY_MESSAGE}</span>
          </AlertBar>
        )}
        {isLatestVersion && selectedVersion.valid && (
          <AlertBar>
            <span>{LATEST_VERSION_MESSAGE}</span>
          </AlertBar>
        )}
        <div className={`${baseClass} product-space__content--wrapper`} style={{height: heightCalc}}>
          <div className={topBarClass}>
            <div className={selectClass}>
              <div>Select Module(s) to show required categories</div>
              <Select
                options={MODULE_OPTIONS}
                onChange={this.onModuleFilterChange}
                //onInputChange={console.log}
                isMulti={true}
                value={selectedModules ? selectedModules.map(mod => MODULE_OPTIONS.find(i => i.value === mod)) : [] }
                isDisabled={selectedVersion && (selectedVersion.validationRunning ||  selectedVersion.valid)}
                styles={customStyles}
              />
            </div>
            {unableToValidateDueToLicense && (
              <div>
                <span style={{color: 'orange', fontSize: '15px', marginRight: '7px'}}><FontAwesomeIcon icon="exclamation-triangle" /></span>
                License limits are preventing you from starting validation 
                <span style={{textDecoration: 'underline', cursor: 'pointer', color: '#056BF6'}} onClick={this.onWarningButtonClick}>View Warnings</span>
              </div>
              )}
            <div className={topBarButtonsClass}>
              {!readOnly && readyToValidate && selectedGeoModelComplete && dataset && !selectedVersion.validationRunning && !selectedVersion.valid && !unableToValidateDueToLicense && (
                <Button
                  onClick={this.onValidationButtonClick}
                  className={attemptingValidation ? "qri-button primary attempting-validation" : "qri-button primary"}
                  disabled={attemptingValidation}
                >
                  {attemptingValidation ? <CircularProgress style={{ width: '30px', height: '100%'}}/> : 'Start Validation'}
              </Button>
              )}
              {/* <Button
                className="qri-button primary"
                onClick={this.onClickDownloadVersion}>
                Test Button
              </Button> */}
              <Button
                className="qri-button primary"
                onClick={this.loadDataset}>
                Refresh
              </Button>
            </div>
          </div>


          {showDataset && (
            <div className={fileContentClass}>
              <div className={groupSelectorContainerClass}>
                <GroupSelector
                  groups={groups}
                  selectedGroupId={selectedGroupId}
                  onClickGroup={this.onClickGroup}
                  storedFilesByCategory={storedFilesByCategory}
                  toggleGeoModels={this.toggleGeoModels}
                  geoOpen={geoModelsOpen}
                  handleGeologicalModelsGroupClick={this.handleGeologicalModelsGroupClick}
                  handleUploadWarning={this.renderUploadWarningModal}
                  filesUploading={Object.values(uploadingFiles).reduce((acc, array) => (array.length > 0 ? true : acc), false)}
                />
              </div>
              <div className={fileUploaderContainerClass}>
                <div className={requiredLegendClass}>
                  * REQUIRED
                </div>
                {selectedGroup.unitConversion && (
                  <div className={learnMoreClass}>
                    <Alert severity="info">
                      Specify feet or meters if applicable <a onClick={e => this.openFileDescriptionDrawer(e, { category: 'Units' })}>Learn more</a>
                    </Alert>
                  </div>
                )}
                
                {selectedGroup && !geoModelsOpen && selectedGroup.categories.map((category, index) => {
                  return (
                    <div key={category.category}>
                      <FileUploader
                        datasetId={dataset._id}
                        versionId={versionId}
                        index={index + 1}
                        key={category.category}
                        category={category}
                        onFiles={this.onFiles}
                        files={storedFilesByCategory[category.category]}
                        uploadingFiles={uploadingFiles[category.category]}
                        failedUploads={failedUploads[category.category]}
                        refreshData={this.loadDataset}
                        readOnly={readOnly}
                        isDatasetValid={selectedVersion.valid}
                        openFileInformationDrawer={this.openFileInformationDrawer}
                        openFileDescriptionDrawer={this.openFileDescriptionDrawer}
                        renderCustomModal={this.renderCustomModal}
                      />
                    </div>
                  )
                })}

                {selectedGroup && geoModelsOpen &&
                  selectedGroup.categories.map((category, index) => {
                    return (
                      <div>
                        <GeologicalModels
                          datasetId={dataset._id}
                          versionId={versionId}
                          index={index + 1}
                          key={category.category}
                          category={category}
                          onFiles={this.onFiles}
                          files={storedFilesByCategory[category.category]}
                          uploadingFiles={uploadingFiles[category.category]}
                          failedUploads={failedUploads[category.category]}
                          allStoredFiles={storedFilesByCategory}
                          allUploadingFiles={uploadingFiles}
                          allFailedUploads={failedUploads}
                          refreshData={this.loadDataset}
                          readOnly={readOnly}
                          valid={selectedVersion.valid}
                          isDatasetValid={selectedVersion.valid}
                          onClickGroup={this.onClickGroup}
                          groups={groups}
                          selectedGroupId={selectedGroupId}
                          toggleSelectedGeoModel={this.toggleSelectedGeoModel}
                          selectedGeoModel={selectedGeoModel}
                          storedFileCategory={storedFilesByCategory}
                          selectedModules={selectedModules}
                          renderCustomModal={this.renderCustomModal}
                          openFileInformationDrawer={this.openFileInformationDrawer}
                          openFileDescriptionDrawer={this.openFileDescriptionDrawer}
                          threeDRequired={groups.GEOLOGICAL_MODELS.subgroups.THREE_D_MODEL.categories[0].required && !groups.GEOLOGICAL_MODELS.subgroups.THREE_D_MODEL.categories[0].alternatives}
                          selectedVersion={selectedVersion}
                        />
                      </div>
                    )
                })}

                <DatasetValidationDrawer
                  dataset={dataset}
                  selectedVersion={selectedVersion}
                  validationIsOpen={validationIsOpen}
                  type={validationDrawerType}
                  onValidationButtonClick={this.onValidationDrawerButtonClick}
                  license={license}
                  project={project}
                />
                <FileInformationDrawer
                  fileInformationDrawerOpen={fileInformationDrawerOpen}
                  uploadParameters={fileInformationDrawerUploadParameters}
                  errors={fileInformationDrawerErrors}
                  closeFileInformationDrawer={this.closeFileInformationDrawer}
                  name={fileInformationDrawerFileName}
                />
                <FileDescriptionDrawer
                  isOpen={fileDescriptionDrawerOpen}
                  category={fileDescriptionDrawerCategory}
                  close={this.closeFileDescriptionDrawer}
                />
              </div>
              {dataset && selectedVersion.validationAttempted && (
                <div className={drawerButtonDrawerClass}>
                  <Toolbar style={{ justifyContent: 'center', height: 64 }}>
                    <Tooltip autofocus="true" title="View validation status">
                      <IconButton
                        className={drawerButtonClass}
                        onClick={this.onValidationDrawerButtonClick}>
                        <ChevronLeftIcon />
                      </IconButton>
                    </Tooltip>
                  </Toolbar>
                </div>
              )}
            </div>
          )}
          <ContinueModal
            showContinueModal={showContinueModal}
            onModalCancel={this.onFileModalCancel}
            onModalContinue={this.onFileModalContinue}
            header="Alert: New Version"
          >
            <span>
              This version is valid and therefore locked.
              </span>
            <br />
            <span>
              Continuing with this action will create a new version.
              </span>
          </ContinueModal>
          <ContinueModal
            showContinueModal={this.state.customModalOpen}
            onModalCancel={this.state.customModalCancel}
            onModalContinue={this.state.customModalContinue}
            header={this.state.customModalHeader}
            children={this.state.customModalChildren}
          />
        </div>
      </>
    )
  }
}

let storedGeo = null
function setStoredGeo(input) {
  storedGeo = input
}


let storedFilesForUpload = null
let storedFileCategory = null
let storedUploadParameters = null
function storeFiles(files, fileCategory, uploadParameters) {
  storedFilesForUpload = files
  storedFileCategory = fileCategory
  storedUploadParameters = uploadParameters
}

function eraseStoredFiles() {
  storedFilesForUpload = null
  storedFileCategory = null
  storedUploadParameters = null
}

function getGroupState(group, storedFilesByCategory) {

  return group.categories.reduce((state, category) => {
    if (state !== StatusConfigurations.COMPLETE) {
      return state
    }
    const filesFromCategory = storedFilesByCategory[category.category]


    if(filesFromCategory.length > 0){
      group.hasFiles = true
      group.allFilesValid = true
      filesFromCategory.forEach(file => {
        group.allFilesValid = file.validationPassed ? group.allFilesValid : false
      })
    } else {
      group.hasFiles = false
    }

    

    if (filesFromCategory.length === 0 && category.required) {
      if(!category.alternatives){
        return StatusConfigurations.INCOMPLETE
      }
      else if(category.alternatives){
        let hasAllAlternativeFiles = true
        category.alternatives.forEach(file => hasAllAlternativeFiles = storedFilesByCategory[file].length === 0 ? false : hasAllAlternativeFiles)
        if(hasAllAlternativeFiles){
          category.required = false
          return StatusConfigurations.COMPLETE
        } else {
          return StatusConfigurations.INCOMPLETE
        }
      }
    }

    return filesFromCategory.reduce((allFilesValid, file) => {

      if (allFilesValid !== StatusConfigurations.COMPLETE) {
        return allFilesValid
      }

      if (file.validationComplete && !file.validationPassed) {
        return StatusConfigurations.ERROR
      }

      if (!file.validationPassed) {
        return StatusConfigurations.INCOMPLETE
      }

      return allFilesValid
    }, StatusConfigurations.COMPLETE)

  }, StatusConfigurations.COMPLETE)
}

function createModuleArray(selectObj){
  return selectObj.map(mod => mod.value)
}

function checkForOutOfMemory(version) {
  const { integration, combination, moduleIntegration } = version
  const types = [ integration, combination, moduleIntegration ]
  let outOfMemory = false

  types.forEach(type => {
    Object.keys(type).forEach(key => {
      if(type[key].error === 'Out of memory. Type "help memory" for your options.' || type[key].error === 'Out of memory.  Type "help memory" for your options.'){
        outOfMemory = true
      }
    })
  })
  return outOfMemory
}

//Recursive function for finding group object with groupId key.
//Implemented with 2.5d grids double nested subgroups.  -WY 5/12
function findGroup(groups, groupId) {
  console.log('finding group for:', groupId)
  const ids = Object.keys(groups)

  for (let id of ids) {
    if(id === groupId){
      return groups[id]
    } else if(groups[id].subgroups){
      let group = findGroup(groups[id].subgroups, groupId)
      if(group){
        return group
      }
    }
  }
  return undefined
}

export default DatasetFileManager
