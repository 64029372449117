import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
  card: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    //minHeight: 400,
    width: '100%',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.04)',
    border: '1px solid #eaf0f4',
    backgroundColor: '#ffffff',
  },
  header: {
    flex: ' 0 1 auto',
  },
  content: {
    paddingTop: 0,
    paddingBottom: 16,
    flex: '0 0 auto',
  },
  loader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background:' rgba(0,0,0, .2)',
    zIndex: '99999 !important',
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: '-25px',
    marginTop: '-25px',
  }
})

const ExtendedCard = (props) => {
  const { title, subtitle, children, action, busy } = props
  const classes = useStyles()

  return (
    <Card className={classes.card} square>
      { title || subtitle || action ? (
        <CardHeader
          className={classes.header}
          title={title}
          subheader={subtitle}
          action={action}
        />
      ) : null }    
      <CardContent className={classes.content}>
        { children }
      </CardContent>
      { busy ? (
        <div className={classes.loader}>
          <CircularProgress className={classes.progress}/>
        </div>
      ) : null}
    </Card>
  )
}

export default ExtendedCard
