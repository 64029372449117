import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import RestoreIcon from '@material-ui/icons/Restore'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import API from '../../../lib/api-store'
import { formatSeconds } from '../../../lib/formatters'
import { numberFormatDate } from '../../../services/dateFormatterService'

const styles = theme => ({
  root: {
    padding: '0 20px',
  },
  meta: {
    maxWidth: 300,
    marginLeft: -16,
  },
  metaIcon: {
    marginRight: 5,
    color: '#CCC',
  },
  metaDetail: {
    display: 'flex',
    color: '#ea5a00',
  },
  description: {
    marginTop: 8,
    marginBottom: 20,
  },
  rightContent: {
    [theme.breakpoints.up('md')]: {
      float: 'right',
    }
  },
  video: {
    margin: 'auto',
    width: '100%',
    maxWidth: 1400,
  },
})

const backButtonUrl = '/training/videos'

const baseClass = 'video'
const contentClass = `${baseClass}__content`
const leftContentClass = `${contentClass}--left`
const rightContentClass = `${contentClass}--right`

const backButtonClass = `back-btn`
const backButtonIconClass = `${backButtonClass}__icon`

@autobind class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loaded: false,
      error: false,
    }
  }

  componentDidMount() {
    const { match } = this.props
    const key = match.params[0]

    if (key) {
      API.getTutorialVideoDetails(key)
        .then((result) => {
          this.setState({
            data: result,
            loaded: true,
            Key: key,
          })

          return API.getTutorialVideoThumbnail(result.Metadata.title)
        })
        .then(url => this.setState({thumbnail: url}))
    }
  }

  render() {
    const { classes } = this.props
    const { data, Key, loaded, thumbnail } = this.state

    return (
      <div className={`${classes.root} content-container`}>
        <div className={classes.rightContent}>
          <NavLink className={backButtonClass} to={backButtonUrl}>
            <ChevronLeftIcon className={backButtonIconClass} />
            Back to Videos
          </NavLink>
        </div>
        {data && data.Metadata ? (
          <Typography variant="h1" gutterBottom>{data.Metadata.title}</Typography>
        ) : <Skeleton variant="text" />}

        <Grid className={classes.meta} container spacing={4}>
          <Grid item xs={6}>
            {data ? (
              <div className={classes.metaDetail}><RestoreIcon className={classes.metaIcon} /> {numberFormatDate(data.LastModified)}</div>
            ) : <Skeleton variant="text" />}
          </Grid>
          <Grid item xs={6}>
            {data && data.Metadata ? (
              <div className={classes.metaDetail}><CalendarTodayIcon className={classes.metaIcon} /> {formatSeconds(data.Metadata.length)}</div>
            ) : <Skeleton variant="text" /> }
          </Grid>
        </Grid>

        {data && data.Metadata ? (
          <Typography className={classes.description} variant="body1" gutterBottom>{data.Metadata.description}</Typography>
        ) : <Skeleton variant="text" />}

        {data && Key ? (
          <video className={classes.video}
            controls
            autoplay
            src={`${process.env.API_ADDRESS}/tutorials/video/?key=${Key}`}
            poster={thumbnail}
          />
        ) : <Skeleton variant="rect" />}   
      </div>
    )
  }
}

export default withStyles(styles)(Video)
