import React from 'react'

import img1 from './static/WellPerforations1.jpg'
import img2 from './static/WellPerforations2.png'

const WellPerforations = (
  <div className={'description-container'}>
    <p><b>Content:</b> All perforation history information (e.g., depth, dates).</p>
    <p><b>Utility:</b> For completion contact analysis and flow unit allocation.</p>
    <p><b>DateOpen and DateClose:</b> Format must be mm/dd/yyyy</p>
    <p><b>DateClose:</b> If the perforation is still open, insert 0000-00-00. If the date is missing, leave it blank.</p>
    <p><b>File Format:</b> CSV template (downloadable) (example shown in Figure 6).</p>
    <p><b>Units:</b> Currently all units must be in meters. We understand that many users are used to working in feet, and we are working on an update that will allow inputs to be in feet.</p>
    <p><b>Other Note:</b> Not all columns are required. See Table 6 for specifics.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 6: Example of the first few rows of a “Well_Perforations.csv.”</figcaption>
    </figure>
    <figure>
      <figcaption>Table 6. Details for the “Well_ Perforations.csv.”</figcaption>
      <img src={img2} />
    </figure>
  </div>
)

export default WellPerforations