import Drawer from '@material-ui/core/Drawer'
import React, { PureComponent, useState } from 'react'
import autobind from 'autobind-decorator'

import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import DescriptionIcon from '@material-ui/icons/Description';
import ListAltIcon from '@material-ui/icons/ListAlt';

import styled from '@material-ui/core/styles/styled'

import UploadParameters from '../FileUploader/uploadParameterConfigurations'
import Error from '../Error/Error'

import './FileInformationDrawer.scss'

const ParamsIcon = styled(ListAltIcon)({
  color: 'blue',
  position: 'relative',
  top: '3px',
  marginRight: '4px',
})

const Warning = styled(ReportProblemOutlinedIcon)({
  color: 'orange',
  position: 'relative',
  top: '3px',
  marginRight: '4px',
})

const ErrorIcon = styled(ReportProblemOutlinedIcon)({
  color: 'red',
  position: 'relative',
  top: '3px',
  marginRight: '4px',
})

const File = styled(DescriptionIcon)({
  position: 'relative',
  top: '2.5px',
  marginRight: '4px',
  color: '#646464'
})

const Less = styled(ExpandLess)({
  paddingTop: '3px' 
})
const More = styled(ExpandMore)({
  paddingTop: '3px' 
})

@autobind class FileInformationDrawer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      warningLogOpen: true,
      criticalLogOpen: true,
      parameterLogOpen: true,
    }

    this.parameterLabels = Object.keys(UploadParameters).reduce((acc, funcKey) => {
      UploadParameters[funcKey]().forEach(param => {
        acc[param.name] = param.fileLabel
      })
      return acc
    }, {})
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.errors !== this.props.errors){
      this.setState({
        warningLogOpen: true,
        criticalLogOpen: true,
      })
    }
  }

  toggleWarningLog(){
    this.setState({ warningLogOpen: !this.state.warningLogOpen})
  }

  toggleCriticalLog(){
    this.setState({ criticalLogOpen: !this.state.criticalLogOpen })
  }

  toggleParameterLog(){
    this.setState({ parameterLogOpen: !this.state.parameterLogOpen })
  }

  createUploadParameterList(params){

    return Object.keys(params).map( key => {
      const label = this.parameterLabels[key]
      const param = params[key]
      let value
      let color

      //Changing what to display based on variable type
      if(typeof param === "boolean"){
        value = param ? "Yes" : "No"
        color = param ? "Green" : "Red"
      } else if(param === null || param === undefined){
        value = "None"
        color = "Blue"
      } else if(Array.isArray(param)){
        value = param.join(', ')
        color = "Green"
      } else if(typeof param === 'number'){
        value = param.toString()
        color = "Green"
      }
      
      return (
        <ListItem>
          <ListItemText>
            {label}: <b style={{'color': color}}>{value}</b>
          </ListItemText>
        </ListItem>
      )
    })
  }

  createErrorLogList(array){
    return array.map((errMsg, i) => (
      <ListItem>
        <ListItemText primary={`${i + 1}) ${errMsg}`} />
      </ListItem>
    ))
  }

  render() {

    const {
      fileInformationDrawerOpen,
      closeFileInformationDrawer,
      errors,
      uploadParameters,
      name
    } = this.props

    const {
      warningLogOpen,
      criticalLogOpen,
      parameterLogOpen
    } = this.state

    //parse errors
    let criticals = []
    let warnings = []
    let oldErrors

    if(errors){
      if(typeof errors === 'string') {
        //Old Errors
        errors.split('\n').forEach(error => {
          const [critical, message] = error.split('|')
          eval(critical) ? criticals.push(message) : warnings.push(message)
          //eval because 'critical' is 'true' or 'false' string
        })
        console.log('crits', criticals)
        console.log('warns', warnings)

        oldErrors = true
      } else {
        //New Errors
        const map = (e) => {
          const {
            isCritical,
            description,
            suggestion,
            impact
          } = e
          return { isCritical, description, suggestion, impact }
        }

        //Single errors don't come inside of an array because MatLab doesn't allow that
        //Check if it's an array, if not, put the single error inside of one
        let errs
        if(!Array.isArray(errors)) errs = [errors]
        else errs = errors

        criticals = errs.filter(e => e.isCritical).map(map)
        warnings = errs.filter(e => !e.isCritical).map(map)

        oldErrors = false
      }
    }

    return (
      <Drawer
        open={fileInformationDrawerOpen}
        variant="persistent"
        anchor="right"
        PaperProps={{ style: { position: 'absolute', width: 500 } }}
      >
        <Toolbar
          style={{ justifyContent: 'space-between', paddingLeft: 16, paddingRight: 16 }}
        >
          <Typography variant="h5">File Upload Information</Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="Close Validation Drawer"
            onClick={closeFileInformationDrawer}
          >
            <ChevronRightIcon/>
          </IconButton>
        </Toolbar>
        <Divider/>
        <Typography
          variant="h5"
          style={{textAlign: 'center', padding: '10px', backgroundColor: '#F7F9FE'}}
        >
          <File /> {name}
        </Typography>
        
        <Divider />
        { uploadParameters && Object.keys(uploadParameters).length > 0 && (
          <>
            <ListItem button onClick={this.toggleParameterLog}>
              <Typography variant="h6" >
                <ParamsIcon /> <b>View Upload Parameters</b>
                {parameterLogOpen ? ( <Less/>) : ( <More/>)}
              </Typography>
            </ListItem>
            <Collapse in={parameterLogOpen} timeout="auto" unmountOnExit>
              <List component="div">
                {this.createUploadParameterList(uploadParameters)}
              </List>
            </Collapse>
            <Divider />
          </>
        )}
        { criticals.length > 0 && (
          <>
            <ListItem button onClick={this.toggleCriticalLog}>
              <Typography variant="h6" >
                <ErrorIcon /> <b>View Errors</b>
                {criticalLogOpen ? ( <Less/>) : ( <More/>)}
              </Typography>
            </ListItem>
            <Collapse in={criticalLogOpen} timeout="auto" unmountOnExit>
              <List component="div">
                <div className="validation-error-container">
                  {oldErrors && (this.createErrorLogList(criticals))}
                  {!oldErrors && (criticals.map((x, i) => <Error {...x} i={i} />))}
                </div>
              </List>
            </Collapse>
            <Divider />
          </>
        )}
        { warnings.length > 0 && (
          <>
            <ListItem button onClick={this.toggleWarningLog}>
              <Typography variant="h6" >
                <Warning /> <b>View Warnings</b>
                {warningLogOpen ? ( <Less/>) : ( <More/>)}
              </Typography>
            </ListItem>
            <Collapse in={warningLogOpen} timeout="auto" unmountOnExit>
            <List component="div">
              <div className="validation-error-container">
                {oldErrors && (this.createErrorLogList(warnings))}
                {!oldErrors && (warnings.map((x, i) => <Error {...x} i={i} />))}
              </div>
            </List>
          </Collapse>
        </>
        )}
      </Drawer>
    )
  }
}

export default FileInformationDrawer