import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'

// TODO - make hover box not shared
@autobind class IPHistogram extends PureComponent {
  render() {
    const { data, groupBy, colorWheel } = this.props
    let plotData = []
    let avgVal = 0

    data.forEach((i) => {
      avgVal += i.predictedIp / data.length
    })

    if (!groupBy) {
      plotData = [{
        x: data.map(i => i.predictedIp),
        type: 'histogram',
        marker: {
          line: {
            color: "#000000",
            width: 0.4,
          }
        },
      }]
    } else {
      const groups = []

      data.forEach((i) => {
        if (!groups.includes(i[groupBy])) {
          groups.push(i[groupBy])
        }
      })


      groups.forEach((group) => {
        const groupData = data.filter(i => i[groupBy] === group)
        const obj = {
          x: groupData.map(i => i.predictedIp),
          name: group,
          type: 'histogram',
          hovertemplate: '<b>Count</b>: %{x}',
        }

        if (colorWheel) {
          obj.marker = {
            color: colorWheel[group],
          }
        }

        plotData.push(obj)
      })
    }

    const layout = {
      title: '',
      barmode: 'stack',
      autosize: true,
      xaxis: { title: 'IP (bbl/d)' },
      yaxis: { title: 'Opportunities' },
      margin: {
        l: 40,
        r: 30,
        b: 30,
        t: 10,
        pad: 0,
      },
      shapes: [{
        type: 'line',
        x0: avgVal,
        x1: avgVal,
        yref: 'paper',
        y0: 0,
        y1: 1,
        line: {
          color: 'rgb(0,0,0)',
          width: 3,
          dash: 'dash',
        },
      }],
    }

    return (
      <Plot
        className="plot"
        data={plotData}
        layout={layout}
        config={{displaylogo: false, showSendToCloud: true, displayModeBar: false}}
        useResizeHandler
      />
    )
  }
}

export default IPHistogram
