import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import InitialProductionRateForecastA from '../../../../../../../../../../static/images/userManual/settings/InitialProductionRateForecastA.png'
import InitialProductionRateForecastB from '../../../../../../../../../../static/images/userManual/settings/InitialProductionRateForecastB.png'

@autobind class InitialProductionRateForecast extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Initial Production Rate Forecast
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Should Use Type Curve (TC)<br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Comments:</span> Type curve (TC) creates a zone-specific type curve to predict initial production of new opportunities.<br/>
            <span className='blue'>Advantages:Captures</span> temporal trends. Can be normalized with static variables (kh).<br/>
            <span className='blue'>Disadvantages:</span> Spatially unaware. Can have a large range of uncertainty.<br/>
        </div>
        <div className="content-row"> 
          Should use Special Temporal Integration (STI)<br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Comments:</span> Spatial Temporal Interpolation (STI). Initial zone-allocated oil rates of an analog well neighborhood are spatially and temporally averaged for each opportunity.<br/>
            <span className='blue'>Advantages:</span> Captures spatial and temporal trends. Various weighting functions to choose from.<br/>
            <span className='blue'>Disadvantages:</span> Sensitive to user-provided weights.<br/>
            <span className='blue'>Summary:</span> An analog well set is defined for each opportunity. The analog well set is defined as the set of wells producing from the opportunity’s target zone, and located within the spatial neighborhood (i.e., within radius R or part of N closest points), and within the temporal neighborhood (i.e., started producing within the last M years). Note that the neighborhood parameters R, N, and M are user-defined. The analog well set can also be restricted to wells that are of the same type as the target opportunity (i.e., horizontal vs. vertical target). Each analog well is assigned a spatial and temporal weight that reflects spatial and temporal proximity respectively using various weighting functions such as sigmoidal or Gaussian functions. The forecast is calculated by interpolating the IPs/EURs of the analog wells using their assigned weights<br/>
        </div>
        <div className="content-row"> 
          Should use Neighborhood Fluid Rates (NFR)<br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Comments:</span> Neighborhood Fluid Rates (NFR). Current zone-allocated fluid rates in the neighborhood around the opportunity (neighborhood size is user-defined) are normalized by perforation length to create fluid rate indices (FRIs). Should use Neighborhood Fluid Rates (NFR)<br/>
            <span className='blue'>Advantages:</span> Neighborhood fluid rates.Uses zone-specific fluid rates.<br/>
            <span className='blue'>Disadvantages:</span> Will underestimate potential.Limited by availability of neighborhood data.<br/>
            <span className='blue'>Additional Comments:</span> Current zone-allocated fluid rates in the neighborhood around the opportunity (neighborhood size is user-defined) are normalized by perforation length to create fluid rate indices (FRIs). The neighborhood wells can also be restricted to only those that are of the same type as the target opportunity (i.e., horizontal vs. vertical wells). The FRIs are interpolated to opportunity location using inverse-distance weighting. The interpolated FRI is then used to calculate IP as follows:<br/>
        </div>
        <div className="content-row"> 
          𝐼𝑃=𝐼𝑛𝑡𝑒𝑟𝑝𝑜𝑙𝑎𝑡𝑒𝑑 𝐹𝑅𝐼×𝑈𝑁𝑃 ×(1−𝑊𝐶𝑇)
        </div>
        <div className="content-row"> 
          where Interpolated FRI is the interpolated FRI at the opportunity location, UNP is the uncontacted net pay thickness in the target zone, and WCT is the recent zone water cut of the nearest-neighbor well producing in the same target zone. Note that this method indirectly incorporates local pressure information since it relies on neighborhood fluid rates. However, the use of current fluid rates means the predicted target will likely be a low-side estimate.
        </div>
        <div className='image-container'>
          <img src={InitialProductionRateForecastA} alt="" />
        </div>
        <div className="image-footnote"> 
          These forecast methods such neighborhood analog that are limited to the spatial and temporal settings, then generates spatial and temporal weights for each of these neighbors, it then combines the weight and interprets a weighted average<br/>
        </div>
        <div className="content-row"> 
            <span className='blue'>Should use Thickness normalized neighborhood fluid rates (TNNFR)</span><br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Advantages:</span> Thickness-Normalized NFR. Fluid rates are normalized with a user-selected thickness attribute (e.g., perf length, pnp, etc.).<br/>
            <span className='blue'>Disadvantages:</span> Limited by availability of neighborhood data.<br/>
        </div>
        <div className="content-row"> 
            <span className='blue'>Should use Thickness normalized spacial temporal interpolation (TNSTI)</span><br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Advantages:</span> Thickness-Normalized STI. Initial rates are normalized with a user-selected thickness attribute (e.g., perf length, pnp, etc.).<br/>
            <span className='blue'>Disadvantages:</span> Sensitive to user-provided weights, and limited by availability of neighborhood data.<br/>
        </div>
        <div className="content-row"> 
            <span className='blue'>Comments for</span> TNNFR and TNSTI: The neighborhood data used to perform the interpolation needed to calculate the TNSTI/TNNFR forecasts can be first normalized with any among a set of thickness attributes. These include the perforation length, the perforated or drained net pay (pnp) as calculated during the derivation of the DNP log, as well as other thickness attributes derived from the DNP log such the drained hydrocarbon pore thickness (hcptPnp). Once the normalized neighborhood target values are interpolated (normalized fluid rates for TNNFR, and normalized IPs for TNSSTI), the interpolated value is de-normalized using the equivalent opportunity attribute such as the UNP thickness, or the hcptUnp. While these methods can capture explicitly the effects of any differential thicknesses between neighborhood wells and the target opportunity, they can be severely restricted by a limited set of neighborhood wells due to the lack of data required to estimate the thickness attribute. For example, normalizing with hcptDnp requires the neighborhood wells to have the phi and sw logs available. Since the log data is rarely available for all wells, the neighborhood data used for the forecast can shrink significantly.
        </div>
        <div className="content-row"> 
            <span className='blue'>Should Use Darcy</span><br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Comments:</span> Physics-based method forecast method for vertical opportunities.<br/>
            <span className='blue'>Advantages:</span> Physics-based forecast method for vertical wells.<br/>
            <span className='blue'>Disadvantages:</span> Requires fine-tuning. Inputs are not always available.<br/>
            <span className='blue'>Comments:</span> This physics-based method forecasts initial rate of a given opportunity. This particular implementation is only valid for vertical recompletion opportunities or new vertical wells (for physics-based method applicable to horizontal wells, see next section). The formula is as follows:<br/>
        </div>
        <div className='image-container'>
          <img src={InitialProductionRateForecastB} alt="" />
        </div>
        <div className="content-row"> 
            <span className='blue'>Primary Method</span><br/>
            <span className='blue'>Comments:</span> These production forecasts are meant to serve as a first-pass estimate of the relative potential of each opportunity. It is suggested to use these forecasts in conjunction with other attributes of the opportunities (such as risk, calculated in the next step) for ranking purposes. It is also generally recommended to test more than one forecast method and review which is the most appropriate for your reservoir.<br/>
            <span className='blue'>Options:</span> TC, STI, TNSTI, NFR, TNNFR, DARCY.<br/>
        </div>



      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(InitialProductionRateForecast)
