import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite'
import MenuBook from '@material-ui/icons/MenuBook'

const baseClass = 'navigation-tabs'

const styles = {
    root: {
      flexGrow: 1,
      maxWidth: '100%',
      width: 'fit-content',
      minHeight: 0,
    },
    indicator: {
      backgroundColor: '#0076c0',
    },
    tab: {
      textTransform: 'none',
      color: '#646464',
      fontSize: '14px',
      fontWeight: '700',
      marginRight: 50,
      minWidth: 75,
      lineHeight: 1,
      minHeight: 0,
      '&.Mui-selected': {
        color: '#0076c0',
      },
    },
    selected: {
      color: '#0076c0',
    },
  }

const TABS = Object.freeze({
  RESOURCES: '/training/resources',
  VIDEOS: '/training/videos',
  USER_MANUAL: '/training/manual/'
})

@autobind class NavigationTabs extends Component {
  componentDidMount() {
  }

  onClickTab(event, route) {
    const { history } = this.props
    console.log('onClickTab', route)
    event.preventDefault()
    history.push(route)
  }

  render() {
    const { location, classes } = this.props

    let tab = TABS.RESOURCES
    if (location.pathname.startsWith(TABS.VIDEOS)) tab = TABS.VIDEOS
    else if(location.pathname.startsWith(TABS.USER_MANUAL)) tab = TABS.USER_MANUAL

    return (
      <div className={baseClass}>
        <Tabs
          value={tab}
          onChange={(e, val) => this.onClickTab(e, val)}
          aria-label=""
          indicatorColor="primary"
          className={classes.root}
          TabIndicatorProps={{ className: classes.indicator }}
        >
          <Tab className={classes.tab} value={TABS.RESOURCES} icon={<InsertDriveFileIcon />} label="Resources &amp; Tutorials" aria-controls="Resources Tutorials" />
          <Tab className={classes.tab} value={TABS.VIDEOS} icon={<PlayCircleFilledWhiteIcon />} label="Videos" aria-controls="Videos" />
          <Tab className={classes.tab} value={TABS.USER_MANUAL} icon={<MenuBook />} label="User Manual" aria-controls="User Manual" />
        </Tabs>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(NavigationTabs))