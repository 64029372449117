import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import { ClipLoader } from 'react-spinners'
import Snackbar from '@material-ui/core/Snackbar'
import Card from '../../common/Card'
// import LogPlot from '../../components/LogPlot'
import WellLogPlot from '../../components/WellLogPlot'
import NeighborLogsTable from '../../components/NeighborLogsTable'

// Tooltip stuff

import './Petrophysical.scss' 

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class Petrophysical extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shouldRenderLogsTable: false,
      shouldRenderLogPlot: false,
      open: false,
      // selectedNeighbors: [],
    }
  }

  componentDidMount() {
    const { setCurPage } = this.props
    setCurPage('petro')
    this.loadData()
  }

  hideSelectedSuccess(event, reason) {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ open: false })
  }

  showSelectedSuccess() {
    this.setState({ open: true })
  }

  loadData() {
    setTimeout(() => {
      this.setState({
        shouldRenderLogsTable: true,
      })  
    }, 100)

    setTimeout(() => {
      this.setState({
        shouldRenderLogPlot: true,
      })  
    }, 200)     
  }

  selectNeighbor(name) {
    const { selectedOffsetWells, handleSelectNeighbor, handleUnselectNeighbor } = this.props

    if (!selectedOffsetWells.includes(name)) {
      handleSelectNeighbor(name)
      this.showSelectedSuccess()
    } else {
      handleUnselectNeighbor(name)
    }
  }

  renderLogsTable() {
    const { caseObj, offsetWells, selectedOffsetWells } = this.props
    const { shouldRenderLogsTable } = this.state
    if (offsetWells && shouldRenderLogsTable) {
      return (
        <NeighborLogsTable
          selectedOffsetWells={selectedOffsetWells}
          selectNeighbor={this.selectNeighbor}
          data={offsetWells}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }

  renderLogPlot() {
    const { caseObj, logData, neighborLogs, selectedOffsetWells, singleOpportunity } = this.props
    const { shouldRenderLogPlot } = this.state
    const logSectionLogs = caseObj.settings.otherGlobalSettings.otherGlobalSettings.logSectionLogs
    if ((logData || neighborLogs) && singleOpportunity && shouldRenderLogPlot) {
      const selectedNeighborLogs = neighborLogs.filter(x => x.length && selectedOffsetWells.includes(x[0].wellId))
      return (
        <WellLogPlot
          id="well-log-1"
          data={[logData]}
          defaultSections={logSectionLogs}
          neighborLogs={selectedNeighborLogs}
          selectedNeighborLogs={selectedNeighborLogs}
          selectedOffsetWells={selectedOffsetWells}
          singleOpportunity={singleOpportunity}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  render() {
    const { open } = this.state
    const { map } = this.props
    return (
      <div className="opportunity-petrophysical">
        <div className="top">
          <div className="left">
            {map}
          </div>
          <div className="right">
            <Card title="Neighboring Wells" subtitle="Select multiple wells to build cross section">
              { this.renderLogsTable() }
            </Card>
          </div>
        </div>
        <div className="bot">
          <Card title="Well Log Cross Section" tooltipKey="WellLogPlot">
            { this.renderLogPlot() }
          </Card>
        </div>
        <Snackbar
          variant="success"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          autoHideDuration={2000}
          onClose={this.hideSelectedSuccess}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          style={{ backgroundColor: '#43a047' }}
          message={<span id="message-id">Added to Opportunity Location map</span>}
        />
      </div>
    )
  }
}




export default Petrophysical
