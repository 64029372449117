import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Modal from 'react-responsive-modal'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import SvgIcon from '@material-ui/core/SvgIcon'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import styled from '@material-ui/core/styles/styled'

import SpeedwiseIcon from '../../../../../static/images/speedwise-icon.svg'
import SupportTicketCreator from './SupportTicketCreator'
import API from '../../../../lib/api-store'

import './ProductNavigation.scss'

const baseClass = 'main-nav'

const alignVertical = `align-vertical`
const inputClass = `${baseClass}__input`

const popClass = 'menu-popper'

const modalClass = 'qri-modal'
const modalCloseIconClass = 'qri-modal__close-button'

const Less = styled(ExpandLess)({
  fontSize: '1.5em',
  marginRight: '-8px',
})
const More = styled(ExpandMore)({
  fontSize: '1.5em',
  marginRight: '-8px',
})

@autobind class ProductNavigation extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      supOpen: false,
      accOpen: false,
    }
  }

  handleLogout(e) {
    e.preventDefault()

    const { handleLogout } = this.props
    handleLogout()
  }

  openModal() {
    this.setState({
      isModalOpen: true,
      accOpen: false,
      supOpen: false,
    })
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    })
  }

  makeModal() {
    return 
  }

  handleNewTicket(category, description) {
    API.createTicket(category, description)
  }

  toggleSupport(e){
    const { supOpen, accOpen } = this.state
    const newSupOpen = !supOpen
    const newAcc = newSupOpen ? false : accOpen
    this.setState({ supOpen: newSupOpen, accOpen: newAcc })
  }

  toggleAccount(e){
    const { supOpen, accOpen } = this.state
    const newAcc = !accOpen
    const newSup = newAcc ? false : supOpen
    this.setState({ accOpen: newAcc, supOpen: newSup})
  }

  closeAll(e) {
    this.setState({ accOpen: false, supOpen: false })
  }

  render() {
    const { isModalOpen, supOpen, accOpen } = this.state
    const { match, location, firstName, lastName, userId } = this.props
    const { application } = match.params

    const { pathname } = location
    const isHome = !application || application === "undefined"

    const Item = ({ children }) => <MenuItem onClick={this.closeAll}>{children}</MenuItem>
    
    return (
      <div className={baseClass}>
        <div className={alignVertical}>
          {/* DELETE THIS AFTER SHOWING KEITH */}
          { userId === "5dfcf256dadd641814b535f0" && (
            <>
              <button 
                onClick={() => {
                  fetch(`/api/testS3Credentials`, {
                    method: 'GET',
                    headers: {
                      'Content-type': 'application/json',
                    },
                    credentials: 'include',
                    cache: "no-store",
                  })
                }}
              />
              -----------
              <button 
                onClick={() => {
                  fetch(`/api/testS3CredentialsRoot`, {
                    method: 'GET',
                    headers: {
                      'Content-type': 'application/json',
                    },
                    credentials: 'include',
                    cache: "no-store",
                  })
                }}
              />
            </>
          )}
          {/* DELETE THIS AFTER SHOWING KEITH */}
          <NavLink to="/" style={{ marginBottom: '1px'}}>
            <SvgIcon component={SpeedwiseIcon} />
            Home
          </NavLink>
        </div>
        {!isHome && (<div className={alignVertical}><NavLink to={`/${application}/projects`}>Workspaces</NavLink></div>)}
        <div
          className={alignVertical}
          onClick={this.toggleSupport}
          ref={this.curRef}
          id={'support'}
        >
          Support
          {supOpen ? <Less/> : <More/>}
        </div>
        <Popper
          open={supOpen}
          anchorEl={document.getElementById('support')}
          style={{zIndex: "100"}}
        >
            <Paper className={popClass}>
              <ClickAwayListener onClickAway={this.toggleSupport}>
                <MenuList>
                  <NavLink to={{ pathname: `/training/manual/landingPage`, state: { prevPath: location.pathname }}}>
                    <Item>User Manual</Item>
                  </NavLink>
                  <NavLink to={{ pathname: `/training/resources`, state: { prevPath: location.pathname }}}>
                    <Item>Resources and Tutorials</Item>
                  </NavLink>
                  <NavLink to={{ pathname: `/training/videos`, state: { prevPath: location.pathname }}}>
                    <Item>Video Gallery</Item>
                  </NavLink>
                  <MenuItem onClick={this.openModal}>Contact Us</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
        </Popper>
        <div
          className={alignVertical}
          onClick={this.toggleAccount}
          id={'account'}
        >
          My Account
          {accOpen ? <Less/> : <More/>}
        </div>
        <Popper
          open={accOpen}
          anchorEl={document.getElementById('account')}
        >
          <ClickAwayListener onClickAway={this.toggleAccount}>
            <Paper className={popClass}>
              <div id={'user-name'}>
                {`${firstName} ${lastName}`}
              </div>
              <MenuList>
                <NavLink to={{pathname: `/licenses`, state: { prevPath: location.pathname }}}>
                  <Item>My Licenses</Item>
                </NavLink>
                <NavLink onClick={this.handleLogout} to="/">
                  <Item>Logout</Item>
                </NavLink>
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Popper>
        {/* <div className={alignVertical}>
          <NavLink to={{pathname: `/${application}/manual/landingPage`, state: { prevPath: location.pathname }}}>
            User Manual
          </NavLink>
        </div>
        <div className={alignVertical} onClick={this.openModal}>
          <a style={{cursor: 'pointer'}}>Support</a>
        </div>
        <div className={alignVertical}>
          <NavLink to={{pathname: `/licenses`, state: { prevPath: location.pathname }}}>
            Licenses
          </NavLink>
        </div>
        <div className={alignVertical}>
          <NavLink onClick={this.handleLogout} to="/login">
            Log Out
          </NavLink>
        </div> */}
        <Modal
          classNames={{ modal: modalClass, closeIcon: modalCloseIconClass }}
          open={isModalOpen}
          center
          onClose={this.closeModal}
        >
        <SupportTicketCreator
          handleNewTicket={this.handleNewTicket}
          handleCloseModal={this.closeModal}
        /> 
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return  ({
    userId: state.getIn(['user', '_id']),
    firstName: state.getIn(['user', 'firstName']),
    lastName: state.getIn(['user', 'lastName'])
  })

}

export default withRouter(connect(mapStateToProps, null)(ProductNavigation))
