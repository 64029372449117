import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import addUser1 from '../../../../../../../static/images/userManual/License/addUser/addUser1.png'
import addUser2 from '../../../../../../../static/images/userManual/License/addUser/addUser2.png'
import addUser3 from '../../../../../../../static/images/userManual/License/addUser/addUser3.png'
import addUser4 from '../../../../../../../static/images/userManual/License/addUser/addUser4.png'
import addUser5 from '../../../../../../../static/images/userManual/License/addUser/addUser5.png'

import './Create.scss'

@autobind class Create extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          How to Add a User
        </div>
        <div className="content-row">
          <ol>
            <li>
              Select the license you would like to add your user to. In most default cases, there will only be one license.
              <img className={'li-image'} src={addUser1} />
            </li>
            <li>
              Select “Add User”
              <img className={'li-image'} src={addUser2} />
            </li>
            <li>
              If the user is a new user, use the “Create New User” option, and add all of the relevant information for the new user
              <img className={'li-image'} src={addUser3} />
            </li>
            <li>
              If the user is an existing user, use the “Existing” user option. An existing user may be the following:
              <ol type="a">
                <li>The user has been added from the Active Directory</li>
                <li>A user added previously to a different license</li>
                <li>A user that had an account previously and was removed from the license</li>
                <li>The user has been added as an Admin</li>
              </ol>
              <img className={'li-image'} src={addUser4} />
            </li>
          </ol>
          <div className="content-row">
            Users will only be able to create a workspace if you allow them to. Select the user’s profile once they are added. Toggle the “Allow users to create or delete their own workspaces” privilege on.
            <img className={'li-image'} src={addUser5} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Create)