import React, { useEffect } from 'react'
import { Route, withRouter, Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import PrivateRoute from './CustomRoute/PrivateRoute'

import LoginForm from '../components/layout/Authorization/LoginForm'
import LoginFormFake from '../components/layout/Authorization/LoginFormFake'
import Home from '../components/layout/Home/Home'
import Training from '../components/layout/Training/Training'
import LoginAuth from '../components/layout/Authorization/LoginAuth'
import LicenseSelection from '../components/layout/License/LicenseSelection'
import License from '../components/layout/License/License'
import LicenseRemoveUser from '../components/layout/License/LicenseRemoveUser'
import UserManual from '../components/layout/UserManual/UserManual'
import ProjectSelection from '../components/layout/ProjectSelection/ProjectSelection'
import Project from '../components/layout/Project/Project'
import Workflow from '../components/layout/Cases/Workflow'
import VisualizationParent from '../components/layout/Visualization/VisualizationParent'

const ProductSpaceRoutes = ({ user }) => {
  
  useEffect(() => {
    document.cookie = `timeoutAt=; expires=Sun, 28 Jun 1914 10:45:00 CET`
  })

  return (
    <div className="product-space">
      <Switch>
        <PrivateRoute exact path="/:application/projects" user={user} component={ProjectSelection} />
        <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/" user={user} component={VisualizationParent} />
        <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/" user={user} component={Workflow} />
        <PrivateRoute path="/:application/projects/:projectId" user={user} component={Project} />
        <PrivateRoute path="/training" user={user} component={Training} />
        <Route path="/loginAuth"  component={LoginAuth} />
        <PrivateRoute path="/licenses/:licenseId/removeUser/:userId" user={user} component={LicenseRemoveUser} />
        <PrivateRoute path="/licenses/:licenseId" user={user} component={License} />
        <PrivateRoute path="/licenses" user={user} component={LicenseSelection} />
      </Switch>
      <Route
        exact
        path="/loginDev"
        render={props => <LoginForm {...props} user={user} />}
      />
      <Route
        exact
        path="/loginFake"
        render={props => <LoginFormFake />}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.get('user'),
})

export default withRouter(connect(mapStateToProps)(ProductSpaceRoutes))
