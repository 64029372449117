import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class FaultTraces extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Fault Traces
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>Faults</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: These are lines indicating the intersections of the faults with the top structures of zones.<br/>
          &ensp;• Utility: The only purpose of importing the fault lines is for displaying them on maps. If you would like to include fault proximity in determining sweet spot locations, you should include distance-to-fault grids into your relative probability of success or RPOS maps.<br/>
          &ensp;• File Format: Zmap + lines (ASCII)<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(FaultTraces)
