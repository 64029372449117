import { fromJS } from 'immutable'

const initialState = fromJS({
  validationObj: {},
})

const dataManagement = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_INTEGRATION_OBJ': {
      const { value } = action
      return state.set('validationObj', fromJS(value))
    }
    case 'UPDATE_INTEGRATION_OBJ': {
      const { innerObj, status } = action
      return state.setIn(['validationObj', innerObj, 'status'], status)
    }
    default:
      return state
  }
}

export default dataManagement
