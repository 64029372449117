import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { withRouter } from 'react-router-dom'
import RecompletionOpportunity from './Recompletion/RecompletionOpportunity'
import VerticalDrillOpportunity from './VerticalDrill/VerticalDrillOpportunity'
import HorizontalDrillOpportunity from './HorizontalDrill/HorizontalDrillOpportunity'
import UnconventionalOpportunity from './Unconventional/UnconventionalOpportunity'

import API from '../../../../lib/api-store'

import './Opportunity.scss'

@autobind class Opportunity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOffsetWells: [],
      offsetWells: [],
      selectedWellData: [],
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    const { caseId, opportunityId } = this.props.match.params
    const { caseId: prevCaseId, opportunityId: prevOpportunityId } = prevProps.match.params

    const caseChanged = (caseId !== prevCaseId)
    const oppChanged = (opportunityId !== prevOpportunityId)

    if(caseChanged || oppChanged){
      this.loadData()
    }

  }

  loadData() {
    const { opportunityData, match } = this.props
    const { opportunityId, caseId } = match.params
    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const selectedOffsetWells = singleOpportunity && singleOpportunity.OffsetWells
      ? singleOpportunity.OffsetWells.slice(0, 3).sort((a, b) => a.distance - b.distance).map(w => w.wellId)
      : []

    API.getOffsetWells(opportunityId, caseId)
      .then((data) => {
        const { wells } = data

        this.setState({
          offsetWells: wells,
        })
      })

    this.loadNeighborLogs(selectedOffsetWells)
  }

  handleSelectNeighbor(neighbor) {
    const { match } = this.props
    const { caseId } = match.params
    const { selectedOffsetWells, selectedWellData } = this.state
    if (!selectedOffsetWells.includes(neighbor)) {
      // Fetch the log data if we haven't already
      if (!selectedWellData.find(x => x[0].wellId === neighbor)) { 
        API.getLogs(neighbor, caseId)
          .then((logData) => {
            this.setState({
              selectedWellData: [...selectedWellData, logData],
            })
          })
      }

      this.setState({
        selectedOffsetWells: [...selectedOffsetWells, neighbor],
      })
    }
  }

  handleUnselectNeighbor(neighbor) {
    const { selectedOffsetWells } = this.state
    if (selectedOffsetWells.includes(neighbor)) {
      this.setState({
        selectedOffsetWells: selectedOffsetWells.filter(s => s !== neighbor),
      })
    }
  }

  clearAllNeighbors(){
    this.setState({ selectedOffsetWells: [] })
    //Only passed to petro => wellLogPlot
  }

  loadNeighborLogs(neighborList) {
    const { match } = this.props
    const { caseId } = match.params
    const { selectedWellData } = this.state

    Promise.all(
      neighborList.map(neigbor => API.getLogs(neigbor, caseId)),
    ).then((logs) => {
      this.setState({
        selectedWellData: [...selectedWellData, ...logs],
      })
    })
  }

  render() {
    const { opportunityData, match, updateOpportunity, updateOpportunityComments } = this.props
    const { opportunityId } = match.params

    const { offsetWells, selectedOffsetWells, selectedWellData } = this.state

    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null

    if (singleOpportunity) {
      const type = singleOpportunity.type || null
      switch (type) {
        case 'recompletion':
          return (
            <RecompletionOpportunity
              offsetWells={offsetWells}
              neighborLogs={selectedWellData}
              selectedOffsetWells={selectedOffsetWells}
              handleSelectNeighbor={this.handleSelectNeighbor}
              handleUnselectNeighbor={this.handleUnselectNeighbor}
              clearAllNeighbors={this.clearAllNeighbors}
              opportunity={singleOpportunity}
              updateOpportunity={updateOpportunity}
              updateOpportunityComments={updateOpportunityComments}
              {...this.props}
            />
          )
        case 'verticalDrill':
          return (
            <VerticalDrillOpportunity
              opportunity={singleOpportunity}
              updateOpportunity={updateOpportunity}
              updateOpportunityComments={updateOpportunityComments}
              {...this.props}
            />
          )
        case 'horizontalDrill':
          return (
            <HorizontalDrillOpportunity
              opportunity={singleOpportunity}
              updateOpportunity={updateOpportunity}
              updateOpportunityComments={updateOpportunityComments}
              {...this.props}
            />
          )
        case 'unconventionalDrill':
          return (
            <UnconventionalOpportunity
              opportunity={singleOpportunity}
              updateOpportunity={updateOpportunity}
              updateOpportunityComments={updateOpportunityComments}
              {...this.props}
            />
          )
        default:
          return null
      }
    }

    return null
  }
}

export default withRouter(Opportunity)
