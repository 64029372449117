import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { text } from '@fortawesome/fontawesome-svg-core'

const logOptions = [
  { label: 'bs', value: 'bs' },
  { label: 'cali', value: 'cali' },
  { label: 'dt', value: 'dt' },
  { label: 'gr', value: 'gr' },
  { label: 'k', value: 'k' },
  { label: 'mnp', value: 'mnp' },
  { label: 'netPay', value: 'netPay' },
  { label: 'netRes', value: 'netRes' },
  { label: 'netSand', value: 'netSand' },
  { label: 'netSeal', value: 'netSeal' },
  { label: 'none', value: 'none' },
  { label: 'nphi', value: 'nphi' },
  { label: 'pe', value: 'pe' },
  { label: 'perfs', value: 'perfs' },
  { label: 'phi', value: 'phi' },
  { label: 'pnp', value: 'pnp' },
  { label: 'resD', value: 'resD' },
  { label: 'resM', value: 'resM' },
  { label: 'resS', value: 'resS' },
  { label: 'rhob', value: 'rhob' },
  { label: 'rockType', value: 'rockType' },
  { label: 'sigma', value: 'sigma' },
  { label: 'sigmaDate', value: 'sigmaDate' },
  { label: 'sp', value: 'sp' },
  { label: 'sstvd', value: 'sstvd' },
  { label: 'sw', value: 'sw' },
  { label: 'unp', value: 'unp' },
  { label: 'vcl', value: 'vcl' },
  { label: 'so', value: 'so' },
  { label: 'baffles', value: 'baffles' },
]

const rposOptions = [
  { label: 'Current Hcpt', value: 'currentHcpt' },
  { label: 'Drainage', value: 'drainage' },
  { label: 'Recent Wct', value: 'recentWct' },
  { label: 'Target Thickness', value: 'targetThickness' },
  { label: 'Target Thickness Index', value: 'targetThicknessIndex' },
  { label: 'Phi', value: 'phi' },
  { label: 'Sw', value: 'sw' },
  { label: 'Net Pay', value: 'netPay' },
  { label: 'Dtf', value: 'dtf' },
  { label: 'Dtf Index', value: 'dtfIndex' },
  { label: 'Structure', value: 'structure' },
  { label: 'Isochore', value: 'isochore' },
]

const gridOptions = [
  { label: 'Current HCPT', value: 'currentHcpt' },
  { label: 'DTF', value: 'dtf' },
  { label: 'GOC', value: 'goc' },
  { label: 'Isochore', value: 'isochore' },
  { label: 'K', value: 'k' },
  { label: 'Net Pay', value: 'netPay' },
  { label: 'Original HCPT', value: 'originalHcpt' },
  { label: 'OWC', value: 'owc' },
  { label: 'Phi', value: 'phi' },
  { label: 'Structure', value: 'structure' },
  { label: 'SW', value: 'sw' }
]

/** https://qrigroup.atlassian.net/browse/SJ-343 */
const attributeOptions = [
  { label: 'predictedIp', value: 'predictedIp'},
  { label: 'pos', value: 'pos' },
  { label: 'zoneIndex', value: 'zoneIndex' },
  { label: 'netPay', value: 'netPay' },
  { label: 'hcpt', value: 'hcpt' },
  { label: 'recentWct', value: 'recentWct' },
  { label: 'recentGor', value: 'recentGor' },
  { label: 'targetIntervalTop', value: 'targetIntervalTop' },
  { label: 'targetIntervalBase', value: 'targetIntervalBase' },
  { label: 'targetIntervalMiddle', value: 'targetIntervalMiddle' },
  { label: 'k', value: 'k' },
]

const sweetSpotWellAttributeOptions = [
  { label: 'predictedIp', value: 'predictedIp' }
]


const mappingMethodOptions = [
  { label: 'nearest', value: 'nearest' },
  { label: 'natural', value: 'natural' },
  { label: 'v4', value: 'v4' },
  { label: 'cubic', value: 'cubic' },
  { label: 'linear', value: 'linear' },
]

const ntgAttributeOptions = [
  { label: 'sw', value: 'sw' },
  { label: 'hcpv', value: 'hcpv' },
  { label: 'phi', value: 'phi' },
]

const horizontalTargetCellOptions = [
  { label: 'hcpv', value: 'hcpv' },
  { label: 'phi', value: 'phi' },
  { label: 'sw', value: 'sw' },
  { label: 'dtf', value: 'dtf' },
  { label: 'dtfIndex', value: 'dtfIndex' },
  { label: 'ntg', value: 'ntg' },
  { label: 'drainage', value: 'drainage' },
  { label: 'recentWct', value: 'recentWct' },
  { label: 'recentGor', value: 'recentGor' },
  { label: 'inOilWindow', value: 'inOilWindow' },
  { label: 'k', value: 'k' },
  { label: 'pos', value: 'pos' },
]

const horizontalRankingAttributes = [
  { label: 'hcpv', value: 'hcpv' },
  { label: 'phi', value: 'phi' },
  { label: 'sw', value: 'sw' },
  { label: 'dtfIndex', value: 'dtfIndex' },
  { label: 'ntg', value: 'ntg' },
  { label: 'drainage', value: 'drainage' },
  { label: 'recentWct', value: 'recentWct' },
  { label: 'recentGor', value: 'recentGor' },
  { label: 'inOilWindow', value: 'inOilWindow' },
  { label: 'k', value: 'k' },
  { label: 'z', value: 'z' },
]

const noGoCellProperties = [
  { label: 'spacing', value: 'spacing' },
  { label: 'drainage', value: 'drainage' },
  { label: 'dtfIndex', value: 'dtfIndex' },
  { label: 'inOilWindow', value: 'inOilWindow' },
]

const horizontalFilterAttributes = [
  { label: 'length', value: 'length' },
  { label: 'posValue', value: 'posValue' },
  { label: 'ntg', value: 'ntg' },
  { label: 'netPay', value: 'netPay' },
  { label: 'recentWct', value: 'recentWct' },
  { label: 'recentGor', value: 'recentGor' },
  { label: 'dtf', value: 'dtf' },
  { label: 'predictedIp', value: 'predictedIp' },
  { label: 'poe', value: 'poe' },
  { label: 'confidence', value: 'confidence' },
]

export const notSGOSubgroups = ['dependency', 'step', 'path', 'name', 'helperText']
//These settings properties are in the same spot as subgroups and can cause errors.

export const GlobalSettingsMap = {
  zoneSettings: {
    name: 'Basic Zone Information',
    path: '/training/manual/modules/recompletions/settings/basicZoneInfo',
    Table: {
      default: [
        {
          "zoneId": "all",
          "fvf": 1.2,
          "rf": 0.25,
        }
      ],
      header: '',
      columns: [{
        Header: 'Zone ID',
        accessor: 'zoneId',
        sortable: false,
        type: 'input'
      }, {
        Header: 'FVF',
        accessor: 'fvf',
        sortable: false,
        type: 'numerical'
      }, {
        Header: 'EURF',
        accessor: 'rf',
        sortable: false,
        type: 'numerical',
        //Pulling these out so they are easily accessible to the <input/> creation attributes
        step: 0.1,
        min: 0,
        max: 1,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      }, {
        Header: 'OWC',
        accessor: 'owc',
        sortable: false,
        type: 'numerical'
      }, {
        Header: 'GOC',
        accessor: 'goc',
        sortable: false,
        type: 'numerical'
      }]
    }
  },
  logAlias: {
    name: 'Log Alias',
    path: '/training/manual/modules/recompletions/settings/logAlias',
    helperText: 'At a minimum, this Log Alias table requires a curve name specified for phi and sw. You should also define a netPay log unless you plan to define this log under Log Zone Analytics.',
    Table: {
      default: [],
      header: '',
      columns: [{
        Header: <span>
            {'From '}
            <a
              data-tip={"This is the log name that comes from<br/> the datasets' LAS files."}
              data-for={'From'}
              data-multiline={true}
            >
              <FontAwesomeIcon icon="question-circle" className="help-icon" />
            </a>
            <ReactTooltip id={'From'} className="customize-settings-tooltip" effect="solid" multiline={true}/>
          </span>,
        accessor: 'From',
        sortable: false,
        type: 'input',
      }, {
        Header: <span>
            {'To '}
            <a
              data-tip={"This is the log type that<br/> you will be aliasing to."}
              data-for={'To'}
              data-multiline={true}
            >
              <FontAwesomeIcon icon="question-circle" className="help-icon" />
            </a>
            <ReactTooltip id={'To'} className="customize-settings-tooltip" effect="solid" multiline={true}/>
          </span>,
        accessor: 'To',
        sortable: true,
        type: 'categorical',
        options: logOptions,
      }, {
        Header: <span>
            {'Priority '}
            <a
              data-tip={"If you have selected more than one<br/> curve from the LAS files to use for a<br/> single type of log curve, you can<br/> prioritize which to use if a well<br/> has multiple in the same interval.<br/> Otherwise, we’ll automatically select the<br/> curve that is more popular in the dataset."}
              data-for='Priority'
            >
              <FontAwesomeIcon icon="question-circle" className="help-icon" />
            </a>
            <ReactTooltip id={'Priority'} className="customize-settings-tooltip" effect="solid" multiline="true" wrapper="span"/>
          </span>,
        accessor: 'Priority',
        sortable: false,
        type: 'numerical',
      }],
    },
  },
  logCleaning: {
    name: 'Log Cleaning',
    path: '/training/manual/modules/recompletions/settings/logCleaning',
    logCleaning: {
      cleanLogs: {
        display: 'Clean Logs',
        subDisplay: '',
        description: "If on, this cleans out-of-bound logs based on 'Trim Min Value' and 'Trim Max Value' which are defined in 'Log Settings' under 'Global Settings'.",
        path: 'Settings.LogSettings.Processed.cleanLogs',
        type: 'bool',
        default: false,
      },
      cleanWithNulls: {
        display: 'Clean with Nulls',
        subDisplay: '',
        description: 'If on, then out-of-bound values are replaced with nulls. If off, then out-of-bound values are set to the limits.',
        // dependency: ['dataIntegration', 'logCleaning', 'cleanLogs', true],
        path: 'Settings.LogSettings.Processed.cleanWithNulls',
        type: 'bool',
        default: false,
      },
      // mergeMultiLogFiles: {
      //   display: 'Merge Multi-Log Files',
      //   subDisplay: '',
      //   description: 'If on, when there are multiple log files per well they will be merged.',
      //   path: 'Settings.LogSettings.Processed.mergeMultiLogFiles',
      //   type: 'bool',
      //   default: true,
      // },
      resampleInterval: {
        display: 'Resample Interval',
        subDisplay: '',
        description: 'Resamples the logs to this depth interval.',
        // dependency: ['dataIntegration', 'logCleaning', 'mergeMultiLogFiles', true],
        path: 'Settings.LogSettings.Processed.resampleInterval',
        type: 'numerical',
        unit: 'meters',
        default: 0.1524,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 10,
          }
        ],
      },
    },
  },
  logSettings: {
    name: 'Log Settings',
    path: '/training/manual/modules/recompletions/settings/logSettings',
    Table: {
      default: [
        {
          "logName": "bs",
        },
        {
          "logName": "cali",
        },
        {
          "logName": "dt",
        },
        {
          "logName": "gr",
        },
        {
          "logName": "k",
        },
        {
          "logName": "mnp",
        },
        {
          "logName": "netPay",
        },
        {
          "logName": "netRes",
        },
        {
          "logName": "netSand",
        },
        {
          "logName": "netSeal",
        },
        {
          "logName": "none",
        },
        {
          "logName": "nphi",
        },
        {
          "logName": "pe",
        },
        {
          "logName": "perfs",
        },
        {
          "logName": "phi",
        },
        {
          "logName": "pnp",
        },
        {
          "logName": "resD",
        },
        {
          "logName": "resM",
        },
        {
          "logName": "resS",
        },
        {
          "logName": "rhob"
        },
        {
          "logName": "rockType",
        },
        {
          "logName": "sigma",
        },
        {
          "logName": "sigmaDate",
        },
        {
          "logName": "sp",
        },
        {
          "logName": "sstvd",
        },
        {
          "logName": "sw",
        },
        {
          "logName": "unp",
        },
        {
          "logName": "vcl",
        },
        {
          "logName": "so",
        },
        {
          "logName": "baffles"
        }
      ],
      header: '',
      columns: [{
        Header: 'Log Name',
        accessor: 'logName',
        sortable: false,
        type: 'input'
      }, {
        Header: 'Trim Min Value',
        accessor: 'trimMinValue',
        sortable: false,
        type: 'numerical'
      }, {
        Header: 'Trim Max Value',
        accessor: 'trimMaxValue',
        sortable: false,
        type: 'numerical'
      }]
    }
  },
  gridCleaning: {
    name: 'Grid Cleaning',
    path: '/training/manual/modules/recompletions/settings/gridCleaning',
    Table: {
      default: [],
      header: '',
      columns: [{
        Header: 'Grid To Clean',
        accessor: 'gridsToClean',
        sortable: false,
        type: 'categorical',
        options: gridOptions,

      }, {
        Header: 'Default Value',
        accessor: 'defaultValues',
        sortable: false,
        type: 'numerical',
      }, {
        Header: 'Mins',
        accessor: 'mins',
        sortable: false,
        type: 'numerical',
      }, {
        Header: 'Maxs',
        accessor: 'maxs',
        sortable: false,
        type: 'numerical',
      }],
    },
  },
  otherGlobalSettings: {
    name: 'Other Global Settings',
    path: '/training/manual/modules/recompletions/settings/otherGlobalSettings',
    otherGlobalSettings: {
      wellIPBest: {
        display: 'Well IP Best',
        subDisplay: '',
        description: 'These set the definition for the initial production (IP) rate.',
        type: 'numerical',
        unit: 'month(s)',
        default: 3,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 1,
          },
          {
            type: 'integer',
          },
          {
            type: 'codependentSettingsLessThanEqualTo',
            dependency: ['otherGlobalSettings', 'otherGlobalSettings', 'wellIPFirst']
          }
        ],
      },
      wellIPFirst: {
        display: 'Well IP First',
        subDisplay: '',
        description: 'These set the definition for the initial production (IP) rate.',
        type: 'numerical',
        unit: 'month(s)',
        default: 12,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 1,
          },
          {
            type: 'integer',
          }
        ],
      },
      logSectionLogs: {
        display: 'Log Section Logs',
        subDisplay: '',
        description: 'Logs to show on log-based sections and to find offset wells',
        path: 'Settings.Figures.LogSection.logs',
        type: 'categorical',
        options: logOptions,
        multi: true,
        default: ['vcl', 'phi', 'sw', 'k'],
      },
      topZone: {
        display: 'Top Zone',
        subDisplay: '',
        description: '',
        type: 'string',
        path: 'Settings.FieldParameters.topZone',
        default: '',
      },
      projectSymbol: {
        display: 'Project Abbreviation',
        subDisplay: '',
        description: '',
        type: 'string',
        path: 'Settings.projSymbol',
        default: ''
      },
      mappingTimeFrame: {
        display: 'Mapping Time Frame',
        subDisplay: 'Positive Integers only',
        description: 'Maps recent water cut or gas-oil ratio data that is within this recent time frame.',
        path: 'Settings.Mapping.recentTimeFrame',
        type: 'numerical',
        unit: 'year(s)',
        default: 5,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 1,
          },
          {
            type: 'integer',
          }
        ],
      },
      verticalIfNoTraj: {
        display: 'Vertical if no Trajectory',
        subDisplay: '',
        description: 'If on, wells with no trajectory data are assumed to be vertical, and the TVDSS logs are based on the MD and KB.',
        path: 'Settings.WellSettings.verticalIfNoTraj',
        type: 'bool',
        default: true,
      },
    }
  }
}

export const UnconventionalsGlobalSettingsMap = {
  zoneSettings: {
    name: 'Basic Zone Information',
    path: '/training/manual/modules/recompletions/settings/basicZoneInfo',
    Table: {
      default: [
        {
          "zoneId": "all",
          "fvf": 1.2,
          "rf": 0.25,
        }
      ],
      header: '',
      columns: [{
        Header: 'Zone ID',
        accessor: 'zoneId',
        sortable: false,
        type: 'input'
      }, {
        Header: 'FVF',
        accessor: 'fvf',
        sortable: false,
        type: 'numerical'
      }, {
        Header: 'EURF',
        accessor: 'rf',
        sortable: false,
        type: 'numerical',
        //Pulling these out so they are easily accessible to the <input/> creation attributes
        step: 0.1,
        min: 0,
        max: 1,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      }, {
        Header: 'OWC',
        accessor: 'owc',
        sortable: false,
        type: 'numerical'
      }, {
        Header: 'GOC',
        accessor: 'goc',
        sortable: false,
        type: 'numerical'
      }]
    }
  },
  gridCleaning: {
    name: 'Grid Cleaning',
    path: '/training/manual/modules/recompletions/settings/gridCleaning',
    Table: {  
      default: [],
      header: '',
      columns: [{
        Header: 'Grid To Clean',
        accessor: 'gridsToClean',
        sortable: false,
        type: 'categorical',
        options: gridOptions,

      }, {
        Header: 'Default Value',
        accessor: 'defaultValues',
        sortable: false,
        type: 'numerical',
      }, {
        Header: 'Mins',
        accessor: 'mins',
        sortable: false,
        type: 'numerical',
      }, {
        Header: 'Maxs',
        accessor: 'maxs',
        sortable: false,
        type: 'numerical',
      }],
    },
  },
  otherGlobalSettings: {
    name: 'Other Global Settings',
    path: '/training/manual/modules/recompletions/settings/otherGlobalSettings',
    otherGlobalSettings: {
      wellIPBest: {
        display: 'Well IP Best',
        subDisplay: '',
        description: 'These set the definition for the initial production (IP) rate.',
        type: 'numerical',
        unit: 'month(s)',
        default: 3,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 1,
          },
          {
            type: 'integer',
          },
          {
            type: 'codependentSettingsLessThanEqualTo',
            dependency: ['otherGlobalSettings', 'otherGlobalSettings', 'wellIPFirst']
          }
        ],
      },
      wellIPFirst: {
        display: 'Well IP First',
        subDisplay: '',
        description: 'These set the definition for the initial production (IP) rate.',
        type: 'numerical',
        unit: 'month(s)',
        default: 12,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 1,
          },
          {
            type: 'integer',
          }
        ],
      },
      topZone: {
        display: 'Top Zone',
        subDisplay: '',
        description: '',
        type: 'string',
        path: 'Settings.FieldParameters.topZone',
        default: '',
      },
      projectSymbol: {
        display: 'Project Abbreviation',
        subDisplay: '',
        description: '',
        type: 'string',
        path: 'Settings.projSymbol',
        default: ''
      },
      mappingTimeFrame: {
        display: 'Mapping Time Frame',
        subDisplay: 'Positive Integers only',
        description: 'Maps recent water cut or gas-oil ratio data that is within this recent time frame.',
        path: 'Settings.Mapping.recentTimeFrame',
        type: 'numerical',
        unit: 'year(s)',
        default: 5,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 1,
          },
          {
            type: 'integer',
          }
        ],
      },
      verticalIfNoTraj: {
        display: 'Vertical if no Trajectory',
        subDisplay: '',
        description: 'If on, wells with no trajectory data are assumed to be vertical, and the TVDSS logs are based on the MD and KB.',
        path: 'Settings.WellSettings.verticalIfNoTraj',
        type: 'bool',
        default: true,
      },
    }
  }
}
// gridCleaning: {
//   cleanAboveGoc: {
//     display: 'Clean Above GOC',
//     subDisplay: '',
//     description: 'Cleans the selected grids below the oil water contact.',
//     path: 'Settings.Data.Grid.cleanAboveGoc',
//     type: 'bool',
//     default: false,
//   },
//   cleanBelowOwc: {
//     display: 'Clean Below OWC',
//     subDisplay: '',
//     description: 'Cleans the selected grids above the gas-oil contact.',
//     path: 'Settings.Data.Grid.cleanBelowOwc',
//     type: 'bool',
//     default: false,
//   },
// },

//     logsToAverage: {
//   display: 'Logs to Average',
//   subDisplay: '',
//   description: 'Logs to average for every interval type (NP, UNP aka MNP, PNP, Perfs).',
//   path: 'Settings.LogSettings.Others.logsToAverage',
//   type: 'categorical',
//   options: [
//     {label: 'vcl', value: 'vcl'},
//     {label: 'phi', value: 'phi'},
//     {label: 'sw', value: 'sw'},
//     {label: 'resD', value: 'resD'},
//     {label: 'k', value: 'k'},
//   ],
//   multi: true,
//   default: ['vcl', 'phi', 'sw', 'resD', 'k'],
// },

// targetSearch: {
//   targetSearch: {
//     Table: {
//       default: [
//         {
//           opportunityRankingAttribute: 'mnp',
//           weight: 0.2
//         },
//         {
//           opportunityRankingAttribute: 'hcpt',
//           weight: 0.4
//         },
//         {
//           opportunityRankingAttribute: 'k',
//           weight: 0.2
//         },
//         {
//           opportunityRankingAttribute: 'resD',
//           weight: 0.2
//         },
//       ],
//       header: '',
//       columns: [{
//         Header: 'Opportunity Ranking Attribute',
//         accessor: 'opportunityRankingAttribute',
//         sortable: false,
//         type: 'categorical',
//         options: attributeOptions, 
//       }, {
//         Header: 'Weight',
//         accessor: 'weight',
//         sortable: false,
//         type: 'numerical',
//       }],
//     },
//   },
//   step: 7
// },

//   targetAttribute: {
//   display: 'Target Attribute',
//   subDisplay: '',
//   description: 'These production forecasts are meant to serve as a first-pass estimate of the relative potential of each opportunity. It is suggested to use these forecasts in conjunction with other attributes of the opportunities (such as risk, calculated in the next step) for ranking purposes. It is also generally recommended to test more than one forecast method and review which is the most appropriate for your reservoir.',
//   path: 'Settings.Forecast.targetAttribute',
//   type: 'categorical',
//   options: [
//     {label: 'IP', value: 'ip'},
//     {label: 'EUR', value: 'eur'},
//   ], 
//   default: 'ip' 
// },
// economicLimit: {
//   display: 'Economic Limit',
//   subDisplay: '',
//   description: `Used to convert initial production rate (IP) to estimated ultimate recovery (EUR).`,
//   path: 'Settings.Forecast.economicLimit',
//   dependency: ['productionForecast', 'productionForecast', 'targetAttribute', 'ip'],
//   type: 'numerical',
//   unit: 'BBL/D',
//   default: 2,
// },

// temporalNeighborhood: {
//   use: {
//     display: 'Use',
//     subDisplay: '',
//     description: 'If on, activates a time filter on the neighborhood data used for production forecasting.', 
//     path: 'Settings.Neighborhood.Temporal.on',
//     type: 'bool',
//     default: true,
//   },
//   zoneScale: {
//     display: 'Zone Scale',
//     subDisplay: '',
//     description: `Number of recent years at zone level.`,
//     path: 'Settings.Neighborhood.Temporal.zoneScale',
//     type: 'numerical',
//     unit: 'year',
//     default: 15,
//   }, 
//   globalScale: {
//     display: 'Global Scale',
//     subDisplay: '',
//     description: `Number of recent years at global level.`,
//     path: 'Settings.Neighborhood.Temporal.globalScale',
//     type: 'numerical',
//     unit: 'year',
//     default: 15,
//   }
// },
// spatialNeighborhood: {
//   use: {
//     display: 'Use',
//     subDisplay: '',
//     description: 'If on, activates a spatial filter on the neighborhood data used for production forecasting.',
//     path: 'Settings.Neighborhood.Spatial.on',
//     type: 'bool',
//     default: true,
//   },
//   type: {
//     display: 'Type',
//     subDisplay: '',
//     description: 'This setting determines whether the neighborhood data is restricted to neighbors within a certain distance (radius) or if the neighborhood is restricted to a certain number of closest neighbors, regardless of distance (points).',
//     path: 'Settings.Neighborhood.Spatial.type',
//     dependency: ['productionForecast', 'spatialNeighborhood', 'use', true],
//     type: 'categorical',
//     options: [
//       { label: 'Radius', value: 'radius'},
//       { label: 'Points', value: 'points'},
//     ], 
//     default: 'points' 
//   },
//   zoneScale: {
//     display: 'Zone Scale',
//     subDisplay: '',
//     description: `Defines the areal extent of the neighborhood at zone level.`,
//     dependency: ['productionForecast', 'spatialNeighborhood', 'use', true],
//     path: 'Settings.Neighborhood.Spatial.zoneScale',
//     type: 'numerical',
//     unit: 'Radius size (meters) or number of points',
//     default: 10,
//   },
//   globalScale: {
//     display: 'Global Scale',
//     subDisplay: '',
//     description: `Defines the areal extent of the neighborhood at global level.`,
//     dependency: ['productionForecast', 'spatialNeighborhood', 'use', true],
//     path: 'Settings.Neighborhood.Spatial.globalScale',
//     type: 'numerical',
//     unit: 'Radius size (meters) or number of points',
//     default: 10,
//   },
// },


export const RecompletionsSettingsMap = {
  engineeringAnalytics: {
    
    DCA: {
      name: 'Decline Curve Analysis',
      path: '/training/manual/modules/recompletions/settings/declineCurveAnalysis',
      declinePhase: {
        display: 'Decline Phase',
        subDisplay: '',
        description: 'Choose which phase to base the decline on',
        path: 'Settings.EngineeringSettings.DCA.declinePhase',
        type: 'categorical',
        options: [
          { label: 'Oil', value: 'Oil' }
        ],
        multi: true,
        default: ['Oil'],
        disabled: true
      },
      declineType: {
        display: 'Decline Type',
        subDisplay: '',
        description: 'Type of Decline Method',
        path: 'Settings.EngineeringSettings.DCA.declineType',
        type: 'categorical',
        options: [
          { label: 'Exponential', value: 'Exponential' },
          { label: 'Hyperbolic', value: 'Hyperbolic' },
          // {label: 'Best', value: 'Best'},
        ],
        multi: false,
        default: 'Hyperbolic',
      },
      minOPR: {
        display: 'Min OPR',
        subDisplay: 'Min Value must be greater than 0',
        description: 'Minimum oil production rate (Production Limit), after which the prediction stops',
        path: 'Settings.EngineeringSettings.DCA.minOPR',
        type: 'numerical',
        unit: 'bbl/d',
        default: 3.3,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      minGPR: {
        display: 'Min GPR',
        subDisplay: 'Min Value must be greater than 0',
        description: 'Minimum gas production rate (Production Limit), after which the prediction stops',
        path: 'Settings.EngineeringSettings.DCA.minGPR',
        type: 'numerical',
        unit: 'mscf/d',
        default: 3,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      numberOfDeclineMonths: {
        display: 'Number Of Decline Months',
        subDisplay: 'Min Value must be greater than 0',
        description: 'Maximum number of months for prediction',
        path: 'Settings.EngineeringSettings.DCA.nMonthsDecline',
        type: 'numerical',
        unit: 'month(s)',
        default: 300,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 1,
          },
          {
            type: 'integer'
          }
        ],
      },
      declineStartDate: {
        display: 'Decline Start Date',
        subDisplay: 'Enter a past year',
        description: 'Year to start decline, disregard any previous data',
        path: 'SeSettings.EngineeringSettings.DCA.declineStartDate',
        type: 'numerical',
        unit: 'year',
        default: 1950,
        restrictions: [
          {
            type: 'above',
            min: 0,
          },
          {
            type: 'integer'
          },
          {
            type: 'beforeCurrentYear'
          }
        ]
      },
      minD: {
        display: 'Min D',
        subDisplay: 'Value must be between 0 and 1',
        description: 'Minimum effective annual decline. If effective annual decline falls below this value, it imposes this value to the decline curve',
        path: 'Settings.EngineeringSettings.DCA.minD',
        type: 'numerical',
        default: 0.05,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      },
      typeCurveDi: {
        display: 'Type Curve Di (optional)',
        subDisplay: 'Min Value must be greater than 0',
        description: 'Field average decline rate to be used as the decline rate for type curves, if the automated type curve results in zero decline rate',
        path: 'Settings.EngineeringSettings.DCA.typeCurveDi',
        type: 'numerical',
        default: 0.15,
        restrictions: [
          {
            type: 'above',
            min: 0,
          },
        ]
      },
    },
    flowUnitAllocation: {
      name: 'Flow Unit Allocation',
      path: '/training/manual/modules/recompletions/settings/flowUnitAllocation',
      setup: {
        display: 'Setup',
        subDisplay: '',
        description: "'Kh' also known as the Darcy Model is recommended for most reservoirs. 'Chan' uses Chan's water behavior identification algorithm, and can be recommened for fractured reservoirs with channeling and coning. PLEASE NOTE: Regardless of this 'Setup' choice, if there are PLTs uploaded, then the default will be to use them.",
        path: 'Settings.EngineeringSettings.FlowUnitAllocation.setup',
        type: 'categorical',
        multi: false,
        options: [
          { label: 'Chan', value: 'chan' },
          { label: 'Kh', value: 'kh' },
          { label: 'Coning', value: 'coning' },
          { label: 'User Allocated (dataset must have the file “Well Production Monthly Allocated”)', value: 'userAllocated' },
        ],
        default: 'kh',
      },
      differenciationMethod: {
        display: 'Differenciation Method',
        dependency: ['engineeringAnalytics', 'flowUnitAllocation', 'setup', 'chan'],
        subDisplay: '',
        description: 'The method used for the differentiation of the water oil ratio',
        path: 'Settings.EngineeringSettings.WaterBehaviorID.differenciationMethod',
        type: 'categorical',
        options: [
          { label: 'Bourdet', value: 'bourdet' },
          { label: 'Forward', value: 'forward' },
          { label: 'Backward', value: 'backward' },
        ],
        default: 'bourdet',
      },
      eventsDetectionData: {
        display: 'Events Detection Data',
        dependency: ['engineeringAnalytics', 'flowUnitAllocation', 'setup', 'chan'],
        subDisplay: '',
        description: 'Identifies the choice of data to be use to detect changes in data trend: 1) derivative of water oil ratio, 2) water oil ratio, 3) both derivative of water oil ratio, and water oil ratio',
        path: 'Settings.EngineeringSettings.WaterBehaviorID.eventsDetectionData',
        type: 'categorical',
        options: [
          { label: 'Deriv. of WOR', value: 1 },
          { label: 'WOR', value: 2 },
          { label: 'Both', value: 3 },
        ],
        default: 3,
      },
      eventsDetectionSensitivity: {
        display: 'Events Detection Sensitivity',
        dependency: ['engineeringAnalytics', 'flowUnitAllocation', 'setup', 'chan'],
        subDisplay: '',
        description: 'Sensitivity for detecting changes in the trend of the water oil ratio derivative. If sensitivity is 1, only the major trends are detected.',
        path: 'Settings.EngineeringSettings.WaterBehaviorID.eventsDetectionSensitivity',
        type: 'numerical',
        default: 0.9,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      },
      minFitPeriodDuration: {
        display: 'Min Fit Period Duration',
        dependency: ['engineeringAnalytics', 'flowUnitAllocation', 'setup', 'chan'],
        subDisplay: 'Min Value must be greater than 0',
        description: 'Number of months  identifying the minimum duration of a fit period (if the duration is smaller than the minimum, the fit period is merged with the preceding period',
        path: 'Settings.EngineeringSettings.WaterBehaviorID.minFitPeriodDuration',
        type: 'numerical',
        unit: 'month(s)',
        default: 8,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      shutInPeriodDurationThreshold: {
        display: 'Shut In Period Duration Threshold',
        dependency: ['engineeringAnalytics', 'flowUnitAllocation', 'setup', 'chan'],
        subDisplay: 'Min Value must be greater than 0',
        description: 'Number of months identifying a duration threshold',
        path: 'Settings.EngineeringSettings.WaterBehaviorID.shutInPeriodDurationThreshold',
        type: 'numerical',
        unit: 'month(s)',
        default: 0,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      smoothLevel: {
        display: 'Smooth Level',
        dependency: ['engineeringAnalytics', 'flowUnitAllocation', 'setup', 'chan'],
        subDisplay: 'Min Value must be greater than 0',
        description: 'A constant, for water to oil ratio curve fitting',
        path: 'Settings.EngineeringSettings.WaterBehaviorID.smoothLevel',
        type: 'numerical',
        default: 20,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      smoothingMethodIndex: {
        display: 'Smoothing Method Index',
        dependency: ['engineeringAnalytics', 'flowUnitAllocation', 'setup', 'chan'],
        subDisplay: '',
        description: 'An array that lists the choice of the smoothing methods to be used for the treatment of WOR data',
        path: 'Settings.EngineeringSettings.WaterBehaviorID.smoothingMethodIndex',
        type: 'categorical',
        multi: true,
        options: [
          { label: 'Moving Average', value: 1 },
          { label: 'Locally Weighted Scatterplot Smoothing', value: 2 },
          { label: 'Locally Estimated Scatterplot Smoothing', value: 3 },
          { label: 'Savitzky-Golay', value: 4 },
          { label: 'Robust Locally Weighted Scatterplot Smoothing', value: 5 },
          { label: 'Robust Locally Estimated Scatterplot Smoothing', value: 6 },
          { label: 'Perfect Smooth', value: 7 },
        ],
        default: [1, 5, 6, 7],
      },
      weightReiIncreaseLastPeriod: {
        display: 'Weight Rei Increase Last Period',
        dependency: ['engineeringAnalytics', 'flowUnitAllocation', 'setup', 'chan'],
        subDisplay: 'Value must be between 0 and 100',
        description: 'Relative weight increase of the last period when calculating misfit to raw data. This is to give more weight to the last period when identifying the best smoothing method. To leave the weight equaly distributed between all fit periods, set to zero.',
        path: 'Settings.EngineeringSettings.WaterBehaviorID.weightRelIncreaseLastPeriod',
        type: 'numerical',
        unit: '%',
        default: 50,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 100,
          }
        ]
      },
      initialPressure: {
        display: 'Initial Pressure',
        subDisplay: '',
        description: 'Reservoir pressure is used to convert the measured flow rates at the surface to reservoir conditions. The analysis interpolates the initial pressure through time in order to get more accurate results.',
        path: 'Settings.EngineeringSettings.pinit',
        type: 'numerical',
        unit: 'psi',
        default: 5000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0
          },
        ]
      },
    },
    step: 2
  },
  logZoneAnalytics: {
    netPaySettings: {
      name: 'Net Pay Settings',
      path: '/training/manual/modules/recompletions/settings/netPay',
      generate: {
        display: 'Generate Net Pay Logs',
        subDisplay: '',
        description: "If you prefer to generate a net pay log instead of importing it, you can set the definition of the net pay log here. After switching this to 'On', set the net pay definition by selecting the logs, cutoffs, and direction for the cutoffs in the rows that appear below. Please make sure the logs you select to define your net pay are also logs you have defined for this case (check this in Global settings/Log Alias). Please also check the ranges of your log values so you set the appropriate cutoff. Most porosity and sw logs scale from 0 to 1, but occasionally they are scaled from 0 to 100.",
        path: 'Settings.NetLogs.NetPay.generate',
        type: 'bool',
        default: false,
      },
      Table: {
        dependency: ['logZoneAnalytics', 'netPaySettings', 'generate', true],
        default: [
          {
            log: 'vcl',
            cutoff: 0.25,
            largerThanCutoff: false,
          },
          {
            log: 'phi',
            cutoff: 0.75,
            largerThanCutoff: false,
          },
          {
            log: 'sw',
            cutoff: 0.65,
            largerThanCutoff: false,
          },
        ],
        header: '',
        columns: [{
          Header: 'Logs',
          accessor: 'log',
          sortable: false,
          type: 'categorical',
          options: logOptions,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
      truthType: {
        display: 'Truth Type',
        subDisplay: '',
        description: 'Specified how to combine the individual property conditions',
        dependency: ['logZoneAnalytics', 'netPaySettings', 'generate', true],
        path: 'Settings.NetLogs.NetPay.truthType',
        type: 'categorical',
        options: [
          { label: 'And', value: 'and' },
          { label: 'Or', value: 'or' },
        ],
        default: 'and',
      }
    },
    mnpSettings: {
      name: 'Uncontacted Net Pay (UNP or MNP) Settings',
      path: '/training/manual/modules/recompletions/settings/uncontactedNetPay',
      useStandoff: {
        display: 'Use Standoff',
        subDisplay: '',
        description: "'If on, then the 'Contact Standoff' distance will be defined and used to truncate identified UNP intervals.",
        path: 'Settings.LogSettings.Special.Mnp.useStandoff',
        type: 'bool',
        default: false,
      },
      contactStandoff: {
        display: 'Contact Standoff',
        subDisplay: '',
        description: `Contact standoff for new opportunities.`,
        dependency: ['logZoneAnalytics', 'mnpSettings', 'useStandoff', true],
        path: 'Settings.Pud.Others.contactStandoff',
        type: 'numerical',
        unit: 'meters',
        default: 35,
        restrictions: [
          {
            type: 'above',
            min: 0,
          },
        ]
      },
      maxSeparationDistance: {
        display: 'Max Separation Distance',
        subDisplay: '',
        description: `If UNP intervals within the same zone are separated by more than specified distance (also MD), they are classified as separate opportunities.`,
        path: 'Settings.LogSettings.Special.Mnp.maxSeparationDistance',
        type: 'numerical',
        unit: 'meters',
        default: 999999,
        restrictions: [
          {
            type: 'above',
            min: 0,
          },
        ]
      },
      sigmaPnlLogs: {
        display: 'Use Sigma PNL Logs',
        subDisplay: '',
        description: 'If on, sigma log will be used to filter UNP.',
        path: 'Settings.LogSettings.Special.Mnp.Sigma.use',
        type: 'bool',
        default: false,
      },
      // Removed On 2/3/21 - WY
      // mergeSigmaCurves: {
      //   display: 'Merge Multiple Sigma Vintages within a well',
      //   subDisplay: '',
      //   description: 'If on, sigma curves will be merged using a date priority. If off, the assumption is that you have imported a sigma curve that has already been merged.',
      //   path: 'Settings.LogSettings.Special.Mnp.Sigma.mergeCurves',
      //   dependency: ['logZoneAnalytics', 'mnpSettings', 'sigmaPnlLogs', true],
      //   type: 'bool',
      //   default: false,
      // },
      Table: {
        dependency: ['logZoneAnalytics', 'mnpSettings', 'mergeSigmaCurves', true],
        default: [],
        header: '',
        columns: [{
          Header: 'Curve Names',
          accessor: 'curveNames',
          sortable: false,
          type: 'input',

        }, {
          Header: 'Curve Date (Year)',
          accessor: 'curveData',
          sortable: false,
          type: 'input',
        }],
      },
      maxSW: {
        display: 'Max Sigma Value (SW proxy)',
        subDisplay: '',
        description: `Value cutoff to use for filtering UNP.`,
        dependency: ['logZoneAnalytics', 'mnpSettings', 'sigmaPnlLogs', true],
        path: 'Settings.LogSettings.Special.Mnp.Sigma.maxSw',
        type: 'numerical',
        default: 20,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 100,
          },
        ]
      },
      // Removed On 2/3/21 - WY
      // minDate: {
      //   display: 'Oldest Sigma Curve Vintage to Include',
      //   subDisplay: '',
      //   description: `Min date cutoff to use for sigma logs that filter UNP.`,
      //   dependency: ['logZoneAnalytics', 'mnpSettings', 'sigmaPnlLogs', true],
      //   path: 'Settings.LogSettings.Special.Mnp.Sigma.minDate',
      //   type: 'numerical',
      //   unit: 'year',
      //   default: 2006,
      // },
      useSimulationModel: {
        display: 'Use Oil Saturation Grids',
        subDisplay: '',
        description: 'Use oil saturation property to filter MNP',
        path: 'Settings.LogSettings.Special.Mnp.Sim.use',
        type: 'bool',
        default: false,
      },
      createPseudoLog: {
        display: 'Create Oil Saturation Pseudo Logs',
        subDisplay: '',
        description: 'If on, this creates a pseudo log based on the So model. This requires that a model with a So property has been imported.',
        dependency: ['logZoneAnalytics', 'mnpSettings', 'useSimulationModel', true],
        path: 'Settings.LogSettings.Special.Mnp.Sim.createPseudoLog',
        type: 'bool',
        default: true,
      },
      minSo: {
        display: 'MinSo',
        subDisplay: '',
        description: `Min So cutoff to use for filtering UNP`,
        dependency: ['logZoneAnalytics', 'mnpSettings', 'useSimulationModel', true],
        path: 'Settings.LogSettings.Special.Mnp.Sim.minSo',
        type: 'numerical',
        default: 0.3,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      }
    },
    pnpSettings: {
      name: 'Perforated Net Pay (PNP) Settings',
      path: '/training/manual/modules/recompletions/settings/perforatedNetPay',
      trackingMethod: {
        display: 'PNP Flagging Method',
        subDisplay: '',
        description: "Options for limiting or extending the net pay that is assumed to be contacted or drained by existing perforations. In reservoirs with a strong aquifer, 'Extended Below' may be appropriate. In reservoirs with gas expansion, 'Extended Above' may be appropriate.",
        path: 'Settings.LogSettings.Special.Pnp.method',
        type: 'categorical',
        options: [
          { label: 'Limited', value: 'limited' },
          { label: 'Extended Above', value: 'extendedAbove' },
          { label: 'Extended Below', value: 'extendedBelow' },
        ],
        default: 'limited',
      },
      includePerfs: {
        display: 'Include Existing Perfs as PNP',
        subDisplay: '',
        description: 'If on, this adds perforated intervals to the PNP log. This is often recommended in cases with uncertainty in the net pay definition.',
        path: 'Settings.LogSettings.Special.Pnp.includePerfs',
        type: 'bool',
        default: true,
      },
      minBaffleThickness: {
        display: 'Min Baffle Thickness',
        subDisplay: '',
        description: `Minimum baffle thickness to define extent of PNP (also creates 'baffles' based on seal log or non-pay otherwise)`,
        path: 'Settings.LogSettings.Special.Pnp.minBaffleThickness',
        type: 'numerical',
        default: 0,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      generateNetSeal: {
        display: 'Generate Net Seal Logs',
        subDisplay: '',
        description: "If you prefer to generate a net seal log, you can set the definition of the net seal log here. After switching this to 'On', set the net seal definition by selecting the logs, cutoffs, and direction for the cutoffs in the rows that appear below.",
        path: 'Settings.NetLogs.NetSeal.generate',
        type: 'bool',
        default: false,
      },
      Table: {
        dependency: ['logZoneAnalytics', 'pnpSettings', 'generateNetSeal', true],
        default: [
          {
            log: 'vcl',
            cutoff: 0.25,
            largerThanCutoff: false,
          },
          {
            log: 'phi',
            cutoff: 0.75,
            largerThanCutoff: false,
          },
          {
            log: 'sw',
            cutoff: 0.65,
            largerThanCutoff: false,
          },
        ],
        header: '',
        columns: [{
          Header: 'Logs',
          accessor: 'log',
          sortable: false,
          type: 'categorical',
          options: logOptions,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
      truthType: {
        display: 'Truth Type',
        subDisplay: '',
        description: 'Specified how to combine the individual property conditions',
        dependency: ['logZoneAnalytics', 'pnpSettings', 'generateNetSeal', true],
        path: 'Settings.NetLogs.NetSeal.truthType',
        type: 'categorical',
        options: [
          { label: 'And', value: 'and' },
          { label: 'Or', value: 'or' },
        ],
        default: 'and',
      }
    },
    step: 3
  },
  attributeMapping: {
    attributeMapping: {
      name: 'Attribute Mapping',
      path: '/training/manual/modules/recompletions/settings/attributeMapping',
      recentWctMethod: {
        display: 'Recent Water Cut Mapping Algoithm',
        subDisplay: '',
        description: 'Maps data within recent time frame (defined in Data Integration settings). You may choose from any of these 5 gridding algorithms. Please refer to the User Manual for more detail regarding these algorithms.',
        type: 'categorical',
        options: mappingMethodOptions,
        default: 'nearest'
      },
      recentGorMethod: {
        display: 'Recent GOR Mapping Algorithm',
        subDisplay: '',
        description: 'Maps data within recent time frame (defined in Data Integration settings). You may choose from any of these 5 gridding algorithms. Please refer to the User Manual for more detail regarding these algorithms.',
        type: 'categorical',
        options: mappingMethodOptions,
        default: 'nearest'
      },
      cumProductionMethod: {
        display: 'Cumulative Production Mapping Method',
        subDisplay: '',
        description: 'Maps data within recent time frame (defined in Data Integration settings). You may choose from any of these 5 gridding algorithms. Please refer to the User Manual for more detail regarding these algorithms.',
        type: 'categorical',
        options: mappingMethodOptions,
        default: 'natural'
      },
      currentProductionMethod: {
        display: 'Current Production Mapping Method',
        subDisplay: '',
        description: 'Maps data within recent time frame (defined in Data Integration settings). You may choose from any of these 5 gridding algorithms. Please refer to the User Manual for more detail regarding these algorithms.',
        type: 'categorical',
        options: mappingMethodOptions,
        default: 'natural'
      },
    },
    step: 4
  },
  drainageAnalysis: {
    generalSettings: {
      name: 'General Settings',
      path: '/training/manual/modules/recompletions/settings/generalSettings',
      singleZone: {
        display: 'Evaluate Reservoir as a single zone (tank)',
        subDisplay: '',
        description: 'If on, drainage analysis assumes all zones are connected and treats them as a single zone.',
        path: 'Settings.Drainage.singleZone',
        type: 'bool',
        default: false,
      },
      method: {
        display: 'Default Method',
        subDisplay: '',
        description: "Volumetric' is recommended for most reservoirs as it takes the produced or EUR volumes to estimate the drainage areas.",
        path: 'Settings.Drainage.method',
        type: 'categorical',
        options: [{ label: 'Volumetric', value: 'volumetric' }, { label: 'Rate Proxy', value: 'rate-proxy' }],
        default: 'volumetric'
      },
      overlapDI: {
        display: 'Drainage Interference',
        subDisplay: '',
        description: 'If pessimistic, the analysis will add up the drainage indices (DI) at each area. If optimistic, the analysis will take the highest DI for that area.',
        dependency: ['drainageAnalysis', 'generalSettings', 'method', 'volumetric'],
        path: 'Settings.Drainage.overlapDI',
        type: 'categorical',
        options: [{ label: 'Optimistic', value: 'optimistic' }, { label: 'Pessimistic', value: 'pessimistic' }],
        default: 'optimistic'
      },
      attribute: {
        display: 'Production Attribute',
        subDisplay: '',
        description: "This is the production attribute that will be mapped. If 'EUR' is chosen, then the EUR drainage radii will be mapped. If 'Cumulative Production' is chosen, then drainage radii will only reflect the current cumulative produced oil.",
        path: 'Settings.Drainage.attribute',
        type: 'categorical',
        options: [{ label: 'EUR', value: 'eur' }, { label: 'Cumulative Production', value: 'cumProduction' }],
        default: 'eur'
      },
      maxDICutoff: {
        display: 'Max Drainage Index (Di) Cutoff',
        subDisplay: '',
        description: `Opportunities located in areas with DI > cutoff will be discarded`,
        path: 'Settings.Drainage.maxDICutoff',
        type: 'numerical',
        default: 0.5,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      },
      useFaciesTrackingConditions: {
        display: 'Use Facies Tracking Conditions',
        subDisplay: '',
        dependency: ['drainageAnalysis', 'generalSettings', 'method', 'volumetric'],
        description: 'If on, this option will constrain drainage to the facies interpretation (instead of assuming circular drainage).',
        path: 'Settings.Drainage.Tracking.use',
        type: 'bool',
        default: false,
      },
      weighting: {
        display: 'Weighting',
        subDisplay: '',
        description: 'Distance, permeability, or the ratio of permeability to distance',
        dependency: [['drainageAnalysis', 'generalSettings', 'method', 'volumetric'], ['drainageAnalysis', 'generalSettings', 'useFaciesTrackingConditions', true]],
        path: 'Settings.Drainage.Tracking.weighting',
        type: 'categorical',
        options: [{ label: 'Distance', value: 'D' }, { label: 'Permeability', value: 'K' }, { label: 'Permeability/Distance', value: 'K2D' }],
        default: 'D'
      },
      generateFaciesMap: {
        display: 'Generate Facies Map',
        subDisplay: '',
        description: 'If facies are not available, this option creates facies grids using the settings below.',
        dependency: [['drainageAnalysis', 'generalSettings', 'method', 'volumetric'], ['drainageAnalysis', 'generalSettings', 'useFaciesTrackingConditions', true]],
        path: 'Settings.Drainage.Tracking.Facies.generate',
        type: 'bool',
        default: false,
      },
      Table: {
        dependency: [['drainageAnalysis', 'generalSettings', 'method', 'volumetric'], ['drainageAnalysis', 'generalSettings', 'useFaciesTrackingConditions', true], ['drainageAnalysis', 'generalSettings', 'generateFaciesMap', true]],
        default: [
          {
            grid: 'currentHcpt',
            cutoff: 3,
            largerThanCutoff: true,
          },
        ],
        header: '',
        columns: [{
          Header: 'Grid',
          accessor: 'grid',
          sortable: false,
          type: 'categorical',
          options: gridOptions,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
      truthType: {
        display: 'Truth Type',
        subDisplay: '',
        description: 'Specified how to combine the individual property conditions',
        dependency: [['drainageAnalysis', 'generalSettings', 'method', 'volumetric'], ['drainageAnalysis', 'generalSettings', 'useFaciesTrackingConditions', true], ['drainageAnalysis', 'generalSettings', 'generateFaciesMap', true]],
        path: 'Settings.Drainage.Tracking.Facies.truthType',
        type: 'categorical',
        options: [
          { label: 'And', value: 'and' },
          { label: 'Or', value: 'or' },
        ],
        default: 'and',
      },
      faciesToTrack: {
        display: 'Facies to Track',
        subDisplay: '',
        description: 'Specifies the list of facies IDs to track. If facies grid is boolean, use [1].',
        dependency: [['drainageAnalysis', 'generalSettings', 'method', 'volumetric'], ['drainageAnalysis', 'generalSettings', 'useFaciesTrackingConditions', true], ['drainageAnalysis', 'generalSettings', 'generateFaciesMap', false]],
        path: 'Settings.Drainage.Tracking.faciesToTrack',
        type: 'categorical',
        multi: true,
        options: [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 }, { label: '6', value: 6 }], // TODO WHAT ARE THESE OPTIONS
        default: [2]
      },
    },
    drainageRadiusCorrection: {
      name: 'Drainage Radius Correction',
      path: '/training/manual/modules/recompletions/settings/drainageRadiusCorrection',
      radiusReduction: {
        display: 'Radius Reduction Factor',
        subDisplay: 'Any whole number or decimal between 0 and 1',
        description: `Radius reduction factor may be used to decrease all calculated drainage radii. A factor of 0.1 reduces the radius by 10%.`,
        path: 'Settings.Drainage.radiusReduction',
        type: 'numerical',
        default: 0,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      },
      minHcptPnp: {
        display: 'Min Hcpt Pnp',
        subDisplay: '',
        description: `Min hcptPnp to consider for radius calculation (very low values create artificially large radius)`,
        path: 'Settings.Drainage.minHcptPnp',
        type: 'numerical',
        unit: 'meters',
        default: 0.1,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0
          }
        ]
      },
      maxRadiusPercentile: {
        display: 'Max Radius Percentile',
        subDisplay: '',
        description: `Radiuses are capped at the provided percentile (to limit outliers). For example, if 0.9 is chosen, then any radii higher than the 90th percentile of the distribution will be capped at the value of the 90th percentile. A value of 0.99 is often recommended to eliminate extreme outliers to the distribution.`,
        path: 'Settings.Drainage.maxRadiusPercentile',
        type: 'numerical',
        default: 0.9,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      },
      smoothIndexes: {
        display: 'Smooth Indexes',
        subDisplay: '',
        description: 'Flag to activate smoothing (2D Drainage)',
        path: 'Settings.Drainage.smoothIndexs',
        type: 'bool',
        default: false,
      },
    },
    step: 5
  },
  targetSearch: {
    targetZones: {
      name: 'Target Zones',
      path: '/training/manual/modules/recompletions/settings',
      shouldTargetZones:{
        display: 'Zones where the new horizontal targets could be placed within.',
        subDisplay: '',
        description: 'Manually specify target zones.  If off, all zones will be selected.',
        path: 'Settings.Recompletions.shouldTargetZones',
        default: false,
        type: 'bool',
      },
      targetZones: {
        display: 'Target Zones',
        subDisplay: '',
        description: 'TODO - add description',
        path: 'Settings.Recompletions.targetZones',
        type: 'categorical',
        default: ['all'],
        multi: true,
        dependency: ['targetSearch', 'targetZones', 'shouldTargetZones', true],
        options: [],
      },
    },
    step: 6
  },
  productionForecast: {
    productionForecast: {
      name: 'Production Forecast',
      helperText: 'Toggle the settings that you want to use.',
      path: '/training/manual/modules/recompletions/settings/initialProductionRateForecast',
      shouldUseTC: {
        display: 'Should Use Type Curve (TC)',
        subDisplay: '',
        description: 'Creates a zone-specific type curve from the allocated initial production (IP) rates. Requires having zone level historical production data (the Engineering Analytics step will allocate the production data to the zone level).',
        type: 'bool',
        default: false,
      },
      shouldUseSTI: {
        display: 'Should Use Spatial Temporal Interpolation (STI)',
        subDisplay: '',
        description: 'Initial zone-allocated oil rates of an analog neighborhood are spatially and temporally weighted and averaged around each opportunity. Requires having initial production (IP) rates from offset producers within the time and spatial range defined in the settings.',
        type: 'bool',
        default: false,
      },
      shouldUseTNSTI: {
        display: 'Should Use Thickness Normalized Spacial Temporal Interpolation (TNSTI)',
        subDisplay: '',
        description: 'Initial zone-allocated oil rates of an analog neighborhood are spatially and temporally weighted and averaged and then normalized by a thickness attribute around each opportunity. Requires having initial production rates from offset producers within the time and spatial range defined in the settings. Also, the normalization attribute (PNP, PNP HCPT, perf length, etc.) needs to exist for the opportunity under evaluation.',
        type: 'bool',
        default: true,
      },
      shouldUseNFR: {
        display: 'Should Use Neighborhood Fluid Rates (NFR)',
        subDisplay: '',
        description: 'Current zone-allocated fluid rates in the neighborhood around the opportunity (neighborhood size or distance is user-defined) are normalized by perforation length to create fluid rate indices (FRIs). Requires having current production data within the spatial range defined in the settings.',
        type: 'bool',
        default: false,
      },
      shouldUseTNNFR: {
        display: 'Should Use Thickness Normalized Neighborhood Fluid Rates (TNNFR)',
        subDisplay: '',
        description: 'Current zone-allocated fluid rates in the neighborhood around the opportunity (neighborhood size or distance is user-defined) are normalized by perforation length to create fluid rate indices (FRIs). These are subsequently normalized by the thickness attribute selected. Requires having current production data within the spatial range defined in the settings. Also, the normalization attribute (PNP, PNP HCPT, perf length, etc.) needs to exist for the opportunity under evaluation.',
        type: 'bool',
        default: true,
      },
      shouldUseDARCY: {
        display: 'Should Use DARCY',
        subDisplay: '',
        description: 'Physics-based method for vertical opportunities. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: permeability, viscosity, FVF, wellbore radius, fBHP.',
        type: 'bool',
        default: false
      },
      shouldUseSML: {
        display: 'Apply Speedwise Machine Learning (SML)',
        description: 'Use SpeedWise Machine Learning for opportunities\' production forecast.',
        type: 'bool',
        default: false,
      },
      smlAPIKey: {
        display: 'Speedwise Machine Learning (SML) API Key',
        description: undefined,
        dependency: ['productionForecast', 'productionForecast', 'shouldUseSML', true],
        path: 'Settings.Forecast.SMLAPI',
        type: 'string',
      },
      primaryMethod: {
        display: 'Primary Method',
        subDisplay: '',
        description: `The primary method is what will be used to display in visualizations and in subsequent filtering if chosen. However, other forecast methods toggled on will be evaluated and then will be available in the CSV download so you can compare the different forecast methods outside of the platform.`,
        path: 'Settings.Forecast.primaryMethod',
        type: 'categorical',
        options: [
          { label: 'SML', value: 'SML' },
          { label: 'TC', value: 'TC' },
          { label: 'STI', value: 'STI' },
          { label: 'TNSTI', value: 'TNSTI' },
          { label: 'NFR', value: 'NFR' },
          { label: 'TNNFR', value: 'TNNFR' },
          { label: 'DARCY', value: 'DARCY' },
        ],
        default: 'TNNFR'
      },

    },
    settingsforThicknessNormalizedMethods: {
      name: 'Settings for Thickness Normalized Methods',
      path: '/training/manual/modules/recompletions/settings/thicknessNormalized',
      normalizationAttribute: {
        display: 'Normalization Attribute',
        subDisplay: '',
        description: 'Thickness attribute used for normalization',
        path: 'Settings.Forecast.Normalization.attribute',
        type: 'categorical',
        options: [
          { label: 'PNP', value: 'pnp' },
          { label: 'Perf Length', value: 'perfLength' },
          { label: 'PNP Hcpt', value: 'pnpHcpt' },
        ],
        default: 'pnp'
      },
      expandNeighborhood: {
        display: 'Expand Neighborhood',
        subDisplay: '',
        description: 'If true, neighborhood wells missing norm attribute will be replaced with other neighborhood wells (when spatial radius is based on N points)',
        path: 'Settings.Forecast.Normalization.expandNeighborhood',
        type: 'bool',
        default: false,
      },
    },
    darcyParameters: {
      name: 'Darcy Parameters',
      path: '/training/manual/modules/recompletions/settings/darcyParameters',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseDARCY', true],
      k: {
        display: 'Default Permeability',
        subDisplay: '',
        description: `Default permeability.`,
        path: 'Settings.Forecast.Darcy.k',
        type: 'numerical',
        unit: 'md',
        default: 10,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      settingsHasPriority: {
        display: 'Always Use Default Permeability',
        subDisplay: '',
        description: 'If on, sets all permeability values the default.',
        path: 'Settings.Forecast.Darcy.UserPriority.k',
        type: 'bool',
        default: false,
      },
      viscosity: {
        display: 'Viscosity',
        subDisplay: '',
        description: `Default viscosity.`,
        path: 'Settings.Forecast.Darcy.viscosity',
        type: 'numerical',
        unit: 'cp',
        default: 10,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      fvf: {
        display: 'Formation Volume Factor (FVF)',
        subDisplay: '',
        description: `Default formation volume factor (FVF).`,
        path: 'Settings.Forecast.Darcy.fvf',
        type: 'numerical',
        unit: 'rb/stb',
        default: 1.19,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      wellboreRadius: {
        display: 'Wellbore Radius',
        subDisplay: '',
        description: `Default wellbore radius.`,
        path: 'Settings.Forecast.Darcy.wellboreRadius',
        type: 'numerical',
        unit: 'm',
        default: 0.091,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      resPressure: {
        display: 'Initial Reservoir Pressure',
        subDisplay: '',
        description: `Default reservoir pressure.`,
        path: 'Settings.Forecast.Darcy.resPressure',
        type: 'numerical',
        unit: 'psi',
        default: 1642,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      fbhp: {
        display: 'Flowing Bottomhole Pressure',
        subDisplay: '',
        description: `Default flowing bottomhole pressure (FBHP).`,
        path: 'Settings.Forecast.Darcy.fbhp',
        type: 'numerical',
        unit: 'psi',
        default: 500,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      lookbackMonths: {
        display: 'Lookback Months',
        subDisplay: '',
        description: `Used to look back for well pressure data.`,
        path: 'Settings.Forecast.Darcy.lookBackMonths',
        type: 'numerical',
        unit: 'month(s)',
        default: 3,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      usePudSpacing: {
        display: 'Use New Drill spacing',
        subDisplay: '',
        description: 'If off, uses the estimated drainage from current wells.',
        path: 'Settings.Forecast.Darcy.usePudSpacing',
        type: 'bool',
        default: false,
      },
      pudSpacing: {
        display: 'New Drill Spacing',
        subDisplay: '',
        description: `Area to use to estimate the drainage radius for the Darcy equation.`,
        dependency: ['productionForecast', 'darcyParameters', 'usePudSpacing', true],
        path: 'Settings.Pud.Spacing.external',
        type: 'numerical',
        unit: 'meters',
        default: 18,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      }
    },
    neighborhoodSettings: {
      name: 'Neighborhood Settings',
      path: '/training/manual/modules/recompletions/settings/neighborhoodSettings',
      temporalScale: {
        display: 'Temporal Scale',
        subDisplay: '',
        description: `Number of recent years`,
        path: 'Settings.Neighborhood.Temporal.globalScale',
        type: 'numerical',
        unit: 'year',
        default: 15,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      type: {
        display: 'Spatial Type',
        subDisplay: '',
        description: 'This setting determines whether the neighborhood data is restricted to neighbors within a certain distance (radius) or if the neighborhood is restricted to a certain number of closest neighbors, regardless of distance (points).',
        path: 'Settings.Neighborhood.Spatial.type',
        type: 'categorical',
        options: [
          { label: 'Radius', value: 'radius' },
          { label: 'Points', value: 'points' },
        ],
        default: 'points'
      },
      spatialScale: {
        display: 'Spatial Scale',
        subDisplay: '',
        description: `Defines the areal extent of the neighborhood at zone level.`,
        unit: 'Radius size (meters) or number of points',
        path: 'Settings.Neighborhood.Spatial.globalScale',
        type: 'numerical',
        default: 15,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      matchWellGroup: {
        display: 'Match Well Group',
        subDisplay: '',
        description: 'If on, this will limit neighborhood wells based on the options selected in well groups to set.',
        path: 'Settings.Neighborhood.matchWellGroup',
        type: 'bool',
        default: false,
      },
      wellGroupSetsToMatch: {
        display: 'Well Group Sets to Match',
        subDisplay: '',
        description: 'Select which attributes to match on for neighborhood wells.',
        dependency: ['productionForecast', 'neighborhoodSettings', 'matchWellGroup', true],
        path: 'Settings.Neighborhood.wellGroupSetsToMatch',
        type: 'categorical',
        multi: true,
        options: [
          { label: 'Well Type', value: 'Well Type' },
        ],
        default: ['Well Type']
      },
      useZoneAllocatedRates: {
        display: 'Use Zone Allocated Rates',
        subDisplay: '',
        description: 'If on, uses the allocated production rates specific to each zone. Incorrect allocation will almost certainly lead to wrong initial rate forecasts when using statistical methods. It is recommended to use PLT data for allocation when available.',
        path: 'Settings.Neighborhood.useZoneAllocatedRate',
        type: 'bool',
        default: true,
      },
      neighborhoodPerformance: {
        display: 'Neighborhood Performance',
        subDisplay: '',
        description: `This defines the neighborhood to use to calculate the probability of exceedance (POE), which is a statistical metric describing the probability that a particular value (in this case, the predicted IP) will be met or exceeded.`,
        path: 'Settings.POE.neighborhoodPerformance',
        type: 'categorical',
        options: [
          { label: 'Initial Rates', value: 'initialRates' },
          { label: 'Current Rates', value: 'currentRates' },
        ],
        default: 'currentRates'
      },
    },
    interpolationSettings: {
      name: 'Interpolation Settings',
      path: '/training/manual/modules/recompletions/settings/interpolationSettings',
      timeWeight: {
        display: 'Time Weight',
        subDisplay: '',
        description: `Weight of time dimension. For example, if this is set to 0.5 and 'Distance Weight' is also 0.5 then time and distance will be weighted equally.`,
        path: 'Settings.Interpolation.timeWeight',
        type: 'numerical',
        default: 0.5,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          },
          {
            type: 'codependentSettingsSum',
            dependency: ['productionForecast', 'interpolationSettings', 'distanceWeight'],
            total: 1,
          }
        ]
      },
      distanceWeight: {
        display: 'Distance Weight',
        subDisplay: '',
        description: `Weight of spatial dimension. For example, if this is set to 0.5 and 'Time Weight' is also 0.5 then time and distance will be weighted equally.`,
        path: 'Settings.Interpolation.distanceWeight',
        type: 'numerical',
        default: 0.5,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          },
          {
            type: 'codependentSettingsSum',
            dependency: ['productionForecast', 'interpolationSettings', 'timeWeight'],
            total: 1,
          }
        ]
      },
      method: {
        display: 'Method',
        subDisplay: '',
        description: 'Interpolation weighting function. Inverse distance weighting (IDW) is a type of deterministic method for multivariate interpolation with a known scattered set of points.',
        path: 'Settings.Interpolation.method',
        type: 'categorical',
        options: [
          { label: 'IDW', value: 'idw' },
          { label: 'Gaussian', value: 'gaussian' },
        ],
        default: 'gaussian'
      },
    },
    step: 6
  },
  opportunityFiltering: {
    filterSettings: {
      name: 'Filter Settings',
      path: '/training/manual/modules/recompletions/settings/opportunityFiltering',
      Table: {
        default: [{
          filterAttributes: 'predictedIp',
          filterValues: 5
        }],
        header: '',
        columns: [{
          Header: 'Filter Attributes',
          accessor: 'filterAttributes',
          sortable: false,
          type: 'categorical',
          options: attributeOptions,

        }, {
          Header: 'Filter Values',
          accessor: 'filterValues',
          sortable: false,
          type: 'numerical',
        }],
      },
    },
    projectionSettings: {
      name: 'Projection Settings',
      path: '/training/manual/modules/recompletions/settings/opportunityFiltering',
      width: {
        display: 'Width',
        subDisplay: '',
        description: `Projection width (i.e. wells within this distance of a cross section will project onto the line of cross section).`,
        path: 'Settings.Figures.ModelSection.width',
        type: 'numerical',
        unit: 'meter(s)',
        default: 300,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      length: {
        display: 'Length',
        subDisplay: '',
        description: `Length of the cross sections that will show in the Visualizations.`,
        path: 'Settings.Figures.ModelSection.length',
        type: 'numerical',
        unit: 'meter(s)',
        default: 4000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
    },
    step: 7
  }
}


export const VerticalsSettingsMap = {
  engineeringAnalytics: RecompletionsSettingsMap.engineeringAnalytics,
  logZoneAnalytics: {
    netPaySettings: {
      name: 'Net Pay Settings',
      path: '/training/manual/modules/recompletions/settings/netPay',
      generate: {
        display: 'Generate',
        subDisplay: '',
        description: "If you prefer to generate a net pay log instead of importing it, you can set the definition of the net pay log here. After switching this to 'On', set the net pay definition by selecting the logs, cutoffs, and direction for the cutoffs in the rows that appear below. Please make sure the logs you select to define your net pay are also logs you have defined for this case (check this in Global settings/Log Alias). Please also check the ranges of your log values so you set the appropriate cutoff. Most porosity and sw logs scale from 0 to 1, but occasionally they are scaled from 0 to 100.",
        type: 'bool',
        default: false,
      },
      Table: {
        dependency: ['logZoneAnalytics', 'netPaySettings', 'generate', true],
        default: [
          {
            log: 'vcl',
            cutoff: 0.25,
            largerThanCutoff: false,
          },
          {
            log: 'phi',
            cutoff: 0.75,
            largerThanCutoff: false,
          },
          {
            log: 'sw',
            cutoff: 0.65,
            largerThanCutoff: false,
          },
        ],
        header: '',
        columns: [{
          Header: 'Logs',
          accessor: 'log',
          sortable: false,
          type: 'categorical',
          options: logOptions,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
      truthType: {
        display: 'Truth Type',
        subDisplay: '',
        description: 'Specified how to combine the individual property conditions',
        dependency: ['logZoneAnalytics', 'netPaySettings', 'generate', true],
        path: 'Settings.NetLogs.NetPay.truthType',
        type: 'categorical',
        options: [
          { label: 'And', value: 'and' },
          { label: 'Or', value: 'or' },
        ],
        default: 'and',
      }
    },
    pnpSettings: {
      name: 'Perforated Net Pay (PNP) Settings',
      path: '/training/manual/modules/recompletions/settings/perforatedNetPay',
      trackingMethod: {
        display: 'PNP Flagging Method',
        subDisplay: '',
        description: "Options for limiting or extending the net pay that is assumed to be contacted or drained by existing perforations. In reservoirs with a strong aquifer, 'Extended Below' may be appropriate. In reservoirs with gas expansion, 'Extended Above' may be appropriate.",
        path: 'Settings.LogSettings.Special.Pnp.method',
        type: 'categorical',
        options: [
          { label: 'Limited', value: 'limited' },
          { label: 'Extended Above', value: 'extendedAbove' },
          { label: 'Extended Below', value: 'extendedBelow' },
        ],
        default: 'limited',
      },
      includePerfs: {
        display: 'Include Perfs',
        subDisplay: '',
        description: 'If on, this adds perforated intervals to the PNP log. This is often recommended in cases with uncertainty in the net pay definition.',
        path: 'Settings.LogSettings.Special.Pnp.includePerfs',
        type: 'bool',
        default: true,
      },
      minBaffleThickness: {
        display: 'Min Baffle Thickness',
        subDisplay: '',
        description: `Minimum baffle thickness to define extent of PNP (also creates 'baffles' based on seal log or non-pay otherwise)`,
        path: 'Settings.LogSettings.Special.Pnp.minBaffleThickness',
        type: 'numerical',
        default: 0,
      },
      generateNetSeal: {
        display: 'Generate Net Seal Logs',
        subDisplay: '',
        description: "If you prefer to generate a net seal log, you can set the definition of the net seal log here. After switching this to 'On', set the net seal definition by selecting the logs, cutoffs, and direction for the cutoffs in the rows that appear below.",
        path: 'Settings.NetLogs.NetSeal.generate',
        type: 'bool',
        default: false,
      },
      Table: {
        dependency: ['logZoneAnalytics', 'pnpSettings', 'generateNetSeal', true],
        default: [
          {
            log: 'vcl',
            cutoff: 0.25,
            largerThanCutoff: false,
          },
          {
            log: 'phi',
            cutoff: 0.75,
            largerThanCutoff: false,
          },
          {
            log: 'sw',
            cutoff: 0.65,
            largerThanCutoff: false,
          },
        ],
        header: '',
        columns: [{
          Header: 'Logs',
          accessor: 'log',
          sortable: false,
          type: 'categorical',
          options: logOptions,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
      truthType: {
        display: 'Truth Type',
        subDisplay: '',
        description: 'Specified how to combine the individual property conditions',
        dependency: ['logZoneAnalytics', 'pnpSettings', 'generateNetSeal', true],
        path: 'Settings.NetLogs.NetSeal.truthType',
        type: 'categorical',
        options: [
          { label: 'And', value: 'and' },
          { label: 'Or', value: 'or' },
        ],
        default: 'and',
      }
    },
    // simulationModel: {
    //   useSimulationModel: {
    //     display: 'Use Simulation Model',
    //     subDisplay: '',
    //     description: "If on, the current So curve will be used to filter UNP. This So curve comes from simulation results. The recommendation is to have imported a So pseudolog directly as part of the LAS files.",
    //     path: 'Settings.LogSettings.Special.Mnp.Sim.use',
    //     type: 'bool',
    //     default: false,
    //   },
    //   createPseudoLog: {
    //     display: 'Create Pseudo Log',
    //     subDisplay: '',
    //     description: 'If on, the current So curve will be used to filter UNP. This So curve comes from simulation results. The recommendation is to have imported a So pseudolog directly as part of the LAS files.',
    //     dependency: ['logZoneAnalytics', 'simulationModel', 'useSimulationModel', true],
    //     path: 'Settings.LogSettings.Special.Mnp.Sim.createPseudoLog',
    //     type: 'bool',
    //     default: true,
    //   },
    //   minSo: {
    //     display: 'MinSo',
    //     subDisplay: '',
    //     description: `Min So cutoff to use for filtering UNP`,
    //     dependency: ['logZoneAnalytics', 'simulationModel', 'useSimulationModel', true],
    //     path: 'Settings.LogSettings.Special.Mnp.Sim.minSo',
    //     type: 'numerical',
    //     default: 0.3,
    //   }
    // },
    step: 3,
  },
  attributeMapping: RecompletionsSettingsMap.attributeMapping,
  drainageAnalysis: RecompletionsSettingsMap.drainageAnalysis,
  generateGrids: {
    regionOfInterest: {
      name: 'Region Of Interest',
      path: '/training/manual/modules/sweetSpots/settings/mapGeneration',
      Table: {
        default: [],
        header: '',
        columns: [{
          Header: 'X (meters)',
          accessor: 'x',
          sortable: false,
          type: 'numerical',

        }, {
          Header: 'Y (meters)',
          accessor: 'y',
          sortable: false,
          type: 'numerical',
        }],
      },
    },
    spacing: {
      name: 'Spacing',
      path: '/training/manual/modules/sweetSpots/settings/mapGeneration',
      externalSpacing: {
        display: 'Spacing to Existing Wells',
        subDisplay: '',
        description: 'Refers to the minimum distance of a new drill to existing wells.',
        path: 'Settings.Pud.Spacing.external',
        type: 'numerical',
        unit: 'meters',
        default: 18,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      internalSpacing: {
        display: 'Spacing between New Drills',
        subDisplay: '',
        description: 'Refers to the minimum distance of a new drill to other new drills.',
        path: 'Settings.Pud.Spacing.internal',
        type: 'numerical',
        unit: 'meters',
        default: 60,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      useTrajectory: {
        display: 'Use Trajectory',
        subDisplay: '',
        description: 'Specifies whether to create the spacing grid around the entire trajectory of wells, the surface locations of the wells, or the bottomholes of the wells.',
        path: 'Settings.Pud.Spacing.useTrajectory',
        type: 'categorical',
        options: [
          { label: 'Trajectory', value: 'Trajectory' },
          { label: 'Surface', value: 'Surface' },
          { label: 'BottomHole', value: 'BottomHole' },
        ],
        default: 'Trajectory'
      },
    },
    faults: {
      name: 'Faults',
      path: '/training/manual/modules/sweetSpots/settings/mapGeneration',
      // createDtfIndex: {
      //   display: 'Create distance-to-fault (Dtf) Index Maps',
      //   subDisplay: '',
      //   description: 'If on, creates an index grid that will be used to limit new drill locations to either a certain distance away from or close to a fault.',
      //   path: 'Settings.Pud.Faults.createDtfIndex',
      //   type: 'bool',
      //   default: true,
      // },
      stayAway: {
        display: 'Stay Away',
        subDisplay: '',
        description: 'If on, sets the index grid to limit new drills to stay away from faults.',
        path: 'Settings.Pud.Faults.stayAway',
        type: 'bool',
        default: true,
      },
      distanceCutoff: {
        display: 'Fault Distance Cutoff',
        subDisplay: '',
        description: 'Distance cutoff to faults.',
        // dependency: ['generateGrids', 'faults', 'createDtfIndex', true],
        path: 'Settings.Pud.Faults.distanceCutoff',
        type: 'numerical',
        unit: 'meters',
        default: 100,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
    },
    other: {
      name: 'Other',
      path: '/training/manual/modules/sweetSpots/settings/mapGeneration',
      contactStandoff: {
        display: 'Contact Standoff',
        subDisplay: '',
        description: 'Contact standoff for new opportunities.',
        // dependency: ['generateGrids', 'faults', 'createDtfIndex', true],
        path: 'Settings.Pud.Others.contactStandoff',
        type: 'numerical',
        unit: 'meters',
        default: 35,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      targetThicknessCutoff: {
        display: 'Target Thickness Cutoff',
        subDisplay: '',
        description: 'Minimum zone thickness within current oil window.',
        path: 'Settings.Pud.Others.targetThicknessCutoff',
        type: 'numerical',
        unit: 'meters',
        default: 0,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      }
    },
    step: 6,
  },
  generatePOSMap: {
    ranking: {
      name: 'RPOS/Ranking Attributes',
      path: '/training/manual/modules/sweetSpots/settings/sweetSpotRanking',
      Table: {
        default: [
          {
            attribute: 'currentHcpt',
            reverseAttribute: false,
          },
          {
            attribute: 'drainage',
            reverseAttribute: true,
          },
          {
            attribute: 'recentWct',
            reverseAttribute: true,
          },
          {
            attribute: 'targetThickness',
            reverseAttribute: false,
          },
          {
            attribute: 'targetThicknessIndex',
            reverseAttribute: false,
          },
        ],
        header: '',
        columns: [{
          Header: 'Attributes',
          accessor: 'attribute',
          sortable: false,
          type: 'categorical',
          smartOption: 'vertical',
          options: rposOptions,
        }, {
          Header: 'Reverse Attributes',
          accessor: 'reverseAttribute',
          sortable: false,
          type: 'bool',
        }],
      },
    },
    targetZones: {
      name: 'Target Zones',
      path: null,
      shouldTargetZones: {
        display: 'Manually Specify Target Zones',
        subDisplay: '',
        description: 'Manually specify target zones.  If off, all zones will be selected.',
        path: 'Settings.Pud.shouldTargetZones',
        default: false,
        type: 'bool',
      },
      targetZones: {
        display: 'Target Zones',
        subDisplay: '',
        description: 'TODO - add description',
        path: 'Settings.Pud.targetZones',
        type: 'categorical',
        default: ['all'],
        multi: true,
        dependency: ['generatePOSMap', 'targetZones', 'shouldTargetZones', true],
        options: [],
      },
    },
    step: 7,
  },
  targetSearch: {
    targetSearch: {
      name: 'Target Search',
      path: '/training/manual/modules/sweetSpots/settings/targetSearch',
      numberOfNewDrills: {
        display: 'Number of New Drills',
        subDisplay: '',
        description: 'Specifies the number of new drills to look for.',
        path: 'Settings.Pud.nLocations',
        type: 'numerical',
        unit: '',
        default: 100,
        restrictions: [
          {
            type: 'integer'
          },
          {
            type: 'aboveOrEqual',
            min: 1,
          }
        ]
      }
    },
    zoneLevelTargetFilters: {
      name: 'Zone-Level Target Filters',
      path: 'TODO - add this',
      Table: {
        default: [],
        header: '',
        columns: [{
          Header: 'Filter Attributes',
          accessor: 'filterAttribute',
          sortable: false,
          type: 'categorical',
          options: attributeOptions,

        }, {
          Header: 'Filter Values',
          accessor: 'filterValue',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },

    },
    step: 8
  },
  productionForecast: {
    productionForecast: {
      name: 'Production Forecast',
      helperText: 'Toggle the settings that you want to use.',
      path: '/training/manual/modules/recompletions/settings/initialProductionRateForecast',
      shouldUseTC: {
        display: 'Type Curve (TC)',
        subDisplay: '',
        description: 'Creates a zone-specific type curve from the allocated initial production (IP) rates. Requires having zone level historical production data (the Engineering Analytics step will allocate the production data to the zone level).',
        type: 'bool',
        default: false,
      },
      shouldUseSTI: {
        display: 'Spatial Temporal Interpolation (STI)',
        subDisplay: '',
        description: 'Initial zone-allocated oil rates of an analog neighborhood are spatially and temporally weighted and averaged around each opportunity. Requires having initial production (IP) rates from offset producers within the time and spatial range defined in the settings.',
        type: 'bool',
        default: false,
      },
      shouldUseTNSTI: {
        display: 'Thickness Normalized Spacial Temporal Interpolation (TNSTI)',
        subDisplay: '',
        description: 'Initial zone-allocated oil rates of an analog neighborhood are spatially and temporally weighted and averaged and then normalized by a thickness attribute around each opportunity. Requires having initial production rates from offset producers within the time and spatial range defined in the settings. Also, the normalization attribute (PNP, PNP HCPT, perf length, etc.) needs to exist for the opportunity under evaluation.',
        type: 'bool',
        default: true,
      },
      shouldUseNFR: {
        display: 'Neighborhood Fluid Rates (NFR)',
        subDisplay: '',
        description: 'Current zone-allocated fluid rates in the neighborhood around the opportunity (neighborhood size or distance is user-defined) are normalized by perforation length to create fluid rate indices (FRIs). Requires having current production data within the spatial range defined in the settings.',
        type: 'bool',
        default: false,
      },
      shouldUseTNNFR: {
        display: 'Thickness Normalized Neighborhood Fluid Rates (TNNFR)',
        subDisplay: '',
        description: 'Current zone-allocated fluid rates in the neighborhood around the opportunity (neighborhood size or distance is user-defined) are normalized by perforation length to create fluid rate indices (FRIs). These are subsequently normalized by the thickness attribute selected. Requires having current production data within the spatial range defined in the settings. Also, the normalization attribute (PNP, PNP HCPT, perf length, etc.) needs to exist for the opportunity under evaluation.',
        type: 'bool',
        default: true,
      },
      shouldUseDARCY: {
        display: 'DARCY',
        subDisplay: '',
        description: 'Physics-based method for vertical opportunities. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: permeability, viscosity, FVF, wellbore radius, fBHP.',
        type: 'bool',
        default: false
      },
      shouldUseSML: {
        display: 'Apply Speedwise Machine Learning (SML)',
        description: 'Use SpeedWise Machine Learning for opportunities\' production forecast.',
        type: 'bool',
        default: false,
      },
      smlAPIKey: {
        display: 'Speedwise Machine Learning (SML) API Key',
        description: undefined,
        dependency: ['productionForecast', 'productionForecast', 'shouldUseSML', true],
        path: 'Settings.Forecast.SMLAPI',
        type: 'string',
      },
      primaryMethod: {
        display: 'Primary Method',
        subDisplay: '',
        description: `The primary method is what will be used to display in visualizations and in subsequent filtering if chosen. However, other forecast methods toggled on will be evaluated and then will be available in the CSV download so you can compare the different forecast methods outside of the platform.`,
        path: 'Settings.Forecast.primaryMethod',
        type: 'categorical',
        options: [
          { label: 'SML', value: 'SML' },
          { label: 'TC', value: 'TC' },
          { label: 'STI', value: 'STI' },
          { label: 'TNSTI', value: 'TNSTI' },
          { label: 'NFR', value: 'NFR' },
          { label: 'TNNFR', value: 'TNNFR' },
          { label: 'DARCY', value: 'DARCY' },
        ],
        default: 'TNNFR'
      },
    },
    settingsforThicknessNormalizedMethods: {
      name: 'Settings for Thickness Normalized Methods',
      path: '/training/manual/modules/recompletions/settings/thicknessNormalized',
      normalizationAttribute: {
        display: 'Normalization Attribute',
        subDisplay: '',
        description: 'Thickness attribute used for normalization',
        path: 'Settings.Forecast.Normalization.attribute',
        type: 'categorical',
        options: [
          { label: 'PNP', value: 'pnp' },
          { label: 'Perf Length', value: 'perfLength' },
          { label: 'PNP Hcpt', value: 'pnpHcpt' },
        ],
        default: 'pnp'
      },
      expandNeighborhood: {
        display: 'Expand Neighborhood',
        subDisplay: '',
        description: 'If true, neighborhood wells missing norm attribute will be replaced with other neighborhood wells (when spatial radius is based on N points)',
        path: 'Settings.Forecast.Normalization.expandNeighborhood',
        type: 'bool',
        default: false,
      },
    },
    darcyParameters: {
      name: 'Darcy Parameters',
      path: '/training/manual/modules/recompletions/settings/darcyParameters',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseDARCY', true],
      k: {
        display: 'Default Permeability',
        subDisplay: '',
        description: `Default permeability.`,
        path: 'Settings.Forecast.Darcy.k',
        type: 'numerical',
        unit: 'md',
        default: 10,
      },
      settingsHasPriority: {
        display: 'Always Use Default Permeability',
        subDisplay: '',
        description: 'If on, sets all permeability values the default.',
        path: 'Settings.Forecast.Darcy.UserPriority.k',
        type: 'bool',
        default: false,
      },
      viscosity: {
        display: 'Viscosity',
        subDisplay: '',
        description: `Default viscosity.`,
        path: 'Settings.Forecast.Darcy.viscosity',
        type: 'numerical',
        unit: 'cp',
        default: 10,
      },
      fvf: {
        display: 'Formation Volume Factor (FVF)',
        subDisplay: '',
        description: `Default formation volume factor (FVF).`,
        path: 'Settings.Forecast.Darcy.fvf',
        type: 'numerical',
        unit: 'rb/stb',
        default: 1.19,
      },
      wellboreRadius: {
        display: 'Wellbore Radius',
        subDisplay: '',
        description: `Default wellbore radius.`,
        path: 'Settings.Forecast.Darcy.wellboreRadius',
        type: 'numerical',
        unit: 'm',
        default: 0.091,
      },
      resPressure: {
        display: 'Initial Reservoir Pressure',
        subDisplay: '',
        description: `Default reservoir pressure.`,
        path: 'Settings.Forecast.Darcy.resPressure',
        type: 'numerical',
        unit: 'psi',
        default: 1642,
      },
      fbhp: {
        display: 'Flowing Bottomhole Pressure',
        subDisplay: '',
        description: `Default flowing bottomhole pressure (FBHP).`,
        path: 'Settings.Forecast.Darcy.fbhp',
        type: 'numerical',
        unit: 'psi',
        default: 500,
      },
      lookbackMonths: {
        display: 'Lookback Months',
        subDisplay: '',
        description: `Used to look back for well pressure data.`,
        path: 'Settings.Forecast.Darcy.lookBackMonths',
        type: 'numerical',
        unit: 'month(s)',
        default: 3,
      },
      usePudSpacing: {
        display: 'Use New Drill spacing',
        subDisplay: '',
        description: 'If off, uses the estimated drainage from current wells.',
        path: 'Settings.Forecast.Darcy.usePudSpacing',
        type: 'bool',
        default: false,
      },
    },
    neighborhoodSettings: {
      name: 'Neighborhood Settings',
      path: '/training/manual/modules/recompletions/settings/neighborhoodSettings',
      temporalScale: {
        display: 'Temporal Scale',
        subDisplay: '',
        description: `Number of recent years`,
        path: 'Settings.Neighborhood.Temporal.globalScale',
        type: 'numerical',
        unit: 'year',
        default: 15,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      type: {
        display: 'Spatial Type',
        subDisplay: '',
        description: 'This setting determines whether the neighborhood data is restricted to neighbors within a certain distance (radius) or if the neighborhood is restricted to a certain number of closest neighbors, regardless of distance (points).',
        path: 'Settings.Neighborhood.Spatial.type',
        type: 'categorical',
        options: [
          { label: 'Radius', value: 'radius' },
          { label: 'Points', value: 'points' },
        ],
        default: 'points',
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      spatialScale: {
        display: 'Spatial Scale',
        subDisplay: '',
        description: `Defines the areal extent of the neighborhood at zone level.`,
        unit: 'Radius size (meters) or number of points',
        path: 'Settings.Neighborhood.Spatial.globalScale',
        type: 'numerical',
        default: 15,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      matchWellGroup: {
        display: 'Match Well Group',
        subDisplay: '',
        description: 'If on, this will limit neighborhood wells based on the options selected in well groups to set.',
        path: 'Settings.Neighborhood.matchWellGroup',
        type: 'bool',
        default: false,
      },
      wellGroupSetsToMatch: {
        display: 'Well Group Sets to Match',
        subDisplay: '',
        description: 'Select which attributes to match on for neighborhood wells.',
        dependency: ['productionForecast', 'neighborhoodSettings', 'matchWellGroup', true],
        path: 'Settings.Neighborhood.wellGroupSetsToMatch',
        type: 'categorical',
        multi: true,
        options: [
          { label: 'Well Type', value: 'Well Type' },
        ],
        default: ['Well Type']
      },
      useZoneAllocatedRates: {
        display: 'Use Zone Allocated Rates',
        subDisplay: '',
        description: 'If on, uses the allocated production rates specific to each zone. Incorrect allocation will almost certainly lead to wrong initial rate forecasts when using statistical methods. It is recommended to use PLT data for allocation when available.',
        path: 'Settings.Neighborhood.useZoneAllocatedRate',
        type: 'bool',
        default: true,
      },
      neighborhoodPerformance: {
        display: 'Neighborhood Performance',
        subDisplay: '',
        description: `This defines the neighborhood to use to calculate the probability of exceedance (POE), which is a statistical metric describing the probability that a particular value (in this case, the predicted IP) will be met or exceeded.`,
        path: 'Settings.POE.neighborhoodPerformance',
        type: 'categorical',
        options: [
          { label: 'Initial Rates', value: 'initialRates' },
          { label: 'Current Rates', value: 'currentRates' },
        ],
        default: 'currentRates'
      },
    },
    interpolationSettings: {
      name: 'Interpolation Settings',
      path: '/training/manual/modules/recompletions/settings/interpolationSettings',
      timeWeight: {
        display: 'Time Weight',
        subDisplay: '',
        description: `Weight of time dimension. For example, if this is set to 0.5 and 'Distance Weight' is also 0.5 then time and distance will be weighted equally.`,
        path: 'Settings.Interpolation.timeWeight',
        type: 'numerical',
        default: 0.5,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1
          },
          {
            type: 'codependentSettingsSum',
            dependency: ['productionForecast', 'interpolationSettings', 'timeWeight'],
            total: 1,
          }
        ]
      },
      distanceWeight: {
        display: 'Distance Weight',
        subDisplay: '',
        description: `Weight of spatial dimension. For example, if this is set to 0.5 and 'Time Weight' is also 0.5 then time and distance will be weighted equally.`,
        path: 'Settings.Interpolation.distanceWeight',
        type: 'numerical',
        default: 0.5,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1
          },
          {
            type: 'codependentSettingsSum',
            dependency: ['productionForecast', 'interpolationSettings', 'timeWeight'],
            total: 1,
          }
        ]
      },
      method: {
        display: 'Method',
        subDisplay: '',
        description: 'Interpolation weighting function. Inverse distance weighting (IDW) is a type of deterministic method for multivariate interpolation with a known scattered set of points.',
        path: 'Settings.Interpolation.method',
        type: 'categorical',
        options: [
          { label: 'IDW', value: 'idw' },
          { label: 'Gaussian', value: 'gaussian' },
        ],
        default: 'gaussian'
      },
    },
    other: {
      name: 'Other',
      path: '/training/manual/modules/recompletions/settings/otherProductionForecast',
      maxNumberOfTargetZones: {
        display: 'Max Number of Target Zones',
        subDisplay: '',
        description: 'Max number of target zones for a single new drill.',
        path: 'Settings.Pud.nZoneTargets',
        type: 'numerical',
        unit: '',
      },
      // Table: {
      //   default: [],
      //   header: '',
      //   columns: [{
      //     Header: 'Exclusion Zones',
      //     accessor: 'exclusionZone',
      //     sortable: false,
      //     type: 'input',
      //   }],
      // },
    },
    step: 9
  },
  opportunityFiltering: {
    filterSettings: {
      name: 'Sweet Spot Location-Level Filters',
      path: '/training/manual/modules/recompletions/settings/opportunityFiltering',
      helperText: 'Filter sweet spot locations by aggregated attributes here. Filters on the zone (e.g. completion level) are found in “Target Search / Zone-Level Target Filters".',
      Table: {
        default: [{
          filterAttributes: 'predictedIp',
          filterValues: 5
        }],
        header: '',
        columns: [{
          Header: 'Filter Attributes',
          accessor: 'filterAttributes',
          sortable: false,
          type: 'categorical',
          options: sweetSpotWellAttributeOptions,

        }, {
          Header: 'Filter Values',
          accessor: 'filterValues',
          sortable: false,
          type: 'numerical',
        }],
      },
    },
    projectionSettings: {
      name: 'Projection Settings',
      path: '/training/manual/modules/recompletions/settings/opportunityFiltering',
      width: {
        display: 'Width',
        subDisplay: '',
        description: `Projection width (i.e. wells within this distance of a cross section will project onto the line of cross section).`,
        path: 'Settings.Figures.ModelSection.width',
        type: 'numerical',
        unit: 'meter(s)',
        default: 300,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
      length: {
        display: 'Length',
        subDisplay: 'Length of the cross sections that will show in the Visualizations.',
        description: ``,
        path: 'Settings.Figures.ModelSection.length',
        type: 'numerical',
        unit: 'meter(s)',
        default: 4000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
    },
    step: 10
  },
}


export const HorizontalsSettingsMap = {
  engineeringAnalytics: RecompletionsSettingsMap.engineeringAnalytics,
  logZoneAnalytics: {
    netPaySettings: {
      name: 'Net Pay Settings',
      path: '/training/manual/modules/recompletions/settings/netPay',
      generate: {
        display: 'Generate',
        subDisplay: '',
        description: "If you prefer to generate a net pay log instead of importing it, you can set the definition of the net pay log here. After switching this to 'On', set the net pay definition by selecting the logs, cutoffs, and direction for the cutoffs in the rows that appear below. Please make sure the logs you select to define your net pay are also logs you have defined for this case (check this in Global settings/Log Alias). Please also check the ranges of your log values so you set the appropriate cutoff. Most porosity and sw logs scale from 0 to 1, but occasionally they are scaled from 0 to 100.",
        path: 'Settings.NetLogs.NetPay.generate',
        type: 'bool',
        default: false,
      },
      Table: {
        dependency: ['logZoneAnalytics', 'netPaySettings', 'generate', true],
        default: [
          {
            log: 'vcl',
            cutoff: 0.25,
            largerThanCutoff: false,
          },
          {
            log: 'phi',
            cutoff: 0.75,
            largerThanCutoff: false,
          },
          {
            log: 'sw',
            cutoff: 0.65,
            largerThanCutoff: false,
          },
        ],
        header: '',
        columns: [{
          Header: 'Logs',
          accessor: 'log',
          sortable: false,
          type: 'categorical',
          options: logOptions,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
      truthType: {
        display: 'Truth Type',
        subDisplay: '',
        description: 'Specified how to combine the individual property conditions',
        dependency: ['logZoneAnalytics', 'netPaySettings', 'generate', true],
        path: 'Settings.NetLogs.NetPay.truthType',
        type: 'categorical',
        options: [
          { label: 'And', value: 'and' },
          { label: 'Or', value: 'or' },
        ],
        default: 'and',
      }
    },
    pnpSettings: {
      name: 'Perforated Net Pay (PNP) Settings',
      path: '/training/manual/modules/recompletions/settings/perforatedNetPay',
      trackingMethod: {
        display: 'PNP Flagging Method',
        subDisplay: '',
        description: "Options for limiting or extending the net pay that is assumed to be contacted or drained by existing perforations. In reservoirs with a strong aquifer, 'Extended Below' may be appropriate. In reservoirs with gas expansion, 'Extended Above' may be appropriate.",
        path: 'Settings.LogSettings.Special.Pnp.method',
        type: 'categorical',
        options: [
          { label: 'Limited', value: 'limited' },
          { label: 'Extended Above', value: 'extendedAbove' },
          { label: 'Extended Below', value: 'extendedBelow' },
        ],
        default: 'limited',
      },
      includePerfs: {
        display: 'Include Perfs',
        subDisplay: '',
        description: 'If on, this adds perforated intervals to the PNP log. This is often recommended in cases with uncertainty in the net pay definition.',
        path: 'Settings.LogSettings.Special.Pnp.includePerfs',
        type: 'bool',
        default: true,
      },
      minBaffleThickness: {
        display: 'Min Baffle Thickness',
        subDisplay: '',
        description: `Minimum baffle thickness to define extent of PNP (also creates 'baffles' based on seal log or non-pay otherwise)`,
        path: 'Settings.LogSettings.Special.Pnp.minBaffleThickness',
        type: 'numerical',
        default: 0,
      },
      generateNetSeal: {
        display: 'Generate Net Seal',
        subDisplay: '',
        description: "If you prefer to generate a net seal log, you can set the definition of the net seal log here. After switching this to 'On', set the net seal definition by selecting the logs, cutoffs, and direction for the cutoffs in the rows that appear below.",
        path: 'Settings.NetLogs.NetSeal.generate',
        type: 'bool',
        default: false,
      },
      Table: {
        dependency: ['logZoneAnalytics', 'netSealSettings', 'generate', true],
        default: [
          {
            log: 'vcl',
            cutoff: 0.25,
            largerThanCutoff: false,
          },
          {
            log: 'phi',
            cutoff: 0.75,
            largerThanCutoff: false,
          },
          {
            log: 'sw',
            cutoff: 0.65,
            largerThanCutoff: false,
          },
        ],
        header: '',
        columns: [{
          Header: 'Logs',
          accessor: 'log',
          sortable: false,
          type: 'categorical',
          options: logOptions,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
      truthType: {
        display: 'Truth Type',
        subDisplay: '',
        description: 'Specified how to combine the individual property conditions',
        dependency: ['logZoneAnalytics', 'netSealSettings', 'generate', true],
        path: 'Settings.NetLogs.NetSeal.truthType',
        type: 'categorical',
        options: [
          { label: 'And', value: 'and' },
          { label: 'Or', value: 'or' },
        ],
        default: 'and',
      }
    },
    // simulationModel: {
    //   useSimulationModel: {
    //     display: 'Use Simulation Model',
    //     subDisplay: '',
    //     description: 'If on, the current So curve will be used to filter UNP. This So curve comes from simulation results. The recommendation is to have imported a So pseudolog directly as part of the LAS files.',
    //     path: 'Settings.LogSettings.Special.Mnp.Sim.use',
    //     type: 'bool',
    //     default: false,
    //   },
    //   createPseudoLog: {
    //     display: 'Create Pseudo Log',
    //     subDisplay: '',
    //     description: 'If on, this creates a pseudo log based on the So model. This requires that a model with a So property has been imported.',
    //     dependency: ['logZoneAnalytics', 'simulationModel', 'useSimulationModel', true],
    //     path: 'Settings.LogSettings.Special.Mnp.Sim.createPseudoLog',
    //     type: 'bool',
    //     default: true,
    //   },
    //   minSo: {
    //     display: 'MinSo',
    //     subDisplay: '',
    //     description: `Min So cutoff to use for filtering UNP`,
    //     dependency: ['logZoneAnalytics', 'simulationModel', 'useSimulationModel', true],
    //     path: 'Settings.LogSettings.Special.Mnp.Sim.minSo',
    //     type: 'numerical',
    //     default: 0.3,
    //   }
    // },
    step: 3,
  },
  attributeMapping: RecompletionsSettingsMap.attributeMapping,
  searchDomainDefinition: {
    regionOfInterest: {
      name: 'Region Of Interest',
      path: '/training/manual/modules/horizontals/settings/searchDomainDefinition',
      Table: {
        default: [],
        header: '',
        columns: [{
          Header: 'X (meters)',
          accessor: 'x',
          sortable: false,
          type: 'numerical',

        }, {
          Header: 'Y (meters)',
          accessor: 'y',
          sortable: false,
          type: 'numerical',
        }],
      },
    },
    coiGrids: {
      name: 'Depth of Interest',
      path: '/training/manual/modules/horizontals/settings/searchDomainDefinition',
      shouldTargetZones: {
        display: 'Manually Specify Target Zones',
        subDisplay: '',
        description: 'Manually specify target zones.  If off, all zones will be selected.',
        path: 'Settings.Horizontals.Coi.targetZones',
        default: false,
        type: 'bool',
      },
      targetZones: {
        display: 'Target Zones',
        subDisplay: '',
        description: 'TODO - add description',
        path: 'Settings.Horizontals.Coi.targetZoneIds',
        type: 'categorical',
        default: ['all'],
        multi: true,
        dependency: ['searchDomainDefinition', 'coiGrids', 'shouldTargetZones', true],
        options: [],
      },
      targetDepthRangeLower: {
        display: 'Deepest Target Depth',
        subDisplay: '',
        description: 'This is the limit for the deepest depth for any outcome horizontal target trajectory (SSTVD). Depths below sea level are expected to be negative.',
        type: 'numerical',
        unit: 'meter',
        default: -4000,
        restrictions: [
          {
            type: 'codependentSettingsLessThan',
            dependency: ['searchDomainDefinition', 'coiGrids', 'targetDepthRangeUpper'],
          }
        ]
      },
      targetDepthRangeUpper: {
        display: 'Shallowest Target Depth',
        subDisplay: '',
        description: 'This is the limit for the shallowest depth for any outcome horizontal target trajectory (SSTVD). Depths below sea level are expected to be negative.',
        type: 'numerical',
        unit: 'meter',
        default: -3000,
        restrictions: [
          {
            type: 'codependentSettingsGreaterThan',
            dependency: ['searchDomainDefinition', 'coiGrids', 'targetDepthRangeLower'],
          }
        ]
      }
    },
    spacingGrids: {
      name: 'Spacing Grids',
      path: '/training/manual/modules/horizontals/settings/searchDomainDefinition',
      externalSpacing: {
        display: 'Spacing to Existing Wells',
        subDisplay: '',
        description: 'Minimum distance to place any new horizontal targets from any existing wells’ trajectories.',
        type: 'numerical',
        path: 'Settings.Pud.Spacing.external',
        unit: 'meters',
        default: 18,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      }
    },
    step: 5,
  },
  drainageAnalysis: {
    drainage: {
      name: 'Drainage',
      path: '/training/manual/modules/horizontals/settings/horDrainage',
      singleZone: {
        display: 'Single Zone',
        subDisplay: '',
        description: 'If on, drainage analysis assumes all zones are connected and treats them as a single zone.',
        type: 'bool',
        path: 'Settings.Drainage.singleZone',
        default: false,
      },
      maxRadius: {
        display: 'Max Radius',
        subDisplay: '',
        description: 'This sets a limit for the maximum drainage radius from any point along any existing wellbore trajectory. This helps prevent high outliers in the drainage analysis data from eliminating areas that are not truly drained.',
        type: 'numerical',
        path: 'Settings.Drainage.maxRadius',
        unit: 'meters',
        default: 1000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      }
    },
    step: 6,
  },
  rposModeling: {
    // additionalProperties: {
    //   path: '/training/manual/modules/horizontals/settings/horSweetSpotRanking',
    //   Table: {
    //     default: [
    //       {
    //         ntgAttribute: 'phi',
    //         cutoff: .05,
    //         largerThanCutoff: true    
    //       }, 
    //       { 
    //         ntgAttribute: 'sw',
    //         cutoff: .8,
    //         largerThanCutoff: false    
    //       },
    //     ],
    //     header: '',
    //     columns: [{
    //       Header: 'NTG Attributes',
    //       accessor: 'ntgAttribute',
    //       sortable: false,
    //       type: 'categorical',
    //       smartOption: 'horizontal',
    //       options: ntgAttributeOptions
    //     }, {
    //       Header: 'Cutoffs',
    //       accessor: 'cutoff',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Larger Than Cutoff',
    //       accessor: 'largerThanCutoff',
    //       sortable: false,
    //       type: 'bool',
    //     }],
    //   },
    // },
    additionalConstraints: {
      name: 'Additional Constraints',
      path: '/training/manual/modules/horizontals/settings/horSweetSpotRanking',
      contactStandoff: {
        display: 'Contact Standoff',
        subDisplay: '',
        description: 'This is used to create a model property to limit the subsequent target search to depths/areas that are not too close to the contacts (OWC, GOC).  Only model cells at least this amount higher than the OWC for each zone (or lower than the GOC for each zone) will be considered as the in the oil window.',
        type: 'numerical',
        unit: 'meters',
        path: 'Settings.Pud.Others.contactStandoff',
        default: 35,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      stayAway: {
        display: 'Fault Stay Away',
        subDisplay: '',
        description: 'If on, then the algorithm tends to favor targets away from any fault. Otherwise, it would favor places in closer proximity with a fault. If no distance-to-fault (DTF) grids have been provided, then this has no effect either way.',
        type: 'bool',
        path: 'Settings.Pud.Faults.stayAway',
        default: true,
      },
      distanceCutoff: {
        display: 'Fault Distance Cutoff',
        subDisplay: '',
        description: 'This is used when generating the distance-to-fault (DTF) Index property for the 3D model. If the algorithm favors staying away from fault (above setting "Fault Stay Away" toggled on), then only cells with DTF values larger than this amount will be considered as feasible cells considering fault proximity. If the algorithm favors staying close to faults (above setting "Fault Stay Away" toggled off), then only cells with DTF values smaller than this amount will be considered feasible cells considering fault proximity. If no distance-to-fault (DTF) grids have been provided, then this has no effect either way.',
        type: 'numerical',
        unit: 'meters',
        path: 'Settings.Pud.Faults.distanceCutoff',
        default: 200,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
    },
    ranking: {
      name: 'RPOS/Ranking Attributes',
      path: '/training/manual/modules/horizontals/settings/horSweetSpotRanking',
      Table: {
        default: [
          {
            attribute: 'hcpv',
            reverseAttribute: false,
          },
          {
            attribute: 'ntg',
            reverseAttribute: false,
          },
          {
            attribute: 'drainage',
            reverseAttribute: true,
          },
          {
            attribute: 'dtfIndex',
            reverseAttribute: false,
          },
          {
            attribute: 'inOilWindow',
            reverseAttribute: false,
          },
          {
            attribute: 'recentWct',
            reverseAttribute: true,
          },
          {
            attribute: 'recentGor',
            reverseAttribute: true,
          },
        ],
        header: '',
        columns: [{
          Header: 'Attributes',
          accessor: 'attribute',
          sortable: false,
          type: 'categorical',
          smartOption: 'horizontal',
          options: horizontalRankingAttributes,

        }, {
          Header: 'Reverse Attributes',
          accessor: 'reverseAttribute',
          sortable: false,
          type: 'bool',
        }],
      },
    },
    step: 7,
  },
  targetSearch: {
    search: {
      name: 'General Search',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      // Table: {
      //   default: [
      //     {
      //       attribute: 'poe',
      //       objective: 'maximize',
      //     }, 
      //   ],
      //   header: '',
      //   columns: [{
      //     Header: 'Search Objective',
      //     accessor: 'attribute',
      //     sortable: false,
      //     type: 'categorical',
      //     options: attributeOptions
      //   }, {
      //     Header: '',
      //     accessor: 'objective',
      //     sortable: false,
      //     type: 'categorical',
      //     options: [
      //       { label: 'Maximize', value: 'maximize'},
      //       { label: 'Minimize', value: 'minimize'},
      //     ]
      //   }],
      // },
      minTargetCellFraction: {
        display: 'Min Target Cell Fraction',
        subDisplay: '',
        description: 'This used to specify the minimum fraction of positive relative probabiliyt of succes (RPOS) cells along any outcome target trajectory. For example, if this value is 0.5, it means the target is still valid as long as more than 50% of the cells it penetrates have positive RPOS values.',
        type: 'numerical',
        path: 'Settings.Horizontals.Search.minTargetCellFraction',
        default: 0.8,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      }
    },
    noGoCells: {
      name: 'No Go Cells',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      Table: {
        default: [
          {
            property: 'spacing',
            cutoff: 1,
            largerThanCutoff: false
          },
          {
            property: 'drainage',
            cutoff: 0,
            largerThanCutoff: true
          },
          {
            property: 'dtfIndex',
            cutoff: 1,
            largerThanCutoff: false
          },
          {
            property: 'inOilWindow',
            cutoff: 1,
            largerThanCutoff: false
          },
        ],
        header: '',
        columns: [{
          Header: 'Properties',
          accessor: 'property',
          sortable: false,
          type: 'categorical',
          smartOption: 'horizontal',
          options: noGoCellProperties,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
    },
    optimization: {
      name: 'Optimization',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      initialGuessNumber: {
        display: 'Initial Guess Number',
        subDisplay: '',
        description: 'This number specifies the number of initial targets to search for. The larger the number, the more targets the engine will initially search for. Larger numbers also means this target search step and the subsequent step "Interference Analysis" will take longer.',
        type: 'numerical',
        path: 'Settings.Horizontals.Optimization.InitGuessNumber',
        default: 1000,
        restrictions: [
          {
            type: 'above',
            min: 0,
          },
          {
            type: 'integer',
          }
        ]
      },
      skipCellsForInterpolation: {
        display: 'Skip Cells For Interpolation',
        subDisplay: '',
        description: 'This setting is meant for particularly large models with lots of cells, which can take a long time to process. If On, the interpolation from spatial coordinates to cell indices will be performed based on a maximum of 3 million cells. If the model contains more than 3 million cells, it will automatically skip cells for interpolation to keep the total number below 3 million.',
        type: 'bool',
        path: 'Settings.Horizontals.Optimization.skipCellsForInterpolation',
        default: true,
      },
    },
    configuration: {
      name: 'Lateral Well Constraints',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      stayInZone: {
        display: 'Stay In Zone',
        subDisplay: '',
        description: 'If On, each identified target could not cross any zone boundary (and therefore each target will target only one zone).',
        type: 'bool',
        path: 'Settings.Horizontals.Configuration.stayInZone',
        default: true,
      },
      azimuthRangeLower: {
        display: 'Lower Azimuth Range',
        subDisplay: '',
        description: 'Each horizontal target will have a single azimuth. This specifies the lower range of azimuths that can be gathered as targets.',
        unit: 'degrees',
        type: 'numerical',
        default: 10,
        restrictions: [
          {
            type: 'range',
            min: -180,
            max: 180
          },
          {
            type: 'codependentSettingsLessThan',
            dependency: ['targetSearch', 'configuration', 'azimuthRangeUpper'],
          }
        ]
      },
      azimuthRangeUpper: {
        display: 'Upper Azimuth Range',
        subDisplay: '',
        description: 'Each horizontal target will have a single azimuth. This specifies the upper range of azimuths that can be gathered as targets.',
        unit: 'degrees',
        type: 'numerical',
        default: 80,
        restrictions: [
          {
            type: 'range',
            min: -180,
            max: 180
          },
          {
            type: 'codependentSettingsGreaterThan',
            dependency: ['targetSearch', 'configuration', 'azimuthRangeLower'],
          }
        ]
      },
      lengthRangeLower: {
        display: 'Lower Length Range',
        subDisplay: '',
        description: 'This specifies the minimum length that a horizontal target can be.',
        unit: 'meters',
        type: 'numerical',
        default: 500,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
          {
            type: 'codependentSettingsLessThan',
            dependency: ['targetSearch', 'configuration', 'lengthRangeUpper'],
          }
        ]
      },
      lengthRangeUpper: {
        display: 'Upper Length Range',
        subDisplay: '',
        description: 'This specifies the maximum length that a horizontal target can be.',
        unit: 'meters',
        type: 'numerical',
        default: 1000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
          {
            type: 'codependentSettingsGreaterThan',
            dependency: ['targetSearch', 'configuration', 'lengthRangeLower'],
          }
        ]
      },
      deviationRangeLower: {
        display: 'Lower Deviation Range',
        subDisplay: '',
        description: 'Each horizontal target will have a single deviation (i.e. inclination). This specifies the lowest inclination target that can be searched for.',
        unit: 'degrees',
        type: 'numerical',
        default: 85,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 180,
          },
          {
            type: 'codependentSettingsLessThan',
            dependency: ['targetSearch', 'configuration', 'deviationRangeUpper'],
          }
        ]
      },
      deviationRangeUpper: {
        display: 'Upper Deviation Range',
        subDisplay: '',
        description: 'Each horizontal target will have a single deviation (i.e. inclination). This specifies the highest inclination target that can be searched for.',
        unit: 'degrees',
        type: 'numerical',
        default: 95,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 180,
          },
          {
            type: 'codependentSettingsGreaterThan',
            dependency: ['targetSearch', 'configuration', 'deviationRangeLower'],
          }
        ]
      },
    },
    targetCells: {
      name: 'Target Cells',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      Table: {
        default: [
          {
            property: 'pos',
            cutoff: .8,
            largerThanCutoff: true,
          },
        ],
        header: '',
        columns: [{
          Header: 'Properties',
          accessor: 'property',
          sortable: false,
          type: 'categorical',
          smartOption: 'horizontal',
          options: horizontalTargetCellOptions,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
    },
    // Mentioned here to remove user opportunities: https://qrigroup.atlassian.net/browse/SJ-357
    // userOpportunities: {
    //   path: '/training/manual/modules/horizontals/settings/horTargetSearch',
    //   Table: {
    //     default: [
    //     ],
    //     header: '',
    //     columns: [{
    //       Header: 'Heel X',
    //       accessor: 'x1',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Toe X',
    //       accessor: 'x2',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Heel Y',
    //       accessor: 'y1',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Toe Y',
    //       accessor: 'y2',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Heel Z',
    //       accessor: 'z1',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Toe Z',
    //       accessor: 'z2',
    //       sortable: false,
    //       type: 'numerical',
    //     }],
    //   },
    // },
    step: 8,
  },
  interferenceAnalysis: {
    spacingHor: {
      name: 'Min. Spacing Between Horizontal Targets',
      path: '/training/manual/modules/horizontals/settings/interferenceAnalysis',
      internalSpacing: {
        display: 'Internal Spacing',
        subDisplay: '',
        description: 'This value specifies the minimum closest distance between two outcome trajectories for them to be considered not interfering with each other. ',
        unit: 'meters',
        type: 'numerical',
        path: 'Settings.Pud.Spacing.internal',
        default: 60,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
    },
    conflicts: {
      name: 'Conflicts',
      path: '/training/manual/modules/horizontals/settings/interferenceAnalysis',
      candidatesToResolve: {
        display: 'Candidates to Resolve',
        subDisplay: '',
        description: 'Maximum number of targets to be resolved within the interference analysis. For example, if 5000 initial targets were found but this setting is 100, then this step would take the top 100 targets (in terms of RPOS value) and evaluate those targets for interference (create a subset of those 100 targets that do not interfere). Thus, after this interference step, the resulting number of targets in the inventory will be less than or equal to 100.',
        unit: '',
        type: 'numerical',
        path: 'Settings.Horizontals.Conflicts.nCandidatesToResolve',
        default: 5000,
        restrictions: [
          {
            type: 'above',
            min: 0,
          },
          {
            type: 'integer'
          }
        ]
      },
      // resolutionAttribute: {
      //   display: 'Resolution Attribute',
      //   subDisplay: '',
      //   description: '',
      //   type: 'categorical',
      //   options: attributeOptions,
      //   path: 'Settings.Horizontals.Conflicts.resolutionAttribute',
      //   default: 'pos',
      // }, 
      selectionAlgorithm: {
        display: 'Selection Algorithm',
        subDisplay: '',
        description: 'The algorithm to be used for interference analysis. Please refer to the user manual for details regarding these two approaches.',
        type: 'categorical',
        options: [
          { label: 'HLWGF', value: 'HLWGF' },
          { label: 'HGF', value: 'HGF' },
        ],
        path: 'Settings.Horizontals.Conflicts.selectionAlgorithm',
        default: 'HLWGF',
      },
      xAxisWeight: {
        display: 'X Axis Weight',
        subDisplay: '',
        description: 'X axis weights for interference distance. For example, a higher X-weight allows candidates to be closer along the x-axis without interfering.',
        type: 'numerical',
        default: 1,
      },
      yAxisWeight: {
        display: 'Y Axis Weight',
        subDisplay: '',
        description: 'Y axis weights for interference distance. For example, a higher Y-weight allows candidates to be closer along the y-axis without interfering.',
        type: 'numerical',
        default: 1,
      },
      zAxisWeight: {
        display: 'Z Axis Weight',
        subDisplay: '',
        description: 'Z axis weights for interference distance. For example, a higher Z-weight allows candidates to be closer along the z-axis without interfering',
        type: 'numerical',
        default: 2,
      },
    },
    step: 9,
  },
  productionForecast: {
    productionForecast: {
      name: 'Production Forecast',
      path: '/training/manual/modules/recompletions/settings/initialProductionRateForecast',
      helperText: 'Toggle the settings that you want to use.',
      shouldUseTC: {
        display: 'Should Use Type Curve (TC)',
        subDisplay: '',
        description: 'Creates a zone-specific type curve from the allocated initial production (IP) rates. Requires having zone level historical production data (the Engineering Analytics step will allocate the production data to the zone level).',
        type: 'bool',
        default: false,
      },
      shouldUseSTI: {
        display: 'Should Use Spatial Temporal Interpolation (STI)',
        subDisplay: '',
        description: 'Initial zone-allocated oil rates of an analog neighborhood are spatially and temporally weighted and averaged around each opportunity. Requires having initial production (IP) rates from offset producers within the time and spatial range defined in the settings.',
        type: 'bool',
        default: false,
      },
      shouldUseTNSTI: {
        display: 'Should Use Thickness Normalized Spatial Temporal Interpolation (TNSTI)',
        subDisplay: '',
        description: 'Initial zone-allocated oil rates of an analog neighborhood are spatially and temporally weighted and averaged and then normalized by a thickness attribute around each opportunity. Requires having initial production rates from offset producers within the time and spatial range defined in the settings. Also, the normalization attribute (PNP, PNP HCPT, perf length, etc.) needs to exist for the opportunity under evaluation.',
        type: 'bool',
        default: true,
      },
      shouldUseNFR: {
        display: 'Should Use Neighborhood Fluid Rates (NFR)',
        subDisplay: '',
        description: 'Current zone-allocated fluid rates in the neighborhood around the opportunity (neighborhood size or distance is user-defined) are normalized by perforation length to create fluid rate indices (FRIs). Requires having current production data within the spatial range defined in the settings.',
        type: 'bool',
        default: false,
      },
      shouldUseTNNFR: {
        display: 'Should Use Thickness Normalized Neighborhood Fluid Rates (TNNFR)',
        subDisplay: '',
        description: 'Current zone-allocated fluid rates in the neighborhood around the opportunity (neighborhood size or distance is user-defined) are normalized by perforation length to create fluid rate indices (FRIs). These are subsequently normalized by the thickness attribute selected. Requires having current production data within the spatial range defined in the settings. Also, the normalization attribute (PNP, PNP HCPT, perf length, etc.) needs to exist for the opportunity under evaluation.',
        type: 'bool',
        default: true,
      },
      shouldUseJOSHI: {
        display: 'Should Use Joshi', //TODO - add this
        subDisplay: '',
        description: 'This is a steady-state flow model developed by Joshi (1988). Please refer to the user manual for more detail on this method. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: horizontal wellbore length, horizontal permeability, horizontal/vertical permeability ratio, oil viscosity, FVF, pressure at drainage boundary, fBHP, half length of drainage ellipse in the horizontal plane, skin factor.',
        type: 'bool',
        default: true,
      },
      shouldUseFURUI: {
        display: 'Should Use FURUI', //TODO - add this
        subDisplay: '',
        description: 'This is a steady-state analytics flow model for horizontal well inflow. Please refer to the user manual for more detail on this method. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: horizontal wellbore length, horizontal permeability, horizontal/vertical permeability ratio, oil viscosity, FVF, pressure at drainage boundary, fBHP, distance to the drainage boundary in the Y direction, partial penetration skin factor.',
        type: 'bool',
        default: false,
      },
      shouldUseBABU: {
        display: 'Should Use Babu', //TODO - add this
        subDisplay: '',
        description: 'These pseudo steady-state models of inflow performance presumes that the reservoir is bounded by no-flow boundaries and pressure declines in a uniform fashion in the reservoir. Please refer to the user manual for more detail on this method. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: horizontal wellbore length, horizontal permeability, horizontal/vertical permeability ratio, oil viscosity, FVF, pressure at drainage boundary, fBHP, wellbore configuration w.r.t. the drainage area, skin factor.',
        type: 'bool',
        default: false,
      },
      shouldUseECONOMIDES: {
        display: 'Should Use Economides', //TODO - add this
        subDisplay: '',
        description: 'This is a completely general model for one or more horizontal wells or laterals developed by integrating unit length point sources in no-flow boundary boxes to create an arbitrary well trajectory or trajectories. Please refer to the user manual for more detail on this method. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: horizontal wellbore length, horizontal permeability, horizontal/vertical permeability ratio, oil viscosity, FVF, pressure at drainage boundary, fBHP, reservoir length, vertical distance from wellbore to bottom of payzone, share factor, skin factor.',
        type: 'bool',
        default: false,
      },
      shouldUseSML: {
        display: 'Apply Speedwise Machine Learning (SML)',
        description: 'Use SpeedWise Machine Learning for opportunities\' production forecast.',
        type: 'bool',
        default: false,
      },
      smlAPIKey: {
        display: 'Speedwise Machine Learning (SML) API Key',
        description: undefined,
        dependency: ['productionForecast', 'productionForecast', 'shouldUseSML', true],
        path: 'Settings.Forecast.SMLAPI',
        type: 'string',
      },
      primaryMethod: {
        display: 'Primary Method',
        subDisplay: '',
        description: 'The primary method is what will be used to display in visualizations and in subsequent filtering if chosen. However, other forecast methods toggled on will be evaluated and then will be available in the CSV download so you can compare the different forecast methods outside of the platform.',
        path: 'Settings.Horizontals.Forecast.primaryMethod',
        type: 'categorical',
        options: [
          { label: 'SML', value: 'SML' },
          { label: 'TC', value: 'TC' },
          { label: 'STI', value: 'STI' },
          { label: 'TNSTI', value: 'TNSTI' },
          { label: 'NFR', value: 'NFR' },
          { label: 'TNNFR', value: 'TNNFR' },
          { label: 'JOSHI', value: 'JOSHI' },
          { label: 'FURUI', value: 'FURUI' },
          { label: 'BABU', value: 'BABU' },
          { label: 'ECONOMIDES', value: 'ECONOMIDES' },
        ],
        default: 'JOSHI'
      },
    },
    settingsforThicknessNormalizedMethods: {
      name: 'Settings for Thickness Normalized Methods',
      path: '/training/manual/modules/recompletions/settings/thicknessNormalized',
      normalizationAttribute: {
        display: 'Normalization Attribute',
        subDisplay: '',
        description: 'Thickness attribute used for normalization',
        path: 'Settings.Forecast.Normalization.attribute',
        type: 'categorical',
        options: [
          { label: 'PNP', value: 'pnp' },
          { label: 'Perf Length', value: 'perfLength' },
          { label: 'PNP Hcpt', value: 'pnpHcpt' },
        ],
        default: 'pnp'
      },
      expandNeighborhood: {
        display: 'Expand Neighborhood',
        subDisplay: '',
        description: 'If true, neighborhood wells missing norm attribute will be replaced with other neighborhood wells (when spatial radius is based on N points)',
        path: 'Settings.Forecast.Normalization.expandNeighborhood',
        type: 'bool',
        default: false,
      },
    },
    neighborhoodSettings: {
      name: 'Neighborhood Settings',
      path: '/training/manual/modules/recompletions/settings/neighborhoodSettings',
      temporalScale: {
        display: 'Temporal Scale',
        subDisplay: '',
        description: `Number of recent years`,
        path: 'Settings.Neighborhood.Temporal.globalScale',
        type: 'numerical',
        unit: 'year',
        default: 15,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      type: {
        display: 'Spatial Type',
        subDisplay: '',
        description: 'This setting determines whether the neighborhood data is restricted to neighbors within a certain distance (radius) or if the neighborhood is restricted to a certain number of closest neighbors, regardless of distance (points).',
        path: 'Settings.Neighborhood.Spatial.type',
        type: 'categorical',
        options: [
          { label: 'Radius', value: 'radius' },
          { label: 'Points', value: 'points' },
        ],
        default: 'points'
      },
      spatialScale: {
        display: 'Spatial Scale',
        subDisplay: '',
        description: `Defines the areal extent of the neighborhood at zone level.`,
        unit: 'Radius size (meters) or number of points',
        path: 'Settings.Neighborhood.Spatial.globalScale',
        type: 'numerical',
        default: 15,
      },
      matchWellGroup: {
        display: 'Match Well Group',
        subDisplay: '',
        description: 'If on, this will limit neighborhood wells based on the options selected in well groups to set.',
        path: 'Settings.Neighborhood.matchWellGroup',
        type: 'bool',
        default: false,
      },
      wellGroupSetsToMatch: {
        display: 'Well Group Sets to Match',
        subDisplay: '',
        description: 'Select which attributes to match on for neighborhood wells.',
        dependency: ['productionForecast', 'neighborhoodSettings', 'matchWellGroup', true],
        path: 'Settings.Neighborhood.wellGroupSetsToMatch',
        type: 'categorical',
        multi: true,
        options: [
          { label: 'Well Type', value: 'Well Type' },
        ],
        default: ['Well Type']
      },
      useZoneAllocatedRates: {
        display: 'Use Zone Allocated Rates',
        subDisplay: '',
        description: 'If on, uses the allocated production rates specific to each zone. Incorrect allocation will almost certainly lead to wrong initial rate forecasts when using statistical methods. It is recommended to use PLT data for allocation when available.',
        path: 'Settings.Neighborhood.useZoneAllocatedRate',
        type: 'bool',
        default: true,
      },
      neighborhoodPerformance: {
        display: 'Neighborhood Performance',
        subDisplay: '',
        description: `This defines the neighborhood to use to calculate the probability of exceedance (POE), which is a statistical metric describing the probability that a particular value (in this case, the predicted IP) will be met or exceeded.`,
        path: 'Settings.POE.neighborhoodPerformance',
        type: 'categorical',
        options: [
          { label: 'Initial Rates', value: 'initialRates' },
          { label: 'Current Rates', value: 'currentRates' },
        ],
        default: 'currentRates'
      },
    },
    interpolationSettings: {
      name: 'Interpolation Settings',
      path: '/training/manual/modules/recompletions/settings/interpolationSettings',
      timeWeight: {
        display: 'Time Weight',
        subDisplay: '',
        description: `Weight of time dimension. For example, if this is set to 0.5 and 'Distance Weight' is also 0.5 then time and distance will be weighted equally.`,
        path: 'Settings.Interpolation.timeWeight',
        type: 'numerical',
        default: 0.5,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          },
          {
            type: 'codependentSettingsSum',
            dependency: ['productionForecast', 'interpolationSettings', 'distanceWeight'],
            total: 1,
          }
        ]
      },
      distanceWeight: {
        display: 'Distance Weight',
        subDisplay: '',
        description: `Weight of spatial dimension. For example, if this is set to 0.5 and 'Time Weight' is also 0.5 then time and distance will be weighted equally.`,
        path: 'Settings.Interpolation.distanceWeight',
        type: 'numerical',
        default: 0.5,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          },
          {
            type: 'codependentSettingsSum',
            dependency: ['productionForecast', 'interpolationSettings', 'timeWeight'],
            total: 1,
          }
        ]
      },
      method: {
        display: 'Method',
        subDisplay: '',
        description: 'Interpolation weighting function. Inverse distance weighting (IDW) is a type of deterministic method for multivariate interpolation with a known scattered set of points.',
        path: 'Settings.Interpolation.method',
        type: 'categorical',
        options: [
          { label: 'IDW', value: 'idw' },
          { label: 'Gaussian', value: 'gaussian' },
        ],
        default: 'gaussian'
      },
    },
    parameters: {
      name: 'Analytical Model Parameters for Horizontal Wells',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      horizontalWellboreRadius: {
        display: 'Horizontal Wellbore Radius',
        subDisplay: '',
        description: 'Wellbore radius of the proposed horizontal well (in meters)',
        unit: 'meters',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.wellboreRadius',
        default: 0.091,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      horizontalPermeability: {
        display: 'Horizontal Permeability',
        subDisplay: '',
        description: 'Horizontal permeability of the reservoir (in mD)',
        unit: 'mD',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.horzPerm',
        default: 1000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      ratioOfKvKh: {
        display: 'Ratio of Kv/Kh',
        subDisplay: '',
        description: 'Ratio between the vertical and horizontal permeability of the reservoir',
        unit: '',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.vert2HorzPermRatio',
        default: 0.1,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      oilViscosity: {
        display: 'Oil Viscosity',
        subDisplay: '',
        description: 'Representative oil viscosity throughout the reservoir (in cp)',
        unit: 'cp',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.oilViscosity',
        default: 5,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      formationVolumeFactor: {
        display: 'Formation Volume Factor',
        subDisplay: '',
        description: 'Representative oil formation volume factor throughout the reservoir.',
        unit: '',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.bo',
        default: 1.1,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      pressureDrainageBoundary: {
        display: 'Pressure at Drainage Boundary',
        subDisplay: '',
        description: 'Representative reservoir pressure at the drainage boundary (in PSI)',
        unit: 'psi',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.Pressure.reservoir',
        default: 5000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      flowingBottomHolePressure: {
        display: 'Flowing Bottom Hole Pressure',
        subDisplay: '',
        description: 'Representative flowing bottomhole pressure throughout the reservoir (in PSI)',
        unit: 'psi',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.Pressure.flowing',
        default: 4000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      // bubblePointPressure: {
      //   display: 'Bubble Point Pressure',
      //   subDisplay: '',
      //   description: '',
      //   unit: 'psi',
      //   type: 'numerical',
      //   path: 'Settings.Horizontals.Forecast.Pressure.bubblePoint',
      //   default: 2500,
      // }, 
    },
    joshiParameters: { // TODO - these all need better descriptions
      name: 'Joshi Parameters',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseJOSHI', true],
      drainageEllipse: {
        display: 'Half Length of Drainage Ellipse in the Horizontal Plane',
        subDisplay: '',
        description: `Half Length of Drainage Ellipse in the Horizontal Plane (in meters)`,
        path: 'Settings.Horizontals.Forecast.Joshi.a',
        type: 'numerical',
        unit: 'meters',
        default: 2000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      s: {
        display: 'S',
        subDisplay: '',
        description: `Skin factor`,
        path: 'Settings.Horizontals.Forecast.Joshi.S',
        type: 'numerical',
        unit: '',
        default: 0,
      },
    },
    furuiParameters: { // TODO - these all need better descriptions
      name: 'Furui Parameters',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseFURUI', true],
      distanceDrainageBoundary: {
        display: 'Distance to the Drainage Boundary in the Y Direction',
        subDisplay: '',
        description: `Distance to the Drainage Boundary in the Y Direction (in meters)`,
        path: 'Settings.Horizontals.Forecast.Furui.Yb',
        type: 'numerical',
        unit: 'meters',
        default: 707,
      },
      partialPenetrationSkinFactor: {
        display: 'Partial Penetration Skin Factor',
        subDisplay: '',
        description: `Partial Penetration Skin Factor`,
        path: 'Settings.Horizontals.Forecast.Furui.SR',
        type: 'numerical',
        unit: '',
        default: 14,
      }
    },
    BabuParameters: { // TODO - these all need better descriptions
      name: 'Babu Parameters',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseBABU', true],
      widthRectangularDrainageBox: {
        display: 'Width of Rectangular Drainage Box',
        subDisplay: '',
        description: `Width of Rectangular Drainage Box (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.a',
        type: 'numerical',
        unit: 'meters',
        default: 2000,
      },
      lengthRectangularDrainageBox: {
        display: 'Length of Rectangular Drainage Box',
        subDisplay: '',
        description: `Length of Rectangular Drainage Box (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.b',
        type: 'numerical',
        unit: 'meters',
        default: 3000,
      },
      xCoordWellInitialPoint: {
        display: 'X-coordinate of Well Initial Point',
        subDisplay: '',
        description: `X-coordinate of Well Initial Point (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.x1',
        type: 'numerical',
        unit: 'meters',
        default: 100,
      },
      xCoordWellLastPoint: {
        display: 'X-coordinate of Well Last Point',
        subDisplay: '',
        description: `X-coordinate of Well Last Point (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.x2',
        type: 'numerical',
        unit: 'meters',
        default: 2100,
      },
      yCoordWell: {
        display: 'Y-coordinate of Well',
        subDisplay: '',
        description: `Y-coordinate of Well (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.y0',
        type: 'numerical',
        unit: 'meters',
        default: 1000,
      },
      zCoordWell: {
        display: 'Z-coordinate of Well',
        subDisplay: '',
        description: `Z-coordinate of Well (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.z0',
        type: 'numerical',
        unit: 'meters',
        default: 50,
      },
      anyOtherSkinFactor: {
        display: 'Any Other Skin Factor',
        subDisplay: '',
        description: `Any Other Skin Factor`,
        path: 'Settings.Horizontals.Forecast.Babu.S',
        type: 'numerical',
        unit: '',
        default: 2,
      },
    },
    economidesParameters: { // TODO - these all need better descriptions
      name: 'Economides Parameters',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseECONOMIDES', true],
      reservoirLength: {
        display: 'Reservoir Length',
        subDisplay: '',
        description: `Reservoir Length (in meters)`,
        path: 'Settings.Horizontals.Forecast.Economides.Xe',
        type: 'numerical',
        unit: 'meters',
        default: 4000,
      },
      verticalDistanceFromWellboreToPayzone: {
        display: 'Vertical Distance from the Wellbore to Bottom of the Payzone',
        subDisplay: '',
        description: `Vertical Distance from the Wellbore to Bottom of the Payzone (in meters)`,
        path: 'Settings.Horizontals.Forecast.Economides.Zw',
        type: 'numerical',
        unit: 'meters',
        default: 14,
      },
      shapeFactor: {
        display: 'Shape Factor',
        subDisplay: '',
        description: `Shape Factor`,
        path: 'Settings.Horizontals.Forecast.Economides.CH',
        type: 'numerical',
        unit: '',
        default: 1,
      },
      anyOtherSkinFactor: {
        display: 'Any Other Skin Factor',
        subDisplay: '',
        description: `Any Other Skin Factor`,
        path: 'Settings.Horizontals.Forecast.Economides.S',
        type: 'numerical',
        unit: '',
        default: 0,
      },
    },
    step: 10,
  },
  filterFinalize: {
    filterDrillLocation: {
      name: 'Filter Drill Location',
      path: '/training/manual/modules/horizontals/settings/horFilterFinalize',
      Table: {
        default: [{
          filterAttribute: 'predictedIp',
          filterValue: 5
        }],
        header: '',
        columns: [{
          Header: 'Horizontals Filter Attributes',
          accessor: 'filterAttribute',
          sortable: false,
          type: 'categorical',
          options: horizontalFilterAttributes,
        }, {
          Header: 'Horizontals Filter Value',
          accessor: 'filterValue',
          sortable: false,
          type: 'numerical',
        }],
      },
    },
    step: 11,
  },
}

export const UnconventionalsSettingsMap = {
  attributeMapping: {
    attributeMapping: {
      name: 'Attribute Mapping',
      path: '/training/manual/modules/recompletions/settings/attributeMapping',
      recentWctMethod: {
        display: 'Recent Water Cut Mapping Algoithm',
        subDisplay: '',
        description: 'Maps data within recent time frame (defined in Data Integration settings). You may choose from any of these 5 gridding algorithms. Please refer to the User Manual for more detail regarding these algorithms.',
        type: 'categorical',
        options: mappingMethodOptions,
        default: 'nearest'
      },
      recentGorMethod: {
        display: 'Recent GOR Mapping Algorithm',
        subDisplay: '',
        description: 'Maps data within recent time frame (defined in Data Integration settings). You may choose from any of these 5 gridding algorithms. Please refer to the User Manual for more detail regarding these algorithms.',
        type: 'categorical',
        options: mappingMethodOptions,
        default: 'nearest'
      },
      cumProductionMethod: {
        display: 'Cumulative Production Mapping Method',
        subDisplay: '',
        description: 'Maps data within recent time frame (defined in Data Integration settings). You may choose from any of these 5 gridding algorithms. Please refer to the User Manual for more detail regarding these algorithms.',
        type: 'categorical',
        options: mappingMethodOptions,
        default: 'natural'
      },
      currentProductionMethod: {
        display: 'Current Production Mapping Method',
        subDisplay: '',
        description: 'Maps data within recent time frame (defined in Data Integration settings). You may choose from any of these 5 gridding algorithms. Please refer to the User Manual for more detail regarding these algorithms.',
        type: 'categorical',
        options: mappingMethodOptions,
        default: 'natural'
      },
    },
    threeDModelGeneration: {
      name: '3D Model Generation',
      path: '/training/manual/modules/recompletions/settings/attributeMapping',
      layersPerZone: {
        display: 'Layers Per Zone',
        subDisplay: '',
        description: 'TODO - Add a description',
        path: 'Settings.GeoModel.ThreeDDerivation.intervalPerZone',
        type: 'numerical',
        default: '4',
      }
    },
    step: 4
  },
  searchDomainDefinition: {
    regionOfInterest: {
      name: 'Region Of Interest',
      path: '/training/manual/modules/horizontals/settings/searchDomainDefinition',
      Table: {
        default: [],
        header: '',
        columns: [{
          Header: 'X (meters)',
          accessor: 'x',
          sortable: false,
          type: 'numerical',

        }, {
          Header: 'Y (meters)',
          accessor: 'y',
          sortable: false,
          type: 'numerical',
        }],
      },
    },
    coiGrids: {
      name: 'Depth of Interest',
      path: '/training/manual/modules/horizontals/settings/searchDomainDefinition',
      shouldTargetZones: {
        display: 'Manually Specify Target Zones',
        subDisplay: '',
        description: 'Manually specify target zones.  If off, all zones will be selected.',
        path: 'Settings.Horizontals.Coi.targetZones',
        default: false,
        type: 'bool',
      },
      targetZones: {
        display: 'Target Zones',
        subDisplay: '',
        description: 'TODO - add description',
        path: 'Settings.Horizontals.Coi.targetZoneIds',
        type: 'categorical',
        default: ['all'],
        multi: true,
        dependency: ['searchDomainDefinition', 'coiGrids', 'shouldTargetZones', true],
        options: [],
      },
      targetDepthRangeLower: {
        display: 'Deepest Target Depth',
        subDisplay: '',
        description: 'This is the limit for the deepest depth for any outcome horizontal target trajectory (SSTVD). Depths below sea level are expected to be negative.',
        type: 'numerical',
        unit: 'meter',
        default: -4000,
        restrictions: [
          {
            type: 'codependentSettingsLessThan',
            dependency: ['searchDomainDefinition', 'coiGrids', 'targetDepthRangeUpper'],
          }
        ]
      },
      targetDepthRangeUpper: {
        display: 'Shallowest Target Depth',
        subDisplay: '',
        description: 'This is the limit for the shallowest depth for any outcome horizontal target trajectory (SSTVD). Depths below sea level are expected to be negative.',
        type: 'numerical',
        unit: 'meter',
        default: -3000,
        restrictions: [
          {
            type: 'codependentSettingsGreaterThan',
            dependency: ['searchDomainDefinition', 'coiGrids', 'targetDepthRangeLower'],
          }
        ]
      }
    },
    spacingGrids: {
      name: 'Spacing Grids',
      path: '/training/manual/modules/horizontals/settings/searchDomainDefinition',
      externalSpacing: {
        display: 'Spacing to Existing Wells',
        subDisplay: '',
        description: 'Minimum distance to place any new horizontal targets from any existing wells’ trajectories.',
        type: 'numerical',
        path: 'Settings.Pud.Spacing.external',
        unit: 'meters',
        default: 18,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      }
    },
    step: 5,
  },
  rposModeling: {
    // additionalProperties: {
    //   path: '/training/manual/modules/horizontals/settings/horSweetSpotRanking',
    //   Table: {
    //     default: [
    //       {
    //         ntgAttribute: 'phi',
    //         cutoff: .05,
    //         largerThanCutoff: true    
    //       }, 
    //       { 
    //         ntgAttribute: 'sw',
    //         cutoff: .8,
    //         largerThanCutoff: false    
    //       },
    //     ],
    //     header: '',
    //     columns: [{
    //       Header: 'NTG Attributes',
    //       accessor: 'ntgAttribute',
    //       sortable: false,
    //       type: 'categorical',
    //       smartOption: 'horizontal',
    //       options: ntgAttributeOptions
    //     }, {
    //       Header: 'Cutoffs',
    //       accessor: 'cutoff',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Larger Than Cutoff',
    //       accessor: 'largerThanCutoff',
    //       sortable: false,
    //       type: 'bool',
    //     }],
    //   },
    // },
    additionalConstraints: {
      name: 'Additional Constraints',
      path: '/training/manual/modules/horizontals/settings/horSweetSpotRanking',
      contactStandoff: {
        display: 'Contact Standoff',
        subDisplay: '',
        description: 'This is used to create a model property to limit the subsequent target search to depths/areas that are not too close to the contacts (OWC, GOC).  Only model cells at least this amount higher than the OWC for each zone (or lower than the GOC for each zone) will be considered as the in the oil window.',
        type: 'numerical',
        unit: 'meters',
        path: 'Settings.Pud.Others.contactStandoff',
        default: 35,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
      stayAway: {
        display: 'Fault Stay Away',
        subDisplay: '',
        description: 'If on, then the algorithm tends to favor targets away from any fault. Otherwise, it would favor places in closer proximity with a fault. If no distance-to-fault (DTF) grids have been provided, then this has no effect either way.',
        type: 'bool',
        path: 'Settings.Pud.Faults.stayAway',
        default: true,
      },
      distanceCutoff: {
        display: 'Fault Distance Cutoff',
        subDisplay: '',
        description: 'This is used when generating the distance-to-fault (DTF) Index property for the 3D model. If the algorithm favors staying away from fault (above setting "Fault Stay Away" toggled on), then only cells with DTF values larger than this amount will be considered as feasible cells considering fault proximity. If the algorithm favors staying close to faults (above setting "Fault Stay Away" toggled off), then only cells with DTF values smaller than this amount will be considered feasible cells considering fault proximity. If no distance-to-fault (DTF) grids have been provided, then this has no effect either way.',
        type: 'numerical',
        unit: 'meters',
        path: 'Settings.Pud.Faults.distanceCutoff',
        default: 200,
        restrictions: [
          {
            type: 'above',
            min: 0,
          }
        ]
      },
    },
    ranking: {
      name: 'RPOS/Ranking Attributes',
      path: '/training/manual/modules/horizontals/settings/horSweetSpotRanking',
      Table: {
        default: [
          {
            attribute: 'hcpv',
            reverseAttribute: false,
          },
          {
            attribute: 'ntg',
            reverseAttribute: false,
          },
          {
            attribute: 'drainage',
            reverseAttribute: true,
          },
          {
            attribute: 'dtfIndex',
            reverseAttribute: false,
          },
          {
            attribute: 'inOilWindow',
            reverseAttribute: false,
          },
          {
            attribute: 'recentWct',
            reverseAttribute: true,
          },
          {
            attribute: 'recentGor',
            reverseAttribute: true,
          },
        ],
        header: '',
        columns: [{
          Header: 'Attributes',
          accessor: 'attribute',
          sortable: false,
          type: 'categorical',
          smartOption: 'horizontal',
          options: horizontalRankingAttributes,

        }, {
          Header: 'Reverse Attributes',
          accessor: 'reverseAttribute',
          sortable: false,
          type: 'bool',
        }],
      },
    },
    step: 7,
  },
  targetSearch: {
    search: {
      name: 'General Search',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      // Table: {
      //   default: [
      //     {
      //       attribute: 'poe',
      //       objective: 'maximize',
      //     }, 
      //   ],
      //   header: '',
      //   columns: [{
      //     Header: 'Search Objective',
      //     accessor: 'attribute',
      //     sortable: false,
      //     type: 'categorical',
      //     options: attributeOptions
      //   }, {
      //     Header: '',
      //     accessor: 'objective',
      //     sortable: false,
      //     type: 'categorical',
      //     options: [
      //       { label: 'Maximize', value: 'maximize'},
      //       { label: 'Minimize', value: 'minimize'},
      //     ]
      //   }],
      // },
      minTargetCellFraction: {
        display: 'Min Target Cell Fraction',
        subDisplay: '',
        description: 'This used to specify the minimum fraction of positive relative probabiliyt of succes (RPOS) cells along any outcome target trajectory. For example, if this value is 0.5, it means the target is still valid as long as more than 50% of the cells it penetrates have positive RPOS values.',
        type: 'numerical',
        path: 'Settings.Horizontals.Search.minTargetCellFraction',
        default: 0.8,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 1,
          }
        ]
      }
    },
    noGoCells: {
      name: 'No Go Cells',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      Table: {
        default: [
          {
            property: 'spacing',
            cutoff: 1,
            largerThanCutoff: false
          },
          {
            property: 'drainage',
            cutoff: 0,
            largerThanCutoff: true
          },
          {
            property: 'dtfIndex',
            cutoff: 1,
            largerThanCutoff: false
          },
          {
            property: 'inOilWindow',
            cutoff: 1,
            largerThanCutoff: false
          },
        ],
        header: '',
        columns: [{
          Header: 'Properties',
          accessor: 'property',
          sortable: false,
          type: 'categorical',
          smartOption: 'horizontal',
          options: noGoCellProperties,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
    },
    optimization: {
      name: 'Optimization',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      initialGuessNumber: {
        display: 'Initial Guess Number',
        subDisplay: '',
        description: 'This number specifies the number of initial targets to search for. The larger the number, the more targets the engine will initially search for. Larger numbers also means this target search step and the subsequent step "Interference Analysis" will take longer.',
        type: 'numerical',
        path: 'Settings.Horizontals.Optimization.InitGuessNumber',
        default: 1000,
        restrictions: [
          {
            type: 'above',
            min: 0,
          },
          {
            type: 'integer',
          }
        ]
      },
      skipCellsForInterpolation: {
        display: 'Skip Cells For Interpolation',
        subDisplay: '',
        description: 'This setting is meant for particularly large models with lots of cells, which can take a long time to process. If On, the interpolation from spatial coordinates to cell indices will be performed based on a maximum of 3 million cells. If the model contains more than 3 million cells, it will automatically skip cells for interpolation to keep the total number below 3 million.',
        type: 'bool',
        path: 'Settings.Horizontals.Optimization.skipCellsForInterpolation',
        default: true,
      },
    },
    configuration: {
      name: 'Lateral Well Constraints',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      stayInZone: {
        display: 'Stay In Zone',
        subDisplay: '',
        description: 'If On, each identified target could not cross any zone boundary (and therefore each target will target only one zone).',
        type: 'bool',
        path: 'Settings.Horizontals.Configuration.stayInZone',
        default: true,
      },
      azimuthRangeLower: {
        display: 'Lower Azimuth Range',
        subDisplay: '',
        description: 'Each horizontal target will have a single azimuth. This specifies the lower range of azimuths that can be gathered as targets.',
        unit: 'degrees',
        type: 'numerical',
        default: 10,
        restrictions: [
          {
            type: 'range',
            min: -180,
            max: 180
          },
          {
            type: 'codependentSettingsLessThan',
            dependency: ['targetSearch', 'configuration', 'azimuthRangeUpper'],
          }
        ]
      },
      azimuthRangeUpper: {
        display: 'Upper Azimuth Range',
        subDisplay: '',
        description: 'Each horizontal target will have a single azimuth. This specifies the upper range of azimuths that can be gathered as targets.',
        unit: 'degrees',
        type: 'numerical',
        default: 80,
        restrictions: [
          {
            type: 'range',
            min: -180,
            max: 180
          },
          {
            type: 'codependentSettingsGreaterThan',
            dependency: ['targetSearch', 'configuration', 'azimuthRangeLower'],
          }
        ]
      },
      lengthRangeLower: {
        display: 'Lower Length Range',
        subDisplay: '',
        description: 'This specifies the minimum length that a horizontal target can be.',
        unit: 'meters',
        type: 'numerical',
        default: 500,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
          {
            type: 'codependentSettingsLessThan',
            dependency: ['targetSearch', 'configuration', 'lengthRangeUpper'],
          }
        ]
      },
      lengthRangeUpper: {
        display: 'Upper Length Range',
        subDisplay: '',
        description: 'This specifies the maximum length that a horizontal target can be.',
        unit: 'meters',
        type: 'numerical',
        default: 1000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
          {
            type: 'codependentSettingsGreaterThan',
            dependency: ['targetSearch', 'configuration', 'lengthRangeLower'],
          }
        ]
      },
      deviationRangeLower: {
        display: 'Lower Deviation Range',
        subDisplay: '',
        description: 'Each horizontal target will have a single deviation (i.e. inclination). This specifies the lowest inclination target that can be searched for.',
        unit: 'degrees',
        type: 'numerical',
        default: 85,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 180,
          },
          {
            type: 'codependentSettingsLessThan',
            dependency: ['targetSearch', 'configuration', 'deviationRangeUpper'],
          }
        ]
      },
      deviationRangeUpper: {
        display: 'Upper Deviation Range',
        subDisplay: '',
        description: 'Each horizontal target will have a single deviation (i.e. inclination). This specifies the highest inclination target that can be searched for.',
        unit: 'degrees',
        type: 'numerical',
        default: 95,
        restrictions: [
          {
            type: 'range',
            min: 0,
            max: 180,
          },
          {
            type: 'codependentSettingsGreaterThan',
            dependency: ['targetSearch', 'configuration', 'deviationRangeLower'],
          }
        ]
      },
    },
    targetCells: {
      name: 'Target Cells',
      path: '/training/manual/modules/horizontals/settings/horTargetSearch',
      Table: {
        default: [
          {
            property: 'pos',
            cutoff: .8,
            largerThanCutoff: true,
          },
        ],
        header: '',
        columns: [{
          Header: 'Properties',
          accessor: 'property',
          sortable: false,
          type: 'categorical',
          smartOption: 'horizontal',
          options: horizontalTargetCellOptions,

        }, {
          Header: 'Cutoff',
          accessor: 'cutoff',
          sortable: false,
          type: 'numerical',
        }, {
          Header: 'Larger than cutoff',
          accessor: 'largerThanCutoff',
          sortable: false,
          type: 'bool',
        }],
      },
    },
    // Mentioned here to remove user opportunities: https://qrigroup.atlassian.net/browse/SJ-357
    // userOpportunities: {
    //   path: '/training/manual/modules/horizontals/settings/horTargetSearch',
    //   Table: {
    //     default: [
    //     ],
    //     header: '',
    //     columns: [{
    //       Header: 'Heel X',
    //       accessor: 'x1',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Toe X',
    //       accessor: 'x2',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Heel Y',
    //       accessor: 'y1',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Toe Y',
    //       accessor: 'y2',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Heel Z',
    //       accessor: 'z1',
    //       sortable: false,
    //       type: 'numerical',
    //     }, {
    //       Header: 'Toe Z',
    //       accessor: 'z2',
    //       sortable: false,
    //       type: 'numerical',
    //     }],
    //   },
    // },
    step: 8,
  },
  interferenceAnalysis: {
    spacingHor: {
      name: 'Min. Spacing Between Horizontal Targets',
      path: '/training/manual/modules/horizontals/settings/interferenceAnalysis',
      internalSpacing: {
        display: 'Internal Spacing',
        subDisplay: '',
        description: 'This value specifies the minimum closest distance between two outcome trajectories for them to be considered not interfering with each other. ',
        unit: 'meters',
        type: 'numerical',
        path: 'Settings.Pud.Spacing.internal',
        default: 60,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          }
        ]
      },
    },
    conflicts: {
      name: 'Conflicts',
      path: '/training/manual/modules/horizontals/settings/interferenceAnalysis',
      candidatesToResolve: {
        display: 'Candidates to Resolve',
        subDisplay: '',
        description: 'Maximum number of targets to be resolved within the interference analysis. For example, if 5000 initial targets were found but this setting is 100, then this step would take the top 100 targets (in terms of RPOS value) and evaluate those targets for interference (create a subset of those 100 targets that do not interfere). Thus, after this interference step, the resulting number of targets in the inventory will be less than or equal to 100.',
        unit: '',
        type: 'numerical',
        path: 'Settings.Horizontals.Conflicts.nCandidatesToResolve',
        default: 5000,
        restrictions: [
          {
            type: 'above',
            min: 0,
          },
          {
            type: 'integer'
          }
        ]
      },
      // resolutionAttribute: {
      //   display: 'Resolution Attribute',
      //   subDisplay: '',
      //   description: '',
      //   type: 'categorical',
      //   options: attributeOptions,
      //   path: 'Settings.Horizontals.Conflicts.resolutionAttribute',
      //   default: 'pos',
      // }, 
      selectionAlgorithm: {
        display: 'Selection Algorithm',
        subDisplay: '',
        description: 'The algorithm to be used for interference analysis. Please refer to the user manual for details regarding these two approaches.',
        type: 'categorical',
        options: [
          { label: 'HLWGF', value: 'HLWGF' },
          { label: 'HGF', value: 'HGF' },
        ],
        path: 'Settings.Horizontals.Conflicts.selectionAlgorithm',
        default: 'HLWGF',
      },
      xAxisWeight: {
        display: 'X Axis Weight',
        subDisplay: '',
        description: 'X axis weights for interference distance. For example, a higher X-weight allows candidates to be closer along the x-axis without interfering.',
        type: 'numerical',
        default: 1,
      },
      yAxisWeight: {
        display: 'Y Axis Weight',
        subDisplay: '',
        description: 'Y axis weights for interference distance. For example, a higher Y-weight allows candidates to be closer along the y-axis without interfering.',
        type: 'numerical',
        default: 1,
      },
      zAxisWeight: {
        display: 'Z Axis Weight',
        subDisplay: '',
        description: 'Z axis weights for interference distance. For example, a higher Z-weight allows candidates to be closer along the z-axis without interfering',
        type: 'numerical',
        default: 2,
      },
    },
    step: 9,
  },
  productionForecast: {
    productionForecast: {
      name: 'Production Forecast',
      path: '/training/manual/modules/recompletions/settings/initialProductionRateForecast',
      helperText: 'Toggle the settings that you want to use.',
      shouldUseJOSHI: {
        display: 'Should Use Joshi', //TODO - add this
        subDisplay: '',
        description: 'This is a steady-state flow model developed by Joshi (1988). Please refer to the user manual for more detail on this method. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: horizontal wellbore length, horizontal permeability, horizontal/vertical permeability ratio, oil viscosity, FVF, pressure at drainage boundary, fBHP, half length of drainage ellipse in the horizontal plane, skin factor.',
        type: 'bool',
        default: true,
      },
      shouldUseFURUI: {
        display: 'Should Use FURUI', //TODO - add this
        subDisplay: '',
        description: 'This is a steady-state analytics flow model for horizontal well inflow. Please refer to the user manual for more detail on this method. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: horizontal wellbore length, horizontal permeability, horizontal/vertical permeability ratio, oil viscosity, FVF, pressure at drainage boundary, fBHP, distance to the drainage boundary in the Y direction, partial penetration skin factor.',
        type: 'bool',
        default: false,
      },
      shouldUseBABU: {
        display: 'Should Use Babu', //TODO - add this
        subDisplay: '',
        description: 'These pseudo steady-state models of inflow performance presumes that the reservoir is bounded by no-flow boundaries and pressure declines in a uniform fashion in the reservoir. Please refer to the user manual for more detail on this method. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: horizontal wellbore length, horizontal permeability, horizontal/vertical permeability ratio, oil viscosity, FVF, pressure at drainage boundary, fBHP, wellbore configuration w.r.t. the drainage area, skin factor.',
        type: 'bool',
        default: false,
      },
      shouldUseECONOMIDES: {
        display: 'Should Use Economides', //TODO - add this
        subDisplay: '',
        description: 'This is a completely general model for one or more horizontal wells or laterals developed by integrating unit length point sources in no-flow boundary boxes to create an arbitrary well trajectory or trajectories. Please refer to the user manual for more detail on this method. Requires having good estimates on the physical parameters used for this production forecast method. The parameters include: horizontal wellbore length, horizontal permeability, horizontal/vertical permeability ratio, oil viscosity, FVF, pressure at drainage boundary, fBHP, reservoir length, vertical distance from wellbore to bottom of payzone, share factor, skin factor.',
        type: 'bool',
        default: false,
      },
      shouldUseSML: {
        display: 'Apply Speedwise Machine Learning (SML)',
        description: 'Use SpeedWise Machine Learning for opportunities\' production forecast.',
        type: 'bool',
        default: false,
      },
      smlAPIKey: {
        display: 'Speedwise Machine Learning (SML) API Key',
        description: undefined,
        dependency: ['productionForecast', 'productionForecast', 'shouldUseSML', true],
        path: 'Settings.Forecast.SMLAPI',
        type: 'string',
      },
      primaryMethod: {
        display: 'Primary Method',
        subDisplay: '',
        description: 'The primary method is what will be used to display in visualizations and in subsequent filtering if chosen. However, other forecast methods toggled on will be evaluated and then will be available in the CSV download so you can compare the different forecast methods outside of the platform.',
        path: 'Settings.Horizontals.Forecast.primaryMethod',
        type: 'categorical',
        options: [
          { label: 'SML', value: 'SML' },
          { label: 'JOSHI', value: 'JOSHI' },
          { label: 'FURUI', value: 'FURUI' },
          { label: 'BABU', value: 'BABU' },
          { label: 'ECONOMIDES', value: 'ECONOMIDES' },
        ],
        default: 'JOSHI'
      },

    },
    // settingsforThicknessNormalizedMethods: {
    //   name: 'Settings for Thickness Normalized Methods',
    //   path: '/training/manual/modules/recompletions/settings/thicknessNormalized',
    //   normalizationAttribute: {
    //     display: 'Normalization Attribute',
    //     subDisplay: '',
    //     description: 'Thickness attribute used for normalization',
    //     path: 'Settings.Forecast.Normalization.attribute',
    //     type: 'categorical',
    //     options: [
    //       { label: 'PNP', value: 'pnp' },
    //       { label: 'Perf Length', value: 'perfLength' },
    //       { label: 'PNP Hcpt', value: 'pnpHcpt' },
    //     ],
    //     default: 'pnp'
    //   },
    //   expandNeighborhood: {
    //     display: 'Expand Neighborhood',
    //     subDisplay: '',
    //     description: 'If true, neighborhood wells missing norm attribute will be replaced with other neighborhood wells (when spatial radius is based on N points)',
    //     path: 'Settings.Forecast.Normalization.expandNeighborhood',
    //     type: 'bool',
    //     default: false,
    //   },
    // },
    // neighborhoodSettings: {
    //   name: 'Neighborhood Settings',
    //   path: '/training/manual/modules/recompletions/settings/neighborhoodSettings',
    //   temporalScale: {
    //     display: 'Temporal Scale',
    //     subDisplay: '',
    //     description: `Number of recent years`,
    //     path: 'Settings.Neighborhood.Temporal.globalScale',
    //     type: 'numerical',
    //     unit: 'year',
    //     default: 15,
    //     restrictions: [
    //       {
    //         type: 'above',
    //         min: 0,
    //       }
    //     ]
    //   },
    //   type: {
    //     display: 'Spatial Type',
    //     subDisplay: '',
    //     description: 'This setting determines whether the neighborhood data is restricted to neighbors within a certain distance (radius) or if the neighborhood is restricted to a certain number of closest neighbors, regardless of distance (points).',
    //     path: 'Settings.Neighborhood.Spatial.type',
    //     type: 'categorical',
    //     options: [
    //       { label: 'Radius', value: 'radius' },
    //       { label: 'Points', value: 'points' },
    //     ],
    //     default: 'points'
    //   },
    //   spatialScale: {
    //     display: 'Spatial Scale',
    //     subDisplay: '',
    //     description: `Defines the areal extent of the neighborhood at zone level.`,
    //     unit: 'Radius size (meters) or number of points',
    //     path: 'Settings.Neighborhood.Spatial.globalScale',
    //     type: 'numerical',
    //     default: 15,
    //   },
    //   matchWellGroup: {
    //     display: 'Match Well Group',
    //     subDisplay: '',
    //     description: 'If on, this will limit neighborhood wells based on the options selected in well groups to set.',
    //     path: 'Settings.Neighborhood.matchWellGroup',
    //     type: 'bool',
    //     default: false,
    //   },
    //   wellGroupSetsToMatch: {
    //     display: 'Well Group Sets to Match',
    //     subDisplay: '',
    //     description: 'Select which attributes to match on for neighborhood wells.',
    //     dependency: ['productionForecast', 'neighborhoodSettings', 'matchWellGroup', true],
    //     path: 'Settings.Neighborhood.wellGroupSetsToMatch',
    //     type: 'categorical',
    //     multi: true,
    //     options: [
    //       { label: 'Well Type', value: 'Well Type' },
    //     ],
    //     default: ['Well Type']
    //   },
    //   useZoneAllocatedRates: {
    //     display: 'Use Zone Allocated Rates',
    //     subDisplay: '',
    //     description: 'If on, uses the allocated production rates specific to each zone. Incorrect allocation will almost certainly lead to wrong initial rate forecasts when using statistical methods. It is recommended to use PLT data for allocation when available.',
    //     path: 'Settings.Neighborhood.useZoneAllocatedRate',
    //     type: 'bool',
    //     default: true,
    //   },
    //   neighborhoodPerformance: {
    //     display: 'Neighborhood Performance',
    //     subDisplay: '',
    //     description: `This defines the neighborhood to use to calculate the probability of exceedance (POE), which is a statistical metric describing the probability that a particular value (in this case, the predicted IP) will be met or exceeded.`,
    //     path: 'Settings.POE.neighborhoodPerformance',
    //     type: 'categorical',
    //     options: [
    //       { label: 'Initial Rates', value: 'initialRates' },
    //       { label: 'Current Rates', value: 'currentRates' },
    //     ],
    //     default: 'currentRates'
    //   },
    // },
    // interpolationSettings: {
    //   name: 'Interpolation Settings',
    //   path: '/training/manual/modules/recompletions/settings/interpolationSettings',
    //   timeWeight: {
    //     display: 'Time Weight',
    //     subDisplay: '',
    //     description: `Weight of time dimension. For example, if this is set to 0.5 and 'Distance Weight' is also 0.5 then time and distance will be weighted equally.`,
    //     path: 'Settings.Interpolation.timeWeight',
    //     type: 'numerical',
    //     default: 0.5,
    //     restrictions: [
    //       {
    //         type: 'range',
    //         min: 0,
    //         max: 1,
    //       },
    //       {
    //         type: 'codependentSettingsSum',
    //         dependency: ['productionForecast', 'interpolationSettings', 'distanceWeight'],
    //         total: 1,
    //       }
    //     ]
    //   },
    //   distanceWeight: {
    //     display: 'Distance Weight',
    //     subDisplay: '',
    //     description: `Weight of spatial dimension. For example, if this is set to 0.5 and 'Time Weight' is also 0.5 then time and distance will be weighted equally.`,
    //     path: 'Settings.Interpolation.distanceWeight',
    //     type: 'numerical',
    //     default: 0.5,
    //     restrictions: [
    //       {
    //         type: 'range',
    //         min: 0,
    //         max: 1,
    //       },
    //       {
    //         type: 'codependentSettingsSum',
    //         dependency: ['productionForecast', 'interpolationSettings', 'timeWeight'],
    //         total: 1,
    //       }
    //     ]
    //   },
    //   method: {
    //     display: 'Method',
    //     subDisplay: '',
    //     description: 'Interpolation weighting function. Inverse distance weighting (IDW) is a type of deterministic method for multivariate interpolation with a known scattered set of points.',
    //     path: 'Settings.Interpolation.method',
    //     type: 'categorical',
    //     options: [
    //       { label: 'IDW', value: 'idw' },
    //       { label: 'Gaussian', value: 'gaussian' },
    //     ],
    //     default: 'gaussian'
    //   },
    // },
    parameters: {
      name: 'Analytical Model Parameters for Horizontal Wells',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      horizontalWellboreRadius: {
        display: 'Horizontal Wellbore Radius',
        subDisplay: '',
        description: 'Wellbore radius of the proposed horizontal well (in meters)',
        unit: 'meters',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.wellboreRadius',
        default: 0.091,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      horizontalPermeability: {
        display: 'Horizontal Permeability',
        subDisplay: '',
        description: 'Horizontal permeability of the reservoir (in mD)',
        unit: 'mD',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.horzPerm',
        default: 1000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      ratioOfKvKh: {
        display: 'Ratio of Kv/Kh',
        subDisplay: '',
        description: 'Ratio between the vertical and horizontal permeability of the reservoir',
        unit: '',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.vert2HorzPermRatio',
        default: 0.1,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      oilViscosity: {
        display: 'Oil Viscosity',
        subDisplay: '',
        description: 'Representative oil viscosity throughout the reservoir (in cp)',
        unit: 'cp',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.oilViscosity',
        default: 5,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      formationVolumeFactor: {
        display: 'Formation Volume Factor',
        subDisplay: '',
        description: 'Representative oil formation volume factor throughout the reservoir.',
        unit: '',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.bo',
        default: 1.1,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      pressureDrainageBoundary: {
        display: 'Pressure at Drainage Boundary',
        subDisplay: '',
        description: 'Representative reservoir pressure at the drainage boundary (in PSI)',
        unit: 'psi',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.Pressure.reservoir',
        default: 5000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      flowingBottomHolePressure: {
        display: 'Flowing Bottom Hole Pressure',
        subDisplay: '',
        description: 'Representative flowing bottomhole pressure throughout the reservoir (in PSI)',
        unit: 'psi',
        type: 'numerical',
        path: 'Settings.Horizontals.Forecast.Pressure.flowing',
        default: 4000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      // bubblePointPressure: {
      //   display: 'Bubble Point Pressure',
      //   subDisplay: '',
      //   description: '',
      //   unit: 'psi',
      //   type: 'numerical',
      //   path: 'Settings.Horizontals.Forecast.Pressure.bubblePoint',
      //   default: 2500,
      // }, 
    },
    completionDataInitialization: {
      name: 'Completion Data Initialization',
      path: null,
      dependency: ['productionForecast', 'productionForecast', 'smlAPIKey', 'exists'],
      Table: {
        default: [{
          attributeName: 'predictedIp',
          value: 5
        }],
        header: '',
        columns: [{
          Header: 'Attribute Name',
          accessor: 'attributeName',
          sortable: false,
          type: 'input',
        }, {
          Header: 'Value',
          accessor: 'value',
          sortable: false,
          type: 'numerical',
        }],
      },
    },
    joshiParameters: { // TODO - these all need better descriptions
      name: 'Joshi Parameters',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseJOSHI', 'exists'],
      drainageEllipse: {
        display: 'Half Length of Drainage Ellipse in the Horizontal Plane',
        subDisplay: '',
        description: `Half Length of Drainage Ellipse in the Horizontal Plane (in meters)`,
        path: 'Settings.Horizontals.Forecast.Joshi.a',
        type: 'numerical',
        unit: 'meters',
        default: 2000,
        restrictions: [
          {
            type: 'aboveOrEqual',
            min: 0,
          },
        ]
      },
      s: {
        display: 'S',
        subDisplay: '',
        description: `Skin factor`,
        path: 'Settings.Horizontals.Forecast.Joshi.S',
        type: 'numerical',
        unit: '',
        default: 0,
      },
    },
    furuiParameters: { // TODO - these all need better descriptions
      name: 'Furui Parameters',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseFURUI', true],
      distanceDrainageBoundary: {
        display: 'Distance to the Drainage Boundary in the Y Direction',
        subDisplay: '',
        description: `Distance to the Drainage Boundary in the Y Direction (in meters)`,
        path: 'Settings.Horizontals.Forecast.Furui.Yb',
        type: 'numerical',
        unit: 'meters',
        default: 707,
      },
      partialPenetrationSkinFactor: {
        display: 'Partial Penetration Skin Factor',
        subDisplay: '',
        description: `Partial Penetration Skin Factor`,
        path: 'Settings.Horizontals.Forecast.Furui.SR',
        type: 'numerical',
        unit: '',
        default: 14,
      }
    },
    BabuParameters: { // TODO - these all need better descriptions
      name: 'Babu Parameters',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseBABU', true],
      widthRectangularDrainageBox: {
        display: 'Width of Rectangular Drainage Box',
        subDisplay: '',
        description: `Width of Rectangular Drainage Box (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.a',
        type: 'numerical',
        unit: 'meters',
        default: 2000,
      },
      lengthRectangularDrainageBox: {
        display: 'Length of Rectangular Drainage Box',
        subDisplay: '',
        description: `Length of Rectangular Drainage Box (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.b',
        type: 'numerical',
        unit: 'meters',
        default: 3000,
      },
      xCoordWellInitialPoint: {
        display: 'X-coordinate of Well Initial Point',
        subDisplay: '',
        description: `X-coordinate of Well Initial Point (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.x1',
        type: 'numerical',
        unit: 'meters',
        default: 100,
      },
      xCoordWellLastPoint: {
        display: 'X-coordinate of Well Last Point',
        subDisplay: '',
        description: `X-coordinate of Well Last Point (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.x2',
        type: 'numerical',
        unit: 'meters',
        default: 2100,
      },
      yCoordWell: {
        display: 'Y-coordinate of Well',
        subDisplay: '',
        description: `Y-coordinate of Well (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.y0',
        type: 'numerical',
        unit: 'meters',
        default: 1000,
      },
      zCoordWell: {
        display: 'Z-coordinate of Well',
        subDisplay: '',
        description: `Z-coordinate of Well (in meters)`,
        path: 'Settings.Horizontals.Forecast.Babu.z0',
        type: 'numerical',
        unit: 'meters',
        default: 50,
      },
      anyOtherSkinFactor: {
        display: 'Any Other Skin Factor',
        subDisplay: '',
        description: `Any Other Skin Factor`,
        path: 'Settings.Horizontals.Forecast.Babu.S',
        type: 'numerical',
        unit: '',
        default: 2,
      },
    },
    economidesParameters: { // TODO - these all need better descriptions
      name: 'Economides Parameters',
      path: '/training/manual/modules/horizontals/settings/otherProductionForecast',
      dependency: ['productionForecast', 'productionForecast', 'shouldUseECONOMIDES', true],
      reservoirLength: {
        display: 'Reservoir Length',
        subDisplay: '',
        description: `Reservoir Length (in meters)`,
        path: 'Settings.Horizontals.Forecast.Economides.Xe',
        type: 'numerical',
        unit: 'meters',
        default: 4000,
      },
      verticalDistanceFromWellboreToPayzone: {
        display: 'Vertical Distance from the Wellbore to Bottom of the Payzone',
        subDisplay: '',
        description: `Vertical Distance from the Wellbore to Bottom of the Payzone (in meters)`,
        path: 'Settings.Horizontals.Forecast.Economides.Zw',
        type: 'numerical',
        unit: 'meters',
        default: 14,
      },
      shapeFactor: {
        display: 'Share Factor',
        subDisplay: '',
        description: `Shape Factor`,
        path: 'Settings.Horizontals.Forecast.Economides.CH',
        type: 'numerical',
        unit: '',
        default: 1,
      },
      anyOtherSkinFactor: {
        display: 'Any Other Skin Factor',
        subDisplay: '',
        description: `Any Other Skin Factor`,
        path: 'Settings.Horizontals.Forecast.Economides.S',
        type: 'numerical',
        unit: '',
        default: 0,
      },
    },
    step: 10,
  },
  filterFinalize: {
    filterDrillLocation: {
      name: 'Filter Drill Location',
      path: '/training/manual/modules/horizontals/settings/horFilterFinalize',
      Table: {
        default: [{
          filterAttribute: 'predictedIp',
          filterValue: 5
        }],
        header: '',
        columns: [{
          Header: 'Horizontals Filter Attributes',
          accessor: 'filterAttribute',
          sortable: false,
          type: 'categorical',
          options: horizontalFilterAttributes,
        }, {
          Header: 'Horizontals Filter Value',
          accessor: 'filterValue',
          sortable: false,
          type: 'numerical',
        }],
      },
    },
    step: 11,
  },
}

export const modelBuilderSettingsMap = {
  flowUnitAllocation: {
    display: 'Flow Unit Allocation',
    step: 1,
    subcategories: {
      flowUnitSetup: {
        display: 'Flow Unit Setup',
        description: {
          prefix: 'Flow allocation based on',
          suffix: '',
          variable: '',
        },
        settings: {
          setup: {
            display: 'Setup',
            subDisplay: '',
            description: "'Kh' also known as the Darcy Model is recommended for most reservoirs. 'Chan' uses Chan's water behavior identification algorithm, and can be recommened for fractured reservoirs with channeling and coning. PLEASE NOTE: Regardless of this 'Setup' choice, if there are PLTs uploaded, then the default will be to use them.",
            path: 'Settings.EngineeringSettings.FlowUnitAllocation.setup',
            type: 'categorical',
            multi: false,
            options: [
              { label: 'Chan', value: 'chan' },
              { label: 'Kh', value: 'kh' },
              { label: 'User Allocated (dataset must have the file “Well Production Monthly Allocated”)', value: 'userAllocated' },
            ],
            default: 'kh',
          }
        }
      },
      waterBehavior: {
        display: 'Water Behavior',
        dependency: {
          path: 'Settings.EngineeringSettings.FlowUnitAllocation.setup',
          val: 'chan',
        },
        settings: {
          differenciationMethod: {
            display: 'Differenciation Method',
            subDisplay: '',
            description: 'The method used for the differentiation of the water oil ratio',
            path: 'Settings.EngineeringSettings.WaterBehaviorID.differenciationMethod',
            type: 'categorical',
            options: [
              { label: 'Bourdet', value: 'bourdet' },
              { label: 'Forward', value: 'forward' },
              { label: 'Backward', value: 'backward' },
            ],
            default: 'bourdet',
          },
          eventsDetectionData: {
            display: 'Events Detection Data',
            subDisplay: '',
            description: 'Identifies the choice of data to be use to detect changes in data trend: 1) derivative of water oil ratio, 2) water oil ratio, 3) both derivative of water oil ratio, and water oil ratio',
            path: 'Settings.EngineeringSettings.WaterBehaviorID.eventsDetectionData',
            type: 'categorical',
            options: [
              { label: 'Deriv. of WOR', value: 1 },
              { label: 'WOR', value: 2 },
              { label: 'Both', value: 3 },
            ],
            default: 3,
          },
          eventsDetectionSensitivity: {
            display: 'Events Detection Sensitivity',
            subDisplay: '',
            description: 'Sensitivity for detecting changes in the trend of the water oil ratio derivative. If sensitivity is 1, only the major trends are detected.',
            path: 'Settings.EngineeringSettings.WaterBehaviorID.eventsDetectionSensitivity',
            type: 'numerical',
            default: 0.9,
          },
          minFitPeriodDuration: {
            display: 'Min Fit Period Duration',
            subDisplay: 'Min Value must be greater than 0',
            description: 'Number of months  identifying the minimum duration of a fit period (if the duration is smaller than the minimum, the fit period is merged with the preceding period',
            path: 'Settings.EngineeringSettings.WaterBehaviorID.minFitPeriodDuration',
            type: 'numerical',
            unit: 'month(s)',
            default: 8,
          },
          shutInPeriodDurationThreshold: {
            display: 'Shut In Period Duration Threshold',
            subDisplay: 'Min Value must be greater than 0',
            description: 'Number of months identifying a duration threshold',
            path: 'Settings.EngineeringSettings.WaterBehaviorID.shutInPeriodDurationThreshold',
            type: 'numerical',
            unit: 'month(s)',
            default: 0,
          },
          smoothLevel: {
            display: 'Smooth Level',
            subDisplay: 'Min Value must be greater than 0',
            description: 'A constant, for water to oil ratio curve fitting',
            path: 'Settings.EngineeringSettings.WaterBehaviorID.smoothLevel',
            type: 'numerical',
            default: 20,
          },
          smoothingMethodIndex: {
            display: 'Smoothing Method Index',
            subDisplay: '',
            description: 'An array that lists the choice of the smoothing methods to be used for the treatment of WOR data',
            path: 'Settings.EngineeringSettings.WaterBehaviorID.smoothingMethodIndex',
            type: 'categorical',
            multi: true,
            options: [
              { label: 'Moving Average', value: 1 },
              { label: 'Locally Weighted Scatterplot Smoothing', value: 2 },
              { label: 'Locally Estimated Scatterplot Smoothing', value: 3 },
              { label: 'Savitzky-Golay', value: 4 },
              { label: 'Robust Locally Weighted Scatterplot Smoothing', value: 5 },
              { label: 'Robust Locally Estimated Scatterplot Smoothing', value: 6 },
              { label: 'Perfect Smooth', value: 7 },
            ],
            default: [1, 5, 6, 7],
          },
          weightReiIncreaseLastPeriod: {
            display: 'Weight Rei Increase Last Period',
            subDisplay: 'Value must be between 0 and 100',
            description: 'Relative weight increase of the last period when calculating misfit to raw data. This is to give more weight to the last period when identifying the best smoothing method. To leave the weight equaly distributed between all fit periods, set to zero.',
            path: 'Settings.EngineeringSettings.WaterBehaviorID.weightRelIncreaseLastPeriod',
            type: 'numerical',
            unit: '%',
            default: 50,
          },
        }
      },
    }
  },
  grids: {
    display: 'Grids',
    step: 2,
    subcategories: {
      geometry: {
        display: 'Geometry',
        description: {
          prefix: 'Grid is descretized to ',
          suffix: ' cells',
          variable: '',
        },
        settings: {
          numGridCells: {
            display: 'Number of Grid Cells',
            subDisplay: 'Min. 50,000, Max 300,000',
            description: '', //TODO
            path: 'Settings.Config.config_struct.numberCells',
            type: 'numerical',
            default: 100000,
          },
          sizeWellCells: {
            display: 'Size of Well Cells',
            subDisplay: 'Min. 50, Max 1,000',
            description: '', //TODO
            path: 'Settings.Config.config_struct.hBase',
            type: 'numerical',
            default: 200,
          },
          numSublayers: {
            display: 'Number of sublayers per zone',
            subDisplay: 'Min. 2, Max 5',
            description: '', //TODO
            path: 'Settings.Config.config_struct.nSubLayer_coeff',
            type: 'numerical',
            default: 2,
          },
          gridRefinementLevel: {
            display: 'Level of grid refinement around cells',
            subDisplay: 'Min. 1, Max 6',
            description: '', //Todo
            path: 'Settings.Config.config_struct.regionWidth_coeff',
            type: 'numerical',
            default: 4,
          },
        }
      },
      propertyMapping: {
        display: 'Property Mapping',
        description: {
          prefix: 'Cross-zone communication factor and vertical to horizontal permeability ratio',
        },
        settings: {
          crossZoneCommunicationFactor: {
            path: 'Settings.Grids.toPopulateGridValuesCZC',
            default: [1]
          },
          vertToHorizontalPermeabilityRatio: {
            path: 'Settings.Grids.toPopulateGridValuesVHPR',
            default: [1]
          },
          faultTransmissibilityMultipliers: {
            path: 'Settings.Grids.fTranMultTable',
            default: []
          }
        }
      },
      aquifer: {
        display: 'Aquifer',
        description: [{
          prefix: 'Aquifer is divided into ',
          suffix: ' partitions. ',
          variable: '',
        }, {
          prefix: ' Water-Oil contact is set to ',
          suffix: ' ft.',
          variable: '',
        }],
        settings: {
          numAquiferRegions: {
            display: 'Number of Aquifer Regions',
            subDisplay: 'Min. 1, Max Infinity',
            description: '', //TODO
            path: 'Settings.Config.config_struct.numberOfAquiferRegions',
            type: 'numerical',
            default: 1,
          },
          aquiferStrength: {
            display: 'Aquifer Strength',
            subDisplay: 'Min. 1, Max 1000',
            description: '', //TODO
            path: 'Settings.Grids.aquiferStrength',
            type: 'numerical',
            default: 1000,
          },
          waterOilContactDepth: {
            display: 'Water-Oil Contact Depth',
            subDisplay: '',
            description: '', //TODO
            path: 'Settings.Config.config_struct.WOcontact',
            type: 'numerical',
            unit: 'ft',
            default: 1,
          },
          aquiferPartitioningAlgorithm: {
            display: 'Aquifer Partitioning Algorithm',
            subDisplay: '',
            description: '', //TODO
            path: 'Settings.Config.config_struct.aquiferSubdivisionType',
            type: 'categorical',
            multi: false,
            options: [
              { label: 'Cluster Based Subdivision', value: 'clusterBasedSubdivision' },
              { label: 'Square Based Subdivision', value: 'squareBasedSubdivision' },
            ],
            default: 'clusterBasedSubdivision',
          },
        }
      },
      wellConfiguration: {
        display: 'Well Configuration',
        description: {
          prefix: 'All wells have ',
          suffix: ' segments',
          variable: '',
        },
        settings: {
          wellConfigurationMethodBool: {
            display: 'Use Default Well Configuration',
            subDisplay: '',
            description: '', //TODO
            path: 'Settings.Config.config_struct.SWM3D',
            type: 'bool',
            default: false,
          },
          numSegmentsWellConfiguration: {
            display: 'Number of Segments Per Well',
            subDisplay: '',
            description: '', //TODO
            dependency: {
              path: 'Settings.Config.config_struct.SWM3D',
              val: false,
            },
            path: 'Settings.Config.config_struct.wellSegments',
            type: 'numerical',
            default: 1,
          },
        }
      },
      // resolutionSurfaceMesh: {
      //   display: 'Resolution of Surface Mesh',
      //   description: {
      //     prefix: 'Settings for resolution of surface mesh visualized on front end',
      //   },
      //   settings: {
      //     nXCoarseSurf: {
      //       display: 'Number of X Coarse Surf',
      //       subDisplay: '',
      //       description: '', //TODO
      //       path: 'Settings.Config.config_struct.nXCoarseSurf',
      //       type: 'numerical',
      //       default: 100,
      //     },
      //     nYCoarseSurf: {
      //       display: 'Number of Y Coarse Surf',
      //       subDisplay: '',
      //       description: '', //TODO
      //       path: 'Settings.Config.config_struct.nYCoarseSurf',
      //       type: 'numerical',
      //       default: 100,
      //     },
      //   }
      // },
    }
  },
  modelSetup: {
    display: 'Model Setup',
    step: 3,
    subcategories: {
      fluidProperties: {
        display: 'Fluid Properties',
        description: {
          prefix: '',
          suffix: '',
          variable: '',
        },
        settings: {
          waterFormationVolumeFactor: {
            display: 'Water Formation Volume Factor',
            subDisplay: 'Min. 1, Max. 1.5',
            description: '', //TODO
            path: 'Settings.Config.Fluid.Bw',
            type: 'numerical',
            default: 1,
          },
          oilFormationVolumeFactor: {
            display: 'Oil Formation Volume Factor',
            subDisplay: 'Min. 1, Max. 1.5',
            description: '', //TODO
            path: 'Settings.Config.Fluid.Bo',
            type: 'numerical',
            default: 1,
          },
          compressibilityEffects: {
            display: 'Compressibility Effects',
            subDisplay: '',
            description: '', //TODO
            path: 'Settings.Config.SolverOptions.solve_all_pressure_together',
            type: 'categorical',
            multi: false,
            options: [
              { label: 'Incompressible', value: 1 },
              { label: 'Slightly Compressible', value: 0 },
            ],
            default: 1,
          },
          totalCompressibility: {
            display: 'Total Compressibility',
            subDisplay: 'Min. 0, Max 10',
            description: '', //TODO
            dependency: {
              path: 'Settings.Config.SolverOptions.solve_all_pressure_together',
              val: 0,
            },
            path: 'Settings.Config.Fluid.compressibility',
            type: 'numerical',
            unit: '10^-6 psi-1',
            default: 1,
          },
          accountForGravity: {
            display: 'Account for Gravity',
            subDisplay: '',
            description: '', //TODO
            dependency: {
              path: 'Settings.Config.SolverOptions.solve_all_pressure_together',
              val: 0,
            },
            path: 'Settings.Config.RunOptions.gravity',
            type: 'bool',
            default: false,
          },
          oilDensity: {
            display: 'Oil Density',
            subDisplay: 'Min. 0.2, Max 1',
            description: '', //TODO
            dependency: {
              path: 'Settings.Config.RunOptions.gravity',
              val: true,
            },
            path: 'Settings.Config.Fluid.den_o',
            type: 'numerical',
            default: .9,
          },
          waterDensity: {
            display: 'Water Density',
            subDisplay: 'Min. 0.2, Max 1',
            description: '', //TODO
            dependency: {
              path: 'Settings.Config.RunOptions.gravity',
              val: true,
            },
            path: 'Settings.Config.Fluid.den_w',
            type: 'numerical',
            default: 1,
          },
        }
      },
      timeSteps: {
        display: 'Time Steps',
        description: {
          prefix: 'Time interval definition',
        },
        settings: {

        }
      },
      postProcessing: {
        display: 'Post Processing',
        description: {
          prefix: 'Activate time-of-flight calculation; filter weak connections',
        },
        settings: {
          solveTimeOfFlight: {
            display: 'Solve Time of Flight',
            subDisplay: '',
            description: '', //TODO
            path: 'Settings.Config.RunOptions.solveTOF',
            type: 'bool',
            default: false,
          },
          wellAllocationFactorThreshold: {
            display: 'Well Allocation Factor Threshold',
            subDisplay: 'Min. 0, Max 1',
            description: '', //TODO
            path: 'Settings.Config.RunOptions.WAFthreshold',
            type: 'numerical',
            default: 0,
          },
        }
      }
    }
  },
  calibration: {
    display: 'Calibration',
    step: 4,
    subcategories: {
      percentageTrainingData: {
        display: 'Percentage of Training Data',
        description: [{
          prefix: 'Model is trained using ',
          variable: '',
        }, {
          prefix: ' and validated with ',
          variable: '',
          suffix: 'of production data'
        }],
        settings: {
          percentageTrainingData: {
            display: 'Percentage of Training Data',
            subDisplay: 'Min. 70, Max 100',
            description: '', //TODO
            path: 'Settings.Config.RunOptions.training_data_prcnt',
            type: 'numerical',
            default: 80,
          },
        }
      }
    }
  }
}


export const swmOptimizationSettingsMap = {
  swmOptimization: {
    display: 'Global',
    step: 1,
    subcategories: {
      optimization: {
        display: 'Optimization',
        description: {
          prefix: '',
          suffix: '',
          variable: '',
        },
        settings: {
          optMode: {
            display: 'Optimization Mode',
            subDisplay: '',
            description: "",
            path: 'Settings.Config.config_struct.optWat',
            type: 'categorical',
            multi: false,
            options: [
              { label: 'Minimize Water', value: true },
              { label: 'Maximize Oil', value: false },
            ],
            default: false,
          },
          minOilLimit: {
            display: 'Minimum Oil Limit',
            subDisplay: '',
            description: "",
            dependency: {
              path: 'Settings.Config.config_struct.optWat',
              val: true,
            },
            path: 'Settings.Config.config_struct.MinOilLimit',
            type: 'numerical',
            default: 0,
          },
          maxWaterLimit: {
            display: 'Maximum Water Limit',
            subDisplay: '',
            description: "",
            dependency: {
              path: 'Settings.Config.config_struct.optWat',
              val: false,
            },
            path: 'Settings.Config.config_struct.MaxWaterLimit',
            type: 'numerical',
            default: 0,
          },
          fieldMinLiqProd: {
            display: 'Field Minimum Liquid Production',
            subDisplay: '',
            description: "",
            path: 'Settings.Config.config_struct.FieldMinLiqProd',
            type: 'numerical',
            default: 0,
          },
          fieldMaxLiqProd: {
            display: 'Field Maximum Liquid Production',
            subDisplay: '',
            description: "",
            path: 'Settings.Config.config_struct.FieldMaxLiqProd',
            type: 'numerical',
            default: 0,
          },
          fieldMinWatInj: {
            display: 'Field Minimum Water Injection',
            subDisplay: '',
            description: "",
            path: 'Settings.Config.config_struct.FieldMinWatInj',
            type: 'numerical',
            default: 0,
          },
          fieldMaxWatInj: {
            display: 'Field Maximum Water Injection',
            subDisplay: '',
            description: "",
            path: 'Settings.Config.config_struct.FieldMaxWatInj',
            type: 'numerical',
            default: 0,
          },
          fieldMinVRR: {
            display: 'Field Minimum VRR',
            subDisplay: '',
            description: "",
            path: 'Settings.Config.config_struct.FieldMinVRR',
            type: 'numerical',
            default: 0,
          },
          fieldMaxVRR: {
            display: 'Field Maximum VRR',
            subDisplay: '',
            description: "",
            path: 'Settings.Config.config_struct.FieldMaxVRR',
            type: 'numerical',
            default: 0,
          },
          forecastPeriod: {
            display: 'Forecast Period',
            subDisplay: 'Integer from 1-6',
            description: "",
            path: 'Settings.Config.config_struct.forecastPeriod',
            type: 'numerical',
            default: 0,
          },
          maxIter: {
            display: 'Maximum Iterations',
            subDisplay: 'Integer from 10-50',
            description: "",
            path: 'Settings.Config.config_struct.maxIter',
            type: 'numerical',
            default: 0,
          },
          minStepSize: {
            display: 'Minimum Step Size',
            subDisplay: '0-100',
            description: "",
            path: 'Settings.Config.config_struct.minStepSize',
            type: 'numerical',
            default: 0,
          },
          maxStepSize: {
            display: 'Maximum Step Size',
            subDisplay: '100-1000',
            description: "",
            path: 'Settings.Config.config_struct.maxStepSize',
            type: 'numerical',
            default: 0,
          },
        }
      },
    }
  },
  swmWellConstraints: {
    display: 'Well Constraints',
    step: 1,
    subcategories: {
      wellConstraints: {
        display: 'Well Constraints',
        // description: {
        //   prefix: 'Cross-zone communication factor and vertical to horizontal permeability ratio',
        // },
        settings: {
          wellConstraints: {},
        }
      },
    }
  },
  swmGroupConstraints: {
    display: 'Group Constraints',
    step: 1,
    subcategories: {
      injection: {
        display: 'Injection',
        settings: {
          injection: {},
        }
      },
      production: {
        display: 'Production',
        settings: {
          production: {},
        }
      },
      vrr: {
        display: 'VRR',
        settings: {
          vrr: {},
        }
      },
    }
  },
}



