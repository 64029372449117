import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PrivateRoute from './CustomRoute/PrivateRoute'

import Initialize from '../components/layout/Cases/Customize/Initialize'
import Modify from '../components/layout/Cases/Customize/Modify'
import Execute from '../components/layout/Cases/Execute/Execute'

const OpportunityRoutes = ({ 
	user,
  caseObj,
  editCaseObj,
  setCanContinue,
  setStep
}) => (
  <React.Fragment>
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/initialize" 
      user={user}
      component={Initialize}
      caseObj={caseObj}
      editCaseObj={editCaseObj}
      setCanContinue={setCanContinue}
      setStep={setStep}
      
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/modify"
      user={user}
      caseObj={caseObj}
      component={Modify}
      editCaseObj={editCaseObj}
      disabled={false}
      setCanContinue={setCanContinue}
      setStep={setStep}
      
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/execute"
      user={user}
      component={Execute}
      caseObj={caseObj}
      editCaseObj={editCaseObj}
      setCanContinue={setCanContinue}
      setStep={setStep}
      
    />
  </React.Fragment>
)

const mapStateToProps = state => ({
  user: state.get('user'),
})

export default withRouter(connect(mapStateToProps)(OpportunityRoutes))