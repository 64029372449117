import React from 'react'

import WellTraj1 from './static/WellTrajectories1.png'
import WellTraj2 from './static/WellTrajectories2.jpg'

const WellTrajectories = (
  <div className={'description-container'}>
    <p>Surveys can be provided in two different formats, whichever that is available: a) Surveys can be provided in a .dev (deviation ASCII survey) format exported from Petrel, one file per well. An example .dev survey file is shown in Figure 14. Or, b) provided in a .CSV format, one file per well, in the specified format shown in Figure 15.</p>
    <p><b>Content:</b> Provides subsurface location of the entire wellbore.</p>
    <p><b>Conventions: Z:</b> Values below sea level need to be negative</p>
    <p><b>TVD: </b>Values below KB need to be positive</p>
    <p><b>Utility: </b>Well trajectories are used in spacing analysis and drainage analysis, as well as for visualizations where you can view the 3D trajectories of the wells.</p>
    <p><b>File Format:</b> .dev (ASCII) or .CSV format. One well survey file should exist for every well.</p>
    <p><b>Units:</b> Well trajectories are used in spacing analysis and drainage analysis, as well as for visualizations where you can view the 3D trajectories of the wells.</p>
    <p>For  further  information,  please refer to the Data Preparation file under resources to the section “ <b>“Appendix: Data Preparation &amp; Quality Control” </b>file under resources.</p>
    <p><b>“Well Surveys/Trajectories”</b> for the following information and guides:</p>
    <ul>
      <li>Importing Surveys into Petrel</li>
      <li>Quality Control of Well Surveys</li>
      <li>Exporting Well Surveys/Trajectories</li>
    </ul>
    <figure>
      <img src={WellTraj1}/>
      <figcaption>Figure 14: Example of a well survey/trajectory field (.dev ASCII from Petrel).</figcaption>
    </figure>
    <figure>
      <figcaption>Figure 15: Example of a well survey/trajectory field (.CSV format)</figcaption>
      <img src={WellTraj2}/>
    </figure>
  </div>
)

export default WellTrajectories