import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class GeologicalModels extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Geological Models
        </div>
        <div className="content-row-header"> 
          2.5D Model
        </div>
        <div className="content-row"> 
          &ensp;• Content: Grids representing various properties for each zone (e.g., structure, net pay thickness, average net pay porosity, etc.). For information regarding what grids are necessary, as well as how to prepare them, please refer to “SpeedWise Reservoir SAAS Data Preparation (Nov2019).docx.”<br/>
          &ensp;• Utility: Grids are used in many steps of the evaluation including (but not necessarily limited to) Data Processing, Calculate Zone Averages, Drainage Analysis, Collect Workover Opportunities & Rank, Predict IP & EUR, Assess Confidence and Geologic Risk, and Opportunity Filtering. One example of the use of grids is to create zone-specific relative probability of success (RPOS or sweet spot maps), to identify the best locations for new drills.<br/>
          &ensp;• File Format: EarthVision grid (ASCII)<br/>
        </div>
        <div className="content-row-header"> 
          3D Model
        </div>
        <div className="content-row"> 
          &ensp;• Content: Required properties include hydrocarbon pore volume (hcpv), porosity, water
          saturation, and distance to faults.<br/>
          &ensp;• Utility: To identify undrained areas for new horizontal targets.<br/>
          &ensp;• File Format: Gslib properties (ASCII)<br/>
        </div>
        <div className="content-row" style={{fontStyle: 'italic'}}> 
          Please remember to use the reference “SpeedWise Reservoir - Data Preparation.docx” for questions regarding specific data requirements and preparation.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(GeologicalModels)
