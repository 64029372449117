import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import Grid from '@material-ui/core/Grid'
import { ClipLoader } from 'react-spinners'
import Card from '../../../common/MUICard'
import API from '../../../../../../lib/api-store'
import WellLocation from '../../../components/WellLocation'
import ModelSectionPlot from '../../../components/ModelSectionPlot'
import { linSpace, downsampleGrid } from '../../../../../../lib/helpers'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class RelativePOS extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shouldRenderDetails: false,
      shouldRenderProduction: false,
      data: [],
      spacing: null,
      rpos: null,
    }
  }

  componentDidMount() {
    const { setCurPage } = this.props
    setCurPage('')
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    const { wellId, caseId } = this.props

    if (wellId !== prevProps.wellId
      || caseId !== prevProps.caseId) {
      this.loadData()
    }
  }

  loadData() {
    const { wellId, caseId, caseObj, datasetId, zone } = this.props
    const property = zone === 'All' ? 'rpos' : ''
    const gridZone = zone === 'All' ? '' : zone
    const attributes = caseObj.settings.rposModeling.ranking.Table  
    
    console.log('sending REQUEST FOR SPACE')
    API.getSectionData(wellId, caseId, 'horizontalDrill', 'spacing')
      .then((results) => {
        console.log('testestest', results)
        this.setState({ spacing: results })
      })

    API.getSectionData(wellId, caseId, 'horizontalDrill', 'pos')
      .then((results) => {
        this.setState({ rpos: results })
      })

    // Load all grid data from the attributes selected in the case settings
    Promise.all(
      attributes.map(attr => (
        API.getSectionData(wellId, caseId, 'horizontalDrill', attr.attribute))
      )
    ).then((results) => {
      const filteredResults = results.filter(x => x !== null)
      this.setState({ data: filteredResults })
    }) 
    
  }

  rposMap() {
    const { rpos } = this.state
    const {
      wellData,
      singleOpportunity,
    } = this.props

    return (
      <Grid item xs={12} md={6}>
        <Card title="RPOS Map">
          { rpos !== null ? (
            <ModelSectionPlot
              modelSectionData={rpos}
              opportunityData={singleOpportunity}
              wellData={wellData}
            />
          ) : <ClipLoader css={loaderCSS} />}
        </Card>
      </Grid>
    )
  }

  spacingMap() {
    const { spacing } = this.state
    if (spacing == null) {
      return (
        <Grid item xs={12} md={6}>
          <Card title="Spacing Map">
            <ClipLoader css={loaderCSS} />
          </Card>
        </Grid>
      )
    }

    const {
      wellData,
      singleOpportunity,
    } = this.props

    const singleOpportunityData = singleOpportunity && Object.keys(singleOpportunity).length > 0 ? [singleOpportunity] : []
 
    return (
      <Grid item xs={12} md={6}>
        <Card title="Spacing Map">
          <ModelSectionPlot
            modelSectionData={spacing}
            opportunityData={singleOpportunity}
            wellData={wellData}
          />
        </Card>
      </Grid>
    )
  }

  render() {
    const {
      wellData,
      singleOpportunity,
      caseObj
    } = this.props

    const { data } = this.state
    const attributes = caseObj.settings.rposModeling.ranking.Table

    return (
      <>
        <div className="relative-pos">
          <Grid className="relative-pos-grid" container spacing={2}>
            { this.spacingMap() }
            { this.rposMap() }

            {attributes.map((attr, idx) => {
              const title = attr.attribute

              const grid = data.find(e => e.propertyId === attr.attribute)

              return (
                <Grid item xs={12} md={6}>
                  <Card title={title}>
                    { grid ? (
                      <ModelSectionPlot
                        modelSectionData={grid}
                        opportunityData={singleOpportunity}
                        wellData={wellData}
                      />
                    ) : <ClipLoader css={loaderCSS} />}
                  </Card>
                </Grid>
              )
            })}

            {/*
            {data.map((grid, idx) => {
              const { property } = grid
              const title = `RPOS Attribute ${idx + 1} Map`
              const subtitle = property

              return (
                <Grid item xs={12} md={6}>
                  <Card title={title} subtitle={subtitle}>
                    <ModelSectionPlot
                      modelSectionData={grid}
                      opportunityData={singleOpportunity}
                      wellData={wellData}
                    />
                  </Card>
                </Grid>
              )
            })}
            */}
          </Grid>
        </div>
      </>
    )
  }
}


export default RelativePOS
