import React from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import PrivateRoute from '../routes/CustomRoute/PrivateRoute'
import ProductSpaceRoutes from '../routes/ProductSpaceRoutes'
import Home from './layout/Home/Home'

const ProductSpace = ({ user }) => {
  return (
	<Switch>
	    <PrivateRoute exact path="/" user={user} component={Home} />
	    <Route 
	      path="/"
	      component={ProductSpaceRoutes}
	    />
  </Switch>
)}

const mapStateToProps = state => ({
  user: state.get('user'),
})

export default withRouter(connect(mapStateToProps)(ProductSpace))
