import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class BasicZoneInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Log Alias
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Specifies a list of curves to combine for each log type in order to create a complete log dataset. This can be achieved by providing one or multiple curve names (From column) and selecting a log type (To column). The Priority column allows you to decide which curve has higher priority when multiple curves are available over the same depth interval. If Priority is left empty, SRO will rank the curves based on well count (Figure 31).
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Required:</span> Porosity (phi), water saturation (sw) logs
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Optional:</span> Net pay (netPay). If a net pay log is not selected in the <b>“Log Alias”</b> settings, then in the <b>“Net Pay Settings”</b> section of Log/Zone Analytics (All Modules) you must select to <b>“Generate”</b> the net pay and then select other logs and cutoffs for the net pay.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Other:</span> You may choose to import and run analytics on other logs. The options for types of logs to import can be seen in the dropdown menu in the <b>“To”</b> column.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(BasicZoneInfo)
