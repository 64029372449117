import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'

import Intro from './components/Intro'
import Importance from './components/Importance'
import Modules from './components/Modules'
import Help from './components/Help'

@autobind class GettingStarted extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/training/manual/gettingStarted/intro" component={Intro} />
          <Route path="/training/manual/gettingStarted/importance" component={Importance} />
          <Route path="/training/manual/gettingStarted/modules" component={Modules} />
          <Route path="/training/manual/gettingStarted/help" component={Help} />
        </Switch>
      </React.Fragment>
    )
  }
}

export default GettingStarted
