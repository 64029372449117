import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import DrainageAnalysisOverviewA from '../../../../../../../../../../static/images/userManual/settings/drainageAnalysisOverviewA.png'

@autobind class DrainageAnalysisOverview extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Drainage Analysis Overview
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          A drainage analysis is performed to ensure that the uncontacted net pay intervals identified in the previous phase have not been drained, or will not be drained, by offset wells. Drainage from each completion is characterized by coupling the results of the flow unit allocation described in the Engineering Analytics (All Modules) with the rock property logs (Table 9). This analysis is performed on either a zone-by-zone basis if the zones constitute isolated flow units, or on a global basis in vertically connected systems. The contacted hydrocarbon pore thickness is also mapped during this step to approximate drainage areas for wells without log information.
        </div>
        <div className="content-row"> 
          Matrix-Dominated Systems<br/>
          The algorithm (Figure 30) estimates a drainage radius for every well/zone by relating the completed (drained) hydrocarbon pore volume thickness (HCPT: estimated from the logs) to the allocated EUR or cumulative production in the completed zone. The algorithm also calculates a drainage index (DI) for every completed zone, which is defined as the ratio of the completed HCPT to the original HCPT (Figure 31). In other words, small DIs reflect areas where only thin sand layers have been accessed/drained and therefore have more oil remaining in either overlying or underlying sand packages. Figure 32 (left map) shows an example of a zone-specific drainage index grid that combines information from both the drainage radius and drainage index. Note that in areas with overlapping radii, the algorithm modifies the DI in the overlap area using either a pessimistic or optimistic method (Figure 33).
        </div>
        <div className="content-row"> 
          Fracture-Dominated Systems<br/>
          In heavily fractured reservoirs, the produced oil is not confined to the local radii calculated above, but can also travel longer distances through fracture networks. To represent these patterns, the algorithm uses distance-based weighting functions that increase the DI away from the point of production. These weighting functions can also be directional to reflect fracture orientation if known. Using the drainage grids created during this phase, the uncontacted net pay intervals identified in the recompletion module are filtered out if the zone DI at the opportunity location exceeds a user-provided DI cutoff.
        </div>
        <div className="content-row"> 
          Facies-Constrained Drainage Analysis<br/>
          In certain depositional environments, drainage shapes cannot be assumed to be isotropic given the geologic complexity pertaining to various reservoir facies. Examples of anisotropic or irregular reservoir facies include fluvial channels, deltaic lobes, turbidite sands, and so on. In the event where an accurate mapping of these reservoir facies has already taken place, SRO provides the user with the option to constrain the drainage areas to these mapped reservoir facies. This is achieved by performing a connected volume search using a breadth first strategy where the tracked volume is capped by the previously calculated drainage area. In addition, preferential flow information can be provided to help guide the tracking/search using permeability-based functions that are based on existing permeability models, for example. The end result is a facies-constrained drainage map that still retains the drainage index information as can be seen in Figure 32 (right figure).
        </div>
        <div className='image-container'>
          <img src={DrainageAnalysisOverviewA} alt="" />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(DrainageAnalysisOverview)
