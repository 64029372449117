import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import Select from 'react-select'
import Switch from 'rc-switch'
import { withRouter } from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import API from '../../../../lib/api-store'

import './UserModal.scss'

const baseClass = 'userModal'
const headerClass = `${baseClass}__header`
const toggleClass = `${baseClass}__toggle`
const toggleOptionClass = `${toggleClass}__option`
const toggleOptionTextClass = `${toggleClass}__text`
const userSelectionClass = `${baseClass}__userSelection`

const userInfoClass = `${baseClass}__userInfo`
const userInfoLeftClass = `${userInfoClass}__left`
const avatarClass = `${userInfoLeftClass}__avatar`
const avatarTextClass = `${avatarClass}__text`
const userInfoRightClass = `${userInfoClass}__right`
const userNameClass = `${userInfoRightClass}__name`
const userEmailClass = `${userInfoRightClass}__email`
const userCreatedClass = `${userInfoRightClass}__created`

const userInfoBottomClass = `${userInfoClass}__bottom`
const userInfoBottomText1Class = `${userInfoBottomClass}__1`
const userInfoBottomText2Class = `${userInfoBottomClass}__2`
const userInfoBottomText3Class = `${userInfoBottomClass}__3`
const userInfoBottomText3Class1 = `${userInfoBottomText3Class}__1`
const userInfoBottomText3ClassCheck = `${userInfoBottomText3Class}__check`
const userInfoBottomText3ClassSwitch = `${userInfoBottomText3Class}__switch`
const userInfoBottomText3Class2 = `${userInfoBottomText3Class}__2`

@autobind class UserModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newUserType: "existing",
      newUserFirstName: '',
      newUserLastName: '',
      newUserEmail: '',
      newUserPassword: '',
      selectedUser: null,
      newPasswordError: null
    }
  }

  makePrivileges(userObj) {
    let { isLicenseAdmin, isAccountAdmin, handleToggleProjectPrivileges, license, selectedUser, handleRemoveLicenseAdmin } = this.props

    let isUserLicenseAdmin = (selectedUser == license.licenseAdminId)
    let isUserAccountAdmin = (selectedUser == license.accountAdminId)

    if (isUserAccountAdmin || isUserLicenseAdmin) {
      return (
        <React.Fragment>
          <div className={userInfoBottomClass}>
            <div className={userInfoBottomText1Class}>
              Administrator Privileges
            </div>
          </div>
          <div className={userInfoBottomText3Class}>
            <div className={userInfoBottomText3Class1}>
              Can invite or delete any user associated with license
              <div className={userInfoBottomText3ClassCheck}>
                <CheckCircleIcon />
              </div>
            </div>
            <div className={userInfoBottomText3Class1}>
              Can create or delete workspaces
              <div className={userInfoBottomText3ClassCheck}>
                <CheckCircleIcon />
              </div>
            </div>
            <div className={userInfoBottomText3Class1}>
              Can change workspace owners
              <div className={userInfoBottomText3ClassCheck}>
                <CheckCircleIcon />
              </div>
            </div>
            <div className={userInfoBottomText3Class1}>
              Can assign privileges to users
              <div className={userInfoBottomText3ClassCheck}>
                <CheckCircleIcon />
              </div>
            </div>
          </div>
          { isUserLicenseAdmin && isAccountAdmin && (
            <div> 
              <Button
                onClick={() => handleRemoveLicenseAdmin(selectedUser)}
              >
                Remove Administrator
              </Button>
            </div>
          )}
        </React.Fragment>
      )
    }



    return (

        <React.Fragment>
          <div className={userInfoBottomClass}>
            <div className={userInfoBottomText1Class}>
              User Privileges (Optional)
            </div>
            <div className={userInfoBottomText2Class} >
              { isLicenseAdmin || isAccountAdmin ? 'Privileges can be modified at any time' : 'Request the administrator to toggle the privileges below' }
            </div>
          </div>
          <div className={userInfoBottomText3Class}>
            <div className={userInfoBottomText3ClassSwitch}>
              <Switch
                disabled={(!(isLicenseAdmin || isAccountAdmin))}
                className='float-right'
                onChange={e => handleToggleProjectPrivileges(userObj.id, e)}
                checked={userObj.projectPrivileges}
              />
            </div>
            <div className={userInfoBottomText3Class1}>
              Allow user to create or delete their own workspace(s)
            </div>
          </div>
      </React.Fragment>
    )
  }



  makeModal() {
    const { newUserType } = this.state
    const { selectedUser, license, isLicenseAdmin, isAccountAdmin, handleRemoveFromLicense } = this.props



      if (!selectedUser) {
        return (
          <React.Fragment>
            <div className={toggleClass}>
              <div className={toggleOptionClass}>
                <label>
                  <input 
                    type="radio"
                    value="new"
                    checked={newUserType === 'new'}
                    onChange={() => this.setState({newUserType: 'new'})}
                  />
                  <span className={toggleOptionTextClass}>Create New User</span>
                </label>
              </div>
              <div className={toggleOptionClass}>
                <label>
                  <input
                    type="radio"
                    value="existing"
                    checked={newUserType === 'existing'}
                    onChange={() => this.setState({newUserType: 'existing'})}
                  />
                  <span className={toggleOptionTextClass}>Choose from Existing Users</span>
                </label>
              </div>
            </div>
            <div className={userSelectionClass} >
              {this.makeUserSelection()}
            </div>
          </React.Fragment>
        )
      }

      let userObj
      if (license.accountAdminId == selectedUser) {
        userObj = license.accountAdmin
      } else if (license.licenseAdminId == selectedUser) {
        userObj = license.licenseAdmin
      } else {
        userObj = license.currentUsers.find(i => i.id == selectedUser)
      }
      console.log(userObj)
      let name = userObj.name.split(' ')
      let firstLetter = name[0][0].toUpperCase()
      let secondLetter = name[1][0].toUpperCase()

      return (
        <React.Fragment> 
          <div className={userInfoClass}>
            <div className={userInfoLeftClass}>
              <div className={avatarClass}>
                <div className={avatarTextClass}>{firstLetter}{secondLetter}</div>
              </div>
            </div>
            <div className={userInfoRightClass}>
              <div className={userNameClass}>
                {userObj.name}
              </div>
              <div className={userEmailClass}>
                {userObj.email}
              </div>
              <div className={userCreatedClass}>
                Owner of {userObj.workspaceCount} Workspaces
              </div>
            </div>
          </div>
          {this.makePrivileges(userObj)}
          { (isLicenseAdmin || isAccountAdmin) && (
            <div> 
              <Button
                className={'qri-button primary'}
                fullWidth
                onClick={() => handleRemoveFromLicense(selectedUser)}
              >
                Remove From License
              </Button>
            </div>
          )}
        </React.Fragment>
      )
  }

  handleFirstNameChange(e) {
    let { value } = e.target

    this.setState({
      newUserFirstName: value
    })
  }

  handleLastNameChange(e) {
    let { value } = e.target

    this.setState({
      newUserLastName: value
    })
  }

  handleEmailChange(e) {
    let { value } = e.target

    this.setState({
      newUserEmail: value
    })
  }

    handlePasswordChange(e) {
    let { value } = e.target

    let error = null
    if (value.length < 8) {
      error = 'Temp Password must be at least 8 character'
    }

    this.setState({
      newUserPassword: value,
      newPasswordError: error
    })
  }

  handleCreateNewUserClick() {
    const { newUserFirstName, newUserLastName, newUserEmail, newUserPassword } = this.state
    const { handleCreateNewUser } = this.props

    handleCreateNewUser(newUserFirstName, newUserLastName, newUserEmail, newUserPassword)
  }

  makeUserSelection() {
    const { newUserType, selectedUser, newUserFirstName, newUserLastName, newUserEmail, newUserPassword, newPasswordError } = this.state
    const { accountUserOptions, handleAddLicenseAdmin, handleAddUser, missingUserType, license, createUserError } = this.props

    const reqStyle = {
      color: 'red',
      display: 'inline-block'
    }

    const makeRequired = textInput => <>{textInput}<span style={reqStyle}>*</span></>


    console.log(newUserLastName, newUserFirstName, newUserEmail, newUserPassword)
    if (newUserType === 'new') {
      return (
        <React.Fragment>
          <div className={'inputs'}>
            <div className={'input-row'}>
              <TextField
                id="new-user-first-name-input"
                label={makeRequired('First Name')}
                type="text"
                margin="normal"
                variant="outlined"
                className={'new-user-input two'}
                value={newUserFirstName}
                autoFocus={true}
                onChange={this.handleFirstNameChange}
                inputProps={{
                  maxLength: 50,
                }}
              />
              
              <TextField
                id="new-user-last-name-input"
                label={makeRequired('Last Name')}
                type="text"
                margin="normal"
                variant="outlined"
                className={'input-field two'}
                value={newUserLastName}
                onChange={this.handleLastNameChange}
                inputProps={{
                  maxLength: 50,
                }}
              /> 
            </div>

            <div className={'input-row'}>
              <TextField
                id="new-user-email-input"
                label={makeRequired('E-mail Address')}
                type="text"
                margin="normal"
                variant="outlined"
                className={'input-field one'}
                value={newUserEmail}
                onChange={this.handleEmailChange}
                inputProps={{
                  maxLength: 50,
                }}
              /> 
            </div>
            <div className={'input-row'}>
              <TextField
                id="new-user-pass-input"
                label={makeRequired('Temporary Password')}
                type="text"
                margin="normal"
                variant="outlined"
                className={'input-field one'}
                value={newUserPassword}
                onChange={this.handlePasswordChange}
                inputProps={{
                  maxLength: 50,
                }}
              /> 
            </div>
            {newPasswordError && (<span style={{fontSize: '14px', color: 'red'}}> {newPasswordError}</span>)}
          </div>
          <div className={'sub'}>
            <div>
              <Button
                className={'qri-button primary'}
                disabled={!newUserFirstName || !newUserLastName || !newUserEmail || !newUserPassword || newUserPassword.length < 8}
                tabIndex="0"
                onClick={this.handleCreateNewUserClick}
                onKeyPress={null}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
              >
                <span>Invite User</span>
              </Button>
              {createUserError && (<span style={{fontSize: '14px', color: 'red'}}> {createUserError}</span>)}
            </div>
          </div>
        </React.Fragment>
      )
    }

    console.log('omg user options', selectedUser)

    if (missingUserType === 'user') {
      let licenseUsers = license.currentUsers.map(i => i.id)

      let existingUsersNotOnLicense = accountUserOptions.filter(i => (!licenseUsers.includes(i.value)))
      
      return (
        <div> 
          <div style={{ marginBottom: '10px'}}>
            <Select
              options={existingUsersNotOnLicense}
              value={accountUserOptions.find(i => i.value == selectedUser)}
              onChange={e => this.handleUserSelectionChange(e)}
            />
          </div>
          <div>
            <Button
              className={'qri-button primary'}
              disabled={!selectedUser}
              fullWidth
              onClick={() => handleAddUser(selectedUser)}
            >
              Add User
            </Button>
          </div>
        </div>
      )    
    }

    return (
      <div> 
        <div>
          <Select
            options={accountUserOptions}
            value={accountUserOptions.find(i => i.value == selectedUser)}
            onChange={e => this.handleUserSelectionChange(e)}
          />
        </div>
        <div>
          <Button
            disabled={!selectedUser}
            onClick={() => handleAddLicenseAdmin(selectedUser)}
          >
            Add Administrator
          </Button>
        </div>
      </div>
    )
  }

  handleUserSelectionChange(e) {
    this.setState({
      selectedUser: e.value
    })
  }


  render() {
    const  { title } = this.props

    return (
      <div 
        className={baseClass}
      >
        <div className={headerClass}>
          {title}
        </div>
        {this.makeModal()}
      </div>
    )
  }
}


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default withRouter(connect(mapStateToProps, null)(UserModal))
