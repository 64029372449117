import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import Waterflood from '../Waterflood/Waterflood'
import Visualization from './Visualization'


@autobind class VisualizationParent extends Component {
  constructor(props) {
    super(props)
    
  }

  render() {
    const { user, match } = this.props
    const { application } = match.params

    return (
      <React.Fragment>
        { application === 'SGO' && (
            <Visualization 
              user={user}
            />
          )
        }
        {
          application === 'SWM' && (
            <Waterflood 
              user={user}
            />
          )
        }
        </React.Fragment>
    )
  }
}


export default withRouter(VisualizationParent)

      