import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'

import {withStyles} from '@material-ui/core/styles';

import Popper from '@material-ui/core/Popper'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

const baseClass = 'dataset-header'

const arrowGenerator = (color) => {
  return ({
    'maxWidth': 500,
    'maxHeight': 100,
    '&[x-placement*="bottom"] $arrow': {
      height: 16,
      left: 259,
      width: 32,
      /* height: 25px; */
      position: 'absolute',
      top: -16,
      right: 0,
      overflow: 'hidden',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="bottom"]': {
      marginTop: 18,
    },
    '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.95em',
    width: '2em',
    height: '1em',
    '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
    },
    },
    '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.95em',
    height: '2em',
    width: '1em',
    '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
    },
    },
    '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.95em',
    height: '2em',
    width: '1em',
    '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
    },
    },
  })
}

const styles = {
    root: {
      borderBottom: '2px solid #ff4040',
      padding: 16,
    },
    content: {
      maxHeight: 99,
      overflowY: 'auto',
      padding: 0,
      '& ul': {
        margin: 0,
        paddingLeft: 15,
        paddingRight: 15,
      }
    },
    button: {
        borderRadius: 0,
    },
    label: {
        fontSize: '18px',
        color: 'black',
    },
    arrow: {
      position: 'absolute',
      fontSize: 6,
      '&::before': {
        content: '""',
        position: 'absolute',
        width: 20,
        height: 20,
        background: 'white',
        transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
        top: '100%',
        left: '50%',
        boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.6)',
      },
    },
    popper: arrowGenerator('white'),
}

const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
        fontSize: 14,
      },
      '& input': {
        fontSize: 14,
        borderRadius: 0,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  }))((props) => <TextField InputProps={{ disableUnderline: true }} {...props} />)

@autobind class Dialog extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleClose(){
    const { onClose } = this.props
    onClose()
  }


  handleArrowRef(reference){
    this.setState({
      arrowRef: reference,
    })
  }

  render() {
    const { arrowRef } = this.state
    const { classes, children, ...restProps } = this.props

    return (
      <Popper
        className={classes.popper}
        placement="bottom-center"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: true, 
            element: arrowRef,
          },
        }}
        {...restProps}
      >
        <span className={classes.arrow} ref={this.handleArrowRef} />
        <Card className={classes.root} square>
            <CardContent className={classes.content}>
              {children}
            </CardContent>
        </Card>
      </Popper>
    )
  }
}

export default  withStyles(styles)(Dialog)
