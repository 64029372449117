import React from 'react'

import img1 from './static/WellMaster1.png'
import img2 from './static/WellMaster2.png'

const WellMaster = (
  <>
    <div className={'description-container'}>
      <p>Well master information is required and is loaded via the “Well_Master.csv” template. It contains typical well header information and is required for all modules of SRO. This should include a row for each valid well within the reservoir.</p>
      <p><b>Content:</b> Basic well information including location, status, etc.</p>
      <p><b>Utility:</b> Basic well information is required for almost all steps in the modules.</p>
      <p>File Format: CSV template (downloadable) (example shown in Figure 1).</p>
      <p><b>Units:</b> Currently all units must be in meters. <em>We understand that many users are used to working in feet, and we are working on an update that will allow inputs to be in feet.</em></p>
      <p><b>Other Notes:</b></p>
      <ul>
        <li><em>Not all columns are required to be filled in. Do NOT delete columns that are not required when preparing your input data. See Table 2 for specifics.</em></li>
        <li><em>Well naming conventions are important as the well name is the unique identifier that links the geological and engineering input data. As such, it needs to be consistent. For example, well naming conventions/nomenclature within a production (OFM) database may be different from those in a static geological model. Any differences between naming conventions need to be reconciled for this input sheet or through the use of the optional well aliasing input (“Well_Alias.csv”).</em></li>
      </ul>
      <figure>
        <img src={img1} />
        <figcaption>Figure 1: Example of the first few rows of a “Well_Master.csv” sheet with only the required data (e.g., no additional, optional data filled out).</figcaption>
      </figure>
      <figure>
        <figcaption>Table 2: Details for Well_Master.csv</figcaption>
        <img src={img2} />
      </figure>
      <p>For further information, please refer to the Data Preparation file under resources to the section </p>
      <p><b>Appendix:</b> Data Preparation &amp; Quality Control subsection</p>
      <p><b>Well Header Information </b> for further information and guides.</p>
      <p></p>
    </div>
  </>
)

export default WellMaster