import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import API from '../../../lib/api-store'
import { linSpace, downsampleGrid } from '../../../lib/helpers'
import VisualizationRoutes from '../../../routes/VisualizationRoutes'
import MainNav from './components/MainNav'
import Header from './components/Header'
import WorkflowNavBar from '../Cases/Components/WorkflowNavBar'
import TooltipDrawer from './common/TooltipDrawer'

const colorWheel =  [
  '#0076c0',
  '#79D8E5',
  '#EBA064',
  '#FFCB4D',
  '#55A571',
  '#4479B4',
  '#B8B400',
  '#FF7093',
  '#965A89',
  '#4AC1E3',
  '#ED7A6C',
  '#829343',
  '#59C1B0',
  '#3F83A0',
  '#FFC16E',
  '#F27E1E',
  '#613142',
  '#C14B7F',
  '#445C77',
  '#69A3A9',
  '#6643AC',
  '#0d547b'
]

/* 
  We are now fetching available grid data
  and filtering out unavailable properties
  from this array.
*/
const gridPopertyOptions = [
  { label: 'Cumulative Production', value: 'cumProduction'},
  { label: 'Current Hydrocarbon Thickness', value: 'currentHcpt'},
  { label: 'Current Production', value: 'currentProduction'},
  { label: 'Drainage', value: 'drainage'},
  { label: 'DTF', value: 'dtf'},
  { label: 'Facies', value: 'facies'},
  { label: 'GOC', value: 'goc'},
  { label: 'Hydrocarbon Thickness Perforated Net Pay', value: 'hcptPnp'},
  { label: 'Isochore', value: 'isochore'},
  { label: 'K', value: 'k'},
  { label: 'Net Pay', value: 'netPay'},
  { label: 'Original Hydrocarbon Thickness', value: 'originalHcpt'},
  { label: 'OWC', value: 'owc'},
  { label: 'Phi', value: 'phi'},
  { label: 'Recent Gas/Oil Ratio', value: 'recentGor'},
  { label: 'Recent Water Cut', value: 'recentWct'},
  { label: 'RPOS', value: 'pos'},
  { label: 'Structure', value: 'structure'},
  { label: 'SW', value: 'sw'},
]


@autobind class Visualization extends Component {
  constructor(props) {
    super(props)

    this.state = {
      wellData: null,
      faultData: null,
      opportunityData: null,
      structureZMatrix: [],
      structureGridX: [],
      structureGridY: [],
      zMatrix: [],
      gridX: [],
      gridY: [],
      gridProperty: 'structure',
      gridPropertyOptions: gridPopertyOptions,
      zone: null,
      topZone: null,
      zoneOptions: [],
      populatedZoneOptions: [],
      datasetId: null,
      realDatasetId: null,
      zoneColorWheel: [],
      colorWheel: {},
      isFinalized: (props.location.state && props.location.state.finalized !== null) ? props.location.state.finalized : true,
    }
  }


  updateZone(zone) {
    const { gridProperty, datasetId, topZone } = this.state
    const { match } = this.props
    const { caseId } = match.params
    
    let zoneFetch = zone || topZone
    this.setState({
      zone,
      zMatrix: null,
      gridX: null,
      gridY: null,
      structureZMatrix: null,
      structureGridX: null,
      structureGridY: null,
    })

    API.getGridData(gridProperty, zoneFetch, datasetId, caseId)
      .then((gridData) => {
        let zMatrix = []
        let gridX = []
        let gridY = []
        if (gridData) {
          const { definition } = gridData
          zMatrix = gridData.zMatrix
          const { iMax, jMax, x1, x2, y1, y2 } = definition
          gridX = linSpace(x1, x2, iMax)
          gridY = linSpace(y1, y2, jMax)
        }
       
        console.log('setting the state because of zmatrix')
        this.setState({
          zMatrix,
          gridX,
          gridY,
        })
      })

    API.getGridData('structure', zoneFetch, datasetId, caseId)
      .then((gridData) => {
        let smallZMatrix = []
        let smallGridY = []
        let smallGridX = []

        if (gridData) {
          const { definition } = gridData
          const { zMatrix } = gridData
          const { iMax, jMax, x1, x2, y1, y2 } = definition
          const structureGridX = linSpace(x1, x2, iMax)
          const structureGridY = linSpace(y1, y2, jMax)
          let obj = downsampleGrid(structureGridX, structureGridY, zMatrix, 10)  
          smallGridX = obj.smallGridX
          smallGridY = obj.smallGridY
          smallZMatrix = obj.smallZMatrix
        }
        
        console.log('setting the state because of structuve z matrix')
        this.setState({
          structureZMatrix: smallZMatrix,
          structureGridX: smallGridX,
          structureGridY: smallGridY,
        })
      })
      
      API.getGridOptions(zoneFetch, datasetId, caseId)
      .then(gridOptions => {
        // Filter hard-coded grid options to only show what data is available
        const filteredGridProperties = gridPopertyOptions.filter(prop => {
          let value = prop.value
          return gridOptions.includes(value)
        })
        this.setState({ gridPropertyOptions: filteredGridProperties})
      })
  }

  updateGridProperty(prop) {
    const { zone, datasetId, topZone } = this.state
    const { match } = this.props
    const { caseId } = match.params

    this.setState({
      gridProperty: prop,
      zMatrix: null,
      gridX: null,
      gridY: null,
    })

    let zoneFetch = zone || topZone
    API.getGridData(prop, zoneFetch, datasetId, caseId)
      .then((gridData) => {
        if (!gridData) {
          this.setState({
            zMatrix: [],
            gridX: [],
            gridY: [],
          })
        } else {
          const { definition } = gridData
          const { zMatrix } = gridData
          const { iMax, jMax, x1, x2, y1, y2 } = definition
          const gridX = linSpace(x1, x2, iMax)
          const gridY = linSpace(y1, y2, jMax)
          console.log('setting the state because of zMatrix')
          this.setState({
            zMatrix,
            gridX,
            gridY,
          })
        }
      })
  }

  updateOpportunity(value, id) {
    const { opportunityData } = this.state

    let newOpportunityData = [...opportunityData]
    let index = newOpportunityData.findIndex(i => i._id == id)
    if (index !== -1) {

      let newOpportunity = {...newOpportunityData[index]}
      newOpportunity.tier = value
      newOpportunityData[index] = newOpportunity
    }

    console.log('setting the state because of opportunityData')
    this.setState({
      opportunityData: newOpportunityData
    })
  }

  updateOpportunityComments(opportunityId, commentCount) {
    const { opportunityData } = this.state

    let newOpportunityData = [...opportunityData]
    let index = newOpportunityData.findIndex(i => i._id == opportunityId)

    newOpportunityData[index].commentCount = commentCount

    this.setState({
      opportunityData: newOpportunityData
    })
  }

  // componentWillUnmount() {
  //   localStorage.removeItem('template')
  // }

  componentDidMount() {
    const { gridProperty, zone } = this.state
    const { match } = this.props
    const { caseId } = match.params

    window.wellLocationZoom = {  
      x0: null,                  //Resets zoom when new Viz component mounts in wellLocation.
      xn: null,                  //See WellLocation.js line ~600 for why this is here.
      y0: null,
      yn: null,
      x0i: null,
      xni: null,
      y0i: null,
      yni: null,
    }

    API.getCase(caseId)
      .then(caseObj => {
        const isFinalized = caseObj.finalized

        this.setState({
          isFinalized,
          caseObj,
        })

        const datasetId = caseObj.dataKey.versionId
        const realDatasetId = caseObj.dataKey.datasetId
                
        API.getZones(datasetId, caseId)
          .then((zoneData) => {
            zoneData = zoneData.sort((a,b) => {
              return parseInt(a.order) - parseInt(b.order)
            })

            let zoneOptions = zoneData.map(i => ({ label: i.name, value: i.name }))
            const topZone = zoneData[0] ? zoneData[0].name : null
            zoneOptions.unshift({ label: 'All Zones', value: null })
            let zoneColorWheel = {}
            zoneData.forEach((zone, index) => {
              zoneColorWheel[zone.name] = colorWheel[index % colorWheel.length]
            })

            console.log('setting the state because of zoneData')
            this.setState({ topZone, zoneOptions, zoneColorWheel })
            let zoneFetch = zone || topZone

            API.getGridOptions(zoneFetch, datasetId, caseId)
              .then(gridOptions => {
                // Filter hard-coded grid options to only show what data is available
                const filteredGridProperties = gridPopertyOptions.filter(prop => {
                  let value = prop.value
                  return gridOptions.includes(value)
                })
                this.setState({ gridPropertyOptions: filteredGridProperties})
              })

            API.getCustomGrids(realDatasetId, datasetId)
              .then((customGrids) => {
                console.log('setting state because of customGrids', customGrids)
                this.setState({ gridPropertyOptions: [...customGrids, ...this.state.gridPropertyOptions]})
              })

            API.getOpportunities(caseId)
              .then((opportunityData) => {
                console.log('setting the state because of opportunityData')    

                let isVertical = opportunityData.length > 0 && opportunityData[0].Opportunities

                let zonesWithOpportunities

                if (isVertical) {
                  zonesWithOpportunities = opportunityData.map(i => i.Opportunities.filter(j => j.isFinalTarget == true).map(j => j.zoneId)).flat()
                } else {
                  zonesWithOpportunities = opportunityData.map(i => i.zone)
                }
                let populatedZoneOptions = zoneData.filter(i => zonesWithOpportunities.includes(i.name)).map(i => ({ label: i.name, value: i.name }))
                populatedZoneOptions.unshift({ label: 'All Zones', value: null })

                this.setState({ opportunityData, populatedZoneOptions })
              })



            API.getGridData(gridProperty, zoneFetch, datasetId, caseId)
              .then((gridData) => {
                if (!gridData) {
                  this.setState({
                    zMatrix: [],
                    gridX: [],
                    gridY: [],
                  })
                } else {
                  const { definition } = gridData
                  const { zMatrix } = gridData
                  const { iMax, jMax, x1, x2, y1, y2 } = definition
                  const gridX = linSpace(x1, x2, iMax)
                  const gridY = linSpace(y1, y2, jMax)
                  console.log('setting the state because of zMatrix')        
                  this.setState({
                    zMatrix,
                    gridX,
                    gridY,
                  })
                }
              })

            API.getGridData('structure', zoneFetch, datasetId, caseId)
              .then((gridData) => {
                if (!gridData) {
                  this.setState({
                    zMatrix: [],
                    gridX: [],
                    gridY: [],
                  })
                } else {
                  const { definition } = gridData
                  const { zMatrix } = gridData
                  const { iMax, jMax, x1, x2, y1, y2 } = definition
                  const structureGridX = linSpace(x1, x2, iMax)
                  const structureGridY = linSpace(y1, y2, jMax)
                  const { smallGridX, smallGridY, smallZMatrix } = downsampleGrid(structureGridX, structureGridY, zMatrix, 10)

                  console.log('setting the state because of structureZMatrix')
                  this.setState({
                    structureZMatrix: smallZMatrix,
                    structureGridX: smallGridX,
                    structureGridY: smallGridY,
                  })
                }
              })

            API.getFaultData(datasetId, topZone, caseId)
            .then((faultData) => {
              console.log('setting the state because of faultData')        
              this.setState({ faultData })
            })
          })

        API.getWells(datasetId, caseId)
          .then((wellData) => {
            console.log('setting the state because of wellData', wellData)        
            this.setState({ wellData })
          })

        this.setState({
          datasetId,
          realDatasetId: caseObj.dataKey.datasetId
        })
      })
  }

  handleFinalizeCase() {
    const { match } = this.props
    const { projectId, caseId } = match.params

    API.finalizeCase(caseId)

    this.setState({ isFinalized: true })
  }

  render() {
    const { match } = this.props
    const { 
      gridData,
      wellData,
      faultData,
      opportunityData,
      structureZMatrix,
      structureGridX,
      structureGridY,
      zMatrix,
      gridX,
      gridY,
      gridProperty,
      zone,
      zoneOptions,
      populatedZoneOptions,
      zoneColorWheel,
      datasetId,
      realDatasetId,
      isFinalized,
      caseObj,
      gridPropertyOptions,
    } = this.state

    const { projectId, caseId, application } = match.params
    const tempContainerStyle = {}

    if (!isFinalized) {
      tempContainerStyle.height = 'calc(100% - 100px)'
    }


    return (
      <>
        {isFinalized && (
          <Header caseObj={caseObj} />
        )}
        {!isFinalized && (
          <div className='dataset-header visualization-workflow-bar'>
            <div className="header">
              <div 
                className="prev"
                onClick={() => this.props.history.push(`/${application}/projects/${projectId}/cases/${caseId}/execute`)}
              >
                <span style={{'paddingRight': '5px'}}>
                  <FontAwesomeIcon icon="chevron-circle-left" />
                </span>
                Back
              </div>
              <WorkflowNavBar step={3} />
              <div 
                className={`continue-button`}
                onClick={this.handleFinalizeCase}
              >
                Finalize
              </div>
            </div>
          </div>
        )}
        <div className={`visualization ${isFinalized ? 'finalized' : ''}`}>
          <div className='temp-container'>
            <nav className="nav-outer">
              <div className="nav-selection">
                <MainNav caseObj={caseObj}/>
              </div>
            </nav>
            <div className="visualization-container">
              <VisualizationRoutes
                updateOpportunity={this.updateOpportunity}
                updateOpportunityComments={this.updateOpportunityComments}
                wellData={wellData}
                faultData={faultData}
                opportunityData={opportunityData}
                structureZMatrix={structureZMatrix}
                structureGridX={structureGridX}
                structureGridY={structureGridY}
                zMatrix={zMatrix}
                gridX={gridX}
                gridY={gridY}
                colorWheel={zoneColorWheel}
                caseId={caseId}
                gridProperty={gridProperty}
                updateGridProperty={this.updateGridProperty}
                gridPopertyOptions={gridPropertyOptions}
                zone={zone}
                updateZone={this.updateZone}
                zoneOptions={zoneOptions}
                populatedZoneOptions={populatedZoneOptions}
                datasetId={datasetId}
                realDatasetId={realDatasetId}
                caseObj={caseObj}
              />
              <TooltipDrawer />
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  const substate = state.get('SGOVisualization')
  return {
    tooltipHTML: substate.get('tooltipHTML'),
    tooltipOpen: substate.get('tooltipOpen')
  }
}


export default withRouter(connect(mapStateToProps)(Visualization))