import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import CommentBox from '../../../common/Comments/CommentBox'

import { Checkbox, Numeric, Integer, Numeric2 } from '../../../../lib/formatters'
import { dealWithNaN } from '../../../../lib/helpers'
import {
  pinCaseOpportunity,
  unpinCaseOpportunity,
} from '../../../../redux/actions/user'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

@autobind class OpportunityCatalogTable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      comments: [],
      isModalOpen: false,
      selectedOpportunityId: null,
    }
  }

  openComment(opportunityId) {
    if (opportunityId) {
      this.setState({
        isModalOpen: true,
        selectedOpportunityId: opportunityId,
      })
    }
  }

  handleClick(e, id) {
    const { history, match } = this.props
    const { projectId, caseId, application } = match.params
    
    history.push(`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${id}`)
  }

  render() {
    const {
      data,
      pinned,
      pinCaseOpportunityAction,
      unpinCaseOpportunityAction,
      updateOpportunityComments,
      match
    } = this.props

    const { selectedOpportunityId, isModalOpen } = this.state

    const { caseId } = match.params

    const plotData = data.map(i => ({
      id: i._id,
      tier: i.tier,
      zone: i.zone,
      name: i.name || i.wellId,
      predictedIp: dealWithNaN(i.predictedIp),
      type: i.type,
      k: dealWithNaN(i.k),
      hcpt: dealWithNaN(i.hcpt),
      commentCount: i.commentCount,
    }))

    const type = plotData[0]?.type
    var columns = [{
      Header: 'Pinned',
      id: 'pinned',
      accessor: i => pinned && pinned.find(p => p.get('_id') === i.id) !== undefined,
      minWidth: 40,
      Cell: Checkbox,
    }, {
      Header: <div>Vetting Tier <br /> Classification</div>,
      accessor: 'tier',
      minWidth: 80,
      filterable: true,
    }, {
      Header: 'Zone',
      accessor: 'zone',
      minWidth: 80,
      filterable: true,
    }, {
      Header: 'Well ID',
      accessor: 'name',
      minWidth: 80,
      filterable: true,
    }, {
      Header: (
        <div>
          Est. IP Gain
          <br />
          (BBL/D)
        </div>),
      accessor: 'predictedIp',
      minWidth: 80,
      Cell: Integer,
    }, {
      Header: 'Opportunity Type',
      accessor: 'type',
      minWidth: 80,
      filterable: true,
    }, {
      Header: <div>k<br/>(md)</div>,
      accessor: 'k',
      minWidth: 80,
      Cell: Numeric,
    }, {
      Header: <div>hcpt<br/> (m) </div>,
      accessor: 'hcpt',
      minWidth: 80,
      Cell: Numeric2,
    }, {
      Header: 'Comments',
      accessor: 'comments',
      minWidth: 80,
      Cell: row => {
        if (row.original.commentCount === 0) {
          return null
        }
        return (
        <div>
          <Link
            component="button"
            style={{ 'height': '100%', 'width': '100%' }}
            onClick={() => this.openComment(row.original.id)}
          >
          View Comments ({row.original.commentCount})
          </Link>
        </div>
      )}
    }]

    if (type == 'verticalDrill') {
      var columns = [{
        Header: 'Pinned',
        id: 'pinned',
        accessor: i => pinned && pinned.find(p => p.get('_id') === i.id) !== undefined,
        minWidth: 40,
        Cell: Checkbox,
      }, {
        Header: <div>Vetting Tier <br /> Classification</div>,
        accessor: 'tier',
        minWidth: 80,
        filterable: true,
      }, {
        Header: 'Zone',
        accessor: 'zone',
        minWidth: 80,
        filterable: true,
      }, {
        Header: 'Well ID',
        accessor: 'name',
        minWidth: 80,
        filterable: true,
      }, {
        Header: (
          <div>
            Est. IP Gain
            <br />
            (BBL/D)
          </div>),
        accessor: 'predictedIp',
        minWidth: 80,
        Cell: Integer,
      }, {
        Header: 'Comments',
        accessor: 'comments',
        minWidth: 80,
        Cell: row => {
          if (row.original.commentCount === 0) {
            return null
          }
          return (
          <div>
            <Link
              component="button"
              style={{ 'height': '100%', 'width': '100%' }}
              onClick={() => this.openComment(row.original.id)}
            >
            View Comments ({row.original.commentCount})
            </Link>
          </div>
        )}
      }]
    }

    return (
      <div className="table">
        <ReactTable
          className="-striped -highlight"
          columns={columns}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id].toUpperCase()).indexOf(filter.value.toUpperCase()) > -1
              : false;
          }}
          showPagination={false}
          data={plotData}
          pageSize={data.length}
          getTdProps={(state, rowInfo, column, instance) => ({
            onClick: (e, handleOriginal) => {
              const id = rowInfo.row._original.id
              if (column.id === 'pinned') {
                // eslint-disable-next-line no-underscore-dangle
                if (rowInfo.row.pinned) {
                  unpinCaseOpportunityAction(id, caseId)
                } else {
                  pinCaseOpportunityAction(id, caseId)
                }
              } else if (column.id !== 'comments') {
                this.handleClick(e, id)
              }
            },
          })}
        />
        <CommentDialog
          threadId={selectedOpportunityId}
          open={isModalOpen}
          handleClose={() => this.setState({ isModalOpen: false, selectedOpportunityId: null })}
          updateOpportunityComments={updateOpportunityComments}
        />
      </div>
    )
  }
}

const CommentDialog = ({ threadId, open, handleClose, updateOpportunityComments }) => (
  <Dialog
    fullWidth
    maxWidth="lg"
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      Comments
    </DialogTitle>
    <DialogContent>
      <CommentBox
        threadId={threadId}
        updateOpportunityComments={updateOpportunityComments}
      />
    </DialogContent>
  </Dialog>
)

const mapDispatchToProps = dispatch => ({
  // setVisualizationOpportunityAction: (page, zone, well) => dispatch(setVisualizationOpportunity({ page, zone, well })),
  // addVisualizationOpportunityAction: (page, zone, well) => dispatch(addVisualizationOpportunity({ page, zone, well })),
  pinCaseOpportunityAction: (opportunityId, caseId) => dispatch(pinCaseOpportunity(opportunityId, caseId)),
  unpinCaseOpportunityAction: (opportunityId, caseId) => dispatch(unpinCaseOpportunity(opportunityId, caseId)),
})

const mapStateToProps = state => ({
  pinned: state.getIn(['user', 'pinnedOpportunities']),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpportunityCatalogTable))
