import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Modules extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          SpeedWise Reservoir Modules
        </div>
        <div className="content-row"> 
          There are currently three available modules that can be run in the SpeedWise Reservoir tool. All three modules can be run within the same workspace in order to create different kinds of opportunity inventories.
        </div>
        <div className="content-row"> 
          <span className='blue'>1) Recompletions Module:</span> Identifies undrained remaining oil behind pipe for potential workover opportunities.
        </div>
        <div className="content-row"> 
          <span className='blue'>2) Sweet Spots Module</span>: Identifies vertical infill and step-out new drill opportunities, and recommends target zones for completion by evaluating attributes of every zone at each potential location.
        </div>
        <div className="content-row"> 
          <span className='blue'>3) Horizontals Module:</span> Identifies optimal locations for placing horizontal or deviated wells in any type of reservoir. The algorithm creates a probability of success model that reflects trends in multiple user-defined geo-engineering attributes which is then used to perform a comprehensive 3D search that incorporates 3D pay tracking.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Modules)
