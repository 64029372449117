import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import SvgIcon from '@material-ui/core/SvgIcon'

const KPI = ({ icon, header, tooltip, value, className }) => (
  <div className={`KPI ${className}`}>
    <div className="icon">
      { tooltip ? (
        <Tooltip title={tooltip} aria-label={tooltip}>
          <SvgIcon viewBox="0 0 31 31" component={icon} />
        </Tooltip>
      ) : <SvgIcon viewBox="0 0 31 31" component={icon} /> }
    </div>
    <div className="text">
      { header }
    </div>
    <div className="val">
      { value }
    </div>
  </div>
)

export default KPI
