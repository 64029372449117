import React from 'react'
import ReactMarkdown from 'react-markdown'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import Avatar from '../Avatar/Avatar'

const Comment = (props) => {
  const { timestamp } = props
  const dateStr = timestamp ? (new Date(timestamp)).toLocaleDateString() : ''

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar fullName={props.author} />
      </ListItemAvatar>
      <ListItemText>
        <div className="comments-info">
          <span className="comments-author">
            {props.author}
          </span>
          <span className="comments-time">{ dateStr }</span>
        </div>
        <div className="comments-text">
          <ReactMarkdown source={props.children} />
        </div>
        <div className="singleCommentButtons">
          {/* <Link onClick={() => { props.handleUpdateComment(props.id); }}>Update</Link> */}
          {
            props.userId === props.authorId
              ? <Link href="#" onClick={() => { props.handleDeleteComment(props.id) }}>Delete</Link>
              : null
          }
        </div>
      </ListItemText>
    </ListItem>
  )
}

export default Comment
