import React from 'react'
import Carousel from 'react-multi-carousel'

import ProductMap from '../ProductRow/ProductMap'

import "react-multi-carousel/lib/styles.css"
import './ProductCarousel.scss'


const baseClass = 'product-card'

const ProductCard = ({ image, name, infoLink }) => {

  const onClick = (e) => {
    if(!infoLink) window.open('https://go.qrigroup.com/SpeedWise-Request-Info', '_blank')
    window.open(infoLink, "_blank")
  }

  return (
    <div className={baseClass + ' hover-effect grow'} onClick={onClick}>
      <img src={image} />
      <p>SpeedWise<sup>®</sup> {name}</p>
    </div>
  )
}


const ProductCarousel = () => {

  const ProductsToShow = [
    'SGO',
    'SWM',
    'SDC',
    'SML',
  ]

  const settings = {
    swipeable: false,
    draggable: false,
    infinite: false,
    arrows: false,
    transitionDuration: 700,
  }

  const responsive = {
    // the naming can be any, depends on you.
    desktop: {
      breakpoint: { max: 100000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <div className={'product-carousel'}>
      <Carousel responsive={responsive} {...settings}>
        {ProductsToShow.map(x => <ProductCard {...ProductMap[x]}/>)}
      </Carousel>
    </div>
  )
}


export default ProductCarousel