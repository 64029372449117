import React from 'react'
import Button from '@material-ui/core/Button'

import SpeedwiseBackground from '../../../../../../static/images/homePage/landingBanner1.png'

import './Speedwise.scss'

const baseClass = `speedwise-banner`
const imgClass = `${baseClass}__img`
const textClass = `${baseClass}__text-container`


const Speedwise = () => {

  const onClick = (e) => window.open("https://www.qrigroup.com/SpeedWise", "_blank")
  
  return (
    <div className={baseClass}>
      <img className={imgClass} src={'https://i.imgur.com/0Rfbyda.png'} />
      <div className={textClass}>
        <h1>SpeedWise<sup>®</sup></h1>
        <p>Augmented AI for Intelligent Reservoir Management</p>
        <Button className={'qri-button primary'} id={'banner-button'} onClick={onClick}>Learn More</Button>
      </div>
    </div>
  )
}

export default Speedwise