import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'

@autobind class NeighborBarPlot extends PureComponent {
  render() {
    const { data, refLine } = this.props

    const plotData = [{
      x: data.map(i => i.id),
      y: data.map(i => i.val),
      type: 'bar',
      hovertemplate: '<b>IP</b>: %{y} ${bbl/d}',
    }]

    if (data && data.length) {
      plotData.push({
        x: [data[0].id],
        y: [refLine],
        text: ['IP Forecast'],
        mode: 'text',
        textposition: 'top right',
      })
    }

    const layout = {
      title: '',
      shapes: [{
        type: 'line',
        x0: 0,
        x1: data.length,
        y0: refLine,
        y1: refLine,
        line: {
          color: 'rgb(0,0,0)',
          width: 3,
          dash: 'dash',
        },
      }],
      margin: {
        l: 60,
        r: 30,
        b: 60,
        t: 10,
        pad: 0,
      },
      showlegend: false,
      autosize: true,
    }

    return (
      <Plot
        className="plot"
        data={plotData}
        layout={layout}
        useResizeHandler
        config={{displaylogo: false, showSendToCloud: true, displayModeBar: false}}
      />
    )
  }
}

export default NeighborBarPlot
