import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import AttributeMappingA from '../../../../../../../../../../static/images/userManual/settings/attributeMappingA.png'

@autobind class AttributeMapping extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Attribute Mapping
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          This step creates several attribute grids in order to look at the spatial variation in each zone’s behavior and history. The grids created include a recent water-cut map, recent gas/oil ratio map, current production map, and a cumulative production map. Additional geo-engineering attributes can be mapped if the underlying well data is available. These geo-engineering attributes are used to extract values for wells that do not have this information. For example, if a well has never been perforated in a particular zone, the recent water-cut grid can be used to approximate the potential water-cut value, it if were to be perforated. For vertical or horizontal new drills, you may want to include some of these maps in the sweet spot (relative probability of success—RPOS) maps.
        </div>
        <div className="content-row"> 
          Settings<br/>
          Recent Water Cut Mapping Algorithm, Recent GOR Mapping Algorithm, Cumulative Production Mapping Method, Current Production Mapping Method
        </div>
        <div className="content-row"> 
          Options<br/>
        </div>
        <div className="content-row"> 
          Nearest<br/>
          <span className='blue'>Comments:</span> Triangulation-based nearest neighbor interpolation supporting 2-D and 3-D interpolation.<br/>
          <span className='blue'>Continuity:</span> Discontinuous<br/>
        </div>
        <div className="content-row"> 
          Linear<br/>
          <span className='blue'>Comments:</span> Triangulation-based linear interpolation (default) supporting 2-D and 3-D interpolation.<br/>
          <span className='blue'>Continuity:</span><sup>0</sup><br/>
        </div>
        <div className="content-row"> 
          Natural<br/>
          <span className='blue'>Comments:</span> Triangulation-based natural neighbor interpolation supporting 2-D and 3-D interpolation. This method is an efficient tradeoff between linear and cubic.<br/>
          <span className='blue'>Continuity:</span> C<sup>1</sup> except at sample points<br/>
        </div>
        <div className="content-row"> 
          Cubic<br/>
          <span className='blue'>Comments:</span> Triangulation-based cubic interpolation supporting 2-D interpolation only.<br/>
          <span className='blue'>Continuity:</span> C<sup>2</sup><br/>
        </div>
        <div className="content-row"> 
          V4<br/>
          <span className='blue'>Comments:</span> Biharmonic spline interpolation (MATLAB® 4 griddata method) supporting 2-D interpolation only. Unlike the other methods, this interpolation is not based on a triangulation.<br/>
          <span className='blue'>Continuity:</span> C<sup>2</sup><br/>
        </div>
        <div className='image-container'>
          <img className="tooBig" src={AttributeMappingA} alt="" />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(AttributeMapping)
