import { datasetBaseUrl } from './apiRoutes'
import {
  createGetRequestParams,
  createPostRequestParams,
  createPutRequestParams,
  createDeleteRequestParams,
} from './apiRequestHelper'

const datasetsForThisUserUrl = `${datasetBaseUrl}/thisUser`
const publicDatasetsUrl = `${datasetBaseUrl}/public`

const createDatasetUrl = datasetId => `${datasetBaseUrl}/${datasetId}`

//const defaultGetRequestParams = createGetRequestParams()

export function generateDownloadVersionUrl(datasetId, versionId) {
  return `${createDatasetUrl(datasetId)}/version/${versionId}/download`
}

export function createDataset(name, description, projectId, shared) {
  const datasetBodyParams = {
    name,
    description,
    projectId,
    shared,
  }

  const fetchParams = createPostRequestParams(datasetBodyParams)

  return fetch(datasetBaseUrl, fetchParams).then(r => r.json())
}

export function copyDatasetFromVersion(datasetId, versionId, name, description, shared) {
  const copyFromUrl = `${createDatasetUrl(datasetId)}/version/${versionId}/copy`
  const copyBody = {
    name,
    description,
    shared,
  }
  const copyFetchParams = createPostRequestParams(copyBody)

  return fetch(copyFromUrl, copyFetchParams)
}

export function setVersionName(datasetId, versionId, name) {
  const url = `${createDatasetUrl(datasetId)}/version/${versionId}/name`
  const body = { name }
  if(!name) return
  const params = createPutRequestParams(body)
  return fetch(url, params)
}

export function getDatasetsForThisUser(projectId) {
  const projectDatasetsForThisUrl = `${datasetsForThisUserUrl}?projectId=${projectId}`
  const getRequestParams = createGetRequestParams()
  return fetch(projectDatasetsForThisUrl, getRequestParams).then(r => r.json())
}

export function getSharedDatasets(projectId) {
  const projectSharedDatasetsUrl = `${publicDatasetsUrl}?projectId=${projectId}`
  const getRequestParams = createGetRequestParams()
  return fetch(projectSharedDatasetsUrl, getRequestParams).then(r => r.json())
}

export function toggleDatasetShare(dataset) {
  const datasetBodyParams = {
    shared: !dataset.shared,
  }

  const shareDatasetUrl = `${createDatasetUrl(dataset._id)}/shared`
  const fetchParams = createPutRequestParams(datasetBodyParams)

  return fetch(shareDatasetUrl, fetchParams).then(r => r.json())
}

export function deleteDataset(datasetId) {
  const deleteDatasetUrl = createDatasetUrl(datasetId)
  const fetchParams = createDeleteRequestParams()

  return fetch(deleteDatasetUrl, fetchParams)
}

export function deleteUnvalidatedVersion(datasetId) {
  const deleteVersionUrl = `${createDatasetUrl(datasetId)}/deleteUnvalidatedVersion`
  const fetchParams = createPutRequestParams()

  return fetch(deleteVersionUrl, fetchParams)
}

export function getDataset(datasetId) {
  const getDatasetUrl = createDatasetUrl(datasetId)
  const getRequestParams = createGetRequestParams()
  return fetch(getDatasetUrl, getRequestParams).then(r => r.json())
}

export function editDataset(datasetId, name, description, shared) {
  const datasetBodyParams = {
    name,
    description,
    shared,
  }

  const editDatasetUrl = createDatasetUrl(datasetId)
  const fetchParams = createPutRequestParams(datasetBodyParams)

  return fetch(editDatasetUrl, fetchParams).then(r => r.json())
}

export function changeModuleForVersion(datasetId, versionId, moduleTypes) {
  const changeModuleForVersionUrl = `${createDatasetUrl(datasetId)}/version/${versionId}/moduleType`
  const fetchParams = createPutRequestParams({ moduleTypes })

  return fetch(changeModuleForVersionUrl, fetchParams)
}

export function changeSelectedGeoModelForVersion(datasetId, versionId, geoModel) {
  const changeGeoModelUrl = `${createDatasetUrl(datasetId)}/version/${versionId}/changeGeoModel`
  const fetchParams = createPutRequestParams({ geoModel })

  return fetch(changeGeoModelUrl, fetchParams)
}

export function createNewVersion(datasetId) {
  const createNewVersionUrl =  `${createDatasetUrl(datasetId)}/createNewVersion`
  const fetchParams = createPutRequestParams()

  return fetch(createNewVersionUrl, fetchParams)
}

export function getFavorites(projectId) {
  const getFavoritesUrl = `${datasetBaseUrl}/favorites?projectId=${projectId}`
  const fetchParams = createGetRequestParams()
  return fetch(getFavoritesUrl, fetchParams).then(r => r.json())
}

export function setFavorite(datasetId) {
  const setFavoriteUrl = `${createDatasetUrl(datasetId)}/favorite`
  const fetchParams = createPostRequestParams()

  return fetch(setFavoriteUrl, fetchParams).then(r => r.json())
}

export function deleteFavorite(datasetId) {
  const deleteFavoriteUrl = `${createDatasetUrl(datasetId)}/favorite`
  const fetchParams = createDeleteRequestParams()

  return fetch(deleteFavoriteUrl, fetchParams).then(r => r.json())
}

export function deleteAllFilesFromDatasetCategory(datasetId, fileCategory) {
  const deleteAllFromCategoryUrl = `${createDatasetUrl(datasetId)}/deleteAllFilesFromCategory/${fileCategory}`
  const fetchParams = createDeleteRequestParams()

  return fetch(deleteAllFromCategoryUrl, fetchParams).then(r => r.json())
}

export function deleteAllFailedFilesFromDatasetCategory(datasetId, fileCategory) {
  const deleteAllFailedFromCategoryUrl = `${createDatasetUrl(datasetId)}/deleteAllFailedFilesFromCategory/${fileCategory}`
  const fetchParams = createDeleteRequestParams()

  return fetch(deleteAllFailedFromCategoryUrl, fetchParams).then(r => r.json())
}

export const addCustomGrids = (datasetId, versionId, input) => {
  const encodedInput = encodeURIComponent(input)
  const options = {
    method: 'POST',
  }
  return fetch(`${process.env.API_ADDRESS}/dataset/${datasetId}/version/${versionId}/customGrids?type=${encodedInput}`, options)
}

export const deleteCustomGrids = (datasetId, versionId, input) => {
  const encodedInput = encodeURIComponent(input)
  const options = {
    method: 'DELETE',
  }
  return fetch(`${process.env.API_ADDRESS}/dataset/${datasetId}/version/${versionId}/customGrids?type=${encodedInput}`, options)
}