import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'

const statusOptions = [
  { label: 'Customize', value: 'customize' },
  { label: 'Execute', value: 'execution' },
  { label: 'Failed', value: 'failed' },
  { label: 'Visualize', value: 'visualize' },
  { label: 'Complete', value: 'finalized' },
]


@autobind class CaseFilter extends Component {
  handleNameFilterChange(e) {
    const { setNameFilter } = this.props
    const val = e.target.value

    setNameFilter(val)
  }

  handleStatusFilterChange(e) {
    const { setStatusFilter } = this.props
    const val = !e ? e : e.value

    setStatusFilter(val)
  }

  render() {
    const { nameFilter, statusFilter } = this.props

    return (
      <div className="case-filter-outer">
        <div className="case-filter-headers">
          <div>
            Search by Case Name, Tags, Description, or Module Type
          </div>
          <div>
            Status
          </div>
        </div>
        <div className="case-filter">
          <input
            className="name-filter"
            value={nameFilter}
            onChange={this.handleNameFilterChange}
            placeholder="Search..."
          />
          <Select
            options={statusOptions}
            onChange={this.handleStatusFilterChange}
            onInputChange={this.handleStatusFilterChange}
            isMulti={false}
            isClearable
            value={statusOptions.find(i => i.value === statusFilter)}
          />
        </div>
      </div>
    )
  }
}


export default withRouter(CaseFilter)
