import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PrivateRoute from './CustomRoute/PrivateRoute'
import DatasetManager from '../components/layout/Dataset/DatasetManager/DatasetManager'
import DatasetFileManager from '../components/layout/Dataset/DatasetFileManager/DatasetFileManager'
import Cases from '../components/layout/Cases/Cases'

const ProjectRoutes = ({ project, license, user }) => (
  <React.Fragment>
    <PrivateRoute exact path="/:application/projects/:projectId/cases" project={project} license={license} user={user} component={Cases} /> 
    <PrivateRoute exact path="/:application/projects/:projectId/dataset" user={user} project={project} license={license} component={DatasetManager} />
    <PrivateRoute exact path="/:application/projects/:projectId/dataset/:datasetId/" user={user} project={project} component={DatasetFileManager} />
    <PrivateRoute exact path="/:application/projects/:projectId/dataset/:datasetId/version/:versionId" user={user} project={project} component={DatasetFileManager} />
  </React.Fragment>
)

const mapStateToProps = state => ({
  user: state.get('user'),
})

export default withRouter(connect(mapStateToProps)(ProjectRoutes))