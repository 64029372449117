import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import Button from '@material-ui/core/Button'

import API from '../../../../../lib/api-store'

@autobind class GroupConstraintSettings
 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteCol: false,
      columns: [{
        Header: 'Wells',
        accessor: 'wells',
        type: 'categorical',
      }, {
        Header: 'Min.--Rate',
        accessor: 'minRate',
        type: 'numerical',
      }, {
        Header: 'Max.--Rate',
        accessor: 'maxRate',
        type: 'numerical',
      }, {
        Header: 'Group Name',
        accessor: 'groupName',
        type: 'input',
      }]
    }
  }

  componentDidMount() {
    let { groupName } = this.props
    let { columns } = this.state


    let tempColumns = [...columns]

    tempColumns.forEach((column) => { 

      if(column.Header.split('-').length > 1) {
        let newHeader = column.Header.split('-')

        if (groupName === 'injection') {
          newHeader[1] = 'Injection'
        } else if (groupName === 'production') {
          newHeader[1] = 'Production'
        } else if (groupName === 'vrr') {
          newHeader[1] = 'VRR'
        }
        newHeader = newHeader.join(' ')
        column.Header = newHeader
        console.log('yayaya', newHeader)
      }

    })

    this.setState({ 
      columns: tempColumns
    })

  }

  handleSettingsChangeTable(type, action, item, index, e) {
    const { editCaseObj, caseObj, stepNo, groupName} = this.props
    const { caseId } = caseObj



    let val

    if (type === 'string') {
      val = e.target.value
    } else if (type === 'numerical') {
      if (action === 'change') {
        val = e.target.value
      } else if (action === 'blur') {
        val = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)  
      }
    } else if (type === 'date') {
      val = e
    } else if (type === 'check') {
        val = e.target.checked
    } else if (type === 'select-multi') {
      val = e ? e.map(item => item.value) : []
    }

    const newCaseObj = {...caseObj}


    if (groupName === 'injection') {
      newCaseObj.settingsOpti.injectionGroupConstraints[index][item] = val  
    } else if (groupName === 'production') {
      newCaseObj.settingsOpti.productionGroupConstraints[index][item] = val  
    } else if (groupName === 'vrr') {
      newCaseObj.settingsOpti.VRRGroupConstraints[index][item] = val  
    }
    

    if (newCaseObj.stepNo && stepNo <= newCaseObj.stepNo) {
      if (stepNo === 1) {
        newCaseObj.stepNo = null
        newCaseObj.err = null
        newCaseObj.stepStatus = null
      }
      else {
        newCaseObj.stepNo = stepNo - 1
        newCaseObj.err = null
        newCaseObj.stepStatus = 'successful'
      }
        
    }

    editCaseObj(newCaseObj)

    if (action === 'change' && type !== 'string' && type !== 'numerical') {
      API.updateCaseSettingsOpti(caseId, newCaseObj.settingsOpti, stepNo)  
    }
    if (action === 'blur' && (type === 'string' || type === 'numerical')) {
      API.updateCaseSettingsOpti(caseId, newCaseObj.settingsOpti, stepNo)  
    }
  }

  addNewRow() {
    const { editCaseObj, caseObj, groupName, match } = this.props
    const { caseId } = match.params

    const newCaseObj = {...caseObj}

    let newRow = {
      wells: [],
      minRate: 0,
      maxRate: 0,
      groupName: '',
    }

    if (groupName === 'injection') {
      newCaseObj.settingsOpti.injectionGroupConstraints.push(newRow)    
    } else if (groupName === 'production') {
      newCaseObj.settingsOpti.productionGroupConstraints.push(newRow)    
    } else if (groupName === 'vrr') {
      newCaseObj.settingsOpti.VRRGroupConstraints.push(newRow)    
    }

    editCaseObj(newCaseObj)
    API.updateCaseSettingsOpti(caseId, newCaseObj.settingsOpti)
  }

  deleteRow(rowInfo, column) {
    return {
      onClick: () => {
        if (column.id === 'delete') {
          const { editCaseObj, caseObj, match, groupName } = this.props
          const { caseId } = match


          const newCaseObj = {...caseObj}

          if (groupName === 'injection') {
            newCaseObj.settingsOpti.injectionGroupConstraints.splice(rowInfo.index, 1)
          } else if (groupName === 'production') {
            newCaseObj.settingsOpti.productionGroupConstraints.splice(rowInfo.index, 1)
          } else if (groupName === 'vrr') {
            newCaseObj.settingsOpti.VRRGroupConstraints.splice(rowInfo.index, 1)
          }

          editCaseObj(newCaseObj)
          API.updateCaseSettingsOpti(caseId, newCaseObj.settingsOpti)
        }
      },
    }
  }

  makeTable() {
    const { editCaseObj, caseObj, disabled, stepNo, match, groupName } = this.props
    const { caseId } = match.params
    const  { deleteCol, columns } = this.state 

    if (!caseObj) {
      return
    }

    let data

    if (!caseObj.settingsOpti || (
      (groupName === 'injection' && !caseObj.settingsOpti.injectionGroupConstraints) ||   
      (groupName === 'production' && !caseObj.settingsOpti.productionGroupConstraints) ||   
      (groupName === 'vrr' && !caseObj.settingsOpti.VRRGroupConstraints)
    )) {
      data = []
      const newCaseObj = {...caseObj}

      if (!newCaseObj.settingsOpti) {
        let wells
        let wellData

        if (caseObj.refWells) {
          wells = caseObj.refWells
        } else {
          wells = []
        }

        wellData = wells.map(i => ({
          checked: false,
          name: i.name,
          type: i.type,
          minLiquidRate: 0,
          maxLiquidRate: 0,
          initialGuess: 0
        }))


        newCaseObj.settingsOpti = {
          wellConstraints: wellData,
          injectionGroupConstraints: [],
          productionGroupConstraints: [],
          VRRGroupConstraints: [],
        }
      } else {
        if (groupName === 'injection') {
          newCaseObj.settingsOpti.injectionGroupConstraints = data    
        } else if (groupName === 'production') {
          newCaseObj.settingsOpti.productionGroupConstraints = data    
        } else if (groupName === 'vrr') {
          newCaseObj.settingsOpti.VRRGroupConstraints = data    
        }
      }
      
      editCaseObj(newCaseObj)
      API.updateCaseSettingsOpti(caseId, newCaseObj.settingsOpti, stepNo)

    } else {
        if (groupName === 'injection') {
          data = caseObj.settingsOpti.injectionGroupConstraints    
        } else if (groupName === 'production') {
          data = caseObj.settingsOpti.productionGroupConstraints    
        } else if (groupName === 'vrr') {
          data = caseObj.settingsOpti.VRRGroupConstraints    
        }
    }


    let tempColumns = [...columns]

    tempColumns.forEach((column) => { 
      if (column.type === 'numerical') {
        column.Cell = row => (
          <input
            disabled={disabled}
            style={{
              'background': 'none',
              "border": "none",
              "height": '100%',
              "width": "100%",
              "padding-left": '5px',
            }}
            type="number"
            value={data[row.index][column.accessor]}
            onChange={e => this.handleSettingsChangeTable('numerical', 'change', column.accessor, row.index, e)}
            onBlur={e => this.handleSettingsChangeTable('numerical', 'blur', column.accessor, row.index, e)}          
          />
        )
      } else if (column.type === 'input') {
        column.Cell = row => (
          <input
            style={{
              'background': 'none',
              "border": "none",
              "height": '100%',
              "width": "100%",
              "padding-left": '5px',
            }}
            disabled={disabled}
            contentEditable
            suppressContentEditableWarning
            value={data[row.index][column.accessor]}
            onChange={e => this.handleSettingsChangeTable('string', 'change', column.accessor, row.index, e)}
            onBlur={e => this.handleSettingsChangeTable('string', 'blur', column.accessor, row.index, e)}
          />
        )
      } else if (column.type === 'categorical') {
        column.style = { overflow: 'visible' }

        let options

        if (groupName === 'injection') {
          options = caseObj.refWells.filter(i => i.type === 'Injector').map(i => ({label: i.name, value: i.name}))
        } else if (groupName === 'production') {
          options = caseObj.refWells.filter(i => i.type === 'Producer').map(i => ({label: i.name, value: i.name}))
        } else if (groupName === 'vrr') {
          options = caseObj.refWells.map(i => ({label: i.name, value: i.name}))
        }



        column.Cell = row => {
          let usedOptions = []
          data.forEach((item, index) => {

            if (index !== row.index) {
              usedOptions = [...usedOptions, ...item[column.accessor]]
            }
          })

          let rowOptions = options.filter(i => !(usedOptions.includes(i.label)))
          return <Select
            isDisabled={disabled}
            placeholder="Select"
            options={rowOptions}
            isMulti={true}
            value={rowOptions.filter(({ value }) => data[row.index][column.accessor] ? data[row.index][column.accessor].includes(value) : false)}
            onChange={e => this.handleSettingsChangeTable(`select-multi`, 'change', column.accessor, row.index, e)}
          />
        }
      }
    })

    if (columns.length < 5 && !disabled) {
      const deleteCol = {
        Header: '',
        accessor: 'delete',
        width: 35,
        resizable: false,
        Cell: () => (
          <div style={{ color: 'white', background: '#ff8e8e', borderRadius: '30px', textAlign: 'center', cursor: 'pointer' }}>
            X
          </div>
        ),
      }

      columns.push(deleteCol)
      this.setState({
        deleteCol: true
      })
    }



    return (
      <React.Fragment>
        <div>
          Each well can only be assigned to one group.  If you do not see a well, remove it from the original group.
        </div>
        <ReactTable
          className="-striped"
          columns={tempColumns}
          showPagination={false}
          data={data}
          pageSize={data.length}
          getTdProps={
            (state, rowInfo, column) => this.deleteRow(rowInfo, column)
          }
        />
        {!disabled && <Button type="button" variant="contained" color="primary" className="new-row-button" onClick={this.addNewRow}>Add Row</Button> }
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className="">
        { this.makeTable()}
      </div>
    )
  }
}

export default withRouter(GroupConstraintSettings)
