import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Collapsible from 'react-collapsible'
import API from '../../../../lib/api-store'
import ContinueModal from '../../../common/ContinueModal/ContinueModal'
import { styled } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip';

import './CaseEditModal.scss'

const stepNameMap = {
  engineeringAnalytics: 'Engineering Analytics',
  dataIntegration: 'Data Integration',
  logZoneAnalytics: 'Log Zone Analytics',
  attributeMapping: 'Attribute Mapping',
  drainageAnalysis: 'Drainage Analysis',
  targetSearch: 'Target Search',
  productionForecast: 'Production Forecast',
  confidenceAndGeologicalRisk: 'Confidence And Geological Risk',
  opportunityFiltering: 'Opportunity Filtering & Finalization',
  zoneSettings: 'Zone Settings',
  logAlias: 'Log Alias',
  logSettings: 'Log Settings',
  userOpps: 'User Opps',
  otherGlobalSettings: 'Other Global Settings',
  generateGrids: 'Generating Grids (Spacing & others)',
  generatePOSMap: 'RPOS Modeling',
  // confidenceGeologicalRisk: 'Confidence & Geologic Risk',
  searchDomainDefinition: 'Search Domain Definition',
  rposModeling: 'RPOS Modeling',
  interferenceAnalysis: 'Interference Analysis',
  // connectivityAnalysis 'Connectivity Analysis',
  // confidence: 'Confidence',
  filterFinalize: 'Filtering & Finalization',
}

const subStepNameMap = {
  DCA: 'DCA Settings',
  initialPressure: 'Initial Pressure',
  flowUnitAllocation: 'Flow Unit Allocation',
  waterBehavior: 'Water Behavior',
  logCleaning: 'Log Cleaning',
  mappingTimeFrame: 'Mapping Time Frame',
  gridCleaning: 'Grid Cleaning',
  wellSettings: 'Well Settings',
  pnpSettings: 'Pnp Settings',
  mnpSettings: 'Mnp Settings',
  simulationModel: 'Simulation Model',
  netPaySettings: 'Net Pay Settings',
  netSealSettings: 'Net Seal Settings',
  attributeMapping: 'Attribute Mapping',
  generalSettings: 'General Settings',
  drainageRadiusCorrection: 'Drainage Radius Correction',
  faciesTracking: 'Facies Tracking',
  targetSearch: 'Target Search',
  productionForecast: 'Production Forecast',
  settingsforThicknessNormalizedMethods: 'Settings for Thickness Normalized Methods',
  darcyParameters: 'Darcy Parameters',
  temporalNeighborhood: 'Temporal Neighborhood',
  spatialNeighborhood: 'Spatial Neighborhood',
  otherNeighborhoodSettings: 'Other Neighborhood Settings',
  interpolationSettings: 'Interpolation Settings',
  otherGlobalSettings: 'Other Global Settings',
  filterSettings: 'Filter Settings',
  projectionSettings: 'Projection Settings',
  regionOfInterest: 'Region Of Interest',
  spacing: 'Spacing',
  faults: 'Faults',
  exclusionZones: 'Exclusion Zones',
  other: 'Other',
  ranking: 'Ranking',
  coiGrids: 'COI Grids',
  spacingGrids: 'Spacing Grids',
  drainage: 'Drainage',
  additionalProperties: 'Additional Properties',
  search: 'Search',
  noGoCells: 'No Go Cells',
  optimization: 'Optimization',
  configuration: 'Configuration',
  targetCells: 'Target Cells',
  // userOpportunities: 'User Opportunities', // https://qrigroup.atlassian.net/browse/SJ-357
  conflicts: 'Conflicts',
  parameters: 'Parameters',
  joshiParameters: 'Joshi Parameters',
  furuiParameters: 'Furui Parameters',
  economidesParameters: 'Economides Parameters',
  filterDrillLocation: 'Filter Drill Location',
}

const Tag = styled(Chip)({
  margin: '5px',
})


@autobind class CaseEditModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newCaseName: '',
      newCaseDescription: '',
      newTagInput: '',
      datasetName: null,
      versionName: null,
      showDeleteCaseModal: false,
    }

  }

  componentDidMount() {
    const { caseObj } = this.props

    if (caseObj) {

      const caseId = caseObj.caseId

      if (caseObj.dataKey) {
        const { datasetId, versionId } = caseObj.dataKey
      
        API.getDatasetNames(datasetId, versionId)
          .then(names => {
            let { datasetName, versionName } = names

            this.setState({
              datasetName,
              versionName,
            })
          })
      }

      this.setState({
        newCaseName: caseObj.name,
        newCaseDescription: caseObj.description,
      })
    }
  }

  handleSaveChanges() {
    const { loadCases } = this.props
    
    const { newCaseName, newCaseDescription } = this.state
    const { caseObj } = this.props
    const caseId = caseObj.caseId

    API.editCaseNameAndDescription(caseId, newCaseName, newCaseDescription)
      .then(() => {
        // TODO handle success/err
        loadCases()
      })
  }

  handleNewTag(caseId) {
    const { loadCases } = this.props

    const { newTagInput } = this.state

    if (newTagInput.length > 0) {
      API.addTag(caseId, newTagInput)
        .then(() => {
          // TODO handle success/err
          loadCases()
        })
      this.setState({
        newTagInput: '',
      })
    }
  }

  handleDeleteTag(tagStr) {
    const { loadCases } = this.props

    const { caseObj } = this.props
    const caseId = caseObj.caseId

    API.removeTag(caseId, tagStr)
      .then(() => {
        // TODO handle success/err
        loadCases()
      })
  }

  handleNewCaseNameInput(e) {
    const { loadCases } = this.props

    const { value } = e.target
    this.setState({
      newCaseName: value,
    })
  }

  handleNewCaseDescriptionInput(e) {
    const { loadCases } = this.props

    const { value } = e.target
    this.setState({
      newCaseDescription: value,
    })
  }


  onClickDelete(){
    const { selectedDataset } = this.state
    const { caseObj, closeEditCaseModal, loadCases } = this.props
    const caseId = caseObj.caseId

    closeEditCaseModal()
    this.toggleDeleteCaseModal()
    API.deleteCase(caseId)
      .then(() => {
        // TODO handle success/err
        loadCases()
      })
  }

  toggleDeleteCaseModal(){
    this.setState({ showDeleteCaseModal: !this.state.showDeleteCaseModal })
  }


  makeTags() {
    const { caseObj } = this.props
    const { tags } = caseObj

    return tags.map((tag, index) => (
      <Tag
        label={tag}
        onDelete={() => this.handleDeleteTag(tag)}
        color="primary"
      />
    ))
  }


  makeSettings() {
    const { caseObj } = this.props
    const { settings } = caseObj

    return settings && Object.keys(settings).map(settingGroupKey => {
      const settingGroup = settings[settingGroupKey]
      return (
          <Collapsible
            className="category-trigger"
            openedClassName="category-trigger"
            trigger={stepNameMap[settingGroupKey]}
          >
            <div className="category-container">
              { this.makeSettingsGroup(settingGroup) }
            </div>
          </Collapsible>
      )
    })
  }

  makeSettingsGroup(settingGroup) {
    if (settingGroup instanceof Array) {
      return (
        <div className="setting-table" style={{paddingTop: '10px', paddingBottom: '10px'}}>
          <div className="table-header">
            {settingGroup[0] && Object.keys(settingGroup[0]).map(i => (
              <span style={{display: 'inline-block', width: '80px'}}> {i} </span>
            ))}
          </div>
          <div className="table-values">
            {this.makeTable(settingGroup)}
          </div>
        </div>
      )
    } else {
      return Object.keys(settingGroup).map(settingSubGroupKey => {
        const settingSubGroup = settingGroup[settingSubGroupKey]
        return (
          <Collapsible
            className="sub-category-trigger"
            openedClassName="sub-category-trigger"
            trigger={subStepNameMap[settingSubGroupKey]}
          >
            <div className="sub-category-container">
              { this.makeSettingsSubGroup(settingSubGroup) }
            </div>
          </Collapsible>
        )
      })
    }
  }


  makeSettingsSubGroup(settingSubGroup) {
    return Object.keys(settingSubGroup).map(settingKey => {
      const setting = settingSubGroup[settingKey]
      return (
        <div className='single-setting' style={{display: 'flex'}}>
          {settingKey !== 'Table' && (
            <div className='setting-name'>
              { settingKey }
            </div>
          )}
          { this.makeSingleSetting(setting) }
        </div>
      )
    })
  }

  makeSingleSetting(setting) {
    if (typeof setting === 'object') {
      if (setting && setting[0] && typeof setting[0] !== 'object') {
        return (
          <div className="setting-value">
            {setting.map(i => {
              return <span style={{'paddingRight': '10px'}}> {i} </span>
            })}
          </div>
        )
      }

      return (
        <div className="setting-table" style={{paddingTop: '10px', paddingBottom: '10px'}}>
          <div className="table-header">
            {setting && setting[0] && Object.keys(setting[0]).map(i => (
              <span style={{display: 'inline-block', width: '80px'}}> {i} </span>
            ))}
          </div>
          <div className="table-values">
            {this.makeTable(setting)}
          </div>
        </div>
      )
    }

    if (setting === true) {
      setting = 'True'
    } else if (setting === false) {
      setting = 'False'
    }
    return (
      <div className="setting-value"> 
        { setting }
      </div>
    )
  }


  makeTable(setting) { 
    if (!setting) {
      return null
    }

    // TODO - clean this up.. This is so the front end shows false for empty values for toggles on tables
    let fixEmpty = false
    let fixKey = ''
    setting.forEach(obj => {
      Object.keys(obj).forEach(key => {
        if (key === 'reverseAttribute' || key === 'largerThanCutoff') {
          fixEmpty = true
          fixKey = key
        }
      })
    })
    if (fixEmpty) {
      setting.forEach(obj => {
        if ((fixKey === 'reverseAttribute' && Object.keys(obj).length == 1) || (fixKey === 'largerThanCutoff' && Object.keys(obj).length == 2)) {
          obj[fixKey] = false
        }
      })
    }


    return setting.map(obj => (
      <div className="table-row">
        {Object.values(obj).map(val => {
          if (val === true) {
            val = 'True'
          } else if (val === false) {
            val = 'False'
          }
          return <span style={{display: 'inline-block', width: '80px'}}>{val}</span>
        })}
      </div>
    ))
  }

  makeDetailsTab() {
    const { 
      newCaseName,
      newCaseDescription,
      newTagInput,
      datasetName,
      versionName,
      showDeleteCaseModal
    } = this.state
    const { caseObj, isEditable, favoriteCasesRef } = this.props

    const { handleShareCase, handleFavoriteCase, handleViewCaseResults, openModal } = this.props

    const {
      caseId,
      name,
      createdAt,
      finalized,
      shared,
      description,
      userFullName,
      step,
      dataKey,
      module,
      settings,
      tags,
    } = caseObj

    const isFavorite = favoriteCasesRef === undefined ? false : favoriteCasesRef.includes(caseId)

    return (
      <div key={caseId} className='case-details'>
        <div className='case-details__header'>
          <Typography variant="h1" gutterBottom>Case Details</Typography>
          { isEditable && (
            <Button
              tabIndex="0"
              onKeyPress={null}
              className={"delete-case"}
              onClick={this.toggleDeleteCaseModal}
              variant="contained"
              size="large"
              color="primary"
            >
              Delete Case
            </Button>
          )
          }
        </div>
        <TextField
          id="case-name-input"
          label="Case Name"
          type="text"
          margin="normal"
          variant="outlined"
          className="text"
          fullWidth
          value={newCaseName}
          disabled={!isEditable}
          onChange={this.handleNewCaseNameInput}
        />

        <TextField
          id="case-name-description"
          label="Case Description"
          type="text"
          margin="normal"
          variant="outlined"
          fullWidth
          multiline
          rows="4"
          value={newCaseDescription}
          disabled={!isEditable}
          onChange={this.handleNewCaseDescriptionInput}
        />
        { isEditable && (
          <Button
            disabled={!isEditable}
            tabIndex="0"
            onClick={this.handleSaveChanges}
            onKeyPress={null}
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          >
            Save Changes
          </Button>
        )}
        <fieldset className="section">
          <Typography variant="h3" gutterBottom>Tags</Typography>
          { tags.length > 0 && (
            <div className="tags">
              { this.makeTags() }
            </div>
          )}
          { isEditable && (
            <div>
              <TextField
                id="case-new-tag"
                variant="outlined"
                size="small"
                margin="none"
                value={newTagInput}
                disabled={!isEditable}
                onChange={(e) => this.setState({
                  newTagInput: e.target.value
                })}
                InputProps={{ style: { marginRight: '10px', height: '28px' } }}
              />
              <Button
                disabled={!isEditable}
                tabIndex="0"
                onClick={() => this.handleNewTag(caseId)}
                onKeyPress={null}
                size="small"
                color="primary"
                variant="contained"
                className="add-tag-button"
              >
                ADD TAG
              </Button>
            </div>
          )
          }
        </fieldset>
        <fieldset className="section">
          <Typography variant="h3" gutterBottom>Case ID</Typography>
          <div className="text">
            {caseId}
          </div>
        </fieldset>
        <fieldset className="section">
          <Typography variant="h3" gutterBottom>Selected Module</Typography>
          <div className="text">
            {caseObj.module || 'None'}
          </div>
        </fieldset>
        <fieldset className="section">
          <Typography variant="h3" gutterBottom>Selected Dataset</Typography>
          <div className="text">
            Name: {datasetName}
          </div>
          <div className="text">
            ID: {caseObj.dataKey ? caseObj.dataKey.datasetId || 'None' : 'None'}
          </div>
        </fieldset>
        <fieldset className="section">
          <Typography variant="h3" gutterBottom>Selected Version</Typography>
          <div className="text">
            Name: {versionName}
          </div>
          <div className="text">
            ID: {caseObj.dataKey ? caseObj.dataKey.versionId || 'None' : 'None'}
          </div>
        </fieldset>
        <fieldset className="section">
          <Typography variant="h3" gutterBottom>Settings</Typography>
          { this.makeSettings()}
        </fieldset>
        <ContinueModal
          showContinueModal={showDeleteCaseModal}
          onModalCancel={this.toggleDeleteCaseModal}
          onModalContinue={this.onClickDelete}
          header="Alert: Deleting Case"
        >
          <span>
            Continuing with this action will delete the case.
          </span>
        </ContinueModal>
      </div>
    )
  }

  makeHistoryTab() {
    const { caseObj } = this.props

    return (
      <div>
        History
      </div>
    )
  }


  render() {
    const { caseObj, tab } = this.props

    if (caseObj) {
      if (tab === 'details') {
        return this.makeDetailsTab()
      }
      return this.makeHistoryTab()
    }
    return null
  }
}

const mapStateToProps = state => ({
  favoriteCasesRef: state.getIn(['user', 'favoriteCases']),
})

export default withRouter(connect(mapStateToProps, null)(CaseEditModal))