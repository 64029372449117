import React, { Component } from 'react'
import { connect } from 'react-redux'
import Link from '@material-ui/core/Link'
import { NavLink, withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'

import moment from 'moment'

import GetAppIcon from '@material-ui/icons/GetApp'
import Typography from '@material-ui/core/Typography'
import SelectedWellsChip from '../Assessment/SelectedWellsChip'
import SelectWellsDropdown from '../Assessment/SelectWellsDropdown'

import TimeSeriesDataMarker from './TimeSeriesDataMarker'
import TimelineSlider from '../../../common/TimelineSlider/TimelineSlider'
import { setTimeline } from '../../../../redux/actions/waterfloodCase'

import { generateDownloadModelBuilderResultsUrl } from '../../../../services/fileService.js'
import * as WaterfloodService from '../../../../services/waterfloodService'

import '../Assessment/Assessment.scss'

const caseClass = 'waterflood_case'
const navClass = `${caseClass}--nav`
const filterClass = `${caseClass}--filters`
const headingClass = `${filterClass}-heading`


@autobind class GlobalFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      start: 0,
      end: 0,
      timeseriesData: [],
      wells: [],
    }
  }

  componentDidMount() {
    const { timeline, rxSetTimestamp, caseId } = this.props
    WaterfloodService.getTimeseries(caseId).then((data) => {
      const { timeSeriesData } = data
      if (timeSeriesData && timeSeriesData.length) {
        const timeseries = timeSeriesData.map(x => ({ value: Math.floor(new Date(x.date.date)) / 1000, label: x.date.year }))
        const start = timeseries[0]
        const end = timeseries[timeseries.length - 1]

        // If a timeline value has not been set, set the default value to the latest
        if (timeline === undefined) {
          rxSetTimestamp(null, end.value)
        }

        this.setState({
          start,
          end, 
          timeseriesData: timeseries,
        })
      }
    })

    WaterfloodService.getCaseWells(caseId).then((wells) => {
      this.setState({
        wells,
      })
    })
  }

  downloadCSVResults() {
    const { caseId } = this.props
    const url = generateDownloadModelBuilderResultsUrl(caseId)

    const link = document.createElement('a')
    link.href = url
    link.download = 'SWM_Results.zip'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  

  }

 render() {
    const { rxSetTimestamp, timeline, caseId, match } = this.props
    const { start, end, timeseriesData, wells } = this.state
    const { application, projectId } = match.params

    if (!timeseriesData || !timeseriesData.length) {
      return null
    }

    return (
      <>
        <div className={navClass}>
          <Link variant="h6" component={NavLink} to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/case`}>Model Assessment</Link>
          <Link variant="h6" component={NavLink} to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/surveillance`}>Surveillance</Link>
          <div className="SWM-download" onClick={this.downloadCSVResults}>
            <GetAppIcon style={{ marginRight: '3px', fontSize: '20px'}}/> <span>Download Connectivity Table (CSV)</span>
          </div>
        </div>
        <div className={filterClass}>
          <div>
            <Typography className={headingClass} variant="h5" component="h2">
              Select Wells
              <SelectedWellsChip wells={wells} />
            </Typography>
            <SelectWellsDropdown caseId={caseId} />
          </div>
          <div>
            <Typography variant="h5" component="h2">Timestamp</Typography>
            <div style={{ position: 'relative' }}>
              <TimelineSlider
                valueLabelFormat={x => moment.unix(x).format('MMM YYYY')}
                marks={timeseriesData}
                min={start.value}
                max={end.value}
                onChangeCommitted={(e, val) => rxSetTimestamp(e, val)}
                value={timeline}
                step={null}
                valueLabelDisplay="auto"
                aria-labelledby="discrete-slider-restrict"
              />
              {/*
              <TimeSeriesDataMarker
                min={start.value}
                max={end.value}
                markers={timeseriesData}
              />
              */}
            </div>
          </div>
        </div>
      </>
    )
  }
}


const mapDispatchToProps = dispatch => ({
  rxSetTimestamp: (e, value) => dispatch(setTimeline(value)),
})

const mapStateToProps = state => ({
  timeline: state.getIn(['waterfloodCase', 'timeline']),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalFilters))
