import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Select from 'react-select'
import Switch from 'rc-switch'
import ReactTable from 'react-table'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Radio from '@material-ui/core/Radio'
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles'
import './SettingsSubGroup.scss'
import API from '../../../../lib/api-store'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { notSGOSubgroups } from '../../../../lib/settingsMap'


const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

var lastValue

@autobind class SettingsSubGroup extends Component {

  constructor(props){
    super(props)
    this.state = {}
  }

  handleSettingsChange(type, action, e, category, subCategory, name) {
    const { editCaseObj, caseObj, stepNo, setCanContinue, settingsMap } = this.props
    const { caseId } = caseObj

    console.log('handleSettingsChange...')
    if(e){
      console.log('e', e)
      console.log(e.target)
      console.log('val', e.target ? e.target.value : null)
    }
  
    let val

    if (type === 'string') {
      val = e.target.value
    } else if (type === 'numerical') {
      if (action === 'change') {
        val = e.target.value
      } else if (action === 'blur') {
        val = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)
      }

    } else if (type === 'select') {
      val = e.value
    } else if (type === 'select-multi') {
      val = e ? e.map(item => item.value) : []
    } else if (type === 'bool') {
      val = e
    } else if(type === 'eitherOr') {
      val = e
    } else {
      console.log('missing type', type)
    }

    const newCaseObj = { ...caseObj }

    if (!newCaseObj.settings[category]) {
      newCaseObj.settings[category] = {}
    }
    if (!newCaseObj.settings[category][subCategory]) {
      newCaseObj.settings[category][subCategory] = {}
    }

    newCaseObj.settings[category][subCategory][name] = val
    newCaseObj.step = 'customize'

    if (newCaseObj.stepNo && stepNo <= newCaseObj.stepNo) {
      if (stepNo === 1) {
        newCaseObj.stepNo = null
        newCaseObj.err = null
        newCaseObj.stepStatus = null
        if (setCanContinue) {
          setCanContinue(false)
        }
      }
      else {
        newCaseObj.stepNo = stepNo - 1
        newCaseObj.err = null
        newCaseObj.stepStatus = 'successful'
        if (setCanContinue) {
          setCanContinue(false)
        }
      }

    }

    /*
      Hard-coded setting behaviors
    */

    //  Production Forecast - Production Forecast - Primary Method
    //  Example: When you change the primary method to "TC", this toggles "shouldUseTC" to true.
    if(category === 'productionForecast' && subCategory === 'productionForecast' && name === 'primaryMethod'){
      let methodName = `shouldUse${val}`
      newCaseObj.settings[category][subCategory][methodName] = true
    }

    //  Production Forecast - Production Forecast - shouldUseSML
    //  Example: When you turn shouldUseSML off, smlParameters becomes 0
    //    This is to prevent SML API Key option from showing
    if(category === 'productionForecast' && subCategory === 'productionForecast' && name === 'shouldUseSML'){
      if(val === false){
        newCaseObj.settings[category][subCategory]['smlParameters'] = 0
      }
    }

    //  Production Forecast - Production Forecast - smlParameters
    //  Example: When you select shouldUseSML = true && smlParameters = 2 (Use API Key from SML)
    //    The primary method switches to SML
    if(category === 'productionForecast' && subCategory === 'productionForecast' && name === 'smlParameters'){
      if(val === 2){
        newCaseObj.settings[category][subCategory]['primaryMethod'] = 'SML'
      }
    }

    /*  Unconventionals
        Production Forecast - Production Forecast - smlAPIKey
        Example: When you modify the smlAPIKey, you remove the existing data for completionDataInitialization.table
        Reason: If you change the API Key, you need to be able to re-fetch the data
                We trigger the API Call to SML if there is no table data
                Therefore, we need to remove the data when API Key changes to re-fetch
    */

    if(category === 'productionForecast' && subCategory === 'productionForecast' && name === 'smlAPIKey') {
      newCaseObj.settings.productionForecast.completionDataInitialization.Table = []
    }
    // End

    /*
      Dynamic codependent settings
    */
    
    //Only update the database when user clicks away from text box, or toggles a button.
    const shouldUpdateDatabase = (action === 'change' && type !== 'string' && type !== 'numerical') || (action === 'blur' && (type === 'string' || type === 'numerical'))
    const { restrictions, default: defaultValue } = subCategory ? settingsMap[category][subCategory][name] : {}

    if (shouldUpdateDatabase) {
      //Check value restriction in here so it's not annoying for user.
      const { message, valid } = this.checkValueRestrictions(restrictions, val, newCaseObj)

      if(!valid){
        newCaseObj.settings[category][subCategory][name] = defaultValue
        alert(message)
      }
      editCaseObj(newCaseObj)
      API.updateCaseSettings(caseId, newCaseObj.settings, stepNo)
    } else {
      editCaseObj(newCaseObj)
    }

  }


  handleSettingsChangeTable(type, action, category, subCategory, data, item, index, e) {
    const { editCaseObj, caseObj, stepNo, settingsMap } = this.props
    const { caseId } = caseObj

    console.log('table change')
    console.log({...arguments})

    const newVal = data
    let val

    if (type === 'string') {
      val = e.target.value
    } else if (type === 'numerical') {
      val = parseFloat(e.target.value)
    } else if (type === 'select') {
      val = e.value
    } else if (type === 'bool') {
      val = e
    }

    const newCaseObj = { ...caseObj }

    subCategory ? newCaseObj.settings[category][subCategory]['Table'][index][item] = val : newCaseObj.settings[category][index][item] = val

    if (newCaseObj.stepNo && stepNo <= newCaseObj.stepNo) {
      if (stepNo === 1) {
        newCaseObj.stepNo = null
        newCaseObj.err = null
        newCaseObj.stepStatus = null
      }
      else {
        newCaseObj.stepNo = stepNo - 1
        newCaseObj.err = null
        newCaseObj.stepStatus = 'successful'
      }
    }

    editCaseObj(newCaseObj)

    //Only update the database when user clicks away from text box, or toggles a button.
    const shouldUpdateDatabase = (action === 'change' && type !== 'string' && type !== 'numerical') || (action === 'blur' && (type === 'string' || type === 'numerical'))

    let table = settingsMap[category].Table || settingsMap[category][subCategory].Table
    const dataColumn = table.columns.find(col => col.accessor === item)
    const defaultValue = (table.default && table.default[0]) ? table.default[0][item] : null
    const { restrictions } =  dataColumn

    if (shouldUpdateDatabase) {
      //Check value restriction in here so it's not annoying for user.
      const { message, valid } = this.checkValueRestrictions(restrictions, val, newCaseObj)

      if(!valid){
        subCategory ? newCaseObj.settings[category][subCategory]['Table'][index][item] = defaultValue : newCaseObj.settings[category][index][item] = defaultValue
        alert(message)
      }
      editCaseObj(newCaseObj)
      API.updateCaseSettings(caseId, newCaseObj.settings, stepNo)
    } else {
      editCaseObj(newCaseObj)
    }
  }

  checkValueRestrictions(restrictions, val, caseObj) {

    console.log('check value restrictions', restrictions, val, typeof val)
    console.log('SETTINGS OBJECT', caseObj)

    //Build helpers
    const response = {
      valid: true,
    }
    
    const setInvalidResponse = msg => {
      //response.val resets the value
      response.valid = false
      response.message = msg
    }

    //Handle edge cases
    if(!restrictions) return response
    val = parseFloat(val)

    for(let restriction of restrictions) {

      //Declaring variables up here because switch statements are not block scoped
      const { min, max, type, dependency, total } = restriction
      let dependencyVal, decimalsLength
      let currentYear = new Date().getFullYear()

      console.log('restriction type', type)
      console.log('value', val)
  
      if(response.valid === false) return response
  
      switch(type){
        /*
          Types:
  
          Range: min <= value <= max
          Above: value >= min
          Before Current Date: 
        */
        case 'range':
          //Ensure values are defined
          if(typeof min === 'number' && typeof max === 'number') {
            //Check that the values are in the range
            if(val >= min && val <= max) break
            else setInvalidResponse(`Ensure the values are within the range:\n\nMin = ${min}\nMax = ${max}`)
          } else {
            //Throw error for developers if missing required values
            setInvalidResponse(`Developer note: Set min/max in settingsMap for this setting\n\nCase: ${type}`)
          }
          break
        case 'aboveOrEqual':
          if(typeof min === 'number'){
            if(val >= min) break
            else setInvalidResponse(`Ensure the value is above or equal to the minimum:\n\nMin = ${min}`)
          } else {
            setInvalidResponse(`Developer note: Set min value in settingsMap for this setting.\n\nSetting: ${type}`)
          }
          break
        case 'above':
          if(typeof min === 'number') {
            if(val > min) break
            else setInvalidResponse(`Ensure the value is above the minimum:\n\nMin = ${min}`)
          } else {
            setInvalidResponse(`Developer note: Set min value in settingsMap for this setting.\n\nSetting: ${type}`)
          }
          break
        case 'beforeCurrentYear':
          if(val <= currentYear) break
          else setInvalidResponse(`This value needs to be before the current year: ${currentYear}`)
          break
        case 'integer':
          let num = parseFloat(val)
          console.log('val', val, 'num', num)
          if(Number.isInteger(num)) break
          else setInvalidResponse('This setting needs to be an integer')
          break
        case 'codependentSettingsSum':
          //Two settings values need to add up to a certain value.
          //Instead of sending a warning, we will simply reassign the value.

          //Wew lad working with float values
          if(Math.floor(val) === val) decimalsLength = 0
          else decimalsLength = val.toString().split(".")[1].length || 0
          caseObj.settings[dependency[0]][dependency[1]][dependency[2]] = parseFloat(total - val).toFixed(decimalsLength)
          break
        case 'codependentSettingsLessThan':
          dependencyVal = caseObj.settings[dependency[0]][dependency[1]][dependency[2]]
          console.log('val', val, typeof val)
          console.log('dependencyVal', dependencyVal, typeof dependencyVal)
          if(val < dependencyVal) break
          else setInvalidResponse(`This value needs to be less than the setting: ${dependency[2]}`)
          break
        case 'codependentSettingsLessThanEqualTo':
          dependencyVal = caseObj.settings[dependency[0]][dependency[1]][dependency[2]]
          if(val <= dependencyVal) break
          else setInvalidResponse(`This value needs to be less than or equal to the setting: ${dependency[2]}`)
          break
        case 'codependentSettingsGreaterThan':
          dependencyVal = caseObj.settings[dependency[0]][dependency[1]][dependency[2]]
          if(val > dependencyVal) break
          else setInvalidResponse(`This value needs to be greater than the setting: ${dependency[2]}`)
          break
        case 'codependentSettingsGreaterThanEqualTo':
          dependencyVal = caseObj.settings[dependency[0]][dependency[1]][dependency[2]]
          if(val >= dependencyVal) break
          else setInvalidResponse(`This value needs to be greater than or equal to the setting: ${dependency[2]}`)
          break
        default:
          setInvalidResponse(`Developer note: Make sure your restriction type is correct.\n\nGiven type (invalid): ${type}`)
          break
      } //Out of switch

    } //Out of for/of

    return response

  }

  makeSettings() {
    const { categoryKey, subCategoryKey, settings, settingsMap } = this.props

    // I believe this is for tables in the Global Settings only.
    if (subCategoryKey === 'Table') {
      const setting = settingsMap[categoryKey]['Table']

      return (
        <div className="setting-table">
          { this.makeTable(setting, categoryKey, null, settings)}
        </div>
      )
    }

    const settingsTemplate = settingsMap[categoryKey][subCategoryKey]

    if (settingsTemplate) {
      return Object.keys(settingsTemplate).map(settingKey => {
        
        if (!notSGOSubgroups.includes(settingKey)) {



          const setting = settingsTemplate[settingKey]
          const dependency = setting.dependency

          let dependencySatisfied = true

          if (dependency) {
            if (typeof (dependency[0]) === 'object') {
              dependency.forEach(individualDependency => {
                if (settings[individualDependency[0]][individualDependency[1]][individualDependency[2]] !== individualDependency[3]) {
                  dependencySatisfied = false
                }
              })
            } else {
              if (!settings[dependency[0]][dependency[1]] || (settings[dependency[0]][dependency[1]][dependency[2]] !== dependency[3])) {
                dependencySatisfied = false
              }
            }

          }
          if (!dependency || dependencySatisfied) {

            // Special SML API setting: completionDataInitialization.
            if (subCategoryKey === 'completionDataInitialization') {

              const { editCaseObj, caseObj } = this.props

              const APIKey = settings.productionForecast.productionForecast.smlAPIKey
              const completionData = settings.productionForecast.completionDataInitialization.Table
              
              // Two possible states            
              // 1) No completion data, need to fetch from SML
              if(completionData.length === 0) {
                const { versionId, datasetId } = caseObj.dataKey
                API.getSMLAttributes(datasetId, versionId, APIKey)
                  .then(data => {
                    if(data){
                      console.log('we have completion data', data)
                      // Update the state and database with the fetched attributes
                      this.addNewCompletionData(data)
                    } else {
                      console.log('failed to fetch completion data attributes')
                    }
                  })
                return (<div>Data is loading...  If this persists, please verify that you SML API key is accurate.</div>)
              }
              // 2) We have completion data
              else if(completionData) {
                return (
                <div className="setting-table">
                  {this.makeTable(setting, categoryKey, subCategoryKey, settings)}
                </div>)
              }
            }

            return settingKey === 'Table' ?
              <div className="setting-table">
                {this.makeTable(setting, categoryKey, subCategoryKey, settings)}
              </div> :
              <div className='setting'>
                {this.makeSetting(setting, categoryKey, subCategoryKey, settingKey)}
              </div>
          }
        }
        return null
      })
    }

    return null
  }



  makeTable(obj, category, subCategory, settings) {
    const { logOptions, disabled, horizontalPropOptions, verticalPropOptions, caseObj } = this.props
    let data = []

    if (subCategory) {
      if (settings[category] && settings[category][subCategory]) {
        data = settings[category][subCategory].Table || []
      } else {
        data = []
      }
    } else {
      data = settings[category] || []
    }

    console.log('OBJ.COLUMNS', obj.columns)
    const columns = [...obj.columns]

    let hardcodeException = false
    let logSelectOptions

    if (category === 'logAlias' && logOptions && logOptions.length > 0) {
      hardcodeException = true
      logSelectOptions = logOptions.map(i => ({
        label: i,
        value: i
      }))
    }

    columns.forEach((column) => {

      if (hardcodeException && column.accessor === 'From') {
        column.style = { overflow: 'visible' }
        column.Cell = row => (
          <Select
            isDisabled={disabled}
            placeholder="Select"
            options={logSelectOptions}
            value={logSelectOptions.filter(({ value }) => value === data[row.index][column.accessor])}
            onChange={e => this.handleSettingsChangeTable(`select`, 'change', category, subCategory, data, column.accessor, row.index, e)}
          />
        )
      }
      else if (column.type === 'input') {
        column.Cell = row => (
          <input
            style={{
              'background': 'none',
              "border": "none",
              "height": '100%',
              "width": "100%",
              "padding-left": '5px',
            }}
            disabled={disabled}
            contentEditable
            suppressContentEditableWarning
            value={data[row.index][column.accessor]}
            onChange={e => this.handleSettingsChangeTable('string', 'change', category, subCategory, data, column.accessor, row.index, e)}
            onBlur={e => this.handleSettingsChangeTable('string', 'blur', category, subCategory, data, column.accessor, row.index, e)}
          />
        )
      } else if (column.type === 'categorical') {
        column.style = { overflow: 'visible' }

        let options = column.options

        if (column.smartOption) {
          console.log('we have a smart option', category, subCategory)
          console.log('column smart option', column.smartOption)
          if (horizontalPropOptions && column.smartOption === 'horizontal') {
            options = horizontalPropOptions ? horizontalPropOptions.map(i => ({ label: i, value: i })) : []
          } else if (verticalPropOptions && column.smartOption === 'vertical') {
            options = verticalPropOptions ? verticalPropOptions.map(i => ({ label: i, value: i })) : []
          }
          console.log('resulting options', options)
        }

        column.Cell = row => {
          // let newOptions = category === "logAlias" && caseObj?.application === "SGO" ? 
          // caseObj?.ModuleSettings?.SGOSettings?.LogAlias?.filter(logAliasObj => { console.log("LogAliasObj: ", logAliasObj); return logAliasObj.From === logSelectOptions.find(({ value }) => value === data[row.index]["From"])?.label}).map(i => ({
          //   label: i?.To,
          //   value: i?.To
          // }))
          // : options;
          return (
          <Select
            isDisabled={disabled}
            placeholder="Select"
            options={options}
            value={options.filter(({ value }) => value === data[row.index][column.accessor])}
            onChange={e => this.handleSettingsChangeTable(`select`, 'change', category, subCategory, data, column.accessor, row.index, e)}
          />
        )}
      } else if (column.type === 'numerical') {
        column.Cell = row => (
          <input
            disabled={disabled}
            style={{
              'background': 'none',
              "border": "none",
              "height": '100%',
              "width": "100%",
              "padding-left": '5px',
            }}
            type="number"
            value={data[row.index][column.accessor]}
            onChange={e => this.handleSettingsChangeTable('numerical', 'change', category, subCategory, data, column.accessor, row.index, e)}
            onBlur={e => this.handleSettingsChangeTable('numerical', 'blur', category, subCategory, data, column.accessor, row.index, e)}
            min={column.min}
            max={column.max}
            step={column.step}
          />
        )
      } else if (column.type === 'bool') {
        column.Cell = row => (
          <Switch
            disabled={disabled}
            className='float-right'
            onChange={e => this.handleSettingsChangeTable('bool', 'change', category, subCategory, data, column.accessor, row.index, e)}
            checked={data[row.index][column.accessor]}
          />
        )
      } else if(column.type === 'string') {
        column.Cell = row => {
          return (
            <input
              style={{
                'background': 'none',
                "border": "none",
                "height": '100%',
                "width": "100%",
                "padding-left": '5px',
              }}
              disabled={disabled}
              contentEditable
              suppressContentEditableWarning
              value={data[row.index][column.accessor]}
              onChange={e => this.handleSettingsChangeTable('string', 'change', category, subCategory, data, column.accessor, row.index, e)}
              onBlur={e => this.handleSettingsChangeTable('string', 'blur', category, subCategory, data, column.accessor, row.index, e)}
            />
          )
        }
      }
    })

    let deleteCol

    //Hard-Coded what can/can't be deleted.
    let isRposRanking = (category === "rposModeling" && subCategory === 'ranking')
    if(isRposRanking) {
      deleteCol = {
        Header: '',
        accessor: 'delete',
        width: 35,
        resizable: false,
        Cell: (obj) => {

          if(obj.index === 0){
            return null
          } else{
            return (
              <div style={{ color: 'white', background: '#ff8e8e', borderRadius: '30px', textAlign: 'center', cursor: 'pointer' }}>
                X
              </div>
            )
          }
        },
      } 
    } else { //Default
      deleteCol = {
        Header: '',
        accessor: 'delete',
        width: 35,
        resizable: false,
        Cell: (obj) => (
          <div style={{ color: 'white', background: '#ff8e8e', borderRadius: '30px', textAlign: 'center', cursor: 'pointer' }}>
            X
          </div>
        )
      }
    }

    if (!disabled) {
      columns.push(deleteCol)
    }

    return (
      <React.Fragment>
        <ReactTable
          className="-striped"
          columns={columns}
          showPagination={false}
          data={data}
          pageSize={data.length}
          getTdProps={
            (state, rowInfo, column) => this.deleteRow(category, subCategory, rowInfo, column)
          }
        />
        {!disabled && <Button type="button" variant="contained" color="primary" className="new-row-button" onClick={() => this.addNewRow(category, subCategory)}>Add Row</Button>}
      </React.Fragment>
    )
  }

  addNewCompletionData(data) {
    const { editCaseObj, caseObj } = this.props

    const newCaseObj = { ...caseObj }

    newCaseObj.settings.productionForecast.completionDataInitialization.Table = data
    editCaseObj(newCaseObj)
    API.updateCaseSettings(caseObj.caseId, newCaseObj.settings)
  }

  addNewRow(category, subCategory) {
    const { editCaseObj, caseObj } = this.props
    const { caseId } = caseObj
    const { settings } = caseObj

    let data = []

    if (subCategory) {
      if (settings[category][subCategory]) {
        data = settings[category][subCategory].Table || []
      } else {
        data = []
      }
    } else {
      data = settings[category] || []
    }

    const newData = [...data]
    newData.push({})
    const newCaseObj = { ...caseObj }

    if (subCategory) {
      if (newCaseObj.settings[category][subCategory]) {
        newCaseObj.settings[category][subCategory]['Table'] = newData
      } else {
        newCaseObj.settings[category][subCategory] = {
          Table: newData
        }
      }
    } else {
      newCaseObj.settings[category] = newData
    }

    editCaseObj(newCaseObj)
    API.updateCaseSettings(caseId, newCaseObj.settings)
  }

  deleteRow(category, subCategory, rowInfo, column) {
    return {
      onClick: () => {
        if (column.id === 'delete') {
          const { editCaseObj, caseObj } = this.props
          const { caseId } = caseObj
          const { settings } = caseObj

          //Hard-coded non-deletable options (this is whack).
          //Can't delete 'all' option.
          if(category === 'zoneSettings' && !subCategory && rowInfo.original.zoneId === 'all' ){
            alert(`Cannot delete the 'all' option from this table.`)
            return
          }
          //Can't delete first row.
          if(category === 'rposModeling' && subCategory === 'ranking' && rowInfo.index === 0) {
            return
          }

          const data = subCategory ? settings[category][subCategory].Table : settings[category]
          console.log('rowInfo', rowInfo)
          console.log('data', data)
          const newData = [...data]
          newData.splice(rowInfo.index, 1)
          const newCaseObj = { ...caseObj }
          subCategory ? newCaseObj.settings[category][subCategory]['Table'] = newData : newCaseObj.settings[category] = newData

          editCaseObj(newCaseObj)
          API.updateCaseSettings(caseId, newCaseObj.settings)
        }
      },
    }
  }

  makeSetting(obj, category, subCategory, name) {
    const display = obj.display
    const description = obj.description

    // !obj.image ? obj.display : 
    //   (<>
    //     {obj.display}
    //     <img src={obj.image} />
    //   </>)

    return (
      <React.Fragment>
        <div className="left">
          <Typography className="setting-name" variant="body1">
            {obj.display}
            {description && (
              <a data-tip={obj.description} data-for={obj.display}>
                <FontAwesomeIcon icon="question-circle" className="help-icon" />
              </a>
            )}
          </Typography>
          <Typography variant="caption">
            {obj.subDisplay}
          </Typography>
        </div>
        <div className="right">
          {this.makeSettingInput(obj, category, subCategory, name)}
        </div>
        {description && (<ReactTooltip id={display} className="customize-settings-tooltip" effect="solid" />)}
      </React.Fragment>
    )
  }

  makeSettingInput(obj, category, subCategory, name) {
    const { caseObj, disabled, wellGroupSetsToMatchOptions, zoneOptions } = this.props
    const { settings } = caseObj

    let val = null
    let settingOptions = obj.options

    // Hardcoded exceptions
    if (name == 'targetZones' && zoneOptions && zoneOptions.length > 0) {
      settingOptions = zoneOptions.map(i => ({
        label: i,
        value: i
      }))
      settingOptions = [{
        label: 'All',
        value: 'all'
      }, ...settingOptions]
    }

    if (name === 'wellGroupSetsToMatch' && wellGroupSetsToMatchOptions && wellGroupSetsToMatchOptions.length > 0) {
      settingOptions = wellGroupSetsToMatchOptions.map(i => ({
        label: i,
        value: i
      }))
    }

    let labelLength = obj.unit && obj.unit.length ? obj.unit.length : 0

    try {
      val = settings[category][subCategory][name]
    } catch (e) {
      console.log(e)
    }

    switch (obj.type) {
      case 'missing':
        return (
          <div style={{ 'color': 'red' }} className="setting-input">
            {obj.display} - {obj.msg.length > 1 ? obj.msg : 'MISSING'}
          </div>
        )
      case 'string':
        return (
          <TextField
            className="setting-input"
            disabled={disabled}
            onChange={e => this.handleSettingsChange('string', 'change', e, category, subCategory, name)}
            onBlur={e => this.handleSettingsChange('string', 'blur', e, category, subCategory, name)}
            InputProps={{
              endAdornment: <InputAdornment position="end">{obj.unit}</InputAdornment>,
            }}
            value={val}
            variant="outlined"
            margin="dense"
          />
        )
      case 'numerical':
        return (
          <TextField
            disabled={disabled}
            className="setting-input"
            value={val}
            onChange={e => this.handleSettingsChange('numerical', 'change', e, category, subCategory, name)}
            onBlur={e => this.handleSettingsChange('numerical', 'blur', e, category, subCategory, name)}
            InputProps={{
              endAdornment: <InputAdornment position="end">{obj.unit}</InputAdornment>,
            }}
            variant="outlined"
            margin="dense"
            style={{ minWidth: 75 + (labelLength * 5) }}
          />
        )
      case 'bool':
        return (
          <Switch
            disabled={disabled}
            className='float-right'
            onChange={e => this.handleSettingsChange('bool', 'change', e, category, subCategory, name)}
            checked={val}
          />
        )
      case 'categorical': {
        const item = val
        let value

        if (obj.multi) {
          value = item ? settingOptions.filter(({ value }) => item.includes(value)) : []
        } else {
          value = settingOptions.filter(({ value }) => value === item)
        }

        return (
          <Select
            className="setting-input selector"
            options={settingOptions}
            isMulti={obj.multi}
            isDisabled={obj.disabled || disabled}
            value={value}
            onChange={e => this.handleSettingsChange(`select${obj.multi ? '-multi' : ''}`, 'change', e, category, subCategory, name)}
          />
        )
      }
      case 'eitherOr': {
        const { options } = obj

        // This was being used for SML Parameters setting only, but that has been removed.
        // Keeping the code here in case we need to use the layout at a later point.

        return (
          <div className="settings-input-eitherOr float-right">
            <div className="settings-input-eitherOr__option float-right">
              <GreenRadio
                checked={val === options[0].value}
                value={options[0].value}
                onChange={() => this.handleSettingsChange('eitherOr', 'change', options[0].value, category, subCategory, name)}
                className="float-right"
                size="large"
              />
              <Typography
                className="settings-input-eitherOr__option__text"
                onClick={() => this.handleSettingsChange('eitherOr', 'change', options[0].value, category, subCategory, name)}
              >
                {options[0].display + '  '}
                {options[0].description && (
                  <a data-tip={options[0].description} data-for={"settings-input-eitherOr"}>
                    <FontAwesomeIcon icon="question-circle" className="help-icon" />
                  </a>
                )}
              </Typography>
            </div>
            <div className="settings-input-eitherOr__option float-right">
              <GreenRadio
                checked={val === options[1].value}
                value={options[1].value}
                onChange={() => this.handleSettingsChange('eitherOr', 'change', options[1].value, category, subCategory, name)}
                className="float-right"
                size="large"
              />
              <Typography
                className="settings-input-eitherOr__option__text"
                onClick={() => this.handleSettingsChange('eitherOr', 'change', options[1].value, category, subCategory, name)}
              >
                {options[1].display + '  '}
                {options[1].description && (
                  <a data-tip={options[1].description} data-for={"settings-input-eitherOr"}>
                    <FontAwesomeIcon icon="question-circle" className="help-icon" />
                  </a>
                )}
              </Typography>
            </div>
            <ReactTooltip id={"settings-input-eitherOr"} className="customize-settings-tooltip" effect="solid" />
          </div>
        )
      }
      default:
        return <div> MISSING type </div>
    }
  }

  render() {
    const { text, caseObj } = this.props

    return (
      <div className="settings-container">
        { text && (
          <div className="settings-text-container">
            <div className="settings-text">{text}</div>
            <Divider />
          </div>
        )}

        { this.makeSettings()}
      </div>
    )
  }
}



export default SettingsSubGroup