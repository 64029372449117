import React, { Component } from 'react'
import Pagination from 'react-js-pagination'
import Typography from '@material-ui/core/Typography'
 
const PaginationComponent = (props) => {
  const {
    data,
    activePage,
    onPageChange,
    totalItems,
    itemsPerPage,
  } = props

  return (
    <div>
      <Typography className="total-text" align="center" display="inline" variant="body1">
          Displaying {data.length} out of {totalItems}
      </Typography>
      { true ? (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItems}
          pageRangeDisplayed={itemsPerPage}
          onChange={onPageChange}
          prevPageText="Previous"
          nextPageText="Next"
          lastPageText="Last"
          firstPageText="First"
        />
      ) : null }
    </div>
  )
}
 
export default PaginationComponent