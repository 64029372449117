import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Execution extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Recompletions Execution Steps
        </div>
        <div className="content-header"> 
          Step 1: Initialization
        </div>
        <div className="content-row"> 
          This step loads all the required data from different data categories, performs initialization of various variables, and processes data in order to store it in the database. In particular, fault and model data are saved to the database.
        </div>
        <div className="content-header"> 
          Step 2: Engineering Analytics
        </div>
        <div className="content-row"> 
          This step uses all relevant data to perform the following engineering analysis:
        </div>
        <div className="content-row"> 
          &ensp;• <b>Contact Analysis:</b> Computes contact lengths of the well perforations within each zone.<br/>
          &ensp;• <b>Flow Unit Allocation:</b> Back-allocates the production to individual zones within a well for each month using different methods.<br/>
          &ensp;• <b>Decline Curve Analysis (DCA):</b> Performs decline curve analysis for all wells available in this dataset. For further information, please refer to the pdf manual subsection <b>Engineering Analytics</b>.<br/>
        </div>
        <div className="content-header"> 
          Step 3: Log/Zone Analytics
        </div>
        <div className="content-row"> 
          The purpose of this step is to generate various types of analytics using the available log data.
        </div>
        <div className="content-row"> 
          &ensp;• <b>Generates Net Logs:</b> This step generates various types of net (Boolean) logs and derived logs. This includes the net pay log, perforated net pay (PNP) log (also known as drained net pay—DNP), and the uncontacted net pay (UNP) log (also known as missed net pay—MNP).<br/>
          &ensp;• <b>Calculates Zone Averages:</b> This step aggregates various logs at each well/zone using different net logs created in the previous part of this step. For example, porosity is aggregated for each zone in each well as follows:<br/>
          &ensp;&ensp;◦ <b>Net Average:</b> This is the porosity average in the net pay intervals of the zone.<br/>
          &ensp;&ensp;◦ <b>Perf Average:</b> This is the porosity average in the perforated intervals of the zone.<br/>
          &ensp;&ensp;◦ <b>PNP Average:</b> This is the porosity average in the perforated net pay intervals of the zone.<br/>
          &ensp;&ensp;◦ <b>MNP Average:</b> This is the porosity average in the missed net pay intervals of the zone.
        </div>
        <div className="content-header"> 
          Step 4: Attribute Mapping
        </div>
        <div className="content-row"> 
          This step creates several attribute grids to look at the spatial variation in each zone’s behavior and history. The grids created include a recent water-cut map, recent gas/oil ratio map, current production map, and a cumulative production map. Additional geo-engineering attributes can be mapped if the underlying well data is available. These geo-engineering attributes are used to extract values for wells that do not have this information. For example, if a well has never been perforated in a particular zone, the recent water-cut grid can be used to approximate the potential water-cut value, it if were to be perforated.
        </div>
        <div className="content-header"> 
          Step 5: Drainage Analysis
        </div>
        <div className="content-row"> 
          A drainage analysis is performed to ensure that the uncontacted net pay intervals identified in the previous phase have not been drained, or will not be drained, by offset wells. Drainage from each completion is characterized by coupling the results of the flow unit allocation from the Engineering Analytics step with the rock property logs. This analysis is performed on either a zone-by-zone basis if the zones constitute isolated flow units, or on a global basis in vertically connected systems. The contacted hydrocarbon pore thickness is also mapped during this step to approximate drainage areas for wells without log information.
        </div>
        <div className="content-row"> 
          For further information, please refer to the pdf manual subsection <b>Drainage Analysis (Recompletion and Sweet Spots Modules)</b>.
        </div>
        <div className="content-header"> 
          Step 6: Target Search
        </div>
        <div className="content-row"> 
          This step gathers all potential behind pipe remaining oil (uncontacted net pay) for an initial list of recompletion opportunity candidates.
        </div>
        <div className="content-header"> 
          Step 7: Production Forecast
        </div>
        <div className="content-row"> 
          This step uses the selected forecast method to generate a prediction for each opportunity’s initial production (IP) rate. It also estimates an EUR using the predicted IP and the decline parameters aggregated from the neighborhood wells.
        </div>
        <div className="content-row"> 
          For further information regarding the forecast methods, please refer to the pdf manual subsection <b>Production Forecast</b>.
        </div>
        <div className="content-header"> 
          Step 8: Opportunity Filtering
        </div>
        <div className="content-row"> 
          This step in this workflow uses various opportunity attributes to filter the list of generated recompletion opportunities. These attributes are user-defined and can include geologic attributes, petrophysical attributes, production attributes, and engineering attributes, which can be selected in the step’s settings.
        </div>
        <div className="content-row"> 
          This final step also finalizes the opportunities, saves the case information and various results to the database in preparation for the visualizations phase. Therefore, please be patient as this last step may take a few minutes to complete.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Execution)
