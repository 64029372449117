import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import { Check, Checkbox } from '../../../../lib/formatters'

const PROPERTY_PREFIX = 'qgeo_'

@autobind class NeighborLogsTable extends PureComponent {
  
  handleClick(name) {
    const { selectNeighbor } = this.props
    selectNeighbor(name)
  }

  render() {
    const { data, selectedOffsetWells } = this.props
    const tableData = []
    let aggProperties = {}

    Object.keys(data).forEach((wellName) => {
      const properties = data[wellName] ? Object.keys(data[wellName]) : []

      /* Find and clean up geo property titles */
      const geoProperties = properties.filter(prop => prop.startsWith(PROPERTY_PREFIX))
      const boolGeoProperties = geoProperties.reduce((acc, prop) => {
        const cleanPropTitle = prop.replace(PROPERTY_PREFIX, '')
        return { ...acc, [cleanPropTitle]: true }
      }, {})

      /* Aggregate all unique properties to build table */
      aggProperties = { ...aggProperties, ...boolGeoProperties }

      tableData.push({
        name: wellName,
        ...boolGeoProperties,
        distance: Number(data[wellName].distance).toFixed(0),
      })
    })

    const geoPropColumns = Object.keys(aggProperties).map(prop => ({
      Header: prop,
      accessor: prop,
      minWidth: 35,
      Cell: Check,
    }))

    return (
      <div className="table">
        <ReactTable
          className="-striped -hover"
          columns={
            [{
              Header: <div>Selected</div>,
              id: 'selected',
              accessor: row => selectedOffsetWells.includes(row.name),
              minWidth: 55,
              Cell: Checkbox,
            }, {
              Header: <div>Name</div>,
              accessor: 'name',
              minWidth: 80,
            },
            ...geoPropColumns,
            {
              Header: 'Dist',
              id: 'distance',
              accessor: d => Number(d.distance),
              minWidth: 30,
            }]
          }
          showPagination={false}
          data={tableData}
          pageSize={tableData.length}
          defaultSorted={[
            {
              id: 'distance',
              desc: false,
            }
          ]}
          getTdProps={(state, rowInfo, column, instance) => ({
            onClick: (e, handleOriginal) => {
              this.handleClick(rowInfo.row.name)
            },
            style: {
              background: selectedOffsetWells.includes(rowInfo.row.name) ? '#87CEFA' : null,
            },
          })}
        />
      </div>
    )
  }
}


export default NeighborLogsTable
