import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'

import Recompletions from './Recompletion/Recompletions'
import SweetSpots from './SweetSpots/SweetSpots'
import Horizontals from './Horizontals/Horizontals'


@autobind class Modules extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <Switch>
          <Route path="/training/manual/modules/recompletions" component={Recompletions} />
          <Route path="/training/manual/modules/sweetSpots" component={SweetSpots} />
          <Route path="/training/manual/modules/horizontals" component={Horizontals} />
        </Switch>
      </div>
    )
  }
}




export default Modules
