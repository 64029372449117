import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import logZoneAnalyticsOverviewA from '../../../../../../../../../../static/images/userManual/settings/logZoneAnalyticsOverviewA.png'


@autobind class LogZoneAnalyticsOverview extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Log Zone Analytics Overview
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          The purpose of this step is to generate various types of analytics using the available log data.
        </div>
        <div className="content-row"> 
          • <span className='blue'>Generates Net Logs:</span> This step generates various types of net (Boolean) logs and derived logs. This includes the net pay log, the perforated net pay (PNP) log (also known as drained net pay—DNP), and the uncontacted net pay (UNP) log (also known as missed net pay—MNP).
        </div>
        <div className="content-row"> 
          • <span className='blue'>Calculates Zone Averages:</span> This step aggregates various logs at each well/zone using different net logs created in the previous part of this step. For example, porosity is aggregated for each zone in each well as follows:
        </div>
        <div className="content-row"> 
          • <span className='blue'>Net Average:</span> This is the porosity average in the net pay intervals of the zone.
        </div>
        <div className="content-row"> 
          • <span className='blue'>Perf Average:</span> This is the porosity average in the perforated intervals of the zone.
        </div>
        <div className="content-row"> 
          • <span className='blue'>PNP Average:</span> This is the porosity average in the perforated net pay intervals of the zone.
        </div>
        <div className="content-row"> 
          • <span className='blue'>UNP Average (Only for Recompletions):</span> This is the porosity average in the uncontacted net pay intervals of the zone.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={logZoneAnalyticsOverviewA} alt="" />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(LogZoneAnalyticsOverview)
