import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import { Auth } from 'aws-amplify'

import speedwiseLogo from '../../../../static/images/speedwise-logo-blue.png'

import API from '../../../lib/api-store'
import { logout } from '../../../redux/actions/user'
import sgoLogo from '../../../../static/images/sgo-logo.png';
import swmLogo from '../../../../static/images/swm-logo@2x.png';
import ProductNavigation from '../Navigation/ProductNavigation/ProductNavigation'


const applicationMap = {
  SWM: {
    name: 'Waterflood Management',
    logo: swmLogo,
    className: 'header-swm'
  },
  SGO: {
    name: 'Reservoir Opportunity',
    logo: sgoLogo,
    className: 'header-sgo'
  },
}


@autobind class ProductHeader extends Component {
  async handleLogout() {
    const { logoutAction } = this.props
    
    API.logout().then((r) => {
      logoutAction()
    })
  }

  render() {
    const { match } = this.props 
    const { application } = match.params

    const applicationObj = applicationMap[application]

    let className, logo

    if (!applicationObj) {
      className = 'header-default'
      logo = speedwiseLogo
      name = ''
    } else {
      className = applicationObj.className
      logo = applicationObj.logo
      name = applicationObj.name
    }

    return (
     <div className="header-outer">
        <div className={`product-header-inner ${className}`}>
          <img className="logo" src={logo} alt={`${application} Logo`} />
          <div className="product-name">
            SpeedWise
            <span className='product-name-registed'>
              <sup>®</sup>
            </span>
            <span className="product-name-span">
              {name}
            </span>
          </div>
          <ProductNavigation handleLogout={this.handleLogout} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.get('user'),
})

const mapDispatchToProps = dispatch => ({
  logoutAction: user => dispatch(logout(user)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductHeader))
