import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FileCopy, Edit, Favorite, History, Share } from '@material-ui/icons'

import { stringFormatDate } from '../../../services/dateFormatterService'
import StepBar from '../../layout/Cases/Components/StepBar'


import Avatar from '../../common/Avatar/Avatar'
import './QRICard.scss'

const containerClass = 'qri-card'
const pictureClass = `${containerClass}__picture-container`

const profileClass = `${containerClass}__name-container`
const itemNameClass = `${profileClass}__item-name`
const ownerNameClass = `${profileClass}__owner-name`

const descriptionClass = `${containerClass}__description-container`
const dateClass = `${descriptionClass}__date`
const descriptionTextClass = `${descriptionClass}__text`

const otherInformationClass = `${containerClass}__other-container`

const actionsClass = `${containerClass}__actions-container`
const favoriteClass = `${actionsClass}__favorite`
const favoriteSelectedClass = `${favoriteClass}--selected`
const shareClass = `${actionsClass}__share`
const shareSelectedClass = `${shareClass}--selected`

const verticalAlignClass = 'align-vertical'


@autobind class QRICard extends Component {
  onItemClick(e) {
    const { onItemClick } = this.props
    e.stopPropagation()
    onItemClick()
  }

  onFavoriteClick(e) {
    const { onFavoriteToggle } = this.props
    e.stopPropagation()
    onFavoriteToggle()
  }

  onShareClick(e) {
    const { onShareToggle } = this.props
    e.stopPropagation()
    onShareToggle()
  }

  onEditClick(e) {
    const { onEditClick } = this.props
    e.stopPropagation()
    onEditClick()
  }

  onCopyClick(e) {
    const { onCopyClick } = this.props
    e.stopPropagation()
    onCopyClick()
  }

  onHistoryClick(e) {
    const { onHistoryClick } = this.props
    e.stopPropagation()
    onHistoryClick()
  }

  render() {
    const {
      itemId,
      index,
      name,
      finalized,
      shared,
      description,
      createdBy,
      dateCreated,
      // not yet implemented yet
      step,
      // dataKey,
      // module,
      // settings,
      isFavorite,
      onFavoriteToggle,
      isShared,
      onShareToggle,
      onEditClick,
      onCopyClick,
      onHistoryClick,
    } = this.props

    const showFavoriteButton = isFavorite !== undefined && onFavoriteToggle !== undefined
    const showShareButton = isShared !== undefined && onShareToggle !== undefined
    const showEditButton = onEditClick !== undefined
    const showCopyButton = onCopyClick !== undefined
    const showHistoryButton = onHistoryClick !== undefined

    const formattedDate = stringFormatDate(dateCreated)
    return (
      <div
        className={containerClass}
        key={itemId}
        onKeyPress={null}
        onClick={this.onItemClick}
        role="button"
        tabIndex={index}
      >
        <div className={`${pictureClass} ${verticalAlignClass} `}>
          <Avatar fullName={createdBy} />
        </div>
        <div className={profileClass}>
          <div className={itemNameClass}>{name}</div>
          <div className={ownerNameClass}>
            By {createdBy}
          </div>
        </div>

        <div className={descriptionClass}>
          <div className={dateClass}>{formattedDate}</div>
          <div className={descriptionTextClass}>{description}</div>
        </div>

        <div className={otherInformationClass}>
          <StepBar
            finalized={finalized}
            shared={shared}
            step={step}
            itemId={itemId}
          />
        </div>

        <div className={actionsClass}>
          {showEditButton && (
            <Edit
              onClick={this.onEditClick}
            />
          )}

          {showHistoryButton && (
            <History
              onClick={this.onHistoryClick}
            />
          )}

          {showCopyButton && (
            <FileCopy
              onClick={this.onCopyClick}
            />
          )}

          {showFavoriteButton && (
            <div className={`${favoriteClass} ${isFavorite ? favoriteSelectedClass : ''}`}>
              <Favorite
                onClick={this.onFavoriteClick}
              />
            </div>
          )}
          {showShareButton && (
            <div className={`${shareClass} ${isShared ? shareSelectedClass : ''}`}>
              <Share
                onClick={this.onShareClick}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({

})

export default withRouter(connect(mapStateToProps, null)(QRICard))