import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import Card from '../common/Card'
import './Settings.scss'
import Modify from '../../Cases/Customize/Modify'
import API from '../../../../lib/api-store'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class Settings extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      caseObj: null
    }
  }

  componentDidMount() {
    const { match } = this.props
    const { params } = match
    const { caseId } = params


    this.loadCase()
  }

  loadCase() {
    const { caseId } = this.props.match.params

    API.getCase(caseId)
      .then(caseObj => {
        this.setState({
          caseObj
        })
      })
  }


  renderSettings() {
    const { caseObj } = this.state


    if (caseObj) {
      return (
        <Modify 
          caseObj={caseObj}
          disabled={true}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }


  render() {
    console.log('render Settings')

    return (
      <div className="settings">
          {this.renderSettings()}
      </div>
    )
  }
}

export default Settings
