import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class DeclineCurveAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Flow Unit Allocation
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          This step back-allocates the production and injection to individual zones within a well for each month, allowing a very granular perspective in performance by formation and by well over time. This technology provides results that are typically not known for most reservoirs, enabling highly targeted reservoir management workflows. All relevant data that is available, such as ILT, PLT, pressure, viscosity, etc., are incorporated using the workflows embedded in the tool for every well, over the field’s entire history. In addition, this module is equipped with multi-PVT flow allocation methodology, in case the field consists of different regions with different fluid PVT properties. This functionality allows for considering different PVT regions into flow unit allocation calculations.
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Flow Unit Allocation Setup<br/>
          <span className='blue'>Options:</span> Chan, KH<br/>
          <span className='blue'>Comments:</span> 'Kh' also known as the Darcy Model is recommended for most reservoirs. 'Chan' uses Chan's water behavior identification algorithm, and can be recommened for fractured reservoirs with channeling and coning.<br/>
          PLEASE NOTE: Regardless of this 'Setup' choice, if there are PLTs uploaded, then the default will be to use them.
        </div>
        <div className="content-row"> 
          Differentiation Method<br/>
          <span className='blue'>Options:</span> Bourdet, Forward, Backward<br/>
          <span className='blue'>Comments:</span> The method used for the differentiation of the water oil ratio.
        </div>
        <div className="content-row"> 
          Events Detection Data<br/>
          <span className='blue'>Options:</span> 1, 2, 3<br/>
          <span className='blue'>Comments:</span> Identifies the choice of data to be use to detect changes in data trend:<br/>
          1) Derivative of water oil ratio<br/>
          2) Water oil ratio<br/>
          3) Both derivative of water oil ration, and water oil ratio.<br/>
        </div>
        <div className="content-row"> 
          Min Fit Period Duration<br/>
          <span className='blue'>Options:</span> Min Value must be greater than 0<br/>
          <span className='blue'>Comments:</span> Sensitivity for detecting changes in the trend of the water oil ratio derivative. If sensitivity is 1, only the major trends are detected.
        </div>
        <div className="content-row"> 
          Shut in Period Threshold<br/>
          <span className='blue'>Options:</span> Min Value must be greater than 0<br/>
          <span className='blue'>Comments:</span> Number of months identifying the minimum duration of a fit period (if the duration is smaller than the minimum, the fit period is merged with the preceding period.
        </div>
        <div className="content-row"> 
          Smooth Level<br/>
          <span className='blue'>Options:</span> Min Value must be greater than 0<br/>
          <span className='blue'>Comments:</span> Number of months identifying a duration threshold.
        </div>
        <div className="content-row"> 
          Smoothing Method Index<br/>
          <span className='blue'>Options:</span> 1,2,3,4,5,6,7<br/>
          <span className='blue'>Comments:</span> An array that lists the choice of the smoothing methods to be used for the treatment of WOR data:<br/>
          1: moving (moving average)<br/>
          2: lowess<br/>
          3: loess<br/>
          4: sgolay<br/>
          5: rlowess<br/>
          6: rloess<br/>
          7: supsmu (super smoother)
        </div>
        <div className="content-row"> 
          Weight REI Increase Last Period<br/>
          <span className='blue'>Options:</span> Value must be between 0 and 100<br/>
          <span className='blue'>Comments:</span> Relative weight increase of the last period when calculating misfit to raw data. This is to give more weight to the last period when identifying the best smoothing method. To leave the weight equaly distributed between all fit periods, set to zero.
        </div>
        <div className="content-row"> 
          Initial Pressure<br/>
          <span className='blue'>Options:</span> PSI<br/>
          <span className='blue'>Comments:</span> Reservoir pressure is used to convert the measured flow rates at the surface to reservoir conditions. The analysis interpolates the initial pressure through time in order to get more accurate results.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(DeclineCurveAnalysis)
