import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Divider from '@material-ui/core/Divider'

import Comment from './Comment'


const CommentList = (props) => {
  const { data, userId } = props
  const commentNodes = data.map(comment => (
    <>
      <Comment
        author={comment.author}
        authorId={comment.authorId}
        userId={userId}
        key={comment._id}
        id={comment._id}
        timestamp={comment.created}
        handleUpdateComment={props.handleUpdateComment}
        handleDeleteComment={props.handleDeleteComment}
      >
        { comment.text}
      </Comment>
      <Divider variant="middle" component="li" />
    </>
  ))
  return (
    <List className="comment-log">
      <Divider variant="middle" component="li" />
      { commentNodes }
    </List>
  )
}

export default CommentList
