import { waterfloodBaseUrl } from './apiRoutes'
import {
  createGetRequestParams,
  createPostRequestParams,
  createPutRequestParams,
  createDeleteRequestParams,
} from './apiRequestHelper'

const wellURL = `${waterfloodBaseUrl}/well`
const caseURL = `${waterfloodBaseUrl}/case`

const getWell = (wellId) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${wellURL}/${wellId}`, getRequestParams).then(r => r.json())
}

const getTimeseries = (caseId) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/timeseries`, getRequestParams).then(r => r.json())
}

const getCaseWells = (caseId) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/wells`, getRequestParams).then(r => r.json())
}

const getCaseReservoir = (caseId) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/reservoir`, getRequestParams).then(r => r.json())
}

const getCaseSurfaces = (caseId) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/surfaces`, getRequestParams).then(r => r.json())
}

const getCaseGrid = (caseId) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/grid`, getRequestParams).then(r => r.json())
}

const getV = (caseId, name) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/grid/${name}/v`, getRequestParams).then(r => r.json())
}

const getF2V = (caseId, name) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/grid/${name}/f2v`, getRequestParams).then(r => r.json())
}

const getCaseTrajectories = (caseId, timestamp) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/trajectories?timestamp=${timestamp}`, getRequestParams).then(r => r.json())
}

const getModelAccuracy = (caseId, xAxis = '', wells = []) => {
  const wellsStr = wells.length ? wells.join(',') : ''
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/accuracy?xAxis=${xAxis}&wells=${wellsStr}`, getRequestParams).then(r => r.json())
}


const getConnectivity = (caseId, timeline) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/connectivity?date=${timeline}`, getRequestParams).then(r => r.json())
}

const getConnectivityTable = (caseId, timeline) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/connectivity-table?date=${timeline}`, getRequestParams).then(r => r.json())
}

const getTimeSeriesData = (caseId) => {
  const getRequestParams = createGetRequestParams()
  return fetch(`${caseURL}/${caseId}/time-series-data`, getRequestParams).then(r => r.json())
}



export {
  getWell,
  getV,
  getF2V,
  getCaseWells,
  getCaseGrid,
  getTimeseries,
  getCaseSurfaces,
  getCaseTrajectories,
  getCaseReservoir,
  getModelAccuracy,
  getConnectivity,
  getConnectivityTable,
  getTimeSeriesData,
}