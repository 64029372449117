import React from 'react'

import Drawer from '@material-ui/core/Drawer'

import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import styled from '@material-ui/core/styles/styled'
import Descriptions from '../../../../../lib/datasetMaps/groupDescriptions'

import './FileDescriptionDrawer.scss'

class FileDescriptionDrawer extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }


  render() {

    const { close, category, isOpen } = this.props

    if(!category) return null

    let desc = Descriptions[category.category]
    let title = category.category

    title = title === 'Units' ? 'Specify Feet or Meters' : title
    if(!desc && category.templateName === 'ExampleGridFile') {
      desc = Descriptions.Grid_Data
      title = 'Surface Files'
    }

    return (
      <Drawer
        open={isOpen}
        variant="persistent"
        anchor="right"
        PaperProps={{ style: { position: 'absolute', width: '50%' } }}
      >
        <div>
          <Toolbar
            style={{ justifyContent: 'space-between', paddingLeft: 16, paddingRight: 16 }}
          >
            <Typography variant="h5">{title}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="Close Validation Drawer"
              onClick={close}
            >
              <ChevronRightIcon/>
            </IconButton>
          </Toolbar>
          <div>
            {desc}
          </div>
        </div>
      </Drawer>
    )
  }
}

export default FileDescriptionDrawer