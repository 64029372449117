import autobind from 'autobind-decorator'
import React, { Component } from 'react'
import styled from '@material-ui/styles/styled'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { FileCopy, Edit, Delete } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GetAppIcon from '@material-ui/icons/GetApp'
import Link from '@material-ui/core/Link'

import Chicklet from '../../../common/Chicklet/Chicklet'
import { generateDownloadVersionUrl } from '../../../../services/datasetService'
import { setVersionName } from '../../../../services/datasetService.js'

import { ChickletTypes } from '../../../common/Chicklet/chickletTypes'
import { numberFormatDate } from '../../../../services/dateFormatterService'

import './DatasetHistory.scss'

const baseClass = 'dataset-history'

const historyItemClass = `${baseClass}__history-item`

const statusCircleClass = `${historyItemClass}__status-circle`
const circlePublishedClass = `${statusCircleClass}--published`

const versionInfoClass = `${historyItemClass}__version-info`
const topBarClass = `${versionInfoClass}__top-bar`
const dateClass = `${topBarClass}__date`
const versionNumberClass = `${topBarClass}__version-number`

const actionClass = `${historyItemClass}__actions`

const contentClass = `${versionInfoClass}__content`
const leftContentClass = `${contentClass}__left`
const rightContentClass = `${contentClass}__right`

const iconButtonClass = 'card-icon-btn'

@autobind class DatasetHistory extends Component {
  constructor(props){
    super(props)
    this.state = {
      editVersionId: null,
      editVersionName: null,
    }
    
  }

  onClickDownloadVersion(version) {
    const { project, dataset } = this.props
    const { versionId, version: versionNumber } = version
    const { name: datasetName, _id: datasetId } = dataset
    
    const link = document.createElement('a')
    link.setAttribute('href', generateDownloadVersionUrl(datasetId, versionId))
    link.setAttribute('download', `${project.name} - ${datasetName} v${versionNumber}.zip`)
    document.body.appendChild(link) 
    link.click()
    document.body.removeChild(link)
  }
 
  onTextboxClick(e) {
    e.stopPropagation()
    this.setEnterClickEventListener(e)
  }

  setEditVersionNameId(versionId) {
    this.setState({ editVersionId: versionId })
  }

  onInputVersionName(e) {
    const text = e.target.value
    this.setEnterClickEventListener(e)
    this.setState({
      editVersionName: text
    })
  }

  setEnterClickEventListener(e) {
    e.target.addEventListener("keyup", e => {
      if(e.keyCode === 13) {
        e.preventDefault()
        this.onSubmitVersionName()
      }
    })
  }

  onSubmitVersionName() {
    const { versions, latestVersion, dataset } = this.props
    const { editVersionName: name, editVersionId: id } = this.state

    if(name){
      let version
      if(latestVersion.versionId === id) version = latestVersion
      else {
        versions.forEach(v => {
          if(v.versionId === id) version = v
        })
      }
      version.publishedName = name
      console.log('version', version)
  
      setVersionName(dataset._id, id, name)
    }

    this.setState({
      editVersionId: null,
      editVersionName: null,
    })
  }

  render() {
    const {
      versions,
      latestVersion,
      userIsOwner,
      showAllVersions,
      onClickEdit,
      onClickCopy,
      toggleDeleteVersionContinueModal,
    } = this.props

    const {
      editVersionId,
      editVersionName
    } = this.state
    
    const preventDefault = event => event.preventDefault();
    
    let displayVersions = [...versions]

    if (latestVersion) {
      displayVersions = [latestVersion, ...versions]
    }

    if (!showAllVersions) {
      displayVersions = displayVersions.filter(x => x.published)
    }

    displayVersions.sort((a, b) => {
      if (a.version > b.version) {
        return -1;
      }
      if (a.version < b.version) {
        return 1;
      }
      return 0
    })

    const WarningIconInfo = styled(ErrorOutlineIcon)({
      width: '0.7em',
      height: '0.7em',
      display: "inline",
      color: "#ff9800",
      margin: '0 0 -1px 5px'
    })

    return (
      <div className={baseClass}>

        {displayVersions.map((version) => {
          return (
            <div key={version.version} className={historyItemClass}>
              <div className={`${statusCircleClass} ${version.published && circlePublishedClass}`} />
              <div className={versionInfoClass}
                onClick={() => editVersionId ? null : latestVersion.version === version.version ? onClickEdit() : onClickEdit(version.versionId)}
              > 
                <div className={topBarClass}>
                  <div className={dateClass}>
                    {numberFormatDate(version.dateCreated || new Date("11-25-19"))}
                  </div>

                  <div className={versionNumberClass}>

                    {version.versionId === editVersionId && (
                      <ClickAwayListener onClickAway={this.onSubmitVersionName}>
                        <input
                          className="version-name-input"
                          value={editVersionName}
                          onChange={this.onInputVersionName}
                          placeholder={version.publishedName}
                          onClick={e => this.onTextboxClick(e)}
                          autoFocus
                        />
                      </ClickAwayListener>
                    )}
                    {version.versionId !== editVersionId && (
                      version.publishedName
                        ? version.publishedName
                        : `Version ${version.version}`
                    )}
                    {version.isOutdated && (<WarningIconInfo/>)}
                  </div>

                  
                </div>
                <div className={contentClass}>
                  <div className={leftContentClass}>
                    {latestVersion.version === version.version && (
                      <Chicklet
                        chickletType={ChickletTypes.CURRENT}
                      />
                    )}
                    <Chicklet
                      chickletType={determineChicklet(version)}
                    />

                  </div>
                  <div className={rightContentClass}>
                    {version.cases.length > 0 && (
                      <Link href="#" onClick={preventDefault}>
                        {version.cases.length} linked cases
                    </Link>
                    )}
                    <div>
                      {generateHistoryText(version.history)}
                    </div>
                  </div>
                </div>
              </div>
              <div className={actionClass}>
                    {(userIsOwner) && (
                      <Tooltip title="Edit Version Name">
                        <IconButton className={iconButtonClass} end="start" aria-label="delete">
                          <Edit onClick={() => this.setEditVersionNameId(version.versionId)} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {(latestVersion.version === version.version && !version.valid && version.version > 1 && userIsOwner) && (
                      <Tooltip title="Delete">
                        <IconButton className={iconButtonClass} end="start" aria-label="delete">
                          <Delete onClick={() => toggleDeleteVersionContinueModal()} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {version.valid && (
                      <Tooltip title="Copy">
                        <IconButton className={iconButtonClass} end="start" aria-label="copy">
                          <FileCopy onClick={(() => onClickCopy(version.versionId))} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {version.valid && (
                      <Tooltip title="Download">
                        <IconButton className={iconButtonClass} end="start" aria-label="copy">
                          <GetAppIcon onClick={(() => this.onClickDownloadVersion(version))} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
            </div>
          )
        })}

      </div>
    )
  }
}

function determineChicklet(version) {
  if (version.valid) {
    return ChickletTypes.VALID
  }
  return null
}

function generateHistoryText(history) {
  let historyObjects = []

  if (history.added > 0) {
    historyObjects.push((<><span>{history.added} file{history.added > 1 ? 's were' : ' was'} added</span><br /></>))
  }
  if (history.changed > 0) {
    historyObjects.push((<><span>{history.changed} file{history.changed > 1 ? 's were' : ' was'} changed</span><br /></>))
  }
  if (history.removed) {
    historyObjects.push((<span>{history.removed} file{history.removed > 1 ? 's were' : ' was'} removed</span>))
  }
  return (
    <>
      {historyObjects.length > 0 && historyObjects.map((historyObject) => {
        return historyObject
      })}
      {historyObjects.length === 0 && (
        <span>There were no file changes</span>
      )}
    </>
  )
}

export default DatasetHistory