import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class HorFilterFinalize extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Filter Drill Locations
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Coming Soon
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(HorFilterFinalize)
