import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import { ClipLoader } from 'react-spinners'
import Card from '../../../common/Card'
import ModelSectionPlot from '../../../components/ModelSectionPlot'
import OpportunityKPIs from '../components/OpportunityKPIs'
const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;` 

@autobind class OpportunitySummary extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shouldRenderDetails: false,
      shouldRenderProduction: false,
    }
  }

  componentDidMount() {
    const { setCurPage } = this.props
    setCurPage('')
    this.loadData()
  }

  loadData() {
    setTimeout(() => {
      this.setState({
        shouldRenderDetails: true,
      })  
    }, 100)

    setTimeout(() => {
      this.setState({
        shouldRenderProduction: true,
      })  
    }, 200)
  }

  renderDetails() {
    const { singleOpportunity, caseId, caseObj, updateOpportunity } = this.props
    if (singleOpportunity && this.state.shouldRenderDetails) {
      return  (
        <OpportunityKPIs
          caseObj={caseObj}
          caseId={caseId}
          data={singleOpportunity}
          updateOpportunity={updateOpportunity}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderModel() {
    const { modelSectionData, singleOpportunity, wellData } = this.props
    if (modelSectionData && Object.keys(modelSectionData).length > 0 && singleOpportunity && wellData) {
      return (
        <ModelSectionPlot
          modelSectionData={modelSectionData}
          opportunityData={singleOpportunity}
          wellData={wellData}
        />
      )
    }
    if (!modelSectionData) {
      return (
        <div>
          This dataset does not contain a 3D Model File.
        </div>
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  render() {
    const { map, modelSectionData } = this.props
    const modelSubtitle = modelSectionData ? modelSectionData.propertyId : ''

    return (
      <div className="opportunity-summary">
        <div className="top">
          <div className="left">
            {map}
          </div>
          <div className="right">
            <Card title="Opportunity Details">
              { this.renderDetails() }
            </Card>
          </div>
        </div>
        <div className="bot">
          <Card title="Model Section" subtitle={modelSubtitle}>
            { this.renderModel() }
          </Card>
        </div>
      </div>
    )
  }
}


export default OpportunitySummary
