import React from 'react'

import img1 from './static/ReservoirPressureStatic1.png'
import img2 from './static/ReservoirPressureStatic2.png'

const ReservoirPressureStatic = (
  <div className={'description-container'}>
    <p><b>Content:</b> Static reservoir pressures measured over time.</p>
    <p><b>Utility:</b> The average reservoir pressure data will be used for flow unit allocation algorithm to allocation production data to different zones/intervals.</p>
    <p><b>Conventions: DatumDepth:</b> Values below sea level need to be positive</p>
    <p><b>Date:</b> It must be in mm/dd/yyyy format</p>
    <p><b>File Format:</b> CSV template (downloadable) (example shown in Figure 11).</p>
    <p><b>Units:</b> Template indicates what units are needed.</p>
    <p><b>Other Note:</b> Not all columns are required. See Table 11 for specifics.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 11: Example of the first few rows of a “Static_Reservoir_Pressure_Monthly.csv.”</figcaption>
    </figure>
    <figure>
      <figcaption>Table 11. Details for the “Static_Reservoir_Pressure_Monthly.csv.”</figcaption>
      <img src={img2} />
    </figure>
  </div>
)

export default ReservoirPressureStatic