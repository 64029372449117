import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import {
  pinCaseOpportunity,
  unpinCaseOpportunity,
} from '../../../../redux/actions/user'


@autobind class PinnedOpportunities extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleClick() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  pinnedByCase() {
    const { pinned, caseId } = this.props
    if (!pinned) {
      return []
    }
    return pinned.filter(x => x.get('case_id') === caseId)
  }

  render() {
    const { unpinCaseOpportunityAction, projectId } = this.props
    const { match } = this.props
    const { application, caseId } = match.params

    const pinned = this.pinnedByCase()

    if (!pinned || !pinned.size) {
      return null
    }

    return (
      <List component="div" disablePadding>
        { pinned.map((opportunity) => {
          const opportunityJS = opportunity.toJS()
          const { case_id, _id, zone } = opportunityJS
          const name = opportunityJS.name || opportunityJS.wellId
          const link = `/${application}/projects/${projectId}/cases/${case_id}/visualization/opportunities/${_id}`
          return (
            <ListItem>
              <NavLink to={link}>{name} {zone ? '-' : ''} {zone}</NavLink>
              <ListItemSecondaryAction>
                <IconButton onClick={e => unpinCaseOpportunityAction(_id, case_id)} size="small" aria-label="remove-pin">
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  pinCaseOpportunityAction: (opportunityId, caseId) => dispatch(pinCaseOpportunity(opportunityId, caseId)),
  unpinCaseOpportunityAction: (opportunityId, caseId) => dispatch(unpinCaseOpportunity(opportunityId, caseId)),
})

const mapStateToProps = state => ({
  pinned: state.getIn(['user', 'pinnedOpportunities']),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PinnedOpportunities))
