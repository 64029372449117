import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { setTooltip } from '../../../../redux/actions/SGOVisualization'
import Tooltips from './tooltips'

/*
 * Meant to be replaced with the Material UI card,
 * currently phasing it out in steps.
 */
@autobind class Card extends PureComponent {
  render() {
    const { title, subtitle, children, overflowY, tooltipKey, setTooltip } = this.props
    const overflowStyle = overflowY && !overflowY.length ? 'auto' : overflowY
    const style = overflowStyle ? { overflow: overflowStyle } : {}

    return (
      <div className="card">
        <div className="title">
          { title }
          { tooltipKey && (
            <FontAwesomeIcon
              icon="question-circle"
              className="help-icon"
              style={{ 
                'height': '13px',
                'width': '13px',
                'position': 'relative',
                'left': '3px',
                'top': '-2px',
                'cursor': 'pointer',
              }}
              onClick={e => setTooltip(tooltipKey, title)}
            />
          )}
          {subtitle ? (
            <div className="subtitle">
              <Typography variant="body1">{ subtitle }</Typography>
            </div>
          ) : null}
        </div>
        <div className="child" style={style}>
          { children }
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setTooltip: (key, title) => dispatch(setTooltip(key, title))
})

export default connect(null, mapDispatchToProps)(Card)
