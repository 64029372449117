import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import './UserCard.scss'

const baseClass = 'userCard'

@autobind class UserCard extends Component {


  render() {
    const  { user, handleUserClick, type, disabled, disabledTooltip } = this.props


    if (!user) {
      if (disabled) {
        return (
          <React.Fragment>
            <a data-tip={disabledTooltip} data-for={'test2'}>
              <div 
                className={baseClass}
                style={{background: '#b5b5b5', cursor: 'default'}}
              >
                <div className='avatar-grey'>
                  <div className='words'></div>
                </div>
                <div className='user-name'>
                  {type}
                </div>
              </div>
            </a>
            <ReactTooltip id={'test2'} multiline className="customize-settings-tooltip" effect="solid" />
          </React.Fragment>
        ) 
      }



      return (
        <div 
          className={baseClass}
          onClick={() => handleUserClick('something')}
        >
          <div className='avatar-grey'>
            <div className='words'><PersonAddIcon/></div>
          </div>
          <div className='user-name'>
            {type}
          </div>
        </div>
      )
    }



    let name = user.name.split(' ')
    let firstLetter = name[0][0].toUpperCase()
    let secondLetter = name[1][0].toUpperCase()

    return (
      <div 
        className={baseClass}
        onClick={() => handleUserClick(user.id)}
      >
        <div className='avatar'>
          <div className='words'>{firstLetter}{secondLetter}</div>
        </div>
        <div className='user-type'>
          {type}
        </div>
        <div className='user-name'>
          {user.name}
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(UserCard)
