import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'
import { range } from '../../../../lib/helpers'

const colorParula = [
    [0/63, 'rgb(62, 39, 169)'],
    [1/63, 'rgb(64, 43, 181)'],
    [2/63, 'rgb(66, 47, 192)'],
    [3/63, 'rgb(68, 51, 203)'],
    [4/63, 'rgb(70, 55, 214)'],
    [5/63, 'rgb(71, 60, 223)'],
    [6/63, 'rgb(71, 66, 230)'],
    [7/63, 'rgb(72, 71, 236)'],
    [8/63, 'rgb(72, 77, 241)'],
    [9/63, 'rgb(72, 83, 245)'],
    [10/63, 'rgb(72, 88, 248)'],
    [11/63, 'rgb(71, 94, 251)'],
    [12/63, 'rgb(69, 100, 253)'],
    [13/63, 'rgb(67, 106, 254)'],
    [14/63, 'rgb(63, 112, 255)'],
    [15/63, 'rgb(57, 118, 255)'],
    [16/63, 'rgb(51, 124, 253)'],
    [17/63, 'rgb(47, 130, 250)'],
    [18/63, 'rgb(46, 135, 247)'],
    [19/63, 'rgb(45, 141, 243)'],
    [20/63, 'rgb(44, 146, 239)'],
    [21/63, 'rgb(40, 151, 236)'],
    [22/63, 'rgb(38, 156, 232)'],
    [23/63, 'rgb(36, 161, 229)'],
    [24/63, 'rgb(32, 165, 227)'],
    [25/63, 'rgb(29, 170, 224)'],
    [26/63, 'rgb(25, 174, 220)'],
    [27/63, 'rgb(18, 178, 215)'],
    [28/63, 'rgb(8, 181, 209)'],
    [29/63, 'rgb(1, 184, 202)'],
    [30/63, 'rgb(2, 187, 196)'],
    [31/63, 'rgb(12, 189, 189)'],
    [32/63, 'rgb(25, 192, 182)'],
    [33/63, 'rgb(36, 194, 175)'],
    [34/63, 'rgb(44, 196, 168)'],
    [35/63, 'rgb(50, 198, 160)'],
    [36/63, 'rgb(56, 200, 152)'],
    [37/63, 'rgb(63, 202, 142)'],
    [38/63, 'rgb(75, 204, 133)'],
    [39/63, 'rgb(87, 205, 123)'],
    [40/63, 'rgb(100, 205, 112)'],
    [41/63, 'rgb(114, 205, 100)'],
    [42/63, 'rgb(129, 204, 89)'],
    [43/63, 'rgb(144, 203, 78)'],
    [44/63, 'rgb(158, 201, 67)'],
    [45/63, 'rgb(172, 199, 57)'],
    [46/63, 'rgb(185, 197, 49)'],
    [47/63, 'rgb(198, 194, 42)'],
    [48/63, 'rgb(210, 192, 40)'],
    [49/63, 'rgb(221, 189, 41)'],
    [50/63, 'rgb(231, 187, 46)'],
    [51/63, 'rgb(240, 186, 54)'],
    [52/63, 'rgb(249, 187, 62)'],
    [53/63, 'rgb(254, 190, 61)'],
    [54/63, 'rgb(255, 196, 57)'],
    [55/63, 'rgb(254, 202, 52)'],
    [56/63, 'rgb(253, 208, 49)'],
    [57/63, 'rgb(250, 214, 46)'],
    [58/63, 'rgb(247, 221, 42)'],
    [59/63, 'rgb(246, 227, 40)'],
    [60/63, 'rgb(245, 233, 37)'],
    [61/63, 'rgb(246, 240, 33)'],
    [62/63, 'rgb(248, 245, 28)'],
    [1, 'rgb(250, 251, 21)']
]
const depthRange = [-1250, -1050]
const trajectoryColor = 'blue'
const targetColor = 'red'
const offset = 50
const attribute = ''

// Similar function to python's bisect
// Return the index of x if x is inserted to an sorted ascend array
function bisect (array, x) {
  if (array.length === 0) {
    return 0;
  }

  for (var i = 0; i < array.length; i++) {
    if (array[i] > x) return i
  }

  return i
}

@autobind class ModelSectionPlot extends PureComponent {

  render() {
    const { modelSectionData, opportunityData, wellData } = this.props
    const { name, x, y, XData, YData, ZData, propertyId, properties, trajectory } = modelSectionData
    let { startDepthMd, stopDepthMd } = opportunityData
    const location = { x, y }
    const plotData = []
    let wellTrajectory = null
    let xMin = null
    let xMax = null
    let yMin = null
    let yMax = null
    let zMin = null
    let zMax = null

    let viewX = 1
    let viewY = 0

    console.log('XData', XData)
    console.log('YData', YData)
    console.log('ZData', ZData)


    if (XData == null && YData == null && ZData == null) {
      console.log('broke on', name)
      return null
    }
    
    XData.forEach((row) => {
      row.forEach((item) => {
        if (!xMin || item < xMin) {
          xMin = item
        }
        if (!xMax || item > xMax) {
          xMax = item
        }
      })
    })

    YData.forEach((row) => {
      row.forEach((item) => {
        if (!yMin || item < yMin) {
          yMin = item
        }
        if (!yMax || item > yMax) {
          yMax = item
        }
      })
    })

    ZData.forEach((row) => {
      row.forEach((item) => {
        if (!zMin || (item && item < zMin)) {
          zMin = item
        }
        if (!zMax || (item && item > zMax)) {
          zMax = item
        }
      })
    })

    zMin = zMin || depthRange[0]
    zMax = zMax || depthRange[1]

    const xLimits = [xMin - offset, xMax + offset]
    const yLimits = [yMin - offset, yMax + offset]
    const zLimits = [zMin, zMax]

    if (opportunityData.type === 'verticalDrill') {
      wellTrajectory = {
        x: trajectory.map((i, idx) => i.x),
        y: trajectory.map((i, idx) => i.y),
        z: trajectory.map(i => i.z),
        md: trajectory.map(i => i.z),
      }
    } else if(opportunityData.type === 'horizontalDrill') {
      wellTrajectory = {
        x: trajectory.map(i => i.x),
        y: trajectory.map(i => i.y),
        z: trajectory.map(i => i.z),
        md: trajectory.map(i => i.z),
      }
    } else {
      Object.keys(wellData).forEach((wellName) => {
        const well = wellData[wellName]
        if (well['name'] === name) {
          const trajectory = well['trajectory']

          wellTrajectory = {
            x: trajectory.map(i => i.x),
            y: trajectory.map(i => i.y),
            z: trajectory.map(i => -1 * i.tvdss),
            md: trajectory.map(i => i.md),
          }
        }
      })
    }

    // Add trajectory data
    if (wellTrajectory) {
      plotData.push({
        type: 'scatter3d',
        x: wellTrajectory['x'],
        y: wellTrajectory['y'],
        z: wellTrajectory['z'],
        mode: 'lines',
        line: {
          color: trajectoryColor,
          width: 5,
        },
        hoverinfo: 'text',
        hovertext: name,
      })

      if (opportunityData.type === 'horizontalDrill') {
        plotData.push({
          type: 'scatter3d',
          x: [wellTrajectory['x'][0], wellTrajectory['x'][wellTrajectory['x'].length - 1]],
          y: [wellTrajectory['y'][0], wellTrajectory['y'][wellTrajectory['y'].length - 1]],
          z: [wellTrajectory['z'][0], wellTrajectory['z'][wellTrajectory['z'].length - 1]],
          mode: 'markers',
          marker: {
            size: 4,
            symbol: 'circle',
            color: 'rgba(250,0,0,1)',
          },
          hoverinfo: 'text',
          hovertext: name,
        })
      }

      plotData.push({
        type: 'scatter3d',
        x: [wellTrajectory['x'].slice(0), wellTrajectory['x'].slice(-1)],
        y: [wellTrajectory['y'].slice(0), wellTrajectory['y'].slice(-1)],
        z: [wellTrajectory['z'].slice(0), wellTrajectory['z'].slice(-1)],
        mode: 'markers',
        marker: {
          color: trajectoryColor,
          size: 0,
        },
      })

      plotData.push({
        type: 'scatter3d',
        x: [0, 1],
        y: [0, 1],
        z: [0, 1],
        mode: 'lines',
        line: {
          color: trajectoryColor,
          width: 0,
        },
      })

      // Filter the data according to the start and stop depth in the oppotunity
      if (startDepthMd && stopDepthMd) {
        let startTargetIdx = bisect(wellTrajectory['md'], startDepthMd)
        let stopTargetIdx = bisect(wellTrajectory['md'], stopDepthMd)

        if (startTargetIdx == stopTargetIdx) {
          let startTargetRatio = (startDepthMd - wellTrajectory['md'][stopTargetIdx-1]) / (wellTrajectory['md'][stopTargetIdx] - wellTrajectory['md'][stopTargetIdx-1])
          let stopTargetRatio = (stopDepthMd - wellTrajectory['md'][stopTargetIdx-1]) / (wellTrajectory['md'][stopTargetIdx] - wellTrajectory['md'][stopTargetIdx-1])

          plotData.push({
            type: 'scatter3d',
            x: [wellTrajectory['x'][stopTargetIdx-1] + startTargetRatio*(wellTrajectory['x'][stopTargetIdx] - wellTrajectory['x'][stopTargetIdx-1]), wellTrajectory['x'][stopTargetIdx-1] + stopTargetRatio*(wellTrajectory['x'][stopTargetIdx] - wellTrajectory['x'][stopTargetIdx-1])],
            y: [wellTrajectory['y'][stopTargetIdx-1] + startTargetRatio*(wellTrajectory['y'][stopTargetIdx] - wellTrajectory['y'][stopTargetIdx-1]), wellTrajectory['y'][stopTargetIdx-1] + stopTargetRatio*(wellTrajectory['y'][stopTargetIdx] - wellTrajectory['y'][stopTargetIdx-1])],
            z: [wellTrajectory['z'][stopTargetIdx-1] + startTargetRatio*(wellTrajectory['z'][stopTargetIdx] - wellTrajectory['z'][stopTargetIdx-1]), wellTrajectory['z'][stopTargetIdx-1] + stopTargetRatio*(wellTrajectory['z'][stopTargetIdx] - wellTrajectory['z'][stopTargetIdx-1])],
            mode: 'lines',
            line: {
              color: targetColor,
              width: 20,
            },
            hoverinfo: 'text',
            hovertext: 'Target',
          })
        } else {
          plotData.push({
            type: 'scatter3d',
            x: wellTrajectory['x'].slice(startTargetIdx-1, stopTargetIdx),
            y: wellTrajectory['y'].slice(startTargetIdx-1, stopTargetIdx),
            z: wellTrajectory['z'].slice(startTargetIdx-1, stopTargetIdx),
            mode: 'lines',
            line: {
              color: targetColor,
              width: 20,
            },
            hoverinfo: 'text',
            hovertext: 'Target',
          })
        }
      }

      viewX = wellTrajectory['x'].slice(-1)[0] - location['x']
      viewY = wellTrajectory['y'].slice(-1)[0] - location['y']

      // Force view for vertical opportunities
      if (opportunityData.type === 'verticalDrill') {
        viewX = 0
        viewY = 0
      }
    }

    // Add the x section heatmap data
    const XDataSmall = XData.filter((row, idx) => idx % 2).map((col) => {
      return col.filter((c, i) => i % 2)
    })

    const YDataSmall = YData.filter((row, idx) => idx % 2).map((col) => {
      return col.filter((c, i) => i % 2)
    })

    const ZDataSmall = ZData.filter((row, idx) => idx % 2).map((col) => {
      return col.filter((c, i) => i % 2)
    })

    const propertiesSmall = properties.filter((row, idx) => idx % 2).map((col) => {
      return col.filter((c, i) => i % 2)
    })

    plotData.push({
      type: 'surface',
      x: XDataSmall,
      y: YDataSmall,
      z: ZDataSmall,
      surfacecolor: propertiesSmall,
      colorscale: 'Viridis',
      opacity: .8,
      colorbar: {
        len: .5,
        thickness: 15,
        title: {
          text: propertyId,
        }
      },
      hoverinfo: 'none',
    })
    
    // Add the grid lines of the x section
    properties.forEach((row, index) => {
      plotData.push({
        type: 'scatter3d',
        x: XDataSmall.map(i => i[index]),
        y: YDataSmall.map(i => i[index]),
        z: ZDataSmall.map(i => i[index]),
        mode: 'lines',
        line: {
          color: 'black',
          width: 1
        },
        hoverinfo: 'none'
      })

      plotData.push({
        type: 'scatter3d',
        x: XDataSmall[index],
        y: YDataSmall[index],
        z: ZDataSmall[index],
        mode: 'lines',
        line: {
          color: 'black',
          width: 1
        },
        hoverinfo: 'none'
      })
    })

    let camera = {
      up: {
        x: 0,
        y: 0,
        z: 1,
      },
      center: {
        x: 0,
        y: 0,
        z: 0,
      },
      eye: {
        y: 1.5 * viewX / (Math.pow(Math.pow(viewX, 2) + Math.pow(viewY, 2), .5)),
        x: -1.5 * viewY / (Math.pow(Math.pow(viewX, 2) + Math.pow(viewY, 2), .5)),
        z: 0,
      }
    }

    let layout = {
      autosize: true,
      margin: {l: 0, r: 0, t: 0, b: 0, pad: 4},
      scene: {
        camera,
        xaxis: {
          range: xLimits
        },
        yaxis: {
          range: yLimits,
        },
        zaxis: {
          range: zLimits
        }
      },
      showlegend: false,
    }

    return (
      <Plot
        className="plot"
        data={plotData}
        layout={layout}
        config={{displaylogo: false, showSendToCloud: true}}
        useResizeHandler
      />
    )
  }
}

export default ModelSectionPlot
