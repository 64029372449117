import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import MUICard from '../../../common/MUICard/MUICard'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

// import moment from 'moment'
import './ConnectivityTable.scss'

import { getConnectivityTable } from '../../../../services/waterfloodService'
import { Typography } from '@material-ui/core'

// import './ConnectivityGraph.scss'

const baseClass = 'connectivity-table'
const filtersClass = `${baseClass}__filters`

const percColumns =[
  {
    Header: 'Wells',
    accessor: 'wellName',
    minWidth: 80,
  },
  {
    Header: 'Allocation Factor',
    accessor: 'allocationFactor',
    minWidth: 80,
  },{
    Header: 'Connection Efficiency',
    accessor: 'connectionEfficiency',
    minWidth: 80,
  }
]
 
const valColumns =[
  {
    Header: 'Wells',
    accessor: 'wellName',
    minWidth: 80,
  },
  {
    Header: 'Allocation',
    accessor: 'allocation',
    minWidth: 80,
  },{
    Header: 'Connection',
    accessor: 'connection',
    minWidth: 80,
  }
]
                       

const dispOptions = [
  { label: 'Percentages', value: 'perc'},
  { label: 'Absolute Values', value: 'value'}
]

@autobind class ConnectivityTable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      disp: 'perc',
      data: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    const { timeline, selectedWells} = this.props
    if (prevProps.timeline !== timeline) {
      this.getData()
    }
  }



  getData() {
    const { selectedWells } = this.props
    const { timeline, caseId } = this.props
    const tableData = []

    getConnectivityTable(caseId, timeline).then((data) => {
      
      // TODO - handle wellDaata didnt load
      this.setState({
        data,
        loaded: true
      })
    })
  }

  makeTables(data) {
    const { disp } = this.state
    const { type } = this.props

    let tableData = data.map(well => {
      let wellConnections


      let showWell = (well.timeSeriesData && well.timeSeriesData[0].status === type)

      if (well.timeSeriesData && well.timeSeriesData[0].wellConnections && showWell) {

        return ({
          wellName: well.name, 
          rows: well.timeSeriesData[0].wellConnections.map(i => ({
            wellName: i.wellName,
            allocationFactor: `${Math.round((i.WAF + Number.EPSILON) * 100)} %`,
            connectionEfficiency: `${Math.round((i.connEffic + Number.EPSILON) * 100)} %`,
            allocation: i.allocation,
            connection: i.connection,
          }))
        })
      }
    }).filter(i => i)

    let height =Math.min((tableData.length * 31 + 47), 500)

    if(tableData.length === 0) {
      return <Typography>No data found for this filter. Please try again with different filter values.</Typography>
    }

    return  (
        <div className="table">
          <ReactTable
            className="outer"
            style={{'height': `${height}px`}}
            columns={disp === 'perc' ? percColumns : valColumns}
            showPagination={false}
            data={tableData}
            pageSize={tableData.length}
            SubComponent={row => {
              return (
                <React.Fragment>
                  <div className='italic-header'>
                    Connected {type}s
                  </div>
                  <ReactTable
                    className="inner"
                    columns={disp === 'perc' ? percColumns : valColumns}
                    showPagination={false}
                    data={row.original.rows}
                    pageSize={row.original.rows.length}
                  />
                </React.Fragment>
              )
            }}
          />
        </div>
    )
  }

  handleChange(e) {
    this.setState({
      disp: e.target.value
    })
  }

  render() {
    const { loaded, data, disp } = this.state
    const { selectedWells, title } = this.props

    let filteredData = data

    if (selectedWells.size > 0) {
      filteredData = data.filter(i => selectedWells.includes(i._id))
    } else if( selectedWells.size === 0) { // If no wells selected, show all
      filteredData = data
    }

    return (
      <MUICard 
        title={title}
        busy={!loaded}
      >
      <div>
        <FormControl component="fieldset">
          <RadioGroup row aria-label="display options" name="display" value={disp} onChange={this.handleChange}>
            {dispOptions.map((opt) => (
              <FormControlLabel key={`opt-${opt.value}`} value={opt.value} control={<Radio color="primary" />} label={opt.label} />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
        {this.makeTables(filteredData)}
      </MUICard>
    )
  }
}

const mapStateToProps = state => ({
  timeline: state.getIn(['waterfloodCase', 'timeline']),
  selectedWells: state.getIn(['waterfloodCase', 'selectedWells']),
})

export default withRouter(connect(mapStateToProps, null)(ConnectivityTable))
