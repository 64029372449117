import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import ReadMore from '../../../common/ReadMore/ReadMore'

const baseClass = 'dataset-header'
const projectTitleClass = `${baseClass}__project-title`
const datasetTitleClass = `${baseClass}__dataset-title`

const contentClass = `${baseClass}__content`
const leftContentClass = `${contentClass}--left`
const rightContentClass = `${contentClass}--right`

const backButtonClass = `${baseClass}__back-btn`
const backButtonIconClass = `${backButtonClass}__icon`

@autobind class Header extends PureComponent {
  render() {
    const { caseObj, match } = this.props
    const { projectId, application } = match.params
    

    return (
      <div className={baseClass}>
        <div className={contentClass}>
          <div className={`${leftContentClass} align-vertical`}>
            <div>
              <div className={projectTitleClass}>Case: {caseObj && caseObj.name}</div>
              <div className={datasetTitleClass}>
                {caseObj && caseObj.description && (
                <ReadMore variant="subtitle1" noWrap={true} gutterBottom>              
                  {`${caseObj.description}`}
                </ReadMore>
                )}
              </div>
            </div>
          </div>
          <div className={`${rightContentClass} align-vertical`}>
            <NavLink className={backButtonClass} to={`/${application}/projects/${projectId}/cases`}>
              <ChevronLeftIcon className={backButtonIconClass} />
              Back to Cases
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)
