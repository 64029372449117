import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Executive from '../components/layout/Visualization/Executive/Executive'
import OpportunityCatalog from '../components/layout/Visualization/Opportunity/OpportunityCatalog/OpportunityCatalog'
import Opportunity from '../components/layout/Visualization/Opportunity/Opportunity'
import WellCatalog from '../components/layout/Visualization/WellCatalog/WellCatalog'
import Settings from '../components/layout/Visualization/Settings/Settings'
import Download from '../components/layout/Visualization/Download/Download'
import PrivateRoute from './CustomRoute/PrivateRoute'

const VisualizationRoutes = ({ 
	user,
  match,
	gridData,
  faultData,
  wellData,
  caseObj,
  opportunityData,
  structureZMatrix,
  structureGridX,
  structureGridY,
  zMatrix,
  gridX,
  gridY,
  colorWheel,
  updateOpportunity,
  updateOpportunityComments,
  gridProperty,
  updateGridProperty,
  gridPopertyOptions,
  zone,
  updateZone,
  zoneOptions,
  populatedZoneOptions,
  datasetId,
  realDatasetId
}) => (
  <React.Fragment>
    <PrivateRoute exact path="/:application/projects/:projectId/cases/:caseId/visualization"
      user={user}
      wellData={wellData}
      faultData={faultData}
      opportunityData={opportunityData}
      structureZMatrix={structureZMatrix}
      structureGridX={structureGridX}
      structureGridY={structureGridY}
      zMatrix={zMatrix}
      gridX={gridX}
      gridY={gridY}
      colorWheel={colorWheel}
      gridProperty={gridProperty}
      updateGridProperty={updateGridProperty}
      gridPopertyOptions={gridPopertyOptions}
      zone={zone}
      updateZone={updateZone}
      zoneOptions={zoneOptions}
      populatedZoneOptions={populatedZoneOptions}
      component={Executive}  
    />
    <PrivateRoute exact path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities"
      user={user}
      opportunityData={opportunityData}
      updateOpportunityComments={updateOpportunityComments}
      caseObj={caseObj}
      component={OpportunityCatalog}     
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId"
      user={user}
      match={match}
      wellData={wellData}
      structureZMatrix={structureZMatrix}
      structureGridX={structureGridX}
      structureGridY={structureGridY}
      zMatrix={zMatrix}
      gridX={gridX}
      gridY={gridY}
      faultData={faultData}
      opportunityData={opportunityData}
      gridData={gridData}
      updateOpportunity={updateOpportunity}
      updateOpportunityComments={updateOpportunityComments}
      gridProperty={gridProperty}
      updateGridProperty={updateGridProperty}
      gridPopertyOptions={gridPopertyOptions}
      parentZone={zone}
      updateZone={updateZone}
      datasetId={datasetId}
      realDatasetId={realDatasetId}
      caseObj={caseObj}
      component={Opportunity}
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/wells"
      user={user}
      wellData={wellData} 
      component={WellCatalog}        
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/settings"
      user={user}
      component={Settings}        
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/download"
      user={user}
      caseObj={caseObj}
      component={Download}        
    />
  </React.Fragment>
)

const mapStateToProps = state => ({
  user: state.get('user'),
})

export default withRouter(connect(mapStateToProps)(VisualizationRoutes))