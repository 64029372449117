import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'

const TableKPI = ({ header, value, tooltip, className }) => (
  <div className={`table-KPI ${className}`}>
    <div className="val">
      { value + " "}
      { tooltip ? (
        <Tooltip title={tooltip} aria-label={tooltip}>
          <HelpIcon />
        </Tooltip>
      ) : null }
    </div>
    <div className="text">
      { header }
      
    </div>
  </div>
)

export default TableKPI
