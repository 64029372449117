
export const dealWithNaN = (input) => {
  if (Number.isNaN(input) || input === Infinity || typeof input !== 'number' || input === undefined) {
    /*
      Since .toFixed() is always called after this function
      I'm just going to return a dummy method that returns '-'
      So the code is cleaner.
    */
    return {
      toFixed: (num) => '-'
    }
  } else {
    return input
  }
}


const s3PathConverter = (mainKey) => {
  if (mainKey === 'grids') { return '/grid_data' }
  if (mainKey === 'logs') { return '/log_data' }
  if (mainKey === 'faults') { return '/fault_data' }
  if (mainKey === 'model') { return '/model_data' }
  if (mainKey === 'Well_Trajectories') { return '/traj_data' }
  if (mainKey === 'Well_Master') { return '/Well_Master' }
  if (mainKey === 'Well_Groups') { return '/Well_Groups' }
  if (mainKey === 'Well_Production_Monthly') { return '/Well_Production_Monthly' }
  if (mainKey === 'Well_Injection_Monthly') { return '/Well_Injection_Monthly' }
  if (mainKey === 'Fluid_Pvt') { return '/Fluid_Pvt' }
  if (mainKey === 'Well_Pressure_Test_Static') { return '/Well_Pressure_Test_Static' }
  if (mainKey === 'Well_Production_Log') { return '/Well_Production_Log' }
  if (mainKey === 'Well_Injection_Log') { return '/Well_Injection_Log' }
  if (mainKey === 'Well_Perforations') { return '/Well_Perforations' }
  if (mainKey === 'Well_Production_Monthly_Allocated') { return '/Well_Production_Monthly_Allocated' }
  if (mainKey === 'Wellbore_Diagrams') { return '/Wellbore_Diagrams' }
  if (mainKey === 'Well_Events') { return '/Well_Events' }
  if (mainKey === 'Intervals') { return '/Intervals' }
  if (mainKey === 'Well_Tops') { return '/Well_Tops' }
  if (mainKey === 'Well_Interval_Properties') { return '/Well_Interval_Properties' }
  if (mainKey === 'Reservoir_Pressure_Static') { return '/Reservoir_Pressure_Static' }
    
  return ''
}

const PREFIX_KEY = (dataset, mainKey, subKey) => (
  `datasets/${dataset}/raw${s3PathConverter(mainKey)}${subKey === undefined ? '' : `/${subKey}`}`
)

export const POST_KEY = (dataset, mainKey, subKey, fileName) => (
  `${PREFIX_KEY(dataset, mainKey, subKey)}/${fileName}`
)

/**
 * Returns an array of values of size `cardinality` with a
 * min and max at `start` `end` respectively (inclusively).
 */
export const linSpace = (start, end, cardinality) => {
  const arr = []
  const step = (end - start) / (cardinality - 1)
  for (let i = 0; i < cardinality; i += 1) {
    arr.push(start + (step * i))
  }
  return arr
}

export const range = (start, stop, step) => {
  const result = []

  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i)
  }
  return result
}

export const downsampleGrid = (gridX, gridY, zMatrix, factor) => {
  const smallGridX = []
  const smallGridY = []
  const smallZMatrix = []

  for (let i = 0; i < gridX.length; i++) {
    if (i % factor === 0) {
      smallGridX.push(gridX[i])
    }
  }

  for (let i = 0; i < gridY.length; i++) {
    if (i % factor === 0) {
      smallGridY.push(gridY[i])

      const innerArray = zMatrix[i]
      const newInnerArray = []
      for (let j = 0; j < innerArray.length; j++) {
        if (j % factor === 0) {
          newInnerArray.push(innerArray[j])
        }
      }
      smallZMatrix.push(newInnerArray)
    }
  }

  return { smallGridX, smallGridY, smallZMatrix }
}

export default function isEquivalent(a, b) {
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  if (aKeys.length !== bKeys.length) {
    return false
  }
  for (let i = 0; i < aKeys.length; i += 1) {
    const key = aKeys[i]
    if (a[key] !== b[key]) {
      return false
    }
  }

  return true
}

export async function setDataFromS3URL(url, object, key, caseId){ //caseId for case access middleware -wy 9/20
  object[key] = await fetch(`${process.env.API_ADDRESS}/file/${caseId}/getS3FileStream?url=${url}`).then(r => r.json())
  return 'OK'
}

export function fileExtFromPath(path) {
  return path.replace(/^.*[\\.]/, '')
}

export function fileNameFromPath(path) {
  return path.replace(/^.*[\\\/]/, '')
}
