import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import datasetsIntroA from '../../../../../../../static/images/userManual/datasetsIntroA.png'
import datasetsIntroB from '../../../../../../../static/images/userManual/datasetsIntroB.png'

@autobind class Intro extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Introduction to Datasets
        </div>
        <div className="content-row"> 
          A dataset is defined as a collection of different categories of static information, from production histories to geologic models, that can be used for a SpeedWise Reservoir module (e.g., recompletions, vertical new drills, horizontal targets) to generate an opportunity inventory list. workspaces can have multiple datasets, and each dataset can be modified or updated to create newer versions.
        </div>
        <div className="content-row"> 
          If you click on “Datasets” when you are within a workspace, you will see a list of existing datasets you have created, datasets others have shared with you, and datasets you have designated as our favorites. You will also see the options you have to create or edit datasets.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={datasetsIntroA} alt="" />
        </div>
        <div className="content-row-no-padding"> 
          &ensp;• <span className="blue">Basic Information of a Dataset:</span>Title, Author, Date Created, Description, Versions
        </div>
        <div className="content-row"> 
          &ensp;• <span className="blue">Dataset Versions:</span> Clicking on a dataset will directly open its latest version, however if you click the eye icon of a dataset, a dialog will pop-up on the right side of the page which shows all its versions:
        </div>
        <div className='image-container'>
          <img className="tooBig" src={datasetsIntroB} alt="" />
        </div>
        <div className="content-row-no-padding"> 
          &ensp;&ensp;◦ <span className="blue">Published Version:</span> Can be used in a module because they have been completely uploaded and the data has been validated.
        </div>
        <div className="content-row"> 
          &ensp;&ensp;◦ <span className="blue">Unpublished Version:</span> Shows all edits that have been done to a dataset, including the intermediate steps between published versions (e.g., if production data is deleted, before updated production data is added).
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Intro)
