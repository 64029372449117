import React, { Component } from 'react'
import autobind from 'autobind-decorator'

@autobind class CaseEditModalHeader extends Component {
  render() {
    const { handleClickEditModalTab, tab } = this.props

    return (
      <div className="tab-header">
        <div
          className={`tab ${tab === 'details' ? 'selected' : ''}`}
          onClick={() => handleClickEditModalTab('details')}
          onKeyPress={null}
          role="button"
          tabIndex="0"
        >
          Case Details
        </div>
        <div
          className={`tab ${tab === 'history' ? 'selected' : ''}`}
          onClick={() => handleClickEditModalTab('history')}
          onKeyPress={null}
          role="button"
          tabIndex="0"
        >
          Case History
        </div>
      </div>
    )
  }
}

export default CaseEditModalHeader
