import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ReactTooltip from 'react-tooltip'
import Chip from '@material-ui/core/Chip'
import { FileCopy, Edit, Favorite, History, Share } from '@material-ui/icons'
import VisibilityIcon from '@material-ui/icons/Visibility'

import IconButton from '@material-ui/core/IconButton'

import { stringFormatDate } from '../../../../services/dateFormatterService'
import Avatar from '../../../common/Avatar/Avatar'
import '../../../common/Card/QRICard'

const containerClass = 'qri-card'
const pictureClass = `${containerClass}__picture-container`

const profileClass = `${containerClass}__name-container`
const itemNameClass = `${profileClass}__item-name`
const ownerNameClass = `${profileClass}__owner-name`

const descriptionClass = `${containerClass}__description-container`
const descriptionClassWrapper = `${descriptionClass}__content-wrapper`
const dateClass = `${descriptionClass}__date`
const descriptionTextClass = `${descriptionClass}__text`

const otherInformationClass = `${containerClass}__other-container`

const chipClass = `${containerClass}__chip`
const actionsClass = `${containerClass}__actions-container`
const favoriteClass = `${actionsClass}__favorite`
const favoriteSelectedClass = `${favoriteClass}--selected`
const shareClass = `${actionsClass}__share`
const shareSelectedClass = `${shareClass}--selected`

const iconButtonClass= 'card-icon-btn'
const verticalAlignClass = 'align-vertical'

@autobind class DatasetCard extends Component {
  onItemClick(e) {
    const { onItemClick } = this.props
    e.stopPropagation()
    onItemClick()
  }

  onViewClick(e) {
    const { onViewClick, dataset } = this.props
    e.stopPropagation()
    onViewClick(dataset._id)
  }

  onFavoriteClick(e) {
    const { onFavoriteToggle } = this.props
    e.stopPropagation()
    onFavoriteToggle()
  }

  onShareClick(e) {
    const { onShareToggle } = this.props
    e.stopPropagation()
    onShareToggle()
  }

  onEditClick(e) {
    const { onEditClick } = this.props
    e.stopPropagation()
    onEditClick()
  }

  onCopyClick(e) {
    const { onCopyClick } = this.props
    e.stopPropagation()
    onCopyClick()
  }

  onHistoryClick(e) {
    const { onHistoryClick } = this.props
    e.stopPropagation()
    onHistoryClick()
  }

  render() {
    const {
      index,
      dataset,
      onFavoriteToggle,
      isShared,
      onShareToggle,
      onEditClick,
      onCopyClick,
      onHistoryClick,
      selectedTab,
    } = this.props

    const showFavoriteButton = dataset.isFavorite !== undefined && onFavoriteToggle !== undefined
    const showShareButton = dataset.shared !== undefined && onShareToggle !== undefined && selectedTab.name !== 'shareddatasets'
    const showEditButton = onEditClick !== undefined && selectedTab.name !== 'shareddatasets'
    const showCopyButton = onCopyClick !== undefined
    const showHistoryButton = onHistoryClick !== undefined

    const versionCount = dataset.versions ? dataset.versions.length + 1 : 0
    const createdBy = dataset.createdBy ? `${dataset.createdBy.firstName} ${dataset.createdBy.lastName}` : 'Missing'
    const formattedDate = stringFormatDate(dataset.dateCreated)

    return (
      <div
        className={containerClass + ' button'}
        key={dataset.itemId}
        onKeyPress={null}
        onClick={this.onViewClick}
        role="button"
        tabIndex={index}
      >
        <div className={`${pictureClass} ${verticalAlignClass}`}>
          <Avatar fullName={createdBy} />
        </div>
        <div className={`${profileClass} ${verticalAlignClass}`}>
          <div>
            <div className={itemNameClass}>{dataset.name}</div>
            <div className={ownerNameClass}>
              By {createdBy}
            </div>
          </div>
        </div>

        <div className={`${descriptionClass} ${verticalAlignClass}`}>
          <div className={descriptionClassWrapper}>
            <div className={dateClass}>{formattedDate}</div>
            <div className={descriptionTextClass}>{dataset.description}</div>
          </div>
        </div>

        <div className={`${otherInformationClass} ${verticalAlignClass}`}>
          <Chip className={chipClass} color="primary" label={`${versionCount} Versions`} />
        </div>

        <div className={`${actionsClass} ${verticalAlignClass}`}>

          {showEditButton && (
              <IconButton className={iconButtonClass} data-tip="Edit" end="start" aria-label="edit">
                <Edit
                  onClick={this.onEditClick}
                />
              </IconButton>
          )}

          <IconButton className={iconButtonClass} data-tip="View Version History" end="start" aria-label="edit">
            <VisibilityIcon
              onClick={this.onItemClick}
            />
          </IconButton>

          {showHistoryButton && (
              <IconButton className={iconButtonClass} data-tip="History" end="start" aria-label="history">
                <History 
                  onClick={this.onHistoryClick}
                />
              </IconButton>
          )}

          {showCopyButton && (
              <IconButton className={iconButtonClass} data-tip="File Copy" end="start" aria-label="copy">
                <FileCopy 
                  onClick={this.onCopyClick}
                />
              </IconButton>
          )}

          {showFavoriteButton && (
              <IconButton className={`${iconButtonClass} ${dataset.isFavorite ? favoriteSelectedClass : ''}`} data-tip={dataset.isFavorite ? "Remove Favorite" : "Add Favorite"} end="start" aria-label="favorite">
                <Favorite
                  onClick={this.onFavoriteClick}
                />
              </IconButton>
          )}
          {showShareButton && (
            <IconButton className={`${iconButtonClass} ${dataset.shared ? shareSelectedClass : ''}`} data-tip={dataset.shared ? "Unshare" : "Share"} end="start" aria-label="share">
                <Share
                  onClick={this.onShareClick}
                />
            </IconButton>
          )}
          <ReactTooltip effect="solid" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userCases: state.getIn(['user']),
})

export default withRouter(connect(mapStateToProps, null)(DatasetCard))