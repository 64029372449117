import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import Select from 'react-select'
import Modal from 'react-responsive-modal'
import _ from 'underscore'

import API from '../../../../lib/api-store'
import { getDatasetsForThisUser, getSharedDatasets } from '../../../../services/datasetService'
import ModuleSelection from './ModuleSelection'
import DatasetSelection from './DatasetSelection'
import CaseSelection from './CaseSelection'

const modalClass = 'qri-modal'
const modalCloseIconClass = 'qri-modal__close-button'


const moduleMap = {
  generateWorkovers: 'RECOMPLETIONS',
  verticals: 'NEW_DRILLS', 
  horizontals: 'HORIZONTALS',
  modelBuilder: 'MODEL_BUILDER',
  unconventionals: 'UNCONVENTIONALS',
}

@autobind class Initialize extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datasetOptions: [],
      caseOptions: [],
      isModalOpen: false,
      loadingDatasets: true,
      loadingCases: true,
      allDatasets: null,
    }
  }

  componentDidMount() {
    const { caseObj, setCanContinue, setStep, match } = this.props
    const {caseId, projectId, application } = match.params

    setStep(0, `/${application}/projects/${projectId}/cases`, `/${application}/projects/${projectId}/cases/${caseId}/modify`)  

    
    if (caseObj.dataKey && caseObj.module) {
      setCanContinue(true)
    }

    this.loadDatasets()
    if (application === 'SWM') {
      this.loadCases()  
    }
    
  }

  componentDidUpdate(prevProps) {
    const { caseObj, setCanContinue, setStep, match } = this.props
    const { projectId, caseId, application } = match.params
    const { allDatasets, loadingDatasets } = this.state

    if (caseObj.dataKey !== prevProps.caseObj.dataKey || caseObj.module !== prevProps.caseObj.module) {
      if (caseObj.dataKey && caseObj.module) {
        setCanContinue(true)
      }
    }

    if ((allDatasets && loadingDatasets) || (allDatasets && (caseObj.module !== prevProps.caseObj.module))) {
      if (caseObj.module) {
        if (caseObj.module === 'swmOptimization') {
          console.log('filter cases')
        } else {
          this.filterDataset(allDatasets, caseObj.module)
        }
      }
    }
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      value: null
    })
  }


   filterDataset(allDatasets, moduleVal) {
      const { userId } = this.props
      const datasetOptions = []

      allDatasets.forEach(dataset => {
        const versions = []



        dataset.versions.filter(i => i.enabledModules[moduleMap[moduleVal]] && i.enabledModules[moduleMap[moduleVal]].valid).forEach(i => { 
          const versionName = i.publishedName ? `${i.publishedName} (${i.version})` : `Version ${i.version}`
          const fileCount = i.files.length

          versions.push({
            name: versionName,
            versionId: i.versionId,
            datasetId: dataset._id,
            fileCount
          })
        })

        if (dataset.latestVersion.enabledModules[moduleMap[moduleVal]] && dataset.latestVersion.enabledModules[moduleMap[moduleVal]].valid) {
          const latestVersion = dataset.latestVersion
          const versionName = latestVersion.publishedName ? `${latestVersion.publishedName} (${latestVersion.version})` : `Version ${latestVersion.version}`
          const fileCount = latestVersion.files.length

          versions.push({
            name: versionName,
            versionId: latestVersion.versionId,
            datasetId: dataset._id,
            fileCount
          })
        }
        
        if (versions.length > 0) {
          datasetOptions.push({
            name: dataset.name,
            versions,
            isUserFavorite: dataset.favoritedBy.includes(userId)
          })
        }
      })

      console.log("YEYEYEYEYEYE", datasetOptions)
      this.setState({
        datasetOptions,
        loadingDatasets: false
      })
    }


  loadDatasets() {
    let { match } = this.props
    let { projectId } = match.params


    const userPromise = getDatasetsForThisUser(projectId).catch((err) => {
      console.log('there was an error getting user datasets')
      console.log(err)
    })

    const sharedPromise = getSharedDatasets(projectId).catch((err) => {
      console.log('there was an error getting public datasets')
      console.log(err)
    })

    const allPromises = [
      userPromise,
      sharedPromise,
    ]

    Promise.all(allPromises).then((results) => {
      const userDatasets = results[0]
      const sharedDatasets = results[1]

      const allDatasets = userDatasets

      sharedDatasets.forEach(dataset => {
        if (!userDatasets.map(i => i._id).includes(dataset._id)) {
          allDatasets.push(dataset)
        }
      })

      this.setState({
        allDatasets
      })
    })
  }


  loadCases() {
    let { match } = this.props
    let { projectId } = match.params


    const cases = API.getCasesForOptimization(projectId)
      .then(cases => {
        // TODO - handle success/err
        this.setState({
          caseOptions: cases,
          loadingCases: false
        })
      })
      .catch(err => console.error(err))

  }



  handleChangeWarning(value, type) {
    this.setState({
      isModalOpen: true,
      tempVal: value,
      type,
    })
  }


  handleCaseChange(value) {
    const { caseObj, editCaseObj, setCanContinue } = this.props
    const { caseId, module } = caseObj

    this.setState({ 
      isModalOpen: false
    })

    API.updateCaseCase(caseId, value)
      .then(caseObj => {
          // TODO - handle success/err
          editCaseObj(caseObj)
          if (module) {
            setCanContinue(true)
          }
      })
      .catch(err => console.error(err))
  }


  attemptDatasetChange(value) {
    
    const { caseObj } = this.props

    if (value === caseObj.dataKey) {
      return
    } else if (caseObj.stepNo) {
      this.handleChangeWarning(value, 'dataset')
    } else {
      this.handleDatasetChange(value)
    }
  }


  handleDatasetChange(value) {
    const { caseObj, editCaseObj, setCanContinue } = this.props
    const { caseId, module } = caseObj

    this.setState({ 
      isModalOpen: false
    })

    API.updateCaseDataset(caseId, value)
      .then(caseObj => {
          // TODO - handle success/err
          editCaseObj(caseObj)
          if (module) {
            setCanContinue(true)
          }
      })
      .catch(err => console.error(err))
  }



  attemptModuleChange(value) {
    const { caseObj } = this.props

    if (value === caseObj.module) {
      return
    } else {
      this.handleChangeWarning(value, 'module')
    }
  }


  handleModuleChange(value) {
    const { caseObj, editCaseObj, setCanContinue } = this.props
    const { caseId, dataKey } = caseObj
    // const {caseId, projectId, application } = match.params

    this.setState({ 
      isModalOpen: false
    })

    API.updateCaseModule(caseId, value)
      .then(caseObj => {
          editCaseObj(caseObj)
          // TODO - handle success/err
          if (dataKey) {
            setCanContinue(true)
          }
      })
      .catch(err => console.error(err))
  }

  makeSelection() {
    const { datasetOptions, caseOptions, loadingDatasets, loadingCases } = this.state
    const { caseObj, match } = this.props
    const { dataKey, module, refCaseId } = caseObj

    if (caseObj.module === 'swmOptimization') {
      return (
        <CaseSelection
          selectedModule={module}
          caseOptions={caseOptions}
          selectedCase={refCaseId}
          handleCaseChange={this.handleCaseChange}
          loadingCases={loadingCases}
        />
      )
    }

    return (
      <DatasetSelection
        selectedModule={module}
        datasetOptions={datasetOptions}
        selectedDatasetAndVersion={dataKey}
        attemptDatasetChange={this.attemptDatasetChange}
        loadingDatasets={loadingDatasets}
      />
    )
  }

  render() {
    const { datasetOptions, tempVal, isModalOpen, type, loadingDatasets } = this.state
    const { caseObj, match } = this.props
    const { dataKey, module } = caseObj
    const { projectId } = match.params

    if (caseObj.stepStatus === 'running' || caseObj.stepStatus === 'queued') {
      return (
        <div className="initialize">
          <div className="initialize-header"> Module and Dataset selection are disabled during execution </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="initialize">
          <div className="initialize-header"> Select a Module </div>
          <div className="initialize-sub-header"> Each module has different settings and datsets. </div>
          <ModuleSelection
            selectedModule={module}
            attemptModuleChange={this.attemptModuleChange}
          />
          { this.makeSelection() }
        </div>
        <Modal
          classNames={{ modal: modalClass, closeIcon: modalCloseIconClass }}
          open={isModalOpen}
          center
          onClose={this.closeModal}
        >
          <div>
            <div style={{
              'height': '76px',
              'width': '400px',
              'font-size': '21px',
              'border-top': 'solid 10px #FFCA83',
              'padding-top': '23px',
              'padding-left': '25px',
            }}>
              Warning
            </div>
            <div style={{
              'font-size': '15px',
              'text-align': 'center',
              'width': '400px',
              'padding-left': '20px',
              'padding-right': '20px',
            }}>
              {type === 'module' && (<span>Changing the module will result in having to configure new settings and run each step again.</span>)}
              {type === 'dataset' && (<span>Changing a dataset will result in you having to run each step again.  The settings will not be affected.</span>)}
            </div>
            <button
              style={{
                'font-size': '16px',
                'float': 'right',
                'margin-right': '25px',
                'margin-top': '10px',
                'background': '#0076C0',
                'color': 'white',
                'padding': '5px 15px 5px 15px',
                'margin-bottom': '20px',
              }}
              onClick={type === 'dataset' ? () => this.handleDatasetChange(tempVal) : () => this.handleModuleChange(tempVal)}
            >
              Got It
            </button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default withRouter(connect(mapStateToProps, null)(Initialize))
