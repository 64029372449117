import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import workspaceOperations from '../../../../../../../static/images/userManual/workspaceOperations.png';
import workspaceCreateA from '../../../../../../../static/images/userManual/workspaceCreateA.png';
import workspaceCreateB from '../../../../../../../static/images/userManual/workspaceCreateB.png';

@autobind class Create extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Create or Edit a Workspace
        </div>
        <div className='image-container'>
          <img className="image" src={workspaceOperations} alt="" />
        </div>
        <div className="content-row"> 
          <span className='blue'>Create Workspace:</span> Creating a new workspace is as simple as clicking the plus icon on the workspaces page, filling out the basic information for your new workspace, and then clicking “Create workspace”
        </div>
        <div className='image-container'>
          <img className="image" src={workspaceCreateA} alt="" />
        </div>
        <div className="content-row"> 
          <span className='blue'>Edit Your Workspace Basic Information:</span> You can view and edit the basic information for a workspace you have created by clicking the gear icon on the workspace, and you can also edit the collaborators on the workspace.
        </div>
        <div className="content-row"> 
          <span className='blue'>View Shared Workspace Basic Information:</span> For workspaces that have been shared with you, you can only view the basic information and collaborators by clicking on the gear icon of those workspaces.
        </div>
        <div className='image-container'>
          <img className="logo" src={workspaceCreateB} alt="" />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Create)
