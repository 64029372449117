import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import autobind from 'autobind-decorator'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  pinCaseOpportunity,
  unpinCaseOpportunity,
} from '../../../../redux/actions/user'

@autobind class PinOpportunity extends PureComponent {
  handleClick() {
    const {
      opportunityId,
      pinCaseOpportunityAction,
      unpinCaseOpportunityAction,
      caseId
    } = this.props

    if (!this.isPinned()) {
      pinCaseOpportunityAction(opportunityId, caseId)
    } else {
      unpinCaseOpportunityAction(opportunityId, caseId)
    }
  }

  isPinned() {
    const { pinned, opportunityId } = this.props
    return pinned && pinned.find(p => p.get('_id') === opportunityId) !== undefined
  }

  render() {
    const isPinnned = this.isPinned()
    const activeClass = isPinnned ? 'secondary' : 'primary'
    const tooltipText = isPinnned ? 'Unpin from Navigation' : 'Pin to Navigation'
    return (
      <Tooltip title={tooltipText} placement="right">
        <Fab size="small" className="pin" aria-label="pin" color={activeClass} onClick={this.handleClick}>
          <i className="fa fa-thumb-tack" />
        </Fab>
      </Tooltip>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  pinCaseOpportunityAction: (opportunityId, caseId) => dispatch(pinCaseOpportunity(opportunityId, caseId)),
  unpinCaseOpportunityAction: (opportunityId, caseId) => dispatch(unpinCaseOpportunity(opportunityId, caseId)),
})

const mapStateToProps = state => ({
  pinned: state.getIn(['user', 'pinnedOpportunities']),
})

export default connect(mapStateToProps, mapDispatchToProps)(PinOpportunity)
