import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import { ClipLoader } from 'react-spinners'
import Select from 'react-select'
import Card from '../common/Card'
import WellLocation from '../components/WellLocation'
import IPHistogram from '../components/IPHistogram'
import SummaryIPGain from '../components/SummaryIPGain'
import SummaryZonePie from '../components/SummaryZonePie'
import SummaryKPIs from '../components/SummaryKPIs'
import './Executive.scss'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class Executive extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shouldRenderMap: false,
      shouldRenderIPGain: false,
      shouldRenderOpportunities: false,
      shouldRenderIPDist: false,
      shouldRenderIPPie: false,
      //refreshWellLocation: false,
      curHoverWell: null,
      clickedOffTraces: [],
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    setTimeout(() => {
      this.setState({
        shouldRenderOpportunities: true,
      })  
    }, 100)

    setTimeout(() => {
      this.setState({
        shouldRenderIPDist: true,
      })  
    }, 150)

    setTimeout(() => {
      this.setState({
        shouldRenderIPPie: true,
      })  
    }, 200)


    setTimeout(() => {
      this.setState({
        shouldRenderIPGain: true,
      })  
    }, 250)

    setTimeout(() => {
      this.setState({
        shouldRenderMap: true,
      })  
    }, 300)      
  }

  setClickedOffTraces(val) {
    this.setState({
      clickedOffTraces: val,
      //refreshWellLocation: true
    })
  }

  setCurHoverWell(val) {
    this.setState({
      curHoverWell: val
    })
  }

  renderZoneToggle() {
    const { zone, updateZone, populatedZoneOptions } = this.props

    return (
      <div className="zone-toggle">
        <Select
          options={populatedZoneOptions}
          onChange={(val) => updateZone(val.value)}
          isMulti={false}
          value={populatedZoneOptions.find(i => i.value === zone)}
        />
      </div>
    )
  }

  renderOpportunities() {
    const { opportunityData, zone } = this.props
    if (opportunityData && this.state.shouldRenderOpportunities) {
      const oppData = opportunityData.reduce((acc, val) => {
        if (!zone) {
          return [...acc, val]
        }

        if (val.Opportunities && val.Opportunities.filter(v => v.isFinalTarget == true).find(v => v.zoneId === zone)) {
          return [...acc, val]
        }

        if (val.zone === zone) {
          return [...acc, val]
        }
        return acc
      }, [])
      return <SummaryKPIs data={oppData} />
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderIpDist() {
    const { opportunityData, zone } = this.props
    if (opportunityData && this.state.shouldRenderIPDist) {
      const oppData = opportunityData.reduce((acc, val) => {
        const vals = val.Opportunities ? val.Opportunities.filter(v => v.isFinalTarget) : [val]
        return [...acc, ...vals]
      }, [])
      const data = zone ? oppData.filter(i => i.zone === zone || i.zoneId === zone) : oppData
      return <IPHistogram data={data} />
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderIPPie() {
    const {
      opportunityData,
      colorWheel,
      zone
    } = this.props

    if (opportunityData && this.state.shouldRenderIPPie) {
      const oppData = opportunityData.reduce((acc, val) => {
        const vals = val.Opportunities ? val.Opportunities.filter(v => v.isFinalTarget) : [val]
        return [...acc, ...vals]
      }, [])
      const data = zone ? oppData.filter(i => i.zone === zone || i.zoneId === zone) : oppData
      return (
        <SummaryZonePie
          data={data}
          colorWheel={colorWheel}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderIPGain() {
    const {
      opportunityData,
      colorWheel,
      zone,
    } = this.props

    const { curHoverWell } = this.state

    if (opportunityData && this.state.shouldRenderIPGain) {
      const oppData = opportunityData.reduce((acc, val) => {
        let vals = val.Opportunities ? val.Opportunities.filter(v => v.isFinalTarget) : [val]
        if (val.Opportunities) {
          vals = vals.map(x => ({ ...x, _id: val._id }))
        }
        return [...acc, ...vals]
      }, [])
      const data = zone ? oppData.filter(i => i.zone === zone || i.zoneId === zone) : oppData
      return (
        <SummaryIPGain
          data={data}
          curHoverWell={curHoverWell}
          colorWheel={colorWheel}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderMap() {
    const {
      wellData,
      structureZMatrix,
      structureGridX,
      structureGridY,
      zMatrix,
      gridX,
      gridY,
      faultData,
      opportunityData,
      gridProperty,
      updateGridProperty,
      gridPopertyOptions,
      zone,
    } = this.props

    const { clickedOffTraces } = this.state

    if (wellData && gridX && structureGridX && faultData && opportunityData && this.state.shouldRenderMap) {
      const oppData = opportunityData.reduce((acc, val) => {
        let vals = val.Opportunities ? val.Opportunities.filter(v => v.isFinalTarget) : [val]
        if (val.Opportunities) {
          vals = vals.map(x => ({ ...x, _id: val._id }))
        }
        return [...acc, ...vals]
      }, [])
      const data = zone ? oppData.filter(i => i.zone === zone || i.zoneId === zone) : oppData
      return (
        <React.Fragment>
          <WellLocation
            wellData={wellData}
            zMatrixHeat={zMatrix}
            gridXHeat={gridX}
            gridYHeat={gridY}
            heatTitle="Structure"
            yMargin={75}
            zMatrixContour={structureZMatrix}
            gridXContour={structureGridX}
            gridYContour={structureGridY}
            faultData={faultData}
            opportunityData={data}
            clickedOffTraces={clickedOffTraces}
            setClickedOffTraces={this.setClickedOffTraces}
            gridProperty={gridProperty}
            setCurHoverWell={this.setCurHoverWell}
          />
          <div style={{'position': 'relative', top: '-45px', width: '300px', margin: 'auto'}}>
            <Select
              options={gridPopertyOptions}
              onChange={(val) => updateGridProperty(val.value)}
              isMulti={false}
              value={gridPopertyOptions.find(i => i.value === gridProperty)}
            />
          </div>
        </React.Fragment>
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  render() {
    console.log('render executive')
    let { curHoverWell } = this.state

    return (
      <div className="executive">
       
        <div className="top">
          <div className="left">
            <Card title="Identified Opportunities">
              { this.renderMap() }
            </Card>
          </div>
          <div className="right">
            <div className="r-top">
              <Card overflowY="visible" title="Zone Filter">
                { this.renderZoneToggle() }
              </Card>
            </div>
            <div className="r-mid">
              <Card title="Opportunities Identified">
                { this.renderOpportunities() }
              </Card>
            </div>
            <div className="r-bot">
               <Card title="IP Distribution">
                { this.renderIpDist() }
              </Card>
            </div>
          </div>
        </div>
        <div className="bot">
          <div className="left">
            <Card title="Total IP Gain per Well" overflowY={true}>
              { this.renderIPGain() }
            </Card>
          </div>
          <div className="right">
            <Card title="Identified IP per Zone">
              { this.renderIPPie() }
            </Card>
          </div>
        </div>

      </div>
    )
  }
}

export default Executive
