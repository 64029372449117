import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import { Numeric, Integer, Numeric2 } from '../../../../lib/formatters'
import { dealWithNaN } from '../../../../lib/helpers'

const columns = [{
  Header: 'Well ID',
  accessor: 'id',
  minWidth: 80,
}, {
  Header: 'Start Time',
  accessor: 'time',
  minWidth: 80,
}, {
  Header: 'Distance',
  accessor: 'distance',
  minWidth: 80,
}, {
  Header: <div>Perf Length <br/> (m) </div>,
  accessor: 'perfLength',
  minWidth: 80,
  Cell: Numeric,
}, {
  Header: <div>PNP<br/> (m) </div>,
  accessor: 'pnp',
  minWidth: 80,
  Cell: Numeric,
}, {
  Header: <div>hcpt<br/> (m) </div>,
  accessor: 'hcpt',
  minWidth: 80,
  Cell: Numeric2,
}, {
  Header: <div>Perm<br/> </div>,
  accessor: 'pnpPerm',
  minWidth: 80,
  Cell: Numeric,
}, {
  Header: <div>Initial Pressure<br/> </div>,
  accessor: 'pnpInitialPressure',
  minWidth: 80,
  Cell: Numeric2,
}, {
  Header: <div>IP<br/> (BBL/D) </div>,
  accessor: 'ip',
  minWidth: 80,
  Cell: Numeric,
}]

@autobind class OpportunityNeighborTable extends PureComponent {
  render() {
    const { data } = this.props

    //Floats numbers, shows 'null' if null.
    // const format = (num, float) => typeof num === 'number' ? num.toFixed(float) : 'null'

    // TODO - make a real date string
    const plotData = data.map(i => ({
      id: i.id,
      time: i.time ? new Date(i.time.date).toDateString() : '',
      distance: dealWithNaN(i.distance).toFixed(1),
      perfLength: dealWithNaN(i.perfLength),
      pnp: dealWithNaN(i.pnp),
      hcpt: dealWithNaN(i.hcpt),
      pnpPerm: dealWithNaN(i.pnpPerm),
      pnpInitialPressure: dealWithNaN(i.pnpInitialPressure),
      ip: dealWithNaN(i.targetIP),
    }))

    return (
      <div className="table">
        <ReactTable
          className="-striped"
          columns={columns}
          showPagination={false}
          data={plotData}
          pageSize={data.length}
        />
      </div>
    )
  }
}

export default OpportunityNeighborTable
