import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'

@autobind class SummaryZonePie extends PureComponent {
  render() {
    const { data, colorWheel } = this.props
    const zones = []
    const wells = []

    data.forEach((i) => {
      const zone = i.zone || i.zoneId
      if (!zones.includes(zone)) {
        zones.push(zone)
      }
    })

    let obj = {}

    if (zones.length > 1) {
      obj = {
        values: zones.map(i => data.filter(j => j.zone === i || j.zoneId === i).reduce((sum, cur) => sum + cur.predictedIp, 0)),
        labels: zones,
        type: 'pie',
      }

      if (colorWheel) {
        obj.marker = {
          colors: zones.map(i => colorWheel[i])
        }
      }    
    } else {
      data.forEach((i) => {
        const name = i.name || i.wellId
        if (!wells.includes(name)) {
          wells.push(name)
        }
      })

      obj = {
        values: wells.map(i => data.filter(j => j.name === i || j.wellId === i).reduce((sum, cur) => sum + cur.predictedIp, 0)),
        labels: wells,
        type: 'pie',
      }

      if (colorWheel) {
        obj.marker = {
          colors: data.map(i => colorWheel[i.zone])
        }
      }
    }

    const plotData = [obj]
    const layout = {
      title: '',
      autosize: true,
      margin: {
        l: 10,
        r: 10,
        b: 10,
        t: 10,
        pad: 0,
      },
    }

    return (
      <Plot
        className="plot"
        data={plotData}
        layout={layout}
        config={{displaylogo: false, showSendToCloud: true, displayModeBar: false}}
        useResizeHandler
      />
    )
  }
}

export default SummaryZonePie
