import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'
import { range } from '../../../../lib/helpers'
import { linSpace } from '../../../../lib/helpers'

const offset = 50

const trajectoryColor = 'black'
const perfOpenColor = 'green'
const perfClosedColor = 'red'

const trajectoryWidth = 1
const mainTrajectoryWidth = 8
const perfWidth = 20

const owcColor = 'blue'
const gocColor = 'red'

const logColor = 'blue'

// Settings.Figures.Horizontals.color
const horizontalColor = 'blue'

const verticalLineColor = 'black'
const verticalMarkerColor = 'blue'


// Similar function to python's bisect
// Return the index of x if x is inserted to an sorted ascend array
function bisect (array, x) {
  if (array.length === 0) {
    return 0;
  }

  for (var i = 0; i < array.length; i++) {
    if (array[i] > x) return i
  }

  return i
}

@autobind class StructuralXSection extends PureComponent {
  render() {
    let { modelSectionData, singleOpportunity, structSectionData, opportunityData, wellData, wellId } = this.props

    let zRatio = 0.5 // TODO: user adjustable in the plot

    let { zones, x, y, z, xLimits, yLimits, color, contactGrids } = structSectionData
    let structralSection = { x, y, z, color }

    let { startDepthMd, stopDepthMd } = opportunityData

    let zoneTextIdx = -1
    let orientation = '[North - South]'

    // Recompletion and vertical has axis
    if (structSectionData.axis) {
      let { axis } = structSectionData

      if (axis == 'y') {
        let zoneTextIdx = 0
        orientation = '[West - East]'
      }
    } // TODO: For horinzontal target, no 'axis'. So orientation is round(opportunity['azimuth']) + '°'
    
    let targetWell = wellId
    let plotData = []
    let annotations = []

    let zLimits = [null, null]

    structralSection['z'].forEach(row => {
      row.forEach(item => {
        if (!zLimits[0] || (item && item < zLimits[0])) {
          zLimits[0] = item
        }
        if (!zLimits[1] || (item && item > zLimits[1])) {
          zLimits[1] = item
        }
      })
    })

    let viewX = xLimits[1] - xLimits[0]
    let viewY = yLimits[1] - yLimits[0]

    let camera = {
      up: {
        x: 0,
        y: 0,
        z: 1,
      },
      center: {
        x: 0,
        y: 0,
        z: 0,
      },
      eye: {
        y: 0.75*viewX / (Math.pow(Math.pow(viewX, 2) + Math.pow(viewY, 2), .5)),
        x: -0.75 * viewY / (Math.pow(Math.pow(viewX, 2) + Math.pow(viewY, 2), .5)),
        z: 0.05,
      }
    }

    let lineWidth = trajectoryWidth
    let lineColor = trajectoryColor
    let textColor = 'black'
    let textOpacity = 0.7

    let perfColor = perfOpenColor
    const hideWells = false

    // Well trajectory and perforation data
    Object.keys(wellData).forEach(name => {
      let well = wellData[name]
      let trajectory = well['trajectory']
      
      if (name === targetWell) {
        lineWidth = mainTrajectoryWidth
        lineColor = logColor
        textColor = logColor
        textOpacity = 1
      } else {
        lineWidth = trajectoryWidth
        lineColor = trajectoryColor
        textColor = 'black'
        textOpacity = 0.7
      }

      // Check well trajectory points. Only add data within the x, y, z limits.
      for (let el of trajectory) {
        if (el['tvdss'] > -zLimits[1] && el['tvdss'] < -zLimits[0]) {
          if (el['x'] > xLimits[0] && el['x'] < xLimits[1]) {
            if (el['y'] > yLimits[0] && el['y'] < yLimits[1]) {
              if (well['trajectory'] && well['trajectory'].length) {

                let wellTrajectory = {
                  x: trajectory.map(i => i.x),
                  y: trajectory.map(i => i.y),
                  z: trajectory.map(i => -1 * i.tvdss),
                  md: trajectory.map(i => i.md),
                }


                if (!hideWells || name === targetWell) {
                  // Add the trajectory
                  plotData.push({
                    type: 'scatter3d',
                    x: wellTrajectory['x'],
                    y: wellTrajectory['y'],
                    z: wellTrajectory['z'],
                    mode: 'lines',
                    line: {
                      color: lineColor,
                      width: lineWidth,
                    },
                    showlegend: false,
                    hoverinfo: 'text',
                    hovertext: name,
                  })
                }

                // Target well's perforation data
                if (well['perforations'] && well['perforations'].length) {
                  let perforationsData = well['perforations']

                  perforationsData.forEach(function (perf) {
                    if (perf['dateClosed']) {
                      perfColor = perfClosedColor
                    } else {
                      perfColor = perfOpenColor
                    }
                    
                    // Use bisect to find the position of the top/base depth in well trajectory's md list
                    let topIdx = bisect(wellTrajectory['md'], perf['top'])
                    let baseIdx = bisect(wellTrajectory['md'], perf['base'])
                    
                    if (topIdx == baseIdx) {
                      let topRatio = (perf['top'] - wellTrajectory['md'][baseIdx-1]) / (wellTrajectory['md'][baseIdx] - wellTrajectory['md'][baseIdx-1])
                      let baseRatio = (perf['base'] - wellTrajectory['md'][baseIdx-1]) / (wellTrajectory['md'][baseIdx] - wellTrajectory['md'][baseIdx-1])

                      if (!hideWells || name === targetWell) {
                        plotData.push({
                          type: 'scatter3d',
                          x: [wellTrajectory['x'][baseIdx-1] + topRatio*(wellTrajectory['x'][baseIdx] - wellTrajectory['x'][baseIdx-1]), wellTrajectory['x'][baseIdx-1] + baseRatio*(wellTrajectory['x'][baseIdx] - wellTrajectory['x'][baseIdx-1])],
                          y: [wellTrajectory['y'][baseIdx-1] + topRatio*(wellTrajectory['y'][baseIdx] - wellTrajectory['y'][baseIdx-1]), wellTrajectory['y'][baseIdx-1] + baseRatio*(wellTrajectory['y'][baseIdx] - wellTrajectory['y'][baseIdx-1])],
                          z: [wellTrajectory['z'][baseIdx-1] + topRatio*(wellTrajectory['z'][baseIdx] - wellTrajectory['z'][baseIdx-1]), wellTrajectory['z'][baseIdx-1] + baseRatio*(wellTrajectory['z'][baseIdx] - wellTrajectory['z'][baseIdx-1])],
                          mode: 'lines',
                          line: {
                            color: perfColor,
                            width: perfWidth ,
                          },
                          hoverinfo: 'text',
                          hovertext: name,
                        })
                      }
                    } else {
                      if (!hideWells || name === targetWell) {
                        plotData.push({
                          type: 'scatter3d',
                          x: wellTrajectory['x'].slice(topIdx-1, baseIdx),
                          y: wellTrajectory['y'].slice(topIdx-1, baseIdx),
                          z: wellTrajectory['z'].slice(topIdx-1, baseIdx),
                          mode: 'lines',
                          line: {
                            color: perfColor,
                            width: perfWidth ,
                          },
                          hoverinfo: 'text',
                          hovertext: name,
                        })
                      }
                    }
                    
                  })
                }
              }

              break;
            }
          }
        }
      }
    })

    if (opportunityData.type === 'verticalDrill') {
      let trajectory

      if (modelSectionData && modelSectionData !== 'missing' && modelSectionData.trajectory && modelSectionData.trajectory.length > 0) {
        trajectory = modelSectionData.trajectory
      } else {
        trajectory = [
          { x: singleOpportunity.x, y: singleOpportunity.y, z: 0 },
          { x: singleOpportunity.x, y: singleOpportunity.y, z: Math.min(...singleOpportunity.targetDepths)},
        ] 
      }
      
      if (trajectory) {
        plotData.push({
          type: 'scatter3d',
          x: trajectory.map(t => t.x),
          y: trajectory.map(t => t.y),
          z: trajectory.map(t => t.z),
          mode: 'lines',
          line: {
            color: logColor,
            width: mainTrajectoryWidth,
          },
        })
      }
    }

    if (opportunityData.type === 'horizontalDrill') {
      plotData.push({
        type: 'scatter3d',
        x: opportunityData.Trajectory.x,
        y: opportunityData.Trajectory.y,
        z: opportunityData.Trajectory.z,
        mode: 'lines',
        line: {
          color: logColor,
          width: mainTrajectoryWidth,
        },
        hoverinfo: 'text',
        hovertext: targetWell,
      })

      plotData.push({
        type: 'scatter3d',
        x: [opportunityData.Trajectory.x[0], opportunityData.Trajectory.x[opportunityData.Trajectory.x.length - 1]],
        y: [opportunityData.Trajectory.y[0], opportunityData.Trajectory.y[opportunityData.Trajectory.y.length - 1]],
        z: [opportunityData.Trajectory.z[0], opportunityData.Trajectory.z[opportunityData.Trajectory.z.length - 1]],
        mode: 'markers',
        marker: {
          size: 3,
          symbol: 'circle',
          color: 'rgba(250,0,0,1)',
        },
        hoverinfo: 'text',
        hovertext: targetWell,
      })
    }

    // Add the x section heatmap data
    const colDim = structralSection['x'][0].length

    const XDataSmall = structralSection['x'].filter((row, idx) => idx % 2).map((col) => {
      return col.filter((c, i) => i % 2 || i === colDim-2)
    })

    const YDataSmall = structralSection['y'].filter((row, idx) => idx % 2).map((col) => {
      return col.filter((c, i) => i % 2 || i === colDim-2)
    })

    const ZDataSmall = structralSection['z'].filter((row, idx) => idx % 2).map((col) => {
      return col.filter((c, i) => i % 2 || i === colDim-2)
    })

    const propertiesSmall = structralSection['color'].filter((row, idx) => idx % 2).map((col) => {
      return col.filter((c, i) => i % 2 || i === colDim-2)
    })
    
    // Add zone x-section data
    plotData.push({
      type: 'surface',
      x: XDataSmall,
      y: YDataSmall,
      z: ZDataSmall,
      surfacecolor: propertiesSmall,
      //colorscale: 'Portland',
      showscale: false,
      opacity: .4,
      hoverinfo: 'none'
    })

    plotData.push({
      type: 'scatter3d',
      x: [0, 1],
      y: [0, 1],
      z: [0, 1],
      mode: 'lines',
      line: {
        color: logColor,
        width: 0,
      },
    })

    // Add zone name text
    zones.forEach(function (zone, idx) {
      let zoneSectionX = structralSection['x'].slice(zoneTextIdx)[0]
      let zoneSectionY = structralSection['y'].slice(zoneTextIdx)[0]
      let zoneSectionZ = structralSection['z'].slice(zoneTextIdx)[0]

      annotations.push({
        showarrow: false,
        x: zoneSectionX.reduce(function(sum, a) { return sum + a }, 0)/(zoneSectionX.length || 1), // Mean of zoneSectionX
        y: zoneSectionY.reduce(function(sum, a) { return sum + a }, 0)/(zoneSectionY.length || 1),
        z: (zoneSectionZ[idx*2] + zoneSectionZ[idx*2+1])/2,
        text: '<b>'.concat(zone, '</b>'),
        xanchor: 'left',
        yanchor: 'middle'
      })
    })

    // Add data for contacts
    contactGrids.forEach(function (zone) {
      plotData.push({
        type: 'scatter3d',
        x: zone['owcXq'],
        y: zone['owcYq'],
        z: zone['owcVq'],
        mode: 'lines',
        line: {
          color: owcColor,
          width: trajectoryWidth,
        },
        showlegend: false,
        hoverinfo: 'text',
        hovertext: 'owc',
      })

      plotData.push({
        type: 'scatter3d',
        x: zone['gocXq'],
        y: zone['gocYq'],
        z: zone['gocVq'],
        mode: 'lines',
        line: {
          color: gocColor,
          width: trajectoryWidth,
        },
        showlegend: false,
        hoverinfo: 'text',
        hovertext: 'goc',
      })
    })

    // Add log data (for recompletion)
    // Recompletion has logData
    if (structSectionData.logData) {
      let { logData } = structSectionData
      let targetLogData = {
        xValues: logData['xValues'],
        yValues: logData['yValues'],
        zValues: logData['zValues'],
      }

      // Filter the data according to the start and stop depth in the oppotunity
      if (startDepthMd && stopDepthMd) {
        let targetTrajectory = wellData[targetWell]['trajectory']
        targetTrajectory = {
          x: targetTrajectory.map(i => i.x),
          y: targetTrajectory.map(i => i.y),
          z: targetTrajectory.map(i => -1 * i.tvdss),
          md: targetTrajectory.map(i => i.md),
        }
        let startTargetZ = 0
        let stopTargetZ = 0
        let startTargetIdx = bisect(targetTrajectory['md'], startDepthMd)
        let stopTargetIdx = bisect(targetTrajectory['md'], stopDepthMd)
        if (startTargetIdx == stopTargetIdx) {
          let startTargetRatio = (startDepthMd - targetTrajectory['md'][stopTargetIdx-1]) / (targetTrajectory['md'][stopTargetIdx] - targetTrajectory['md'][stopTargetIdx-1])
          let stopTargetRatio = (stopDepthMd - targetTrajectory['md'][stopTargetIdx-1]) / (targetTrajectory['md'][stopTargetIdx] - targetTrajectory['md'][stopTargetIdx-1])
          startTargetZ = targetTrajectory['z'][stopTargetIdx-1] + startTargetRatio*(targetTrajectory['z'][stopTargetIdx] - targetTrajectory['z'][stopTargetIdx-1])
          stopTargetZ = targetTrajectory['z'][stopTargetIdx-1] + stopTargetRatio*(targetTrajectory['z'][stopTargetIdx] - targetTrajectory['z'][stopTargetIdx-1])
        } else {
          startTargetZ = targetTrajectory['z'][startTargetIdx]
          stopTargetZ = targetTrajectory['z'][stopTargetIdx]
        }
        if (startTargetZ !== 0 && stopTargetZ !== 0) {
          // Hide the log offset part in the zone section depth
          logData['zValues'].forEach(function (zValue, idx) {
            if (zValue > startTargetZ || zValue < stopTargetZ) {
              logData['logValues'][idx] = false
            }
          })

          if (structSectionData['axis'] == 'y') {
            logData['logValues'].forEach(function (logValue, idx) {
              if (logValue) {
                let targetOffset = (xLimits[1] - xLimits[0])*0.05
                targetLogData['xValues'][idx] = targetLogData['xValues'][idx] + targetOffset
              }
            })
          } else {
            logData['logValues'].forEach(function (logValue, idx) {
              if (logValue) {
                let targetOffset = (yLimits[1] - yLimits[0])*0.05
                targetLogData['yValues'][idx] = targetLogData['yValues'][idx] + targetOffset
              }
            })
          }
        }
      }

      plotData.push({
        type: 'scatter3d',
        x: targetLogData['xValues'],
        y: targetLogData['yValues'],
        z: targetLogData['zValues'],
        mode: 'lines',
        line: {
          color: logColor,
          width: mainTrajectoryWidth
        },
        showlegend: false,
        hoverinfo: 'text',
        hovertext: targetWell
      })
    }

    if (opportunityData.type === 'verticalDrill') {
      const targets = opportunityData.Opportunities
        .filter(o => o.isFinalTarget)
      targets.forEach((target) => {
        plotData.push({
          type: 'scatter3d',
          x: [target.x, target.x],
          y: [target.y, target.y],
          z: [target.targetIntervalTop, target.targetIntervalBase],
          mode: 'lines',
          line: {
            color: 'red',
            width: mainTrajectoryWidth * 2,
          },
          showlegend: false,
          smoothing: 0,
          hoverinfo: 'text',
          hovertext: targetWell
        })
      })
    }

    // Update layout
    let layout = {
      autosize: true,
      //title: {text: 'Structural x-section - '.concat(orientation)},
      margin: {l: 0, r: 0, t: 0, b: 0, pad: 4},
      scene: {
        camera,
        xaxis: {
          range: [xLimits[0]-offset, xLimits[1]+offset],
        },
        yaxis: {
          range: [yLimits[0]-offset, yLimits[1]+offset],
        },
        zaxis: {
          range: [zLimits[0]-offset, zLimits[1]+offset],
          title: {
            text: 'Depth'
          },
        },
        aspectmode: 'manual',
        aspectratio: {
          x: (xLimits[1]-xLimits[0]+2*offset)/(xLimits[1]-xLimits[0]+yLimits[1]-yLimits[0]+4*offset),
          y: (yLimits[1]-yLimits[0]+2*offset)/(xLimits[1]-xLimits[0]+yLimits[1]-yLimits[0]+4*offset),
          z: zRatio
        },
        annotations: annotations,
      },
      showlegend: false,
      autosize: true,
    }

    return (
      <Plot
        className="plot"
        data={plotData}
        layout={layout}
        config={{displaylogo: false, showSendToCloud: true}}
        useResizeHandler
      />
    )
  }
}


export default StructuralXSection