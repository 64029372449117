import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import uncontactedNetPaySettingsA from '../../../../../../../../../../static/images/userManual/settings/uncontactedNetPaySettingsA.png'

@autobind class UncontactedNetPay extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Uncontacted Net Pay Settings
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Use Standoff<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Description:</span> If on, then the 'Contact Standoff' distance will be defined and used to truncate identified UNP (uncontacted net pay) intervals.<br/>
        </div>
        <div className="content-row"> 
          Contact Standoff<br/>
          <span className='blue'>Dependency:</span> Use Standoff is toggled on<br/>
          <span className='blue'>Options:</span> Meters<br/>
          <span className='blue'>Description:</span> If on, then the ‘Contact Standoff’ distance will be defined to truncate identified UNP intervals.<br/>
        </div>
        <div className='image-container'>
          <img className="tooBig" src={uncontactedNetPaySettingsA} alt="" />
        </div>
        <div className="content-row"> 
          Max Separation Distance<br/>
          <span className='blue'>Options:</span> Meters<br/>
          <span className='blue'>Description:</span> If UNP intervals within the same zone are separated by more than specified distance (also MD), they are classified as separate opportunities.<br/>
        </div>
        <div className="content-row"> 
          Use Sigma PNL Logs<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Description:</span> If on, sigma log will be used to filter UNP.<br/>
        </div>
        <div className="content-row"> 
          Merge Multiple Sigma Vintages within a well<br/>
          <span className='blue'>Dependency:</span> Use Sigma PNL Logs is turned on<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Description:</span> If on, sigma curves will be merged using a date priority. If off, the assumption is that you have imported a sigma curve that has already been merged.<br/>
        </div>
        <div className="content-row"> 
          Max Sigma Values (SW Proxy)<br/>
          <span className='blue'>Dependency:</span> Based on Sigma PNL logs selected<br/>
          <span className='blue'>Options:</span> 0 to 1 or 0 to 100 (depending on curve scale)<br/>
          <span className='blue'>Description:</span> Value cutoff to use for filtering UNP.<br/>
        </div>
        <div className="content-row"> 
          Max Sigma Values (SW Proxy)<br/>
          <span className='blue'>Dependency:</span> Based on Sigma PNL logs selected<br/>
          <span className='blue'>Options:</span> 0 to 1 or 0 to 100 (depending on curve scale)<br/>
          <span className='blue'>Description:</span> Value cutoff to use for filtering UNP.<br/>
        </div>
        <div className="content-row"> 
          Use Simulation Model<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Description:</span> If on, the current So curve will be used to filter UNP. This So curve comes from simulation results. The recommendation is to have imported a So pseudolog directly as part of the LAS files.<br/>
        </div>
        <div className="content-row"> 
          Use Oil Saturation Grids<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Description:</span> If on, the current So curve will be used to filter UNP. This So curve comes from simulation results. The recommendation is to have imported a So pseudolog directly as part of the LAS files.<br/>
        </div>
        <div className="content-row"> 
          Create Oil Saturation Pseduo Logs<br/>
          <span className='blue'>Depdencies:</span> Use Oil Saturation Grids turned on<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Description:</span> If on, this creates a pseudo log based on the So model. This requires that a model with a So property has been imported.<br/>
        </div>
        <div className="content-row"> 
          Minso<br/>
          <span className='blue'>Depdencies:</span> Use Oil Saturation Grids turned on<br/>
          <span className='blue'>Options:</span> 0 to 1 or 0 to 100 (depending on curve scale)<br/>
          <span className='blue'>Description:</span> Min So cutoff to use for filtering UNP.<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(UncontactedNetPay)
