import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners'
import ReactImageMagnify from 'react-image-magnify'
import Card from '../../common/Card'
import API from '../../../../../lib/api-store'
import WellTimeline from '../../components/WellTimeline'
import WellPerforationTable from '../../components/WellPerforationTable'

import './MechanicalFeasibility.scss'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class MechanicalFeasibility extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      wellboreDiagram: null,
      shouldRenderWBD: false,
      shouldRenderPerfHist: false,
    }
  }

  componentDidMount() {
    const { setCurPage } = this.props
    setCurPage('')
    this.loadData()
  }

  loadData() {
    const { datasetId, realDatasetId, singleOpportunity } = this.props

    API.getWellbore(datasetId, realDatasetId, singleOpportunity.name)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error loading diagram')
        }
        response.text().then((diagram) => {
          this.setState({
            wellboreDiagram: diagram,
            shouldRenderWBD: true,
          })
        })
      })
      .catch((error) => {
        this.setState({
          wellboreDiagram: null,
          shouldRenderWBD: true,
        })
      })

    setTimeout(() => {
      this.setState({
        shouldRenderPerfHist: true,
      })  
    }, 100)
  }

  renderWBD() {
    const { singleOpportunity } = this.props

    if (this.state.shouldRenderWBD) { 
      if (this.state.wellboreDiagram) {
        return <div style={{height: '100%', width: '100%'}}><ReactImageMagnify {...{
            smallImage: {
             src: this.state.wellboreDiagram,
             isFluidWidth: true,
            },
            largeImage: {
              src: this.state.wellboreDiagram,
              width: 1500,
              height: 1500,
            },
            enlargedImagePosition: 'over',
            className: 'testMag',
        }}/></div>
      }  
      return <div>MISSING</div>
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderPerfHist() {
    const { wellData, singleOpportunity } = this.props
    if (wellData && singleOpportunity&& this.state.shouldRenderPerfHist) {
      
      let singleWellData = wellData[singleOpportunity.name]
      return <WellTimeline data={singleWellData}/>
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderPerfData() {
    const { wellData, singleOpportunity } = this.props
    if (wellData && singleOpportunity&& this.state.shouldRenderPerfHist) {
      
      let singleWellData = wellData[singleOpportunity.name]
      return <WellPerforationTable data={singleWellData}/>
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  render() {
    const { map } = this.props
    return (
      <div className="opportunity-mechanical">
        <div className="top">
          <div className="left">
            {map}
          </div>
          <div className="right">
            <Card title="Wellbore Diagram">
              { this.renderWBD() }
            </Card>
          </div>
        </div>
        <div className="bot">
          <Card title="Perforation Data">
            { this.renderPerfData() }
          </Card>
        </div>
        <div className="bot">
          <Card title="Perforation History">
            { this.renderPerfHist() }
          </Card>
        </div>
      </div>
    )
  }
}

export default MechanicalFeasibility
