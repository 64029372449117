import autobind from 'autobind-decorator'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { withStyles, styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import DatasetHistory from '../DatasetHistory/DatasetHistory'
import { VALIDATIONS, filterValidationObject } from '../../../../lib/datasetMaps/validations'

import './ViewDataset.scss'

console.log('hi, testing preproduction CI/CD!')

const TAB_NAMES = {
  PUBLISHED_VERSIONS: 'publishedversions',
  ALL_VERSIONS: 'allversions',
}

const baseClass = 'view-dataset'

const headerClass = `${baseClass}__header`
const deleteButtonClass = `${headerClass}__delete-button`
const tabContentClass = `${baseClass}__tab-content`
const legendClass = `${baseClass}__legend`
const legendItemClass = `${legendClass}__legend-item`
const publishCircleClass = `${legendItemClass}__publish-circle`
const publishTextClass = `${legendItemClass}__text`

const styles = {
  root: {},
  indicator: {
    height: 1,
  },
  tab: {
    fontSize: '1.8rem',
    borderBottom: '1px solid #CCC',
  },
} 

@autobind class ViewDataset extends Component {

  onClickEdit(version) {
    const { dataset, onClickEdit } = this.props
    onClickEdit(dataset._id, version)
  }

  onClickCopy(versionId) {
    const { dataset, onClickCopy } = this.props
    onClickCopy(dataset, versionId)
  }

  render() {
    const { dataset, classes, userId, toggleDeleteVersionContinueModal, toggleDeleteDatasetContinueModal, project } = this.props
    const { latestVersion } = dataset

    const userIsOwner = userId === dataset.createdBy._id

    let versions = dataset.versions

    //Logic to see if a version has unrun, new validations
    //For display purposes
    let hasOutdatedVersion = false
    const isVersionOutdated = (version) => {

      if(version.validationRunning || !version.validationAttempted){
        return
      }

      const integrationObject = filterValidationObject(version.integration, VALIDATIONS.integration, version.hasCategories)
      const combinationObject = filterValidationObject(version.combination, VALIDATIONS.combination, version.hasCategories)

      const validationFields = [
        ...Object.values(integrationObject),
        ...Object.values(combinationObject),
      ]
      const newValidationsImplemented = !!validationFields.find(f => !f.complete)
      version.isOutdated = (newValidationsImplemented && version.valid) ? true : false
      hasOutdatedVersion = version.isOutdated ? true : hasOutdatedVersion
    }

    versions.forEach((version) => {
      isVersionOutdated(version)
    })
    isVersionOutdated(latestVersion)
    //end

    

    const WarningIconLegend = styled(ErrorOutlineIcon)({
      width: '17.5px',
      height: '17.5px',
      color: '#ff9800',
      marginLeft: '-1px'
    })

    return (
      <div className={`${baseClass} product-space__content--wrapper`}>
        <div className={headerClass}>
          <Typography variant="h1" gutterBottom>
            Version History
          </Typography>
        </div>

        <div className={tabContentClass}>
          <div>
            
          </div>

          <div className={legendClass}>
            {hasOutdatedVersion ? (
                <div className={legendItemClass}>
                  <WarningIconLegend/>
                    <span className={publishTextClass} style={{marginTop: '-'}}>
                      Missing New Validations
                    </span>
                </div>
            ) : null}
          </div>

          <DatasetHistory
            project={project}
            dataset={dataset}
            userIsOwner={userIsOwner}
            versions={versions}
            latestVersion={latestVersion}
            showAllVersions={true}
            onClickEdit={this.onClickEdit}
            onClickCopy={this.onClickCopy}
            toggleDeleteVersionContinueModal={toggleDeleteVersionContinueModal}
          />
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default withStyles(styles)(withRouter(connect(mapStateToProps)(ViewDataset)))
