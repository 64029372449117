import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import StepBar from './StepBar'
import ActionButtons from './ActionButtons'
import Avatar from '../../../../components/common/Avatar/Avatar'

import emptyCaseIcon from '../../../../../static/images/emptyCase.svg'
import arrowIcon from '../../../../../static/images/arrow.svg'

import './CaseRows.scss'

const icons = {
  emptyCase: emptyCaseIcon,
  arrow: arrowIcon,
}

const CategoryIcon = (props) => {
  const { className, category } = props
  const Component = icons[category]
  return <Component className={className} />
}


const monthMap = {
  0: 'JANUARY',
  1: 'FEBRUARY',
  2: 'MARCH',
  3: 'APRIL',
  4: 'MAY',
  5: 'JUNE',
  6: 'JULY',
  7: 'AUGUST',
  8: 'SEPTEMBER',
  9: 'OCTOBER',
  10: 'NOVEMBER',
  11: 'DECEMBER',
}

const moduleNameMap = {
  'generateWorkovers': 'Recompletions',
  'verticals': 'Sweet Spots',
  'horizontals': 'Horizontals',
  'modelBuilder': 'Model Builder',
  'swmOptimization': 'Optimization'
}

@autobind class CaseRows extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {

  }

  getModuleName(module) {
    const moduleName = moduleNameMap[module] || module
    return moduleName
  }

  redirectToViz(e, caseId) {
    e.stopPropagation()
    const { match } = this.props
    const { projectId, application } = match.params
    this.props.history.push(`/${application}/projects/${projectId}/cases/${caseId}/visualization`)
  }

  redirectToWorkflow(e, caseId) {
    e.stopPropagation()
    const { match, datasetId, module, settings } = this.props
    const { projectId, application } = match.params
    this.props.history.push(`/${application}/projects/${projectId}/cases/${caseId}/initialize`)
  }

  makeEmpty() {
    return (
      <div className='empty-case'>
        <CategoryIcon className="empty-case-icon" category='emptyCase'/>
        <div className='empty-case-text'> 
          <div className='empty-case-text-header'>
            Welcome to your Workspace
          </div>
          <div className='empty-case-text-subheader'>
            Get started by creating your first case
          </div>
        </div>
        <CategoryIcon className="empty-case-arrow-icon" category='arrow'/>
      </div>
    )
  }

  

  render() {
    const { sharedCases, favoriteCases, favoriteCasesRef,
      tab, caseTabName, sharedTabName, favoritesTabName, handleShareCase,
      handleEditCase, handleFavoriteCase, handleViewCaseResults, openModal,
      openEditCaseModal, statusFilter, match, loaded } = this.props
    let { cases, nameFilter } = this.props

    const { projectId } = match.params

    const tabDataMap = {
      [caseTabName]: cases,
      [sharedTabName]: sharedCases,
      [favoritesTabName]: favoriteCases,
    }

    let selectCases = tabDataMap[tab]

    if (nameFilter) {
      nameFilter = nameFilter.toUpperCase()
      selectCases = selectCases.filter(i => i.name.toUpperCase().includes(nameFilter)
        || i.description.toUpperCase().includes(nameFilter)
        || (i.module && i.module.toUpperCase().includes(nameFilter))
        || (i.tags && i.tags.map(i => i.toUpperCase()).reduce((out, cur) => out + cur + '*', '').includes(nameFilter)))
    }

    if (statusFilter) {
      selectCases = selectCases.filter(i => i.step === statusFilter)
    }

    const casesToShow = selectCases.filter(i => !!i.projectId && i.projectId === projectId).reverse() //reverse makes it newest-first
    

    return (
      <div className="case-table">
        {
          loaded && tab === caseTabName && cases.length === 0 && this.makeEmpty()
        }
        {
          casesToShow.map((obj) => {
            const {
              caseId,
              name,
              createdAt,
              finalized,
              shared,
              description,
              userFullName,
              step,
              dataKey,
              module,
              settings,
            } = obj

            const date = new Date(createdAt)
            const formattedDate = `${monthMap[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
            const isFavorite = favoriteCasesRef === undefined ? false : favoriteCasesRef.includes(caseId)

            return (
              <div
                className="case-item qri-card"
                key={caseId}
                onClick={e => finalized ? this.redirectToViz(e, caseId) : this.redirectToWorkflow(e, caseId)}
                onKeyPress={null}
                role="button"
                tabIndex="0"
              >
                <div className="case-avatar align-vertical">
                  <div>
                    <Avatar fullName={userFullName} />
                  </div>
                </div>
                <div className="case-item-one align-vertical">
                  <div className="case-item-one__name-wrapper">
                    <div className="name">{name}</div>
                    { module 
                      ? <div className="module">{`Type ${this.getModuleName(module)}`}</div>
                      : null
                    }
                    <div className="owner">
                      By
                      {' '}
                      { userFullName }
                    </div>
                  </div>
                </div>
                <div className="case-item-two align-vertical">
                  <div>
                    <div className="created">{formattedDate}</div>
                    <div className="description">{description}</div>
                  </div>
                </div>
                <div className="case-item-three align-vertical">
                  <div>
                    <StepBar
                      finalized={finalized}
                      shared={shared}
                      step={step}
                      caseId={caseId}
                    />
                  </div>
                </div>
                <div className="qri-card__actions-container align-vertical">
                  <ActionButtons
                    finalized={finalized}
                    caseId={caseId}
                    isFavorite={isFavorite}
                    isShared={shared}
                    handleShareCase={handleShareCase}
                    handleFavoriteCase={handleFavoriteCase}
                    handleViewCaseResults={handleViewCaseResults}
                    openModal={openModal}
                    openEditCaseModal={openEditCaseModal}
                    datasetId={dataKey}
                    module={module}
                    settings={settings}
                  />
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  favoriteCasesRef: state.getIn(['user', 'favoriteCases']),
})

export default withRouter(connect(mapStateToProps, null)(CaseRows))
