import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class OtherGlobalSettings extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Other Global Settings
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Other settings of importance are put in this section
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Well IP Best (i.e., N) and Well IP First (i.e., M):</span> These set the definition for the initial production (IP) rate. The well IP is defined as the average of the best N months out of the first M months of production.
        </div>
        <div className="content-row">
          &ensp;• <span className='blue'>Log Section Logs:</span> Logs to show on log-based sections and to find offset wells.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Top Zone:</span> Name of stratigraphic top zone, used for some visualizations.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Project Abbreviation:</span> This is the abbreviation you want to set for naming new drill opportunities. For example, if you put “YAY”, then all well names will be “1-QRI-YAY”, “2-QRI-YAY”, “3-QRI-YAY”, etc.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Mapping Time Frame:</span> This is the time frame within which the water cut and GOR information would be considered as recent.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Vertical if no Trajectory:</span> If no, wells with no trajectory data are assumed to be vertical, and the TVDSS logs are based on the MD and KB.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(OtherGlobalSettings)
