import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'


import Overview from './components/Overview'
import Wells from './components/Wells'
import Testing from './components/Testing'
import Surfaces from './components/Surfaces'
import FaultTraces from './components/FaultTraces'
import Logs from './components/Logs'
import GeologicalModels from './components/GeologicalModels'



@autobind class Intro extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/:application/manual/datasets/categories/overview" component={Overview} />
          <Route path="/:application/manual/datasets/categories/wells" component={Wells} />
          <Route path="/:application/manual/datasets/categories/testing" component={Testing} />
          <Route path="/:application/manual/datasets/categories/surfaces" component={Surfaces} />
          <Route path="/:application/manual/datasets/categories/faults" component={FaultTraces} />
          <Route path="/:application/manual/datasets/categories/logs" component={Logs} />
          <Route path="/:application/manual/datasets/categories/models" component={GeologicalModels} />
        </Switch>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Intro)
