////////////////////////
// Webpack Entrypoint //
////////////////////////

import React from 'react'
import { render } from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPowerOff, faBars, faTint, faArrowDown, faChevronCircleRight, faChevronCircleLeft, faQuestionCircle, faSpinner, faDownload, faEllipsisH, faCheck, faTimes, faTimesCircle, faEye, faPlay, faTrash, faUser, faHome, faCog, faHeart, faShareAlt, faChartBar, faSignInAlt, faClone, faUsers, faPencilAlt, faExclamationTriangle, faPlus, faFilePdf, faFileArchive, faFile, faFileWord} from '@fortawesome/free-solid-svg-icons'
import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter, ConnectedRouter } from 'connected-react-router/immutable'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { Map } from 'immutable'
import Amplify from 'aws-amplify'
import config from './config.json'
import { MuiThemeProvider } from '@material-ui/core/styles'
import ReactGA from 'react-ga';

import { loadState, saveState } from './lib/local-storage'
import API from './lib/api-store'


import rootReducer from './redux/index'
import App from './components/App'

import theme from './style/MuiTheme'
import './style/main.scss'
import 'react-table/react-table.css'

//////////////////
// Font-awesome //
//////////////////



library.add(faPowerOff, faBars, faTint, faArrowDown, faSpinner, faChevronCircleRight, faChevronCircleLeft, faQuestionCircle, faPlay, faDownload, faEllipsisH, faTimes, faTimesCircle, faCheck, faEye, faTrash, faUser, faHome, faCog, faHeart, faShareAlt, faChartBar, faClone, faUsers, faPencilAlt, faSignInAlt, faExclamationTriangle, faPlus, faFilePdf, faFileArchive, faFile, faFileWord)


// TODO - fix local storage or add redux persist npm package
const localStorageKey = 'welcomeToTheMachine'
const persistedState = loadState(localStorageKey, new Map())
// const persistedState = Map()

const history = createBrowserHistory()
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  connectRouter(history)(rootReducer),
  persistedState,
  composeEnhancer(
    applyMiddleware(
      routerMiddleware(history),
      thunk,
    ),
  ),
)

const state = store.getState().toJS()
console.log('gettting state from store')
console.log(state)
store.subscribe(() => {
  saveState(localStorageKey, store.getState())
})

const trackingId = process.env.API_ADDRESS === 'http://localhost:3001/api' ?  "" : "UA-182493879-1"
ReactGA.initialize(trackingId);

//////////////////
// React Render //
//////////////////

// TODO - reconnect session to user if need be
const start = () => {
  document.getElementById('app').classList.remove('isLoading', 'isRestoring')
  document.cookie = `timeoutAt=; expires=Sun, 28 Jun 1914 10:45:00 CET`
  console.log('clearing timeout cookie')
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app'),
  )
}

const timeoutString = document.cookie.split(';').filter(item => item.includes('timeoutAt='))[0]
let timeout
let isExpired = false

/*

  Timeout Logic Summary.
  -timeoutAt cookie is added on window close in ./components/App.js
  -timeout cookie is cleared on start() in this function and
   when the ProductSpaceRoutes component updates
   this is to prevent a second tab closing and creating the timeout cookie while you're still using the app
  -
  
*/
if(timeoutString){
  timeout = new Date(timeoutString.split('=')[1])
  let now = new Date()
  isExpired = now > timeout
  console.log('timeout', timeout)
  console.log('isExpired', isExpired)
}

if (state.user && !isExpired) {
  console.log('i have a user')
  document.getElementById('app').className = 'isLoading isRestoring'
  store.dispatch({ type: 'LOGIN', user: state.user })
  start()
} else if(state.user && isExpired){
  API.logout()
    .then(r => {
      console.log('Session expired')
      store.dispatch({ type: 'LOGOUT' })
      start()
    })
} else {
  console.log('no user found in state...')
  start()
}
