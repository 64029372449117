import React, { PureComponent, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import autobind from 'autobind-decorator'
import Plotly from 'plotly.js'
import Plot from 'react-plotly.js'
import { NavLink, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Popover from '@material-ui/core/Popover'
import CloseIcon from '@material-ui/icons/Close'
import { pinCaseOpportunity, unpinCaseOpportunity } from '../../../../redux/actions/user'

const opportunityLabel = (opp) => {
  const name = opp.name || opp.wellId
  return opp.zone ? `${name} - ${opp.zone}` : `${name}`
}

const routes = {
  executiveSummary: "visualization",
  opportunitySummary: "opportunities",
  petroChar: "petrophysical",
  productionForecast: "production",
  modelAnalysis: "model",
  perforationHistory: "mechanical" 
}

@autobind class WellLocation extends PureComponent {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      anchorEl: null,
      selectedOpportunity: null,
      xrange: null,
      yrange: null,
    }

  }

  componentDidMount(){
    //parse route
    const pathName = this.props.location.pathname.split('/')
    if(pathName.length === 7) this.route = routes.executiveSummary
    else if(pathName.length === 9) this.route = routes.opportunitySummary
    else this.route = Object.values(routes).filter(i => i === pathName[pathName.length - 1])[0]
    //

    this.setRange()
  }

  setRange(){
    //Notes on this at bottom of page.

    const { xzoom, yzoom } = getZoom()
    const alreadyHasZoom = (!!xzoom && !!yzoom)

    if(alreadyHasZoom){
      this.setState({ xrange: xzoom, yrange: yzoom })
      return
    }
    
    this.setState({ yrange: null, xrange: null })
    setInitial(null, null)

  }

  onRelayout(input){
    // console.log('onRelayout()')
    // console.log('raw input: ', input)
    let xInput = [input['xaxis.range[0]'], input['xaxis.range[1]']]
    let yInput = [input['yaxis.range[0]'], input['yaxis.range[1]']]

    if(!xInput[0] && !xInput[1]){
      xInput = null
    }
    if(!yInput[0] && !yInput[1]){
      yInput = null
    }
   
    if(!xInput && !yInput){
      return
    }
    setZoom(xInput, yInput)
    this.setState({xrange: xInput, yrange: yInput}) 
  }

  onResetAxes(){
    setZoom(null, null)
    const { xInit, yInit } = getInitial()
    this.setState({ xrange: xInit, yrange: yInit })
  }

  handleLegendClick(e) {
    const { setClickedOffTraces, clickedOffTraces } = this.props

    let name = e.data[e.curveNumber].name
    let newClickedOffTraces = clickedOffTraces.slice()
    if (newClickedOffTraces.includes(name)) {
      newClickedOffTraces = newClickedOffTraces.filter(i => i !== name)
    } else {
      newClickedOffTraces.push(name)
    }

    setClickedOffTraces(newClickedOffTraces)

    return false

  }

  selectNeighbor(name) {
    const { selectedNeighborLogs, handleSelectNeighbor, handleUnselectNeighbor } = this.props
    if (!selectedNeighborLogs.includes(name)) {
      handleSelectNeighbor(name)
      const showSelectedSuccess = new Event('showSelectedSuccess')
      window.dispatchEvent(showSelectedSuccess)                     //Triggers a snackbar to show in Petrophysical.js
    } else {
      handleUnselectNeighbor(name)
    }
  }

  handleClick(e) {
    const point = e.points.find(x => x.customdata !== undefined)
    if (point) {
      const { _id, id, type, name, wellId, zone, case_id } = point.customdata
      const link = ''
      const selectedOpportunity = {
        id: _id || id,
        well: name || wellId,
        zone,
        type,
        case_id,
        link,
      }

      this.setState({
        anchorEl: e.event.target,
        selectedOpportunity,
      })

      const position = {
        x: point.x,
        y: point.y,
      }
    } else if(this.route === routes.petroChar && e.points.length > 0) {
      this.selectNeighbor(e.points[0].text)
    }
  }

  handleHover(e) {
    const { setCurHoverWell } = this.props

    if(e.points && e.points.find(p => p.data.mode === 'markers' /*|| p.data.mode === 'lines+markers'*/)){
      const dragLayer = document.getElementsByClassName('nsewdrag')[0]
      dragLayer.style.cursor = 'pointer'

      let text = e.points[0].text.split(' - ')

      if (text.length > 1 && typeof setCurHoverWell === "function") setCurHoverWell(text[0])
    } 
  }

  handleUnhover(e) {
    const { setCurHoverWell } = this.props
    if(e.points && e.points.find(p => p.data.mode === 'markers' /*|| p.data.mode === 'lines+markers'*/)){
      const dragLayer = document.getElementsByClassName('nsewdrag')[0]
      dragLayer.style.cursor = ''
    }

    if(typeof setCurHoverWell === "function") setCurHoverWell(null)
    
  }

  closePopover() {
    this.setState({
      anchorEl: null,
      selectedOpportunity: null,
    })
  }

  onInitialized(figure, graphDiv) {
    // console.log('Implement this')
  }
  
  isPinned(opportunityId) {
    const { pinned } = this.props
    return pinned && pinned.find(p => p.get('_id') === opportunityId) !== undefined
  }

  handlePin(opportunityId, caseId) {
    const { pinCaseOpportunityAction, unpinCaseOpportunityAction } = this.props

    if (!this.isPinned(opportunityId)) {
      pinCaseOpportunityAction(opportunityId, caseId)
    } else {
      unpinCaseOpportunityAction(opportunityId, caseId)
    }
  }

  render() {
    const {
      match,
      wellData,
      zMatrixHeat,
      gridXHeat,
      gridYHeat,
      zMatrixContour,
      gridXContour,
      gridYContour,
      faultData,
      opportunityData,
      heatTitle,
      yMargin,
      selectedNeighborLogs,
      pinCaseOpportunityAction,
      onUpdate,
      gridProperty,
      //layout,
      clickedOffTraces,
      axis,
    } = this.props

    // console.log('gridProperty: ', gridProperty)

    const { projectId, caseId, application } = match.params

    const { 
      anchorEl,
      selectedOpportunity,
      xrange,
      yrange,
    } = this.state

    //adding an outer buffer to fix a stupid bug
    // let xrange = null
    // let yrange = null

    // if(x) xrange = x.map(val => val * 1.1)
    // if(y) yrange = y.map(val => val * 1.1)

    const isPinnned = selectedOpportunity ? this.isPinned(selectedOpportunity.id) : false
    const activeClass = isPinnned ? 'secondary' : 'primary'
    const tooltipText = isPinnned ? 'Unpin from Navigation' : 'Pin to Navigation'

    const oppoNames = opportunityData.map(j => j.name)

    let wellDataSmall = Object.keys(wellData).map((i) => {
      const well = wellData[i]
      return {
        x: well.x,
        y: well.y,
        name: well.name || well.wellId,
      }
    })

    wellDataSmall = wellDataSmall.filter(i => !!i && !oppoNames.includes(i.name))

    const opportunityWellData = opportunityData.map((opportunity) => {
      const name = opportunity.name || opportunity.wellId
      let oppoWellData = wellData[name]
      if (oppoWellData) {
        if ('x' in opportunity && 'y' in opportunity) {
          return {
            id: opportunity._id,
            x: opportunity.x,
            y: opportunity.y,
            name,
            zone: opportunity.zone,
            type: opportunity.type,
            case_id: opportunity.case_id,
          }
        } else {
          return {
            id: opportunity._id,
            x: oppoWellData.x,
            y: oppoWellData.y,
            name,
            zone: opportunity.zone,
            type: opportunity.type,
            case_id: opportunity.case_id,
          }
        }
      }
      return opportunity
    })


    let plotData = []

    if (faultData) {
      plotData = [...plotData, ...faultData.map(i => ({
            x: i.x,
            y: i.y,
            type: 'scattergl',
            mode: 'lines',
            marker: {
              size: 0.5,
              color: 'rgba(0,0,0,1)',
            },
            hoverinfo: 'skip',
            showlegend: false,
      }))]
    }

    if (wellData && opportunityData) {
      const selectedData = wellDataSmall.filter(i => selectedNeighborLogs && selectedNeighborLogs.includes(i.name))
      const uselectedData = wellDataSmall.filter(i => !selectedNeighborLogs || !selectedNeighborLogs.includes(i.name))
      let plotObjWell = {
            name: 'Well',
            x: uselectedData.map(i => i.x),
            y: uselectedData.map(i => i.y),
            text: uselectedData.map(i => i.name),
            hovertemplate: this.route === routes.petroChar ? '<b>%{text} - Click to Add</b>' : '<b>%{text}</b>',
            mode: 'markers',
            type: 'scattergl',
            marker: {
              color: 'rgba(128, 128, 128, 1)',
              line: {
                width: 1,
                color: 'rgba(0,0,0,1)',
              },
            },
          }

      if (clickedOffTraces.includes('Well')) {
        plotObjWell.visible = 'legendonly'
      }

      plotData.push(plotObjWell)

      plotData.push({
            name: 'Selected Well',
            x: selectedData.map(i => i.x),
            y: selectedData.map(i => i.y),
            text: selectedData.map(i => i.name),
            hovertemplate: this.route === routes.petroChar ? '<b>%{text} - Click to Remove</b>' :'<b>%{text}</b>',
            mode: 'markers',
            type: 'scattergl',
            marker: {
              color: '#0274FF',
              line: {
                width: 1,
                color: 'black',
              },
            },
          })
    }

    if (opportunityData) {
      opportunityData.forEach((opp, idx) => {
        if(opp.type === 'horizontalDrill' || opp.type === 'unconventionalDrill'){
          const trajectory = opp.Trajectory
          plotData.push({
            name: 'Opportunity',
            text: [opportunityLabel(opp), opportunityLabel(opp)],
            hovertemplate: '<b>%{text} - Click For More</b>',
            x: [trajectory.x[0], trajectory.x[trajectory.x.length - 1]],
            y: [trajectory.y[0], trajectory.y[trajectory.y.length - 1]],
            customdata: [opp],
            mode: 'lines+markers',
            type: 'scattergl',
            showlegend: idx === 0, 
            marker: {
              size: 8,
              symbol: 'circle',
              color: 'rgba(250,0,0,1)',
            },
          })
        }
      })
    }

    if (opportunityWellData) {
      let plotObjOppo = {
        name: 'Opportunity',
        x: opportunityWellData.filter(opp => opp.type !== 'horizontalDrill').map(i => i.x),
        y: opportunityWellData.filter(opp => opp.type !== 'horizontalDrill').map(i => i.y),
        text: opportunityWellData.filter(opp => opp.type !== 'horizontalDrill').map(i => opportunityLabel(i)),
        customdata: opportunityWellData.filter(opp => opp.type !== 'horizontalDrill'),
        hovertemplate: '<b>%{text} - Click For More</b>',
        mode: 'markers',
        type: 'scattergl',
        showlegend: true,
        marker: {
          size: 12,
          symbol: 'star',
          color: 'rgba(250,0,0,1)',
          line: {
            width: 1,
            color: 'black',
          },
        },

      }

      if (clickedOffTraces.includes('Opportunity')) {
        plotObjOppo.visible = 'legendonly'
      }
      plotData.push(plotObjOppo)
    }

    if (zMatrixContour && gridXContour && gridYContour) {
      plotData.push({
            z: zMatrixContour,
            x: gridXContour,
            y: gridYContour,
            type: 'contour',
            line: {
              smoothing: 0.85,
            },
            contours: {
              coloring: 'lines',
            },
            hoverinfo: 'skip',
            showscale: false,
          })
    }

    //Inverting colors for 'recentWct'

    const greenBlueHeatmap = {
      'recentWct': true,
      'sw': true,
    }

    if (zMatrixHeat && gridXHeat && gridYHeat && greenBlueHeatmap[gridProperty]) {
      plotData.push({
            name: heatTitle !== undefined ? heatTitle : '',
            z: zMatrixHeat,
            x: gridXHeat,
            y: gridYHeat,
            type: 'heatmap',
            zsmooth: 'best',
            colorscale: [
              ['0.0', 'rgb(0, 255, 0)'],
              ['1.0', 'rgb(0, 0, 255)']
            ],
            hoverinfo: 'skip',
            showlegend: false,
          })
    } else if (zMatrixHeat && gridXHeat && gridYHeat) {
      plotData.push({
            name: heatTitle !== undefined ? heatTitle : '',
            z: zMatrixHeat,
            x: gridXHeat,
            y: gridYHeat,
            type: 'heatmap',
            zsmooth: 'best',
            colorscale: 'Viridis',
            hoverinfo: 'skip',
            showlegend: false,
          })
    }

    const plotLayout = {
      title: {
        text: undefined,
      },
      autosize: true,
      hovermode: 'closest',
      dragmode: 'pan',
      //clickmode: 'event',
      margin: {
        l: 60,
        r: 10,
        b: yMargin || 30,
        t: 10,
        pad: 0,
      },
      xaxis: {
        range: xrange || null,
      },
      yaxis: {
        range: yrange || null,
        scaleanchor: "x",
        // scaleratio: 1,
      },
      legend: {
        orientation: 'h',
        y: 1.06,//-0.02,
        x: 0.3,//1.15,
        xanchor: "right",
        // bgcolor: '#FFF',
      },
      ...axis,
    }

    //https://community.plotly.com/t/how-to-remove-mode-bar-buttons/34002
    const plotConfig = {
      displaylogo: false,
      plotlyServerURL: "https://chart-studio.plotly.com",
      modeBarButtons: [[
        'toImage'
        ], [
        'zoom2d',
        'pan2d',
        'zoomIn2d',
        'zoomOut2d'
      ], [
        'autoScale2d',
        // {
        //   name: 'myResetScale2d',
        //   title: 'Reset axes',
        //   icon: Plotly.Icons.home,
        //   click: () => this.onResetAxes(),
        // }
      ], []]
    }

    return (
      <>
        <Plot
          style={{ 'width': '100%' }}
          data={plotData}
          layout={plotLayout}
          config={plotConfig}
          useResizeHandler
          onClick={e => this.handleClick(e)}
          onLegendClick={e => this.handleLegendClick(e)}
          onHover={e => this.handleHover(e)}
          onUnhover={e => this.handleUnhover(e)}
          onRelayout={(x) => this.onRelayout(x)}
          onUpdate={onUpdate}
          onError={console.log}
          onInitialized={(a, b) => this.onInitialized(a, b)}
          id="well-location"
        />
        { selectedOpportunity && (
          <Popover
            id="mouse-over-popover"
            open={open}
            onClose={e => this.closePopover()}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Card className="opportunity-card">
                <IconButton className="close-btn" aria-label="close" size="small" onClick={e => this.closePopover()}>
                  <CloseIcon />
                </IconButton>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  { selectedOpportunity.well }
                </Typography>
                <div>
                  <span>Zone: </span>
                  { selectedOpportunity.zone }
                </div>
                <div>
                  <span>Type: </span>
                  { selectedOpportunity.type }
                </div>
              </CardContent>
              <CardActions className="opporunity-card-actions">
                <ButtonGroup className="opporunity-card-buttons" size="small" aria-label="small outlined button group">
                  <Button onClick={e => this.handlePin(selectedOpportunity.id, caseId)} size="small" color={activeClass}>
                    {tooltipText}
                  </Button>
                  <Button size="small" color="primary" component={NavLink} exact activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${selectedOpportunity.id}`}>
                    View Opportunity
                  </Button>
                </ButtonGroup>
              </CardActions>
            </Card>
          </Popover>
        )}
      </>
    )
  }
}


window.wellLocationZoom = {
  x0: null,
  xn: null,
  y0: null,
  yn: null,
  x0i: null,
  xni: null,
  y0i: null,
  yni: null,
}

function setZoom(xrange, yrange){
  // console.log('setZoom()')
  // console.log('x: ', xrange)
  // console.log('y: ', yrange)


  window.wellLocationZoom.x0 = Array.isArray(xrange) ? xrange[0] : null
  window.wellLocationZoom.xn = Array.isArray(xrange) ? xrange[1] : null
  window.wellLocationZoom.y0 = Array.isArray(yrange) ? yrange[0] : null
  window.wellLocationZoom.yn = Array.isArray(yrange) ? yrange[1] : null
}
function getZoom(){
  let { x0, xn, y0, yn } = window.wellLocationZoom

  let xzoom = (!x0 && !xn) ? null : [x0, xn]
  let yzoom = (!y0 && !yn) ? null : [y0, yn]
  return { xzoom, yzoom }
}

function setInitial(xrange, yrange){
  // console.log('setInitial()')
  // console.log('X: ', xrange)
  // console.log('Y: ', yrange)

  window.wellLocationZoom.x0i = Array.isArray(xrange) ? xrange[0] : null
  window.wellLocationZoom.xni = Array.isArray(xrange) ? xrange[1] : null
  window.wellLocationZoom.y0i = Array.isArray(yrange) ? yrange[0] : null
  window.wellLocationZoom.yni = Array.isArray(yrange) ? yrange[1] : null
}
function getInitial(){
  let { x0i, xni, y0i, yni } = window.wellLocationZoom

  let xInit = (!x0i && !xni) ? null : [x0i, xni]
  let yInit = (!y0i && !yni) ? null : [y0i, yni]
  return { xInit, yInit }
}


const mapDispatchToProps = dispatch => ({
  pinCaseOpportunityAction: (opportunityId, caseId) => dispatch(pinCaseOpportunity(opportunityId, caseId)),
  unpinCaseOpportunityAction: (opportunityId, caseId) => dispatch(unpinCaseOpportunity(opportunityId, caseId)),
})

const mapStateToProps = state => ({
  pinned: state.getIn(['user', 'pinnedOpportunities']),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WellLocation))

/*
  On setRange():

    We need to add white space to keep data at 1:1 ratio (not streched)
    Initially, we did this with plotLayout.yaxis.scaleanchor = "x", but this locked the zoom to a 1:1 ratio as well
    The solution was to calculate the whitespace needed for a 1:1 ratio manually on render (no other solution found in react-ploty documentation)

    White space can be added in plotLayout.xaxis/yaxis.range for left/right or top/bottom whitespace
    For dataRatio/graphRatio: 1 => is perfect square.  > 1 => tall rectangle.  < 1 = fat rectangle.
    (Case 1) dataRatio > graphRatio => tall data in fat graph.
      graph height = data height
      [by default] graph width = data width
      [it should be] graph width = data width + (2 * buffer)
        -where buffer is the white space on left and right.
        -buffer needs to be in the same arbitrary units of the plotted data.
    Get conversion factor:
      graphHeight = dataHeight| graphWidth [=] px | dataWidth [=] units | ([=] means 'has units of')
      conversionFactor = (graphHeight/dataHeight) = 1 [=] px/units
    Solve for buffer:
      graphWidth = dataWidth + 2*buffer
      graphWidth*conversionFactor = dataWidth + 2*buffer
      (graphWidth*cF - dataWidth)/2 = buffer [=] units
    Get new initial points:
      x0 = firstX - buffer
      y0 = lastX + buffer
    (Case 2) dataRatio < graphRatio => fat data in tall graph.
      graphWidth = dataWidth
      same logic as case1, except for adding white space to y-axis and using x-axis as a conversion factor.
    -Will Yang (4/20/20)
*/

/* 
  On setInitial, getInitial, setZoom, getZoom:

    This was implemented to perseve zoom on filter change.
    Due to the filter
*/
