import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import caseWorkflowA from '../../../../../../../static/images/userManual/caseWorkflowA.png'
import caseWorkflowB from '../../../../../../../static/images/userManual/caseWorkflowB.png'
import caseWorkflowC from '../../../../../../../static/images/userManual/caseWorkflowC.png'
import caseWorkflowD from '../../../../../../../static/images/userManual/caseWorkflowD.png'

@autobind class Workflow extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Case Workflow / How to Run a Case
        </div>
        <div className="content-row"> 
          Whether you decide to create a new case from scratch, duplicate a case to run, or continue running an existing case to generate an opportunity inventory and review it, you will be guided through the SRO Workflow. This workflow has three main steps: Customize, Execute, and Visualize.
        </div>
        <div className='grayLine' />
        <div className="content-row"> 
          <span className="blue">Customize</span>
        </div>
        <div className="content-row-no-padding"> 
          Step 1: Choose a module.
        </div>
        <div className="content-row"> 
            The first step is a dropdown menu that will allow you to choose which module to run. Currently there are three available modules, as reviewed in the section SpeedWise Reservoir Modules:
        </div>
        <div className="content-row"> 
          &ensp;• <span className="blue">Recompletions Module:</span> finds remaining behind pipe pay opportunities.<br/>
          &ensp;• <span className="blue">Sweet Spots Module:</span> finds infill and step-out vertical new drill opportunities.<br/>
          &ensp;• <span className="blue">Horizontals Module:</span> finds infill and step-out horizontal new drill opportunities.
        </div>
        <div className="content-row"> 
          Please note: Other modules are on the way! The next release, scheduled for 3Q 2020, will include the ability to generate inventory lists of reactivations, geomodel validation, and geomodel updates.
        </div>
        <div className='image-container'>
          <img className="" src={caseWorkflowA} alt="" />
        </div>
        <div className="content-row"> 
          Step 2: Choose a Dataset
        </div>
        <div className="content-row"> 
          Once you have chosen your module, the second dropdown menu on the page will automatically update, offering you a list of potential datasets for you to choose for the case. You can select any dataset that appears, because the tool automatically filters the list of datasets to ones that contain all the required data for the module you had selected. This displays all feasible versions of a dataset, including older versions you may want to experiment with.
        </div>
        <div className="content-row"> 
          <b>Please Note</b>: If you have no datasets that are appropriate for the module you’ve selected, then you will need to go back and edit one of your dataset versions or create a new one that fulfills the module requirements.
        </div>
        <div className="content-row"> 
          Step 3: Settings
        </div>
        <div className="content-row">
          After dataset selection, this app will ask if you would like to proceed with Autopilot Mode or Manual Mode. This refers to the method of generating the case settings.
        </div>
        <div className='image-container'>
          <img className="" src={caseWorkflowB} alt="" />
        </div>
        <div className="image-footnote"> 
          For more details on each setting, click on the Module you are interested in the navigation
        </div>
        <div className="content-row">
          For beginners we recommend starting with autopilot which will choose a variety of setting for your case based on the dataset you selected. You can always switch to manual mode to modify any settings and make the case specific to your needs. There are two types of settings:
        </div>
        <div className="content-row"> 
          &ensp;• <span className="blue">Global Settings:</span> settings that affect several steps of the workflow, such as choosing which logs to use.<br/>
          &ensp;• <span className="blue">Step Settings (aka Submodule Settings):</span> settings that are specific to a step in the workflow, such as drainage analysis.
        </div>
        <div className='image-container'>
          <img className="" src={caseWorkflowC} alt="" />
        </div>
        <div className="image-footnote"> 
          Submodule settings can all be modified in Manual Mode. 
        </div>
        <div className="content-row"> 
          <span className="blue">Edit Settings:</span> to view a certain section of settings, click the dropdown arrow to expand the section. You will also see a question mark, which will open a pop-up with information about the section. Many of the sections have multiple settings, which gives you the power to truly customize the case.
        </div>
        <div className="content-row"> 
          <span className="blue">Help & Additional Information:</span>
        </div>
        <div className="content-row"> 
          &ensp;• <span className="blue">Help Pop-ups:</span> For many settings, you can find additional information by hovering the mouse over the question marks, which will give you a pop-up with basic information and/or background.<br/>
          &ensp;• <span className="blue">Detailed Appendix:</span> The same information that you can see by hovering the mouse over the question marks is also available for you in the <b>Appendix</b>. Additionally, background information for each step in the analysis is available in this appendix and can also be found on the Execute pages.
        </div>
        <div className='grayLine' />
        <div className="content-row"> 
          <span className="blue">Execute</span>
        </div>
        <div className="content-row"> 
          After you are comfortable with your choice of settings for the module you selected, you will click the “Continue” button on the top right of the page and the “Execution” page will appear (Figure 20). The case execution will automatically start after you click the “Run Execution” button. If execution fails, you will be able to make modifications and run it again.
        </div>
        <div className="content-row"> 
          &ensp;• <span className="blue">View Help:</span> Information for each step can be found by clicking the on the question mark icon.
        </div>
        <div className='image-container'>
          <img className="" src={caseWorkflowD} alt="" />
        </div>
        <div className='grayLine' />
        <div className="content-row"> 
          <span className="blue">Visualize</span>
        </div>
        <div className="content-row"> 
          Once a case is successfully executed, the results can be accessed through an interactive browser-based visualization panel. Results can also be shared easily with collaborators on the workspace (usually other asset team members or management). This facilitates a cooperative investigation process to vet the opportunities. Some features that allow this include:
        </div>
        <div className="content-row">
          &ensp;• <span className="blue">Commenting:</span> Every collaborator can leave comments that can be viewed by all other team members. This allows interdisciplinary discussion and review of opportunities.<br/>
          &ensp;• <span className="blue">Tiers:</span> Every opportunity can be categorized into tiers, or priorities for investigation or operations.
        </div>
        <div className="content-row" style={{fontStyle: 'italic'}}> 
          For more information on visualization, view the modules section
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Workflow)
