import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'autobind-decorator'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import LinearProgress from '@material-ui/core/LinearProgress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import API from '../../../lib/api-store'
import Header from '../Visualization/components/Header'
import WaterfloodRoutes from '../../../routes/WaterfloodRoutes'
import WorkflowNavBar from '../Cases/Components/WorkflowNavBar'

import { getCaseSurfaces } from '../../../services/waterfloodService'
import { reset } from '../../../redux/actions/waterfloodCase'
import { generateDownloadResultsUrl } from '../../../services/fileService'

import './Waterflood.scss'

const navClass = `waterflood--nav`

@autobind class Waterflood extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      reservoir: null,
      isFinalized: (props.location.state && props.location.state.finalized !== null) ? props.location.state.finalized : true,
    }
  }

  componentDidMount() {
    const { match, prevCaseId, rxReset } = this.props
    const { application, projectId, caseId } = match.params

    const resetPromise = getCaseSurfaces(caseId)
      .then((surf) => {
        const values = surf.map(val => ({ value: val, label: val }))
        rxReset({
          caseId,
          defaultSurface: surf[0],
        })
      })

    const casePromise = API.getCase(caseId)
      .then(caseObj => {
        const isFinalized = caseObj.finalized

        this.setState({
          isFinalized,
          caseObj,
        })
      })

    const allPromises = [casePromise]
    
    if(prevCaseId !== caseId) {
      allPromises.push(resetPromise)
    }

    Promise.all(allPromises).then((results) => {
      this.setState({ loaded: true })
    })
  }

  handleFinalizeCase() {
    const { match } = this.props
    const { caseId } = match.params

    API.finalizeCase(caseId)

    this.setState({ isFinalized: true })
  }


  async handleDownloadClick(caseId) {
    const link = document.createElement('a')
    const url  = await generateDownloadResultsUrl(caseId)

    link.href = url
    // link.setAttribute('download', name)
    link.target = '_blank'
    link.click()
  }

  renderVisuals() {
    const { reservoir, caseObj } = this.state
    const { user } = this.props

    if (caseObj.module === 'swmOptimization') {
      return (
        <div className='download-1'>
          <div className='download-2' onClick={() => this.handleDownloadClick(caseObj.caseId)}>
            Download Results
          </div>
        </div>
      )
    }

    return (
      <WaterfloodRoutes
        user={user}
        reservoir={reservoir}
      />
    )
  }
  render() {
    const { reservoir, isFinalized, caseObj, loaded } = this.state
    const { match } = this.props
    const { application, projectId, caseId } = match.params
    

    if(!loaded) {
      return <LinearProgress />
    }

    return (
      <React.Fragment>
        <Header caseObj={caseObj} />
        {!isFinalized && (
          <div className='dataset-header visualization-workflow-bar'>
            <div className="header">
              <div 
                className="prev"
                onClick={() => this.props.history.push(`/${application}/projects/${projectId}/cases/${caseId}/execute`)}
              >
                <span style={{'paddingRight': '5px'}}>
                  <FontAwesomeIcon icon="chevron-circle-left" />
                </span>
                Back
              </div>
              <WorkflowNavBar step={3} />
              <div 
                className={`continue-button`}
                onClick={this.handleFinalizeCase}
              >
                Finalize
              </div>
            </div>
          </div>
        )}
        <div className={`visualization ${isFinalized ? 'finalized' : ''}`}>
          <div className='temp-container'>
            {this.renderVisuals()}
        </div>
        </div>
        </React.Fragment>
    )
  }
}


const mapDispatchToProps = dispatch => ({
  rxReset: value => dispatch(reset(value)),
})

const mapStateToProps = state => ({
  prevCaseId: state.getIn(['waterfloodCase', 'caseId']),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Waterflood))
