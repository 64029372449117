import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Slider from '@material-ui/core/Slider'

import CardHeader from '@material-ui/core/CardHeader'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'

import Settings4D from './Settings4D'

import { toggleSettings } from '../../../../redux/actions/waterfloodCase'


const useStyles = makeStyles({
})

const SettingsDrawer = (props) => {
  const { title, subtitle, children, action, showSettings, rxToggleSettings, caseId } = props
  const classes = useStyles()

  return (
    <Drawer
      anchor="right"
      open={showSettings}
      style={{
        position: 'absolute',
      }}
      BackdropProps={{
        style: {
          position: 'absolute',
        },
      }}
      ModalProps={{
        disablePortal: true,
      }}
      PaperProps={{
        position: 'absolute',
        style: {
          position: 'absolute',
          minWidth: 300,
          maxWidth: '100%',
          padding: 20,
        },
      }}
    >
      <div>
        <CardHeader
          title="Filters"
          style={{ padding: 0 }}
          action={
            <IconButton onClick={() => rxToggleSettings()} aria-label="close-settings">
              <CloseIcon />
            </IconButton>
          }
        />
        <Settings4D caseId={caseId} />
      </div>
    </Drawer>
  )
}

const mapDispatchToProps = dispatch => ({
  rxToggleSettings: () => dispatch(toggleSettings()),
})
  
const mapStateToProps = state => ({
  showSettings: state.getIn(['waterfloodCase', 'viewer', 'showSettings']),
  timeline: state.getIn(['waterfloodCase', 'timeline']),
  selectedWells: state.getIn(['waterfloodCase', 'selectedWells']),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsDrawer))