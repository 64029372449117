import React from 'react'

import img1 from './static/FluidPVT1.png'
import img2 from './static/FluidPVT2.png'

const FluidPVT = (
  <div className={'description-container'}>
    <p><b>Content:</b> This file provides PVT tables for entities existing in the study. At least one PVT table for the field is needed. However, a field may have several reservoirs that have quite different PVT behaviors due to different hydrocarbon compositions.</p>
    <p><b>Utility:</b> To provide phase behavior data for fluid volumes conversion between surface conditions and reservoir conditions.</p>
    <p><b>Conventions: Sample Date: </b> It must be in mm/dd/yyyy format</p>
    <p><b>File Format:</b> CSV template (downloadable) (example shown in Figure 9).</p>
    <p><b>Units:</b> Template indicates what units are needed.</p>
    <p><b>Other Note:</b> Not all columns are required. See Table 9 for specifics.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 9: Example of the first few rows of a “Fluid_PVT.csv.”</figcaption>
    </figure>
    <figure>
      <figcaption>Table 9. Details for the “Fluid_PVT.csv.”</figcaption>
      <img src={img2} />
    </figure>
</div>
)

export default FluidPVT