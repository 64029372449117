import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class DarcyParameters extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Darcy Parameters
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Default Permeability<br/>
          <span className='blue'>Options:</span> md<br/>
        </div>
        <div className="content-row"> 
          Alwayse use default permeability<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> If on, sets all permeability values the default.<br/>
        </div>
        <div className="content-row"> 
          Viscosity<br/>
          <span className='blue'>Options:</span> cp<br/>
        </div>
        <div className="content-row"> 
          Formation Volume Factor (FVF)<br/>
          <span className='blue'>Options:</span> rb/stb<br/>
        </div>
        <div className="content-row"> 
          Wellbore Radius<br/>
          <span className='blue'>Options:</span> Meters<br/>
        </div>
        <div className="content-row"> 
          Initial Reservoir Pressure<br/>
          <span className='blue'>Options:</span> Psi<br/>
          <span className='blue'>Comments:</span> Default reservoir pressure.<br/>
        </div>
        <div className="content-row"> 
          Flowing Bottomhole Pressure<br/>
          <span className='blue'>Options:</span> Psi<br/>
          <span className='blue'>Comments:</span> Default flowing bottomhole pressure (FBHP).<br/>
        </div>
        <div className="content-row"> 
          Lookback Months<br/>
          <span className='blue'>Options:</span> Month(s)<br/>
          <span className='blue'>Comments:</span> Used to look back for well pressure data.<br/>
        </div>
        <div className="content-row"> 
          Use New Drill Spacing<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> If off, uses the estimated drainage from current wells.<br/>
        </div>
        <div className="content-row"> 
          New Drill Spacing<br/>
          <span className='blue'>Options:</span> acre(s)<br/>
          <span className='blue'>Comments:</span> Area to use to estimate the drainage radius for the Darcy equation.<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(DarcyParameters)
