
import SDXImage from '../../../../../../static/images/homePage/productImages/png/SDX.png'
import SGOImage from '../../../../../../static/images/homePage/productImages/png/SGO.png'
import SWMImage from '../../../../../../static/images/homePage/productImages/png/SWM.png'
import SDCImage from '../../../../../../static/images/homePage/productImages/png/SDC.png'
import SMLImage from '../../../../../../static/images/homePage/productImages/png/SML.png'

import SDXsvg from '../../../../../../static/images/homePage/productImages/svg/SDX.svg'
import SGOsvg from '../../../../../../static/images/homePage/productImages/svg/SGO.svg'
import SWMsvg from '../../../../../../static/images/homePage/productImages/svg/SWM.svg'
import SDCsvg from '../../../../../../static/images/homePage/productImages/svg/SDC.svg'
import SMLsvg from '../../../../../../static/images/homePage/productImages/svg/SML.svg'

export default {
    SDX: {
        name: "Diagnostics",
        shortName: "SDX",
        color: "#F1954A",
        description: "Accelerate field development planning",
        availability: "Available 2020",
        image: SDXImage,
        svg: SDXsvg
    },
    SPA: {
        name: "Portfolio Analysis",
        shortName: "SPA",
        color: "#F8B715",
        description: "Perform rapid and comprehensive asset valuation",
        availability: "Available - Contact Us",
    },
    SUN: {
        name: "Unconventionals",
        shortName: "SUN",
        color: "#F16363",
        description: "Find opportunities for unconventional fields",
        availability: "Available 2022",
    },
    SGO: {
        name: "Reservoir Opportunity",
        oldShortName: "SGO",
        shortName: "SRO",
        color: "#6DAD9C",
        description: "Streamlined opportunity identification and geological insight",
        path: "projects",
        infoLink: "https://www.qrigroup.com/speedwise-reservoir-opportunity",
        image: SGOImage,
        svg: SGOsvg,
    },
    SDS: {
        name: "Drilling Solutions",
        shortName: "SDS",
        color: "#BB873B",
        description: "Rapid and comprehensive D&C performance analysis",
        availability: "Available 2020",
    },
    SWM: {
        name: "Waterflood Management",
        shortName: "SWM",
        color: "#7EB442",
        description: "Waterflood optimization using data-driven models",
        path: "projects",
        infoLink: "https://www.qrigroup.com/SpeedWise#SWM",
        image: SWMImage,
        svg: SWMsvg,
    },
    SAL: {
        name: "Artificial Lift",
        shortName: "SAL",
        color: "#B2BFB5",
        description: "Rapid and comprehensive artificial lift system performance analysis",
        availability: "Available 2020",
    },
    SDC: {
        name: "Decline Curve",
        shortName: "SDC", 
        color: '#794692',
        description: 'A fully automated decline curve solution.',
        infoLink: "https://www.qrigroup.com/Decline-Curve-Analysis",
        url: "https://www.speedwisedc.com/login",
        image: SDCImage,
        svg: SDCsvg,
    },
    SML: {
        name: "Machine Learning",
        shortName: "SML",
        color: '#F8BAAA',
        description: "A cutting-edge machine learning technology to unveil trends, patterns, and correlations from your data.",
        infoLink: "https://www.qrigroup.com/SpeedWiseML",
        url: "https://www.speedwiseml.com/login",
        image: SMLImage,
        svg: SMLsvg,
    }
}