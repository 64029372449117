import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'
import { NavLink, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Popover from '@material-ui/core/Popover'
import CloseIcon from '@material-ui/icons/Close'
import { pinCaseOpportunity, unpinCaseOpportunity } from '../../../../redux/actions/user'

@autobind class SummaryIPGain extends PureComponent {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      anchorEl: null,
      selectedOpportunity: null,
    }
  }

  handleClick(e) {
    const well = e.points[0].y
    const zone = e.points[0].data.name
    const point = e.points[0]
    const { _id, type, case_id } = e.points[0].customdata

    const link = ''

    const selectedOpportunity = {
      id: _id,
      well,
      zone,
      type,
      case_id,
      link,
      x: e.event.x,
      y: e.event.y,
    }

    this.setState({
      anchorEl: e.event.target,
      selectedOpportunity,
    })
  }

  closePopover() {
    this.setState({
      anchorEl: null,
      selectedOpportunity: null,
    })
  }

  isPinned(opportunityId) {
    const { pinned } = this.props
    return pinned && pinned.find(p => p.get('_id') === opportunityId) !== undefined
  }

  handlePin(opportunityId, caseId) {
    const { pinCaseOpportunityAction, unpinCaseOpportunityAction } = this.props

    if (!this.isPinned(opportunityId)) {
      pinCaseOpportunityAction(opportunityId, caseId)
    } else {
      unpinCaseOpportunityAction(opportunityId, caseId)
    }
  }

  render() {
    const { match, data, colorWheel, pinCaseOpportunityAction, curHoverWell } = this.props
    const { projectId, caseId, application } = match.params
    const { anchorEl, selectedOpportunity } = this.state
    const isPinnned = selectedOpportunity ? this.isPinned(selectedOpportunity.id) : false
    const activeClass = isPinnned ? 'secondary' : 'primary'
    const tooltipText = isPinnned ? 'Unpin from Navigation' : 'Pin to Navigation'
    const zones = []
    const wellNames = []
    const plotData = []

    const open = Boolean(anchorEl)


    data.forEach((i) => {
      const name = i.name || i.wellId
      const zone = i.zone || i.zoneId
      if (!wellNames.includes(name)) {
        wellNames.push(name)
      }
      if (!zones.includes(zone)) {
        zones.push(zone)
      }
    })

    const wells = wellNames.map(name => ({
      name,
      totalIp: data.filter(i => i.name === name || i.wellId === name).reduce((sum, item) => sum + item.predictedIp, 0),
    })).sort((a, b) => a.totalIp - b.totalIp)


    zones.forEach((zone) => {
      const zoneData = data.filter(i => i.zone === zone || i.zoneId === zone)
      const obj = {
        y: wells.map(well => well.name),
        x: wells.map((well) => {
          return zoneData.filter(i => i.name === well.name || i.wellId === well.name).reduce((sum, item) => sum + item.predictedIp, 0)
        }),
        customdata: wells.map((well) => {
          const opportunity = zoneData.find(i => i.name === well.name || i.wellId === well.name)
          return opportunity || {}
        }),
        name: zone,
        type: 'bar',
        orientation: 'h',
        hovertemplate: '<b>Well</b>: %{y}<br><b>IP</b>: %{x}',
      }

      if (colorWheel) {
        obj.marker = {
          color: wells.map(i => {
            let color = colorWheel[zone]
          
            if (i.name === curHoverWell) {
              color = 'red'
            }

            return color
          })
        }
      }

      plotData.push(obj)
    })



    const layout = {
      // title: 'Total IP Gain per Well',
      title: '',
      barmode: 'stack',
      hovermode: 'closest',
      autosize: true,
      annotations: [],
      xaxis: {
        fixedrange: true,
        side: "top",
      },
      margin: {
        l: 100,
        r: 100,
        b: 10,
        t: 30,
        pad: 10,
      },
    }

    return (
      <>
        <Plot
          ref={this.myRef}
          className="plot"
          style={{ "width": "100%"}}
          data={plotData}
          layout={layout}
          config={{displaylogo: false, showSendToCloud: true, displayModeBar: false}}
          onClick={(e) => this.handleClick(e)}
        />
        { selectedOpportunity && (
          <Popover
            id="mouse-over-popover"
            open={open}
            onClose={e => this.closePopover()}
            // anchorEl={anchorEl}
            anchorReference="anchorPosition"
            anchorPosition={{ top: selectedOpportunity.y, left: selectedOpportunity.x }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            /*
            transformOrigin={{
              vertical: 20,
              horizontal: -597.7021475749902,
            }}
            */
          >
            <Card className="opportunity-card">
                <IconButton className="close-btn" aria-label="close" size="small" onClick={e => this.closePopover()}>
                  <CloseIcon />
                </IconButton>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  { selectedOpportunity.well }
                </Typography>
                <div>
                  <span>Zone: </span>
                  { selectedOpportunity.zone }
                </div>
                <div>
                  <span>Type: </span>
                  { selectedOpportunity.type }
                </div>
              </CardContent>
              <CardActions className="opporunity-card-actions">
                <ButtonGroup className="opporunity-card-buttons" size="small" aria-label="small outlined button group">
                  <Button onClick={e => this.handlePin(selectedOpportunity.id, caseId)} size="small" color={activeClass}>
                    {tooltipText}
                  </Button>
                  <Button size="small" color="primary" component={NavLink} exact activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${selectedOpportunity.id}`}>
                    View Opportunity
                  </Button>
                </ButtonGroup>
              </CardActions>
            </Card>
          </Popover>
        )}
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  pinCaseOpportunityAction: (opportunityId, caseId) => dispatch(pinCaseOpportunity(opportunityId, caseId)),
  unpinCaseOpportunityAction: (opportunityId, caseId) => dispatch(unpinCaseOpportunity(opportunityId, caseId)),
})

const mapStateToProps = state => ({
  pinned: state.getIn(['user', 'pinnedOpportunities']),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SummaryIPGain))
