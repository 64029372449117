import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import API from '../../../lib/api-store'
import WorkflowNavBar from './Components/WorkflowNavBar'
import WorkflowRoutes from '../../../routes/WorkflowRoutes'

@autobind class Workflow extends Component {
  constructor(props) {
    super(props)


    this.state = {
      step: (props.location.state && props.location.state.step !== null) ? props.location.state.step : 0,
      canContinue: false,
      caseObj: {},
      prev: '',
      next: '',
    }
  }

  componentDidMount() {
    const { match } = this.props
    const { caseId, projectId } = match.params

    this.loadCase(caseId)
  }

  loadCase(caseId) {
    API.getCase(caseId)
      .then(caseObj => {
        this.setState({
          caseObj
        })
      })
  }

  editCaseObj(newObj) {
    const toNotSelectedList = newObj?.settings?.logAlias?.filter((selectedLogAlias) => {
      return selectedLogAlias?.From && !selectedLogAlias?.To;
    });
    this.setCanContinue(toNotSelectedList?.length === 0);
    this.setState({
      caseObj: newObj
    })
  }

  setCanContinue(val) {
    this.setState({
      canContinue: val,
    })
  }

  setStep(step, prev, next) {
    this.setState({
      step,
      prev,
      next
    })
  }

  handlePrevious() {
    const { prev } = this.state

    this.props.history.push(prev)
  }


  handleNext() {
    const { canContinue } = this.state
    const { next } = this.state

    if (!canContinue) {
      return null
    }

    this.props.history.push(next)
  }


  makeHeader() {
    const { step, canContinue } = this.state

    return (
      <div className="header">
        <div 
          className="prev"
          onClick={this.handlePrevious}
        >
          <span style={{'paddingRight': '5px'}}>
            <FontAwesomeIcon icon="chevron-circle-left" />
          </span>
          Back
        </div>
        <WorkflowNavBar step={step} />
        <div 
          className={`continue-button${canContinue ? '' : '-disabled'}`}
          onClick={this.handleNext}
        >
          Continue
          <span style={{'paddingLeft': '5px'}}>
            <FontAwesomeIcon icon="chevron-circle-right" />
          </span>
        </div>
      </div>
    )
  }

  render() {
    const { step, caseObj } = this.state
    const { match } = this.props

    return (
      <div className="cases">
        <div className="workflow">
          { this.makeHeader() }
          <WorkflowRoutes 
            caseObj={caseObj}
            editCaseObj={this.editCaseObj}
            setCanContinue={this.setCanContinue}
            setStep={this.setStep}
          />
        </div>
      </div>
    )
  }
}



export default withRouter(Workflow)
