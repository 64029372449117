import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import _ from 'underscore'

import Select from 'react-select'
import Creatable from 'react-select/creatable'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from '@material-ui/core/styles/styled'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { CategoryTypes } from '../../../lib/datasetMaps/groupConfigurations'
import FileViewer from '../FileUploader/FileViewer'
import ContinueModal from '../ContinueModal/ContinueModal'
import GroupSelector from '../GroupSelector/GroupSelector'
import UploadParameters from '../UploadParameters/UploadParameters'
import SurfacesSelector from './components/SurfacesSelector'
import ThreeDModelProperties from './components/ThreeDModelProperties'

import {
  deleteAllFilesFromDatasetCategory,
  deleteAllFailedFilesFromDatasetCategory,
  addCustomGrids,
  deleteCustomGrids,
} from '../../../services/datasetService'

import './GeologicalModels.scss'

const baseClass = 'geological-models'

const topBarClass = `${baseClass}__top-bar`
const topButtonsClass = `${baseClass}__top-buttons`
const topButtonsTextClass = `${topButtonsClass}--text`

const uploaderContainerClass = `${baseClass}__uploader-container`
const threeDUploadClass = `${uploaderContainerClass}--3D`

const twoDUploadClass = `${uploaderContainerClass}--2D`
const twoDUploaderClass = `${twoDUploadClass}__uploader`
const twoDSelectorClass = `${twoDUploadClass}__selector`
const twoDSelectorHeaderClass = `${twoDSelectorClass}--header`
const twoDSelectorBodyClass = `${twoDSelectorClass}--body`
const filesSectionClass = `${twoDUploaderClass}__files`

const uploadParametersClass = `${uploaderContainerClass}__upload-parameters`
const uploadParameterHeader = `${uploadParametersClass}__header`
const uploadParameterSelection = `${uploadParametersClass}__selection`
const optionClass = `${uploadParametersClass}__option`
const uploadParameterSingle = `${uploadParametersClass}__single`

const indexClass = `${uploaderContainerClass}__step`

const infoClass = `${uploaderContainerClass}__info`
const categoryTextClass = `${infoClass}__category-text`
const optionsClass = `${infoClass}__options`
const fileExtensionClass = `${infoClass}__file--extension`
const requiredTextClass = `${categoryTextClass}__required`
const helperTextClass = `${infoClass}__helper-text`

const browseButtonClass = `${infoClass}__browse-button`
const browseButtonLabelClass = `${infoClass}__browse-button-label`
const browseButtonTextClass = `${browseButtonLabelClass}__text`
const browseButtonInputClass = `${browseButtonLabelClass}__input`

//Modify Modal css in ContinueModal.scss
const modalUploadClass = `cancel-modal__content__children__file-upload`
const modalHeaderClass = `${modalUploadClass}__header`
const modalInputField = `${modalUploadClass}__input`
const modalInputItem = `${modalInputField}__item`

const baseState = {
  showFileUploadModal: false,
  fileUploadModalChildren: [],
  zoneNames: {},

  //pull these directly from uploadParameters and fill dynaically... later if needed.
  wellNameField: null,
  isFileNameZoneName: null,
  shouldConvertUnits: null,

  //stuff for three d model file
  propertiesToConvert: [],
  threeDProperties: [],
  threeDFileValid: false,
  selected3DFile: null,
  nullValue: -999.25,
}

const AddIcon = styled(AddCircleIcon)({
  color: '#0183EE',
  position: 'absolute',
  left: '-3px',
  top: '12px',
  fontSize: '14px'
})

@autobind class GeologicalModels extends Component {
  constructor(props){
    super(props)
    this.state = baseState
  }

  componentDidMount(){
    this.check3DRequired()
  }

  componentDidUpdate(prevProps){
    this.check3DRequired()
  }

  check3DRequired() {
    const {
      threeDRequired,
      toggleSelectedGeoModel,
      selectedGeoModel
    } = this.props

    if(threeDRequired && (selectedGeoModel !== '3D')){
      toggleSelectedGeoModel('3D')
    }
  }

  parse3DFile(files){
    const file = files[0]
    const CHUNK_SIZE = 1024
    const firstPartOfFile = file.slice(0, CHUNK_SIZE)
    const fr = new FileReader()
    fr.onload = () => {
      console.log(fr.result)
      const lines = fr.result.split('\n')
      const numberOfProperties = parseInt(lines[1])

      //Change these to error modal eventually.
      if(files.length > 1){
        alert('Please upload only one model file.')
        return
      }
      if(lines[0].split(' ')[0].toUpperCase() !== 'PETREL:'){
        alert('Please upload a PETREL model file!')
        return
      }
      if(typeof numberOfProperties !== 'number' || numberOfProperties === NaN){
        alert('Please upload a PETREL model file!')
        return
      }

      const properties = []
      for(let i = 5; i < numberOfProperties + 2; i ++){
        properties.push(lines[i].split(' ')[0])
      }

      this.setState({ threeDProperties: properties, selected3DFile: file })
    }
    fr.readAsText(firstPartOfFile)
  }

  setThreeDFileValidity(boolean){
    this.setState({ threeDFileValid: boolean })
  }

  setThreeDFilePropertiesToConvert(array){
    this.setState({ propertiesToConvert: array })
  }

  upload3DModel() {
    const { propertiesToConvert, selected3DFile } = this.state
    let shouldConvertUnits, properties

    if(propertiesToConvert.length > 0){
      shouldConvertUnits = true
      properties = propertiesToConvert
    } else if (propertiesToConvert.length === 0){
      shouldConvertUnits = false
      properties = null
    }
    this.setState({ shouldConvertUnits, propertiesToConvert: properties }, () => this.callbackWithFiles([selected3DFile]))
  }

  componentWillUnmount(){
    console.log('unmounting geo')
  }

  showDeleteFilesModal(){
    const { 
      category,
      renderCustomModal,
      readOnly,
    } = this.props

    let header, children

    if(!readOnly){
      header = "Delete All Files"
      children = `Are you sure you want to delete all files for ${category.text}?`
    } else{
      header = "Delete All Files / Create New Version"
      children = `Are you sure you want to delete all files for ${category.text}?  This action will create a new version.`
    }

    renderCustomModal(header, children, this.deleteAllFiles)
  }

  onFilesSelected(evt) {
    const { selectedGeoModel } = this.props
    const files = evt.target.files

    if(selectedGeoModel === '3D'){
      this.parse3DFile(files)
      return
    }
    this.callbackWithFiles(files)
    // evt.target.files = null
    // evt.target.value = null
  }

  onDrop(evt) {
    evt.preventDefault()
    evt.stopPropagation()

    const files = evt.dataTransfer.files
    const { selectedGeoModel } = this.props


    if(selectedGeoModel === '3D'){
      this.parse3DFile(files)
      return
    }
    
    this.callbackWithFiles(files)
  }

  checkUploadParametersCompleted(uniqueParams, files, showAlerts) {
    const { category } = this.props
    if (category.uploadParameters) {
      switch(category.category){
        case 'Model_Data':
          if(this.state.shouldConvertUnits === null || (this.state.shouldConvertUnits && !this.state.propertiesToConvert)) {
            showAlerts ? alert('You must specify parameters before uploading files.') : null// <----- maybe change to continue modal later
            return false
          }
          break
        default:
          for (let param of category.uploadParameters){
            if(this.state[param.name] === null){
              showAlerts ? alert(`You must specify parameters before uploading files.`) : null // <----- maybe change to continue modal later
              return false
            }
          }
      }

      // Check if FILE NAME != ZONE NAME in GRIDS.  This is where the modal gets opened.
      if (category.templateName === 'ExampleGridFile' && this.state.isFileNameZoneName === false && !uniqueParams){
        showAlerts ? this.openFileUploadModal(files) : false
        return !showAlerts
        //Why !showAlerts?
        //If we're not showing alerts, we haven't uploaded anything yet.  SO let it pass because the modal comes up after upload @ callback w/ files
        //If we are showing alerts, we want to not continue untill the user fills out the zone name info.
      }

      return true
    } else {
      return true
    }
  }

  async addCustomGrids(property) {

    const {
      datasetId,
      versionId,
      refreshData,
      groups,
    } = this.props

    //Remove the input field
    this.setState({ customGridsCreation: false })

    //Check the existing custom grids to ensure it doesn't already exist.
    console.log('groups', groups)
    let twoDSubgroups = Object.values(groups.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups)
    console.log('2d subgroups', twoDSubgroups)
    let [ match ] = twoDSubgroups.filter(x => x.categories[0].category.toLowerCase() === property.toLowerCase())
    if(match){
      console.log('match', match)
      alert(`A custom grid with the property name you input already exists:\n${match.text} (${match.categories[0].category})`)
      return
    }

    //If there is no input, just return.
    if(property === ''){
      return
    }

    //Send the API call and handle results accordingly.
    const response = await addCustomGrids(datasetId, versionId, property)
    if(!response.ok) {
      alert(`There was an error uploading your file. \n ${response.error}`)
    } else if(response.ok){
      refreshData()
    }
  }

  async deleteCustomGrid(property){
    const {
      selectedVersion,
      allStoredFiles,
      allUploadingFiles,
      allFailedUploads,
      datasetId,
      versionId,
      refreshData
     } = this.props

    //check the file objects.
     const hasFiles = (arr) => (arr && arr.length > 0)
     const stored = allStoredFiles[property]
     const uploading = allUploadingFiles[property]
     const failed = allFailedUploads[property]
     let hasNoFiles = !hasFiles(stored) && !hasFiles(uploading) && !hasFiles(failed)

    //Only allow user to delete if there are no files in category.
    if(!hasNoFiles){
      alert('Please delete all files from this category before deleting')
      return
    }

    //Send the API call and handle results accordingly
    const response = await deleteCustomGrids(datasetId, versionId, property)
    if(!response.ok) {
      alert(`There was an error uploading your file. \n ${response.error}`)
    }else if(response.ok){
      refreshData()
    }

  }

  callbackWithFiles(files, uniqueParams) {

    const {
      onFiles,
      category,
      readOnly,
    } = this.props


    if (readOnly) {
      return
    }

    if (!files || files.length === 0) {
      return
    }

    if (category.type === CategoryTypes.SINGLE) {
      files = [files[0]]
    }

    // Check if upload params are filled out.  Add in additional logic as-needed.
    let x = this.checkUploadParametersCompleted(uniqueParams, files, true)
    console.log('CHECK UPLOAD PARAMS COMPLETED', x)
    if(!this.checkUploadParametersCompleted(uniqueParams, files, true)){
      return
    }

    

    const uploadParameters = (category.uploadParameters || []).reduce((acc, parameter) => {
      acc[parameter.name] = this.state[parameter.name]
      return acc
    }, {})

    console.log('UPLOAD PARAMS', uploadParameters)

    onFiles(files, category.category, uploadParameters, false, uniqueParams)
    this.resetState()
    this.forceUpdate()
  }

  onDragOver(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    //console.log('debounced drag over')
  }

  onDragLeave(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    //console.log('debounced drag leave')
  }

  handleTemplateDownloadClick(name) {
    const link = document.createElement('a')
    link.href = `/templates/${name}`
    link.setAttribute('download', name)
    link.target = '_blank'
    link.click()
  }

  deleteAllFiles() {
    const {
      datasetId,
      category,
      refreshData,
    } = this.props

    //reload if either failed or successful
    deleteAllFilesFromDatasetCategory(datasetId, category.category).finally(() => {
      refreshData()
    })
  }

  deleteAllFailedFiles() {
    const {
      datasetId,
      category,
      refreshData,
    } = this.props

    //reload if either failed or successful
    deleteAllFailedFilesFromDatasetCategory(datasetId, category.category).finally(() => {
      refreshData()
    })
  }


  /*--- Section: Upload Parameter Functions ---*/

    //Zone Names

  openFileUploadModal(files){
    this.setState({
      files,
      fileUploadModalChildren: this.createFileUploadModalChildren(files),
      showFileUploadModal: true,
    })
  }

  resetState(){
    this.setState(baseState)
  }

  closeAndSend() {
    const { files, zoneNames } = this.state
    const uniqueParams = this.formatZoneNameParams(zoneNames)
    this.callbackWithFiles(files, uniqueParams)
  }

  formatZoneNameParams(zoneNames){
    const uniqueParams = {}
    _.map(zoneNames, (val, fileName) => {
      uniqueParams[fileName] = { 
        zoneName: val,
      }
    })
    return uniqueParams
  }

  handleZoneNameInput(fileName, wellName){
    //This is slow, but it works for now.
    //Every change is re-rendering all uploaded files currently.
    //fixed in shouldComponentUpdate, below.
    const { zoneNames } = this.state
    this.setState({
      zoneNames: {
        ...zoneNames,
        [fileName]: wellName,
      }
    })
  }

  onNullValueChange(e) {
    console.log('nullValuyye', typeof e.target.value, e.target.value)
    this.setState({ nullValue: e.target.value })
  }

  setCreateGrids(bool) {
    this.setState({ customGridsCreation: bool })
  }

  createFileUploadModalChildren(files){
    if(files){
      let textInputs = _.map(files, (file) => {
        return (
          <div className={modalInputItem}>
            <div className={'align-left'}>
              <p>{file.name}</p>
            </div>
            <div className={'align-right'}>
              <TextField
                id={file.name}
                variant="outlined"
                fullWidth
                size='small'
                onChange={(e) => this.handleZoneNameInput(e.target.id, e.target.value)}
              />
            </div>
          </div>
        )
      })
      //Modify Modal css in ContinueModal.scss
      return (
        <div className={modalUploadClass}>
          <div className={modalHeaderClass}>
            <p>In order to upload a file, a zone name must be entered.  Zone names can not be changed unless the file is uploaded again.</p>
          </div>
          <div className={modalInputField}>
            <div className={`${modalInputItem} header`}>
              <span className={'align-left'}>File Name</span><span className={'align-right'}>Zone Name</span>
            </div>
            {textInputs}
          </div>
        </div>
      )
    }
  }

    //General Parameters

  changeCreatableSelection(paramName, selectedArray){
    if (Array.isArray(selectedArray)){
      this.setState({ [paramName]: selectedArray.map(prop => prop.value.toUpperCase()) })
    } else {
      this.setState({ [paramName]: selectedArray })
    }
  }

  changeSelectedValue(paramName, value){
    if(value === "true"){
      value = true
    } else if (value === "false"){
      value = false
    }
    this.setState({ [paramName]: value })
  }

  // formatThreeDArrayData(){
  //   this.setState({['propertiesToConvert']: this.state['propertiesToConvert'].map(e => e.value)})
  // }


  

  /*-----end section-----*/

  //this could introduce some unforseen bugs.  
  shouldComponentUpdate(nextProps, nextState){
    if(this.state.showFileUploadModal !== nextState.showFileUploadModal) { return true }
    if(this.state.zoneNames !== nextState.zoneNames) { return false }
    return true
  }

  render() {

    const {
      datasetId,
      versionId,
      index,
      key,
      category,
      onFiles,
      files,
      uploadingFiles,
      failedUploads,
      refreshData,
      readOnly,
      valid,
      isDatasetValid,
      onClickGroup,
      groups,
      selectedGroupId,
      toggleSelectedGeoModel,
      selectedGeoModel,
      storedFilesByCategory,
      threeDRequired,
      openFileInformationDrawer,
      openFileDescriptionDrawer,
    } = this.props

    const { 
      threeDProperties,
      selected3DFile,
      threeDFileValid,
      propertiesToConvert,
      showFileUploadModal,
      fileUploadModalChildren,
      nullValue,
      customGridsCreation,
    } = this.state

    const fileExists = files.length > 0
    const containsFailedFiles = files.find((file) => {
      return file.validationComplete && !file.validationPassed
    })

    const uploadParametersCompleted = this.checkUploadParametersCompleted(false)

    return (

      <div className={baseClass}>
        <div className={topButtonsClass}>
          <div className={topButtonsTextClass}>
            { valid && (
              `${selectedGeoModel} Model was selected for this version.`
            )}
            { !valid && threeDRequired &&(
              `3D Model is required for your selected modules.`
            )}
            { !valid && !threeDRequired && (
              `What type of Geological Model do you want to upload?`
            )}
          </div>
          <div></div>
          <ToggleButtonGroup
            value={valid ? selectedGeoModel : threeDRequired ? '3D' : selectedGeoModel}
            size="large"
            exclusive
            onChange={(e, type) => {
              if(threeDRequired) return
              else toggleSelectedGeoModel(type)
            }}
            aria-label="geo model selector"
          >
            <ToggleButton value="3D" aria-label="left aligned">
              3D
            </ToggleButton>
            <ToggleButton value="2.5D" aria-label="centered">
              2.5D
            </ToggleButton>
          </ToggleButtonGroup>

        </div>
        {/* 3D MODEL */}
        {selectedGeoModel === '3D' && (<>
          {
            !fileExists && uploadingFiles.length === 0 && failedUploads.length === 0 && category.type === CategoryTypes.SINGLE && (
              <>
              <div
                className={`${uploaderContainerClass} ${threeDUploadClass} align-vertical`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
              >
                <div className={indexClass}>
                  {index}
                </div>
                <div className={infoClass}>
                  <div className={categoryTextClass}>
                    <span>{category.text}</span>
                    <FontAwesomeIcon
                      icon="question-circle"
                      className="help-icon"
                      style={{ 
                        'height': '13px',
                        'width': '13px',
                        'position': 'relative',
                        'left': '3px',
                        'top': '-2px',
                        'cursor': 'pointer',
                      }}
                      onClick={e => openFileDescriptionDrawer(e, category)}
                    />
                    {category.required && (
                      <span className={requiredTextClass}>
                        <sup style={{ 'padding-top': '0.2em', 'font-size': '0.75em' }}> *Required</sup>
                      </span>
                    )}
                  </div>
                  <div className={optionsClass}>
                    <span>
                      <span className={fileExtensionClass}>
                        {category.fileFormat}
                      </span> file -
                  </span>
                    <Link
                      onClick={() => this.handleTemplateDownloadClick(category.templateName)}
                      href="#"
                    > DOWNLOAD TEMPLATE
                  </Link>
                  </div>
                </div>
                {/* Disabling upload params for now
                { category.uploadParameters && (
                  <div className={uploadParametersClass}>
                  <div className={uploadParameterHeader}>
                    <p><b>Parameters</b></p>
                    <p>In order to upload the files, the following information is needed:</p>
                  </div>
                  <div className={uploadParameterSelection}>
                    {category.uploadParameters.map(param => (
                        <UploadParameters
                          uploadParameter={param}
                          changeSelectedValue={this.changeSelectedValue}
                          parentState={this.state}
                        />
                      ))}
                  </div>
                </div>
                )} */}
                <div></div>
                {!readOnly && (
                  <>
                    {!threeDFileValid && (
                      <>
                        <div className={helperTextClass}>
                          Drag files here or
                        </div>
                        <div></div>
                      </>
                    )}
                    {threeDFileValid && (
                      <>
                        <Button
                          className={'geo-button upload'}
                          onClick={() => this.upload3DModel()}
                          // this needs uploadParameters
                        >
                          Complete Upload
                        </Button>
                        <div className={helperTextClass}>
                          or
                        </div>
                      </>
                    )}
                    <div>
                      <input
                        id={`upload-${index}-file`}
                        type="file"
                        className={browseButtonInputClass}
                        onChange={this.onFilesSelected}
                      />
                      <label className={browseButtonLabelClass} htmlFor={`upload-${index}-file`}>
                        <Button className={browseButtonClass} variant="outline" component="span" size="large">
                          Browse
                        </Button>
                      </label>
                    </div>
                  </>
                )}
                {readOnly && (
                  <>
                  <div className={helperTextClass}>
                    No file was uploaded for this category
                  </div>
                  </>
                )}
              </div>
              {threeDProperties.length > 0 && selected3DFile && (
                <ThreeDModelProperties
                  properties={threeDProperties}
                  file={selected3DFile}
                  callbackWithFiles={this.callbackWithFiles}
                  upload3DModel={this.upload3DModel}
                  setThreeDFileValidity={this.setThreeDFileValidity}
                  setThreeDFilePropertiesToConvert={this.setThreeDFilePropertiesToConvert}
                  propertiesToConvert={propertiesToConvert}
                  onNullValueChange={this.onNullValueChange}
                  nullValue={nullValue}
                />
              )}
            </>
            )
          }
        </>)}

        {/* 2.5D Model */}
        {selectedGeoModel === '2.5D' && (<>
          {category.type === CategoryTypes.ARRAY && (
            <>
              <div
                className={`${uploaderContainerClass} ${twoDUploadClass} align-vertical`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
              >
                <div className={twoDSelectorClass}>
                  <div className={twoDSelectorHeaderClass}>
                    <h3>2.5D Model</h3>
                    <p>Upload files for each required category</p>
                    <div
                      className={twoDSelectorHeaderClass + '__add-custom-grids'}
                      onClick={() => this.setCreateGrids(true)}
                    >
                      <AddIcon />
                      <span>Add Property Grids</span>
                    </div>
                  </div>
                    <div className={twoDSelectorBodyClass}>
                      <SurfacesSelector
                        datasetId={datasetId}
                        versionId={versionId}
                        refreshData={refreshData}
                        customGridsCreation={customGridsCreation}
                        setCreateGrids={this.setCreateGrids}
                        addCustomGrids={this.addCustomGrids}
                        groups={groups}
                        onClickGroup={onClickGroup}
                        selectedGroupId={selectedGroupId}
                        storedFilesByCategory={storedFilesByCategory}
                        deleteCustomGrid={this.deleteCustomGrid}
                      />
                    </div>
                </div>
                <div className={twoDUploaderClass}>
                  <div className={infoClass}>
                    <div className={categoryTextClass}>
                      <span><b>{category.text}</b></span>
                      <FontAwesomeIcon
                        icon="question-circle"
                        className="help-icon"
                        style={{ 
                          'height': '13px',
                          'width': '13px',
                          'position': 'relative',
                          'left': '3px',
                          'top': '-2px',
                          'cursor': 'pointer',
                        }}
                        onClick={e => openFileDescriptionDrawer(e, category)}
                      />
                      {category.required && (
                        <span className={requiredTextClass}>
                          <sup style={{ 'padding-top': '0.2em', 'font-size': '0.75em' }}> *Required</sup>
                        </span>
                      )}
                    </div>
                    {!readOnly && (
                      <>
                    
                      <div className={helperTextClass}>
                        {uploadParametersCompleted ? 'Drag files here or' : <span style={{color: "red"}}>Please specifiy upload parameters</span>}
                      </div>
  
                      { uploadParametersCompleted && (
                        <input
                          id={`upload-${index}-file`}
                          type="file"
                          multiple
                          className={browseButtonInputClass}
                          onInput={this.onFilesSelected}
                        />
                      )}
                      <label className={browseButtonLabelClass} htmlFor={`upload-${index}-file`}>
                        <Button
                          className={uploadParametersCompleted ? browseButtonClass : browseButtonClass + ' disabled'}
                          variant="outline"
                          component="span"
                          size="large"
                          disabled={!uploadParametersCompleted}
                          style={uploadParametersCompleted ? null : { backgroundColor: '#E0E1E2 !important'}}
                        >
                          Browse
                        </Button>
  
                      </label>
                    </>
                    )}
                    {readOnly && (
                      <>
                        <div>
                          No files can be uploaded in readonly mode
                        </div>
                      </>
                    )}
                  </div>
                  {category.uploadParameters && !readOnly && (
                    <div className={uploadParametersClass}>
                      <div className={uploadParameterHeader}>
                        <p><b>Parameters</b></p>
                        <p>In order to upload the files, the following information is needed:</p>
                      </div>
                      <div className={uploadParameterSelection}>
                        {category.uploadParameters.map(param => (
                          <UploadParameters
                            uploadParameter={param}
                            changeSelectedValue={this.changeSelectedValue}
                            parentState={this.state}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={topBarClass}>
                {files.length > 0 && (
                  <Button
                    className="qri-button primary"
                    onClick={this.showDeleteFilesModal}>
                    Delete all files
                  </Button>
                )}

                {containsFailedFiles && (
                  <Button
                    className="qri-button primary"
                    onClick={this.deleteAllFailedFiles}>
                    Delete Failed files
                  </Button>
                )}
                <div style={{ 'float': 'right', 'padding-top': '9px' }}>
                  {files.length} files
                </div>
              </div>
            </>)}
        </>)}

        {/* {
          !fileExists && uploadingFiles.length === 0 && failedUploads.length === 0 && category.type === CategoryTypes.SINGLE && (
            <div
              className={`${uploaderContainerClass} ${singleUploadClass} align-vertical`}
              onDragOver={this.onDragOver}
              onDragLeave={this.onDragLeave}
              onDrop={this.onDrop}
            >
              <div className={indexClass}>
                {index}
              </div>
              <div className={infoClass}>
                <div className={categoryTextClass}>
                  <span>{category.text}</span>
                  {category.required && (
                    <span className={requiredTextClass}>*</span>
                  )}
                </div>
                <div className={optionsClass}>
                  <span>
                    <span className={fileExtensionClass}>
                      {category.fileFormat}
                    </span> file -
                </span>
                  <Link
                    onClick={() => this.handleTemplateDownloadClick(category.templateName)}
                    href="#"
                  > DOWNLOAD TEMPLATE
                </Link>
                </div>
              </div>
              { category.uploadParameters && (
                <div className={uploadParametersClass}>
                <div className={uploadParameterHeader}>
                  <p><b>Parameters</b></p>
                  <p>In order to upload the files, the following information is needed:</p>
                </div>
                <div className={uploadParameterSelection}>
                  {category.uploadParameters.map(param => (
                      <UploadParameters
                        uploadParameter={param}
                        changeSelectedValue={this.changeSelectedValue}
                        parentState={this.state}
                      />
                    ))}
                </div>
              </div>
              )}
              { !category.uploadParameters && (<div></div>) }
              {!readOnly && (
                <>
                  <div className={helperTextClass}>
                    Drag files here or
                </div>
                  <div>
                    <input
                      id={`upload-${index}-file`}
                      type="file"
                      className={browseButtonInputClass}
                      onChange={this.onFilesSelected}
                    />
                    <label className={browseButtonLabelClass} htmlFor={`upload-${index}-file`}>
                      <Button className={browseButtonClass} variant="outline" component="span" size="large">
                        Browse
                      </Button>
                    </label>
                  </div>
                </>
              )}
              {readOnly && (
                <>
                  <div className={helperTextClass}>
                    No file was uploaded for this category
                </div>
                </>
              )}
            </div>
          )
        } */}
        {
          uploadingFiles.map((file) => {
            return (
              <div>
                <FileViewer
                  versionId={versionId}
                  datasetId={datasetId}
                  uploadPromise={file.promise}
                  name={file.file.originalFilename}
                  date={file.file.dateCreated}
                  extension={file.file.extension}
                  category={category}
                  required={category.required}
                  openFileInformationDrawer={openFileInformationDrawer}
                  openFileDescriptionDrawer={openFileDescriptionDrawer}
                />
              </div>
            )
          })
        }

        {
          failedUploads.map((file) => {
            console.log('faileduploaddddddd')
            return (
              <div>
                <FileViewer
                  versionId={versionId}
                  datasetId={datasetId}
                  name={file.file.originalFilename}
                  date={file.file.dateCreated}
                  extension={file.file.extension}
                  category={category}
                  onReplaceDocument={this.onFilesSelected}
                  required={category.required}
                  failedUpload={true}
                  openFileInformationDrawer={openFileInformationDrawer}
                  openFileDescriptionDrawer={openFileDescriptionDrawer}
                />
              </div>
            )
          })
        }

        {
          files.map((file) => {
            return (
              <div
                key={file._id}
                file-id={file._id}
              >
                <FileViewer
                  versionId={versionId}
                  name={file.originalFilename}
                  id={file._id}
                  datasetId={datasetId}
                  createdBy={file.createdBy}
                  date={file.dateCreated}
                  extension={file.extension}
                  category={category}
                  categoryType={category.type}
                  templateName={category.templateName}
                  onReplaceDocument={this.onFilesSelected}
                  validationPassed={file.validationPassed}
                  uploadParameters={file.uploadParameters}
                  validationComplete={file.validationComplete}
                  validationError={file.validationError}
                  refreshData={refreshData}
                  readOnly={readOnly}
                  required={category.required}
                  isDatasetValid={isDatasetValid}
                  openFileInformationDrawer={openFileInformationDrawer}
                  openFileDescriptionDrawer={openFileDescriptionDrawer}
                />
              </div>
            )
          })
        }
        <ContinueModal
          showContinueModal={showFileUploadModal}
          onModalCancel={this.resetState}
          onModalContinue={this.closeAndSend}
          header={"File Zone Names"}
          children={fileUploadModalChildren}
        >
        </ContinueModal>
      </div >
    )
  }
}

export default GeologicalModels
