import * as THREE from "three"

import Surface from './Surface'
import * as WaterfloodService from '../../services/waterfloodService'

class SurfaceManager {
  constructor(cfg) {
    cfg = cfg || {}
  
    this.caseID = cfg.case || null
    this.property = cfg.property || null   
    this.reservoir = cfg.reservoir || null
    this.surfacesList = cfg.surfaces || []
    this.displayMesh = cfg.displayMesh !== undefined ? cfg.displayMesh : true
    this.transparency = cfg.transparency !== undefined ? cfg.transparency : 100

    this.surfacesGroup = new THREE.Group()
    this.surfaces = []

    this.bounds = this.getBounds()
    this.boundingBox = this.boundingBox(this.bounds)

    //this.buildSurfaces()
  }

  async buildSurfaces(surfaces) {
    const me = this
    const surfacesRef = this.surfaces

    /* Remove unused surfaces */
    this.surfacesGroup.children.forEach((surface) => {
        if(!surfaces.includes(surface.name)) {
            me.surfacesGroup.remove(surface)
        }
    })

    const status = await Promise.all(
        surfaces.map(async (surfaceName) => {
            let surface = surfacesRef.find(s => s.name === surfaceName)
            if(surface) {
                me.surfacesGroup.add(surface.surfaceGroup)
                return true
            } else {    
                //await Promise.all([someCall(), anotherCall()]);
                surface = await this.buildSurface(surfaceName)
                me.surfaces.push(surface)
                me.surfacesGroup.add(surface.surfaceGroup)
                return true
            }
        })
    )

    return status
    /*
    surfaces.forEach((surfaceName) => {
        let surface = surfacesRef.find(s => s.name === surfaceName)
        if(surface) {
            me.surfacesGroup.add(surface.surfaceGroup)
        } else {    
            //await Promise.all([someCall(), anotherCall()]);
            this.buildSurface(surfaceName).then((surface) => {
                me.surfaces.push(surface)
                me.surfacesGroup.add(surface.surfaceGroup)
            })
        }
    })
    */
  }

  async updateSurfaces(surfaces) {
      const status = await this.buildSurfaces(surfaces)
      return status
  }

  async buildSurface(surface) {
    const vertices = await WaterfloodService.getV(this.caseID, surface)
    const faces = await WaterfloodService.getF2V(this.caseID, surface)

    const imgLoader = new THREE.TextureLoader()
    const image = imgLoader.load(`/api/waterflood/case/${this.caseID}/grid/${surface}/property/${this.property}`)

    const surfaceObj = new Surface({
    name: surface,
    image,
    faces,
    vertices,
    transparency: this.transparency,
    reservoir: this.reservoir,
    displayMesh: this.displayMesh,
    })

    return surfaceObj
  }

  showMesh(showMesh) {
    this.displayMesh = showMesh
    if (this.surfaces.length) {
      this.surfaces.forEach((s) => {
        s.displayWireframe(showMesh)
      })
    }
  }

  updateTransparency(transparency) {
    if (this.surfaces.length) {
      this.surfaces.forEach((s) => {
        s.setTransparency(transparency)
      })
    }
  }

  updateProperty(property) {
    this.property = property

    if (this.surfaces.length) {
        this.surfaces.forEach((s) => {
          const surface = s.name
          const imgLoader = new THREE.TextureLoader()
          const image = imgLoader.load(`/api/waterflood/case/${this.caseID}/grid/${surface}/property/${this.property}`)

          s.updateProperty(image)
        })
      }
    /*
    this.property = property
    this.clearSurface()
    this.buildSurface()
    */
  }

  updateVScale(scale) {
    if (this.surfaceGroup) {
      //this.surface.setZExaggeration(scale)
      /*
      var Dx = this.surfaceGroup.range.Dx;
      var Dy = this.surfaceGroup.range.Dy;
      var Dz = this.surfaceGroup.range.Dz;
      */

      var zExaggeration = Math.round(Math.sqrt(Dx*Dx + Dy*Dy)/Dz/6);
      this.surfaceGroup.scale.set(-1,1, scale)
    }
  }

  clearSurface() {
    this.surfaceGroup.children.forEach((obj) => {
      obj.geometry.dispose()
      obj.material.dispose()
      this.surfaceGroup.remove(obj)
    })
  }

  clearTrajectories() {
    this.trajectoryGroup.children.forEach((obj) => {
      obj.geometry.dispose()
      obj.material.dispose()
      this.trajectoryGroup.remove(obj)
    })
  }

  getBounds() {
    const vertices = this.reservoir.xyBoundary
    return vertices.reduce((accum, curr) => {
      return {
        x: {
          min: Math.min(accum.x.min, curr.x),
          max: Math.max(accum.x.max, curr.x),
        },
        y: {
          min: Math.min(accum.y.min, curr.y),
          max: Math.max(accum.y.max, curr.y),
        },
      }
    }, { x: { min: Infinity, max: -Infinity }, y: { min: Infinity, max: -Infinity } })
  }

  hasLoadedSurface(surfaceName) {
      return this.surfaces.find(x => x.name === surfaceName)
  }

  // eslint-disable-next-line class-methods-use-this
  boundingBox(bounds) {
    const boundingGeometry = new THREE.PlaneGeometry(bounds.x.max - bounds.x.min, bounds.y.max - bounds.y.min)
    boundingGeometry.computeBoundingBox()
    return boundingGeometry.boundingBox
  }
}

export default SurfaceManager