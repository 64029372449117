import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import Card from '../../common/Card'
import OpportunityCatalogTable from '../../components/OpportunityCatalogTable'
import './OpportunityCatalog.scss'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class OpportunityCatalog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shouldRenderCatalog: false,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    setTimeout(() => {
      this.setState({
        shouldRenderCatalog: true,
      })  
    }, 100)     
  }



  renderOpportunityCatalogTable() {
    const { opportunityData, updateOpportunityComments } = this.props

    if (opportunityData && this.state.shouldRenderCatalog) {
      return (
        <OpportunityCatalogTable
          data={opportunityData}
          updateOpportunityComments={updateOpportunityComments}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }



  render() {
    return (
      <div className="opportunity-catalog">
        <Card title="Opportunity Catalog">
          { this.renderOpportunityCatalogTable() }
        </Card>
      </div>
    )
  }
}

export default OpportunityCatalog
