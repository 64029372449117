import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp'
import { generateSRODownloadResultsUrl, generateSRODownloadResultsUrlOld, generateSRODownloadResultsDCA } from '../../../../../services/fileService'

import './DownloadLink.scss'

const baseClass = `download-csv-link`
const iconClass = `${baseClass}__icon`
const textClass = `${baseClass}__text`
const titleClass = `${textClass}__title`
const descriptionClass = `${textClass}_description`

const DownloadLink = ({ title, description, downloadType, caseId, moduleName, old }) => {

  const onClick = async (caseId, moduleName, downloadType, old) => {
    const link = document.createElement('a')
    
    console.log('caseId', caseId, 'moduleName', moduleName, 'downloadType', downloadType, 'old', old)
    let url
    if(old){
      //Old cases only have 1 .csv file to download
      url  = await generateSRODownloadResultsUrlOld(caseId, moduleName)
    } else if(downloadType === 'declineCurveAnalysis'){
      //This is a little different, because we're downloading a .zip file
      url = generateSRODownloadResultsDCA(caseId, moduleName, downloadType)
      link.setAttribute('download', 'Decline Curve Analysis.zip')
    } else {
      url  = await generateSRODownloadResultsUrl(caseId, moduleName, downloadType)
    }
    
    link.href = url
    link.target = '_blank'
    link.click()
  }

  return (
    <div className={baseClass}>
      <div className={iconClass} onClick={() => onClick(caseId, moduleName, downloadType, old)}>
        <GetAppIcon style={{ fontSize: 35, width: '100%', color: '#1665D8' }}/>
      </div>
      <div className={textClass}>
        <div className={titleClass}>{title}</div>
        <div className={descriptionClass}>{description}</div>
      </div>
    </div>
  )
}

export default DownloadLink