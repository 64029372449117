import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Select from 'react-select'

import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Slider from '@material-ui/core/Slider'
import Divider from '@material-ui/core/Divider'

import { 
  togglePieCharts,
  toggleTrajectories,
  setPieSize,
  setTrimWells,
  showMesh,
  setProperty,
  setTransparency,
  setVScale,
  setSurface,
} from '../../../../redux/actions/waterfloodCase'

import { getCaseSurfaces } from '../../../../services/waterfloodService'

const useStyles = makeStyles({
  card: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.04)',
    border: '1px solid #eaf0f4',
    backgroundColor: '#ffffff',
  },
  header: {
    flex: ' 0 1 auto',
  },
  content: {
    flex: '1 1 auto',
  },
})

const Settings4D = (props) => {

  const {
    caseId,
    rxTogglePieCharts, showPieCharts,
    rxToggleTrajectories, showTrajectories, 
    rxsetPieSize, pieSize,
    rxsetTrimWells, trimWells,
    rxShowMesh, showMesh,
    rxsetProperty, property,
    rxsetTransparency, transparency,
    rxsetVScale, verticalScale,
    rxsetSurface, surface,
    match
  } = props

  const surfaceProperties = [
    { value: 'z', label: 'Z' },
    { value: 'phi', label: 'Phi' },
    { value: 'sw', label: 'SW' },
    { value: 'perm', label: 'Perm' },
    { value: 'ntg', label: 'NTG' },
  ]

  const [surfaces, setSurfaces] = useState([])
  const classes = useStyles()

  useEffect(() => {
    getCaseSurfaces(caseId).then((surf) => {
      const values = surf.map(val => ({ value: val, label: val }))
      setSurfaces(values)
    })
  }, [])

  return (
    <div>
      <FormGroup row>
        <FormControlLabel
          className="full-width"
          labelPlacement="start"
          label="Show Well Pie Charts"
          control={(
            <Switch
              checked={showPieCharts}
              onChange={() => rxTogglePieCharts()}
              value="showPieCharts"
              size="small"
              color="primary"
            />
          )}
        />
      </FormGroup>
      <FormGroup row>
        <Typography gutterBottom>Pie Size</Typography>
        <Slider
          onChange={(e, val) => rxsetPieSize(val)}
          aria-label="pie-size"
          value={pieSize}
        />
      </FormGroup>

      <FormGroup row>
        <FormControlLabel
          className="full-width"
          labelPlacement="start"
          label="Show Well Trajectories"
          control={(
            <Switch
              checked={showTrajectories}
              onChange={() => rxToggleTrajectories()}
              value="show-trajectories"
              size="small"
              color="primary"
            />
          )}
        />
      </FormGroup>
      <FormGroup row>
        <Typography gutterBottom>Trim Well %</Typography>
        <Slider
          onChange={(e, val) => rxsetTrimWells(val)}
          aria-label="trim-well"
          value={trimWells}
        />
      </FormGroup>
      <Divider />
      <FormGroup row>
        <FormControlLabel
          className="full-width"
          labelPlacement="start"
          label="Show Mesh"
          control={(
            <Switch
              checked={showMesh}
              onChange={(e, val) => rxShowMesh(val)}
              value="show-mesh"
              size="small"
              color="primary"
            />
          )}
        />
      </FormGroup>

      <FormGroup row>
        <Typography gutterBottom>Surface Transparency</Typography>
        <Slider
          onChange={(e, val) => rxsetTransparency(val)}
          aria-label="surface-transparency"
          value={transparency}
        />
      </FormGroup>

      {/* 
      <FormGroup row>
        <Typography gutterBottom>Vertical Rescaling</Typography>
        <Slider
          onChange={(e, val) => rxsetVScale(val)}
          aria-label="vertical-scale"
          value={verticalScale}
        />
      </FormGroup>
      */}

      <FormGroup>
        <Typography gutterBottom>Select Property</Typography>
        <div>
          <Select
            autoFocus
            isMulti={false}
            onChange={(e) => rxsetProperty(e.value)}
            options={surfaceProperties}
            placeholder="Select"
            value={surfaceProperties.find(i => i.value === property)}
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Typography gutterBottom>Select Surfaces</Typography>
        <div>
          <Select
            autoFocus
            isMulti={true}
            onChange={e => (!!e ? rxsetSurface(e.map(mod => mod.value)) : null)}
            options={surfaces}
            placeholder="Select"
            value={surface.map(mod => surfaces.find(i => i.value === mod)).toJS()}
          />
        </div>
      </FormGroup>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  rxTogglePieCharts: () => dispatch(togglePieCharts()),
  rxToggleTrajectories: () => dispatch(toggleTrajectories()),
  rxsetPieSize: value => dispatch(setPieSize(value)),
  rxsetTrimWells: value => dispatch(setTrimWells(value)),
  rxShowMesh: value => dispatch(showMesh(value)),
  rxsetProperty: value => dispatch(setProperty(value)),
  rxsetTransparency: value => dispatch(setTransparency(value)),
  rxsetVScale: value => dispatch(setVScale(value)),
  rxsetSurface: value => dispatch(setSurface(value)),
})

const mapStateToProps = state => ({
  showPieCharts: state.getIn(['waterfloodCase', 'viewer', 'settings', 'showPieCharts']),
  showTrajectories: state.getIn(['waterfloodCase', 'viewer', 'settings', 'showTrajectories']),
  transparency: state.getIn(['waterfloodCase', 'viewer', 'settings', 'transparency']),
  verticalScale: state.getIn(['waterfloodCase', 'viewer', 'settings', 'verticalScale']),
  pieSize: state.getIn(['waterfloodCase', 'viewer', 'settings', 'pieSize']),
  trimWells: state.getIn(['waterfloodCase', 'viewer', 'settings', 'trimWells']),
  showMesh: state.getIn(['waterfloodCase', 'viewer', 'settings', 'showMesh']),
  property: state.getIn(['waterfloodCase', 'viewer', 'settings', 'property']),
  surface: state.getIn(['waterfloodCase', 'viewer', 'settings', 'surface']),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings4D))
