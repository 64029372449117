import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'autobind-decorator'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import CommentList from './CommentList'
import CommentForm from './CommentForm'
import CommentSearch from './CommentSearch'
import Pagination from './Pagination'
import Card from '../../layout/Visualization/common/Card'


@autobind class CommentBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activePage: 1,
      data: [],
      totalCount: 0,
      itemsPerPage: 5,
      error: null,
      author: '',
      comment: '',
      updateId: null,
      loading: false,
      searchText: '',
    }
    this.pollInterval = null
  }

  componentDidMount() {
    this.loadCommentsFromServer()
    /*
    if (!this.pollInterval) {
      this.pollInterval = setInterval(this.loadCommentsFromServer, 2000)
    }
    */
  }

  componentDidUpdate(prevProps) {
    const { threadId } = this.props
    if (prevProps.threadId !== threadId) {
      this.loadCommentsFromServer()
    }
  }

  componentWillUnmount() {
    /*
    if (this.pollInterval) clearInterval(this.pollInterval)
    this.pollInterval = null
    */
  }

  onChangeText(e) {
    const newState = { ...this.state }
    newState[e.target.name] = e.target.value
    this.setState(newState)
  }

  onPageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.loadCommentsFromServer()
    })
  }

  onUpdateComment(id) {
    const { data } = this.state
    const oldComment = data.find(c => c._id === id)
    if (!oldComment) return
    this.setState({ author: oldComment.author, text: oldComment.text, updateId: id })
  }

  onDeleteComment(id) {
    const { data, totalCount } = this.state
    const { updateOpportunityComments, threadId } = this.props
    const i = data.findIndex(c => c._id === id)
    const newData = [
      ...data.slice(0, i),
      ...data.slice(i + 1),
    ]

    updateOpportunityComments(threadId, totalCount - 1)
    this.setState({ data: newData, totalCount: totalCount - 1})
    fetch(`/api/comments/${id}`, { method: 'DELETE' })
      .then(res => res.json()).then((res) => {
        if (!res.success) this.setState({ error: res.error })
      })
  }

  submitNewComment() {
    const { threadId, updateOpportunityComments } = this.props
    const { text, data, totalCount } = this.state
    fetch('/api/comments', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ threadId, text }),
    }).then(res => res.json()).then((res) => {
      if (!res.success) this.setState({ error: res.error.message || res.error })
      else {
        const newData = [res.inserted, ...data]
        updateOpportunityComments(threadId, totalCount + 1)
        this.setState({ data: newData, author: '', text: '', error: null, totalCount: totalCount + 1 })
      }
    })
  }

  submitUpdatedComment() {
    const { author, text, updateId } = this.state
    fetch(`/api/comments/${updateId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ author, text }),
    }).then(res => res.json()).then((res) => {
      if (!res.success) this.setState({ error: res.error.message || res.error })
      else this.setState({ author: '', text: '', updateId: null })
    })
  }

  loadCommentsFromServer() {
    const { threadId } = this.props
    const { activePage, itemsPerPage, searchText } = this.state
    const params = obj => Object.keys(obj).map(key => key + '=' + obj[key]).join('&')
    const query = params({ page: activePage, pageSize: itemsPerPage, searchText })

    if (threadId) {
      this.setState({ loading: true })

      fetch(`/api/comments/${threadId}?${query}`)
        .then(data => data.json())
        .then((res) => {
          if (!res.success) this.setState({ error: res.error })
          else this.setState({ loading: false, data: res.data, totalCount: res.totalDocumentCount })
        })
    }
  }

  submitComment(e) {
    e.preventDefault()
    const { text, updateId } = this.state
    if (!text) return
    if (updateId) {
      this.submitUpdatedComment()
    } else {
      this.submitNewComment()
    }
  }

  onChangeSearch(e) {
    const newState = { ...this.state }
    newState.searchText = e.target.value
    this.setState(newState)
  }

  submitSearch(e) {
    e.preventDefault()
    // const { searchText } = this.state
    // if (!searchText || searchText === '') return
    this.loadCommentsFromServer()
  }

  render() {
    const { threadId, userId } = this.props
    const { loading, activePage, totalCount, itemsPerPage, data, author, searchText, text, error } = this.state

    if (!threadId) {
      return null
    }

    const title = (
      <Grid container spacing={0}>
        <Grid item xs>
          Comments
        </Grid>
        <Grid item xs>
          <span className="view-all">
            {`View All (${totalCount})`}
          </span>
        </Grid>
      </Grid>
    )

    return (
      <div className='comments'>
        <Card title={title}>
          <div className="comments-container container">
            <div className="form">
              <CommentForm
                author={author}
                text={text}
                handleChangeText={this.onChangeText}
                submitComment={this.submitComment}
              />
              {error && <p>{error}</p>}
            </div>
            {!loading ? (
              <>
                {!data || data.length ? (
                  <>
                    {/*<div className="search-form">
                      <CommentSearch
                        searchText={searchText}
                        submitSearch={this.submitSearch}
                        handleChangeSearch={this.onChangeSearch}
                      />
                    </div>*/}
                    <div className="comments">
                      <CommentList
                        data={data}
                        userId={userId}
                        handleDeleteComment={this.onDeleteComment}
                        handleUpdateComment={this.onUpdateComment}
                      />
                    </div>
                    <div className="pagination">
                      <Pagination
                        data={data}
                        activePage={activePage}
                        totalItems={totalCount}
                        itemsPerPage={itemsPerPage}
                        onPageChange={this.onPageChange}
                      />
                    </div>
                  </>
                ) :  (
                  <div className="comment-notice">
                    <Typography align="center" display="inline" variant="body1">No comments left yet.</Typography>
                  </div>
                )}
              </>
            ) : <CircularProgress className="progress" />}
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(CommentBox)
