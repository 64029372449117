// These are used in components/GroupSelector/groupConfiguration.js
//this needs to get moved to dataset maps.

const UploadParameters = {
  'FAULT_TRACES': () => ([
    {
      name: 'shouldConvertUnits',
      fileLabel: 'Convert XYZ from feet to meters?',
      label: 'Convert XYZ from feet to meters?',
      helpText: null,
      type: 'radio',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false}
      ]
    }
  ]),
  'WELL_NAME_FIELD': () => {
    return [
      {
        name: 'wellNameField',
        fileLabel: 'Well name field',
        label: 'Well Name Field',
        helpText: null,
        type: 'dropdown',
        options: [
          { label: 'UWI', value: 'UWI' },
          { label: 'WELL', value: 'WELL' },
          { label: 'API', value: 'API' },
        ],
      }
    ]
  },
  'CONVERTABLE_GRIDS': () => {
    return [
      {
        name: 'isFileNameZoneName',
        fileLabel: 'File name same as zone name',
        label: 'Is your file name the same as the zone name?',
        helpText: null,
        type: 'radio',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ], 
      },
      {
        name: 'shouldConvertUnits',
        fileLabel: 'Convert from feet to meters',
        label: 'Convert grid from feet to meters?',
        helpText: null,
        type: 'radio',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
    ]
  },
  'GRIDS': () => {
    return [
      {
        name: 'isFileNameZoneName',
        fileLabel: 'File name same as zone name',
        label: 'Is your file name the same as the zone name?',
        helpText: null,
        type: 'radio',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
    ]
  },
  '3D': () => {
    return [
      {
        name: 'shouldConvertUnits',
        fileLabel: 'Convert from feet to meters',
        label: 'Convert model from feet to meters?',
        helpText: null,
        type: 'radio',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      {
        name: 'propertiesToConvert',
        fileLabel: 'Properties converted',
        label: 'Properties to Convert',
        helpText: 'Type to enter a new property.  Property variable names must exactly match those described in the user manual.',
        condition: 'parentState["shouldConvertUnits"] === "false" || !parentState["shouldConvertUnits"]',
        type: 'dropdown-custom',
        options:[
          { label: 'Z', value: 'Z' },
          { label: 'DTF', value: 'DTF' }
        ]
      },
      {
        name: 'nullValue',
        fileLabel: 'Null Value',
        label: 'What is the value for "null"?',
        helpText: null,
        type: 'text',
      }
    ]
  }
}

export default UploadParameters
