import API from '../../lib/api-store'

export const login = user => ({ type: 'LOGIN', user })
export const logout = () => ({ type: 'LOGOUT' })
export const addCaseToFavorites = value => ({ type: 'ADD_FAVORITE_CASE', value })
export const removeCaseFromFavorites = value => ({ type: 'REMOVE_FAVORITE_CASE', value })
export const editCaseNameAndDescription = (caseId, name, description) => ({ type: 'EDIT_NAME_AND_DESCRIPTION', caseId, name, description })
export const pinCaseOpportunity = (opportunityId, caseId) => (dispatch) => {
  return API.pinOpportunity(opportunityId, caseId)
    .then((opportunity) => {
      dispatch({ type: 'PIN_CASE_OPPORTUNITY', opportunity })
    })
}

export const unpinCaseOpportunity = (opportunityId, caseId) => (dispatch) => {
  return API.unpinOpportunity(opportunityId, caseId)
    .then(() => {
      dispatch({ type: 'UNPIN_CASE_OPPORTUNITY', opportunityId })
    })
}