import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class InterpolationSettings extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Interpolation Settings
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Time Weight<br/>
          <span className='blue'>Comments:</span> Weight of time dimension. For example, if this is set to 0.5 and 'Distance Weight' is also 0.5 then time and distance will be weighted equally.<br/>
        </div>
        <div className="content-row"> 
          Distance Weight<br/>
          <span className='blue'>Comments:</span> Weight of spatial dimension. For example, if this is set to 0.5 and 'Time Weight' is also 0.5 then time and distance will be weighted equally. <br/>
        </div>
        <div className="content-row"> 
          Method<br/>
          <span className='blue'>Options:</span> IDW, Gaussian<br/>
        </div>
        <div className="content-row"> 
          <span className='blue'>Comments:</span> Interpolation weighting function. Inverse distance weighting (IDW) is a type of deterministic method for multivariate interpolation with a known scattered set of points.<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(InterpolationSettings)
