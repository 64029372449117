import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class NeighborhoodSettings extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          NeighborhoodSettings
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Temporal Scale<br/>
          <span className='blue'>Comments:</span> Number of recent years<br/>
        </div>
        <div className="content-row"> 
          Type<br/>
          <span className='blue'>Options:</span> Radius, Points<br/>
          <span className='blue'>Comments:</span> This setting determines whether the neighborhood data is restricted to neighbors within a certain distance (radius) or if the neighborhood is restricted to a certain number of closest neighbors, regardless of distance (points).<br/>
        </div>
        <div className="content-row"> 
          Match Well Type<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> TIf on, this will limit neighborhood wells to those of same well type as target opportunity (i.e. only vertical wells, etc.)<br/>
        </div>
        <div className="content-row"> 
          Use Zone Allocated Rates<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> If on, uses the allocated production rates specific to each zone. Incorrect allocation will almost certainly lead to wrong initial rate forecasts when using statistical methods. It is recommended to use PLT data for allocation when available.<br/>
        </div>
        <div className="content-row"> 
          Neighorhood Performance<br/>
          <span className='blue'>Options:</span> Initial Rates, Current Rates<br/>
          <span className='blue'>Comments:</span> This defines the neighborhood to use to calculate the probability of exceedance (POE), which is a statistical metric describing the probability that a particular value (in this case, the predicted IP) will be met or exceeded.<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(NeighborhoodSettings)
