const all = [
  {
    title: "Initial Neighbor Data",
    description: "This file contains the initial production (IP) rates of the neighborhood for each target zone at each sweet spot (used for STI/TNSTI forecasts). Neighbor rates are zone-specific (i.e. the result of production allocation). The extent of the neighborhood was defined under Production Forecast/Neighborhood Settings.",
    downloadType: "SRO_Initial_Neighbor_Data.csv",
  },
  {
    title: "Current Neighbor Data",
    description: "This file contains the current production rates of the neighborhood for each target zone at each sweet spot (used for NFR/TNNFR forecasts). Neighbor rates are zone-specific (i.e. the result of production allocation). The extent of the neighborhood was defined under Production Forecast/Neighborhood Settings.",
    downloadType: "SRO_Current_Neighbor_Data.csv",
  },
  {
    title: "Production Allocation",
    description: "This file contains the results of the production allocation so you can review the production by zone through time for every well.",
    downloadType: "SRO_Allocated_Production.csv",
  },
  {
    title: "Decline Curve Analysis",
    description: "The files containing the field and well decline results, and the decline curve parameters.",
    downloadType: "declineCurveAnalysis",
    //Three Files
  },
  {
    title: "Machine Learning Training Attributes",
    description: "You can use this file in Speedwise Machine Learning to train a model, generate API key, and use the API for future production forecast predictions.",
    downloadType: "mlTrainingData.csv",
  },
  {
    title: "Generated Grids",
    description: "SRO generated grids.",
    downloadType: "SRO_Generated_Grids.zip",
  },
]

/*
  generateWorkovers = recompletions
  verticals = verticals (new drills)
  horizontals = horizontals
*/

export default {
  generateWorkovers: [
    ...all, 
    {
      title: "Opportunities",
      description: "This file contains the list of recompletion opportunities and their details (e.g.  locations, zones, geologic attributes, IP forecasts, etc.).",
      downloadType: "SRO_Recompletion_Opportunities.csv",
    },
    {
      title: "Drainage Analysis",
      description: "This file contains the results of the drainage analysis so you can review the drainage radii and drainage index for every producing zone in each well.",
      downloadType: "SRO_Drainage_Summary.csv"
    },
    {
      title: "Recompletion Intervals",
      description: "SRO recompletion intervals",
      downloadType: "SRO_Recomp_Intervals.csv"
    }
  ],
  verticals: [
    ...all,
    {
      title: "Opportunities Summary",
      description: "This file contains the list of sweet spots and their summary details (e.g. locations, sum of IP forecasts for all identified target zones, etc.).",
      downloadType: "SRO_Vertical_Opportunities_Summary.csv",
    },
    {
      title: "Opportunities Detail",
      description: "This file contains the detail for all penetrated zones of the sweet spots, (e.g. geologic attributes, IP forecasts, etc.).",
      downloadType: "SRO_Vertical_Opportunities_Detail.csv",
    },
    {
      title: "Drainage Analysis",
      description: "This file contains the results of the drainage analysis so you can review the drainage radii and drainage index for every producing zone in each well.",
      downloadType: "SRO_Drainage_Summary.csv"
    },
  ],
  horizontals: [
    ...all,
    {
      title: "Opportunities",
      description: "This file contains the list of horizontal targets and their details (e.g. locations, geologic attributes, IP forecasts, etc.).",
      downloadType: "SRO_Horizontal_Opportunities.csv",
    },
    {
      title: "Horizontal Trajectories",
      description: "SRO horizontal trajectories",
      downloadType: "SRO_Horizontal_Trajectories.csv"
    },
    {
      title: "Horizontal Model File",
      description: "Download comprehensive 3D model with all properties SRO created",
      downloadType: "SRO_Horizontal_Model"
    }
  ],
  unconventionals: [
      {
        title: "Machine Learning Training Attributes",
        description: "You can use this file in Speedwise Machine Learning to train a model, generate API key, and use the API for future production forecast predictions.",
        downloadType: "mlTrainingData.csv",
      },
  ]
}