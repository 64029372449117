import React, { Component } from 'react'
import autobind from 'autobind-decorator'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './TabSelector.scss'

const containerClass = 'tab-selector'

const individualTabClass = `${containerClass}__tab-option`
const selectedTabClass = `${individualTabClass}--selected`

const tabContentClass = `${individualTabClass}__content`
const tabContentIconClass = `${tabContentClass}__icon`
const tabContentTextClass = `${tabContentClass}__text`

@autobind class TabSelector extends Component {
  render() {
    const { tabs, selectedTab, onClickTab } = this.props

    return (
      <div className={containerClass}>
        {tabs.map((value) => {
          return (
            <div
              key={value.name}
              className={`${individualTabClass} ${selectedTab.name === value.name ? selectedTabClass : ''}`}
              onClick={() => onClickTab(value)}
              onKeyPress={null}
              role="button"
              tabIndex="0"
            >
              <div className={tabContentClass}>
                {value.icon && (
                  <div className="tab-option-icon-background">
                    <FontAwesomeIcon icon={value.icon} className={tabContentIconClass} />
                  </div>
                )}
                <span className={tabContentTextClass}>
                  {value.text}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default TabSelector
