import React, { Component } from 'react'
import autobind from 'autobind-decorator'


@autobind class KPI extends Component {
  render() {
    let { name, cur, total, color, decimals } = this.props

    if (cur) {
      cur = cur.toFixed(decimals)
    }

    return (
      <div style={{ height: '60px', padding: '15px', textAlign: 'center'}}>
        <div style={{color: color, fontSize: '14px', fontWeight: 'bold'}}>
          {name}
        </div>
        <div>
          {cur} of {total} used
        </div>
      </div>
    )
  }
}

export default KPI
