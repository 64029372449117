import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'

import BasicZoneInfo from './Global/BasicZoneInfo'
import LogAlias from './Global/LogAlias'
import LogCleaning from './Global/LogCleaning'
import LogSettings from './Global/LogSettings'
import GridCleaning from './Global/GridCleaning'
import OtherGlobalSettings from './Global/OtherGlobalSettings'

import DeclineCurveAnalysis from './EngineeringAnalytics/DeclineCurveAnalysis'
import FlowUnitAllocation from './EngineeringAnalytics/FlowUnitAllocation'

import LogZoneAnalyticsOverview from './LogZoneAnalytics/LogZoneAnalyticsOverview'
import NetPay from './LogZoneAnalytics/NetPay'
import PerforatedNetPay from './LogZoneAnalytics/PerforatedNetPay'
import UncontactedNetPay from './LogZoneAnalytics/UncontactedNetPay'

import AttributeMapping from './AttributeMapping/AttributeMapping'

import DrainageAnalysisOverview from './DrainageAnalysis/DrainageAnalysisOverview'
import DrainageRadiusCorrection from './DrainageAnalysis/DrainageRadiusCorrection'
import GeneralSettings from './DrainageAnalysis/GeneralSettings'

import DarcyParameters from './ProductionForecast/DarcyParameters'
import InitialProductionRateForecast from './ProductionForecast/InitialProductionRateForecast'
import InterpolationSettings from './ProductionForecast/InterpolationSettings'
import NeighborhoodSettings from './ProductionForecast/NeighborhoodSettings'
import ProductionForecastOverview from './ProductionForecast/ProductionForecastOverview'
import ThicknessNormalized from './ProductionForecast/ThicknessNormalized'
import OtherProductionForecast from './ProductionForecast/OtherProductionForecast'


import OpportunityFiltering from './OpportunityFiltering/OpportunityFiltering'

import MapGeneration from '../../../SweetSpots/components/Settings/MapGeneration/MapGeneration'

import SweetSpotRanking from  '../../../SweetSpots/components/Settings/SweetSpotRanking/SweetSpotRanking'

import TargetSearch from  '../../../SweetSpots/components/Settings/TargetSearch/TargetSearch'


import SearchDomainDefinition from  '../../../Horizontals/components/Settings/searchDomainDefinition/SearchDomainDefinition'

import HorDrainage from  '../../../Horizontals/components/Settings/Drainage/HorDrainage'

import HorSweetSpotRanking from  '../../../Horizontals/components/Settings/SweetSpotRanking/HorSweetSpotRanking'

import HorTargetSearch from  '../../../Horizontals/components/Settings/TargetSearch/HorTargetSearch'

import InterferenceAnalysis from  '../../../Horizontals/components/Settings/InterferenceAnalysis/InterferenceAnalysis'

import HorOtherProductionForecast from  '../../../Horizontals/components/Settings/ProductionForecast/HorOtherProductionForecast'

import HorFilterFinalize from  '../../../Horizontals/components/Settings/FilterFinalize/HorFilterFinalize'

@autobind class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/:application/manual/modules/recompletions/settings/basicZoneInfo" component={BasicZoneInfo} />
          <Route path="/:application/manual/modules/recompletions/settings/logAlias" component={LogAlias} />
          <Route path="/:application/manual/modules/recompletions/settings/logCleaning" component={LogCleaning} />
          <Route path="/:application/manual/modules/recompletions/settings/logSettings" component={LogSettings} />
          <Route path="/:application/manual/modules/recompletions/settings/gridCleaning" component={GridCleaning} />
          <Route path="/:application/manual/modules/recompletions/settings/otherGlobalSettings" component={OtherGlobalSettings} />
          <Route path="/:application/manual/modules/recompletions/settings/declineCurveAnalysis" component={DeclineCurveAnalysis} />
          <Route path="/:application/manual/modules/recompletions/settings/flowUnitAllocation" component={FlowUnitAllocation} />
          <Route path="/:application/manual/modules/recompletions/settings/logZoneAnalyticsOverview" component={LogZoneAnalyticsOverview} />
          <Route path="/:application/manual/modules/recompletions/settings/netPay" component={NetPay} />
          <Route path="/:application/manual/modules/recompletions/settings/perforatedNetPay" component={PerforatedNetPay} />
          <Route path="/:application/manual/modules/recompletions/settings/uncontactedNetPay" component={UncontactedNetPay} />
          <Route path="/:application/manual/modules/recompletions/settings/attributeMapping" component={AttributeMapping} />
          <Route path="/:application/manual/modules/recompletions/settings/drainageAnalysisOverview" component={DrainageAnalysisOverview} />
          <Route path="/:application/manual/modules/recompletions/settings/drainageRadiusCorrection" component={DrainageRadiusCorrection} />
          <Route path="/:application/manual/modules/recompletions/settings/generalSettings" component={GeneralSettings} />
          <Route path="/:application/manual/modules/recompletions/settings/darcyParameters" component={DarcyParameters} />
          <Route path="/:application/manual/modules/recompletions/settings/initialProductionRateForecast" component={InitialProductionRateForecast} />
          <Route path="/:application/manual/modules/recompletions/settings/interpolationSettings" component={InterpolationSettings} />
          <Route path="/:application/manual/modules/recompletions/settings/neighborhoodSettings" component={NeighborhoodSettings} />
          <Route path="/:application/manual/modules/recompletions/settings/productionForecastOverview" component={ProductionForecastOverview} />
          <Route path="/:application/manual/modules/recompletions/settings/thicknessNormalized" component={ThicknessNormalized} />
          <Route path="/:application/manual/modules/recompletions/settings/otherProductionForecast" component={OtherProductionForecast} />
          <Route path="/:application/manual/modules/recompletions/settings/opportunityFiltering" component={OpportunityFiltering} />
          

          <Route path="/:application/manual/modules/sweetSpots/settings/mapGeneration" component={MapGeneration} />
          <Route path="/:application/manual/modules/sweetSpots/settings/sweetSpotRanking" component={SweetSpotRanking} />
          <Route path="/:application/manual/modules/sweetSpots/settings/targetSearch" component={TargetSearch} />

          <Route path="/:application/manual/modules/horizontals/settings/searchDomainDefinition" component={SearchDomainDefinition} />

          <Route path="/:application/manual/modules/horizontals/settings/horDrainage" component={HorDrainage} />

          <Route path="/:application/manual/modules/horizontals/settings/horSweetSpotRanking" component={HorSweetSpotRanking} />

          <Route path="/:application/manual/modules/horizontals/settings/horTargetSearch" component={HorTargetSearch} />

          <Route path="/:application/manual/modules/horizontals/settings/interferenceAnalysis" component={InterferenceAnalysis} />

          <Route path="/:application/manual/modules/horizontals/settings/otherProductionForecast" component={HorOtherProductionForecast} />

          <Route path="/:application/manual/modules/horizontals/settings/horFilterFinalize" component={HorFilterFinalize} />
        </Switch>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Settings)
