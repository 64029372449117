import React from 'react'

import img1 from './static/WellMonthlyInjection1.jpg'
import img2 from './static/WellMonthlyInjection2.png'

const WellMonthlyInjection = (
  <div className={'description-container'}>
  <p><b>Content:</b> Well-level monthly injection rates.</p>
  <p><b>Utility:</b> If provided, this information will be used for flow allocation purposes to different zones/intervals.</p>
  <p><b>Conventions:</b> Date: It must be the last day of the month, and in mm/dd/yyyy format</p>
  <p><b>File Format:</b> CSV template (downloadable)  (example shown in Figure 8).</p>
  <p><b>Units:</b> Template indicates what units are needed.</p>
  <p><b>Other Note:</b> Not all columns are required. See Table 8 for specifics.</p>
  <figure>
    <img src={img1} />
    <figcaption>Figure 8: Example of the first few rows of a “Well_Monthly_Injection.csv.”</figcaption>
  </figure>
  <figure>
    <figcaption>Table 8. Details for the “Well_Monthly_Injection.csv.”</figcaption>
    <img src={img2} />
  </figure>
</div>
)

export default WellMonthlyInjection