import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'
import { css } from '@emotion/core'
import { ClipLoader } from 'react-spinners'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import API from '../../../../../lib/api-store'
import OpportunityRoutes from './components/OpportunityRoutes'
import Card from '../../common/Card'
import WellLocation from '../../components/WellLocation'
import CommentBox from '../../../../common/Comments/CommentBox'

//import '../Opportunity.scss'
import PinOpportunity from '../../components/PinOpportunity';

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class UnconventionalOpportunity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shouldRenderMap: false,
      logData: null,
      time: null,
      oilRate: null,
      waterCut: null,
      gor: null,
      analogNeighborData: null,
      structSectionDataNS: null,
      structSectionDataEW: null,
      neighborLogs: null,
      selectedOffsetWells: [],
      selectedNeighborLogs: [],
      selectedWellData: [],
      modelSectionData: {},
      curPage: '',
      gridData: [],
      clickedOffTraces: [],
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    const { match, parentZone, updateZone, opportunity } = this.props
    
    const { opportunityId } = match.params
    if (opportunityId !== prevProps.match.params.opportunityId) {
      this.loadData()
    }
  }

  setClickedOffTraces(val) {
    this.setState({
      clickedOffTraces: val,
      refreshWellLocation: true
    })
  }

  setCurPage(curPage) {
    this.setState({
      curPage
    })
  }

  loadData() {   
    const { opportunityData, match, datasetId, zone } = this.props
    const { caseId, opportunityId } = match.params
  
    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const wellId = singleOpportunity.name || singleOpportunity.wellId
    const opportunityType = singleOpportunity.type || 'recompletion'
    const zoneFetch = singleOpportunity.zone
    const selectedOffsetWells = singleOpportunity.OffsetWells
      ? singleOpportunity.OffsetWells.slice(0, 3).sort((a, b) => a.distance - b.distance).map(w => w.wellId)
      : []

    this.setState({
      logData: null,
      time: null,
      oilRate: null,
      waterCut: null,
      gor: null,
      structSectionDataNS: null,
      structSectionDataEW: null,
      modelSectionData: null,
      analogNeighborData: null,
      neighborLogs: null,
      selectedOffsetWells,
    })

    API.getStructureGrids(datasetId, zoneFetch, caseId)
      .then((gridData) => {     
        this.setState({ gridData })
      })

    API.getLogs(wellId, caseId)
      .then((logData) => {
        this.setState({
          logData,
        })
      })

    API.getModelSection(wellId, caseId, opportunityType)
      .then((modelSectionData) => {        
        this.setState({ modelSectionData })
      })

    API.getStructSection(wellId, caseId, true, opportunityType)
      .then((structSectionDataNS) => {     
        this.setState({ structSectionDataNS })
      })

    API.getStructSection(wellId, caseId, false, opportunityType)
      .then((structSectionDataEW) => {     
        this.setState({ structSectionDataEW })
      })


    API.getWellProduction(wellId, datasetId, caseId)
      .then((production) => {
        const { date: time, wOPR: oilRate, wWCT: waterCut, wGOR: gor } = production

        this.setState({
          time,
          oilRate,
          waterCut,
          gor,
        })
      })

    API.getOffsetWells(opportunityId, caseId)
      .then((data) => {
        const { wells } = data

        this.setState({
          offsetWells: wells,
        })
      })

    API.getAnalogNeighborData(wellId, zoneFetch, caseId)
      .then((data) => {
        const { analogNeighborData, neighborLogs } = data

        this.setState({
          analogNeighborData,
          neighborLogs,
        })
      })

    this.loadNeighborLogs(selectedOffsetWells)

    setTimeout(() => {
      this.setState({
        shouldRenderMap: true,
      })  
    }, 100)    
  }

  makeConstantMap() {
    return (
      <Card title="Opportunity Location">
        {this.renderMap()}
      </Card>
    )
  }

  makeNavLinks() {
    const { match, opportunityData, zone } = this.props
    const { projectId, caseId, opportunityId, application } = match.params

    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const singleOpportunityId = singleOpportunity._id
    const wellId = singleOpportunity.name || singleOpportunity.wellId
    return (
      <nav className="nav-container">
        <div className="action-bar">
          <div className="title"> {wellId} (Horizontal) </div>
          <div className="pin">
            { singleOpportunity ? (
              <PinOpportunity
                opportunityId={singleOpportunity._id}
                caseId={caseId} />
            ) : null }
          </div>
        </div>
        <div className="nav-selection">
          <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}`}>Summary</NavLink>
          {/* Removing PETROPHYSICAL CHARACTERIZATION until post-release */}
          {/* <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}/petrophysical`}>Petrophysical Characterization</NavLink> */}
          <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}/production`}>Production Forecast</NavLink>
          <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}/pos`}>Relative POS</NavLink>
          <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}/model`}>Model Analysis</NavLink>
        </div>
      </nav>
    )
  }

  loadNeighborLogs(neighborList) {
    const { match } = this.props
    const { caseId } = match.params
    const { selectedWellData } = this.state

    Promise.all(
      neighborList.map(neigbor => API.getLogs(neigbor, caseId)),
    ).then((logs) => {
      this.setState({
        selectedWellData: [...selectedWellData, ...logs],
      })
    })
  }

  handleSelectNeighbor(neighbor) {
    const { match } = this.props
    const { caseId } = match.params
    const { selectedOffsetWells, selectedWellData } = this.state
    if (!selectedOffsetWells.includes(neighbor)) {
      // Fetch the log data if we haven't already
      if (!selectedWellData.find(x => x[0].wellId === neighbor)) { 
        API.getLogs(neighbor, caseId)
          .then((logData) => {
            this.setState({
              selectedWellData: [...selectedWellData, logData],
            })
          })
      }

      this.setState({
        selectedOffsetWells: [...selectedOffsetWells, neighbor],
      })
    }
  }

  handleUnselectNeighbor(neighbor) {
    const { selectedOffsetWells } = this.state
    if (selectedOffsetWells.includes(neighbor)) {
      this.setState({
        selectedOffsetWells: selectedOffsetWells.filter(s => s !== neighbor),
      })
    }
  }

  renderMap() {
    const { wellData, structureZMatrix, structureGridX,
      structureGridY,faultData, zMatrix, opportunityData, gridX, gridY, match,
      gridProperty, updateGridProperty, gridPopertyOptions, modelSectionData, zone} = this.props
    const { opportunityId } = match.params
    const { selectedNeighborLogs, analogNeighborData, curPage, clickedOffTraces } = this.state
    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const wellId = singleOpportunity.name || singleOpportunity.wellId

    if (wellData && gridX && structureGridX && faultData && singleOpportunity && this.state.shouldRenderMap) {
      const singleOpportunityData = singleOpportunity && Object.keys(singleOpportunity).length > 0 ? [singleOpportunity] : []
      
      let neighbors = []
      if (curPage === 'petro') {
        neighbors = selectedNeighborLogs
      } else if (curPage === 'production') {
        neighbors = analogNeighborData ? analogNeighborData.map(i => i.id) : []
      }

      return (
        <React.Fragment>
          <WellLocation
            wellData={wellData}
            zMatrixHeat={zMatrix}
            gridXHeat={gridX}
            gridYHeat={gridY}
            yMargin={75}
            zMatrixContour={structureZMatrix}
            gridXContour={structureGridX}
            gridYContour={structureGridY}
            faultData={faultData}
            opportunityData={singleOpportunityData}
            selectedNeighborLogs={neighbors}
            clickedOffTraces={clickedOffTraces}
            setClickedOffTraces={this.setClickedOffTraces}
          />
          <div style={{'position': 'relative', top: '-45px', width: '300px', margin: 'auto'}}>
            <Select
              options={gridPopertyOptions}
              onChange={(val) => updateGridProperty(val.value)}
              isMulti={false}
              value={gridPopertyOptions.find(i => i.value === gridProperty)}
            />
          </div>
        </React.Fragment>
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }

  render() {
    const { 
      time,
      oilRate,
      waterCut,
      gor,
      logData,
      neighborLogs,
      structSectionDataNS,
      structSectionDataEW,
      modelSectionData,
      analogNeighborData,
      offsetWells,
      selectedOffsetWells,
      selectedWellData,
      gridData,
    } = this.state

    const {
      match,
      wellData,
      opportunityData,
      structureZMatrix,
      structureGridX,
      structureGridY,
      faultData,
      realDatasetId,
      datasetId,
      updateOpportunity,
      updateOpportunityComments,
      caseObj,
    } = this.props

    const { opportunityId } = match.params
    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const wellId = singleOpportunity.name || singleOpportunity.wellId

    const map = this.makeConstantMap()

    return (
      <Grid className="opportunity-outer" container spacing={2}>
        <Grid item xs={12}>
          { this.makeNavLinks() }
        </Grid>
        <Grid item xs={12}>
          <div className='opportunity-container'>
            <OpportunityRoutes 
              map={map}
              caseId={singleOpportunity.case_id}
              wellData={wellData}
              structureZMatrix={structureZMatrix}
              structureGridX={structureGridX}
              structureGridY={structureGridY}
              faultData={faultData}
              caseObj={caseObj}
              singleOpportunity={singleOpportunity}
              time={time}
              oilRate={oilRate}
              waterCut={waterCut}
              gor={gor}
              logData={logData}
              neighborLogs={selectedWellData}
              analogNeighborData={analogNeighborData}
              structSectionDataNS={structSectionDataNS}
              structSectionDataEW={structSectionDataEW}
              wellId={wellId}
              zone={singleOpportunity.zone}
              datasetId={datasetId}
              realDatasetId={realDatasetId}
              gridData={gridData}
              updateOpportunity={updateOpportunity}
              modelSectionData={modelSectionData}
              offsetWells={offsetWells}
              selectedOffsetWells={selectedOffsetWells}
              selectedNeighborLogs={selectedOffsetWells}
              handleSelectNeighbor={this.handleSelectNeighbor}
              handleUnselectNeighbor={this.handleUnselectNeighbor}
              setCurPage={this.setCurPage}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <CommentBox
            threadId={singleOpportunity._id}
            updateOpportunityComments={updateOpportunityComments}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(UnconventionalOpportunity)


