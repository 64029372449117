import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'


const variableToName = {
  perfLength: {
    title: 'Perforation Length',
    unit: 'm'
  },
  hcpt: {
    title: 'Hydropcarbon Thickness',
    unit: 'm'
  },
  pnp: {
    title: 'PNP',
    unit: 'm'
  },
  pnpPerm: {
    title: 'Perm',
    unit: 'md'
  },
  pnpInitialPressure: {
    title: 'Initial Pressure',
    unit: 'psi'
  },
}


// TODO - make hover box not shared
@autobind class ScatterChart extends PureComponent {
  render() {
    let { data, xKey, textKey, yKey, sizeKey, refLine, xTitle, yTitle, yUnits, yMargin, refLineTitle, newHoverTemplate } = this.props

    yUnits = !!yUnits ? yUnits : ''

    let hovertemplate = textKey ? `<b>${yTitle}</b>: %{y} ${yUnits} - %{hovertext}` : `<b>${yTitle}</b>: %{y} ${yUnits}`

    let xObj = variableToName[xKey]
    let sizeObj = variableToName[sizeKey]

    if (newHoverTemplate) {
      hovertemplate = `<b>${yTitle}</b>: %{y} ${yUnits}<br><b>${xObj.title}</b>: %{x} ${xObj.unit}`

      if (sizeObj) {
        hovertemplate += `<br><b>${sizeObj.title}</b>: %{marker.size:,} ${sizeObj.unit}`
      }
    }

    let marker = {
      size: 10
    }

    if (sizeKey) {
      marker = {
        size: data.map(i => i[sizeKey]),
        sizeref: 2 * Math.max.apply(null, (data.map(i => i[sizeKey])).filter(i => !!i)) / (400),
        sizemode: 'area'
      }
    }


    const plotData = [{
      name: '',
      x: data.map(i => i[xKey]),
      y: data.map(i => i[yKey]),

      type: 'scatter',
      mode: 'markers',
      marker: marker,
      hovertext: data.map(i => i[textKey]),
      hovertemplate: hovertemplate,
      showlegend: false,
      hoverinfo: 'none',
    }]

    const layout = {
      title: '',
      barmode: 'stack',
      autosize: true,
      shapes: null,
      margin: {
        l: 50,
        r: 10,
        b: yMargin || 50,
        t: 10,
        pad: 0,
      },
      xaxis: {
        title: {
          text: xTitle,
        },
      },
      yaxis: {
        title: {
          text: yTitle,
        },
      },
    }

    if(refLine && refLineTitle && data && data.length){
      //Find where to put title.
      let xArray = plotData[0].x
      let minX = xArray[0]
      if(typeof minX === 'number'){
        minX = Math.min(...xArray)
      } else {
        //x is an array of dates, not ints
        let lowest = 0  //index
        xArray.forEach((date, i) => (new Date(date) < new Date(xArray[lowest])) ? lowest = i : null)
        minX = xArray[lowest]
      }

      plotData.push({
        x: [minX],
        y: [refLine],
        text: [refLineTitle],
        mode: 'text',
        textposition: 'top right',
      })

      layout.shapes = [{
        type: 'line',
        xref: 'paper',
        yref: 'y',
        x0: 0,
        x1: 1,
        y0: refLine,
        y1: refLine,
        line: {
          color: 'rgb(0,0,0)',
          width: 3,
          dash: 'dash',
        },
      }]
    }

    return (
      <Plot className="plot"
        data={plotData}
        layout={layout}
        useResizeHandler
        config={{showlegend: true, displaylogo: false, showSendToCloud: true, displayModeBar: false}}
      />
    )
  }
}

export default ScatterChart
