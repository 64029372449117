import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import SlidingPane from 'react-sliding-pane'
import ReactTooltip from 'react-tooltip'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core'

import API from '../../../lib/api-store'
import UserCard from './components/UserCard'
import UserModal from './components/UserModal'
import PercentBar from './components/PercentBar'
import './License.scss'


const baseClass = 'license'
const leftContainerClass = `${baseClass}__leftContainer`
const headerClass = `${leftContainerClass}__header`
const prevHeaderClass = `${leftContainerClass}__prevHeader`
const subHeaderClass = `${leftContainerClass}__subHeader`
const textClass = `${leftContainerClass}__text`
const licensesClass = `${leftContainerClass}__licenses`
const adminContainerClass = `${leftContainerClass}__adminContainer`
const userContainerClass = `${leftContainerClass}__userContainer`

const rightContainerClass = `${baseClass}__rightContainer`
const rightSubHeaderClass = `${rightContainerClass}__subHeader`
const rightTextClass = `${rightContainerClass}__text`

@autobind class License extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUserModalOpen: false,
      selectedUser: null,
      modalTitle: null,
      license: null,
      projects: null,
      missingUserType: null,
      accountUserOptions: [],
      createUserError: null,
    }
  }

  componentDidMount() {
    this.fetchLicense()
    this.fetchAccountOptions()
    this.fetchLicenseProjects()
  }

  fetchLicense() {
    const { match } = this.props
    const { licenseId } = match.params

    API.getLicense(licenseId)
      .then(license => {
        console.log('othertetestest', license)
        this.setState({
          license
        })
    })
  }

  fetchLicenseProjects() {
    const { match } = this.props
    const { licenseId } = match.params

    API.loadLicenseProjects(licenseId)
      .then(projects => {
        console.log('othertetestest', projects)
        this.setState({
          projects
        })
    })
  }

  fetchAccountOptions() {
    API.getAccountUserOptions()
      .then((users) => {
        console.log('testestest', users)    
        this.setState({
          accountUserOptions: users,
        })
      })
  }

  handleUserClick(userId) {
    console.log('testest', userId)

    this.setState({
      isUserModalOpen: true,
      selectedUser: userId,
      modalTitle: 'User Profile'
    })
  }

  handleAddUserClick(userId) {
    console.log('testest', userId)

    this.setState({
      isUserModalOpen: true,
      selectedUser: null,
      missingUserType: "user",
      modalTitle: 'Add User'
    })
  }

  handleAddLicenseAdminClick() {
    console.log('adding license admin')
    this.setState({
      isUserModalOpen: true,
      selectedUser: null,
      missingUserType: "admin",
      modalTitle: 'Add License Administrator'
    })
  }

  handleAddLicenseAdmin(userId) {
    const { match } = this.props
    const { licenseId } = match.params

    API.addLicenseAdmin(userId, licenseId).then(res => {
      this.fetchLicense()
      this.closeUserModel()
    })
  }

  handleRemoveLicenseAdmin(userId) {
    const { match } = this.props
    const { licenseId } = match.params

    API.removeLicenseAdmin(userId, licenseId).then(res => {
      this.fetchLicense()
      this.closeUserModel()
    })
  }

  handleAddUser(userId) {
    const { match } = this.props
    const { licenseId } = match.params

    console.log('aadding user', userId, licenseId)

    API.addLicenseUser(userId, licenseId).then(res => {
      this.fetchLicense()
      this.closeUserModel()
    })
  }

  handleToggleProjectPrivileges(userId, e) {
    const { match } = this.props
    const { licenseId } = match.params
    const { license } = this.state

    API.toggleUserProjectPrivileges(userId, licenseId).then(res => {
      let newLicense = {...license}
      newLicense.currentUsers.find(i => i.id == userId).projectPrivileges = e

      this.setState({
        license: newLicense
      })
    })
  }

  handleRemoveFromLicense(userId, e) {
    const { match } = this.props
    const { licenseId } = match.params
    
    this.props.history.push(`/licenses/${licenseId}/removeUser/${userId}`)
  }

  handleCreateNewUser(firstName, lastName, email, password) {
    const { match } = this.props
    const { licenseId } = match.params

    const user = {
      email: email,
      tempPass: password,
      firstName: firstName,
      lastName: lastName,
    }

    API.createNewCognitoUser(user, licenseId)
    .then(user => {
      let { err } = user
      console.log('new user', user)

      if (err) {
        console.log('err creating user', err)
        console.log(err.split(':')[1])
        return this.setState({
          createUserError: err.split(":")[1]
        })  
      }

      this.fetchLicense()

      this.setState({
        isUserModalOpen: false
      })
    })
  }


  handleGoToLicenses() {
    this.props.history.push('/licenses')
  }
  
  openUserModel() {
    this.setState({
      isUserModalOpen: true
    })
  }

  closeUserModel() {
    this.setState({
      isUserModalOpen: false
    })
  }




  makeAccountAdminCard(accountAdmin) {
    return (
      <UserCard 
        user={accountAdmin}
        type="Account Administrator"
        handleUserClick={this.handleUserClick}
      />
    )

  }

  makeLicenseAdminCard(licenseAdmin, isAccountAdmin) {
    if (!licenseAdmin) {
      if (isAccountAdmin) {
        return (
          <UserCard 
            user={null}
            type="Add Administrator"
            handleUserClick={this.handleAddLicenseAdminClick}
          />

        )        
      }
      return
    }

    return (
      <UserCard 
        user={licenseAdmin}
        type="License Administrator"
        handleUserClick={this.handleUserClick}
      />
    )
  }

  makeUserCards(currentUsers) {
    return currentUsers.map(i => {
      return (
        <UserCard 
          user={i}
          type="User"
          handleUserClick={this.handleUserClick}
        />
      ) 
    })
  }

  makeWorkspaceSummary() {
    const { projects } = this.state

    if (!projects) {
      return null
    }
    return projects.map(project => {
      return <ExpansionPanel
        className={''}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography component="h3" variant="h6">{project.name}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            {this.makeWorkspaceInside(project)}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    })
  }

  makeWorkspaceInside(project) {
    const { license } = this.state
    let projectStorage = ((project.caseS3StorageSize || 0) + (project.processedFileS3StorageSize || 0) + (project.rawFileS3StorageSize || 0)) / 1024 / 1024 / 1024
    let rawFileS3StorageSize = (project.rawFileS3StorageSize || 0) / 1024 / 1024 / 1024
    let processedFileS3StorageSize = (project.processedFileS3StorageSize || 0) / 1024 / 1024 / 1024
    let caseS3StorageSize = (project.caseS3StorageSize || 0) / 1024 / 1024 / 1024
    let storageLimit = license.dataStorageLimit / 1024 / 1024 / 1024

    let text = `Raw Data Usage: ${rawFileS3StorageSize.toFixed(2)} GB<br />Processed Data Usage: ${processedFileS3StorageSize.toFixed(2)} GB<br />Case Results Usage: ${caseS3StorageSize.toFixed(2)} GB`

    return <div className="workspace-inside">
          <div className="left">
            <a data-tip={text} data-for={'test'}>
              <PercentBar 
                curOne={rawFileS3StorageSize}
                curTwo={processedFileS3StorageSize}
                curThree={caseS3StorageSize}
                type={"three"}
                max={1000}
                small={true}
              />
            </a>
            <ReactTooltip id={'test'} multiline place='left' className="customize-settings-tooltip" effect="solid" />
            <PercentBar 
              curOne={project.jobsUsed}
              max={project.jobLimit}
              small={true}
            />
            <PercentBar 
              curOne={project.jobHoursUsed}
              max={project.jobHourLimit}
              small={true}
            />
          </div>
          <div className="right">
            <div className={'small-text'}>
              {projectStorage.toFixed(2)} of {storageLimit} <b>GB</b>
            </div>
            <div className={'small-text'}>
              {project.jobsUsed} of {project.jobLimit} <b>Jobs</b>
            </div>
            <div className={'small-text'}>
              {project.jobHoursUsed.toFixed(2)} of {project.jobHourLimit} <b>Hours</b>
            </div>
          </div>
        </div>  
  }

  render() {
    const { projects, createUserError } = this.state 
    const  { userId } = this.props
    const { isUserModalOpen, selectedUser, modalTitle, accountUserOptions, license, missingUserType } = this.state

    if (!license) {
      return (
      <div className={baseClass}>
        <div className={leftContainerClass}>
        </div>
        <div className={rightContainerClass}>
        </div>
      </div>
      )
    }

    let accountAdmin = license.accountAdmin
    let licenseAdmin = license.licenseAdmin

    let curUserCount = license.currentUsers.length
    let maxUserCount = license.userLimit
    let workspaceCount = license.workspaceCount
    let workspaceLimit = license.workspaceLimit
    let jobCount = license.jobsUsed
    let jobLimit = license.jobLimit
    let jobHourCount = license.jobHoursUsed.toFixed(2)
    let jobHourLimit = license.jobHourLimit
    let expirationDate = new Date(license.expiration)
    let expirationText = `Expires ${expirationDate.getMonth() + 1}/${expirationDate.getDate()}/${expirationDate.getFullYear()}`

    let isLicenseAdmin = (license.licenseAdminId == userId)
    let isAccountAdmin = (license.accountAdminId == userId)

    let projectJobCount = 0
    let projectJobHourCount = 0

    if (projects) {
      projects.forEach(project => {
        projectJobCount += project.jobsUsed
        projectJobHourCount += project.jobHoursUsed
      })
    }

    let deletedProjectJobCount = jobCount - projectJobCount
    let deletedProjectJobHourCount = jobHourCount - projectJobHourCount


    let jobText = `Current workspaces have used: ${projectJobCount} jobs.<br /> Previously deleted workspaces have used ${deletedProjectJobCount} jobs.`
    let jobHourText = `Current workspaces have used: ${projectJobHourCount.toFixed(2)} job hours.<br /> Previously deleted workspaces have used ${deletedProjectJobHourCount.toFixed(2)} job hours.`

    let canAddUser = curUserCount < maxUserCount

    return (
      <div className={baseClass}>
        <div className={leftContainerClass}>
          <div className={prevHeaderClass}
            onClick={this.handleGoToLicenses}
          >
            Back to Licenses
          </div>
          <div className={headerClass}>
            {license.name}
          </div>
          <div className={subHeaderClass}>
            Administrators
          </div>
          <div className={textClass}>
            Administrators are not counted as users, unless added as users below
          </div>
          <div className={adminContainerClass}>
            {this.makeAccountAdminCard(accountAdmin)}
            {this.makeLicenseAdminCard(licenseAdmin, isAccountAdmin)}
          </div>


          <div className={subHeaderClass}>
            User
          </div>
          <div className={textClass}>
            All users are able to create cases or datasets, delete their own cases or datasets, or be a part of a workspace.
          </div>
          <div className={userContainerClass}>
            {this.makeUserCards(license.currentUsers)}
            {(isLicenseAdmin || isAccountAdmin) &&
              <UserCard 
                user={null}
                type="Add User"
                disabled={!canAddUser}
                disabledTooltip="The maximum number of users allowed for this license have been added.  Contact us to increase tier limits."
                handleUserClick={this.handleAddUserClick}
              />
            }
          </div>
        </div>
        <div className={rightContainerClass}>
          <div className={"plan-summary"}>
            <div className={rightSubHeaderClass}>
              Your Plan
            </div>
            <div className={'expiration-text'}>
              {expirationText}
            </div>
            <div className={rightTextClass}>
              TBD
            </div>
            <div className={rightSubHeaderClass}>
              Plan Summary
            </div>
            <div className={rightTextClass}>
              {curUserCount} of {maxUserCount} <b>Users Added</b>
            </div>
            <PercentBar 
              curOne={curUserCount}
              max={maxUserCount}
            />
            <div className={rightTextClass}>
              {workspaceCount} of {workspaceLimit} <b>Workspace(s) Added</b>
            </div>
            <PercentBar 
              curOne={workspaceCount}
              max={workspaceLimit}
            />
            <div className={rightTextClass}>
              {jobCount} of {jobLimit} <b>Jobs Used</b>
            </div>
            <a data-tip={jobText} data-for={'test2'}>
              <PercentBar
                curTwo={projectJobCount}
                curOne={deletedProjectJobCount}
                type='two'
                max={jobLimit}
              />
            </a>
            <ReactTooltip id={'test2'} multiline place='left' className="customize-settings-tooltip" effect="solid" />
            <div className={rightTextClass}>
              {jobHourCount} of {jobHourLimit} <b>Hours Used</b>
            </div>
            <a data-tip={jobHourText} data-for={'test3'}>
              <PercentBar 
                curOne={deletedProjectJobHourCount}
                curTwo={projectJobHourCount}
                type='two'
                max={jobHourLimit}
              />
            </a>
          </div>
          <ReactTooltip id={'test3'} multiline place='left' className="customize-settings-tooltip" effect="solid" />
          <div className={`${rightSubHeaderClass} workspace-header`}>
            Workspace Level Summary
          </div>
          <div className={'workspace-summary'}>
            <div className={"workspaces"}>
              {this.makeWorkspaceSummary()}
            </div>
          </div>
        <SlidingPane
          isOpen={isUserModalOpen}
          from="right"
          width="500px"
          title={''}
          onRequestClose={this.closeUserModel}
        >
          <UserModal
            createUserError={createUserError}
            selectedUser={selectedUser}
            missingUserType={missingUserType}
            title={modalTitle}
            accountUserOptions={accountUserOptions}
            license={license}
            isLicenseAdmin={isLicenseAdmin}
            isAccountAdmin={isAccountAdmin}
            handleToggleProjectPrivileges={this.handleToggleProjectPrivileges}
            handleAddLicenseAdmin={this.handleAddLicenseAdmin}
            handleRemoveLicenseAdmin={this.handleRemoveLicenseAdmin}
            handleAddUser={this.handleAddUser}
            handleRemoveFromLicense={this.handleRemoveFromLicense}
            handleCreateNewUser={this.handleCreateNewUser}
          />
        </SlidingPane>



        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default withRouter(connect(mapStateToProps, null)(License))
