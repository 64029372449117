import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Modal from 'react-responsive-modal'
import Button from '@material-ui/core/Button'

import './ContinueModal.scss'

const modalClass = 'qri-modal'
const modalCloseIconClass = 'qri-modal__close-button'

const baseClass = 'cancel-modal'
const headerClass = `${baseClass}__header`

const contentClass = `${baseClass}__content`
const childrenClass = `${contentClass}__children`

@autobind class ContinueModal extends Component {

  render() {
    const {
      showContinueModal,
      onModalCancel,
      onModalContinue,
      header,
      children,
    } = this.props

    return (
      <Modal
        classNames={{ modal: modalClass, closeIcon: modalCloseIconClass }}
        open={showContinueModal}
        onClose={onModalCancel}
        center
      >
        <div
          className={baseClass}
        >
          <div
            className={headerClass}
          >
            <span>{header}</span>
          </div>
          <div
            className={contentClass}
          >
            <div className={childrenClass}>
              {children}
            </div>
            <Button
              color="primary"
              size="large"
              variant="contained"
              tabIndex="0"
              onClick={onModalContinue}
              fullWidth
            >
              Continue
          </Button>
          </div>

        </div>
      </Modal>
    )
  }
}

export default ContinueModal