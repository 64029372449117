import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import GeneralSettingsA from '../../../../../../../../../../static/images/userManual/settings/generalSettingsA.png'
import GeneralSettingsB from '../../../../../../../../../../static/images/userManual/settings/generalSettingsB.png'
import GeneralSettingsC from '../../../../../../../../../../static/images/userManual/settings/generalSettingsC.png'

@autobind class GeneralSettings extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          General Settings
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
            Evaluate Reservoir as a single zone (tank)<br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Comments:</span> If on, drainage analysis assumes all zones are connected and treats them as a single zone.<br/>
        </div>
        <div className="content-row"> 
            Evaluate Reservoir as a single zone (tank)<br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Comments:</span> If on, drainage analysis assumes all zones are connected and treats them as a single zone.<br/>
        </div>
        <div className="content-row"> 
            Drainage Interference<br/>
            <span className='blue'>Options:</span> Optimistic, Pessimistic<br/>
            <span className='blue'>Comments:</span> If pessimistic, the analysis will add up the drainage indices (DI) at each area. If optimistic, the analysis will take the highest DI for that area.<br/>
        </div>
        <div className='image-container'>
          <img className="tooBig" src={GeneralSettingsA} alt="" />
        </div>
        <div className="content-row"> 
            Production Attribute<br/>
            <span className='blue'>Options:</span> EUR, Cumulative Production<br/>
            <span className='blue'>Comments:</span> This is the production attribute that will be mapped. If 'EUR' is chosen, then the EUR drainage radii will be mapped. If 'Cumulative Production' is chosen, then drainage radii will only reflect the current cumulative produced oil.<br/>
        </div>
        <div className='image-container'>
          <img className="tooBig" src={GeneralSettingsB} alt="" />
        </div>
        <div className="content-row"> 
            Max Drainage Index (DI) Cutoff<br/>
            <span className='blue'>Options:</span> 0 to 1<br/>
            <span className='blue'>Comments:</span> Opportunities located in areas with DI > cutoff will be discarded.<br/>
        </div>
        <div className="content-row"> 
            Use Facies Tracking Conditions<br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Comments:</span> If on, this option will constrain drainage to the facies interpretation (instead of assuming circular drainage).<br/>
        </div>
        <div className='image-container'>
          <img className="tooBig" src={GeneralSettingsC} alt="" />
        </div>
        <div className="content-row"> 
            Weighting<br/>
            <span className='blue'>Dependencies:</span> Use Facies Tracking Conditions<br/>
            <span className='blue'>Options:</span> D, K, K2D<br/>
            <span className='blue'>Comments:</span> Distance (D) or permeability (K), or K2D (the ratio of permeability to distance).<br/>
        </div>
        <div className="content-row"> 
            Generate Facies Map<br/>
            <span className='blue'>Dependencies:</span> Use Facies Tracking Conditions<br/>
            <span className='blue'>Options:</span> On, Off<br/>
            <span className='blue'>Comments:</span> If facies are not available, this option creates facies grids using settings.<br/>
        </div>
        <div className="content-row"> 
            Facies to Track<br/>
            <span className='blue'>Dependencies:</span> Use Facies Tracking Conditions<br/>
            <span className='blue'>Options:</span> Facies IDs (generally whole numbers).<br/>
            <span className='blue'>Comments:</span> Specifies the list of facies Ids to track. If facies grid is boolean, use [1].<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(GeneralSettings)
