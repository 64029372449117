import React from 'react'

import img1 from './Faults1.jpg'

const Faults = (
  <div className={'description-container'}>

    <p><b>Content:</b> These are lines indicating the intersections of the faults with the top structures of zones.</p>
    <p><b>Utility:</b> The only purpose of importing the fault lines is for displaying them on maps. If you would like to include fault proximity in determining sweet spot locations, you should include distance-to-fault grids into your relative probability of success or RPOS maps. Similarly, if fault proximity information is needed when evaluating horizontal candidates, distance-to-fault property from GSLIB model should be included in the 3D RPOS modeling.</p>
    <p><b>Conventions: Z: </b> Values of Z need to be negative when below sea level</p>
    <p><b>File Format:</b> Zmap + lines (ASCII) (example shown in Figure 17).</p>
    <ul>
      <li>There must be one file per zone that represents the fault polygons for that specific zone. Files must be named by the zone naming conventions consistent with all other input (i.e., grid names, zone names).</li>
      <li>The data columns include, X, Y, polygon #, and Z, with no header information like many other data formats.</li>
      <li>The spatial data in these files (XYZ) must always be in meters, consistent with other input data.</li>
    </ul>
    <p><b>Units:</b> Meters.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 17: Example of Zmap + lines (ASCII) format for fault polygons.</figcaption>
    </figure>
  </div>
)

export default Faults