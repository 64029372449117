import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import TextField from '@material-ui/core/TextField'

import Button from '@material-ui/core/Button'

import './ProjectEditor.scss'

const baseClass = 'project-editor'

const headerClass = `${baseClass}__header`

const contentClass = `${baseClass}__content`

const topInputBarClass = `${contentClass}__top-bar`
const projectNameInputClass = `${topInputBarClass}__name-input`

const projectDescriptionLabelClass = `${contentClass}__description-label`
const projectDescriptionTextAreaClass = `${projectDescriptionLabelClass}__text-area`

const bottomBarClass = `${contentClass}__bottom-bar`
const bottomLeftBarClass = `${bottomBarClass}__left`
const dateCreatedLabelClass = `${bottomLeftBarClass}__date-created-label`
const dateCreatedClass = `${bottomLeftBarClass}__date-created`

const multiTextRows = 4

@autobind class ProjectEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectName: '',
      projectBasin: '',
      projectRegion: '',
      projectCountry: '',
      projectObjective: '',
    }
  }

  componentDidMount() {
    const { project } = this.props

    this.setState({
      projectName: project.name,
      projectBasin: project.basin,
      projectRegion: project.region,
      projectCountry: project.country,
      projectObjective: project.objective,
    })
  }

  handleNameChange(e) {
    const { value } = e.target
    this.setState({
      projectName: value,
    })
  }

  handleBasinChange(e) {
    const { value } = e.target
    this.setState({
      projectBasin: value,
    })
  }

  handleRegionChange(e) {
    const { value } = e.target
    this.setState({
      projectRegion: value,
    })
  }

  handleCountryChange(e) {
    const { value } = e.target
    this.setState({
      projectCountry: value,
    })
  }

  handleDescriptionChange(e) {
    const { value } = e.target
    this.setState({
      projectObjective: value,
    })
  }

  onActionClick() {
    const { projectName, projectBasin, projectRegion, projectCountry, projectObjective } = this.state
    const { handleEditProject, selectedProject } = this.props

    handleEditProject(selectedProject, projectName, projectBasin, projectRegion, projectCountry, projectObjective)
  }

  onDeleteClick() {
    const { toggleDeleteModal, selectedProject } = this.props

    toggleDeleteModal(selectedProject)
  }

  render() {
    const { 
      projectName,
      projectBasin,
      projectRegion,
      projectCountry,
      projectObjective
  } = this.state

  const { setModalType, disabled } = this.props

    return (
      <div className={baseClass}>

        <div className={headerClass}>
          <span>{disabled ? 'View Workspace' : 'Edit Workspace'}</span>
        </div>

        <div className={contentClass}>
          <div className={topInputBarClass}>
            <TextField
              id="dataset-name-input"
              label="Workspace Name"
              type="text"
              margin="normal"
              variant="outlined"
              className={disabled ? projectNameInputClass : projectNameInputClass + " disabled"}
              value={projectName}
              onChange={this.handleNameChange}
              disabled={disabled}
              inputProps={{
                maxLength: 50,
                readOnly: disabled,
              }}
            />
            <TextField
              id="dataset-basin-input"
              label="Workspace Basin"
              type="text"
              margin="normal"
              variant="outlined"
              className={disabled ? projectNameInputClass : projectNameInputClass + " disabled"}
              value={projectBasin}
              onChange={this.handleBasinChange}
              disabled={disabled}
              inputProps={{
                maxLength: 100,
                readOnly: disabled,
              }}
            />
          </div>
          <div className={topInputBarClass}>
            <TextField
              id="dataset-region-input"
              label="Workspace Region"
              type="text"
              margin="normal"
              variant="outlined"
              className={disabled ? projectNameInputClass : projectNameInputClass + " disabled"}
              value={projectRegion}
              onChange={this.handleRegionChange}
              disabled={disabled}
              inputProps={{
                maxLength: 100,
                readOnly: disabled,
              }}
            />
            <TextField
              id="dataset-country-input"
              label="Workspace Country"
              type="text"
              margin="normal"
              variant="outlined"
              className={disabled ? projectNameInputClass : projectNameInputClass + " disabled"}
              value={projectCountry}
              onChange={this.handleCountryChange}
              disabled={disabled}
              inputProps={{
                maxLength: 100,
                readOnly: disabled,
              }}
            />
          </div>
          <TextField
            fullWidth
            id="dataset-description-input"
            label="Project Description"
            type="text"
            margin="normal"
            variant="outlined"
            multiline
            rows={multiTextRows}
            value={projectObjective}
            onChange={this.handleDescriptionChange}
            disabled={disabled}
            inputProps={{
              maxLength: 5000,
              readOnly: disabled,
            }}
          />

          <div className={bottomBarClass}>
            <div style={{display: 'flex'}}>
              <Button
                tabIndex="0"
                onClick={() => setModalType('collab')}
                onKeyPress={null}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                style={{marginRight: '10px'}}
              >
                <span>View Collaborators</span>
              </Button>
              { !disabled && (<Button
                disabled={!projectName || !projectObjective || disabled}
                tabIndex="0"
                onClick={this.onActionClick}
                onKeyPress={null}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
              >
                <span>Save</span>
              </Button>)}
            </div>
            <div style={{display: 'flex', paddingTop: '5px'}}>
              { !disabled && (<Button
                disabled={!projectName || !projectObjective || disabled}
                tabIndex="0"
                onClick={this.onDeleteClick}
                onKeyPress={null}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
              >
                <span>Delete Workspace</span>
              </Button>)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectEditor
