import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import API from '../../../../lib/api-store'

import './SupportTicketCreator.scss'

const baseClass = 'ticket-creator'

const headerClass = `${baseClass}__header`

const contentClass = `${baseClass}__content`
const emersonLinkClass = `${contentClass}__emerson`

const topInputBarClass = `${contentClass}__top-bar`

const bottomBarClass = `${contentClass}__bottom-bar`
const bottomBarClassCentered = `${bottomBarClass}__centered`



const multiTextRows = 4

@autobind class SupportTicketCreator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      category: null,
      selectedProduct: undefined,
      description: null,
      submitted: false,
    }
  }

  async componentDidMount(){
    const licenses = await API.getLicenses()
    const unique = (value, index, self) => self.indexOf(value) === index
    let products = []
    licenses.forEach(x => x.applications.forEach(y => products.push(y)))
    products = products.filter(unique)

    this.setState({ products })
  }

  handleCategoryChange(val) {
    this.setState({
      category: val,
    })
  }

  handleProductChange({ value }){
    this.setState({
      selectedProduct: value,
    })
  }

  handleDescriptionChange(e) {
    const { value } = e.target
    this.setState({
      description: value,
    })
  }

  onActionClick() {
    const { category, description, selectedProduct } = this.state
    const { handleNewTicket } = this.props
    

    handleNewTicket(category, description, selectedProduct)

    this.setState({
      submitted: true
    })
  }

  closeModal() {
    const { handleCloseModal } = this.props

    handleCloseModal()
  }


  render() {
    const { category, selectedProduct, description, submitted, products } = this.state
    const { handleCloseModal, match } = this.props
    const { application } = match.params

    let productMap = {
      SGO: { label: 'Speedwise Reservoir Opportunity', value: 'SGO' },
      SWM: { label: 'Speedwise Waterflood Management', value: 'SWM' },
    }

    let productOptions = []

    if(products){
      products.forEach(pKey => productOptions.push(productMap[pKey]))
    }else {
      productOptions = [
        { label: 'Speedwise Reservoir Opportunity', value: 'SGO' },
        { label: 'Speedwise Waterflood Management', value: 'SWM' },
      ]
    }
    console.log('products in render: ', products)
    console.log('product options: ', productOptions)

    const SPVal = selectedProduct ? selectedProduct : application
    const selectedProductObject = productOptions.find(x => x.value === SPVal) 

    if (submitted) {
      return (
      <div className={baseClass}>
        <div className={headerClass}>
          <span>Thank you, your feedback has been recieved.</span>
        </div>
         <div className={bottomBarClassCentered}>
          </div>
      </div>
      )

    }

    return (
      <div className={baseClass}>

        <div className={headerClass}>
          <span>Leave us a message, our team will get back to you within 1-3 business days.</span>
        </div>

        <div className={contentClass}>
          <Select
            className="selector"
            placeholder="What product do you need help with?"
            options={productOptions}
            value={selectedProductObject}
            onChange={this.handleProductChange}
          />
          { SPVal !== 'SGO' && SPVal && (<>
            <Select
              className="selector"
              placeholder="How can we help you?"
              options={[
                { label: 'How to use the Product', value: 'help'},
                { label: 'Report an Issue', value: 'issue'},
                { label: 'Request a feature', value: 'featureRequest'}
              ]}
              value={category}
              onChange={this.handleCategoryChange}
            />
            <div>Message</div>
            <TextField
              fullWidth
              id="dataset-description-input"
              type="text"
              margin="normal"
              variant="outlined"
              multiline
              rows={multiTextRows}
              value={description}
              onChange={this.handleDescriptionChange}
            />
            <div className={bottomBarClass}>
              <div>
                <Button
                  disabled={!description || !category}
                  tabIndex="0"
                  onClick={this.onActionClick}
                  onKeyPress={null}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  <span>Submit</span>
                </Button>
              </div>
            </div>
          </>)}
          { SPVal === 'SGO' && (
          <div className={emersonLinkClass}>
            <p>It is <b>important to note</b> that for SRO support, you must visit <a href="https://www.emerson.com/epssupport" target="_blank">Emerson's Customer Portal</a> and select 'Sign Up' with Emerson first in order to send requests.  If you are not a registered user, the help request will not be recieved.</p>
            <p>Please email our support team at customersupport@emerson.com with any questions, comments, or feedback.  Our team will get back to you shortly.</p>
          </div>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(SupportTicketCreator)
