import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import datasetCreateA from '../../../../../../../static/images/userManual/datasetCreateA.png'
import datasetCreateB from '../../../../../../../static/images/userManual/datasetCreateB.png'
import datasetCreateC from '../../../../../../../static/images/userManual/datasetCreateC.png'
import datasetCreateD from '../../../../../../../static/images/userManual/datasetCreateD.png'

@autobind class Create extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Dataset Operations
        </div>
        <div className="content-row-header"> 
          Create or Edit a Dataset
        </div>
        <div className="content-row"> 
          If you click on “<b>+ New Dataset</b>” or the edit button (pencil icon) of an existing dataset, it will take you to the <b>Data Loading/Editing</b> page. This is the interface for loading new data (whether to update an existing dataset or create a new one).
        </div>
        <div className="content-row"> 
          <span className="blue"> 1. Review Required Data by Category:</span> Click on one of the data categories and view the data that is necessary to complete this category.
        </div>
        <div className="content-row"> 
          &ensp;• As mentioned previously, some data requires you to download a template.
        </div>
        <div className="content-row"> 
          &ensp;• When selecting different modules, the data requirement will change accordingly.
        </div>
        <div className="content-row"> 
          <span className="blue"> 2. How to Upload:</span> Simply drag the file into the browser or browse for it and the page will show the status of your files being uploaded.
        </div>
        <div className="content-row"> 
          &ensp;• File Formats: Most of the data necessary to create a dataset are the industry standards (e.g., LAS files for logs). When a different format is required, a template is provided for you to download and fill in.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={datasetCreateA} alt="" />
        </div>
        <div className="image-footnote"> 
          Dataset editing page where data categories are shown on the menu on the left, and data can be uploaded.
        </div>
        <div className="content-row"> 
          <span className="blue"> 3. Upload Status:</span> As files are uploading the status will show as Completed, In Progress, or that there is an Error
        </div>
        <div className="content-row"> 
          &ensp;• File Quality Control: This will show you if there are errors when you upload files.
        </div>
        <div className="content-row"> 
          &ensp;• Multi-file Categories: For multi-file categories like logs, you’re able to upload multiple files at the same time.
        </div>
        <div className="content-row"> 
          <span className="blue"> 4. View, Replace, or Delete Uploaded Files:</span> After files are uploaded, you are always able to view them, replace them, or delete them if you choose.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={datasetCreateB} alt="" />
        </div>
        <div className="image-footnote"> 
          While creating or editing a dataset, the status of the upload will show for each file and category. You will be able to view, replace, and delete documents.
        </div>
        <div className='grayLine'/>
        <div className="content-row-header"> 
          Dataset Validation
        </div>
        <div className="content-row"> 
          As you complete the upload of the required data in each category, it will show each category as “Complete” in the menu on the left. After all the required categories are complete, the button “Start Validation” will appear in the top right of the page. When you have completed the upload of all your files, click this “Start Validation” button. This will start the process of cross-checking different data categories against each other. For example, it checks that the wells and the grids are in the same area, or that the well logs match the wells that were imported. If there are issues, they will be highlighted as shown in and you’ll be able to go back and fix them. This process may take a few minutes, depending on the size of the dataset. The status of the dataset validation will appear as a pop-up menu on the right (Figure 15). You may leave the browser page as this process runs. When you reload the page you’ll be able to see the status by clicking the three-line menu bar that will be below the refresh button.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={datasetCreateC} alt="" />
        </div>
        <div className="image-footnote"> 
          After all required files for a dataset are uploaded, a button to validate the dataset will appear in the top right called “Start Validation”.
        </div>
        <div className="content-row"> 
          The following are the detailed validation checks that currently exist:
        </div>
        <div className="content-row"> 
          <span className="blue"> 1. File Combination:</span> This step combines each file type together and saves them into the database (e.g., combines all grid files, combines all log files, etc.). It also does a few other steps such as extracts all the log names from the LAS files so they can be used to populate the Log Alias table when cases are run.
        </div>
        <div className="content-row"> 
          <span className="blue"> 2. Cross File Validation:</span>
        </div>
        <div className="content-row"> 
          &ensp;• Cross Grid Validation: to check if all grids share the same definition. It becomes critical error when any grid uploaded is not using the same definition as the others.
        </div>
        <div className="content-row"> 
          &ensp;• Grids and Well Master Cross Validation: to check whether wells’ surface locations are within the definition of the grids. It becomes critical error when more than 20% of wells are outside of the grids’ definition.
        </div>
        <div className="content-row"> 
          &ensp;• Model and Well Master Cross Validation: to check whether wells’ surface locations are within the definition of the model. It becomes critical error when more than 20% of wells are outside of the model’s definition.
        </div>
        <div className="content-row"> 
          &ensp;• Well Name Validation: first to map all well names according to the well alias data, then benchmarks all well names against the ones existing in well master. For the required data category, it becomes critical error when more than 80% of the well names in well master are missing in this corresponding data category.
        </div>
        <div className="content-row"> 
          &ensp;• Zone Name Validation: to benchmark the zone names in well tops/grids/faults against the names existing in structural surfaces. It becomes critical error when more than 80% of zone names in structural surfaces are missing in any of the other data categories to be checked.
        </div>
        <div className="content-row"> 
          &ensp;• Perforation and Deviation Cross Validation: to check whether the wells’ perforation is shallower than the deepest point in the corresponding deviation survey. It becomes critical error when more than 80% of wells have perforations deeper than deviation survey. If this issue exists, maybe there is a unit problem.
        </div>
        <div className="content-row"> 
          &ensp;• Production and Injection Cross Validation: it is a critical error if the most recent production date is earlier than the most recent injection date.
        </div>
        <div className="content-row"> 
          &ensp;• Production and Perforation Cross Validation: it is a critical error if the first production date of any well is earlier than the first perf open date of that well.
        </div>
        <div className="content-row"> 
          &ensp;• Well Tops and Well Perforation Cross Validation: to check between the well tops depths and well perforations’ depths. It is a critical error if more than 80% of wells have entire perforations shallower than the well tops depth range.
        </div>
        <div className="content-row"> 
          &ensp;• Structure Grid Model Cross Validation: for all zones defined by the structural surfaces, at least there should be cell within the model that exist within those zones.
        </div>
        <div className="content-row"> 
          &ensp;&ensp;1) It is a critical error if no cell exists within a specific zone.
        </div>
        <div className="content-row"> 
          &ensp;&ensp;2) It is a critical error if all cells within a specific zone contains only NaN values for any of the required properties.
        </div>
        <div className="content-row"> 
          &ensp;&ensp;3) It is a critical error if the maximum permeability is 0 for any specific zone.
        </div>
        <div className="content-row"> 
          <span className="blue"> 3. Data Derivation:</span> At this step, more data to be used in opportunity identification will be derived based upon the existing dataset. Typically, three types of new data are derived at this step:
        </div>
        <div className="content-row"> 
          &ensp;• 2.5D Grids: If the geological model uploaded in the dataset contains only the 3D GSLIB model, then all properties provided within the 3D model will be aggregated, based on the provided structural surfaces, to 2.5D grids for each zone. If the original dataset already contains the 2.5D grids, this step will be skipped. To be noted, the data derived here is very important. First, the 2.5D grid information will be carried to the next step for intervals and well interval properties data derivation, which greatly affects the flow unit allocation step. Additionally, all evaluation for the recompletion and sweet spot identifications will be using this information.
        </div>
        <div className="content-row"> 
          &ensp;• Intervals: This information is derived by taking meaningful averages of the grid data to provide basic stratigraphic information and average geologic properties of each interval (or zone). This information is very important for flow unit allocation. For example, if information about a perforated zone is not available from well logs (e.g., you are running a case with the Kh method for production allocation and there is no permeability log in that well), then the production allocation will gather the average interval (or zone) permeability to conduct allocation in that well.
        </div>
        <div className="content-row"> 
          &ensp;• Well Interval Properties: This information is derived to provide geologic property values for each well at each layer. This information is very important for flow unit allocation.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={datasetCreateD} alt="" />
        </div>
        <div className="image-footnote"> 
          The status of the dataset validation will appear as a pop-up menu on the right. If you reload the browser page, you’ll be able to see the status by clicking the three-line menu bar that will be below the refresh button.
        </div>
        <div className="content-row"> 
          <span className="blue"> 4. Smart Settings:</span> At this step, all data uploaded/derived from the specific dataset will be evaluated to generate a first-pass settings’ values/options to be used in the Auto Pilot mode for case executions (should you decide to utilize that function). This step helps you have more meaningful initial guesses for many settings to be used, and also helps to prevent unnecessary typos or redundant information extraction from the dataset.
        </div>
        <div className="content-row"> 
          <span className="blue"> 5. Publish Dataset:</span> When all data has successfully been validated, you will have the option of publishing that dataset and naming the published version. Published datasets are ones that are complete and therefore can be used in a case. After it is published, it will show up to your list of datasets as available.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Create)
