import autobind from 'autobind-decorator'
import React, { Component } from 'react'
import styled from '@material-ui/styles/styled'

import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import Error from '../../../common/Error/Error'
import { VALIDATIONS } from '../../../../lib/datasetMaps/validations'


@autobind class StatusList extends Component {
  constructor(props) {
    super(props)

    const { list, type } = props
    const expandableList = Object.keys(list).reduce((accum, validation) => {
      return { ...accum, [validation]: false }
    }, {})
    this.state = {
      expandableList,
    }

    console.log('Type: ', type)
    console.log('VALIDATIONS: ', VALIDATIONS)
  }

  toggleErrorList(key) {
    const { expandableList } = this.state
    const currState = expandableList[key]

    this.setState({
      expandableList: { ...expandableList, [key]: !currState },
    })
  }

  

  render() {
    const { type, label, list, dateValidated, StatusIcon, title } = this.props
    const { expandableList } = this.state

    return (
      <List aria-label={label}>
        {title && (
          <>
            <ListItem>
              <ListItemText style={{color: '#42526e'}}>
                <b style={{fontSize: '1.2em'}}>{VALIDATIONS[type].title}</b>
              </ListItemText>
            </ListItem>
            <Divider variant="middle" />
          </>
        )}
        {Object.keys(list).map((validation) => {
          
          const oldError = (!!list[validation].error && typeof list[validation].error === "string")
          const showErrorExpand = oldError ? true :
            (Array.isArray(list[validation].error) && list[validation].error.length > 0) ? true : false

          if(!VALIDATIONS[type].validations[validation]){
            return null
          }

          return (
            <>
              <ListItem button={!!list[validation].error} onClick={e => this.toggleErrorList(validation)}>
                <ListItemText primary={VALIDATIONS[type].validations[validation].label}/>
                {showErrorExpand ? (
                  <>{expandableList[validation] ? <ExpandLess /> : <ExpandMore/>}</>
                ) : null}
                <ListItemSecondaryAction>
                  <StatusIcon status={list[validation]} />
                </ListItemSecondaryAction>
              </ListItem>
              {showErrorExpand ? (
                <Collapse in={expandableList[validation]} timeout="auto" unmountOnExit>
                  <List component="div">
                    <ListItem>
                      {oldError && (<ListItemText primary={list[validation].error} />)}
                      {!oldError && (<ListItemText primary={list[validation].error.map((x, i) => <Error {...x} i={i} />)} />)}
                    </ListItem>
                  </List>
                </Collapse>
              ) : null}
              <Divider variant="middle" />
            </>
          )
        })}
      </List>
    )
  }
}

export default StatusList
