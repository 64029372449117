import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'
import { css } from '@emotion/core'
import { ClipLoader } from 'react-spinners'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import API from '../../../../../lib/api-store'
import OpportunityRoutes from '../../../../../routes/OpportunityRoutes'
import Card from '../../common/Card'
import WellLocation from '../../components/WellLocation'
import CommentBox from '../../../../common/Comments/CommentBox'

//import '../Opportunity.scss'
import PinOpportunity from '../../components/PinOpportunity';

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class RecompletionOpportunity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // shouldRenderMap: false,
      logData: null,
      time: null,
      oilRate: null,
      waterCut: null,
      gor: null,
      analogNeighborData: null,
      structSectionDataNS: null,
      structSectionDataEW: null,
      neighborLogs: null,
      selectedNeighborLogs: [],
      modelSectionData: null,
      curPage: '',
      gridData: [],
      clickedOffTraces: [],
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    const { match, clearAllNeighbors } = this.props
    const { opportunityId } = match.params
    
    if (opportunityId !== prevProps.match.params.opportunityId) {
      clearAllNeighbors()
      this.loadData()
    }
  }

  setCurPage(curPage) {
    this.setState({
      curPage
    })
  }

  setClickedOffTraces(val) {
    this.setState({
      clickedOffTraces: val,
      refreshWellLocation: true
    })
  }

  loadData() {   
    const { opportunityData, match, datasetId, parentZone, updateZone } = this.props
    const { caseId, opportunityId } = match.params
    // const { wellId } = match.params
  
    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const zoneFetch = singleOpportunity.zone

    this.setState({
      logData: null,
      time: null,
      oilRate: null,
      waterCut: null,
      gor: null,
      structSectionDataNS: null,
      structSectionDataEW: null,
      modelSectionData: {},
      analogNeighborData: null,
      neighborLogs: null,
    })

    if (zoneFetch !== parentZone) {
      updateZone(zoneFetch)
    }

    
    this.getData()
  }

  getData() {

    const { wellData, gridX, structureGridX, faultData, opportunityData } = this.props

    //We wait until we have the data for the heatmap
    //Before getting all this stuff
    //To improve render time
    
    if (!wellData || !gridX || !structureGridX || !faultData || !opportunityData) {
      setTimeout(() => this.getData(), 250)
      return
    }

    const {  match, datasetId } = this.props
    const { caseId, opportunityId } = match.params
    // const { wellId } = match.params
  
    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const wellId = singleOpportunity.name || singleOpportunity.wellId
    const opportunityType = singleOpportunity.type || 'recompletion'
    const zoneFetch = singleOpportunity.zone

    API.getStructureGrids(datasetId, zoneFetch, caseId)
      .then((gridData) => {     
        this.setState({ gridData })
      })
    
    API.getModelSection(wellId, caseId, opportunityType)
      .then((modelSectionData) => {      
        this.setState({ modelSectionData })
      })
    
    API.getStructSection(wellId, caseId, true, opportunityType)
      .then((structSectionDataNS) => {     
        this.setState({ structSectionDataNS })
      })
    
    API.getStructSection(wellId, caseId, false, opportunityType)
      .then((structSectionDataEW) => {     
        this.setState({ structSectionDataEW })
      })
      
    API.getWellProduction(wellId, datasetId, caseId)
      .then((production) => {
        const { date: time, wOPR: oilRate, wWCT: waterCut, wGOR: gor } = production
        
        this.setState({
          time,
          oilRate,
          waterCut,
          gor,
        })
      })
      
    API.getLogs(wellId, caseId)
      .then((logData) => {
        this.setState({
          logData,
        })
      })

    API.getAnalogNeighborData(wellId, zoneFetch, caseId)
      .then((data) => {
        const { analogNeighborData, neighborLogs } = data

        this.setState({
          analogNeighborData,
          neighborLogs,
        })
      })
  }


  makeConstantMap() {
    return (
      <Card title="Opportunity Location">
        {this.renderMap()}
      </Card>
    )
  }

  makeNavLinks() {
    const { match, opportunityData, zone } = this.props
    const { projectId, caseId, opportunityId, application } = match.params

    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const singleOpportunityId = singleOpportunity._id
    const wellId = singleOpportunity.name || singleOpportunity.wellId
    return (
      <nav className="nav-container">
        <div className="action-bar">
          <div className="title"> {wellId} (Recompletion) </div>
          <div className="pin">
            { singleOpportunity ? (
              <PinOpportunity opportunityId={singleOpportunity._id} caseId={caseId} />
            ) : null }
          </div>
        </div>
        <div className="nav-selection">
          <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}`}>Summary</NavLink>
          <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}/petrophysical`}>Petrophysical Characterization</NavLink>
          <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}/production`}>Production Forecast</NavLink>
          <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}/model`}>Model Analysis</NavLink>
          <NavLink exact className="nav-link" activeClassName="nav-link-active" to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities/${singleOpportunityId}/mechanical`}>Perforation History</NavLink>
        </div>
      </nav>
    )
  }


  //I think these two functions aren't in use, got moved up to parent component Opportunity.js
  //I'm not deleting these because idk what will happen - WY 8/13/20
  handleSelectNeighbor(neighbor) {
    const { selectedNeighborLogs } = this.state
    if (!selectedNeighborLogs.includes(neighbor)) {
      this.setState({
        selectedNeighborLogs: [neighbor, ...selectedNeighborLogs],
      })
    }
  }

  handleUnselectNeighbor(neighbor) {
    const { selectedNeighborLogs } = this.state
    if (selectedNeighborLogs.includes(neighbor)) {
      this.setState({
        selectedNeighborLogs: selectedNeighborLogs.filter(s => s !== neighbor),
      })
    }
  }

  renderMap() {
    const { wellData, structureZMatrix, structureGridX, selectedOffsetWells,
      structureGridY,faultData, zMatrix, opportunityData, gridX, gridY, match,
      gridProperty, updateGridProperty, gridPopertyOptions, modelSectionData, zone,
      handleSelectNeighbor, handleUnselectNeighbor
    } = this.props
    const { opportunityId } = match.params
    const { selectedNeighborLogs, analogNeighborData, curPage, clickedOffTraces } = this.state
    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const wellId = singleOpportunity.name || singleOpportunity.wellId

    if (wellData && gridX && structureGridX && faultData && singleOpportunity) {
      const singleOpportunityData = singleOpportunity && Object.keys(singleOpportunity).length > 0 ? [singleOpportunity] : []

      return (
        <React.Fragment>
          <WellLocation
            wellData={wellData}
            zMatrixHeat={zMatrix}
            gridXHeat={gridX}
            gridYHeat={gridY}
            yMargin={75}
            zMatrixContour={structureZMatrix}
            gridXContour={structureGridX}
            gridYContour={structureGridY}
            faultData={faultData}
            opportunityData={singleOpportunityData}
            selectedNeighborLogs={selectedOffsetWells}
            clickedOffTraces={clickedOffTraces}
            setClickedOffTraces={this.setClickedOffTraces}
            handleSelectNeighbor={handleSelectNeighbor}
            handleUnselectNeighbor={handleUnselectNeighbor}
          />
          <div style={{'position': 'relative', top: '-45px', width: '300px', margin: 'auto'}}>
            <Select
              options={gridPopertyOptions}
              onChange={(val) => updateGridProperty(val.value)}
              isMulti={false}
              value={gridPopertyOptions.find(i => i.value === gridProperty)}
            />
          </div>
        </React.Fragment>
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }

  render() {
    const { 
      time,
      oilRate,
      waterCut,
      gor,
      logData,
      structSectionDataNS,
      structSectionDataEW,
      modelSectionData,
      analogNeighborData,
      selectedNeighborLogs,
      gridData,
    } = this.state

    const {
      match,
      wellData,
      datasetId,
      realDatasetId,
      opportunityData,
      structureZMatrix,
      structureGridX,
      structureGridY,
      faultData,
      caseObj,
      updateOpportunity,
      updateOpportunityComments,
      offsetWells,
      selectedOffsetWells,
      neighborLogs,
      handleSelectNeighbor,
      handleUnselectNeighbor,
      clearAllNeighbors
    } = this.props

    const { opportunityId } = match.params
    const singleOpportunity = opportunityData ? opportunityData.find(i => i._id === opportunityId) : null
    const wellId = singleOpportunity.name || singleOpportunity.wellId

    const map = this.makeConstantMap()



    return (
      <Grid className="opportunity-outer" container spacing={2}>
        <Grid item xs={12}>
          { this.makeNavLinks() }
        </Grid>
        <Grid item xs={12}>
          <div className='opportunity-container'>
            <OpportunityRoutes 
              map={map}
              caseId={singleOpportunity.case_id}
              datasetId={datasetId}
              realDatasetId={realDatasetId}
              wellData={wellData}
              structureZMatrix={structureZMatrix}
              structureGridX={structureGridX}
              structureGridY={structureGridY}
              faultData={faultData}
              caseObj={caseObj}
              singleOpportunity={singleOpportunity}
              time={time}
              oilRate={oilRate}
              waterCut={waterCut}
              gor={gor}
              logData={logData}
              analogNeighborData={analogNeighborData}
              structSectionDataNS={structSectionDataNS}
              structSectionDataEW={structSectionDataEW}
              wellId={wellId}
              zone={singleOpportunity.zone}
              gridData={gridData}
              updateOpportunity={updateOpportunity}
              modelSectionData={modelSectionData}
              selectedNeighborLogs={selectedNeighborLogs}
              offsetWells={offsetWells}
              neighborLogs={neighborLogs}
              selectedOffsetWells={selectedOffsetWells}
              handleSelectNeighbor={handleSelectNeighbor}
              handleUnselectNeighbor={handleUnselectNeighbor}
              clearAllNeighbors={clearAllNeighbors}
              setCurPage={this.setCurPage}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <CommentBox
            threadId={singleOpportunity._id}
            updateOpportunityComments={updateOpportunityComments}
            />
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(RecompletionOpportunity)
