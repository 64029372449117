const SDAS3Categories = {
  WELL_MASTER: 'Well_Master',
  WELL_GROUPS: 'Well_Groups',
  WELL_PRODUCTION_MONTHLY: 'Well_Production_Monthly',
  WELL_PRODUCTION_MONTHLY_ALLOCATED: 'Well_Production_Monthly_Allocated',
  WELL_INJECTION_MONTHLY: 'Well_Injection_Monthly',
  WELL_PRESSURE_TEST_STATIC: 'Well_Pressure_Test_Static',
  WELL_PRODUCTION_LOG: 'Well_Production_Log',
  WELL_INJECTION_LOG: 'Well_Injection_Log',
  WELL_PERFORATIONS: 'Well_Perforations',
  WELL_TOPS: 'Well_Tops',
  FLUID_PVT: 'Fluid_PVT',
  WELL_EVENTS: 'Well_Events',
  INTERVALS: 'Intervals',
  RESERVOIR_PRESSURE_STATIC: 'Reservoir_Pressure_Static',
  WELL_INTERVAL_PROPERTIES: 'Well_Interval_Properties',
  WELL_COMPLETION_ATTRIBUTES: 'Well_Completion_Attributes',
}

const FileCategories = {
  ...SDAS3Categories,
  WELL_TRAJECTORIES: 'Well_Trajectories',
  GRID_DATA: 'Grid_Data',
  FAULT_DATA: 'Fault_Data',
  MODEL_DATA: 'Model_Data',
  LOG_DATA: 'Log_Data',
  WELL_ALIAS: 'Well_Alias',
  WELLBORE_DIAGRAMS: 'Wellbore_Diagrams',
}

const GridCategories = [
  'currentHcpt',
  'dtf',
  'goc',
  'isochore',
  'k',
  'netPay',
  'originalHcpt',
  'owc',
  'phi',
  'structure',
  'sw',
]

const TwoDModelCategories = [

]

export {
  SDAS3Categories,
  FileCategories,
  GridCategories,
  TwoDModelCategories,
}
