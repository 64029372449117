import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Execution extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Recompletions Execution Steps
        </div>
        <div className="content-header"> 
          Step 1: Initialization
        </div>
        <div className="content-row"> 
          Same as for the Recompletions module.
        </div>
        <div className="content-header"> 
          Step 2: Engineering Analytics
        </div>
        <div className="content-row"> 
          Same as for the Recompletions module.
        </div>
        <div className="content-header"> 
          Step 3: Log/Zone Analytics
        </div>
        <div className="content-row"> 
          Same as for the Recompletions module.
        </div>
        <div className="content-header"> 
          Step 4: Attribute Mapping
        </div>
        <div className="content-row"> 
          Same as for the Recompletions module.
        </div>
        <div className="content-header"> 
          Step 5: Search Domain Definition
        </div>
        <div className="content-row"> 
          The first part of this step is the 3D domain definition. This allows subsequent steps of the module to confine the search of horizontal opportunities to the areas of interest. Specifically, the user can constrain the domain by stratigraphy, spatially, and by depth.
        </div>
        <div className="content-row"> 
          This second part of this step creates a 3D spacing property. The objective is to prevent new targets from conflicting with existing well paths. To do this, SRO builds 3D meshes around existing well trajectories with a given spacing parameter. This results in a set of curved 3D cylinders encompassing existing well surveys. These 3D meshes are then used to create a Boolean model property where cells are flagged if they are located within these 3D volumes. A ray tracing algorithm is used to do this. The resulting spacing property will later be incorporated in the RPOS model prior to the search.
        </div>
        <div className="content-row"> 
          For further information, please refer to the pdf manual subsection Map Generation (Spacing & Others) (Sweet Spots Module).
        </div>
        <div className="content-header"> 
          Step 6: Drainage Analysis
        </div>
        <div className="content-row"> 
          This step performs the 3D equivalent of the 2D drainage analysis reviewed in the recompletion workflow. However, because it is performed in 3D space, there is no longer any need for the drainage index. The algorithm uses the drained pay thickness and production volume of the well to geometrically estimate the radius (and therefore 3D volume) that is going to be allocated to (or drained by) existing wells. This analysis can be performed on zone-specific basis as well using drained pay thickness in the zone together with the production allocated to it.
        </div>
        <div className="content-row"> 
          For further information, please refer to the pdf manual subsection Drainage Analysis (Horizontal Module).
        </div>
        <div className="content-header"> 
          Step 7: Sweetspot Ranking
        </div>
        <div className="content-row"> 
          This step’s objective is to create various 3D properties such as net-to-gross (if user has not provided one), zone index, oil window index, distance-to-fault index, and finally a relative probability of success (RPOS) property that aggregates trends of multiple user-defined attributes (ideally those that are known to impact production). As with the vertical sweet spot grids, this model property can include geologic attributes such as current hydrocarbon pore volume, permeability, fracture intensity, and baffle layers as well as other geoengineering attributes such as spacing, drainage, recent water cut and GOR trends, zone-specific fluid contacts, contact standoffs, and so on. SRO automatically includes the domain and spacing attributes. The user can also include any other attribute if it has been imported as a model property.
        </div>
        <div className="content-row"> 
          For further information, please refer to the pdf manual subsection Sweet Spot Ranking (Horizontal Module).
        </div>
        <div className="content-header"> 
          Step 8: Target Search
        </div>
        <div className="content-row"> 
          This step uses a global search algorithm to find optimal targets. The main objective is to maximize a given user-provided attribute. By default, the algorithm maximizes the sum of the RPOS attribute along a given target length. Initially, the algorithm identifies a set of target cells from where it will start the search. These are cells that meet a set of user-defined attribute cutoffs (e.g., targets with cells having RPOS higher than the 90th percentile and at least 200 meters away from faults).
        </div>
        <div className="content-row"> 
          For further information, please refer to the pdf manual subsection Target Search (Horizontal Module).
        </div>
        <div className="content-header"> 
          Step 9: Interference Analysis
        </div>
        <div className="content-row"> 
          The goal of this step is to select the best set of non-interfering candidates that optimize a given objective function. A typical objective is to simply maximize production, which is why this step is described after the production forecast in this workflow.
        </div>
        <div className="content-header"> 
          Step 10: Production Forecast
        </div>
        <div className="content-row"> 
          As with the recompletions and vertical sweet spots modules, this step uses the selected forecast method to generate a prediction for each opportunity’s initial production (IP) rate and estimates an EUR using the predicted IP and the decline parameters aggregated from the neighborhood wells. There are also several additional forecast methods specific to horizontal new drills that can be utilized.
        </div>
        <div className="content-row"> 
          For further information regarding the forecast methods used to generate the IP, please refer to the pdf manual subsection Production Forecast and subsection Other Production Forecasts for Horizontal Targets.
        </div>
        <div className="content-header"> 
          Step 11: Filtering & Finalization
        </div>
        <div className="content-row-no-padding"> 
          Same as <b>Step 8: Opportunity Filtering</b> for the Recompletions module.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Execution)
