import autobind from 'autobind-decorator'
import React, { Component } from 'react'

import { ChickletConfigurations } from './chickletTypes'
import './Chicklet.scss'

const baseClass = 'chicklet'

@autobind class Chicklet extends Component {
  constructor(props) {
    super(props)
    const { chickletType } = props
    const configuration = ChickletConfigurations[chickletType]
    const valid = !!configuration

    if (!valid) {
      this.state = {
        valid,
      }
    } else {
      const { text, className } = configuration

      this.state = {
        valid,
        text,
        className,
      }
    }
  }

  render() {
    const { text, className, valid } = this.state

    if (!valid) {
      return null
    }

    return (
      <div className={`${baseClass} ${className}`}>
        {text}
      </div>
    )
  }
}

export default Chicklet
