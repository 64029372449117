import UploadParameters from '../../components/common/FileUploader/uploadParameterConfigurations'
import Descriptions from './groupDescriptions/index'
import React from 'react'

const baseClass = 'group-selector__group-option__content__status'

const StatusConfigurations = {
  INCOMPLETE: {
    text: 'INCOMPLETE',
    className: `${baseClass}--incomplete`,
  },
  ERROR: {
    text: 'ERROR',
    className: `${baseClass}--errors`,
  },
  COMPLETE: {
    text: 'COMPLETE',
    className: `${baseClass}--complete`,
  },
  IN_PROGRESS: {
    text: 'IN PROGRESS',
    className: `${baseClass}--in_progress`
  }
}

const StatusTypes = Object.keys(StatusConfigurations).reduce((acc, type) => {
  acc[type] = type
  return acc
}, {})

const CategoryTypes = {
  'SINGLE': 'SINGLE',
  'ARRAY': 'ARRAY',
}

//NOTE: if a group has subgroups (SURFACES, GEOLOGICAL_DATA), category key/value indicates
// what will go to "selectedParentGroup" state in DatasetFileManager component.
class GroupConfiguration {

  constructor(){
    this.configuration = {
      WELL_INFO: {
        text: 'Well Info',
        type: CategoryTypes.MULTIPLE,
        unitConversion: true,
        categories: [
          {
            category: 'Well_Master',
            text: 'Well Master',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Master.csv',
            fileFormat: 'csv',
            required: false,
          },
          {
            category: 'Well_Tops',
            text: 'Well Tops',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Tops.csv',
            fileFormat: 'csv',
            required: false,
          },
          {
            category: 'Well_Alias',
            text: 'Well Name Alias',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Alias.csv',
            fileFormat: 'csv',
            required: false,
          },
          {
            category: 'Well_Groups',
            text: 'Well Groups',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Groups.csv',
            fileFormat: 'csv',
            required: false,
          },
          {
            category: 'Well_Events',
            text: 'Well Events',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Events.csv',
            fileFormat: 'csv',
            required: false,
          },
          {
            category: 'Well_Perforations',
            text: 'Well Perforations',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Perforations.csv',
            fileFormat: 'csv',
            required: false,
          },
          {
            category: 'Well_Completion_Attributes',
            text: 'Well Completion Attributes',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Perforations.csv', //Needs to be changed.
            fileFormat: 'csv',
            required: false,
          },
        ],
      },
      PRODUCTION_AND_INJECTION: {
        text: 'Well Production and Injection',
        categories: [
          {
            category: 'Well_Production_Monthly',
            text: 'Well Monthly Production',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Production_Monthly.csv',
            fileFormat: 'csv',
            required: false,
          },
          {
            category: 'Well_Injection_Monthly',
            text: 'Well Monthly Injection',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Injection_Monthly.csv',
            fileFormat: 'csv',
            required: false,
          },
        ],
      },
      TESTING: {
        text: 'Testing',
        unitConversion: true,
        categories: [
          {
            category: 'Fluid_PVT',
            text: 'Fluid PVT',
            type: CategoryTypes.SINGLE,
            templateName: 'Fluid_PVT.csv',
            fileFormat: 'csv',
            required: false,
          },
          {
            category: 'Well_Pressure_Test_Static',
            text: 'Static Well Pressure Test (Monthly)',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Pressure_Test_Static.csv',
            fileFormat: 'csv',
            required: false,
          },
          {
            category: 'Reservoir_Pressure_Static',
            text: 'Static Reservoir Pressure Test (Monthly)',
            type: CategoryTypes.SINGLE,
            templateName: 'Reservoir_Pressure_Static_Monthly.csv',
            fileFormat: 'csv',
            required: false,
          }, {
            category: 'Well_Production_Log',
            text: 'Well Production Log',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Production_Log.csv',
            fileFormat: 'csv',
            required: false,
          }, {
            category: 'Well_Injection_Log',
            text: 'Well Injection Log',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Injection_Log.csv',
            fileFormat: 'csv',
            required: false,
          }, {
            category: 'Well_Production_Monthly_Allocated',
            text: 'Well Production Monthly Allocated',
            type: CategoryTypes.SINGLE,
            templateName: 'Well_Production_Monthly_Allocated.csv',
            fileFormat: 'csv',
            required: false,
          },
        ],
      },
      // DRILLING_AND_COMPLETION: {
      //   text: 'Drilling and Completion',
      //   categories: [
      //     {
      //       category: 'Well_Perforations',
      //       text: 'Well Perforations',
      //       type: CategoryTypes.SINGLE,
      //       templateName: 'Well_Perforations.csv',
      //       fileFormat: 'csv',
      //       required: false,
      //     },
      //   ],
      // },
      WELLBORE_DIAGRAMS: {
        text: 'Wellbore Diagrams',
        categories: [
          {
            category: 'Wellbore_Diagrams',
            text: 'Wellbore Diagrams',
            type: CategoryTypes.ARRAY,
            templateName: 'ExampleWellboreDiagram.png',
            fileFormat: 'png',
            required: false,
          },
        ],
      },
      WELL_TRAJECTORIES: {
        text: 'Well Trajectories',
        unitConversion: true,
        categories: [
          {
            category: 'Well_Trajectories',
            text: 'Well Trajectories',
            type: CategoryTypes.ARRAY,
            templateName: 'ExampleTrajectoryFile.dev',
            fileFormat: 'DEV',
            required: false,
          },
        ],
      },
      // OPERATION: {
      //   text: 'Operation',
      //   categories: [
      //     {
      //       category: 'Well_Events',
      //       text: 'Well Events',
      //       type: CategoryTypes.SINGLE,
      //       templateName: 'Well_Events.csv',
      //       fileFormat: 'csv',
      //       required: false,
      //     },
      //   ],
      // },
      // INTERVALS_ZONES: {
      //   text: 'Interval/Zones',
      //   categories: [
      //     {
      //       category: 'Well_Tops',
      //       text: 'Well Tops',
      //       type: CategoryTypes.SINGLE,
      //       templateName: 'Well_Tops.csv',
      //       fileFormat: 'csv',
      //       required: false,
      //     },
      //     {
      //       category: 'Intervals',
      //       text: 'Interval Properties',
      //       type: CategoryTypes.SINGLE,
      //       templateName: 'Intervals.csv',
      //       fileFormat: 'csv',
      //       required: false,
      //     },
      //     {
      //       category: 'Well_Interval_Properties',
      //       text: 'Well Interval Properties',
      //       type: CategoryTypes.SINGLE,
      //       templateName: 'Well_Interval_Properties.csv',
      //       fileFormat: 'csv',
      //       required: false,
      //     },
      //   ],
      // },
      SURFACES: {
        text: 'Surfaces',
        isOpen: false,
        category: 'Grid_Data',
        value: 'GRIDS',
        subgroups: {
          STRUCTURE_GRIDS: {
            text: 'Structure Grids',
            categories: [
              {
                category: 'structure',
                text: 'Structure Grids',
                type: CategoryTypes.ARRAY,
                templateName: 'ExampleGridFile',
                fileFormat: 'EarthVision',
                required: false,
                uploadParameters: UploadParameters.CONVERTABLE_GRIDS(),
              },
            ],
          },
          OWC_GRIDS: {
            text: 'OWC Grids',
            categories: [
              {
                category: 'owc',
                text: 'OWC Grids',
                type: CategoryTypes.ARRAY,
                templateName: 'ExampleGridFile',
                fileFormat: 'EarthVision',
                required: false,
                uploadParameters: UploadParameters.CONVERTABLE_GRIDS(),
              },
            ],
          },
          GOC_GRIDS: {
            text: 'GOC Grids',
            categories: [
              {
                category: 'goc',
                text: 'GOC Grids',
                type: CategoryTypes.ARRAY,
                templateName: 'ExampleGridFile',
                fileFormat: 'EarthVision',
                required: false,
                uploadParameters: UploadParameters.CONVERTABLE_GRIDS(),
              },
            ],
          },
        },
      },
      FAULTS: {
        text: 'Fault Traces',
        categories: [
          {
            category: 'Fault_Data',
            text: 'Fault Traces',
            type: CategoryTypes.ARRAY,
            templateName: 'ExampleFaultFile',
            fileFormat: 'txt',
            required: false,
            uploadParameters: UploadParameters.FAULT_TRACES(),
          },
        ],
      },
      LOGS: {
        text: 'Logs',
        categories: [
          {
            category: 'Log_Data',
            text: 'Logs',
            type: CategoryTypes.ARRAY,
            templateName: 'ExampleLASFile.las',
            fileFormat: 'las',
            required: false,
            uploadParameters: UploadParameters.WELL_NAME_FIELD(),
          },
        ],
      },
      GEOLOGICAL_MODELS: {
        text: 'Geological Models',
        category: 'Model_Data',
        value: 'GEOLOGICAL_MODELS',
        isOpen: false,
        subgroups: {
          THREE_D_MODEL: {
            text: '3D Model',
            category: 'THREE_D_MODEL',
            categories: [
              {
                category: 'Model_Data',
                text: '3D Model',
                type: CategoryTypes.SINGLE,
                templateName: 'ExampleModelFile',
                fileFormat: '',
                required: false,
                uploadParameters: UploadParameters['3D']()
              },
            ],
          },
          TWO_D_MODEL: {
            text: '2.5D Model',
            category: 'Grid_Data',
            value: 'TWO_D_MODEL',
            subgroups: {
              ISOCHORE_GRIDS: {
                text: 'Isochore Grids',
                categories: [
                  {
                    category: 'isochore',
                    text: 'Isochore Grids',
                    type: CategoryTypes.ARRAY,
                    templateName: 'ExampleGridFile',
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.CONVERTABLE_GRIDS(),
                  },
                ],
              },
              ORIGINAL_HCPT_GRIDS: {
                text: 'Original HCPT Grids',
                categories: [
                  {
                    category: 'originalHcpt',
                    text: 'Original HCPT Grids',
                    type: CategoryTypes.ARRAY,
                    templateName: 'ExampleGridFile',
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.CONVERTABLE_GRIDS(),
                  },
                ],
              },
              CURRENT_HCPT_GRIDS: {
                text: 'Current HCPT Grids',
                categories: [
                  {
                    category: 'currentHcpt',
                    text: 'Current HCPT Grids',
                    type: CategoryTypes.ARRAY,
                    templateName: 'ExampleGridFile',
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.CONVERTABLE_GRIDS(),
                  },
                ],
              },
              NET_PAY_GRIDS: {
                text: 'Net Pay Grids',
                categories: [
                  {
                    category: 'netPay',
                    text: 'Net Pay Grids',
                    type: CategoryTypes.ARRAY,
                    templateName: 'ExampleGridFile',
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.CONVERTABLE_GRIDS(),
                  },
                ],
              },
              POROSITY_GRIDS: {
                text: 'Porosity Grids',
                categories: [
                  {
                    category: 'phi',
                    text: 'Porosity Grids',
                    type: CategoryTypes.ARRAY,
                    templateName: 'ExampleGridFile',
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.GRIDS(),
                  },
                ],
              },
              WATER_SATURATION_GRIDS: {
                text: 'Water Saturation Grids',
                categories: [
                  {
                    category: 'sw',
                    text: 'Water Saturation Grids',
                    templateName: 'ExampleGridFile',
                    type: CategoryTypes.ARRAY,
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.GRIDS(),
                  },
                ],
              },
              PERMEABILITY_GRIDS: {
                text: 'Permeability Grids',
                categories: [
                  {
                    category: 'k',
                    text: 'Permeability Grids',
                    templateName: 'ExampleGridFile',
                    type: CategoryTypes.ARRAY,
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.GRIDS(),
                  },
                ],
              },
              DISTANCE_TO_FAULT_GRIDS: {
                text: 'Distance To Faults Grids',
                categories: [
                  {
                    category: 'dtf',
                    text: 'Distance To Faults Grids',
                    type: CategoryTypes.ARRAY,
                    templateName: 'ExampleGridFile',
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.CONVERTABLE_GRIDS(),
                  },
                ],
              },
              FACIES_GRIDS: {
                text: 'Facies Grids',
                categories: [
                  {
                    category: 'facies',
                    text: 'Facies Grids',
                    type: CategoryTypes.ARRAY,
                    templateName: 'ExampleGridFile',
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.GRIDS(),
                  },
                ],
              },
              NET_TO_GROSS_GRIDS: {
                text: 'NTG Grids',
                categories: [
                  {
                    category: 'ntg',
                    text: 'Net To Gross Grids',
                    type: CategoryTypes.ARRAY,
                    templateName: 'ExampleGridFile',
                    fileFormat: 'EarthVision',
                    required: false,
                    uploadParameters: UploadParameters.GRIDS(),
                  },
                ],
              },
            }
          }
        }
      },
    }
  }
  //End of constructor

  //getters
  get groupIds() {
    return Object.keys(this.configuration).reduce((acc, category) => {
      acc[category] = category
      return acc
    }, {})
  }

  get categoryNames() {
    return Object.values(this.configuration).reduce(function reduceFunction(acc, group) {
      if (group.categories) {
        group.categories.forEach((category) => {
          acc.push(category.category)
        })
      } else if (group.subgroups) {
        Object.values(group.subgroups).reduce((acc, subgroups) => reduceFunction(acc, subgroups), acc)
      }
      return acc
    }, [])
  }

  addCustomGrids(customGridData) {
    //This method adds custom grids to the configuration
    if(customGridData && customGridData.length > 0) {
      const customGrids = customGridData.map(x => new CustomGridObject(x.property, x.files, x.id))
      const customObject = {}
      for(let i = 0; i < customGrids.length; i ++){
        customObject[`CUSTOM_GRIDS_${i}`] = customGrids[i]
      }
      //This puts the custom grids first on the list.  That's why I did it.
      this.configuration.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups = {
        ...customObject,
        ...this.configuration.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups
      }
    }
    //Save the data idk we might need it lol.
    this.customGrids = customGridData
  }

  updateWithRequirements(newGroups){
    this.configuration = newGroups
  }
}

class CustomGridObject {
  constructor(property, files, id){
    this.text = `${property} (Custom Grid)`
    this.custom = true
    this.files = files
    this.id = id,
    this.property = property
    this.categories = [
      {
        category: property,
        custom: true,
        text: `${property} (Custom Grid)`,
        type: CategoryTypes.ARRAY,
        required: false,
        uploadParameters: UploadParameters.CONVERTABLE_GRIDS()
      }
    ]
  }

  makeRequired() {
    this.categories[0].required = true
  }
}

// const GroupIds = Object.keys(GroupConfigurations).reduce((acc, category) => {
//   acc[category] = category
//   return acc
// }, {})

// const CategoryNames = Object.values(GroupConfigurations).reduce(function reduceFunction(acc, group) {
//   if (group.categories) {
//     group.categories.forEach((category) => {
//       acc.push(category.category)
//     })
//   } else if (group.subgroups) {
//     Object.values(group.subgroups).reduce((acc, subgroups) => reduceFunction(acc, subgroups), acc)
//   }
//   return acc
// }, [])



export {
  // GroupIds,
  // CategoryNames,
  CategoryTypes,
  StatusConfigurations,
  GroupConfiguration
}
