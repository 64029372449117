import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import API from '../../../lib/api-store'
import { bytesToSize } from '../../../lib/formatters'
import { fileNameFromPath, fileExtFromPath } from '../../../lib/helpers'
import { generateResourceDownloadUrl } from '../../../services/fileService'
import { numberFormatDate } from '../../../services/dateFormatterService'

const baseClass = 'training-module'
const containerClass = `${baseClass}__container`

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    color: '#a7a9ae',
    fontWeight: 600,
    fontSize: '1rem',
  },
  typeIcon: {
    fontSize: '2.5rem',
    color: '#0076c0',
  },
  iconButton: {
    borderRadius: 4,
    border: '2px solid #a7a9ae',
  },
}

const fileIcon = (ext) => {
  switch (ext) {
    case 'doc': return "file-word"
    case 'docx': return "file-word"
    case 'pdf': return "file-pdf"
    case 'zip': return "file-archive"
    default:
      return "file"
  }
}


@autobind class Resources extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resources: []
    }
  }


  componentDidMount() {
    API.getTutorialResources()
      .then((response) => {
        this.setState({
          resources: response,
        })
      })
  }

  handleFileDownload(key) {
    const name = key.replace(/^.*[\\\/]/, '')
    const link = document.createElement('a')
    link.href = generateResourceDownloadUrl(key)
    link.setAttribute('download', name)
    link.target = '_blank'
    link.click()
  }

  render() {
    const { classes } = this.props
    const { resources } = this.state

    return (
      <Paper elevation={0} className={`${classes.root} content-container`}>
        <Table className={classes.table} aria-label="resources table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>File Type</TableCell>
              <TableCell className={classes.cell}>File Name</TableCell>
              <TableCell className={classes.cell}>Date Updated</TableCell>
              <TableCell className={classes.cell}>File Size</TableCell>
              <TableCell className={classes.cell}>File Description</TableCell>
              <TableCell className={classes.cell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources && resources.map(row => (
              <TableRow key={row.name}>
                <TableCell className={classes.cell} component="th" scope="row">
                  <FontAwesomeIcon className={classes.typeIcon} icon={fileIcon(fileExtFromPath(row.Key))} />
                </TableCell>
                <TableCell className={classes.cell}>{fileNameFromPath(row.Key)}</TableCell>
                <TableCell className={classes.cell}>{numberFormatDate(row.LastModified)}</TableCell>
                <TableCell className={classes.cell}>{bytesToSize(row.ContentLength)}</TableCell>
                <TableCell className={classes.cell}>{row.Metadata.description}</TableCell>
                <TableCell className={classes.cell}>
                  <IconButton onClick={() => this.handleFileDownload(row.Key)} className={classes.iconButton} aria-label="download">
                    <GetAppIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

export default withStyles(styles)(Resources)
