import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class OpportunityFiltering extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Opportunity Filtering
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          This step in this workflow uses various attributes to filter the list of generated opportunities. These attributes are user-defined and can include geologic attributes, petrophysical attributes, production attributes, and engineering attributes, which can be selected in the step’s settings.<br/>
          This final step also finalizes the opportunities, saves the case information and various results to the database in preparation for the visualizations phase. Therefore, please be patient as this last step may take a few minutes to complete.
        </div>
        <div className="content-row"> 
          Filter Settings<br/>
          <span className='blue'>Options:</span> mnp, mnp_tvt, hcpt, phi, recentWct, recentGor, k, resD, origHcpt, predictedIp, avgUI, poe, targetP90, targetP10<br/>
          <span className='blue'>Comments:</span> Attributes and the filter (i.e. cutoff value) that will be used to filter the list of opportunities.<br/>
        </div>
        <div className="content-row"> 
          Projection Settings<br/>
          <span className='blue'>Options:</span> Meters<br/>
          <span className='blue'>Comments for width:</span> Projection width (i.e. wells within this distance of a cross section will project onto the line of cross section).<br/>
          <span className='blue'>Comments for length:</span> Length of the cross sections that will show in the Visualizations.<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(OpportunityFiltering)
