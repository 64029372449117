import React from 'react'

import img1 from './static/WellNameAlias1.png'

const WellNameAlias = (
  <div className={'description-container'}>
    <p>One key requirement for the multidisciplinary data integration in SRO is to have consistent well names across the various datasets. In SRO the well name is the unique identifier that links the geological and engineering input data. In practice, well names for the same well often differ depending on the source (e.g. geoscience, petrophysics, engineering). Any differences between naming conventions need to be reconciled prior to loading the data using this input file.</p>
    <p>Well names for the same well often differ depending on the source (e.g., production data, well log data). When this is the case, a well name cross reference or alias is necessary to link the various datasets. The “Well_Alias.csv” template allows SRO to properly link alternate well names. “Well_Alias.csv” is optional providing there is consistency in well names across the various data sources. It is required when data coming from different sources (e.g., production data, well log data) refer to the same well by different names. If you upload this template, both columns should be filled out.</p>
    <p><b>Content:</b> List of alternate well names.</p>
    <p><b>Utility:</b> This allows data that may use an alternate well name or identifier to be compiled into the same dataset.</p>
    <p><b>File Format:</b> CSV template (downloadable) (example shown in Figure 3).</p>
    <p><b>Units:</b> NA</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 3: Example of the first few rows of a “Well_Alias.csv.”</figcaption>
    </figure>
  </div>
)

export default WellNameAlias