import React from 'react'
import autobind from 'autobind-decorator'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'

import CssBaseline from '@material-ui/core/CssBaseline'

import ProductSpace from './ProductSpace'
import ProductHeader from './ProductHeader'
import { logout } from '../redux/actions/user'
import { connect } from 'react-redux'
import API from '../lib/api-store'
  
@autobind class App extends React.Component {
  constructor(props){
    super(props)
    this.idleTimer = null
    this.idleTime = 60 * 60 * 2 * 1000
  }

  componentDidMount(){
    const { user } = this.props

    if(user){
      let accountId = user.accountId
      if(accountId === '5f2854a33c78f6f8b3f7f8fd') {
        this.idleTime = 2 * 60 * 60 * 1000
      }
    }
  }

  handleOnIdle(event) {
    const { logoutAction } = this.props
    API.logout().then(r => logoutAction())
  }

  onWindowClose() {
    const { user } = this.props

    if(user){
      let expireTime = new Date(new Date().getTime() + this.idleTime)
      //expireTime.setMinutes( expireTime.getMinutes() + 30)
      // document.cookie = `timeoutAt=${expireTime.toUTCString()}; path=/`
    }
  }

  handleOnActive(event) {
    document.cookie = `timeoutAt=; expires=Sun, 28 Jun 1914 10:45:00 CET`
  }

  render() {
    window.onbeforeunload = this.onWindowClose
    
    return (
      <div id="outer-container">
        <CssBaseline />
        {/* Baseline for material-ui styling*/}
        {/* <IdleTimer
          ref={ref => {this.idleTimer = ref}}
          timeout={this.idleTime}
          debounce={300}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnActive}
        /> */}
        <Router>
          <React.Fragment>
            <ProductHeader />
            <ProductSpace />
          </React.Fragment>
        </Router>
      </div>
    )
  } 
}

const mapStateToProps = state => ({
  user: state.get('user'),
})

const mapDispatchToProps = dispatch => ({
  logoutAction: user => dispatch(logout(user)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
