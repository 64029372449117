import React, { PureComponent, useState } from 'react'

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Divider from '@material-ui/core/Divider'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CircularProgress from '@material-ui/core/CircularProgress'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem' 
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import styled from '@material-ui/core/styles/styled'

import StatusList from './StatusList'
import CompatibleModules from './CompatibleModules'

import { VALIDATIONS, filterValidationObject } from '../../../../lib/datasetMaps/validations'
import LicenseWarning from '../../../../components/layout/Cases/Execute/components/LicenseWarning'

import autobind from 'autobind-decorator'

import './DatasetValidationDrawer.scss'



const validationContainerClass = `validation-container`
const statusContainerClass = `${validationContainerClass}__status--container`
const statusClass = `${validationContainerClass}__status`

const StatusIcon = ({ status }) => {

  const Attempting = styled(CircularProgress)({
    marginTop: '7px',
  })
  const Error = styled(CancelIcon)({
    marginTop: '7px',
  })
  const Good = styled(CheckCircleIcon)({
    marginTop: '7px',
  })
  const Missing = styled(NewReleasesIcon)({
    marginTop: '7px',
  })

  if (!status.complete && status.inProgress) {
    return <Attempting style={{ width: 15, height: 15 }} />
  }
  if (status.complete && !status.successful) {
    return <Error color="error" />
  }
  if (status.complete && status.successful) {
    return <Good color="primary" />
  }
  if (!status.complete && !status.inProgress) {
    return <Missing color="action" />
  }
}

const StatusAlert = ({ newValidationsImplemented, valid, validationRunning }) => {

  const CustomAlert = styled(Alert)({
    paddingTop: "3px",
    paddingBottom: "3px",
    height: "40px",
    minHeight: "40px",
    userSelect: "none",
    display: "flex",
    justifyContent: "center"
  })
  const AlertHeader = styled(AlertTitle)({
    fontSize: "1.4em",
    fontWeight: "500",
    margin: "0 0 -2px 0"
  })

  if(validationRunning){
    return (
    <CustomAlert severity="info">
      <AlertHeader>Validation In Progress</AlertHeader>
    </CustomAlert>
    )
  } else if (!valid) {
    return (
      <CustomAlert severity="error" color="error">
        <AlertHeader>Validation Failed</AlertHeader>
      </CustomAlert>
    )
  } else if (!newValidationsImplemented && valid) {
    return (
      <CustomAlert severity="success" color="success">
        <AlertHeader>Validation Complete</AlertHeader>
      </CustomAlert>
    )
  } else if (newValidationsImplemented && valid) {

      const WarningIconInfo = styled(NewReleasesIcon)({
        width: '0.7em',
        height: '0.7em',
        display: "inline"
      })
      
      const [info, setInfo] = useState(false)

      return (
        <>
          <CustomAlert severity="success" color="success">
            <AlertHeader>Validation Complete</AlertHeader>
          </CustomAlert>
          <CustomAlert severity="warning" color="warning" button onClick={() => setInfo(!info)} style={{cursor: 'pointer'}}>
            <AlertHeader style={{marginTop: "-3px"}}>Missing New Validations   {info ? <ExpandLess style={{marginBottom: "-2px", paddingTop: "3px"}}/> : <ExpandMore style={{marginBottom: "-2px", paddingTop: "3px"}}/>}</AlertHeader>
          </CustomAlert>
          <Collapse in={info} timeout="auto" unmountOnExit style={{minHeight: "101px !important"}}>
            <ListItem>
              <Typography style={{fontSize: "0.825em"}}>
                Since the time of this dataset's validation, new validations have been implemented.  
                You can still run cases off this version.  To ensure quality case results you can create a new dataset from this version, make edits, then re-run validation.  
                Unapplied validations can be viewed by clicking “See Validation Log” and are indicated with a “<WarningIconInfo color="action"/>” icon.
              </Typography>
            </ListItem>
          </Collapse>
        </>
      )
    }
  console.log('ERROR: Status Alert Fallthrough')
  return null
}

@autobind class DatasetValidationDrawer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      validationLogOpen: false,
      validationsOpen: false,
      dataDerivationsOpen: false,
      smartSettingsOpen: false,
    }
  }

  filterValidations(){
    const { selectedVersion } = this.props
    
    // filterValidationObject() removes irrelevent single validations from object.
    let integrationObject = filterValidationObject(selectedVersion.integration, VALIDATIONS.integration, selectedVersion.hasCategories)
    let combinationObject = filterValidationObject(selectedVersion.combination, VALIDATIONS.combination, selectedVersion.hasCategories)
    let moduleIntegrationObject = filterValidationObject(selectedVersion.moduleIntegration || {}, VALIDATIONS.moduleIntegration, selectedVersion.hasCategories)
  
    const validationFields = [
      ...Object.values(integrationObject),
      ...Object.values(combinationObject),
    ]

    const removeNotRun = (object) => {
      Object.keys(object).forEach(key => {
        const singleDDSS = object[key]
        if(!(singleDDSS.complete || singleDDSS.inProgress)){
          delete object[key]
        }
      })
      return object
    }

    let dataDerivationObject = removeNotRun(selectedVersion.derivation || {})
    let smartSettingsObject = removeNotRun(selectedVersion.settingsGeneration || {})
  
    const newValidationsImplemented = !!validationFields.find(f => !f.complete)
    const hasFailedValidations = !!validationFields.find(f => f.successful === false)

    console.log('Searching for incomplete validations...')
    console.log(validationFields.find(f => !f.complete))

    this.setState({
      newValidationsImplemented,
      hasFailedValidations,
      integrationObject,
      combinationObject,
      moduleIntegrationObject,
      dataDerivationObject,
      smartSettingsObject,
      validationLogOpen: selectedVersion.validationAttempted ? true : false,
    })
  }

  reduceEngineCalculations(){
    const { selectedVersion } = this.props
    const {
      integration,
      combination,
      moduleIntegration,
      derivation,
      settingsGeneration,
    } = selectedVersion
    
    const reduce = (object) => {
      return Object.values(object).reduce((acc, validation) => {
        if(validation.error && !acc.error){
          acc.error = validation.error
        } else if(validation.error && acc.error){
          acc.error = acc.error + validation.error
        }
        
        if(validation.successful === false){
          acc.successful = false
        }
        
        if(validation.inProgress === true){
          acc.inProgress = true
        }

        if(validation.complete === false){
          acc.complete = false
        }
        
        if(validation.missing){
          acc.missing = validation.missing
        }
        
        return acc
      }, { successful: true, complete: true, inProgress: false, error: null })
    }

    const isEmpty = (object) => {
      let isEmpty = true
      Object.values(object).forEach(validation => {
        if(Object.values(validation).length > 0){
          isEmpty = false
        }
      })
      return isEmpty
    }

    let combinedValidationObject = {
      ...integration,
      ...combination,
      ...moduleIntegration,
    }

    let reducedDerivationObject = !isEmpty(derivation) ? reduce(derivation) : { complete: true, inProgress: false, successful: false, error: 'Data derivation was not run on this dataset version.'}
    let reducedSettingsObject = !isEmpty(settingsGeneration) ? reduce(settingsGeneration) : { complete: true, inProgress: false, successful: false, error: 'Smart settings generation was not run on this dataset version.'}
    let reducedValidationsObject = !isEmpty(combinedValidationObject) ? reduce(combinedValidationObject) : { complete: true, inProgress: false, successful: false, error: 'Error with validation: Try again or contact support.'}

    
    this.setState({
      reducedDerivationObject,
      reducedSettingsObject,
      reducedValidationsObject,
    })
  }

  componentDidMount(){
    this.filterValidations()
    this.reduceEngineCalculations()
  }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props){
      this.filterValidations()
      this.reduceEngineCalculations()
    }
  }
  
  toggleValidationLog() {
    const isOpen = this.state.validationLogOpen
    this.setState({ validationLogOpen: !isOpen })
  }

  toggleValidations() {
    this.setState({ validationsOpen: !this.state.validationsOpen })
  }

  toggleDataDerivations() {
    this.setState({ dataDerivationsOpen: !this.state.dataDerivationsOpen })
  }

  toggleSmartSettings() {
    this.setState({ smartSettingsOpen: !this.state.smartSettingsOpen })
  }
  
  render(){
    const {
      onValidationButtonClick,
      validationIsOpen,
      selectedVersion,
      type,
      license,
      project,
    } = this.props

    const {
      newValidationsImplemented,
      hasFailedValidations,
      validationLogOpen,
      validationsOpen,
      dataDerivationsOpen,
      smartSettingsOpen,
      integrationObject,
      combinationObject,
      moduleIntegrationObject,
      dataDerivationObject,
      smartSettingsObject,
    } = this.state
  
    const { 
      validationAttempted, 
      validationRunning, 
      valid, 
      enabledModules
    } = selectedVersion

    let curStorageGB = project ? (((project.caseS3StorageSize || 0) + (project.processedFileS3StorageSize || 0) + (project.rawFileS3StorageSize || 0)) / 1024 / 1024 / 1024) : 0
    let storageLimit = license ? license.dataStorageLimit / 1024 / 1024 / 1024 : 5000
    let jobHoursUsed = project ? project.jobHoursUsed : 0
    let jobHourLimit = project ? project.jobHourLimit : 1000

    //Not sure if this is used anymore
    const dateValidated = selectedVersion.dateValidated ? new Date(selectedVersion.dateValidated) :
      selectedVersion.dateCreated ? new Date(selectedVersion.dateCreated) :
      new Date('2019', '11', '09', '12')

      const ValidationHeader = styled(ListItem)({
        display: 'flex',
        justifyContent: 'space-between',
      })
      const Less = styled(ExpandLess)({
        paddingTop: '3px' 
      })
      const More = styled(ExpandMore)({
        paddingTop: '3px' 
      })
    
    if (type=='warnings') {
      return (
        <Drawer
          className={`${validationContainerClass}`}
          variant="persistent"
          anchor="right"
          open={validationIsOpen}
          PaperProps={{ style: { position: 'absolute', minWidth: 300 } }}
        >
          <LicenseWarning
            curStorageGB={curStorageGB}
            storageLimit={storageLimit}
            jobHoursUsed={jobHoursUsed}
            jobHourLimit={jobHourLimit}
            jobsUsed={0}
            jobLimit={100}
            handleClose={onValidationButtonClick}
          />
        </Drawer>
      )
    }

    return (
      <Drawer
        className={`${validationContainerClass}`}
        variant="persistent"
        anchor="right"
        open={validationIsOpen}
        PaperProps={{ style: { position: 'absolute', minWidth: 300 } }}
      >
        <Toolbar
          style={{ justifyContent: 'space-between', paddingLeft: 16, paddingRight: 16 }}
        >
          <Typography variant="h5">
            Validation
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="Close Validation Drawer"
            onClick={onValidationButtonClick}
          >
          <ChevronRightIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        {validationAttempted && newValidationsImplemented !== undefined && (
          <StatusAlert
            newValidationsImplemented={newValidationsImplemented}
            valid={selectedVersion.valid}
            validationRunning={validationRunning}
          />
        )}
        {valid === true && (
            <CompatibleModules valid={valid} enabledModules={enabledModules}/>
        )}
        <ListItem button onClick={this.toggleValidationLog}>
          <Typography variant="h6" >
            <b>See Validation Log </b>
            {validationLogOpen ? <Less/> : <More/>}
          </Typography>
        </ListItem>
        <ListItem>
          <Collapse in={validationLogOpen} timeout="auto" unmountOnExit style={{width: '100%'}}>
            <List>
              <ValidationHeader button onClick={this.toggleValidations}>
                <Typography variant="h6" >
                  <b>Validations</b>
                  {validationsOpen ? <Less/> : <More/>}
                </Typography>
              </ValidationHeader>
              <Divider variant="middle" />
              <Collapse in={validationsOpen} timeout="auto" unmountOnExit style={{width: '100%'}}>
                <List>
                    <StatusList type='combination' label="combination" list={combinationObject} title={true} StatusIcon={StatusIcon}/>
                    <StatusList type='integration' label="integration" list={integrationObject} title={true} StatusIcon={StatusIcon}/>
                    {moduleIntegrationObject && Object.keys(moduleIntegrationObject).length > 0 && (
                      <StatusList type='moduleIntegration' label="moduleIntegration" list={moduleIntegrationObject} title={true} StatusIcon={StatusIcon}/>
                    )}
                </List>
              </Collapse>
              {dataDerivationObject && Object.keys(dataDerivationObject).length > 0 && (
                <>
                  <ValidationHeader button onClick={this.toggleDataDerivations}>
                    <Typography variant="h6" >
                      <b>Data Derivation</b>
                      {dataDerivationsOpen ? <Less/> : <More/>}
                    </Typography>
                  </ValidationHeader>
                  <Divider variant="middle" />
                  <Collapse in={dataDerivationsOpen} timeout="auto" unmountOnExit style={{width: '100%'}}>
                    <List>
                      <StatusList type='derivation' label="derivation" list={dataDerivationObject} title={false} StatusIcon={StatusIcon}/>
                    </List>
                  </Collapse>
                </>
              )}
              {smartSettingsObject && Object.keys(smartSettingsObject).length > 0 && (
                <>
                  <ValidationHeader button onClick={this.toggleSmartSettings}>
                    <Typography variant="h6" >
                      <b>Smart Settings</b>
                      {smartSettingsOpen ? <Less/> : <More/>}
                    </Typography>
                  </ValidationHeader>
                  <Divider variant="middle" />
                  <Collapse in={smartSettingsOpen} timeout="auto" unmountOnExit style={{width: '100%'}}>
                    <List>
                      <StatusList type='settingsGeneration' label="settingsGeneration" list={smartSettingsObject} title={false} StatusIcon={StatusIcon}/>
                    </List>
                  </Collapse>
                </>
              )}
              {/* {reducedDerivationObject && (
                <>
                  <ValidationHeader button={!!reducedDerivationObject.error} onClick={this.toggleDataDerivations}>
                    <Typography variant="h6">
                      <b>Data Derivation</b>
                      {reducedDerivationObject.error && (
                        <>{dataDerivationsOpen ? <Less/> : <More/>}</>
                      )}
                    </Typography>
                    <StatusIcon status={reducedValidationsObject}/>
                  </ValidationHeader>
                  {reducedDerivationObject.error && (
                    <Collapse in={dataDerivationsOpen} timeout="auto" unmountOnExit>
                      <List component="div">
                        <ListItem>
                          <ListItemText primary={reducedDerivationObject.error} />
                        </ListItem>
                      </List>
                    </Collapse>
                  )}
                </>
              )}
              <Divider variant="middle" />
              {reducedSettingsObject && (
                <>
                  <ValidationHeader button={!!reducedSettingsObject.error} onClick={this.toggleSmartSettings}>
                    <Typography variant="h6" >
                      <b>Smart Settings Generation</b>
                      {reducedSettingsObject.error && (
                        <>{smartSettingsOpen ? <Less/> : <More/>}</>
                      )}
                    </Typography>
                    <StatusIcon status={reducedSettingsObject} />
                  </ValidationHeader>
                  {reducedSettingsObject.error && (
                    <Collapse in={smartSettingsOpen} timeout="auto" unmountOnExit>
                      <List component="div">
                        <ListItem>
                          <ListItemText primary={reducedSettingsObject.error} />
                        </ListItem>
                      </List>
                    </Collapse>
                  )}
                </>
              )} */}
            </List>
          </Collapse>
        </ListItem>

      </Drawer>
    )
  }

}

export default DatasetValidationDrawer
