import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class LogCleaning extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Grid Cleaning
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          These settings include the list of grids to clean with respect to the OWC/GOC. They also include other corresponding information such as the default values when above GOC or below OWC, as well as the minimum and maximum values of each grid
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(LogCleaning)
