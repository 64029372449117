const VALIDATIONS = {
  integration: {
    title: 'Cross File Validation',
    value: 'integration',
    validations: {
      grid: {
        label: 'Cross Grid Validation',
        dateImplemented: new Date('2019', '10', '09', '12'),
        type: 'integration',
        minimumFileRequirements: ['Grid_Data'],
      },
      structureModel: {
        label: 'Structure Grid Model Cross Validation',
        dateImplemented: new Date('2020', '05', '21', '12'),
        type: 'integration',
        minimumFileRequirements: ['Grid_Data', 'Model_Data'],
        requirementType: 'all',
      },
      wellGrid: {
        label: 'Grids and Well Master Cross Validation',
        dateImplemented: new Date('2019', '10', '09', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Master',
          'Grid_Data',
        ],
        requirementType: 'all',
      },
      modelWell: {
        label: 'Model and Well Master Cross Validation',
        dateImplemented: new Date('2019', '10', '09', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Master',
          'Model_Data',
        ],
        requirementType: 'all',
      },
      wellNames: {
        label: 'Well Name Validation',
        dateImplemented: new Date('2019', '10', '19', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Master',
          'Well_Groups',
          'Well_Production_Monthly',
          'Well_Injection_Monthly',
          'Well_Pressure_Test_Static',
          'Well_Production_Log',
          'Well_Injection_Log',
          'Well_Perforations',
          'Well_Tops',
          'Well_Events',
          'Well_Interval_Properties',
          'Well_Trajectories',
          'Log_Data',
          'Well_Alias',
        ],
        requirementType: 'anyTwo',
        // If it has any two of the files, this validation runs.
        // Could have one minimum requirement.
      },
      zoneNames: {
        label: 'Zone Name Validation',
        dateImplemented: new Date('2019', '10', '19', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Tops',
          'Intervals',
          'Well_Interval_Properties',
          'Grid_Data',
          'Fault_Data',
        ],
        requirementType: 'anyTwo',
        // If it has any two of the files, this validation runs.
        // Could have one minimum requirement.
      },
      perforationDeviation: {
        label: 'Perforation And Deviation Cross Validation',
        dateImplemented: new Date('2020', '01', '01', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Perforations',
          'Well_Trajectories',
        ],
        requirementType: 'all',
      },
      productionInjection: {
        label: 'Production And Injection Cross Validation',
        dateImplemented: new Date('2020', '01', '01', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Production_Monthly',
          'Well_Injection_Monthly',
        ],
        requirementType: 'all',
      },
      productionPerforation: {
        label: 'Production And Perforation Cross Validation',
        dateImplemented: new Date('2020', '01', '01', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Production_Monthly',
          'Well_Perforations',
        ],
        requirementType: 'all',
      },
      topPerf: {
        label: 'Well Tops And Well Perforation Cross Validation',
        dateImplemented: new Date('2020', '10', '20', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Tops',
          'Well_Perforations',
        ],
        requirementType: 'all',
      },
      prodProdAllocated: {
        label: 'Well Production And Well Production Allocated Cross Validation',
        dateImplemented: new Date('2020', '10', '20', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Production_Monthly',
          'Well_Production_Monthly_Allocated',
        ],
        requirementType: 'all',
      },
      wellPressureProd: {
        label: 'Production vs Pressure Data Cross Validation',
        dateImplemented: new Date('2021', '05', '05', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Production_Monthly',
          'Well_Pressure_Test_Static'
        ],
        requirementType: 'all'
      },
      // wellTopsWellInterval: {
      //   label: 'Well Tops And Well Interval Cross Validation',
      //   dateImplemented: new Date('2020', '01', '22', '12'),
      //   type: 'integration',
      //   minimumFileRequirements: [
      //     'Well_Tops',
      //     'Well_Interval_Properties',
      //   ],
      //   requirementType: 'all',
      // },
      wellPressurePVT: {
        label: 'Well Pressure vs PVT Data',
        dateImplemented: new Date('2021', '09', '24', '12'),
        type: 'integration',
        minimumFileRequirements: [
          'Well_Production_Monthly',
          'Fluid_PVT'
        ]
      }
    },
  },
  combination: {
    value: 'combination',
    title: 'File Combination',
    validations: {
      log: {
        label: 'Log File Combination',
        dateImplemented: new Date('2019', '10', '09', '12'),
        type: 'combination',
        minimumFileRequirements: ['Log_Data'],
        requirementType: 'all',
      },
      fault: {
        label: 'Fault File Combination',
        dateImplemented: new Date('2019', '10', '09', '12'),
        type: 'combination',
        minimumFileRequirements: ['Fault_Data'],
        requirementType: 'all',
      },
      grid: {
        label: 'Grids File Combination',
        dateImplemented: new Date('2019', '10', '09', '12'),
        type: 'combination',
        minimumFileRequirements: ['Grid_Data'],
        requirementType: 'all',
      },
      wellTrajectory: {
        label: 'Well Trajectory File Combination',
        dateImplemented: new Date('2019', '10', '09', '12'),
        type: 'combination',
        minimumFileRequirements: ['Well_Trajectories'],
        requirementType: 'all',
      },
      sda: {
        label: 'All File Combination',
        dateImplemented: new Date('2019', '10', '09', '12'),
        type: 'combination',
        minimumFileRequirements: [ 
          'Well_Master',
          'Well_Groups',
          'Well_Production_Monthly',
          'Well_Injection_Monthly',
          'Well_Pressure_Test_Static',
          'Well_Production_Log',
          'Well_Injection_Log',
          'Well_Perforations',
          'Well_Tops',
          'Fluid_PVT',
          'Well_Events',
          'Intervals',
          'Reservoir_Pressure_Static',
          'Well_Interval_Properties',
          'Well_Trajectories' 
        ],
        requirementType: 'anyTwo',
      },
    },
  },
  moduleIntegration: {
    value: 'moduleIntegration',
    title: 'Product Specific Validation',
    validations: {
      SWM: {
        label: 'Waterflood Management',
        dateImplemented: new Date('2020', '02', '10', '12'),
        type: 'moduleIntegration',
        minimumFileRequirements: [
          'Intervals',
          'Grid_Data',
        ],
        requirementType: 'all',
      },
    },
  },
  derivation: {
    value: 'derivation',
    title: 'Data Derivation',
    validations: {
      grid: {
        label: 'Grid Derivations',
        dateImplemented: new Date('2020', '05', '15', '12'),
        type: 'derivation',
      },
      intervals: {
        label: 'Interval Derivation',
        dateImplemented: new Date('2020', '05', '15', '12'),
        type: 'derivation',
      },
      wellIntervals: {
        label: 'Well Intervals Derivation',
        dateImplemented: new Date('2020', '05', '15', '12'),
        type: 'derivation',
        minimumFileRequirements: [
          'Well_Perforations',
          'Well_Master',
          'Well_Tops',
          'Well_Production_Monthly',
          'Well_Pressure_Test_Static'
        ],
        requirementType: 'all'
      },
    }
  },
  settingsGeneration: {
    value: 'settingsGeneration',
    title: 'Smart Settings Generation',
    validations: {
      SGO: {
        label: 'Speedwise: Reservoir Opportunity',
        dateImplemented: new Date('2020', '05', '15', '12'),
        type: 'settingsGeneration',
      },
      SWM: {
        label: 'Speedwise: Waterflood Management',
        dateImplemented: new Date('2020', '05', '15', '12'),
        type: 'settingsGeneration',
      }
    }
  }
}

// --- Filtering out unrun validations without data requirements --- //

// NOTE: Double check logic for SUBGROUP file categories (Gids)
const filterValidationObject = (validationObject, validationMap, hasCategories) => {
  const validations = Object.keys(validationObject)

  validations.forEach(val => {

    if(validationObject[val].inProgress === true || validationObject[val].complete === true){
      if(!["", {}, undefined].includes(validationObject.error)){
        return
      }
    }

    if(!validationMap.validations[val]){
      //If the version has an old validation that we don't use anymore.
      //Delete it.
      delete validationObject[val]
      return
    }

    const { minimumFileRequirements, requirementType } = validationMap.validations[val]
    let meetsDataRequirements = true
    const missingFiles = []

    if(requirementType === 'all'){
      minimumFileRequirements.forEach(fileType => {
        if(hasCategories[fileType] === false){
          meetsDataRequirements = false
          missingFiles.push(fileType)
        }else if(typeof hasCategories[fileType] === 'object'){
        // --- Subgroup Logic --- //
          const parentGroup = hasCategories[fileType]
          // console.log('Found a subgroup category.  Parent group: ', fileType)
          let hasOneSubgroupCategory = false
          Object.keys(parentGroup).forEach(subgroupCategory => {
            if(hasOneSubgroupCategory) {
              return
            }
            if(parentGroup[subgroupCategory]) {
              hasOneSubgroupCategory = true
            }
          })
          if(!hasOneSubgroupCategory){
            // console.log('Parent group has no uploaded files.')
            meetsDataRequirements = false
            missingFiles.push(fileType)
          }
        // ------   End   ------- //
        }
      })
    } else if(requirementType === 'anyTwo'){
      let hasTwo = false
      let files = []
      minimumFileRequirements.forEach(fileType => {
        if(hasTwo) {
          return
        }
        if(hasCategories[fileType]) {
          files.push(fileType)
        }
        if(files.length >= 2) {
          hasTwo = true
        }
      })
      meetsDataRequirements = hasTwo ? true : false
    }


    if(meetsDataRequirements === false){
      console.log('Removing Validation: ', val)
      requirementType === "all" ? console.log('---Missing Files: ', missingFiles) : console.log('---Did not have two required files')
      delete validationObject[val]
    }
  })

  return validationObject
}

export {
  VALIDATIONS,
  filterValidationObject
}
