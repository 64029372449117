import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'

const CommentForm = (props) => {
  const {
    text,
    submitComment,
    handleChangeText,
  } = props

  return (
    <form onSubmit={submitComment}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            name="text"
            placeholder="Leave a Comment"
            multiline
            fullWidth
            margin="normal"
            variant="outlined"
            style={{ margin: 0 }}
            value={text}
            onChange={handleChangeText}
          />
        </Grid>
        <Grid item xs={6}>
          <span> </span>
        </Grid>
        <Grid item xs={6}>
          <Button
            style={{ float: 'right' }}
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CommentForm
