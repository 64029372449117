import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Collapsible from 'react-collapsible'

import LandingPage from './categories/LandingPage'
import GettingStarted from './categories/gettingStarted/GettingStarted'
import Workspaces from './categories/workspaces/Workspaces'
import Cases from './categories/cases/Cases'
import Datasets from './categories/datasets/Datasets'
import Modules from './categories/modules/Modules'
import License from './categories/license/License'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import './UserManual.scss'

const baseClass = 'user-manual'
const tempClass = `${baseClass}__temp`
const navHeaderClass = `${tempClass}__header`
const navLinkClass = `${tempClass}__link`
const contentClass = `${baseClass}__content`
const backButtonClass = `back-btn`
const backButtonContainerClass = `${baseClass}__back-btn-container`
const backButtonIconClass = `${backButtonClass}__icon`

@autobind class UserManual extends Component {
  constructor(props) {
    super(props)
    this.state = {
      prevPath: '/'
    }
  }

  componentDidMount() {
    // if(this.props.location.state) window.prevPath = this.props.location.state.prevPath
    // this.setState({ prevPath: window.prevPath })
  }


  render() {

    // const prevPath = this.state.prevPath
    
    return (
    <>
      {/* <div className={backButtonContainerClass}>
        <NavLink className={backButtonClass} to={prevPath}>
          <ChevronLeftIcon className={backButtonIconClass} />
          Back
        </NavLink>
      </div> */}
      <div className={`${baseClass} content-container`}>
        <div className={tempClass}>
          <div className={navHeaderClass}>
            Getting Started
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/gettingStarted/intro' className='setting-link-font'>
              Introduction
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/gettingStarted/importance' className='setting-link-font'>
              Why is this tool useful?
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/gettingStarted/modules' className='setting-link-font'>
              Speedwise Reservoir Modules
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/gettingStarted/help' className='setting-link-font'>
              How to get help / send requests
            </NavLink>
          </div>
          <div className={navHeaderClass}>
            License
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/license/create' className='setting-link-font'>
              Add Users
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/license/remove' className='setting-link-font'>
              Remove Users
            </NavLink>
          </div>
          <div className={navHeaderClass}>
            Workspaces
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/workspaces/intro' className='setting-link-font'>
              Introduction to Workspaces
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/workspaces/create' className='setting-link-font'>
              Create or edit a Workspace
            </NavLink>
          </div>
          
          <div className={navHeaderClass}>
            Datasets
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/intro' className='setting-link-font'>
              Introduction to Datasets
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/create' className='setting-link-font'>
              Dataset Operations
            </NavLink>
          </div>

        <Collapsible
          className="setting-menu-trigger"
          openedClassName="setting-menu-trigger-open"
          trigger={'Data Categories'}
        >
          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/categories/overview' className='setting-link-font'>
              Data Categories Overview
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/categories/wells' className='setting-link-font'>
              Wells
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/categories/testing' className='setting-link-font'>
              Testing
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/categories/surfaces' className='setting-link-font'>
              Surfaces
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/categories/faults' className='setting-link-font'>
              Fault Traces
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/categories/logs' className='setting-link-font'>
              Logs
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/categories/models' className='setting-link-font'>
              Geological Models
            </NavLink>
          </div>
        </Collapsible>

          <div className={navLinkClass}>
            <NavLink to='/training/manual/datasets/import' className='setting-link-font'>
              Import RESQML
            </NavLink>
          </div>

          <div className={navHeaderClass}>
            Cases
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/cases/intro' className='setting-link-font'>
              Introduction to Cases
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/cases/actions' className='setting-link-font'>
              Case Actions
            </NavLink>
          </div>
          <div className={navLinkClass}>
            <NavLink to='/training/manual/cases/workflow' className='setting-link-font'>
              Case Workflow / How to Run a Case
            </NavLink>
          </div>
          
          <div className={navHeaderClass}>
            Modules
          </div>
          <Collapsible
            className="setting-menu-trigger"
            openedClassName="setting-menu-trigger-open"
            trigger={'Recompletions'}
          >
            <div className={navLinkClass}>
              <NavLink to='/training/manual/modules/recompletions/settings' className='setting-link-font'>
                Settings
              </NavLink>
            </div>
            <div className={navLinkClass}>
              <NavLink to='/training/manual/modules/recompletions/execution' className='setting-link-font'>
                Execution
              </NavLink>
            </div>
            <div className={navLinkClass}>
              <NavLink to='/training/manual/modules/recompletions/visualization' className='setting-link-font'>
                Visualization
              </NavLink>
            </div>
          </Collapsible>
          <Collapsible
            className="setting-menu-trigger"
            openedClassName="setting-menu-trigger-open"
            trigger={'Sweet Spots'}
          >
            <div className={navLinkClass}>
              <NavLink to='/training/manual/modules/sweetSpots/settings' className='setting-link-font'>
                Settings
              </NavLink>
            </div>
            <div className={navLinkClass}>
              <NavLink to='/training/manual/modules/sweetSpots/execution' className='setting-link-font'>
                Execution
              </NavLink>
            </div>
            <div className={navLinkClass}>
              <NavLink to='/training/manual/modules/sweetSpots/visualization' className='setting-link-font'>
                Visualization
              </NavLink>
            </div>
          </Collapsible>
          <Collapsible
            className="setting-menu-trigger"
            openedClassName="setting-menu-trigger-open"
            trigger={'Horizontals'}
          >
            <div className={navLinkClass}>
              <NavLink to='/training/manual/modules/horizontals/settings' className='setting-link-font'>
                Settings
              </NavLink>
            </div>
            <div className={navLinkClass}>
              <NavLink to='/training/manual/modules/horizontals/execution' className='setting-link-font'>
                Execution
              </NavLink>
            </div>
            <div className={navLinkClass}>
              <NavLink to='/training/manual/modules/horizontals/visualization' className='setting-link-font'>
                Visualization
              </NavLink>
            </div>
          </Collapsible>
        </div>
        <div className={contentClass}>
          <Switch>
            <Route path="/training/manual/gettingStarted" component={GettingStarted} />
            <Route path="/training/manual/workspaces" component={Workspaces} />
            <Route path="/training/manual/cases" component={Cases} />
            <Route path="/training/manual/license" component={License} />
            <Route path="/training/manual/datasets" component={Datasets} />
            <Route path="/training/manual/modules" component={Modules} />
            <Route path="/training/manual/" component={LandingPage} />
          </Switch>
        </div>
      </div>
    </>)
  }
}

          // <Route path="/manual/workspaces" component={workspaces} />
          // <Route path="/manual/cases" component={cases} />
          // <Route path="/manual/datasets" component={datasets} />
          // <Route path="/manual/modules" component={modules} />


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(UserManual)
