import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Intro extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    console.log('me to')
    return (
      <React.Fragment>
        <div className="content-title"> 
          Introduction to Workspaces
        </div>
        <div className="content-row"> 
          After you launch the SRO tool, you will be taken to the “workspace” page. Workspaces contain all the interdisciplinary data and experiments about an asset. You may choose to have multiple workspaces. For example, you may decide to have a workspace for each reservoir in a field, or you may happen to be working on multiple fields. All the workspaces you make will be stored on the cloud, and therefore you do not need to worry about losing the work or older work taking up room on your hard drive.
        </div>
        <div className="content-row"> 
          <span className='blue'>Basic Information of a workspace:</span> Workspace Name, Basin, Region, Country, Description, Collaborators
        </div>
        <div className="content-row"> 
          <span className='blue'>Workspace Contents:</span> Workspaces contain datasets, cases, and settings. The next sections review each of these in detail.
        </div>
          &ensp;• <span className='blue'>Your Workspace:</span> If you are the original creator of a workspace for your asset team, then you will have the power to edit the content of workspaces (e.g., upload data, run cases, etc.). You will also be able to edit the basic information of the workspace and control who collaborates on the workspace.
        <div className="content-row"> 
          &ensp;• <span className='blue'>Workspaces Shared With You:</span> If you are a collaborator on a workspace, you will still be able to edit the content of workspaces that are shared with you (e.g., upload data, run cases, etc.), but you cannot edit the basic information or change who the collaborators are.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Intro)
