import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Drainage extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Drainage
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Evaluate Reservoir as a single zone (tank)<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> If on, drainage analysis assumes all zones are connected and treats them as a single zone.<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Drainage)
