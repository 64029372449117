import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import SearchDomainDefinitionA from '../../../../../../../../../../static/images/userManual/settings/searchDomainDefinitionA.png'
import SearchDomainDefinitionB from '../../../../../../../../../../static/images/userManual/settings/searchDomainDefinitionB.png'




@autobind class SearchDomainDefinition extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Search Domain Definition
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          The first part of this step is the 3D domain definition. This allows subsequent steps of the module to confine the search of horizontal opportunities to the areas of interest (Figure 28). To do so, the user is given the option to provide the following constraints:
        </div>
        <div className="content-row"> 
          • Stratigraphic Constraints: This constraint allows the user to specify specific formations/zones/ layers to target in the analysis. This can be recommended when the user has prior knowledge of seal zones or non-reservoir formations that are not to be targeted.
        </div>
        <div className="content-row"> 
          • Spatial Constraints: This constraint allows the user to define a spatial region of interest that can be used by the algorithm to limit the lateral extent of the area to be searched. • Depth Constraints: This constraint allows the user to restrict the analysis to a defined depth range. This can be used in situations when the user is aware of drilling limitations such as the deepest horizontal well that can be drilled with the current field technology.
        </div>
        <div className="content-row"> 
          When combined, these constraints help the user articulate what constitutes a valid domain and also helps the algorithm reduce the computational cost and perform a more efficient and focused search.
        </div>
        <div className='image-container'>
          <img src={SearchDomainDefinitionA} className='tooBig' alt="" />
        </div>
        <div className="image-footnote"> 
          Polygon defining spatial region of interest (left), and cross section showing cells of interest (right).
        </div>
        <div className="content-row"> 
          This second part of this step creates a 3D spacing property, which defines no-go cells. The objective is to prevent new targets from conflicting with existing well paths. To do this, SRO builds 3D meshes around existing well trajectories with a given spacing parameter. This results in a set of curved 3D cylinders encompassing existing well surveys. These 3D meshes are then used to create a Boolean model property where cells are flagged if they are located within these 3D volumes (Figure 29). A ray tracing algorithm is used to do this. The resulting spacing property will later be incorporated in the RPOS model prior to the search.
        </div>
        <div className='image-container'>
          <img src={SearchDomainDefinitionB} className='tooBig' alt="" />
        </div>
        <div className="image-footnote"> 
          Cylinder around existing well paths (left), and the spacing property showing conflicting cells (right).
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(SearchDomainDefinition)
