import React from 'react'

import img1 from './static/WellTops1.jpg'
import img2 from './static/WellTops2.png'

const WellTops = (
  <div className={'description-container'}>
    <p>An example of the “Well_Tops.csv” template is shown in Figure 2. This should be populated after quality control and preparation recommendations are completed. This is to ensure that the most complete and consistent set of well tops is used. Well names should be consistent with the Well_Master.csv naming conventions and well top names must be consistent with those used in the “Intervals.csv” spreadsheet.</p>
    <p><b>Content:</b> A well top is necessary for every interval of interest that the wellbore penetrates.</p>
    <p><b>Utility:</b> Well formation tops are central to the production allocation estimation and opportunity identification. </p>
    <p>The results of production allocation in SRO is central to both drainage analysis and production rate forecasting. One key requirement for effective production allocation is the development of a practical and discernible reservoir zonation. This concept is not unique to SRO.</p>
    <p><b>File Format:</b> CSV template (downloadable) (example shown in Figure 2).</p>
    <p><b>Units:</b> Currently all units must be in meters. We understand that many users are used to working in feet, and we are working on an update that will allow inputs to be in feet.</p>
    <p><b>Conventions:</b> TopZ -  Values below sea level need to be positive</p>
    <p><b>Important Notes:</b></p>
    <ol>
      <li>Not all columns are required. See Table 3 for specifics.</li>
      <li>There should be only one formation top pick for each well unless the well is horizontal and recrosses a top (i.e. there should not be multiple interpreters).</li>
      <li>Reservoir zones in SRO are called intervals and are defined through this input file. One important item to note from this file is that if loading a 2.5D model, there should be properties for each relevant interval, and the exact same names should be used for the intervals and the tops file.</li>
    </ol>
    <figure>
      <img src={img1}/>
      <figcaption>Figure 2: Example of the “Well_Tops.csv” sheet.</figcaption>
    </figure>
    <figure>
      <figcaption>Table 3. Details for the “Well_ Tops.csv.”</figcaption>
      <img src={img2} />
    </figure>
  </div>
)

export default WellTops