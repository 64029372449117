import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import SweetSpotRankingA from '../../../../../../../../../../static/images/userManual/settings/SweetSpotRankingA.png'
import SweetSpotRankingB from '../../../../../../../../../../static/images/userManual/settings/SweetSpotRankingB.png'
import SweetSpotRankingC from '../../../../../../../../../../static/images/userManual/settings/SweetSpotRankingC.png'


@autobind class MapGeneration extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          RPOS Modeling/Ranking Sweet Spots
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          This step creates a sweet spot map, which SpeedWise Reservoir calls a relative probability of success (RPOS) map, that essentially ranks different areas according to various input attributes. It is important to understand that this is a relative probability used for ranking, not an absolute measure of success. This process starts by mapping RPOS for every stratigraphic zone independently using various user-specified input maps for the zone. An RPOS map for a particular zone might be mapped using any number of zone mappable attributes such as: HCPT, permeability, recent water cut, recent gas/oil ratio, cumulative production trends, fluid contact maps, drainage index map (see Drainage Analysis (All Modules) section), proximity to faults, and so on. In addition, any attribute that can be reliably mapped can be used as an input to this mapping process. Different mapping algorithms are used in this process depending on the nature of the attribute to be mapped. These include linear, nearest neighbor, natural neighbor, cubic, and biharmonic spline interpolation methods.
        </div>
        <div className='image-container'>
          <img src={SweetSpotRankingA} className='tooBig' alt="" />
        </div>
        <div className="image-footnote"> 
          Example zone RPOS map (center) along with an example set of zone input maps (corners). The example input maps are HCPT, drainage, distance to fault index, and targetThicknessIndex, which shows areas where the zone is between the OWC and GOC.
        </div>
        <div className="content-row"> 
          The individual zone RPOS input attribute maps are globally normalized (using a global min-max from all zones for each input attribute). A zone RPOS map represents the product of the normalized input attribute maps. Next, a global RPOS map is created by averaging the individual zone RPOS maps. Since the individual zone RPOS maps were created using globally normalized input attribute maps, the global RPOS average map will weigh every zone appropriately, and will essentially reflect the aggregate quality from all the zones at any given location. Finally, the global RPOS map is used together with the spacing grid to identify locations that maximize RPOS while honoring the spacing grid constraints.
        </div>
        <div className='image-container'>
          <img src={SweetSpotRankingB} className='tooBig' alt="" />
        </div>
        <div className="image-footnote">
          Example zone RPOS map (center) along with an example set of zone input maps (corners). The example input maps are HCPT, drainage, distance to fault index, and targetThicknessIndex, which shows areas where the zone is between the OWC and GOC.
        </div>
        <div className="content-row"> 
          Settings<br/>
          <span className='blue'>Setting Name:</span> Attributes & Reverse Attributes<br/>
          <span className='blue'>Options:</span> Input grids for the RPOS grids<br/>
          <span className='blue'>Comments:</span> Sets the input grids to create the relative probability of success (RPOS) grids. For each type of grid, choose 'Reverse Attributes' if the relatively higher values indicate poorer areas for new drills (e.g. drainage, water saturation).<br/>
        </div>
        <div className='image-container'>
          <img src={SweetSpotRankingC} className='tooBig' alt="" />
        </div>
        <div className="image-footnote"> 
          Global RPOS map created from the individual zone RPOS maps.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(MapGeneration)
