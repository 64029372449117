import { POST_KEY, setDataFromS3URL } from './helpers'
import config from '../config.json'

// TODO - make this less hardcoded, just tryna test for now
const LOGIN_URL = process.env.API_ADDRESS == `http://localhost:3001/api`
  ? `http://localhost:3001/loginDev`
  : process.env.API_ADDRESS == `https://speedwise.ai/api`
      ? `https://speedwise.auth.us-east-1.amazoncognito.com/login?client_id=6bsd0d9a6i1baoquht71mvc8p2&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://speedwise.ai/loginAuth`
      : process.env.API_ADDRESS ==  `https://preproduction.speedwise.ai/api`
          ? `https://preproduction-speedwise.auth.us-east-1.amazoncognito.com/login?client_id=3hpp65jogvcjjd6crailu3puhj&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://preproduction.speedwise.ai/loginAuth`
          : null


function handleErrors(response) {
  const status = response.status
  return response.json().then((json) => {
    if (status !== 200) {
      console.log('json', json)
      throw Error(json.error || 'Server Error')
    }
    return json
  })
}

function setTokenCookies(user) {
  const { idToken } = user
  document.cookie = `authToken=${idToken}; path=/`;
  document.cookie = `samesite=strict; path=/`
}

function setTokenCookiesDev(email) {
  document.cookie = `email=${email}; path=/`;
  document.cookie = `samesite=strict; path=/`;
  // document.cookie = 'authToken=eyJraWQiOiJYK282Wk9kYzJqYU5DYkd5OEpkaWlvNlg5czhlSUNBTzhzUVB1N2kxcWx3PSIsImFsZyI6IlJTMjU2In0.eyJ0ZW5hbnRfaWQiOiI1ZjI4NTRhMzNjNzhmNmY4YjNmN2Y4ZmQiLCJhdF9oYXNoIjoidnMxRkhWa3AzLWRScHN0VmV2Wm5jdyIsInN1YiI6IjAxODQ2MTQxLWI4OGYtNDBhNi1iODM1LTJlMmYxYTNkOWYzZCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9uaXFhMW1ISFgiLCJjb2duaXRvOnVzZXJuYW1lIjoiMDE4NDYxNDEtYjg4Zi00MGE2LWI4MzUtMmUyZjFhM2Q5ZjNkIiwiYXVkIjoiNmJzZDBkOWE2aTFiYW9xdWh0NzFtdmM4cDIiLCJldmVudF9pZCI6ImFkZTk0NzFmLTFlZmQtNDJmOS05ZWNiLTNiZDg1ZjYyMGE1MyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA0NTA3OTIwLCJleHAiOjE2MDQ1MTUxMjAsImlhdCI6MTYwNDUwNzkyMCwiZW1haWwiOiJzY2hhZWZmZXJyZWVkQGdtYWlsLmNvbSJ9.gBYFvYW6-b6ozHRHI3z-nuNtwaOJghGl-6N__0voAhe6lwL1-nGmZ0YLv1uIletMC53ABmdQw9W8PSOuU89co_UOD2jA2WtcULCL1OAcT0tKm5hN9naL_qArgW-DRp58wsJOr5dNJwnCSONEd0KCMaPiPiL_zCpPPMN507olTEoXURjOgMKBJ1cFh2e41zt7FLaSWlNF5jl5N7KztWJxReC16onZYcEWqykhEnoLUx0P7IAM7Ar6BxDRnAFejoiqsro1uV8-iV7i6LTttNTlIvbf2P_7PeZ67D2qdUTzBD30RWNU0f8FmkAQjhcOQmejwn3a7axTMGSEFQlmfH7qFg; path=/';
}


const auth = async (token) => {
  console.log(' i am here boys', token)

  return fetch(`/auth/handleLogIn`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ token }),
  })
  .then(r => r.json())
  .then(user => {
    console.log('testestests', user)
    setTokenCookies(user)
    delete user['idToken']
    console.log('othertest', user)
    return user
  })
}


const authDev = async ({ email, password }) => {

  return fetch(`/auth/handleLogInDev`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ email }),
  })
  .then(r => r.json())
  .then(user => {
    let { useToken } = user
    console.log('testestests', user)
    
    if (useToken) {
      setTokenCookiesDev(user)  
      delete user['idToken']
    } else {
      setTokenCookiesDev(email)  
    }
    
    delete user['useToken']
    
    return user
  })
}



const getUserData = () => {
  //User info get's sent via cookies.
  return fetch('/auth/getUserData', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    credentials: 'include',
  })
  .then(r => r.json())
}


function clearTokenCookies() {
  const date = 'Sun, 28 Jun 1914 10:45:00 CET' 
  document.cookie = `authToken=; expires=${date}`
  document.cookie = `attributes=; expires=${date}`
  document.cookie = `samesite=; expires=${date}`
  document.cookie = `expires=; expires=${date}`
  document.cookie = `timeoutAt=; expires=${date}`
}


const logout = async () => {
  clearTokenCookies()
  window.location.href = LOGIN_URL
  return null
}





//
// Dataset Functions (TODO - move to seperate file)
//
const createFile = (dataset, name, type, category) => {
  const key = POST_KEY(dataset, type, category, name)
  return fetch(`${process.env.API_ADDRESS}/createFile`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ dataset, name, type, category, key }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

}

const createNewDataset = newDatasetName => fetch(`${process.env.API_ADDRESS}/createNewDataset?name=${newDatasetName}`)
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())

const s3DownloadFile = key => fetch(`${process.env.API_ADDRESS}/S3/download-url?key=${key}`)
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.text())

const s3PresignedURL = (fullName, fileType) => fetch(`${process.env.API_ADDRESS}/S3/presigned-url?filename=${fullName}&content_type=${fileType}`)
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(res => res.json())

const uploadFile = ({ fields, postURL, fullName, file, fileType }) => {
  const formData = new FormData()
  formData.append('key', fields.key)
  formData.append('Policy', fields.Policy)
  formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm'])
  formData.append('X-Amz-Credential', fields['X-Amz-Credential'])
  formData.append('X-Amz-Date', fields['X-Amz-Date'])
  formData.append('X-Amz-Signature', fields['X-Amz-Signature'])
  formData.append('Content-Type', fileType)
  formData.append('file', file, fullName)

  return fetch(postURL, {
    mode: 'no-cors',
    method: 'POST',
    body: formData,
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const updateFileMeta = (match, doc) => {
  fetch(`${process.env.API_ADDRESS}/update-file-meta`, {
    method: 'POST',
    body: JSON.stringify({ match, doc }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then((r) => {
    console.log('updated file meta')
    return 'success'
  })
}

const validateFiles = dataset_id => fetch(`${process.env.API_ADDRESS}/validate-files?dataset_id=${dataset_id}`)
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then((res) => {
    console.log(res)
  })


const deleteFile = async (key) => {
  console.log('deleting db', key)
  const dbDelete = await fetch(`${process.env.API_ADDRESS}/delete-staging-file?key=${key}`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ key }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  console.log(dbDelete)
  console.log('deleting s3', key)
  const s3Delete = await fetch(`${process.env.API_ADDRESS}/S3/delete-object?key=${key}`)
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  console.log(s3Delete)
  console.log('deleting s3 processed', key)
  const s3DeleteProcessed = await fetch(`${process.env.API_ADDRESS}/S3/delete-object?key=${key.replace('raw', 'processed')}`)
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  console.log(s3DeleteProcessed)
}

const markForDeletion = async (key, willDelete) => fetch(`${process.env.API_ADDRESS}/mark-for-deletion`, {
  method: 'POST',
  headers: {
    'Content-type': 'application/json',
  },
  credentials: 'include',
  body: JSON.stringify({ key, willDelete }),
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const setDatasetProcessingNeeds = (dataset, type) => fetch(`${process.env.API_ADDRESS}/setDatasetProcessingNeeds`, {
  method: 'PUT',
  headers: {
    'Content-type': 'application/json',
  },
  credentials: 'include',
  body: JSON.stringify({ dataset, type }),
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })



const processModule = (selectedDataset, selectedCase) => (
  fetch(`${process.env.API_ADDRESS}/ProcessModules`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ datasetID: selectedDataset, caseId: selectedCase }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(() => {
    console.log('im back boys')
    return null
  })
)


//
// New License Functions
//

const getLicenses = () => {
  return fetch(`${process.env.API_ADDRESS}/license/`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
  .catch(err => console.log('test', err))
}

const getLicense = (licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())

}

const getAccountUserOptions = () => {
  return fetch(`${process.env.API_ADDRESS}/license/accountUserOptions/`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}

const addLicenseAdmin = (userId, licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}/admin`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ userId }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const addLicenseUser = (userId, licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}/user`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ userId }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const removeLicenseUser = (userId, licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}/user`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ userId }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const fetchTasksForRemovingUser = (userId, licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}/getRemoveUserTasks/${userId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}

const toggleProjectSupportAccess = async (projectId, bool) => {
  const response = await fetch(`${process.env.API_ADDRESS}/project/${projectId}/toggleSupportAccess?set=${bool}`, { method: 'PUT', cache: "no-store" })

  return response
}


const deleteCasesAndDatasets = (licenseId, workspaceId, datasets, cases) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}/deleteCasesAndDatasets`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store", 
    credentials: 'include',
    body: JSON.stringify({ workspaceId, datasets, cases }),

  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const markSaveCasesAndDatasets = (licenseId, workspaceId, datasets, cases) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}/markSaveCasesAndDatasets`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store", 
    credentials: 'include',
    body: JSON.stringify({ workspaceId, datasets, cases }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}


const removeLicenseAdmin = (userId, licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}/admin`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ userId }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}


const createNewCognitoUser = (user, licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/license/user`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      tempPass: user.tempPass,
      licenseId 
    }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}


const toggleUserProjectPrivileges = (userId, licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}/toggleUserProjectPrivileges`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ userId }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const changeProjectOwner = (projectId, userId, licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/license/${licenseId}/project/${projectId}/changeOwner`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store", 
    credentials: 'include',
    body: JSON.stringify({ userId }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

//
// Visualization Functions (TODO - move to seperate file)
//
const getWellProduction = (wellId, datasetId, caseId) => (
  fetch(`${process.env.API_ADDRESS}/data/${caseId}/production?well=${wellId}&datasetId=${datasetId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => {
    console.log('get well production', r)
    return r.json()
  })
)


const getZones = (datasetId, caseId) => (
  fetch(`${process.env.API_ADDRESS}/data/${caseId}/zoneData?datasetId=${datasetId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)



const getWells = (datasetId, caseId) => (
  fetch(`${process.env.API_ADDRESS}/data/${caseId}/wells?datasetId=${datasetId}`, {cache: "no-store"})
    .then(res => {
      if (res.status == 301) {
        console.log('expired token, redirecting')
        // window.location.href = LOGIN_URL
        logout()
      }
      return res
    })
    .then(r => r.json())
    .then((wellData) => {
      const wellDataObj = {}

      wellData.forEach((i) => {

        wellDataObj[i.name] = {
          name: i.name,
          x: i.meta.location.x,
          y: i.meta.location.y,
          firstOilDate: i.meta.firstOilDate,
          ip: i.meta.ip,
          eur: i.meta.eur,
          md: i.meta.md,
          lastOil: i.meta.lastOil,
          lastWCT: i.meta.lastWCT,
          lastGOR: i.meta.lastGOR,
          perforations: i.perforations,
          trajectory: i.trajectory,
          zoneData: i.zoneData,
          contacts: i.contacts,
        }
      })

      return wellDataObj
    })
)

const getOpportunities = (caseId) => (
  fetch(`${process.env.API_ADDRESS}/data/${caseId}/opportunities`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const getLogs = async (wellId, caseId) => {
  let logData = await fetch(`${process.env.API_ADDRESS}/data/${caseId}/logs?well=${wellId}`, {cache: "no-store"})
    .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

  logData = await logData.json()

  const promises = []

  logData.forEach(log => {
    if(typeof log.values === 'string') {
      promises.push(setDataFromS3URL(log.values, log, 'values', caseId))
    }
  })

  await Promise.all(promises)

  return logData
}

const getOffsetWells = (opportunityId, caseId) => (
  fetch(`${process.env.API_ADDRESS}/data/${caseId}/offsetWells?opportunityId=${opportunityId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const getAnalogNeighborData = (wellId, zone, caseId) => (
  fetch(`${process.env.API_ADDRESS}/data/${caseId}/analogNeighborData?well=${wellId}&zone=${zone}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const getGridOptions = async (zone, datasetId, caseId) => {

  let res = await fetch(`${process.env.API_ADDRESS}/data/${caseId}/grid-options?zone=${zone}&datasetId=${datasetId}`)
  
  // Check if token is expired
  if(res.status === 301) {
    // window.location.href = LOGIN_URL
    logout()
    return
  }

  // Return request body
  return res.json()
}

const getGridData = async (property, zone, datasetId, caseId) => {

  let gridData = await fetch(`${process.env.API_ADDRESS}/data/${caseId}/grid-data?property=${property}&zone=${zone}&datasetId=${datasetId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

  gridData = await gridData.json()

  return gridData
}

const getCustomGrids = async (datasetId, versionId) => {
  return  fetch(`${process.env.API_ADDRESS}/dataset/${datasetId}/version/${versionId}/customGrids`)
    .then(r => r.json())
}

const getStructureGrids = async (datasetId, zone, caseId) => {
  let gridData = await fetch(`${process.env.API_ADDRESS}/data/${caseId}/structure-grid-data?datasetId=${datasetId}&zone=${zone}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

  gridData = await gridData.json()

  return gridData
}

const getSectionData = async (wellId, caseId, opportunityType, propertyId) => {
  let sectionData = await fetch(`${process.env.API_ADDRESS}/data/${caseId}/section-data?wellId=${wellId}&opportunityType=${opportunityType}&propertyId=${propertyId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  sectionData = await sectionData.json()

  if(!sectionData) return //Is there any data?

  console.log('getSectionData', sectionData)

  // if(typeof sectionData.x === 'string' || typeof sectionData.XData === 'string'){ //Is the big data passed in by reference?
  //   if(sectionData.type === 'structureSection'){ //What type of section are we dealing with? 
  //     await Promise.all([
  //       setDataFromS3URL(sectionData.x, sectionData, 'x', caseId),
  //       setDataFromS3URL(sectionData.y, sectionData, 'y', caseId),
  //       setDataFromS3URL(sectionData.z, sectionData, 'z', caseId),
  //       setDataFromS3URL(sectionData.color, sectionData, 'color', caseId),
  //       setDataFromS3URL(sectionData.contactGrids, sectionData, 'contactGrids', caseId),
  //     ]) 
  //   } else if (sectionData.type === 'modelSection'){
  //     await Promise.all([
  //       setDataFromS3URL(sectionData.XData, sectionData, 'XData', caseId),
  //       setDataFromS3URL(sectionData.YData, sectionData, 'YData', caseId),
  //       setDataFromS3URL(sectionData.ZData, sectionData, 'ZData', caseId),
  //       setDataFromS3URL(sectionData.properties, sectionData, 'properties', caseId),
  //     ]) 
  //   }
  // }

  return sectionData
}

const getModelSection = async (wellId, caseId, opportunityType) => {
  let sectionData = await fetch(`${process.env.API_ADDRESS}/data/${caseId}/model-section?wellId=${wellId}&opportunityType=${opportunityType}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

  sectionData = await sectionData.json()

  if(!sectionData) return //Is there any data?

  console.log('getModelSection', sectionData)

  // if(typeof sectionData.x === 'string' || typeof sectionData.XData === 'string'){ //Is the big data passed in by reference?
  //   if(sectionData.type === 'structureSection'){ //What type of section are we dealing with? 
  //     await Promise.all([
  //       setDataFromS3URL(sectionData.x, sectionData, 'x', caseId),
  //       setDataFromS3URL(sectionData.y, sectionData, 'y', caseId),
  //       setDataFromS3URL(sectionData.z, sectionData, 'z', caseId),
  //       setDataFromS3URL(sectionData.color, sectionData, 'color', caseId),
  //       setDataFromS3URL(sectionData.contactGrids, sectionData, 'contactGrids', caseId),
  //     ]) 
  //   } else if (sectionData.type === 'modelSection'){
  //     await Promise.all([
  //       setDataFromS3URL(sectionData.XData, sectionData, 'XData', caseId),
  //       setDataFromS3URL(sectionData.YData, sectionData, 'YData', caseId),
  //       setDataFromS3URL(sectionData.ZData, sectionData, 'ZData', caseId),
  //       setDataFromS3URL(sectionData.properties, sectionData, 'properties', caseId),
  //     ]) 
  //   }
  // }

  return sectionData
}

const getStructSection = async (wellId, caseId, shouldGetNS, opportunityType) => {
  let sectionData = await fetch(`${process.env.API_ADDRESS}/data/${caseId}/struct-section?wellId=${wellId}&shouldGetNS=${shouldGetNS}&opportunityType=${opportunityType}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

  sectionData = await sectionData.json()

  if(!sectionData) return //Is there any data?

  // if(typeof sectionData.x === 'string' || typeof sectionData.XData === 'string'){ //Is the big data passed in by reference?
  //   if(sectionData.type === 'structureSection'){ //What type of section are we dealing with? 
  //     await Promise.all([
  //       setDataFromS3URL(sectionData.x, sectionData, 'x', caseId),
  //       setDataFromS3URL(sectionData.y, sectionData, 'y', caseId),
  //       setDataFromS3URL(sectionData.z, sectionData, 'z', caseId),
  //       setDataFromS3URL(sectionData.color, sectionData, 'color', caseId),
  //       setDataFromS3URL(sectionData.contactGrids, sectionData, 'contactGrids', caseId),
  //     ]) 
  //   } else if (sectionData.type === 'modelSection'){
  //     await Promise.all([
  //       setDataFromS3URL(sectionData.XData, sectionData, 'XData', caseId),
  //       setDataFromS3URL(sectionData.YData, sectionData, 'YData', caseId),
  //       setDataFromS3URL(sectionData.ZData, sectionData, 'ZData', caseId),
  //       setDataFromS3URL(sectionData.properties, sectionData, 'properties', caseId),
  //     ]) 
  //   }
  // }

  return sectionData
}

const getFaultData = (datasetId, zone, caseId) => (
  fetch(`${process.env.API_ADDRESS}/data/${caseId}/fault-data?zone=${zone}&datasetId=${datasetId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const getWellbore = (versionId, datasetId, wellName) => (
  fetch(`${process.env.API_ADDRESS}/dataset/${datasetId}/wellbore?versionId=${versionId}&wellName=${wellName}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
)


const changeOpportunityTier = (value, id, caseId) => fetch(`${process.env.API_ADDRESS}/case/${caseId}/changeOpportunityTier`, {
  method: 'POST',
  headers: {
    'Content-type': 'application/json',
  },
  cache: "no-store",
  credentials: 'include',
  body: JSON.stringify({ value, id }),
}) 
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })


//
// Case Functions (TODO - move to seperate file)
//

const createNewCase = (name, description, projectId, application) => fetch(`${process.env.API_ADDRESS}/case/`, {
  method: 'POST',
  headers: {
    'Content-type': 'application/json',
  },
  cache: "no-store",
  credentials: 'include',
  body: JSON.stringify({ name, description, projectId, application }),
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())

const deleteCase = caseId => fetch(`${process.env.API_ADDRESS}/case/${caseId}`, {
  method: 'DELETE',
  cache: "no-store"
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const addCaseToFavorites = caseId => fetch(`${process.env.API_ADDRESS}/case/${caseId}/favorites`, {
  method: 'PUT',
  cache: "no-store"
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const removeCaseFromFavorites = caseId => fetch(`${process.env.API_ADDRESS}/case/${caseId}/favorites`, {
  method: 'DELETE',
  cache: "no-store"
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const pinOpportunity = (opportunityId, caseId) => fetch(`${process.env.API_ADDRESS}/case/${caseId}/pin/opportunity/${opportunityId}`, {
  method: 'PUT',
  cache: "no-store",
  headers: {
    'Content-type': 'application/json',
  },
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())

const unpinOpportunity = (opportunityId, caseId) => fetch(`${process.env.API_ADDRESS}/case/${caseId}/pin/opportunity/${opportunityId}`, {
  method: 'DELETE',
  cache: "no-store"
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const shareCase = caseId => fetch(`${process.env.API_ADDRESS}/case/${caseId}/shared`, {
  method: 'PUT',
  cache: "no-store"
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const unshareCase = caseId => fetch(`${process.env.API_ADDRESS}/case/${caseId}/shared`, {
  method: 'DELETE',
  cache: "no-store"
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const addTag = (caseId, tag) => fetch(`${process.env.API_ADDRESS}/case/${caseId}/tags`, {
  method: 'PUT',
  cache: "no-store",
  headers: {
    'Content-type': 'application/json',
  },
  body: JSON.stringify({ tag }),
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const removeTag = (caseId, tag) => fetch(`${process.env.API_ADDRESS}/case/${caseId}/tags`, {
  method: 'DELETE',
  headers: {
    'Content-type': 'application/json',
  },
  cache: "no-store",
  body: JSON.stringify({ tag }),
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const getSharedCases = (projectId) => (
  fetch(`${process.env.API_ADDRESS}/case/byProject/${projectId}/shared`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const getCases = (projectId) =>(
  fetch(`${process.env.API_ADDRESS}/case/byProject/${projectId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const getCasesForOptimization = (projectId) => (
  fetch(`${process.env.API_ADDRESS}/case/byProject/${projectId}/optimization`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const getCase = (caseId) => (
  fetch(`${process.env.API_ADDRESS}/case/${caseId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const setCaseStep = (step, caseId) => fetch(`${process.env.API_ADDRESS}/case/${caseId}/setStep`, {
  method: 'PUT',
  headers: {
    'Content-type': 'application/json',
  },
  cache: "no-store",
  body: JSON.stringify({ step }),
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })

const duplicateCase = (caseId, name, description) => fetch(`${process.env.API_ADDRESS}/case/${caseId}/duplicate`, {
  method: 'POST',
  headers: {
    'Content-type': 'application/json',
  },
  cache: "no-store",
  credentials: 'include',
  body: JSON.stringify({
    name,
    description,
  }),
})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())

const editCaseNameAndDescription = (caseId, name, description) => (
  fetch(`${process.env.API_ADDRESS}/case/${caseId}`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ name, description }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
)

const updateCaseCase = (caseId, refCaseId) => (
  fetch(`${process.env.API_ADDRESS}/case/${caseId}/updateCase`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ refCaseId }),
  })
)
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())


const updateCaseDataset = (caseId, datasetId) => (
  fetch(`${process.env.API_ADDRESS}/case/${caseId}/updateDataset`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ datasetId }),
  })
)
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())

const finalizeCase = (caseId) => (
  fetch(`${process.env.API_ADDRESS}/case/${caseId}/finalize`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
)

const udateCaseSettingsMode = (caseId, mode) => (
  fetch(`${process.env.API_ADDRESS}/case/${caseId}/updateMode`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ mode }),
  })
  ) 
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())

const updateCaseModule = (caseId, module) => (
  fetch(`${process.env.API_ADDRESS}/case/${caseId}/updateModule`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ module }),
  })
)
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())

const updateCaseSettings = (caseId, settings, stepNo) => (
  fetch(`${process.env.API_ADDRESS}/case/${caseId}/updateSettings`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ settings, stepNo }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
)

const updateCaseSettingsOpti = (caseId, settings, stepNo) => (
  fetch(`${process.env.API_ADDRESS}/case/${caseId}/updateSettingsOpti`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ settings, stepNo }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
)

const getDatasetNames = (datasetId, versionId) => (
  fetch(`${process.env.API_ADDRESS}/dataset/${datasetId}/version/${versionId}/name`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

//
// Execution Functions (TODO - move to seperate file)
//

const getSmartOptions = (datasetId, versionId) => (
  fetch(`${process.env.API_ADDRESS}/dataset/${datasetId}/version/${versionId}/smartOptions`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const runModule = (caseId, step, isSingularStep) => {
  console.log('about to fetch', caseId)
  return fetch(`${process.env.API_ADDRESS}/engine/${caseId}/runModule`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ step, isSingularStep }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const killExecution = (caseId) => {

  const url = `${process.env.API_ADDRESS}/engine/${caseId}/killExecution`
  const params = {
    method: 'POST',
    cache: "no-store",
    credentials: 'include',
  }
  
  return fetch(url, params)
    .then(res => {

    })
}

//
// Project Functions (TODO - move to seperate file)
//

const createNewProject = (name, basin, region, country, objective, license) => {
  return fetch(`${process.env.API_ADDRESS}/project/`, {
    method: 'POST',
    headers :{
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ name, basin, region, country, objective, license })
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}


const editProject = (projectId, name, basin, region, country, objective) => {
  return fetch(`${process.env.API_ADDRESS}/project/${projectId}`, {
    method: 'PUT',
    headers:{
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ name, basin, region, country, objective })
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const deleteProject = (projectId) => {
  return fetch(`${process.env.API_ADDRESS}/project/${projectId}`, {
    method: 'DELETE',
    headers :{
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include'
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const loadProjects = async () => {
  return fetch(`${process.env.API_ADDRESS}/project/`, {
    credentials: 'include',
    cache: "no-store"
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}

const loadLicenseProjects = async (licenseId) => {
  return fetch(`${process.env.API_ADDRESS}/project/license/${licenseId}`, {
    credentials: 'include',
    cache: "no-store"
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}

const removeProjectFromUser = (projectId, email) => {
  return fetch(`${process.env.API_ADDRESS}/project/${projectId}/removeCollaborator`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ email }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const getProject = (projectId) => {
  return fetch(`${process.env.API_ADDRESS}/project/${projectId}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}


const getCollaboratorOptions = (projectId, license) => {
  return fetch(`${process.env.API_ADDRESS}/project/${projectId}/collaboratorOptions?license=${license}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}

const getCollaborators = (projectId, license) => {
  return fetch(`${process.env.API_ADDRESS}/project/${projectId}/collaborators?license=${license}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}

const addProjectCollaborators = (projectId, emails) => {
  return fetch(`${process.env.API_ADDRESS}/project/${projectId}/addCollaborators`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ emails }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const getTutorialResources = () => {
  return fetch(`${process.env.API_ADDRESS}/tutorials/resources`, {cache: "no-store"}) 
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}

const downloadResource = (key) => {
  return fetch(`${process.env.API_ADDRESS}/tutorials/resource/?key=${key}`, {cache: "no-store"}) 
    .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
    .then(r => r.text())
}

const getTutorialVideos = () => {
  return fetch(`${process.env.API_ADDRESS}/tutorials/videos`, {cache: "no-store"}) 
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}

const getTutorialVideo = (key) => {
  return fetch(`${process.env.API_ADDRESS}/tutorials/video/?key=${key}`, {cache: "no-store"}) 
    .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
    .then(r => r.json())
}

const getTutorialVideoThumbnail = (title) => {
  return fetch(`${process.env.API_ADDRESS}/tutorials/thumbnail/?title=${title}`)
    .then(r => r.json())
}

const getTutorialVideoDetails = (key) => {
  return fetch(`${process.env.API_ADDRESS}/tutorials/videoDetails/?key=${key}`, {cache: "no-store"}) 
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
}

const createTicket = (category, description) => {
  return fetch(`${process.env.API_ADDRESS}/createTicket`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include',
    body: JSON.stringify({ category, description }),
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

const testSocket = () => {
  return fetch(`${process.env.API_ADDRESS}/testSocket`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    cache: "no-store",
    credentials: 'include', 
  })
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
}

// s3 functions

const getS3FileStream = (url) => (
  fetch(`${process.env.API_ADDRESS}/file/getS3FileStream?url=${url}`, {cache: "no-store"})
  .then(res => {
    if (res.status == 301) {
      console.log('expired token, redirecting')
      // window.location.href = LOGIN_URL
      logout()
    }
    return res
  })
  .then(r => r.json())
)

const clearCookies = () => {
  const date = 'Sun, 28 Jun 1914 10:45:00 CET' 
  document.cookie = `authToken=; expires=${date}`
  document.cookie = `attributes=; expires=${date}`
  document.cookie = `samesite=; expires=${date}`
  document.cookie = `expires=; expires=${date}`
  document.cookie = `timeoutAt=; expires=${date}`
}

const getSMLAttributes = async (datasetId, versionId, APIKey) => {

  const SMLOptions = {
    "method": "POST",
    "cache": "no-store",
    "mode": "cors",
    "headers": {
      "MediaType": "application/json",
      "Content-Type": "application/json"
    },
    "body": JSON.stringify({
      "data": [],
      "predictUncertainty": true,
      "predictProbability": true,
      "includeInput": false
    })
  }

  const datasetOptions = {
    "method": "GET",
    "cache": "no-store",
  }

  // Get SML Resonse and dataset data
  const SROResponse = fetch(`${process.env.API_ADDRESS}/dataset/${datasetId}`, datasetOptions).then(r => r.json())
  const SMLResponse = fetch(`https://www.speedwiseml.com/api?key=${APIKey}`, SMLOptions).then(r => r.json())
  const dataset = await SROResponse

  // Find correct version, pull uploaded grid data from it.
  let version, availableGrids
  if(dataset.latestVersion.versionId.toString() === versionId) version = dataset.latestVersion
  else version = dataset.versions.find(ver => ver.versionId.toString() === versionId)

  if(version) {
    let gridData = version.hasCategories.Grid_Data
    availableGrids = Object.keys(gridData).filter(grid => gridData[grid])
  }

  // Combine available grids and constant properties
  const alwaysHaveProp = ['owc','goc','recentWct','recentGor','cumProduction','currentProduction','spacing','drainage','isochore','z','coi','doi','roi','ntg','zoneIndex','inOilWindow','dtfIndex','pos','ip','eur']
  const combinedProperties = [...alwaysHaveProp, ...availableGrids]

  // Remove from SMLResponse anything that is already contained in combined properties.
  // Ask Amir K why we're doing this, I just know that's what to do
  const body = await SMLResponse
  if(body.msg === 'success') {
    const results = body.result.signature
    const filteredResults = results.filter(prop => !combinedProperties.includes(prop.column))
    const removedProps = results.filter(prop => combinedProperties.includes(prop.column))
    console.log('we have the following grids:', availableGrids)
    console.log('removed the following properties:', removedProps)
    // I'm going to format the data to appropriate table settings here
    return filteredResults.map(property => ({
      attributeName: property.column,
      value: 0
    }))
  } else {
    return false
  }
}



export default {
  authDev,
  auth,
  logout,
  s3DownloadFile,
  s3PresignedURL,
  createNewDataset,
  clearCookies,
  createNewCase,
  deleteCase,
  addCaseToFavorites,
  pinOpportunity,
  unpinOpportunity,
  removeCaseFromFavorites,
  shareCase,
  editCaseNameAndDescription,
  unshareCase,
  addTag,
  removeTag,
  getSharedCases,
  getCases,
  getCasesForOptimization,
  getCase,
  setCaseStep,
  duplicateCase,
  getZones,
  getDatasetNames,
  getSmartOptions,
  updateCaseDataset,
  updateCaseCase,
  updateCaseModule,
  udateCaseSettingsMode,
  updateCaseSettings,
  updateCaseSettingsOpti,
  createFile,
  uploadFile,
  updateFileMeta,
  deleteFile,
  markForDeletion,
  validateFiles,
  changeOpportunityTier,
  setDatasetProcessingNeeds,
  runModule,
  killExecution,
  processModule,
  finalizeCase,
  getWellProduction,
  getWells,
  getOpportunities,
  getLogs,
  getOffsetWells,
  getAnalogNeighborData,
  getCustomGrids,
  getGridOptions,
  getGridData,
  getFaultData,
  getWellbore,
  getStructureGrids,
  getSectionData,
  getModelSection,
  getStructSection,
  getCollaboratorOptions,
  getCollaborators,
  addProjectCollaborators,
  loadProjects,
  loadLicenseProjects,
  createNewProject,
  editProject,
  deleteProject,
  removeProjectFromUser,
  getProject,
  changeProjectOwner,
  getTutorialResources,
  downloadResource,
  getTutorialVideos,
  getTutorialVideo,
  getTutorialVideoThumbnail,
  getTutorialVideoDetails,
  createTicket,
  testSocket,
  getLicenses,
  getLicense,
  getAccountUserOptions,
  addLicenseAdmin,
  addLicenseUser,
  removeLicenseUser,
  toggleUserProjectPrivileges,
  removeLicenseAdmin,
  createNewCognitoUser,
  fetchTasksForRemovingUser,
  deleteCasesAndDatasets,
  markSaveCasesAndDatasets,
  getS3FileStream,
  toggleProjectSupportAccess,
  getSMLAttributes,
}
