import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Wells extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Wells
        </div>
        <div className="content-row-header"> 
          Well Info
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>1. Well Master (required)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Includes the basic information for existing wells including location, status, etc.<br/>
          &ensp;• Utility: Basic well information is required for almost all steps in the modules.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>2. Well Tops</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: A well top is necessary for every interval of interest that the wellbore penetrates.<br/>
          &ensp;• Utility: Well formation tops are central to the production allocation estimation and opportunity identification.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>3. Well Name Alias (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Includes a list of alternate well names.<br/>
          &ensp;• Utility: This allows data which may use an alternate well name or identifier to be compiled into the same dataset.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>4. Well Groups (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Defines wells into groups of interest that the user would like to define [e.g., by fault block, well type (vertical, horizontal, etc.), area, etc.].<br/>
          &ensp;• Utility: If provided, the well group information will be used in the automated decline curve analysis for clustering and type-curve generation purposes.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>5. Well Events (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: A history of well operations.<br/>
          &ensp;• Utility: To provide major historical well operation events that affect production.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>6. Well Perforations (required)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: All perforation history information (e.g., depth, dates).<br/>
          &ensp;• Utility: For completion contact analysis and flow unit allocation.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className='grayLine'/>
        <div className="content-row-header"> 
          Well Production & Injection Data
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>1. Well Monthly Production (required)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Well level monthly production rates.<br/>
          &ensp;• Utility: Production is used in multiples steps of the opportunity generation process. For example, the production is allocated by zone and then used to forecast new opportunities.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>2. Well Monthly Injection (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Well level monthly injection rates.<br/>
          &ensp;• Utility: If provided, this information will be used for flow allocation purposes to different zones/intervals.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className='grayLine'/>
        <div className="content-row-header"> 
          Wellbore Diagrams (optional)
        </div>
        <div className="content-row"> 
          &ensp;• Content: Wellbore diagrams are graphical representations of the latest state of a well’s configuration.<br/>
          &ensp;• Utility: This is used in the recompletions module to check for wellbore feasibility. It is not required data; if this data is incomplete or missing, then the wellbore feasibility step will be skipped for the well(s) missing this data.<br/>
          &ensp;• File Format: PNG<br/>
        </div>
        <div className='grayLine'/>
        <div className="content-row-header"> 
          Well Trajectories (required)
        </div>
        <div className="content-row"> 
          &ensp;• Content: Provides subsurface location of the entire wellbore trajectories.<br/>
          &ensp;• Utility: Well trajectories are used in spacing analysis and drainage analysis, as well as for visualizations where you can view the 3D trajectories of the wells.<br/>
          &ensp;• File Format: .dev (ASCII), CSV<br/>
        </div>
        <div className="content-row" style={{fontStyle: 'italic'}}> 
          Please remember to use the reference “SpeedWise Reservoir - Data Preparation.docx” for questions regarding specific data requirements and preparation.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Wells)
