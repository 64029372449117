import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PrivateRoute from './CustomRoute/PrivateRoute'

import Summary from '../components/layout/Visualization/Opportunity/Summary/Summary'
import Petrophysical from '../components/layout/Visualization/Opportunity/Petrophysical/Petrophysical'
import ProductionForecast from '../components/layout/Visualization/Opportunity/ProductionForecast/ProductionForecast'
import ModelAnalysis from '../components/layout/Visualization/Opportunity/ModelAnalysis/ModelAnalysis'
import MechanicalFeasibility from '../components/layout/Visualization/Opportunity/MechanicalFeasibility/MechanicalFeasibility'

const OpportunityRoutes = ({ 
  map,
	user,
  caseId,
  datasetId,
  realDatasetId,
  wellData,
  structureZMatrix,
  structureGridX,
  structureGridY,
  faultData,
  caseObj,
  singleOpportunity,
  time,
  oilRate,
  waterCut,
  gor,
  logData,
  neighborLogs,
  analogNeighborData,
  structSectionDataNS,
  structSectionDataEW,
  wellId, 
  gridData,
  zone,
  updateOpportunity,
  modelSectionData,
  offsetWells,
  selectedOffsetWells,
  selectedWellData,
  selectedNeighborLogs,
  handleSelectNeighbor,
  handleUnselectNeighbor,
  setCurPage,
}) => (
  <React.Fragment>
    <PrivateRoute exact path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId" 
      user={user} 
      component={Summary}
      map={map}
      setCurPage={setCurPage}
      singleOpportunity={singleOpportunity}
      caseId={caseId}
      caseObj={caseObj}
      time={time}
      oilRate={oilRate}
      waterCut={waterCut}
      gor={gor}
      updateOpportunity={updateOpportunity}
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId/petrophysical"
      user={user}
      component={Petrophysical}
      map={map}
      setCurPage={setCurPage}
      caseObj={caseObj}
      singleOpportunity={singleOpportunity}
      offsetWells={offsetWells}
      selectedWellData={selectedWellData}
      logData={logData}
      neighborLogs={neighborLogs}
      wellData={wellData}
      selectedOffsetWells={selectedOffsetWells}
      selectedNeighbors={selectedNeighborLogs}
      handleSelectNeighbor={handleSelectNeighbor}
      handleUnselectNeighbor={handleUnselectNeighbor}
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId/model"
      user={user}
      component={ModelAnalysis}
      map={map}
      setCurPage={setCurPage}
      wellData={wellData}
      structSectionDataNS={structSectionDataNS}
      structSectionDataEW={structSectionDataEW}
      wellId={wellId}
      gridData={gridData}
      modelSectionData={modelSectionData}
      singleOpportunity={singleOpportunity}
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId/production"
      user={user}
      component={ProductionForecast}
      map={map}
      setCurPage={setCurPage}
      caseObj={caseObj}
      wellData={wellData}
      singleOpportunity={singleOpportunity}
      analogNeighborData={analogNeighborData}
      zone={zone}
    />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId/mechanical"
      user={user}
      component={MechanicalFeasibility}
      map={map}
      setCurPage={setCurPage}
      wellData={wellData}
      datasetId={datasetId}
      realDatasetId={realDatasetId}
      singleOpportunity={singleOpportunity}
    />
  </React.Fragment>
)

const mapStateToProps = state => ({
  user: state.get('user'),
})

export default withRouter(connect(mapStateToProps)(OpportunityRoutes))