import * as THREE from "three"

class HoverHelper {
  constructor() {
    this.raycaster = new THREE.Raycaster()
    this.pickedObject = null
    this.pickedObjectSavedColor = 0
    this.context = null
  }

  pick(normalizedPosition, scene, camera, time, textbox, canvas) {
    // restore the color if there is a picked object
    if (this.pickedObject) {
      textbox.style.visibility = "hidden"
      //this.pickedObject.material.color.setHex(this.pickedObjectSavedColor)
      //this.pickedObject = undefined
    }

    // cast a ray through the frustum
    this.raycaster.setFromCamera(normalizedPosition, camera)
    // get the list of objects the ray intersected
    if (scene.children[3] && scene.children[3].children) {
      const intersectedObjects = this.raycaster.intersectObjects(scene.children[3].children)
      if (intersectedObjects.length) {
        // pick the first object. It's the closest one
        this.pickedObject = intersectedObjects[0].object
        // save its color
        //this.pickedObjectSavedColor = this.pickedObject.material.color.getHex()
        // set its emissive color to flashing red/yellow
        //this.pickedObject.material.color.setHex((time * 8) % 2 > 1 ? 0xFFFF00 : 0xFF0000)

        const vector = this.pickedObject.position.clone().project(camera)
        textbox.style.left = (normalizedPosition.x + 1) / 2 * canvas.clientWidth + 10 + 'px'
        textbox.style.top = -(normalizedPosition.y - 1) / 2 * canvas.clientHeight - textbox.clientHeight + 'px'

        if (this.pickedObject.objtype === 'wellpie' && this.pickedObject.visible) {
          textbox.style.visibility = "visible"
          textbox.innerHTML = this.pickedObject.Pie.info
        } else {
            textbox.style.visibility = "hidden"
        }
      }
    }
  }
}

export default HoverHelper