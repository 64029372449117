import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'

import { range } from '../../../../lib/helpers'

@autobind class TrajectoryPlot extends PureComponent {

  render() {
    let { trajectory, gridData, wellData, wellId, onlyShowCurTraj, zone } = this.props

    const hardcodedTopZone = 'A'

    let plotData = []
    let perforations = []
    let camera = {}

    let xMin = null
    let xMax = null
    let yMin = null
    let yMax = null
    let zMin = null
    let zMax = null
    let zRatio = 0.5 // TODO: user adjustable in the plot
    
    let selectedZone = zone || hardcodedTopZone
    let offset = 50
    let gridLimit = 50 // set a grid limit to downsample the data
    let trajectoryLimit = 50 // a limit of the points in the non-target trajectories

    let targetTrajectoryColor = 'blue'
    let trajectoryColor = 'orange'
    let perfColor = 'red'
    let targetWell = wellId

    let xGridLimit, yGridLimit, xStep, yStep = 0

    if (gridData.length > 0) {
      let definition = gridData[0]['definition']
      xGridLimit = Math.min(gridLimit, definition['iMax'])
      yGridLimit = Math.min(gridLimit, definition['jMax'])

      xStep = (definition['x2'] - definition['x1']) / (definition['iMax'] - 1)
      yStep = (definition['y2'] - definition['y1']) / (definition['jMax'] - 1)

      const grid = gridData.find(i => i.zone === selectedZone) || gridData[0]

      let xRange = range(grid['definition']['x1'], grid['definition']['x2'] + xStep, xStep)
      let yRange = range(grid['definition']['y1'], grid['definition']['y2'] + yStep, yStep)

      let zMatrix = grid['zMatrix']
      let outZMatrix = []
      let outXRange = []
      let outYRange = []

      let downsampleXStep = xRange.length / xGridLimit
      let downsampleYStep = yRange.length / yGridLimit

      let downsampleXIndex = range(0, xRange.length - 1, downsampleXStep).map(i => Math.floor(i))
      let downsampleYIndex = range(0, yRange.length - 1, downsampleYStep).map(i => Math.floor(i))

      console.log('grid', grid)
      console.log('zMatrix', zMatrix)

      window.grid = grid

      downsampleXIndex.forEach(index => {
        outXRange.push(xRange[index])
      })

      downsampleYIndex.forEach(index => {
        outYRange.push(yRange[index])
      })

      downsampleYIndex.forEach(yIndex => {
        let newRow = []
        downsampleXIndex.forEach(xIndex => {
          newRow.push(zMatrix[yIndex][xIndex])
        })
        outZMatrix.push(newRow)
      })

      let obj = {
        x: downsampleXIndex.map(i => xRange[i]),
        y: downsampleYIndex.map(i => yRange[i]),
        z: outZMatrix,
        opacity: .5,
        showscale: false,
        type: 'surface',
        hoverinfo: 'text',
        hovertext: grid['zone']
      }

      plotData.push(obj)      
 
    }

    //Calculate mins/maxs
    plotData.forEach(zone => {
      let { x, y, z } = zone

      z.forEach(row => {
        row.forEach(item => {
          if (item && (!zMin || item < zMin)) {
            zMin = item
          }
          if (item && (!zMax || item > zMax)) {
            zMax = item
          }
        })
      })

      x.forEach(val => {
        if (!xMin || val < xMin) {
          xMin = val
        }
        if (!xMax || val > xMax) {
          xMax = val
        } 
      })

      y.forEach(val => {
        if (!yMin || val < yMin) {
          yMin = val
        }
        if (!yMax || val > yMax) {
          yMax = val
        } 
      })
    })

    Object.keys(wellData).forEach(name => {
      let well = wellData[name]
      // let wellTrajectoryLimit = Math.min(well['trajectory'].length, trajectoryLimit)
      let wellTrajectoryColor = trajectoryColor 
      let trajectoryWidth = 2
      let trajectoryData = well['trajectory']
      
      if (name === targetWell) {  
        wellTrajectoryColor = targetTrajectoryColor
        trajectoryWidth = 5
        perforations = well['perforations']
      }

      if (name === targetWell || !onlyShowCurTraj) {
        trajectoryData = trajectoryData.filter((i, index) => index % 25 === 0 || (trajectoryData.length - index < 50 && index % 5 === 0) || (trajectoryData.length - index < 100 && index % 10 === 0) || trajectoryData.length - 1 === index)

        if (trajectoryData.length && -trajectoryData[0].tvdss > zMax) {
          zMax = trajectoryData.tvdss * -1
        }

        plotData.push({
          type: 'scatter3d',
          x: trajectoryData.map(i => i.x),
          y: trajectoryData.map(i => i.y),
          z: trajectoryData.map(i => -1 * i.tvdss),
          mode: 'lines',
          line: {
            color: wellTrajectoryColor,
            width: trajectoryWidth ,
          },
          hoverinfo: 'text',
          hovertext: name
        })
      }
    })

    if (trajectory) {
      const wellTrajectoryColor = targetTrajectoryColor
      const trajectoryWidth = 5

      if (trajectory.length && trajectory[0].z > zMax) {
        zMax = trajectory.z
      }

      plotData.push({
        type: 'scatter3d',
        x: trajectory.map(i => i.x),
        y: trajectory.map(i => i.y),
        z: trajectory.map(i => i.z), // z),
        mode: 'lines',
        line: {
          color: wellTrajectoryColor,
          width: trajectoryWidth,
        },
        hoverinfo: 'text',
        hovertext: targetWell,
      })
    }

    const layout = {
      margin: {l: 0, r: 0, t: 0, b: 0, pad: 4},
      scene: {
        camera: {
          up: {
            x: 0,
            y: 0,
            z: 1,
          },
          center: {
            x: 0,
            y: 0,
            z: 0,
          },
          eye: {
            y: 0.75,
            x: 0.05,
            z: 0.05,
          },
        },
        xaxis: {
          'range': [xMin - offset, xMax + offset],
        },
        yaxis: {
          'range': [yMin - offset, yMax + offset],
        },
        zaxis: {
          'range': [zMin - offset, zMax + offset],
        title: {
            text: 'Depth'
          },
        },
        aspectmode: 'manual',
        aspectratio: {
          x: (xMax-xMin+2*offset)/(xMax-xMin+yMax-yMin+4*offset),
          y: (yMax-yMin+2*offset)/(xMax-xMin+yMax-yMin+4*offset),
          z: zRatio
        },
      },
      showlegend: false,
      autosize: true,
    }
    
    return (
      <Plot
        className="plot"
        data={plotData}
        layout={layout}
        config={{displaylogo: false, showSendToCloud: true}}
        useResizeHandler
      />
    )
  }
}

export default TrajectoryPlot
