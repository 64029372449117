import React, { Component } from 'react'
import autobind from 'autobind-decorator'
// import { Route, withRouter, Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import './LicenseCard.scss'

const baseClass = 'licenseCard'
const headerClass = `${baseClass}__header`
const rowsClass = `${baseClass}__rows`
const leftClass = `${rowsClass}__left`
const leftClassItem = `${leftClass}__val`
const rightClass = `${rowsClass}__right`
const rightClassItem = `${rightClass}__val`

@autobind class LicenseCard extends Component {


  render() {
    const  { license, handleLicenseClick } = this.props

    console.log('ey', license)
    let adminName = license.licenseAdminName || 'No License Administrator'
    let productsText = license.applications.join('/')
    let expirationDate = license.expiration ? new Date(license.expiration) : null
    let expirationText = expirationDate ? `${expirationDate.getMonth() + 1}/${expirationDate.getDate()}/${expirationDate.getFullYear()}` : 'No Expiration'
    let workspacesText = `${license.workspaceCount} of ${license.workspaceLimit} Created`
    let userText = `${license.currentUsers.length} of ${license.userLimit} Users Added`

    return (
      <div 
        className={baseClass}
        onClick={() => handleLicenseClick(license._id)}
      >
        <div className={headerClass}>
          {license.name}
        </div>
        <div className={rowsClass}>
          <div className={leftClass}>
            <div className={leftClassItem}>Administrator</div>
            <div className={leftClassItem}>Tier</div>
            <div className={leftClassItem}>Product(s)</div>
            <div className={leftClassItem}>Expires</div>
            <div className={leftClassItem}>Workspaces</div>
            <div className={leftClassItem}>Users</div>
          </div>
          <div className={rightClass}>
            <div className={rightClassItem}>{adminName}</div>
            <div className={rightClassItem}>TBD</div>
            <div className={rightClassItem}>{productsText}</div>
            <div className={rightClassItem}>{expirationText}</div>
            <div className={rightClassItem}>{workspacesText}</div>
            <div className={rightClassItem}>{userText}</div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(LicenseCard)
