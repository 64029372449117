import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'
import Collapsible from 'react-collapsible'

const SettingLink = (props) => {
  const { path, display } = props

  return (
    <div className='setting-link' >
      <NavLink to={path} className='setting-link-font'>
        {display}
      </NavLink>
    </div>
  )
}

@autobind class SettingsOptions extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Horizontals Settings
        </div>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Global Settings'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/basicZoneInfo'
            display='Basic Zone Info'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/logAlias'
            display='Log Alias'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/logCleaning'
            display='Log Cleaning'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/logSettings'
            display='Log Settings'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/gridCleaning'
            display='Grid Cleaning'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/otherGlobalSettings'
            display='Other Global Settings'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Engineering Analytics'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/declineCurveAnalysis'
            display='Decline Curve Analysis'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/flowUnitAllocation'
            display='Flow Unit Allocation'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Log Zone Analytics'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/logZoneAnalyticsOverview'
            display='Log Zone Analytics Overview'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/perforatedNetPay'
            display='Perforated Net Pay Settings (PNP)'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/netPay'
            display='Net Pay Settings'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Attribute Mapping'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/attributeMapping'
            display='Attribute Mapping'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Search Domain Definition'}
        >
          <SettingLink
            path='/training/manual/modules/horizontals/settings/searchDomainDefinition'
            display='Search Domain Definition'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Drainage Analysis'}
        >
          <SettingLink
            path='/training/manual/modules/horizontals/settings/horDrainage'
            display='Drainage Settings'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Sweet Spot Ranking'}
        >
          <SettingLink
            path='/training/manual/modules/horizontals/settings/horSweetSpotRanking'
            display='RPOS/Ranking Attribute'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Target Search'}
        >
          <SettingLink
            path='/training/manual/modules/horizontals/settings/horTargetSearch'
            display='Target Search'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Interference Analysis'}
        >
          <SettingLink
            path='/training/manual/modules/horizontals/settings/interferenceAnalysis'
            display='Interference Analysis Settings'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Production Forecast'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/initialProductionRateForecast'
            display='Initial Production Rate Forecast'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/thicknessNormalized'
            display='Settings for Thickness Normalized'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/darcyParameters'
            display='Darcy Parameters'
          />
          <div className='grayLine2'/>
         <SettingLink
            path='/training/manual/modules/recompletions/settings/neighborhoodSettings'
            display='Neighborhood Settings'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/interpolationSettings'
            display='Interpolation Settings'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/horizontals/settings/otherProductionForecast'
            display='Other Production Forecast Settings'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger"
          trigger={'Filtering & Finalization'}
        >
          <SettingLink
            path='/training/manual/modules/horizontals/settings/horFilterFinalize'
            display='Filter Drill Locations'
          />
        </Collapsible>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(SettingsOptions)
