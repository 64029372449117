import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'autobind-decorator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import API from '../../../lib/api-store'
import { login } from '../../../redux/actions/user'

@autobind class LoginAuth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      failedLogIn: null,
    }

  }



  componentDidMount () {
    const { location } = this.props

    console.log('aaaa', location)

    if (location.hash || location.search) {
      let hash = location.hash.split('&')
      let idToken = hash[0].split('=')[1]

      this.getUserInfo(idToken)
      console.log(idToken)

    }
  }

  async getUserInfo(idToken) {
    const { loginAction } = this.props

    try {
      const userObj = await API.auth(idToken)        
      console.log('testestset', userObj) 

      if (userObj.success == false) {
        this.setState({ failedLogIn: true })
      } else {
        this.setState({ failedLogIn: false })
        loginAction(userObj)  
      }
      
    } catch (err){
      console.log('errrrr', err)
      this.setState({ failedLogIn: true })
    }
  }



  render() {
    let { failedLogIn } = this.state
    let { user } = this.props
    console.log('mouting login auth')
    console.log("User: ", user);
    console.log("failedlogin: ", failedLogIn);

    if (failedLogIn != null && !failedLogIn) {
      return <Redirect to="/" />
    }

    return <div />
  }
}

const mapStateToProps = state => ({
  user: state.get('user'),
})


const mapDispatchToProps = dispatch => ({
  loginAction: user => dispatch(login(user)),
})


export default connect(mapStateToProps, mapDispatchToProps)(LoginAuth)