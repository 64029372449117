import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import { Numeric, Integer, Numeric2 } from '../../../../lib/formatters'

const columns = [{
  Header: 'Well ID',
  accessor: 'id',
  minWidth: 80,
}, {
  Header: 'Distance',
  accessor: 'distance',
  minWidth: 80,
}, {
  Header: <div>Perf Length <br/> (m) </div>,
  accessor: 'perfLength',
  minWidth: 80,
  Cell: Numeric,
}, {
  Header: <div>PNP<br/> (m) </div>,
  accessor: 'pnp',
  minWidth: 80,
  Cell: Numeric,
}, {
  Header: <div>hcpt<br/> (m) </div>,
  accessor: 'hcpt',
  minWidth: 80,
  Cell: Numeric2,
}, {
  Header: <div>Perm<br/> </div>,
  accessor: 'pnpPerm',
  minWidth: 80,
  Cell: Numeric,
}, {
  Header: <div>Initial Pressure<br/> </div>,
  accessor: 'pnpInitialPressure',
  minWidth: 80,
  Cell: Numeric2,
}, {
  Header: <div>oil<br/></div>,
  accessor: 'oil',
  minWidth: 80,
  Cell: Numeric,
}, {
  Header: <div>water<br/></div>,
  accessor: 'water',
  minWidth: 80,
  Cell: Numeric,
}, {
  Header: <div>fr<br/></div>,
  accessor: 'fr',
  minWidth: 80,
  Cell: Numeric,
}]

@autobind class OpportunityNeighborTable extends PureComponent {
  render() {
    const { data } = this.props

    const plotData = data.map(i => ({
      id: i.wellId,
      time: i.time ? new Date(i.time.date).toDateString() : '',
      distance: Number(i.distance).toFixed(1),
      perfLength: i.perfLength,
      pnp: i.pnp,
      hcpt: i.pnpHcpt,
      pnpPerm: i.pnpPerm,
      pnpInitialPressure: i.pnpInitialPressure,
      ip: i.targetIP,
      oil: i.oil,
      water: i.water,
      fr: i.fr,
    }))

    return (
      <div className="table">
        <ReactTable
          className="-striped"
          columns={columns}
          showPagination={false}
          data={plotData}
          pageSize={data.length}
        />
      </div>
    )
  }
}

export default OpportunityNeighborTable
