import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import VideoCard from './VideoCard'

import API from '../../../lib/api-store'


const baseClass = 'training-module'
const containerClass = `${baseClass}__container`

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    color: '#a7a9ae',
    fontWeight: 600,
    fontSize: '1rem',
  },
  iconButton: {
    borderRadius: 4,
    border: '2px solid #a7a9ae',
  },
  gridContainerClass: {
    maxWidth: 1000,
    margin: 'auto',
  }
}


@autobind class VideoList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videos: [],
    }
  }

  componentDidMount() {
    API.getTutorialVideos()
      .then((response) => {
        this.setState({ videos: response })
      })
  }

  render() {
    const { classes } = this.props
    const { videos } = this.state

    return (
      <Paper elevation={0} className={classes.root}>
        <Grid className={classes.gridContainerClass} container spacing={4}>
          {videos.map(video => (
            <Grid item xs={4}>
              <VideoCard data={video} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(VideoList)
