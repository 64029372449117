import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import ProjectRoutes from '../../../routes/ProjectRoutes'
import ProjectNavigation from './ProjectNavigation'
import API from '../../../lib/api-store'
import './Project.scss'

@autobind class Project extends Component {
  constructor(props) {
    super(props)
    this.state = {
      project: {name: ' '},
      license: null,
    }
  }

  componentDidMount() {
    this.fetchProject()
  }

  fetchProject() {
    const { match } = this.props
    const { projectId } = match.params

    API.getProject(projectId)
      .then(project => {
        this.setState({
          project
        })

        console.log('i got a project', project)
        
        API.getLicense(project.license)
        .then(license => {
          console.log('herre it is boys', license)
          this.setState({
            license
          })
        })
      })
  }

  render() {
    const { match } = this.props
    const { project, license } = this.state

    return (
      <div className='project-outer'>
        <div className='project-container'>
          <ProjectRoutes project={project} license={license}/>
        </div>
      </div>
    )
  }
}

export default withRouter(Project)
