import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { withStyles } from '@material-ui/core/styles'
import { Route, Switch } from 'react-router-dom'

import Video from './Video'
import VideoList from './VideoList'

import API from '../../../lib/api-store'

const baseClass = 'video-module'
const containerClass = `${baseClass}__container`

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    color: '#a7a9ae',
    fontWeight: 600,
    fontSize: '1rem',
  },
  iconButton: {
    borderRadius: 4,
    border: '2px solid #a7a9ae',
  },
  gridContainerClass: {
    maxWidth: 1000,
    margin: 'auto',
  },
}


@autobind class Videos extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <Switch>
        <Route path="/training/videos/*" component={Video} />
        <Route path="/training/videos" component={VideoList} />
      </Switch>
    )
  }
}

export default withStyles(styles)(Videos)
