import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'

const TimelineSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 8,
    width: 8,
    backgroundColor: '#3b86ff',
    //boxShadow: iOSBoxShadow,
    marginTop: -4,
    marginLeft: -4,
    '&:focus,&:hover,&$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
    },
  },
  active: {},
  valueLabel: {
    width: 70,
    top: -20,
    left: -30,
    textAlign: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '2px 2px 4px #00000029',
    border: '1px solid #E6E6E7',
    padding: 5,
    '& *': {
        background: 'transparent',
        color: '#000',
        width: 'auto',
        height: 'auto',
    },
  },
  markLabel: {
    transform: 'rotate(-45deg) translate(-14px, -12px)',
  },
  track: {
    height: 1,
  },
  rail: {
    height: 1,
    opacity: 1,
    backgroundColor: '#3b86ff',
    '&:before, &:after': {
        position: 'relative',
        display: 'block',
        content: '""',
        height: 20,
        width: 1,
        background: '#3b86ff',
        marginTop: -10,
    },
    '&:after': {
        right: '-100%',
        marginTop: -20,
    },
  },
  mark: {
    backgroundColor: '#3b86ff',
    height: 6,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider)

export default TimelineSlider