import React, { Component } from 'react'
import autobind from 'autobind-decorator'

@autobind class WorkflowNavBar extends Component {
  makeNavBar() {
    return (
      <React.Fragment>
        {this.makeItem('1. Customize', [0])}
        <div className="line" />
        {this.makeItem('2. Settings', [1])}
        <div className="line" />
        {this.makeItem('3. Execute', [2])}
        <div className="line" />
        {this.makeItem('4. Visualize', [3])}
      </React.Fragment>
    )
  }

  makeItem(item, stepNo) {
    const { step } = this.props
    const circleStyle = {}
    const fontStyle = {}
    let current = ''

    if (stepNo.includes(step)) {
      current = 'current'
    } else if (step > Math.max.apply(null, stepNo)) {
      circleStyle.background = '#4DC462'
      circleStyle.border = '#4DC462'
    }

    return (
      <div className="step-item">
        <div className="inner">
          <div className={`circle ${current}`} style={circleStyle} />
          <div className="text" style={fontStyle}>
            {item}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="nav-bar">
        {this.makeNavBar()}
      </div>
    )
  }
}

export default WorkflowNavBar
