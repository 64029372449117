import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import { ClipLoader } from 'react-spinners'
import Plot from 'react-plotly.js'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`


@autobind class RPOSHistogram extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
    }
  }



  render() {
    const { data, refLine } = this.props

    const vals = []

    data.forEach(innerArray => {
      innerArray.forEach(val => {
        if (val) {
          vals.push(val)
        }
      })
    })


    const plotData = [{
      name: '',
      x: vals,
      type: 'histogram',
      marker: {
        line: {
          color: "#000000",
          width: 0.4,
        }
      },
      showlegend: false,
    }]

    const layout = {
      title: '',
      autosize: true,
      margin: {
        l: 50,
        r: 10,
        b: 50,
        t: 10,
        pad: 0,
      },
      xaxis: {
        title: {
          text: 'x',
        },
      },
      yaxis: {
        title: {
          text: 'y',
        },
      },
    }

    if (refLine) {
      layout.shapes = [{
        type: 'line',
        x0: refLine,
        x1: refLine,
        yref: 'paper',
        y0: 0,
        y1: 1,
        line: {
          color: 'rgb(0,0,0)',
          width: 3,
          dash: 'dash',
        },
      }]
    }


    return (
      <Plot className="plot"
        data={plotData}
        layout={layout}
        useResizeHandler
        config={{showlegend: false, displaylogo: false, showSendToCloud: true, displayModeBar: false}}
      />
    )
  }
}


export default RPOSHistogram
