import React from 'react'

import img1 from './static/WellLogPlot/WellLogPlot1.jpg'
import img2 from './static/WellLogPlot/WellLogPlot2.jpg'
import img3 from './static/WellLogPlot/WellLogPlot3.jpg'
import img4 from './static/WellLogPlot/WellLogPlot4.jpg'
import img5 from './static/WellLogPlot/WellLogPlot5.jpg'
import img6 from './static/WellLogPlot/WellLogPlot6.jpg'

const WellLogPlotTooltip = (
  <div className={'description-container'}>
    <h4>Graph</h4>
    <ul>
      <li>
        <p>To align zones double click on the zone</p>
        <img src={img1} />
      </li>
      <li>
      <p>Select the right-side scroll bar to scroll for all the wells, and the left-side scroll bar to scroll for an individual well</p>
        <img src={img2} />
        <img src={img3} />
      </li>
      <li>
      <p>Double click on the curve to view curve properties.  You can also double click on the scale to add curve properties.  Double click on the track to vieww track properties.</p>
        <img src={img4} />
      </li>
      <li>
      <p>The scale is the length of the well from the surface to the total depth.  It can also be used to zoom in and out by hovering over it and dragging the white circles up or down.</p>
        <img src={img5} />
      </li>
    </ul>
    <h4>Templates</h4>
    <ul>
      <li>
      <p>Any modifications made to the track or curves will be saved as a template. This template will be used for each opportunity selected. The template will be private to the user only. When a teammate accesses this case, they will be able to create their own template without affecting the users’ template. If a teammate accesses this case, they will be able to create their own template.</p>
      </li>
      <li>
      <p>Users can use the Neighboring Wells table to add wells to the well log cross section. The same template will be applied to each well added but will use the appropriate data for the well. For example, the colors chosen will be applied.</p>
      </li>
    </ul>
    <h4>Buttons</h4>
    <img src={img6} />
    <ul>
      <li>
        <b>Camera</b>
      </li>
      <li>
        <b>Zoom In</b>
      </li>
      <li>
        <b>Zoom Out</b>
      </li>
      <li>
        <b>Zoom to Opportunity</b> - Used to zoom into the opportunities view
      </li>
      <li>
        <b>Zoom to Tops</b> - Used to focus the plot from your first top to the last top
      </li>
      <li>
        <b>Zoom to Logs</b> - Used to zoom into the first log datapoint to the last log datapoint.  This is useful if there is a period of interval in which there is no data, and you only want to view the logs.
      </li>
      <li>
        <b>Fit Tracks</b> - This button is useful if you want to fit all tracks into one view without having to use the horizontal scrollbar.
      </li>
      <li>
        <b>Full Screen</b>
      </li>
      <li>
        <b>Add Track</b> - Select a track, then select this button to add a track next to it.  You will see three options:
        <ol>
          <li>Index</li>
          <li>Linear - horizontal axis uses a linear scale</li>
          <li>Logarithmic - horizontal axis uses log scale</li>
        </ol>
      </li>
    </ul>
  </div>
)

export default WellLogPlotTooltip