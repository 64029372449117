import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Collapsible from 'react-collapsible'
import ReactTooltip from 'react-tooltip'

import { NavLink, withRouter, Switch, Route } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import SettingsSubGroup from './SettingsSubGroup'

@autobind class SettingsGroup extends Component {



  handleClick(e, path) {
    console.log(e, path)
    e.stopPropagation()
    console.log('path', path)
  }

  render() {
    const {
      subCategories,
      selectedSubcategory,
      categoryKey,
      settings,
      disabled,
      caseObj,
      editCaseObj,
      settingsMap,
      stepNo,
      setCanContinue,
      onChange,
      logOptions,
      zoneOptions,
      wellGroupSetsToMatchOptions,
      // mRefs,
      horizontalPropOptions,
      verticalPropOptions
    } = this.props

    return Object.keys(subCategories).map((subCategoryKey) => {
      console.log('SUB CATEGORY KEY', subCategoryKey)
      const subCategory = subCategories[subCategoryKey]
      const dependency = subCategory.dependency
      const selected = selectedSubcategory === subCategoryKey

      let settingsCategory = categoryKey
      let settingsSubcategory = subCategoryKey
      let selectedSettings = settingsMap

      if (categoryKey === 'global') {
        settingsCategory = subCategoryKey
        settingsSubcategory = subCategoryKey
        selectedSettings = settingsMap['global']
      }

      if (categoryKey === 'global' && subCategory && subCategory.Table) {
        settingsCategory = subCategoryKey
        settingsSubcategory = 'Table'
      }


      const { path, name, helperText } = settingsMap[categoryKey][subCategoryKey]

      // Update dependency logic

      let dependencyMet = false
      if(dependency){
        let [a, b, c, requirement] = dependency
        if(typeof requirement === 'boolean') dependencyMet = settings[a][b][c] === requirement
        else if(requirement === 'exists') dependencyMet = !!settings[a][b][c]
      }

      if ((!dependency && (subCategoryKey !== 'step' && subCategoryKey !== 'path')) || dependencyMet) {
        return (   
          <ExpansionPanel
            className={selected ? 'selected' : ''}
            onChange={(evt, expanded) => onChange({ subcategory: subCategoryKey, expanded })}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={subCategoryKey}
              id={subCategoryKey}
              key={subCategoryKey}
              // ref={mRefs[subCategoryKey]}
            >
              <Typography component="h3" variant="h6">{name}</Typography>
              { path && (
                <NavLink
                  target={"_blank"}
                  to={path}
                  data-tip={"Click to read more in the user manual"}
                >
                <FontAwesomeIcon
                  icon="question-circle"
                  className="help-icon"
                  style={{ 
                    'height': '16px',
                    'width': '16px',
                    'position': 'relative',
                    'left': '8px',
                    'top': '3px',
                  }}
                />
                </NavLink>
                )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <SettingsSubGroup
                stepNo={stepNo}
                setCanContinue={setCanContinue}
                categoryKey={settingsCategory}
                subCategoryKey={settingsSubcategory}
                settings={settings}
                caseObj={caseObj}
                settingsMap={selectedSettings}
                editCaseObj={editCaseObj}
                logOptions={logOptions}
                zoneOptions={zoneOptions}
                wellGroupSetsToMatchOptions={wellGroupSetsToMatchOptions}
                horizontalPropOptions={horizontalPropOptions}
                verticalPropOptions={verticalPropOptions}
                disabled={disabled}
                text={helperText}
              />        
            </ExpansionPanelDetails>
            <ReactTooltip effect="solid" />          
          </ExpansionPanel>
        )
      }
    })
  }
}

export default SettingsGroup
