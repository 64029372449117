import React from 'react'

import img1 from './Surfaces1.jpg'

const Surfaces = (
  <div className={'description-container'}>
    <p><b>Content:</b> Grids representing various surfaces (e.g., top structures, oil-water contacts, and gas-oil contacts) for each zone. 
    For information regarding which surface grids are required and optional, as well as how to create and export the grids from Petrel, please refer to the file “Data Preparation” under resources to the section 
    <b>Appendix: Data Preparation &amp; Quality Control</b>, subsection <b>Grid Data</b>.</p>
    <p><b>Utility:</b> Surfaces are used in many steps of the evaluation. For example, the OWC grids can be used to constrain the identification or remaining net pay (uncontacted net pay or UNP) in potential recompletions.</p>
    <p><b>Conventions:</b> </p>
    <ul>
      <li><b>Structure Grids: Z: </b> Values of Z need to be negative when below sea level</li>
      <li><b>OWC Grids: Z: </b> Values of Z need to be negative when below sea level</li>
      <li><b>GOC Grids: Z: </b> Values of Z need to be negative when below sea level</li>
    </ul>
    <p><b>File Format:</b> EarthVision grid (ASCII) (example shown in Figure 16).</p>
    <p><b>Units:</b> Please refer to the Data Preparation file under resources to the section <b>Appendix: Data Preparation &amp; Quality Control Table 17</b>. 
    Grids that represent structure depths or thicknesses must be in meters, however, these can be converted by data processing options when importing into SRO by turning on the setting to convert the data from feet to meters. Regardless, it is very important to be aware of the units for grids if your data is partially or totally in feet.</p>
    <p><b>Important Note:</b> Reservoir zones in SRO are called intervals and are defined in the <b>Well Formation Tops (REQUIRED)</b> input file. One important item to note is that there should be surfaces for each relevant interval, and the exact same names should be used for the surfaces as are seen in the tops file.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 16: Typical header information for Earth Vision grids (ASCII) format.</figcaption>
    </figure>
  </div>
)

export default Surfaces