import React, { Component } from 'react'
import autobind from 'autobind-decorator'

import {withStyles} from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const stepMap = {
  customize: 'Customize',
  execution: 'Execute',
  failed: 'Failed Execution',
  visualize: 'Visualize',
  visualization: 'Visualize',
}

const styles = {
  icon: {
    width: 35,
    height: 35,
  },
  success: {
    color: '#52ab7f',
  },
  progress: {
    color: '#ffcb4d',
  },
  execution: {
    color: '#0076c0',
  },
  error: {
    color: '#dc3545',
  }
}

@autobind class StepBar extends Component {
  icon(step){
    const { classes } = this.props

    switch(step) {
      case 'finalized': 
        return <CheckCircleIcon className={[classes.icon, classes.success].join(' ')} /> 
      case 'execution':
        return <MoreHorizIcon className={[classes.icon, classes.execution].join(' ')} /> 
      case 'failed':
        return <ErrorIcon className={[classes.icon, classes.error].join(' ')} /> 
      default:
        return <AccessTimeIcon className={[classes.icon, classes.progress].join(' ')} />
    }
  }

  render() {
    const { finalized, shared, caseId } = this.props
    let { step } = this.props
    if (step === 'running') {
     step = 'execution' 
    }
    const sharedText = shared ? 'Public' : 'Private'
    const progress = finalized ? `Complete - ${sharedText}` : 'In Progress'
    const displayStep = finalized ? '' : stepMap[step]

    if (finalized) {
      step = 'finalized'
    }


    return (
      <div key={caseId} className="step-bar">
        <div>
          {this.icon(step)}
        </div>
        <div className="step-bar-upper-text">{progress}</div>
        <div className="step-bar-lower-text">{displayStep}</div>
      </div>
    )
  }
}

export default withStyles(styles)(StepBar)
