import React,  { Component } from 'react'
import autobind from 'autobind-decorator'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import Grid from '@material-ui/core/Grid'

import { withRouter } from 'react-router-dom'


import RestoreIcon from '@material-ui/icons/Restore'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';

import videpPlaceholder from '../../../../static/images/video-placeholder.jpg'

import { formatSeconds } from '../../../lib/formatters'
import { numberFormatDate } from '../../../services/dateFormatterService'
import API from '../../../lib/api-store'

const styles = {
  card: {
    maxWidth: 345,
  },
  media: {
    backgroundColor: '#ccc',
    height: 140,
    position: 'relative',
    '& > .MuiSvgIcon-root': {
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '50%',
      marginLeft: '-25px',
      marginTop: '-25px',
      width: '50px',
      height: '50px',
      color: 'rgba(255, 255, 255, 0.5)',
      textAlign: 'center',
      lineHeight: '50px',
    }
  },
  meta: {
    width: '100%',
  },
  metaIcon: {
    marginRight: 5,
    color: '#CCC',
  },
  metaDetail: {
    display: 'flex',
    color: '#ea5a00',
  },
  description: {
    marginTop: 8,
  },
}
/*
.withRouter\(VideoCard\)-media-193::after {
      display: block;
      position: absolute;
      content: ' ';
      background: url('data:image/svg+xml;utf8,<svg class="MuiSvgIcon-root jss402" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z"></path></svg>');
      left: 50%;
      top: 50%;
      margin-left: -25px;
      margin-top: -25px;
      width: 50px;
      height: 50px;
      color: white;
      text-align: center;
      line-height: 50px;
    }
    */

@autobind class VideoCard extends Component {
  constructor(props){
    super(props)
    this.state = {
      thumbnail: null
    }
  }

  componentDidMount() {
    const title = this.props.data.Metadata.title
    API.getTutorialVideoThumbnail(title)
      .then(x =>this.setState({ thumbnail: x}))
  }

  clickHandler(key) {
    const { history } = this.props
    history.push('/training/videos/' + encodeURI(key))
  }

  render() {
    const { data, classes } = this.props
    const { thumbnail } = this.state
    return (
      <Card className={classes.card}>
        <CardActionArea onClick={() => this.clickHandler(data.Key)}>
          <CardMedia
            className={classes.media}
            image={thumbnail}
            title={data.Key}
          >
            <PlayCircleFilledWhiteIcon />
          </CardMedia>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
            {data.Metadata.title}
            </Typography>
            <Grid className={classes.meta} container spacing={4}>
              <Grid item xs={6}>
                <div className={classes.metaDetail}><CalendarTodayIcon className={classes.metaIcon} />{numberFormatDate(data.LastModified)}</div>
              </Grid>
              <Grid item xs={6}>
                {data && data.Metadata ? (
                  <div className={classes.metaDetail}><RestoreIcon className={classes.metaIcon} /> {formatSeconds(data.Metadata.length)}</div>
                ) : <Skeleton variant="text" /> }
              </Grid>
            </Grid>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
              {data.Metadata.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }
}

export default withStyles(styles)(withRouter(VideoCard))