import React from 'react'

import img1 from './static/WellEvents1.png'
import img2 from './static/WellEvents2.png'

const WellEvents = (
  <div className={'description-container'}>
  <p></p>
  <p></p>
  <p><b>Content:</b> A history of well operations.</p>
  <p><b>Utility:</b> To provide major historical well operation events that affect production.</p>
  <p><b>Conventions:</b> Date: Format must be mm/dd/yyyy</p>
  <p><b>File Format:</b> CSV template (downloadable) (example shown in Figure 5). </p>
  <p><b>Units:</b> Units, where appropriate, are indicated in the template and Table 5. </p>
  <p><b>Other Note:</b> Not all columns are required. See Table 5 for specifics.</p>
  <figure>
    <img src={img1} />
    <figcaption>Figure 5: Example of the first few rows of a “Well_Events.csv.”</figcaption>
  </figure>
  <figure>
    <figcaption>Table 5. Details for the “Well_ Events.csv.”</figcaption>
    <img src={img2} />
  </figure>
</div>
)

export default WellEvents