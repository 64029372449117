import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import caseActionsA from '../../../../../../../static/images/userManual/caseActionsA.png'

@autobind class Intro extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Case Actions
        </div>
        <div className='image-container'>
          <img className="" src={caseActionsA} alt="" />
        </div>
        <div className="content-row"> 
          <span className="blue">Share:</span> The share icon is only available for complete cases. Once a case is shared, all teammates that are a part of your workspace will be able to view the case.
        </div>
        <div className="content-row"> 
          <span className="blue">Duplicate:</span> The duplicate icon is only available for complete cases. The case creator or a teammate (if case is shared) can duplicate a case. Once a case is duplicated, users will see the original dataset and settings applied. Users can modify the case as needed. This is helpful for users that may want to change a few settings, and see the visualization results.
        </div>
        <div className="content-row"> 
          <span className="blue">Favorites:</span> Cases that are in progress or complete can be favorited. Favoriting a case is helpful for uesrs that want to set aside a case in the favorites tab.
        </div>
        <div className="content-row"> 
          <span className="blue">Edit:</span> The edit button is available for both in progress or complete cases. The edit button is used to view the case details, or to edit a cases name or description.A case is an instance of a user executing a certain module (e.g., recompletions, vertical new drills, or horizontal targets) with a specific group of settings and a particular dataset. In that sense, a case can be thought of as one of many experiments that the user can create to explore the impact of different settings and datasets on the generated opportunity inventory.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Intro)
