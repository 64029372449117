import React, { Component } from 'react'
import autobind from 'autobind-decorator'

import './PercentBar.scss'

const baseClass = 'percentBar'
const baseClassSmall = 'percentBarSmall'
const curBarClass = `${baseClass}__cur`
const remainingBarClass = `${baseClass}__remaining`

@autobind class PercentBar extends Component {


  render() {
    const  { curOne, curTwo, curThree, type, max, small } = this.props

    let percOne = curOne ? percOne = Math.min(Math.round(curOne/max*100), 100) : 0
    let percTwo = curTwo ? percTwo = Math.min(Math.round(curTwo/max*100), 100) : 0
    let percThree = curThree ? percThree = Math.min(Math.round(curThree/max*100), 100) : 0
    let remaining = 100 - percOne - percTwo - percThree

    if (type === 'two') {

      return (
        <div className={small ? baseClassSmall : baseClass}>
          <div className={curBarClass} 
            style={{width: `${percOne}%`, background: '#5A52DE'}}
          />
          <div className={curBarClass} 
            style={{width: `${percTwo}%`, background: '#2DD070'}}
          />
          <div className={remainingBarClass} 
            style={{width: `${remaining}%`}}
          />
        </div>
      )      
    }

    if (type === 'three') {

      return (
        <div className={small ? baseClassSmall : baseClass}>
          <div className={curBarClass} 
            style={{width: `${percOne}%`, background: '#2DD070'}}
          />
          <div className={curBarClass} 
            style={{width: `${percTwo}%`, background: '#E7B34A'}}
          />
          <div className={curBarClass} 
            style={{width: `${percThree}%`, background: '#5A52DE'}}
          />
          <div className={remainingBarClass} 
            style={{width: `${remaining}%`}}
          />
        </div>
      )      
    }


    return (
      <div className={small ? baseClassSmall : baseClass}>
        <div className={curBarClass} 
          style={{width: `${percOne}%`}}
        />
        <div className={remainingBarClass} 
          style={{width: `${remaining}%`}}
        />
      </div>
    )
  }
}

export default PercentBar
