import React, { Component } from 'react'
import autobind from 'autobind-decorator'

import styled from '@material-ui/core/styles/styled'
import Checkbox from '@material-ui/core/Checkbox'
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'


import './ThreeDModelProperties.scss'

const baseClass = 'three-d-properties'
const defineNullClass = `${baseClass}__define-null`
const nullInputClass = `${defineNullClass}__input`
const headerClass = `${baseClass}__header`


const propertyCardClass = 'property-card'
const propertyClass = `${propertyCardClass}__property`
const missingClass = `${propertyCardClass}__missing-icons`
const checkboxClass = `${propertyCardClass}__checkbox`

@autobind class ThreeDModelProperties extends Component {
  constructor(props){
    super(props)
    this.required = ['z_coord', 'x_coord', 'y_coord', 'ntg', 'phi', 'sw', 'k']
    this.convertable = ['z_coord', 'x_coord', 'y_coord', 'dtf', 'cellHeight']
    
    this.state = {
      hasRequired: false,
      missing: [],
    }
  }

  componentDidMount(){
    this.checkForRequiredProperties()
  }

  resetState(callback) {
    this.setState({
      hasRequired: false,
      missing: [],
    }, callback)
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.properties !== this.props.properties){
      this.resetState(() => this.checkForRequiredProperties())
    }
  }

  checkForRequiredProperties(){

    const { properties, setThreeDFileValidity } = this.props

    const missing = []
    const hasRequired = this.required.reduce((acc, prop) => {
      if(properties.includes(prop) === false){
        missing.push(prop)
        acc = false
      }
      return acc
    }, true)
    this.setState({ hasRequired, missing })
    setThreeDFileValidity(hasRequired)
  }

  onCheckboxClick(property, e) {

    const { propertiesToConvert, setThreeDFilePropertiesToConvert } = this.props;

    const copy = propertiesToConvert.slice(0)
    const propertyInArray = copy.indexOf(property)
    if(propertyInArray !== -1){
      copy.splice(propertyInArray, 1)
    } else {
      copy.push(property)
    }
    setThreeDFilePropertiesToConvert(copy)
  }

  render(){

    const { properties, file, upload3DModel, propertiesToConvert, onNullValueChange, nullValue } = this.props
    const { hasRequired, missing } = this.state

    const CustomAlert = styled(Alert)({
      paddingTop: "3px",
      paddingBottom: "3px",
      height: "auto",
      minHeight: "40px",
      userSelect: "none",
      display: "flex",
      justifyContent: "center",
      marginBottom: "15px",
    })

    const AlertHeader = styled(AlertTitle)({
      fontSize: "1.4em",
      fontWeight: "500",
      margin: "0 0 -2px 0"
    })

    const Included = styled(CheckCircleIcon)({
      height: "25px",
      width: "25px",
    })

    const Missing = styled(ErrorIcon)({
      height: "25px",
      width: "25px",
    })

    const PropertyCard = ({ property, missing }) => (
      <div className={!missing ? propertyCardClass : `${propertyCardClass} missing`}>
        <span className={propertyClass}>
          {property}
          {this.required.includes(property) && (<span className={'required'}>*</span>)}
        </span>
        <div className={checkboxClass}>
          {this.convertable.includes(property) && (
          <input
            type="checkbox"
            value={property}
            readOnly={!hasRequired}
            onClick={(e) => this.onCheckboxClick(property, e)}
            checked={propertiesToConvert && propertiesToConvert.includes(property)}
          />
          )}
        </div>
        <div className={missingClass}>
          {missing === false ? <Included className={'success'}/> : <Missing className={'missing'}/>}
        </div>
      </div>
    )

    


    return (
      <div className={baseClass}>

        {!hasRequired && (
          <CustomAlert severity="error" color="error">
            <AlertHeader>
              Some properties below were not found and are required for validation.  Please upload a model file with the required properties.
            </AlertHeader>
          </CustomAlert>
        )}
        <div className={defineNullClass}>
          <div>Null Value<span className="required">*</span></div>
          <input
            type="number"
            className={nullInputClass}
            onChange={onNullValueChange}
            value={nullValue ? nullValue.toString() : null }
          />
        </div>
        <div className={headerClass}>
          <span>Properties</span>
          <span>Convert from Ft to Meters</span>
          <div></div>
        </div>
        {missing.map(property => <PropertyCard property={property} missing={true}/>)}
        {properties.map(property => <PropertyCard property={property} missing={false}/>)}
      </div>
    )
  }
}

export default ThreeDModelProperties