import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Collapsible from 'react-collapsible'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { modelBuilderSettingsMap, swmOptimizationSettingsMap, GlobalSettingsMap } from '../../../../lib/settingsMap'
import SettingsSubGroupSWM from './SettingsSubGroupSWM'
import API from '../../../../lib/api-store'
import MUICard from '../../Visualization/common/MUICard'

import autopilotModeIcon from '../../../../../static/images/swm/settings/swm-mode-autopilot.svg'
import manualModeIcon from '../../../../../static/images/swm/settings/swm-mode-manual.svg'
import gridIcon from '../../../../../static/images/swm/settings/swm-setting-grids.svg'
import flowIcon from '../../../../../static/images/swm/settings/swm-setting-flow.svg'
import modelIcon from '../../../../../static/images/swm/settings/swm-setting-model.svg'
import calibrationIcon from '../../../../../static/images/swm/settings/swm-setting-calibration.svg'
import swmWellConstraintsIcon from '../../../../../static/images/swm/settings/swm-setting-well-constraints.svg'
import swmGroupConstraintsIcon from '../../../../../static/images/swm/settings/swm-setting-group-constraints.svg'


import './ModifySWM.scss'

const icons = {
  autopilot: autopilotModeIcon,
  manual: manualModeIcon,
  grids: gridIcon,
  flowUnitAllocation: flowIcon,
  modelSetup: modelIcon,
  calibration: calibrationIcon,
  swmOptimization: flowIcon,
  swmWellConstraints: swmWellConstraintsIcon,
  swmGroupConstraints: swmGroupConstraintsIcon,
}

const CategoryIcon = (props) => {
  const { category, className, ...restProps } = props
  const Component = icons[category]

  if(Component) {
    return <Component className={className}/>
  }
  return null
}


const getValFromPath = (path, settings) => {
  let item = settings
  let pList = path.split('.')
  let len = pList.length
  for (let i = 1; i < len; i++) {
      let elem = pList[i]
      item = item[elem]
  }
  return item
}

const settingsMapOptions = {
  'modelBuilder': modelBuilderSettingsMap,
  'swmOptimization': swmOptimizationSettingsMap,
}

@autobind class ModifySWM extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.refs = [];
    this.state = {
      logOptions: null,
      selectedCategory: null,
      selectedSubcategory: null,
    }
  }

  componentDidMount() {
    const { caseObj, setCanContinue, disabled, setStep, match } = this.props
    const { projectId, caseId, application } = match.params
    const { versionId, datasetId } = caseObj.dataKey


    setStep(1, `/${application}/projects/${projectId}/cases/${caseId}/initialize`, `/${application}/projects/${projectId}/cases/${caseId}/execute`)

    if (!disabled && caseObj.dataKey && caseObj.module && caseObj.settingsMode && caseObj.settingsMode !== 'forced-manual') {
      setCanContinue(true)
    } else {
      setCanContinue(false)
    }
  }

  selectCategory(val) {
    this.setState({
      selectedCategory: val
    })
  }

  setMode(val) {
    const { editCaseObj, match, setCanContinue } = this.props
    const { caseId } = match.params

    API.udateCaseSettingsMode(caseId, val)
      .then(caseObj => {
          // TODO - handle success/err
          editCaseObj(caseObj)
      })
      .catch(err => console.error(err))

    setCanContinue(true)
  }


  makePage() {
    const { caseObj } = this.props
    const mode = caseObj.settingsMode
    const caseModule = caseObj.module
    const disabled = mode === 'forced-manual'

    if (!caseObj) {
      return <div> Loading Case ... </div>
    }

    if (!mode || disabled) {
      return (
        <div className='choose-mode'>
          <MUICard>
            <div className="choose-mode-title">
              How should we get started?
            </div>
            <div className="choose-mode-text">
              <span className="blue">Autopilot Mode</span> is recommended for users that want to quickly accelerate to visualization.
            </div>
            <div className="choose-mode-text">
              <span className="blue">Manual Mode</span> is recommended for users that prefer to customize settings to optimize visualization results.
            </div>
            <div className='choose-mode-options'>
              <div className={`choose-mode-option ${disabled ? 'disabled' : ''}`} onClick={() => disabled ? console.log('test') : this.setMode('autopilot')}>
                <CategoryIcon category="autopilot" className="autopilot-icon"/>
                <div className="icon-text">Autopilot Mode</div>
              </div>
              <div className='choose-mode-option' onClick={() => this.setMode('manual')}>
                <CategoryIcon category="manual" className="manual-icon"/>
                <div className="icon-text">Manual Mode</div>
              </div>
            </div>
            {mode === 'forced-manual' && (
              <div className="choose-mode-text">
                <span className="blue">Autopilot Mode</span> is not available for the selected version.
              </div>
            )}
          </MUICard>
        </div>
      )
    }

    if (mode === 'autopilot') {
      if (caseModule === 'modelBuilder') {
        //TODO - less hardcoded once we add different modules (seperate component with different words/values based on module)
        const flowAllocationDisplay = caseObj.settings.EngineeringSettings.FlowUnitAllocation.setup === 'kh' ? 'Kh method' : 'Chan method'
        const fluidPropertiesDisplay = caseObj.settings.Config.SolverOptions.solve_all_pressure_together === 0 ? 'slightly compressible' : 'incompressible'      
        const gravityDisplay = caseObj.settings.Config.RunOptions.gravity === false ? 'ignored' : 'included'
        const gridsDisplay = caseObj.settings.Config.config_struct.numberCells
        const aquiferDisplay = caseObj.settings.Config.config_struct.numberOfAquiferRegions
        const wocDisplay = caseObj.settings.Config.config_struct.WOcontact.toFixed(2)
        const trainingDisplay = caseObj.settings.Config.RunOptions.training_data_prcnt
        const validationDisplay = 100 - trainingDisplay

        return (
          <div className="autopilot">
            <MUICard>
              <div className="autopilot-outer">
                <div className="autopilot-1">
                  Thanks for trusting Speedwise with your settings, we are selecting the best options for you!
                </div>
                <div className="autopilot-2">
                  We picked a few settings for your review.
                </div>
                <div className="autopilot-3">
                  <div className="autopilot-4 blue-background">
                    Flow allocation is based on {flowAllocationDisplay}.
                  </div>
                  <div className="autopilot-4">
                    Fluid properties are {fluidPropertiesDisplay}, and gravity effects are {gravityDisplay}.
                  </div>
                  <div className="autopilot-4 blue-background">
                    Grids are discretized to {gridsDisplay} PEBI cells.
                  </div>
                  <div className="autopilot-4">
                    Aquifers are divided into {aquiferDisplay} partitions. Water-Oil contact is set to {wocDisplay} ft.
                  </div>
                  <div className="autopilot-4 blue-background">
                    Models are trained using {trainingDisplay}% and validated with {validationDisplay}% production data.
                  </div>
                </div>
                <div className="autopilot-5">
                  {"You can review all settings or modify them in "}
                  <span className="autopilot-6" onClick={() => this.setMode('manual')}> 
                    Manual Mode
                  </span>
                </div>
              </div>

            </MUICard>
          </div>
        )
      } else if (caseModule === 'swmOptimization') {

        const optimizationModeDisplay = caseObj.settings.Config.config_struct.optWat ? 'minimize water' : 'maximize oil'
        const targetNameDisplay = caseObj.settings.Config.config_struct.optWat ? 'minimum oil limit' : 'maximum water limit'
        const targetDisplay = caseObj.settings.Config.config_struct.optWat ? caseObj.settings.Config.config_struct.MinOilLimit : caseObj.settings.Config.config_struct.MaxWaterLimit
        const forecastPeriodDisplay = caseObj.settings.Config.config_struct.forecastPeriod
        const maxIterationDisplay = caseObj.settings.Config.config_struct.maxIter


        return (
          <div className="autopilot">
            <MUICard>
              <div className="autopilot-outer">
                <div className="autopilot-1">
                  Thanks for trusting Speedwise with your settings, we are selecting the best options for you!
                </div>
                <div className="autopilot-2">
                  We picked a few settings for your review.
                </div>
                <div className="autopilot-3">
                  <div className="autopilot-4 blue-background">
                    The optimization mode is set to {optimizationModeDisplay}.
                  </div>
                  <div className="autopilot-4">
                    The {targetNameDisplay} is set to {targetDisplay}.
                  </div>
                  <div className="autopilot-4 blue-background">
                    The forecast period is set to {forecastPeriodDisplay}.
                  </div>
                  <div className="autopilot-4">
                    The maximum number of iterations is set to {maxIterationDisplay}.
                  </div>
                </div>
                <div className="autopilot-5">
                  {"You can review all settings or modify them in "}
                  <span className="autopilot-6" onClick={() => this.setMode('manual')}> 
                    Manual Mode
                  </span>
                </div>
              </div>

            </MUICard>
          </div>
        )
      }
    }


    return this.makeSettings()
  }

  makeSettings() {
    let { selectedCategory } = this.state
    const { caseObj } = this.props
    const { module } = caseObj
    const settingsMap = settingsMapOptions[module]

    const title = selectedCategory ? settingsMap[selectedCategory].display : null

    return (
      <div className="settings-groups">
        <div className="settings-groups-sidebar">
          <MUICard>
            { this.makeSettingsMenu() }
          </MUICard>
        </div>
        <div ref={this.myRef} className="settings-groups-main">
          <MUICard title={title}>
            { this.makeSettingsCategories() }
          </MUICard>
        </div>
      </div>
    )
  }



  makeSettingsMenu() {
    const { selectedCategory, selectedSubcategory } = this.state
    const { caseObj } = this.props
    const { module } = caseObj

    const settingsMap = settingsMapOptions[module]
    const settings = caseObj.settings

    return (
      <React.Fragment>
        <div className="sidebar-header">
          {"Select a Settings Group "}
          <FontAwesomeIcon icon="cog" className="action-button-icon" />
        </div>
        <div className="sidebar-icons">  
          { Object.keys(settingsMap).map(categoryKey => (
            <React.Fragment>
              <div className={`sidebar-icon ${selectedCategory === categoryKey ? 'selected' : ''}`} onClick={() => this.selectCategory(categoryKey)}>
                <CategoryIcon category={categoryKey} className="sidebar-icon-svg"/>
                <div className={`sidebar-icon-text ${categoryKey}`}>
                  {settingsMap[categoryKey].display}
                </div>
              </div>
            </React.Fragment>

          ))}
        </div>
      </React.Fragment>
    )
  }


  makeSettingsCategories() {
    const { selectedCategory } = this.state
    const { caseObj, editCaseObj } = this.props
    const { module } = caseObj
    const settingsMap = settingsMapOptions[module]


    if (!selectedCategory) {
      return (
        <div className="sub-category-container">
          <div className="no-category-1">
            <CategoryIcon category="manual" className="manual-icon"/>
          </div>
          <div className="no-category-2">
            Manual Mode
          </div>
          <div className="no-category-3">
            Select a group of Settings to begin.
          </div>
        </div>
      )
    }
    
    const stepNo = settingsMap[selectedCategory].step
    const subCategories = settingsMap[selectedCategory].subcategories

    return Object.keys(subCategories).map(subCategoryKey => {
      const subCategory = subCategories[subCategoryKey]

      if (subCategory.dependency && getValFromPath(subCategory.dependency.path, caseObj.settings) === subCategory.dependency.val) {
        return null
      }

      return (
        <div className="sub-category-container">
          <SettingsSubGroupSWM 
            subCategoryKey={subCategoryKey}
            data={subCategory}
            caseObj={caseObj}
            editCaseObj={editCaseObj}
            stepNo={stepNo}
          />
        </div>
      )
    })
  }




  render() {
    const { mode } = this.state
    const { caseObj, disabled } = this.props

    if (Object.keys(caseObj).length === 0) {
      return (
        <div className="initialize">
          <div className="initialize-header"> Loading Case </div>
        </div>
      )
    }

    if (caseObj.stepStatus === 'running' || caseObj.stepStatus === 'queued') {
      return (
        <div className="initialize">
          <div className="initialize-header"> Settings selection is disabled during execution </div>
        </div>
      )
    }

    return (
      <div className="modify">
        {
          caseObj.stepNo && !disabled && (
            <div className="settings-error">
              Changing settings on a prior run step will cause you to have to run the step again, as well as each step following it.  Changes to global settings will require the entire execution to be rerun.
            </div>
          )
        }
        { this.makePage() }
      </div>
    )
  }
}


export default withRouter(ModifySWM)
