import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'
import Collapsible from 'react-collapsible'

const SettingLink = (props) => {
  const { path, display } = props

  return (
    <div className='setting-link' >
      <NavLink to={path} className='setting-link-font'>
        {display}
      </NavLink>
    </div>
  )
}

@autobind class SettingsOptions extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Sweet Spots Settings
        </div>

        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Global Settings'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/basicZoneInfo'
            display='Basic Zone Info'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/logAlias'
            display='Log Alias'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/logSettings'
            display='Log Settings'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/gridCleaning'
            display='Grid Cleaning'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/otherGlobalSettings'
            display='Other Global Settings'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Engineering Analytics'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/declineCurveAnalysis'
            display='Decline Curve Analysis'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/flowUnitAllocation'
            display='Flow Unit Allocation'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Log Zone Analytics'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/logZoneAnalyticsOverview'
            display='Log Zone Analytics Overview'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/perforatedNetPay'
            display='Perforated Net Pay Settings (PNP)'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/netPay'
            display='Net Pay'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Attribute Mapping'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/attributeMapping'
            display='Attribute Mapping'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Drainage Analysis'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/generalSettings'
            display='General Settings'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/drainageRadiusCorrection'
            display='Drainage Radius Correction'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Map Generation'}
        >
          <SettingLink
            path='/training/manual/modules/sweetSpots/settings/mapGeneration'
            display='Map Generation Settings'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Sweet Spot Ranking'}
        >
          <SettingLink
            path='/training/manual/modules/sweetSpots/settings/sweetSpotRanking'
            display='RPOS/Ranking Attribute'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Target Search'}
        >
          <SettingLink
            path='/training/manual/modules/sweetSpots/settings/targetSearch'
            display='Target Search'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Production Forecast'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/initialProductionRateForecast'
            display='Initial Production Rate Forecast'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/thicknessNormalized'
            display='Settings for Thickness Normalized'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/darcyParameters'
            display='Darcy Parameters'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/neighborhoodSettings'
            display='Neighborhood Settings'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/interpolationSettings'
            display='Interpolation Settings'
          />
          <div className='grayLine2'/>
          <SettingLink
            path='/training/manual/modules/recompletions/settings/otherProductionForecast'
            display='Other Settings'
          />
        </Collapsible>
        <Collapsible
          className="setting-trigger"
          openedClassName="setting-trigger-open"
          trigger={'Opportunity Filtering'}
        >
          <SettingLink
            path='/training/manual/modules/recompletions/settings/opportunityFiltering'
            display='Opportunity Filtering Settings'
          />
        </Collapsible>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(SettingsOptions)
