import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import { withRouter } from 'react-router-dom'

import API from '../../../../../lib/api-store'


const columns = [{
  Header: '',
  accessor: 'checked',
  type: 'check',
}, {
  Header: 'Well',
  accessor: 'name',
}, {
  Header: 'Well Type',
  accessor: 'type',
}, {
  Header: 'Min. Liquid Rate',
  accessor: 'minLiquidRate',
  type: 'numerical',
}, {
  Header: 'Max. Liquid Rate',
  accessor: 'maxLiquidRate',
  type: 'numerical',
}, {
  Header: 'Initial Guess',
  accessor: 'initialGuess',
  type: 'numerical',
}]

@autobind class WellConstraintSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  handleSettingsChangeTable(type, action, item, index, e) {
    const { editCaseObj, caseObj, stepNo } = this.props
    const { caseId } = caseObj

    let val

    if (type === 'string') {
      val = e.target.value
    } else if (type === 'numerical') {
      if (action === 'change') {
        val = e.target.value
      } else if (action === 'blur') {
        val = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)  
      }
    } else if (type === 'date') {
      val = e
    } else if (type === 'check') {
        val = e.target.checked
    }

    const newCaseObj = {...caseObj}

    newCaseObj.settingsOpti.wellConstraints[index][item] = val

    if (newCaseObj.stepNo && stepNo <= newCaseObj.stepNo) {
      if (stepNo === 1) {
        newCaseObj.stepNo = null
        newCaseObj.err = null
        newCaseObj.stepStatus = null
      }
      else {
        newCaseObj.stepNo = stepNo - 1
        newCaseObj.err = null
        newCaseObj.stepStatus = 'successful'
      }
        
    }

    editCaseObj(newCaseObj)


    if (action === 'change' && type !== 'string' && type !== 'numerical') {
      API.updateCaseSettingsOpti(caseId, newCaseObj.settingsOpti, stepNo)  
    }
    if (action === 'blur' && (type === 'string' || type === 'numerical')) {
      API.updateCaseSettingsOpti(caseId, newCaseObj.settingsOpti, stepNo)  
    }
  }



  makeTable() {
    const { editCaseObj, caseObj, disabled, stepNo, match } = this.props
    const { caseId } = match.params

    if (!caseObj) {
      return
    }

    let data

    if (!caseObj.settingsOpti || !caseObj.settingsOpti.wellConstraints) {
      let wells

      if (caseObj.refWells) {
        wells = caseObj.refWells
      } else {
        wells = []
      }

      data = wells.map(i => ({
        checked: false,
        name: i.name,
        type: i.type,
        minLiquidRate: 0,
        maxLiquidRate: 0,
        initialGuess: 0
      }))

      const newCaseObj = {...caseObj}


      if (!newCaseObj.settingsOpti) {
        newCaseObj.settingsOpti = {
          wellConstraints: data,
          injectionGroupConstraints: [],
          productionGroupConstraints: [],
          VRRGroupConstraints: [],
        }
      } else {
        newCaseObj.settingsOpti.wellConstraints = data  
      }
      
      
      editCaseObj(newCaseObj)
      API.updateCaseSettingsOpti(caseId, newCaseObj.settingsOpti, stepNo)

    } else {
      data = caseObj.settingsOpti.wellConstraints
    }


    let tempColumns = [...columns]

    tempColumns.forEach((column) => { 
      if (column.type === 'numerical') {
        column.Cell = row => (
          <input
            disabled={disabled}
            style={{
              'background': 'none',
              "border": "none",
              "height": '100%',
              "width": "100%",
              "padding-left": '5px',
            }}
            type="number"
            value={data[row.index][column.accessor]}
            onChange={e => this.handleSettingsChangeTable('numerical', 'change', column.accessor, row.index, e)}
            onBlur={e => this.handleSettingsChangeTable('numerical', 'blur', column.accessor, row.index, e)}          
          />
        )
      }
      else if (column.type === 'check') {
        column.Cell = row => (
          <input
            type='checkbox'
            disabled={disabled}
            style={{
              'background': 'none',
              "border": "none",
              "height": '100%',
              "width": "100%",
              "padding-left": '5px',
            }}
            value={data[row.index][column.accessor]}
            checked={data[row.index][column.accessor]}
            onChange={e => this.handleSettingsChangeTable('check', 'change', column.accessor, row.index, e)}
          />
        )
      }
    })

    return (
      <React.Fragment>
        <div>
          Select the wells that you would like to impose constraints
        </div>
        <ReactTable
          className="-striped"
          columns={tempColumns}
          showPagination={false}
          data={data}
          pageSize={data.length}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className="">
        { this.makeTable()}
      </div>
    )
  }
}

export default withRouter(WellConstraintSettings)
