import React, { Component } from 'react'
import autobind from 'autobind-decorator'

import './DatasetSelection.scss'

@autobind class CaseSelection extends Component {
  makeCases() {
    const { selectedCase, caseOptions, loadingCases, handleCaseChange } = this.props

    if (!loadingCases && caseOptions.length === 0) {
      return 'No Valid Versions'
    }

    return caseOptions.map(i => {
      return (
        <div 
        	onClick={() => handleCaseChange(i.caseId)}
			className={`case-option ${ selectedCase && selectedCase === i.caseId ? 'selected' : ''}`}
        >
        	{i.name}
        </div>
      )
    })
  }

  render() {

    const { caseOptions, loadingCases } = this.props

    if (!loadingCases && (caseOptions.length === 0 || !caseOptions)) {
      return (
        <>
          <div className="initialize-header">
            No Valid Datasets Available
          </div>
          <div className="initialize-sub-header">
            This module does not have any compatible valid dataset versions.
            <br/>
            To run this module, create a dataset version that satisfies the module's file requirements.
          </div>
        </>
      )
    } else {
      return (
        <React.Fragment>
          <div className="initialize-header">
            Select a Case to Optimize
          </div>
          <div className="dataset-selection">
            {this.makeCases()}
          </div>
        </React.Fragment>
      )
    }
  }
}

export default CaseSelection
