import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Intro extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          SpeedWise Reservoir Opportunity (SRO)
        </div>
        <div className="content-row"> 
          The following is a complete user manual to SpeedWise® Reservoir Opportunity (SRO) , a key tool in the SpeedWise Platform. SpeedWise® Reservoir Opportunity (SRO) is a commercial software as a service (SaaS) product that applies advanced computational algorithms and data mining techniques on multi-disciplinary datasets to generate a comprehensive opportunity catalog (i.e., remaining, feasible, and actionable field development opportunities). These opportunities include recompletion behind-pipe candidates, vertical new drill locations, and optimal deviated/horizontal targets, aiming to increase reserves and/or increase production.
        </div>
        <div className="content-row"> 
          The method of software delivery and licensing is SaaS, which means SRO software is accessed online via a subscription, rather than bought and installed on individual computers. The platform is secure and protects client data, leveraging AWS (Amazon Web Services) center and network architecture built to meet the requirements of the most security-sensitive organizations. Other benefits include:
        </div>
        <div className="content-row"> 
        • <span className='blue'>Scalability and flexibility:</span> Users have access to high performance computing through the cloud and there is no need to purchase and maintain extra hardware.
        </div>
        <div className="content-row"> 
        • <span className='blue'>Painless deployment and upgrades:</span> No need to physically install the software and/or continuously receive updates (fixing bugs or adding new features is taken care of over the cloud).
        </div>
        <div className="content-row">
        • <span className='blue'>Improved collaboration and productivity:</span> Easy to collaborate with colleagues to upload data, run cases and share results.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Intro)
