import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import API from '../../../../../lib/api-store'


const columns = [{
  Header: 'Zones',
  accessor: 'zone',
  sortable: false,
}, {
  Header: 'Value',
  accessor: 'value',
  sortable: false,
  type: 'numerical',
}]

@autobind class CrossZoneCommunicationFactorSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  handleSettingsChangeTable(type, action, item, index, e) {
    const { editCaseObj, caseObj, stepNo } = this.props
    const { caseId } = caseObj


    console.log(type, e)


    let val

    if (type === 'string') {
      val = e.target.value
    } else if (type === 'numerical') {
      if (action === 'change') {
        val = e.target.value
      } else if (action === 'blur') {
        val = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)  
      }
    } else if (type === 'date') {
      val = e
    }


    const newCaseObj = {...caseObj}
    
    newCaseObj.settings.Grids.toPopulateGridValuesCZC[index] = val

    if (newCaseObj.stepNo && stepNo <= newCaseObj.stepNo) {
      if (stepNo === 1) {
        newCaseObj.stepNo = null
        newCaseObj.err = null
        newCaseObj.stepStatus = null
      }
      else {
        newCaseObj.stepNo = stepNo - 1
        newCaseObj.err = null
        newCaseObj.stepStatus = 'successful'
      }
        
    }

    editCaseObj(newCaseObj)


    if (action === 'change' && type !== 'string' && type !== 'numerical') {
      API.updateCaseSettings(caseId, newCaseObj.settings, stepNo)  
    }
    if (action === 'blur' && (type === 'string' || type === 'numerical')) {
      API.updateCaseSettings(caseId, newCaseObj.settings, stepNo)  
    }
  }



  makeTable() {
    const { caseObj, disabled } = this.props
    let zones

    if (caseObj && caseObj.ModuleSettings && caseObj.ModuleSettings.SWMSettings && caseObj.ModuleSettings.SWMSettings.layerNames) {
      zones = caseObj.ModuleSettings.SWMSettings.layerNames
    } else {
      zones = []
    }

    const data = zones.slice(0, zones.length - 1).map((zone, index) => {
      let val = ''

      if (caseObj.settings.Grids.toPopulateGridValuesCZC.length > index) {
        val = caseObj.settings.Grids.toPopulateGridValuesCZC[index]
      }

      return {
        zone: `${zone} to ${zones[index + 1]}`,
        value: val
      }
    })

    let tempColumns = [...columns]

    tempColumns.forEach((column) => { 
      if (column.type === 'numerical') {
        column.Cell = row => (
          <input
            disabled={disabled}
            style={{
              'background': 'none',
              "border": "none",
              "height": '100%',
              "width": "100%",
              "padding-left": '5px',
            }}
            type="number"
            value={data[row.index][column.accessor]}
            onChange={e => this.handleSettingsChangeTable('numerical', 'change', column.accessor, row.index, e)}
            onBlur={e => this.handleSettingsChangeTable('numerical', 'blur', column.accessor, row.index, e)}          
          />
        )
      }
    })

    return (
      <React.Fragment>
        <div>
          Cross-Zone Coummunication Factor
        </div>
        <ReactTable
          className="-striped"
          columns={tempColumns}
          showPagination={false}
          data={data}
          pageSize={data.length}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className="vert-to-horiz-table">
        { this.makeTable()}
      </div>
    )
  }
}

export default CrossZoneCommunicationFactorSettings
