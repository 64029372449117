import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import TuneIcon from '@material-ui/icons/Tune'
import GetAppIcon from '@material-ui/icons/GetApp'
import FullscreenIcon from '@material-ui/icons/Fullscreen'

import { setSelectedWells } from '../../../../redux/actions/waterfloodCase'
import { getConnectivity } from '../../../../services/waterfloodService'

import MUICard from '../../../common/MUICard/MUICard'

import './ConnectivityGraph.scss'

const findClosestPoint = (x,y,points) => {
  let closestDist, closestX, closestY

  points.forEach(point => {
    let dist = Math.pow(point.x - x, 2) + Math.pow(point.y - y, 2)
    if (!closestDist || dist < closestDist) {
      closestDist = dist
      closestX = point.x
      closestY = point.y
    }
  })

  return {
    x: closestX,
    y: closestY
  }
}

const plotLayout = {
  title: undefined,
  autosize: true,
  hovermode: 'closest',
  margin: {
    l: 60,
    r: 10,
    b: 30,
    t: 10,
    pad: 0,
  },
  yaxis: {
    scaleanchor: 'x',
    scaleratio: 1,
    constrain: 'range'
  },
  legend: {
    orientation: 'h',
    y: 1.06,
    x: 1.15,
    xanchor: "right",
  },
}

const pieLayout = {
      title: '',
      width: 100,
      height: 100,
      showlegend: false,
      margin: {
        l: 10,
        r: 10,
        b: 10,
        t: 10,
        pad: 0,
      },
    }

@autobind class ConnectivityGraph extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      plotData: [],
      anchorEl: null,
      open: false
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    const { timeline, selectedWells } = this.props
    if (prevProps.timeline !== timeline) {
      this.getData()
    }

    if (prevProps.selectedWells !== selectedWells) {
      this.filterData(null, null)
    }
  }

  getData() {
    const { timeline, caseId } = this.props

    getConnectivity(caseId, timeline).then((data) => {
      // TODO - handle wellDaata didnt load

      this.setState({
        data,
      })

      this.filterData(data, null)
    })
  }


  filterData(data, selectedWell) {
    const { selectedWells } = this.props

    if (!data) {
        data = this.state.data ? { ...this.state.data} : {wells: [], aquifer: [], boundary: []}   
    }

    if (!selectedWell) {
      selectedWell = this.state.selectedWell
    }

    if (selectedWell === 'null') {
      selectedWell = null
    }

    let selectedWellConnections
    let selectedWellObj
    const plotData = []

    if (selectedWell) {
      selectedWellObj = data.wells.find(i => selectedWell === i._id)  
      selectedWellConnections = selectedWellObj.timeSeriesData.length > 0 && selectedWellObj.timeSeriesData[0].wellConnections ? selectedWellObj.timeSeriesData[0].wellConnections : []
    }

    // Filter data if some wells are selected
    if (selectedWells.size > 0) {
        data.wells = data.wells.filter(i => selectedWells.includes(i._id))   
    }





    // Plot well connections
    if (selectedWell) {
      console.log(selectedWellObj)
      console.log(selectedWellConnections)
      selectedWellConnections.forEach(connection => {
        let neighborWell = data.wells.find(i => i.name === connection.wellName)

        if (neighborWell) {

          let centerX = (neighborWell.meta.location.x - selectedWellObj.meta.location.x) * connection.connEffic + selectedWellObj.meta.location.x
          let centerY = (neighborWell.meta.location.y - selectedWellObj.meta.location.y) * connection.connEffic + selectedWellObj.meta.location.y
          plotData.push({
            x: [selectedWellObj.meta.location.x,  centerX],
            y: [selectedWellObj.meta.location.y, centerY],
            mode: 'lines',
            type: 'scattergl',
            hoverinfo: 'skip',
            showlegend: false,
             line: {
                color: 'blue',
                width: 4,  
              }
          }) 
          plotData.push({
            x: [centerX, neighborWell.meta.location.x],
            y: [centerY, neighborWell.meta.location.y],
            mode: 'lines',
            type: 'scattergl',
            hoverinfo: 'skip',
            showlegend: false,
            line: {
              color: 'green',
              width: 4,  
            }
          }) 
        }
      })




    } else {
      data.wells.forEach(well => {
        let wellConnections
        if (well.timeSeriesData && well.timeSeriesData[0].wellConnections) {
          wellConnections = well.timeSeriesData[0].wellConnections
          wellConnections.forEach(connection => {
            let neighborWell = data.wells.find(i => i.name === connection.wellName)
            if (neighborWell) {
              plotData.push({
                x: [well.meta.location.x, neighborWell.meta.location.x],
                y: [well.meta.location.y, neighborWell.meta.location.y],
                mode: 'lines',
                type: 'scattergl',
                hoverinfo: 'skip',
                showlegend: false,
                marker: {
                  color: 'rgba(128, 128, 128, 128)',
                },
              })              
            }
          })
        }
      })
    }


    // Plot aquifer connections
    if (data.aquifer.length > 1) {
      //TODO - handle multiple aquifers
    } else {

      if (!selectedWell) {
        data.wells.forEach(well => {
          if (well.timeSeriesData && well.timeSeriesData[0].aquiferConnections) {
            let closestPoint = findClosestPoint(well.meta.location.x, well.meta.location.y, data.boundary)
            plotData.push({
              x: [well.meta.location.x, closestPoint.x],
              y: [well.meta.location.y, closestPoint.y],
              mode: 'lines',
              type: 'scattergl',
              hoverinfo: 'skip',
              showlegend: false,
              marker: {
                color: 'rgba(128, 128, 128, 128)',
                line: {
                  width: 1,
                  color: 'rgba(0,0,0,1)',
                },
              },
            })  
          }
        })
      }
    }





    let producerData
    let injectorData
    let inactiveData = []

    if (data.wells.length > 0 && data.wells[0].timeSeriesData) {
      producerData = data.wells.filter(i => i.timeSeriesData[0].status === 'producer')
      injectorData = data.wells.filter(i => i.timeSeriesData[0].status === 'injector')
      inactiveData = data.wells.filter(i => i.timeSeriesData[0].status === 'inactive')
    } else {
      producerData = data.wells.filter(i => i.meta.type === 'Producer')
      injectorData = data.wells.filter(i => i.meta.type === 'Injector')
    }

    if (data.boundary && data.boundary.length > 0) {
      data.boundary.push(data.boundary[0])

      plotData.push({
        name: 'Boundary',
        x: data.boundary.map(i => i.x),
        y: data.boundary.map(i => i.y),
        mode: 'lines',
        type: 'scattergl',
        marker: {
          color: 'rgba(0, 0, 0, 1)',
          size: 1,
          line: {
            width: 1,
            color: 'rgba(0,0,0,1)',
          },
        },
      })
    }

    console.log(selectedWellConnections)

    // Plot producers
    plotData.push({
      name: 'Producers',
      x: producerData.map(i => i.meta.location.x),
      y: producerData.map(i => i.meta.location.y),
      text: producerData.map(i => i.name),
      // hovertemplate: '<b>%{text}</b>',
      mode: 'markers',
      type: 'scattergl',
      customdata: producerData.map(i => i._id),
      marker: {
        size: selectedWell ? producerData.map(i => {
          if (selectedWellConnections.map(j => j.wellId).includes(i._id) || selectedWell === i._id) {
            let maxWaf = Math.max(...selectedWellConnections.map(j => j.WAF))
            let curWaf = selectedWellConnections.find(j => j.wellId === i._id) ? selectedWellConnections.find(j => j.wellId === i._id).WAF : maxWaf

            console.log( Math.max(20 * curWaf / maxWaf, 5))
            return Math.max(20 * curWaf / maxWaf, 10)
          }

          return 9 
        }) : 9,
        color: selectedWell ? producerData.map(i => {
          if (i._id === selectedWell) {
            return 'rgba(0, 128, 0, 1)'
          } else if (selectedWellConnections.map(j => j.wellId).includes(i._id)) {
            return 'rgba(0, 128, 0, 1)'
          }
         return 'rgba(0, 128, 0, .1)'
        }) : 'rgba(0, 128, 0, 1)',
      },
    })

    // Plot  injectors
    plotData.push({
      name: 'Injectors',
      x: injectorData.map(i => i.meta.location.x),
      y: injectorData.map(i => i.meta.location.y),
      text: injectorData.map(i => i.name),
      // hovertemplate: '<b>%{text}</b>',
      mode: 'markers',
      type: 'scattergl',
      customdata: injectorData.map(i => i._id),
      marker: {
        size: selectedWell ? injectorData.map(i => {
          if (selectedWellConnections.map(j => j.wellId).includes(i._id) || selectedWell === i._id) {
            let maxWaf = Math.max(...selectedWellConnections.map(j => j.WAF))
            let curWaf = selectedWellConnections.find(j => j.wellId === i._id) ? selectedWellConnections.find(j => j.wellId === i._id).WAF : maxWaf

            console.log( Math.max(20 * curWaf / maxWaf, 5))
            return Math.max(20 * curWaf / maxWaf, 10)
          }

          return 9 
        }) : 9,
        color: selectedWell ? injectorData.map(i => {
          if (i._id === selectedWell) {
            return 'rgba(0, 0, 128, 1)'
          } else if (selectedWellConnections.map(j => j.wellId).includes(i._id)) {
            return 'rgba(0, 0, 128, 1)'
          }
         return 'rgba(0, 0, 128, .1)'
        }) : 'rgba(0, 0, 128, 1)',
      },
    })


    // Plot inactive
    plotData.push({
      name: 'Inactive',
      x: inactiveData.map(i => i.meta.location.x),
      y: inactiveData.map(i => i.meta.location.y),
      text: inactiveData.map(i => i.name),

      // hovertemplate: '<b>%{text}</b>',
      mode: 'markers',
      type: 'scattergl',
      customdata: inactiveData.map(i => i._id),
      marker: {
        size: selectedWell ? inactiveData.map(i => i._id === selectedWell ? 15 : 9) : 9,
        color: 'rgba(128, 128, 128, 128)',
        color: selectedWell ? inactiveData.map(i => i._id === selectedWell ? 'rgba(128, 128, 128, 1)' : 'rgba(128, 128, 128, .1)') : 'rgba(128, 128, 128, 128)'
      },
    })

    this.setState({
      plotData,
      loaded: true,
    })
  }

  handleSelect(e) {
    const { data } = this.state
    const { rxSetSelectedWells } = this.props

    let selectedNames = e.points.map(i => i.text).filter(i => i)
    let selectedIds = data.wells.filter(i => selectedNames.includes(i.name)).map(i => i._id)

    rxSetSelectedWells(selectedIds)
  }

  handleClick(e) {
    let selectedWell = e ? e.points[0].data.customdata[e.points[0].pointIndex] : null

    let selectedWellInput = selectedWell ? selectedWell : 'null'
    this.filterData(null, selectedWellInput)

    this.setState({
      selectedWell,
    })
  }


  render() {
    const { loaded, plotData, selectedWell  } = this.state
    const { timeline, rxToggleSettings } = this.props

    if (plotData === undefined) {
      return 'Unable to load well data'
    }

    return (
      <MUICard
        title="Connectivity Graph"
        busy={!loaded}
        action={(
          <>
            <IconButton onClick={() => rxToggleSettings()} aria-label="settings">
              <TuneIcon />
            </IconButton>
            <IconButton aria-label="fullscreen">
              <FullscreenIcon />
            </IconButton>
            <IconButton aria-label="export">
              <GetAppIcon />
            </IconButton>
          </>
        )}
      > 

        <Plot
          style={{ 'width': '100%' }}
          data={plotData}
          layout={plotLayout}
          config={{ displaylogo: false, showSendToCloud: true}}
          useResizeHandler
          onSelected={(data) => this.handleSelect(data)}
          onClick={(data) => this.handleClick(data)}
        />
        { selectedWell && (
          <button className="clear-selected-well" onClick={() => this.handleClick(null)}>
            Clear Selected Well
          </button>
        )}
      </MUICard>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  rxToggleSettings: value => dispatch(toggleSettings()),
  rxSetSelectedWells: value => dispatch(setSelectedWells(value)),
})

const mapStateToProps = state => ({
  timeline: state.getIn(['waterfloodCase', 'timeline']),
  selectedWells: state.getIn(['waterfloodCase', 'selectedWells'])
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectivityGraph))
