import { ApiGatewayManagementApi } from 'aws-sdk'
import React, { Component } from 'react'
import config from '../../../config.json'
import API from '../../../lib/api-store'

// TODO - make this less hardcoded, just tryna test for now
const LOGIN_URL = process.env.API_ADDRESS == `http://localhost:3001/api`
  ? `http://localhost:3001/loginDev`
  : process.env.API_ADDRESS == `https://speedwise.ai/api`
      ? `https://speedwise.auth.us-east-1.amazoncognito.com/login?client_id=6bsd0d9a6i1baoquht71mvc8p2&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://speedwise.ai/loginAuth`
      : process.env.API_ADDRESS ==  `https://preproduction.speedwise.ai/api`
          ? `https://preproduction-speedwise.auth.us-east-1.amazoncognito.com/login?client_id=3hpp65jogvcjjd6crailu3puhj&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://preproduction.speedwise.ai/loginAuth`
          : null

class LoginFormFake extends Component {

  componentDidMount() {
    console.log('clearing cookies and redirecting to login')
    //Attempting to clear cookie whenever we force the login screen, just to see if this prevents the login error Johanna is experiencing
    API.clearCookies()

    //Need time to read console logs for testing
    // setTimeout(() => window.location.href = LOGIN_URL, 15000)
    window.location.href = LOGIN_URL
  }

  render() {
    console.log('i made it here')

    return (
      <div/>
    )
  }
}


export default LoginFormFake