import ProgressPromise from 'progress-promise'
import { rootUrl, datasetBaseUrl, fileBaseUrl, waterfloodBaseUrl, sgoBaseUrl } from './apiRoutes'
import { createPutRequestParams, createDeleteRequestParams, createPostRequestParams } from './apiRequestHelper'

export function uploadFiles(datasetId, files, category, subcategory, uploadParameters, uniqueParameters) {
  return Object.values(files).map((file) => {
    const formData = new FormData()

    formData.append('uploadedFile', file, file.name)

    if (uploadParameters) {
      const params = uniqueParameters ? { ...uploadParameters, ...uniqueParameters[file.name] } : uploadParameters
      console.log(params)
      formData.append('uploadParameters', JSON.stringify(params))
    }
    
    let fileUploadUrl = `${datasetBaseUrl}/${datasetId}/file/${category}`
    if (category === '2.5D_Model') {
      fileUploadUrl += `/twoDModelCategory/${subcategory}`
    } else if (category === 'Grid_Data'){
      fileUploadUrl += `/surfaceCategory/${subcategory}`
    }

    return {
      file: {
        originalFilename: file.name,
        extension: file.name.replace(/.*(\.)(.*)/, '$2'),
        fileCategory: subcategory || category,
        dateCreated: new Date(),
      },
      promise: new ProgressPromise((resolve, reject, progress) => {
        const request = new XMLHttpRequest()
        request.onload = function onFileUploadComplete(data) {
          // debugger;
          if (data.target.status !== 201) {
            alert(`Recieved a status not 201:' ${data.target.status}`)
            console.log('response target', data.target)
            reject(data.target)
          } else {
            resolve(data.target.response)
          }
        }

        let previous = {
          time: new Date().getTime(),
          loaded: 0,
        }

        let current = {
          time: new Date().getTime(),
          loaded: 0,
        }

        request.upload.addEventListener('progress', function onFileProgress(event) {
          if(event.lengthComputable){

            previous.time = current.time
            previous.loaded = current.loaded
            current.time = new Date().getTime()
            current.loaded = event.loaded

            console.log(`${file.name} - ${(event.loaded / event.total * 100).toFixed(2)}% - ${(((current.loaded - previous.loaded))/(1000*(current.time - previous.time))).toFixed(2)} Mbps - ${(event.loaded / 1000000).toFixed(2)} Mb loaded `)
            progress(Math.floor(event.loaded / event.total * 100))
          } else {
            //https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest "Monitoring Progress" section
            console.log('length not computible for event: ', event)
          }
        }, false)

        request.onerror = function onFileUploadError(error) {
          alert('File upload error event')
          console.log('ERRORRRRRRRR')
          console.log('file upload error: ', error)
          reject(error)
        }

        request.open('POST', fileUploadUrl)
        request.send(formData)
      }),
    }
  })
}

export async function getFiles(fileIds, datasetId, versionId) {
  const getFilesUrl = `${fileBaseUrl}/getByIds`
  const getFilesParams = await createPostRequestParams({
    fileIds,
    datasetId,
    versionId,
  })

  return fetch(getFilesUrl, getFilesParams).then(r => r.json())
}

export async function deleteFile(datasetId, fileId) {
  const deleteFileUrl = `${datasetBaseUrl}/${datasetId}/file/${fileId}`
  const deleteParams = await createDeleteRequestParams()

  return fetch(deleteFileUrl, deleteParams)
}

export function generateDownloadUrl(fileId, datasetId, versionId) {
  return `${fileBaseUrl}/${fileId}/raw?datasetId=${datasetId}&versionId=${versionId}`
}

export const generateDownloadResultsUrl = caseId => fetch(`${waterfloodBaseUrl}/case/${caseId}/optimizationResults`)
    .then(r => r.json())

export const generateDownloadModelBuilderResultsUrl = caseId => `${waterfloodBaseUrl}/case/${caseId}/modelBuilderResults`

export const generateSRODownloadResultsUrlOld = (caseId, moduleName) => fetch(`${sgoBaseUrl}/case/${caseId}/sgoResultsOld?moduleName=${moduleName}`)
    .then(r => r.json())

export const generateSRODownloadResultsUrl = (caseId, moduleName, name) => fetch(`${sgoBaseUrl}/case/${caseId}/sgoResults?moduleName=${moduleName}&name=${name}`)
    .then(r => r.json())

export const generateSRODownloadResultsDCA = (caseId, moduleName, name) => `${sgoBaseUrl}/case/${caseId}/sgoResults?moduleName=${moduleName}&name=${name}`
//Only generating URL in this, not performing a fetch.


export function generateResourceDownloadUrl(key) {
  return `${rootUrl}/tutorials/resource?key=${key}`
}

// export function revalidateFile(fileId) {
//   const revalidateFileUrl = `${fileBaseUrl}/${fileId}/startSingleFileValidation`
//   const revalidateParams = createPutRequestParams()
//   return fetch(revalidateFileUrl, revalidateParams)
// }

export async function startValidatingDataset(datasetId) {
  const startValidatingUrl = `${datasetBaseUrl}/${datasetId}/startValidation`
  const startValidatingParams = await createPostRequestParams()

  return fetch(startValidatingUrl, startValidatingParams)
}
