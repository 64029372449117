import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import { Numeric, Integer, DateCell } from '../../../../lib/formatters'
import { stringFormatDate } from '../../../../services/dateFormatterService'

@autobind class WellPerforationTable extends PureComponent {
  render() {
    const { data } = this.props
    const perforations = data.perforations
    const sortedPerforations = perforations.slice().sort((a,b) => new Date(a.dateOpened.date) - new Date(b.dateOpened.date));

    const plotData = sortedPerforations.map((obj, idx) => {
      return {
        index: idx + 1,
        top: obj.top,
        base: obj.base,
        openDate: obj.dateOpened && obj.dateOpened.date ? new Date(obj.dateOpened.date) : Number.POSITIVE_INFINITY,
        closedDate: obj.dateClosed && obj.dateClosed.date ? new Date(obj.dateClosed.date) : Number.POSITIVE_INFINITY,
        zoneIds: obj.zoneIds && obj.zoneIds instanceof Array ? obj.zoneIds.join(', ') : '',
      }
    })
    console.log('plot data', plotData)

    return (
      <div className="table">
        <ReactTable
          className="-striped -highlight"
          columns={
            [{
              Header: 'Perforation #',
              accessor: 'index',
              minWidth: 80,
            }, {
              Header: 'Top (m)',
              accessor: 'top',
              minWidth: 80,
              Cell: Integer,
            }, {
              Header: 'Base (m)',
              accessor: 'base',
              minWidth: 80,
              Cell: Integer,
            }, {
              Header: 'Open Date',
              accessor: 'openDate',
              minWidth: 80,
              sortType: 'datetime',
              Cell: DateCell
            }, {
              Header: 'Closed Date',
              accessor: 'closedDate',
              minWidth: 80,
              sortType: 'datetime',
              Cell: DateCell
            }, {
              Header: 'Zone Ids',
              accessor: 'zoneIds',
              minWidth: 80,
            }]
          }
          showPagination={false}
          data={plotData}
          pageSize={Object.keys(plotData).length}
          getTdProps={(state, rowInfo, column, instance) => ({
            onClick: (e, handleOriginal) => {
              this.handleClick(e, rowInfo.row.name)
            },
          })}
        />
      </div>
    )
  }
}

export default WellPerforationTable
