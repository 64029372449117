import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { Redirect, withRouter } from 'react-router-dom'

import { FileCopy, Edit, Favorite, History, Share } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

const favoritedIconClass = 'favorited'
const sharedIconClass = 'shared'

const actionsClass = `qri-card__actions-container`
const favoriteClass = `${actionsClass}__favorite`
const favoriteSelectedClass = `${favoriteClass}--selected`
const shareClass = `${actionsClass}__share`
const shareSelectedClass = `${shareClass}--selected`

const iconButtonClass = 'card-icon-btn'

import './ActionButtons.scss'

@autobind class ActionButtons extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { 
      finalized,
      caseId,
      isFavorite,
      isShared,
      handleShareCase,
      handleFavoriteCase,
      openModal,
      openEditCaseModal
    } = this.props


    if (finalized) {
      return (
        <>
          <IconButton className={iconButtonClass} data-tip="Edit Case Summary" end="start" aria-label="edit">
            <Edit
              onClick={e => openEditCaseModal(e, caseId)}
            />
          </IconButton>
          <IconButton className={iconButtonClass} data-tip="Duplicate Case" end="start" aria-label="copy">
            <FileCopy 
              onClick={e => openModal(e, caseId)}
            />
          </IconButton>
          <IconButton className={`${iconButtonClass} ${isFavorite ? favoriteSelectedClass : ''}`} data-tip={!isFavorite ? "Add Favorite" :"Remove Favorite"} end="start" aria-label="favorite">
            <Favorite
              onClick={e => handleFavoriteCase(e, caseId, isFavorite)}
            />
          </IconButton>
          <IconButton className={`${iconButtonClass} ${isShared ? shareSelectedClass : ''}`} data-tip={isShared ? "Unshare" : "Share"} end="start" aria-label="share">
            <Share
              onClick={e => handleShareCase(e, caseId, isShared)}
            />
          </IconButton>
          <ReactTooltip effect="solid" />
        </>
      )
    }

    return (
      <>
        <IconButton className={`${iconButtonClass} ${isFavorite ? favoriteSelectedClass : ''}`} data-tip={isFavorite ? "Remove Favorite" : "Add Favorite"} end="start" aria-label="favorite">
          <Favorite
            onClick={e => handleFavoriteCase(e, caseId, isFavorite)}
          />
        </IconButton>
        <IconButton className={iconButtonClass} data-tip="Edit Case Summary" end="start" aria-label="edit">
          <Edit
            onClick={e => openEditCaseModal(e, caseId)}
          />
        </IconButton>
        <ReactTooltip effect="solid" />
      </>

    )
  }
}

export default withRouter(ActionButtons)
