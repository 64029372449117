import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'
import { withRouter } from 'react-router-dom'

import moment from 'moment'

import InputLabel from '@material-ui/core/InputLabel'
import Select, { components } from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress'

import SelectedWellsChip from './SelectedWellsChip'
import MUICard from '../../../common/MUICard/MUICard'
import { getModelAccuracy } from '../../../../services/waterfloodService'

import './ModelAccuracy.scss'
import Axios from 'axios'

const baseClass = 'model-accuracy'
const filtersClass = `${baseClass}__filters`

const xAxisOptions = [
  { label: 'Date', value: 'year' },
  { label: 'Cumulative Water Injection', value: 'cumWaterInjVol' },
]

const yAxisOptions = [
  { label: 'Oil Production Rate​', value: 'oilProdRate' },
  { label: 'Water Production Rate​', value: 'waterProdRate' },
  { label: 'Liquid Production Rate', value: 'fluidProdRate' },
  { label: 'Water Injection Rate​', value: 'waterInjRate' },
  { label: 'Water Cut​', value: 'waterCut' },
  { label: 'Cumulated Oil Production​', value: 'cumOilProd' },
  { label: 'Cumulated Water Production​', value: 'cumWaterProd' },
  { label: 'Cumulated Liquid Production', value: 'cumFluidProd' },
]

const defaultYValues = [
  yAxisOptions[0].value,
  yAxisOptions[1].value,
  yAxisOptions[2].value,
]

const ValueContainer = ({ children, ...props }) => {
  return <components.ValueContainer {...props}>Select...</components.ValueContainer>
}

@autobind class ModelAccuracy extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      caseObj: [],
      loaded: false,
      yAxisSelected: defaultYValues,
      xAxisSelected: xAxisOptions[0].value,
    }
  }

  componentDidMount() {
    const { xAxisSelected } = this.state
    const { selectedWells, caseId } = this.props

    getModelAccuracy(caseId, xAxisSelected, selectedWells).then((caseObj) => {
      this.setState({
        caseObj,
        loaded: true,
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { xAxisSelected } = this.state
    const { selectedWells, caseId } = this.props

    if (prevProps.selectedWells && selectedWells && prevProps.selectedWells.length !== selectedWells.length) {
      getModelAccuracy(caseId, xAxisSelected, selectedWells).then((caseObj) => {
        this.setState({
          caseObj,
          loaded: true,
        })
      })
    }
  }

  handleXAxisChange(e) {
    const { caseId } = this.props
    const val = !e ? e : e.value

    if (val) {
      getModelAccuracy(caseId, val).then((caseObj) => {
        this.setState({
          xAxisSelected: val,
          caseObj,
          loaded: true,
        })
      })
    }
  }

  handleYAxisChange(e) {
    const { yAxisSelected } = this.state
    const values = !!e ? e.map(mod => mod.value) : yAxisSelected
    this.setState({
      yAxisSelected: values,
    })
  }

  render() {
    const { timeline, wells, caseId } = this.props
    const { loaded, xAxisSelected, yAxisSelected } = this.state
    let { caseObj } = this.state
    if (caseObj === undefined) {
      return 'Unable to load well data'
    }

    const activeAxis = {
      y: false, 
      y2: false,
      y3: false,
    }

    const plotData = []
    let xAxisText = "Year"
    let caseObjZeros

    if (xAxisSelected == 'cumWaterInjVol') {
      caseObj = caseObj.map( i => {
        i._id = i.cumWaterInjVol

        return i
      })



      caseObjZeros = caseObj.filter(i => i._id == 0)

      caseObj = caseObj.filter(i => i._id !== 0)
      xAxisText = "Cumulative Water Injection"
    }


    if (yAxisSelected.includes('oilProdRate')) {
      activeAxis.y = true
      plotData.push({
        name: 'Oil (Data)',
        y: caseObj.map(t => t.oilProdRate),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        showlegend: true,
        marker: { symbol: 'circle' },
        line: {
          color: 'green',
          width: 1,
        },
      })

      plotData.push({
        name: 'Oil (Model)',
        y: caseObj.map(t => t.oilProdRateModel),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        showlegend: true,
        marker: { symbol: 'diamond' },
        line: {
          color: 'green',
          width: 1,
        },
      })
    }

    if (yAxisSelected.includes('waterProdRate')) {
      activeAxis.y = true
      plotData.push({
        name: 'Water (Data)',
        y: caseObj.map(t => t.waterProdRate),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        showlegend: true,
        marker: { symbol: 'square' },
        line: {
          color: 'rgb(9, 1, 138)',
          width: 1,
        },
      })

      plotData.push({
        name: 'Water (Model)',
        y: caseObj.map(t => t.waterProdRateModel),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        showlegend: true,
        marker: { symbol: 'triangle-up'},
        line: {
          width: 1,
          color: 'rgb(9, 1, 138)',
        },
      })
    }

    if (yAxisSelected.includes('fluidProdRate')) {
      activeAxis.y = true
      plotData.push({
        name: 'Liquid (Data)',
        y: caseObj.map(t => t.fluidProdRate),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        showlegend: true,
        marker: { symbol: 'square' },
        line: {
          color: 'black',
          width: 1,
        },
      })

      plotData.push({
        name: 'Liquid (Model)',
        y: caseObj.map(t => t.fluidProdRateModel),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        showlegend: true,
        marker: { symbol: 'triangle-up'},
        line: {
          width: 1,
          color: 'black',
        },
      })
    }

    if (yAxisSelected.includes('waterInjRate')) {
      activeAxis.y = true
      plotData.push({
        name: 'Oil (Water Injection)',
        y: caseObj.map(t => t.waterInjRate),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        marker: { symbol: 'triangle-down'},
        showlegend: true,
        line: {
          width: 1,
          color: 'rgb(9, 1, 138)',
        },
      })
    }

    if (yAxisSelected.includes('waterCut')) {
      activeAxis.y3 = true
      plotData.push({
        name: 'Watercut',
        y: caseObj.map(t => t.waterProdRate / t.fluidProdRate),
        x: caseObj.map(t => t._id),
        mode: 'lines',
        showlegend: true,
        yaxis: 'y3',
        line: {
          color: 'rgb(142, 190, 237)',
          width: 1,
        },
      })
    }

    if (yAxisSelected.includes('cumOilProd')) {
      activeAxis.y2 = true
      plotData.push({
        name: 'Cumulated Oil Production (Data)​',
        y: caseObj.map(t => t.cumOilProdVol),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        showlegend: true,
        yaxis: 'y2',
        marker: { symbol: 'circle' },
        line: {
          color: 'green',
          width: 1,
        },
      })

      plotData.push({
        name: 'Cumulated Oil Production​ (Model)',
        y: caseObj.map(t => t.cumOilProdVolModel),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        yaxis: 'y2',
        marker: { symbol: 'diamond' },
        showlegend: true,
        line: {
          color: 'green',
          width: 1,
        },
      })
    }

    if (yAxisSelected.includes('cumWaterProd')) {
      activeAxis.y2 = true
      plotData.push({
        name: 'Cumulated Water Production (Data)​',
        y: caseObj.map(t => t.cumWaterProdVol),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        showlegend: true,
        marker: { symbol: 'square' },
        yaxis: 'y2',
        line: {
          color: 'rgb(9, 1, 138)',
          width: 1,
        },
      })
    }

    if (yAxisSelected.includes('cumFluidProd')) {
      activeAxis.y2 = true
      plotData.push({
        name: 'Cumulated Fluid Production (Data)​',
        y: caseObj.map(t => t.cumFluidProdVol),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        showlegend: true,
        yaxis: 'y2',
        marker: { symbol: 'triangle-up'},
        line: {
          color: 'black',
          width: 1,
        },
      })

      plotData.push({
        name: 'Cumulated Fluid Production​ (Model)',
        y: caseObj.map(t => t.cumFluidProdVolModel),
        x: caseObj.map(t => t._id),
        mode: 'lines+markers',
        yaxis: 'y2',
        showlegend: true,
        marker: { symbol: 'triangle-down'},
        line: {
          color: 'black',
          width: 1,
        },
      })
    }

    const shapes = [{
      'type': 'line',
      'xref': 'x',
      'yref': 'paper',
      'x0': moment.unix(timeline).format(),
      'y0': 0,
      'x1': moment.unix(timeline).format(),
      'y1': 1,
      'line': {
        color: 'black',
        width: 2,
        dash: 'dot',
      },
    }]

    let rightDomain = 1.00

    if (activeAxis.y2) {
      rightDomain -= 0.1
    }

    if (activeAxis.y3) {
      rightDomain -= 0.08
    }

    const plotLayout = {
      title: undefined,
      autosize: true,
      shapes: shapes,
      margin: {
        l: 60,
        r: 70,
        b: 30,
        t: 30,
        pad: 0,
      },
      xaxis: {
        title: xAxisText,
        domain: [0, rightDomain],
      },
      yaxis: {
        title: 'Prdn/Injn Rate (BBL/D)',
        color: 'black',
        linecolor: 'black',
      },
      yaxis2: {
        title: 'Cumulated Volume (BBL)',
        side: 'right',
        overlaying: 'y',
        anchor: 'free',
        position: 0.95,
        color: 'black',
        linecolor: 'black',
      },
      yaxis3: {
        title: 'Watercut',
        side: 'right',
        overlaying: 'y',
        color: 'rgb(142, 190, 237)',
        linecolor: 'rgb(142, 190, 237)',
        anchor: 'x',
      },
      legend: {
        orientation: 'h',
        y: -0.2,//-0.02,
        x: 0,
        //xanchor: 'right',
        bgcolor: 'transparent',
      },
    }


    return (
      <MUICard
        busy={!loaded}
        title={(
          <>
            <span>Model Accuracy</span>
            <SelectedWellsChip
              caseId={caseId}
              wells={wells}
            />
          </>
      )}>
        <div className={filtersClass}>
          <div>
            <div>
              <InputLabel id="label">X Axis</InputLabel>
            </div>
            <Select
              options={xAxisOptions}
              onChange={this.handleXAxisChange}
              onInputChange={this.handleXAxisChange}
              isMulti={false}
              autoWidth
              value={xAxisOptions.find(i => i.value === xAxisSelected)}
            />
          </div>
          <div>
            <div>
              <InputLabel id="label">Y Axis</InputLabel>
            </div>
            <Select
              options={yAxisOptions}
              onChange={this.handleYAxisChange}
              onInputChange={this.handleYAxisChange}
              isMulti={true}
              hideSelectedOptions={false}
              autoWidth
              placeholder="Search..."
              defaultValue={defaultYValues}
              //values={yAxisSelected.map(mod => yAxisOptions.find(i => i.value === mod))}
              components={{ ValueContainer }}
              value={yAxisSelected.map(mod => yAxisOptions.find(i => i.value === mod))}
            />
          </div>
        </div>
        <Plot
          data={plotData}
          layout={plotLayout}
          config={{ displaylogo: false, showSendToCloud: true}}
          useResizeHandler
        />
      </MUICard>
    )
  }
}

export default withRouter(ModelAccuracy)
