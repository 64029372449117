import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'
import spinner from '../../../../../static/images/Spinner.gif';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Select from 'react-select'

import './DatasetHeader.scss'
import { purple } from '@material-ui/core/colors'
import { ManagedBlockchain } from 'aws-sdk'

const baseClass = 'dataset-header'
const projectTitleClass = `${baseClass}__project-title`
const datasetTitleClass = `${baseClass}__dataset-title`

const contentClass = `${baseClass}__content`
const leftContentClass = `${contentClass}--left`
const rightContentClass = `${contentClass}--right`

const backButtonClass = `${baseClass}__back-btn`
const backButtonIconClass = `${backButtonClass}__icon`

@autobind class DatasetHeader extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  createVersionSelectionDropdown(){
    const { dataset, onClickView, selectedVersion } = this.props
    const version = selectedVersion.version
    const id = selectedVersion.versionId
    const isCurrentVersion = version

    const customStyles = {
      control: base => ({
        ...base,
        minHeight: 30
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 4
      }),
      clearIndicator: base => ({
        ...base,
        padding: 4
      }),
      multiValue: base => ({
        ...base,
        backgroundColor: variables.colorPrimaryLighter
      }),
      singleValue: base => ({
        ...base,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 8px 0 0',
      }),
      option: base => ({
        ...base,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '3px 8px',
      }),
      valueContainer: base => ({
        ...base,
        padding: '0px 6px',
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0,
      })
    }

    const dateArray = new Date(selectedVersion.dateCreated).toString().split(' ').slice(1, 4)
    const date = selectedVersion.version === dataset.latestVersion.version ? '(Latest Version)' : 
      selectedVersion.dateCreated ?  `(${dateArray[0]} ${dateArray[1]}, ${dateArray[2]})` : null
    const label = <><div>Version {selectedVersion.version}</div><div className="align-right">{date}</div></>

    return (<Select 
      options={this.generateDropdownOptions()}
      styles={customStyles}
      onChange={this.onClickVersionHandler}
      isMulti={false}
      value={ { label, value: id } }
    />)
  }

  onClickVersionHandler(e){
    const { dataset, onClickView } = this.props

    onClickView(dataset._id, e.value)
  }

  generateDropdownOptions(){
    const { dataset } = this.props

    const options = dataset.versions.map(ver => {
      const dateArray =  new Date(ver.dateCreated).toString().split(' ').slice(1, 4)
      const date = ver.dateCreated ? `(${dateArray[0]} ${dateArray[1]}, ${dateArray[2]})` : null
      const versionName = ver.publishedName ? ver.publishedName : `Version ${ver.version}`
      const label = <><div>{versionName}</div><div className="align-right">{date}</div></>

      return { 
        label,
        value: ver.versionId
      }
    })

    const name = dataset.latestVersion.publishedName ? dataset.latestVersion.publishedName : `Version ${dataset.latestVersion.version}`
    options.push({ 
      label: <><div>{name}</div><div className="align-right">(Latest Version)</div></>,
      value: null
    })
    return options.reverse()
  }


  render() {
    const { dataset, project, selectedVersion, datasetLoading, match } = this.props
    const { application, projectId } = match.params
    const { isLoading } = this.state

    const backButtonUrl = `/${application}/projects/${projectId}/dataset`

    return (
      <div className={baseClass}>
        <div className={contentClass}>
          <div className={`${leftContentClass} align-vertical`}>
            <div className={'asdf'}>
              <div className={projectTitleClass}>{project && project.name}</div>
              <div className={datasetTitleClass}>
                {(dataset
                  && selectedVersion.publishedName)
                  && (<>
                    <div className={'left'}>{dataset.name} - {selectedVersion.publishedName} - </div>
                    <div className={'right'}>{this.createVersionSelectionDropdown()}</div>
                    <img className={'spinner'} src={spinner} hidden={!datasetLoading}/>
                  </>)
                }
                {(dataset
                  && !selectedVersion.publishedName)
                  && (<>
                  <div className={'left'}>{dataset.name} - </div>
                  <div className={'right'}>{this.createVersionSelectionDropdown()}</div>
                  <img className={'spinner'} src={spinner} hidden={!datasetLoading}/>
                </>)
                }
              </div>
            </div>
          </div>
          <div className={`${rightContentClass} align-vertical`}>
            <NavLink className={backButtonClass} to={backButtonUrl}>
              <ChevronLeftIcon className={backButtonIconClass} />
              Back to Datasets
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(DatasetHeader)
