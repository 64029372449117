import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { NavLink } from 'react-router-dom'

import API from '../../../lib/api-store'
import LicenseCard from './components/LicenseCard'
import './LicenseSelection.scss'


const baseClass = 'license-module'
const containerClass = `${baseClass}__container`
const headerClass = `${containerClass}__header`
const headerLeft = `${headerClass}__left`
const headerRight = `${headerClass}__right`
const subHeaderClass = `${containerClass}__subHeader`
const licensesClass = `${containerClass}__licenses`

const backButtonClass = `back-btn`
const backButtonIconClass = `${backButtonClass}__icon`


@autobind class LicenseSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      licenses: [],
      prevPath: '/'
    }
  }


  componentDidMount() {
    this.fetchLicenses()
    if(this.props.location.state) window.prevPath = this.props.location.state.prevPath
    this.setState({ prevPath: window.prevPath })
  }

  fetchLicenses() {
    API.getLicenses()
      .then((licenses) => {    
        this.setState({
          licenses,
        })
      })
  }

  handleLicenseClick(licenseId) {
    this.props.history.push(`/licenses/${licenseId}`)
  }

  makeLicenses() {
    const { licenses, accountUserOptions } = this.state

    return licenses.map(license => {
      return (
        <LicenseCard 
          license={license}
          handleLicenseClick={this.handleLicenseClick}
        />
      )
    })
  }

  render() {
    
    const { prevPath } = this.state

    return (
      <div className={baseClass}>
        <div className={containerClass}>
          <div className={headerClass}>
            <div className={headerLeft}>Licenses</div>
            <div classname={headerRight}>
            <NavLink className={backButtonClass} to={prevPath}>
              <ChevronLeftIcon className={backButtonIconClass} />
              Back
            </NavLink>
            </div>
          </div>
          <div className={subHeaderClass}>
            Select a license to view or set up users
          </div>
          <div className={licensesClass}>
            { this.makeLicenses() }
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(LicenseSelection)
