import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import _ from 'underscore'


import Select from 'react-select'
import Creatable from 'react-select/creatable'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const baseClass = 'geological-models'

const topBarClass = `${baseClass}__top-bar`
const topButtonsClass = `${baseClass}__top-buttons`

const uploaderContainerClass = `${baseClass}__uploader-container`
const singleUploadClass = `${uploaderContainerClass}--single`
const multipleUploadClass = `${uploaderContainerClass}--multiple`

const uploadParametersClass = `${uploaderContainerClass}__upload-parameters`
const uploadParameterHeader = `${uploadParametersClass}__header`
const uploadParameterSelection = `${uploadParametersClass}__selection`
const optionClass = `${uploadParametersClass}__option`
const uploadParameterSingle = `${uploadParametersClass}__single`

const indexClass = `${uploaderContainerClass}__step`

const infoClass = `${uploaderContainerClass}__info`
const categoryTextClass = `${infoClass}__category-text`
const optionsClass = `${infoClass}__options`
const fileExtensionClass = `${infoClass}__file--extension`
const requiredTextClass = `${categoryTextClass}__required`
const helperTextClass = `${infoClass}__helper-text`

const browseButtonClass = `${infoClass}__browse-button`
const browseButtonLabelClass = `${infoClass}__browse-button-label`
const browseButtonTextClass = `${browseButtonLabelClass}__text`
const browseButtonInputClass = `${browseButtonLabelClass}__input`

//Modify Modal css in ContinueModal.scss
const modalUploadClass = `cancel-modal__content__children__file-upload`
const modalHeaderClass = `${modalUploadClass}__header`
const modalInputField = `${modalUploadClass}__input`
const modalInputItem = `${modalInputField}__item`

const UploadParameters = (props) => {

  const {
    uploadParameter,
    changeSelectedValue,
    parentState,
  } = props

  let optionSelection

  let tooltip = !uploadParameter.helpText ? null : (
    <>
    <a data-tip={uploadParameter.helpText} data-for={uploadParameter.name}>
          <FontAwesomeIcon icon="question-circle" className="help-icon" />
        </a>
    <ReactTooltip id={uploadParameter.name} className="customize-settings-tooltip" effect="solid" />
    </>
  )

  switch(uploadParameter.type){
      case 'dropdown':
        optionSelection = (<Select
                            options={uploadParameter.options}
                            onChange={e => changeSelectedValue(uploadParameter.name, e.value)}
                            isMulti={false}
                            value={ { label: parentState[uploadParameter.name], value: parentState[uploadParameter.name] }}
                            key={uploadParameter.name}
                          />)
        break
      case 'radio':
          optionSelection = (<>
                  {
                    uploadParameter.options.map((option) => {
                        return (<>
                            <Radio 
                                checked={parentState[uploadParameter.name] === option.value}
                                onChange={e => changeSelectedValue(uploadParameter.name, e.target.value)}
                                value={option.value}
                                name="radio-button"
                                inputProps={{ 'aria-label': option.label }}
                                color="primary"
                                size="small"
                            />
                            <span>{option.label}</span>
                        </>)
                    })
                  }
              </>)
          break
      case 'text':
          //finish building me if needed
          optionSelection = (<>
              <TextField
                  id={uploadParameter.name}
                  variant="outlined"
                  onChange={e => changeSelectedValue(uploadParameter, e)}
              />
          </>)
          break
      case 'dropdown-custom':
          optionSelection = (<Creatable
                    options={uploadParameter.options}
                    onChange={e => changeCreatableSelection(uploadParameter.name, e)}
                    isDisabled={ uploadParameter.condition ? eval(uploadParameter.condition) : false }
                    isMulti={true}
                    value={eval(uploadParameter.condition) ? null : parentState[uploadParameter]}
                    key={uploadParameter.name}
          />)
          break
      default:
          console.log('ERROR IN generateParameterSelectorsByType: FileUploader 332')
          console.log('Parameter type fell through switch statment.  Check type name:', uploadParameter.type)
  }
  if(parentState){
    return (
        <div className={optionClass}>
            <p>{uploadParameter.label}<span className={requiredTextClass}>*  </span>{tooltip}</p>
            {optionSelection}
        </div>
    )
  } else {
    return null
  }
}

export default UploadParameters