import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import PerforatedNetPayA from '../../../../../../../../../../static/images/userManual/settings/perforatedNetPayA.png'
import PerforatedNetPayB from '../../../../../../../../../../static/images/userManual/settings/perforatedNetPayB.png'

@autobind class PerforatedNetPay extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Perforated Net Pay Settings (PNP)
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          PNP Flagging Method<br/>
          <span className='blue'>Options:</span> Limited, Extended Above, Extended Below<br/>
          <span className='blue'>Comments:</span> Options for limiting or extending the net pay that is assumed to be contacted or drained by existing perforations. In reservoirs with a strong aquifer, 'Extended Below' may be appropriate. In reservoirs with gas expansion, 'Extended Above' may be appropriate.<br/>
        </div>
        <div className='image-container'>
          <img className="tooBig" src={PerforatedNetPayA} alt="" />
        </div>
        <div className="content-row"> 
          Include Existing Pefs as PNP<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> If on, this adds perforated intervals to the PNP log. This is often recommended in cases with uncertainty in the net pay definition.<br/>
        </div>
        <div className="content-row"> 
          Min Baffle Thickness<br/>
          <span className='blue'>Options:</span> Meters<br/>
          <span className='blue'>Comments:</span> Minimum baffle thickness to define extent of PNP (also creates 'baffles' based on seal log or non-pay otherwise).<br/>
        </div>
        <div className='image-container'>
          <img className="tooBig" src={PerforatedNetPayB} alt="" />
        </div>
        <div className="content-row"> 
          Generate Net Seal Logs<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> If you prefer to generate a net seal log, you can set the definition of the net seal log here. After switching this to 'On', set the net seal definition by selecting the logs, cutoffs, and direction for the cutoffs in the rows that appear below.<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(PerforatedNetPay)
