import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Collapse from '@material-ui/core/Collapse'
import ListSubheader from '@material-ui/core/ListSubheader'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import BookIcon from '@material-ui/icons/Book'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy'
import SettingsIcon from '@material-ui/icons/Settings';
import CancelIcon from '@material-ui/icons/Cancel'
import CloseIcon from '@material-ui/icons/Close'

import PinnedOpportunities from './PinnedOpportunities'

const styles = {
  links: {
    fontSize: 12,
  },
  linkActive: {
    color: '#363636',
    background: '#E6E6E6',
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    '&::before' : {
      position: 'absolute',
      display: 'block',
      content: '""',
      height: '100%',
      width: 10,
      borderColor: '#1665d8',
      left: 0,
      borderWidth: '0px 0 0px 2px',
      borderStyle: 'solid',
      borderRadius: 5,
    }
  }
}

const ListItemLink = (props) => {
  return <ListItemText button component="a" {...props} />
}

@autobind class MainNav extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      open: true,
    }
  }

  handleClick() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { classes, match, caseObj } = this.props
    const { projectId, caseId, application } = match.params
    const { open } = this.state

    let showDownload = false
    let oldDownload 

    if (caseObj) {
      showDownload = !(caseObj.noDownload)  

      if (showDownload) {
        oldDownload = (caseObj.oldDownload) ? true : false
      }
    }

    return (
      <List component="nav">
        <ListItem button key="Dashboard" component={NavLink} exact activeClassName={classes.linkActive} to={`/${application}/projects/${projectId}/cases/${caseId}/visualization`}>  
          <ListItemIcon><DashboardOutlinedIcon style={{ fontSize: 25 }} /></ListItemIcon>
          <ListItemText className={classes.link} primary="Executive Summary" />
        </ListItem>
        <ListItem button key="OpportunityCatalog" component={NavLink} exact activeClassName={classes.linkActive} to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/opportunities`}>
          <ListItemIcon><BookIcon style={{ fontSize: 25 }}/></ListItemIcon>
          <ListItemText className={classes.link} primary="Opportunity Catalog" />
        </ListItem>
        <ListItem button key="WellCatalog" component={NavLink} exact activeClassName={classes.linkActive} to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/wells`}>
          <ListItemIcon><BookIcon style={{ fontSize: 25 }} /></ListItemIcon>
          <ListItemText className={classes.link} primary="Wells Catalog" />
        </ListItem>
        <ListItem button key="PinnedOpportunities" onClick={this.handleClick}>
          <ListItemIcon><i className="fa fa-thumb-tack" style={{ fontSize: 20, marginLeft: 5 }} /></ListItemIcon>
          <ListItemText className={classes.link} primary="Pinned Opportunities" />
          { open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" className="nested" disablePadding>
            <PinnedOpportunities projectId={projectId} caseId={caseId} />
          </List>
        </Collapse>
        <ListItem button key="Settings" component={NavLink} exact activeClassName={classes.linkActive} to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/settings`}>
          <ListItemIcon><SettingsIcon style={{ fontSize: 25 }} /></ListItemIcon>
          <ListItemText className={classes.link} primary="Settings" />
        </ListItem>
        {showDownload && (
          <ListItem button key="Downloads" component={NavLink} exact activeClassName={classes.linkActive} to={`/${application}/projects/${projectId}/cases/${caseId}/visualization/download`}>
            <ListItemIcon><GetAppIcon style={{ fontSize: 25 }} /></ListItemIcon>
            <ListItemText className={classes.link} primary="Downloads" />
          </ListItem>
        )}
      </List>
    )
  }
}

export default withRouter(withStyles(styles)(MainNav))
