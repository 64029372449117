import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Select from 'react-select'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import './ProjectCreator.scss'

const baseClass = 'dataset-editor'

const headerClass = `${baseClass}__header`

const contentClass = `${baseClass}__content`

const topInputBarClass = `${contentClass}__top-bar`
const datasetNameInputClass = `${topInputBarClass}__name-input`

const bottomBarClass = `${contentClass}__bottom-bar`


const multiTextRows = 4

@autobind class ProjectCreator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectName: '',
      projectBasin: '',
      projectRegion: '',
      projectCountry: '',
      projectObjective: '',
      selectedLicense: null
    }
  }

  handleNameChange(e) {
    const { value } = e.target
    this.setState({
      projectName: value,
    })
  }

  handleBasinChange(e) {
    const { value } = e.target
    this.setState({
      projectBasin: value,
    })
  }

  handleRegionChange(e) {
    const { value } = e.target
    this.setState({
      projectRegion: value,
    })
  }

  handleCountryChange(e) {
    const { value } = e.target
    this.setState({
      projectCountry: value,
    })
  }

  handleDescriptionChange(e) {
    const { value } = e.target
    this.setState({
      projectObjective: value,
    })
  }

  handleSelectLicense(val) {
    this.setState({
      selectedLicense: val
    })
  }

  onActionClick() {
    const { projectName, projectBasin, projectRegion, projectCountry, projectObjective, selectedLicense } = this.state
    const { handleNewProject } = this.props

    handleNewProject(projectName, projectBasin, projectRegion, projectCountry, projectObjective, selectedLicense.value)
  }

  render() {
    const { 
      projectName,
      projectBasin,
      projectRegion,
      projectCountry,
      projectObjective,
      selectedLicense
    } = this.state

    const reqStyle = {
      color: 'red',
      display: 'inline-block'
    }

    const { licenseOptions, err } = this.props

    const makeRequired = textInput => <>{textInput}<span style={reqStyle}>*</span></>

    return (
      <div className={baseClass}>

        <div className={headerClass}>
          <span>Create Workspace (*REQUIRED)</span>
        </div>
        <div className={contentClass}>
          <div className={topInputBarClass}>
            <TextField
              id="dataset-name-input"
              label={makeRequired('Workspace Name')}
              type="text"
              margin="normal"
              variant="outlined"
              className={datasetNameInputClass}
              value={projectName}
              onChange={this.handleNameChange}
              inputProps={{
                maxLength: 50,
              }}
            />
            <TextField
              id="dataset-basin-input"
              label="Workspace Basin"
              type="text"
              margin="normal"
              variant="outlined"
              className={datasetNameInputClass}
              value={projectBasin}
              onChange={this.handleBasinChange}
              inputProps={{
                maxLength: 100,
              }}
            />
          </div>
          <div className={topInputBarClass}>
            <TextField
              id="dataset-region-input"
              label="Workspace Region"
              type="text"
              margin="normal"
              variant="outlined"
              className={datasetNameInputClass}
              value={projectRegion}
              onChange={this.handleRegionChange}
              inputProps={{
                maxLength: 100,
              }}
            />
            <TextField
              id="dataset-country-input"
              label="Workspace Country"
              type="text"
              margin="normal"
              variant="outlined"
              className={datasetNameInputClass}
              value={projectCountry}
              onChange={this.handleCountryChange}
              inputProps={{
                maxLength: 100,
              }}
            />
          </div>
          <div>
            License<span style={{color: 'red'}}>*</span>
            <Select
              options={licenseOptions}
              onChange={this.handleSelectLicense}
              isMulti={false}
              value={selectedLicense}
            />
          </div>
          <TextField
            fullWidth
            id="dataset-description-input"
            label={makeRequired("Workspace Description")}
            type="text"
            margin="normal"
            variant="outlined"
            multiline
            rows={multiTextRows}
            value={projectObjective}
            onChange={this.handleDescriptionChange}
            inputProps={{
              maxLength: 5000,
            }}
          />
          {err && (
            <div className='testestest' style={{color: 'red'}}>
              {err}
            </div>
          )}
          <div className={bottomBarClass}>
            <div>
              <Button
                disabled={!projectName || !projectObjective || !selectedLicense}
                tabIndex="0"
                onClick={this.onActionClick}
                onKeyPress={null}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
              >
                <span>Create Workspace</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectCreator
