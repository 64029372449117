import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'

import Intro from './components/Intro'
import Actions from './components/Actions'
import Workflow from './components/Workflow'

@autobind class Cases extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/:application/manual/cases/intro" component={Intro} />
          <Route path="/:application/manual/cases/actions" component={Actions} />
          <Route path="/:application/manual/cases/workflow" component={Workflow} />
        </Switch>
      </React.Fragment>
    )
  }
}




export default Cases
