import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class DeclineCurveAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Decline Curve Analysis
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          This step performs DCA for all wells available in this dataset. DCA is one of the most widely used forms of data analysis that evaluates well behavior and forecasts future well and field production and reserves. Usually, this practice is done manually, making analysis of assets with a large number of wells cumbersome and time-consuming. Moreover, results are subject to alternate interpretations, mostly as a function of experience and objectives of the evaluator. In this step of the module, despite the common practice of the industry (i.e., manual DCA), we deploy cutting-edge technologies that QRI developed to intelligently apply DCA methods to any number of wells in an unbiased, systematic, intelligent, and automated fashion. The tool reads production data and multidisciplinary well information (e.g., drilling and completion data, geological data, artificial lift information, etc.). Then it performs cluster analysis using unsupervised machine learning and pattern recognition to partition the dataset into internally homogeneous and externally distinct groups. This cluster analysis is later used for type-curve generation for wells with short production history. For wells with long enough history, the tool first detects production events through a fully automated event detection algorithm without any human interference. Since production events are highly correlated with real-time events, it also cross-validates with the operating conditions.
        </div>
        <div className="content-row"> 
          Next, the last event is selected, and a decline curve is fitted using advanced nonlinear optimization and minimization algorithms. This leads to a reliable and unbiased prediction. For each cluster, a type curve is computed that truly captures the underlying production behavior of the wells that belong to the same group or cluster, and then is applied to the wells with short production history within that cluster. To capture the probabilistic nature of such analysis and quantify the inherent uncertainty, we extended the method to a probabilistic DCA using quantile regression. We successfully deployed this technology/tool to a giant Middle Eastern reservoir, with more than 2,000 wells and 70 years of production. Our predicted aggregated field decline rate is in good agreement with the client’s reservoir simulation results run under the “do-nothing” scenario. While performing traditional DCA for such a field would require several weeks and significant resources, our automated solution integrates all real-life events/information and provides a comprehensive analysis in field, cluster and well level. In addition, our results are “unbiased,” as it is not subject to human errors or evaluator’s interpretations. Our robust and intelligent DCA allows for exhaustive evaluation of production trends and opportunities in fields across time, production zones, well types, or any combination of the above. The results demonstrate the effectiveness of the automated DCA to rapidly execute decline curve analysis for a large number of wells. The accuracy is improved significantly through automatic event detection, cross-validation of events, curve fitting optimization, quantile regression, and cluster-based type-curving.
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Decline Phase<br/>
          <span className='blue'>Options:</span> Oil, Gas<br/>
          <span className='blue'>Comments:</span> Choose which phase to base the decline on.
        </div>
        <div className="content-row"> 
          Decline Type<br/>
          <span className='blue'>Options:</span> Exponential, Hyperbolic<br/>
          <span className='blue'>Comments:</span> Type of decline method.
        </div>
        <div className="content-row"> 
          Min OPR<br/>
          <span className='blue'>Options:</span> Min Value must be greater than 0<br/>
          <span className='blue'>Description:</span> Minimum oil production rate (OPR) or Production Limit, after which the prediction stops.
        </div>
        <div className="content-row"> 
          Min GPR<br/>
          <span className='blue'>Options:</span> Min Value must be greater than 0<br/>
          <span className='blue'>Description:</span> Minimum gas production rate (GPR) or Production Limit, after which the prediction stops.
        </div>
        <div className="content-row"> 
          Number of Decline Month<br/>s
          <span className='blue'>Options:</span> Min Value must be greater than 0<br/>
          <span className='blue'>Description:</span> Maximum number of months for prediction.
        </div>
        <div className="content-row"> 
          Decline Start Date<br/>
          <span className='blue'>Options:</span> Enter a past year<br/>
          <span className='blue'>Description:</span> Date to start decline, disregard any previous data.
        </div>
        <div className="content-row"> 
          Min D<br/>
          <span className='blue'>Options:</span> Min Value must be between 0 and 1<br/>
          <span className='blue'>Description:</span> Minimum effective annual decline. If effective annual decline falls below this value, it imposes this value to the decline curve
        </div>
        <div className="content-row"> 
          Type Curve Di<br/>
          <span className='blue'>Options:</span> Min Value must be between 0 and 1<br/>
          <span className='blue'>Description:</span> Field average decline rate to be used as the decline rate for type curves, if the automated type curve results in zero decline rate.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(DeclineCurveAnalysis)
