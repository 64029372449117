import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import Grid from '@material-ui/core/Grid'
import { ClipLoader } from 'react-spinners'
import Select from 'react-select'
import Typography from '@material-ui/core/Typography'

import Card from '../../../common/Card'
import AntSwitch from '../../../../../common/Switch/Switch'
import API from '../../../../../../lib/api-store'
import NeighborBarPlot from '../../../components/NeighborBarPlot'
import ScatterChart from '../../../components/ScatterChart'
import OpportunityNeighborTable from '../../../components/OpportunityNeighborTable'
import OpportunityCurrentNeighborTable from '../../../components/OpportunityCurrentNeighborTable'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

const xAxisOptions = [
  { label: 'Perforation Length (m)', value: 'perfLength' },
  { label: 'Hydrocarbon Thickness (m)', value: 'pnpHcpt' },
  { label: 'PNP (m)', value: 'pnp' },
  { label: 'Perm', value: 'pnpPerm' },
  { label: 'Initial Pressure', value: 'pnpInitialPressure' },
]

const Neighbors = Object.freeze({
  INITIAL:   { name: "Initial Neighbors", value: 'INITIAL' },
  CURRENT:  { name: "Current Neigbors", value: 'CURRENT' },
})

@autobind class ProductionForecast extends PureComponent {
  constructor(props) {
    super(props)
    this.neigborModeEnabled = false
    this.state = {
      shouldRenderNeighborHistory: false,
      shouldRenderNeighborIP: false,
      shouldRenderNeighborWells: false,
      shouldRenderNeighborTable: false,
      xAxis: 'perfLength',
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    const { wellId, selectedZone, caseId } = this.props
 
    if (wellId !== prevProps.wellId
      || selectedZone !== prevProps.selectedZone
      || caseId !== prevProps.caseId) {
      this.loadData()
    }
  }

  loadData() {
    const { wellId, selectedZone, caseId } = this.props
    const { singleOpportunity } = this.props

    this.neigborModeEnabled = !!singleOpportunity.analogNeighborStats
    
    this.currentNeighborData = singleOpportunity.currentNeighborStats
    ? singleOpportunity.currentNeighbors
    : []

    this.setState({ 
      neighborsToDisplay : Neighbors.INITIAL,
    })

    setTimeout(() => {
      this.setState({
        shouldRenderNeighborHistory: true,
      })
    }, 100)
  }

  toggleNeighbors() {
    this.state.neighborsToDisplay === Neighbors.CURRENT
      ? this.setState({ neighborsToDisplay : Neighbors.INITIAL })
      : this.setState({ neighborsToDisplay : Neighbors.CURRENT, neighborData: this.currentNeighborData })
  }

  renderNeighborTable() {
    const { neighborsToDisplay, neighborData, shouldRenderNeighborTable } = this.state
    const { singleOpportunity } = this.props

    if (neighborData && shouldRenderNeighborTable) {
      if (neighborsToDisplay === Neighbors.INITIAL) {
        return <OpportunityNeighborTable data={neighborData} />
      }
      return <OpportunityCurrentNeighborTable data={neighborData} />
    }

    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }

  renderNeighborHistory() {
    const { wellData, selectedZone } = this.props

    if (wellData && this.state.shouldRenderNeighborHistory) {
      const zoneTypeCurveData = []
      if (Object.keys(wellData).length > 0) {
        Object.keys(wellData).forEach((key) => {
          const wellZoneData = wellData[key].zoneData[selectedZone]
          if (wellZoneData && wellZoneData.ip_oil && wellZoneData.ip_oil.ip_oil) {
            zoneTypeCurveData.push({
              date: wellZoneData.ip_oil.date.date,
              ip: wellZoneData.ip_oil.ip_oil,
              wellName: key,
            })
          }
        })
      }

      return <ScatterChart data={zoneTypeCurveData} textKey="wellName" xKey="date" yKey="ip" xTitle="Date" yTitle="IP" yUnits="bbl/d" />
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }

  renderNeighborIP() {
    const { singleOpportunity } = this.props
    const { neighborsToDisplay, neighborData } = this.state
    if (neighborData && this.state.shouldRenderNeighborIP) {

      const plotData = neighborsToDisplay ===  Neighbors.INITIAL
      ? neighborData.map(i => ({ id: i.id, val: i.targetIP }))
      : neighborData.map(i => ({ id: i.wellId, val: i.oil }))

      return (
        <NeighborBarPlot
          data={plotData}
          refLine={singleOpportunity.predictedIp}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderNeighborWells() {
    const { singleOpportunity } = this.props
    const { neighborsToDisplay, xAxis, neighborData, shouldRenderNeighborWells } = this.state

    if (neighborData && shouldRenderNeighborWells) {
      const xKey = xAxis === 'hcpt' && neighborsToDisplay === Neighbors.CURRENT
        ? 'pnpHcpt'
        : xAxis

      const yKey = neighborsToDisplay === Neighbors.INITIAL
        ? 'targetIP'
        : 'oil'

      const title = neighborsToDisplay === Neighbors.INITIAL
        ? 'IP'
        : 'Oil'

      return (
        <React.Fragment>
          <ScatterChart data={neighborData} xKey={xKey} yKey={yKey} xTitle='' yTitle={title} yUnits="bbl/d" yMargin={75}/>
          <div style={{'position': 'relative', top: '-50px', width: '200px', margin: 'auto'}}>
            <Select
              options={xAxisOptions}
              onChange={val => this.setState({
                xAxis: val.value,
              })}
              isMulti={false}
              value={xAxisOptions.find(i => i.value === xAxis)}
            />
          </div>
        </React.Fragment>
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }

  renderNeighborExplanation() {
    const { caseObj } = this.props
    const { neighborsToDisplay } = this.state


    let spatialType = 'radius'
    let spatialScale = 0
    let temporalScale = 0
    let matchWellTypes = false
    const isInitial = neighborsToDisplay === Neighbors.INITIAL
    let useZoneAllocatedRates = false

    if (caseObj) {
      spatialType = caseObj.settings.productionForecast.neighborhoodSettings.type
      spatialScale = caseObj.settings.productionForecast.neighborhoodSettings.spatialScale
      temporalScale = caseObj.settings.productionForecast.neighborhoodSettings.temporalScale
      matchWellTypes = caseObj.settings.productionForecast.neighborhoodSettings.matchWellType
      useZoneAllocatedRates = caseObj.settings.productionForecast.neighborhoodSettings.useZoneAllocatedRates
    }
    

    return (
      <div className='neighbor-explanation-outer'>
        <div className='neighbor-explanation-title'>
          Analog wells are calculated based on the following criteria:
        </div>
        <div className='neighbor-explanation-row'>
          <span>&#8226;</span> {isInitial ? 'Produced' : 'Currently produce' } from the same target zone
        </div>
        { isInitial && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Started producing within the last {temporalScale} years
          </div>
        )}
        { spatialType === 'radius' && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Within the the closest {spatialScale} wells by distance 
          </div>
        )}
        { spatialType !== 'radius' && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Spatially within {spatialScale} points 
          </div>
        )}
        { matchWellTypes && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Share the same well type with target
          </div>
        )}
        { !useZoneAllocatedRates && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Do not require production allocation (wells produce from target zone only)
          </div>
        )}

      </div>
    )
  }


  render() {
    const { map } = this.props

    return (
      <>
        <div className="opportunity-production">
          <div className="top">
            <div className="left">
              { map }
            </div>
            <div className="right">
              <Card title="Zone IP History">
                { this.renderNeighborHistory() }
              </Card>
            </div>
          </div>
        </div>
      </>
    )
  }
}


export default ProductionForecast
