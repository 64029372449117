import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'

import IconButton from '@material-ui/core/IconButton'
import TuneIcon from '@material-ui/icons/Tune'
import GetAppIcon from '@material-ui/icons/GetApp'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import CircularProgress from '@material-ui/core/CircularProgress'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import MUICard from '../../../common/MUICard/MUICard'

import ModelAccuracy from './ModelAccuracy'
import GlobalFilters from '../Shared/GlobalFilters'

import * as WaterfloodService from '../../../../services/waterfloodService'

import Viewer4D from './Viewer4D'

import { toggleSettings } from '../../../../redux/actions/waterfloodCase'


import './Assessment.scss'

const caseClass = 'waterflood_case'
const headerClass = `${caseClass}-header`
const cardsClass = `${caseClass}-cards`
const loaderClass = `${caseClass}-loader`
const filtersClass = `${caseClass}--filters-wrapper`


@autobind class Assessment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wells: []
    }
  }

  componentDidMount() {
    const { match } = this.props
    const { caseId } = match.params

    WaterfloodService.getCaseWells(caseId).then((wells) => {
      this.setState({
        wells,
      })
    })
    /*
    const { match } = this.props
    const { caseId } = match.params

    getCaseReservoir(caseId).then(async (reservoir) => {
      this.setState({
        reservoir,
      })
    })
    */
  }

 render() {
   const { timeline, rxToggleSettings, selectedWells, showSettings, viewerBusy, match } = this.props
   const { wells } = this.state
   const { caseId } = match.params

    return (
      <div className={caseClass}>
        <div className={headerClass}>
          <MUICard className={filtersClass}>
            <GlobalFilters caseId={caseId} />
          </MUICard>
        </div>
        <Grid container spacing={2}>
          <Grid container item xs={6} spacing={0}>
            <Viewer4D toggleSettings={rxToggleSettings} showSettings={showSettings} caseId={caseId} />
          </Grid>
          <Grid container item xs={6} spacing={0}>
            <ModelAccuracy
              wells={wells}
              caseId={caseId}
              timeline={timeline}
              selectedWells={selectedWells.toJS()}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}


const mapDispatchToProps = dispatch => ({
  rxToggleSettings: () => dispatch(toggleSettings()),
})

const mapStateToProps = state => ({
  showSettings: state.getIn(['waterfloodCase', 'viewer', 'showSettings']),
  viewerBusy: state.getIn(['waterfloodCase', 'viewer', 'busy']),
  timeline: state.getIn(['waterfloodCase', 'timeline']),
  selectedWells: state.getIn(['waterfloodCase', 'selectedWells']),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assessment))
