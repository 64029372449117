import * as THREE from "three"

class Surface {
  constructor(cfg) {
    cfg = cfg || {}

    this.name = cfg.name || ''
    this.faces = cfg.faces || []
    this.vertices = cfg.vertices || []
    this.reservoir = cfg.reservoir || null
    this.image = cfg.image || null
    this.displayMesh = cfg.displayMesh !== undefined ? cfg.displayMesh : true
    this.transparency = cfg.transparency !== undefined ? cfg.transparency : 100

    this.meshMaterial = null
    this.surfaceMaterial = null

    this.bounds = this.getBounds()
    this.geometry = this.buildGeometry()
    this.build()
  }

  getBounds() {
    const vertices = this.reservoir.xyBoundary
    return vertices.reduce((accum, curr) => {
      return {
        x: {
          min: Math.min(accum.x.min, curr.x),
          max: Math.max(accum.x.max, curr.x),
        },
        y: {
          min: Math.min(accum.y.min, curr.y),
          max: Math.max(accum.y.max, curr.y),
        },
      }
    }, { x: { min: Infinity, max: -Infinity }, y: { min: Infinity, max: -Infinity } })
  }

  build() {
    this.surfaceGroup = new THREE.Group()
    this.surfaceGroup.name = this.name
    this.meshMaterial = new THREE.MeshPhongMaterial({ color: 0xAAAAAA, wireframe: true })
    this.surfaceMaterial = new THREE.MeshBasicMaterial({
      map: this.image,
      side: THREE.DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: 1.0,
      polygonOffsetUnits: 1.0,
      transparent: true,
    })

    this.surfaceMesh = new THREE.Mesh(this.geometry, this.surfaceMaterial)
    this.wireframeMesh = new THREE.Mesh(this.geometry, this.meshMaterial)

    this.meshMaterial.visible = !!this.displayMesh
    this.surfaceMesh.material.opacity = this.transparency / 100
    
    this.surfaceGroup.add(this.surfaceMesh)
    this.surfaceGroup.add(this.wireframeMesh)
  }

  buildGeometry() {
    const geometry = new THREE.Geometry()

    const bounds = this.bounds
    const v = this.vertices

    this.faces.forEach((face) => {
      const v1 = v[ face[0] - 1 ]
      const v2 = v[ face[1] - 1 ]
      const v3 = v[ face[2] - 1 ]
      const v4 = v[ face[3] - 1 ]
  
      const ndx = geometry.vertices.length

      const boundingGeometry = new THREE.PlaneGeometry(bounds.x.max - bounds.x.min, bounds.y.max - bounds.y.min)
      boundingGeometry.computeBoundingBox()
      const bbox = boundingGeometry.boundingBox
  
      geometry.vertices.push(
        new THREE.Vector3(v1[0] - bounds.x.min + bbox.min.x, v1[1] + bbox.min.y - bounds.y.min, -v1[2]),
        new THREE.Vector3(v2[0] - bounds.x.min + bbox.min.x, v2[1] + bbox.min.y - bounds.y.min, -v2[2]),
        new THREE.Vector3(v3[0] - bounds.x.min + bbox.min.x, v3[1] + bbox.min.y - bounds.y.min, -v3[2]),
        new THREE.Vector3(v4[0] - bounds.x.min + bbox.min.x, v4[1] + bbox.min.y - bounds.y.min, -v4[2]),
      )

      geometry.faces.push(
        new THREE.Face3(ndx, ndx + 1, ndx + 2),
        new THREE.Face3(ndx, ndx + 2, ndx + 3),
      )

      const u0 = (v1[0] - bounds.x.min + bbox.min.x) / (bounds.x.max - bounds.x.min) + 0.5
      const v0 = (v1[1] + bbox.min.y - bounds.y.min) / (bounds.y.max - bounds.y.min) + 0.5
      const u1 = (v2[0] - bounds.x.min + bbox.min.x) / (bounds.x.max - bounds.x.min) + 0.5
      const _v1 = (v2[1] + bbox.min.y - bounds.y.min) / (bounds.y.max - bounds.y.min) + 0.5
      const u2 = (v3[0] - bounds.x.min + bbox.min.x) / (bounds.x.max - bounds.x.min) + 0.5
      const _v2 = (v3[1] + bbox.min.y - bounds.y.min) / (bounds.y.max - bounds.y.min) + 0.5
      const u3 = (v4[0] - bounds.x.min + bbox.min.x) / (bounds.x.max - bounds.x.min) + 0.5
      const _v3 = (v4[1] + bbox.min.y - bounds.y.min) / (bounds.y.max - bounds.y.min) + 0.5

      geometry.faceVertexUvs[0].push(
        [new THREE.Vector2(u0, v0), new THREE.Vector2(u1, _v1), new THREE.Vector2(u2, _v2) ],
        [new THREE.Vector2(u0, v0), new THREE.Vector2(u2, _v2), new THREE.Vector2(u3, _v3) ],
      )
    })

    geometry.computeFaceNormals()
    geometry.uvsNeedUpdate = true
    return geometry
  }

  displayWireframe(display) {
    this.displayMesh = display
    if (this.meshMaterial) {
      this.meshMaterial.visible = this.displayMesh
    }
  }

  setTransparency(transparency) {
    this.surfaceMesh.material.transparent = true
    this.surfaceMesh.material.opacity = transparency / 100
  }

  updateProperty(image) {
    this.surfaceMesh.material.map = image
  }
}

export default Surface