import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import ReactTable from 'react-table'
import Button from '@material-ui/core/Button'
import API from '../../../../../lib/api-store'
import DatePicker from 'react-datepicker'

// import './TimeStepSettings.scss'
import "react-datepicker/dist/react-datepicker.css";

const defaultInterval = 12

const columns = [{
  Header: 'Start Date',
  accessor: 'startDate',
  sortable: false,
  type: 'date',
  disabled: true,
}, {
  Header: 'End Date',
  accessor: 'endDate',
  sortable: false,
  type: 'date',
}, {
  Header: 'Interval',
  accessor: 'interval',
  sortable: false,
  type: 'numerical',
}]

const itemMap = {
  startDate: 'start_date_list',
  endDate: 'end_date_list',
  interval: 'interval_list'
}

@autobind class TimeStepSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: props.caseObj.settings.Config.config_struct.start_date_list.length > 1 ? 'manual' : 'default'
    }
  }



  handleOptionChange(e) {
    const { caseObj, editCaseObj, stepNo } = this.props
    const { caseId } = caseObj

    if (e.target.value === 'default') {
      const newCaseObj = {...caseObj}
      newCaseObj.settings.Config.config_struct.start_date_list = [newCaseObj.settings.Config.config_struct.start_date_list[0]]
      newCaseObj.settings.Config.config_struct.end_date_list = [newCaseObj.settings.Config.config_struct.end_date_list[newCaseObj.settings.Config.config_struct.end_date_list.length - 1]]
      newCaseObj.settings.Config.config_struct.interval_list = [defaultInterval]

      editCaseObj(newCaseObj)
      API.updateCaseSettings(caseId, newCaseObj.settings, stepNo)  
    }

    this.setState({
      selectedOption: e.target.value
    })
  }

  handleSettingsChangeTable(type, action, item, index, e) {
    const { editCaseObj, caseObj, stepNo } = this.props
    const { caseId } = caseObj


    item = itemMap[item]

    let val

    if (type === 'string') {
      val = e.target.value
    } else if (type === 'numerical') {
      if (action === 'change') {
        val = e.target.value
      } else if (action === 'blur') {
        val = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)  
      }
    } else if (type === 'date') {
      val = e
    }


    const newCaseObj = {...caseObj}

    newCaseObj.settings.Config.config_struct[item][index] = val

    if (item === 'end_date_list' && (index < newCaseObj.settings.Config.config_struct[item].length - 1)) {
      newCaseObj.settings.Config.config_struct.start_date_list[index + 1] = val
    }

    if (newCaseObj.stepNo && stepNo <= newCaseObj.stepNo) {
      if (stepNo === 1) {
        newCaseObj.stepNo = null
        newCaseObj.err = null
        newCaseObj.stepStatus = null
      }
      else {
        newCaseObj.stepNo = stepNo - 1
        newCaseObj.err = null
        newCaseObj.stepStatus = 'successful'
      }
        
    }

    editCaseObj(newCaseObj)


    if (action === 'change' && type !== 'string' && type !== 'numerical') {
      API.updateCaseSettings(caseId, newCaseObj.settings, stepNo)  
    }
    if (action === 'blur' && (type === 'string' || type === 'numerical')) {
      API.updateCaseSettings(caseId, newCaseObj.settings, stepNo)  
    }
  }



  makeTimeTable() {
    const { selectedOption } = this.state
    const { caseObj } = this.props

    console.log('selectedOption', selectedOption)
    const disabled = selectedOption === 'default'

    
    const data = caseObj.settings.Config.config_struct.start_date_list.map((item, index) => ({
        startDate: caseObj.settings.Config.config_struct.start_date_list[index],
        endDate: caseObj.settings.Config.config_struct.end_date_list[index], 
        interval: caseObj.settings.Config.config_struct.interval_list[index],
    }))

    let tempColumns = [...columns]

    tempColumns.forEach((column) => {
      if (column.type === 'input') {
        column.Cell = row => (
          <input
            style={{
              'background': 'none',
              "border": "none",
              "height": '100%',
              "width": "100%",
              "padding-left": '5px',
            }}
            disabled={disabled}
            contentEditable
            suppressContentEditableWarning
            value={data[row.index][column.accessor]}
            onChange={e => this.handleSettingsChangeTable('string', 'change', column.accessor, row.index, e)}
            onClick={() => console.log(column)}
            onBlur={e => this.handleSettingsChangeTable('string', 'blur', column.accessor, row.index, e)}
          />
        )
      } else if (column.type === 'date') {
        column.Cell = row => (
          <DatePicker
            // readOnly={disabled || column.disabled || ((row.index === data.length - 1) && column.accessor === 'endDate')}  //We need to look into how this is handled.
            readOnly={disabled || column.disabled}
            onChange={e => this.handleSettingsChangeTable('date', 'change', column.accessor, row.index, e)}
            onClick={() => console.log(column)}
            selected={new Date(data[row.index][column.accessor])}
          />
        )
      } else if (column.type === 'numerical') {
        column.Cell = row => (
          <input
            disabled={disabled}
            style={{
              'background': 'none',
              "border": "none",
              "height": '100%',
              "width": "100%",
              "padding-left": '5px',
            }}
            type="number"
            value={data[row.index][column.accessor]}
            onChange={e => this.handleSettingsChangeTable('numerical', 'change', column.accessor, row.index, e)}
            onBlur={e => this.handleSettingsChangeTable('numerical', 'blur', column.accessor, row.index, e)}          
          />
        )
      }
    })

    const deleteCol = {
      Header: '',
      accessor: 'delete',
      width: 35,
      resizable: false,
      Cell: () => (
        <div style={{ color: 'white', background: '#ff8e8e', borderRadius: '30px', textAlign: 'center', cursor: 'pointer' }}>
          X
        </div>
      ),
    }

    if (!disabled) {
      tempColumns.push(deleteCol)
    }

    return (
      <React.Fragment>
        <ReactTable
          className="-striped"
          columns={tempColumns}
          showPagination={false}
          data={data}
          pageSize={data.length}
          getTdProps={
            (state, rowInfo, column) => this.deleteRow(rowInfo, column)
          }
        />
        {!disabled && <Button type="button" variant="contained" color="primary" className="new-row-button" onClick={() => this.addNewRow()}>Add Row</Button> }
      </React.Fragment>
    )
  }

  addNewRow() {
    const { editCaseObj, caseObj } = this.props
    const { caseId } = caseObj
    const { settings } = caseObj

    const newCaseObj = { ...caseObj }
    newCaseObj.settings.Config.config_struct.start_date_list = [...caseObj.settings.Config.config_struct.start_date_list, caseObj.settings.Config.config_struct.end_date_list[ caseObj.settings.Config.config_struct.end_date_list.length - 1]]
    newCaseObj.settings.Config.config_struct.end_date_list = [...caseObj.settings.Config.config_struct.end_date_list, caseObj.settings.Config.config_struct.end_date_list[caseObj.settings.Config.config_struct.end_date_list.length - 1]]
    newCaseObj.settings.Config.config_struct.interval_list = [...caseObj.settings.Config.config_struct.interval_list, 12]
    
    editCaseObj(newCaseObj)
    API.updateCaseSettings(caseId, newCaseObj.settings)
  }

  deleteRow(rowInfo, column) {
    return {
      onClick: () => {
        if (column.id === 'delete' && rowInfo.index !== 0) {
          const { editCaseObj, caseObj } = this.props
          const { caseId } = caseObj
          const { settings } = caseObj
          
          const newCaseObj = { ...caseObj }
          const oldStartDate = newCaseObj.settings.Config.config_struct.start_date_list[rowInfo.index]
          const oldEndDate = newCaseObj.settings.Config.config_struct.end_date_list[rowInfo.index]
          newCaseObj.settings.Config.config_struct.start_date_list.splice(rowInfo.index, 1)
          newCaseObj.settings.Config.config_struct.end_date_list.splice(rowInfo.index, 1)
          newCaseObj.settings.Config.config_struct.interval_list.splice(rowInfo.index, 1)

          if (rowInfo.index < newCaseObj.settings.Config.config_struct.start_date_list.length - 1) {
            newCaseObj.settings.Config.config_struct.start_date_list[rowInfo.index] = oldStartDate
          } else {
            newCaseObj.settings.Config.config_struct.end_date_list[rowInfo.index - 1] = oldEndDate
          }


          editCaseObj(newCaseObj)
          API.updateCaseSettings(caseId, newCaseObj.settings)
        }
      },
    }
  }


  render() {
    const { selectedOption } = this.state
    // const { duplicateCaseId } = this.props

   
    return (
      <div >
        <div className="radio">
          <label>
            <input 
              type="radio" 
              value="default"
              checked={selectedOption === 'default'}
              onChange={this.handleOptionChange}
            />
            Use Default Setup
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="manual"
              checked={selectedOption === 'manual'}
              onChange={this.handleOptionChange}
            />
            Manually Setup
          </label>
        </div>
        <div className="time-table">
          { this.makeTimeTable()}
        </div>
      </div>
    )
  }
}

export default TimeStepSettings
