import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import Chip from '@material-ui/core/Chip'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'

import Select from 'react-select'
import { defaultTheme } from 'react-select'

import { setSelectedWells } from '../../../../redux/actions/waterfloodCase'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    title: {
        color: '#3b86ff',
    },
    paper: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eaf0f4',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.04)',
        maxHeight: 200,
        overflowY: 'auto',
    },
    chip: {
        float: 'right',
        height: 29,
        backgroundColor: '#3b86ff'
    }
})

@autobind class SelectWellsChip extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false, anchorEl: null }
  }

  toggleOpen(e) {
    const { anchorEl } = this.state
    const aEl = anchorEl ? null : e.currentTarget
    this.setState({ isOpen: !this.state.isOpen, anchorEl: aEl })
  }

  render() {
    const { selectedWells, classes, wells } = this.props
    const { isOpen, anchorEl } = this.state

    const selectedWellsCount = selectedWells ? selectedWells.size : 0

    return (
      <>
        <Chip color="primary" className={classes.chip} label={selectedWellsCount} onClick={this.toggleOpen} />
        <Popper open={isOpen} anchorEl={anchorEl} placement="bottom-end" transition>
            {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <div className={classes.paper}>
                    {selectedWellsCount ? (
                      <Typography className={classes.title} variant="subtitle1" gutterBottom>Selected Wells</Typography>
                    ): (
                      <Typography className={classes.title} variant="body1" gutterBottom>No Wells Selected</Typography>
                    )}  
                    {selectedWells.map((sW) => {
                        const well = wells.find(w => w._id === sW)
                        const name = well ? well.name : sW
                        return (<Typography variant="body1" gutterBottom>{name}</Typography>)
                    })}
                </div>
            </Fade>
            )}
        </Popper>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  rxSetSelectedWells: value => dispatch(setSelectedWells(value)),
})

const mapStateToProps = state => ({
  selectedWells : state.getIn(['waterfloodCase', 'selectedWells']),
})


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SelectWellsChip))