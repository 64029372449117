import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'
import Select from 'react-select'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getTimeSeriesData } from '../../../../services/waterfloodService'

import MUICard from '../../../common/MUICard/MUICard'

import './FlowAllocation.scss'
import { Typography } from '@material-ui/core'

const baseClass = 'flow-allocation'
const filtersClass = `${baseClass}__filters`


const allocationOptions = [
  { label: 'Connected Injectors', value: 'Injector' },
  { label: 'Connected Producers', value: 'Producer' },
]

const phaseOptions = [
  { label: 'Liquid', value: 'allocation' },
  { label: 'Oil', value: 'oilAllocation' },
  { label: 'Water', value: 'waterAllocation' },
]

const plotLayout = {
  title: undefined,
  autosize: true,
  hovermode: 'closest',
  margin: {
    l: 60,
    r: 10,
    b: 30,
    t: 10,
    pad: 0,
  },
  yaxis: {
    title: 'Production/Injection Rate (BBL/D)',
    scaleratio: 1,
    constrain: 'range'
  },
  legend: {
    orientation: 'h',
    y: 1.06,
    x: 1.15,
    xanchor: "right",
  },
  barmode: 'stack'
}


@autobind class FlowAllocation extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      allocation: 'Injector',
      phase: 'allocation',
      isNormalized: false,
      filteredData: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    const { timeline, selectedWells, caseId } = this.props
    if (prevProps.timeline !== timeline || prevProps.selectedWells !== selectedWells) {
      this.filterData()
    }
  }

  getData() {
    const { caseId } = this.props
    getTimeSeriesData(caseId).then((data) => {
      // TODO - handle wellDaata didnt load

      this.setState({
        data,
      })

      this.filterData(data)
    })
  }

  filterData(data) {
    const { selectedWells, timeline } = this.props
    const {  phase, allocation, isNormalized } = this.state

    if (!data) {
      data = this.state.data ? [...this.state.data] : []
    }

    if (selectedWells.size > 0) {
      data = data.filter(i => selectedWells.includes(i._id))
    } 

    this.setState({
      loaded: true,
      filteredData: data
    })

  }


  handleIsNormalizedChange() {
    this.setState({
      isNormalized: !this.state.isNormalized
    })
  }


  handleAllocationChange(e) {

    let obj = {
      allocation: e.value
    }

    if (e.value === 'Injector') {
      obj.phase = 'allocation'
    }

    this.setState(obj)
  }

  handlePhaseChange(e) {

    this.setState({
      phase: e.value
    })
  }


  render() {
    const { loaded, data, allocation, phase, isNormalized, filteredData } = this.state
    const { timeline } = this.props

    let plotData = []
    let dates = filteredData && filteredData.length > 0 ? filteredData[0].timeSeriesData.map(i => new Date(i.date.date)) : []
    let sums = dates.map(i => 0)
    let maxY = 0

    filteredData.forEach(well => { 
      let show = false

      if (well.meta.type === allocation) {
        well.timeSeriesData.forEach(item => {
          if (item.wellConnections) {
            show = true
          }
        })
      }

      if (show) {
        plotData.push({
          x: dates,
          y: well.timeSeriesData.map(i => {
            let sum = 0
            if (i.wellConnections) {
              i.wellConnections.forEach(con => {
                sum += con[phase]
              })
            }
            if (sum > maxY) {
              maxY = sum
            }
            return sum
          }),
          name: well.name,
          type: 'bar',
          showlegend: false,
        })
      }
    })

    if (isNormalized) {
      maxY = 1

      plotData.forEach(item => {
        item.y.forEach((val, index) => {
          sums[index] += val
        })
      })


      plotData.forEach(item => {
        item.y = item.y.map((val, index) => val / sums[index])
      })    
    }

    plotData.push({
      x: [new Date(timeline * 1000), new Date(timeline * 1000)],
      y: [0, maxY],
      mode: 'lines',
      type: 'scattergl',
      showlegend: false,
      line:{
        color: 'black',
        width: 2,
        dash: 'dash',
      },
    }) 


    return (
      <MUICard
        title="Allocation Factor"
        busy={!loaded}
      >
        <div>
          <Select
            className='selectors'
            options={allocationOptions}
            onChange={this.handleAllocationChange}
            isMulti={false}
            value={allocationOptions.find(i => i.value === allocation)}
          />
          <Select
            className='selectors'
            options={phaseOptions}
            onChange={this.handlePhaseChange}
            isMulti={false}
            isDisabled={allocation === 'Injector'}
            value={phaseOptions.find(i => i.value === phase)}
          />
          <input type="checkbox" id="normalize" name="normalize" value={isNormalized} onChange={this.handleIsNormalizedChange}/>
          <label htmlFor="normalize">Show as Percent</label>
        </div>
        {loaded && data === undefined ? (
          <Typography>Unable to load well data</Typography>
        ) : (
          <Plot
            style={{ 'width': '100%' }}
            data={plotData}
            layout={plotLayout}
            config={{ displaylogo: false, showSendToCloud: true }}
            useResizeHandler
          />
        )}
      </MUICard>
    )
  }
}

const mapStateToProps = state => ({
  timeline: state.getIn(['waterfloodCase', 'timeline']),
  selectedWells: state.getIn(['waterfloodCase', 'selectedWells'])
})

export default withRouter(connect(mapStateToProps, null)(FlowAllocation))
