import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import ProductMap from './ProductMap.js'
// Note - flipeped 'active' and 'disabled' because im lazy and isActive is built into NavLink
const linkToPath = (path, shortName, url, disabled) => {

  console.log('shortname', shortName, 'url', url)

  if(path && shortName) {
    return (
      <div className='launch'>
        <NavLink
          className="outer-nav-link"
          to={disabled ? `/` : `/${shortName}/${path}` }
          activeStyle={{ background: "grey", cursor: "default", textDecoration: 'none', color: '#545454' }}
          isActive={() => {
            return disabled
          }}
        >
          Launch
        </NavLink>
      </div>
    )
  } else if(url){
    return (
      <div className='launch'>
        <a
          className="outer-nav-link"
          href={url}
        >
          Launch
        </a>
      </div>
    )
  }
}

const ProductRow = ({ product }) => {
  const { name, shortName, oldShortName, color, description, availability, path, disabled, image, url } = ProductMap[product]
  
  return (
    <div className={`product-row hover-effect grow`} style={{ 'border': `dashed 2px ${color}` }}>
      <div className='product-image'>
        <img src={image} />
      </div>
      <div className="product-info">
        <div className="product-name">
          SpeedWise
          <span className='product-name-registed'>
            <sup>®</sup>
          </span>
          <span className="product-name-span" style={{color}}>
          { name }
          </span>
        </div>
        <div className='desc'>
          {description}
        </div>
      </div>
      {
        availability ? (
          <div className='availability'>
            {availability}
          </div>
        ) : linkToPath(path, oldShortName ? oldShortName : shortName, url, disabled)
      }
    </div>
  )
}


export default ProductRow