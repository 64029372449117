import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { Search as SearchIcon } from '@material-ui/icons'

import './Search.scss'

const baseClass = 'sgo-search'

const labelTextClass = `${baseClass}__text`
const inputClass = `${baseClass}__input`

@autobind class Search extends Component {
  render() {
    const {
      labelText,
      onChange,
    } = this.props

    let className = this.props.className || ''
    let placeholder = this.props.placeholder || 'Search'


    return (
      <label className={`${baseClass} ${className}`}>
        <span className={labelTextClass}>{labelText}</span>
        <input
          className={inputClass}
          type="text"
          onChange={onChange}
          placeholder={placeholder}
        />
        <SearchIcon />
      </label>
    )
  }
}

export default Search
