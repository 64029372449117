import React from 'react'

import './Avatar.scss'

const baseClass = 'avatar'
const textClass = `${baseClass}__text`

const Avatar = (props) => {
  const { user, fullName } = props

  if (user) {
    const { firstName, lastName } = user
    const firstInitial = firstName.charAt(0)
    const lastInitial = lastName.charAt(0)

    return (
      <div className={`${baseClass}`}>
        {firstInitial}{lastInitial}
      </div>
    )
  }

  if (fullName) {
    const nameParts = fullName.split(' ')

    const firstInitial = nameParts[0] ? nameParts[0].charAt(0) : ' '
    const lastInitial = nameParts[1] ? nameParts[1].charAt(0) : ' '

    return (
      <div className={`${baseClass}`}>
        <span className={textClass}>
          {firstInitial}{lastInitial}
        </span>
      </div>
    )
  }

  return null
}

export default Avatar
