import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class MapGeneration extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Target Search for Sweet Spots
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          In this step, the global RPOS map is used together with the spacing grid to identify locations that maximize RPOS while honoring the spacing grid constraints. This step also identifies the candidate target zones for every location and collects various types of geo-engineering attributes for every target zone.
        </div>
        <div className="content-row"> 
          Number of Sweet Spots<br/>
          <span className='blue'>Options:</span> Integar<br/>
          <span className='blue'>Comments:</span> Specifies the number of new drills to look for.<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(MapGeneration)
