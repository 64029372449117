import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import MoreIcon from '@material-ui/icons/More'

import Link from '@material-ui/core/Link'
import ButtonBase from '@material-ui/core/ButtonBase'


const baseClass = 'read-more'

const READMORE_TEXT = 'Read more'
const READLESS_TEXT = 'Read less'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

@autobind class ReadMore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      active: false,
    }
  }

  componentDidMount() {
    const e = this.element
    this.setState({ active: this.isEllipsisActive(e) })
  }

  isEllipsisActive(e) {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  }

  more() {
    this.setState({
      open: true,
    })
  }

  less() {
    this.setState({
      open: false,
    })
  }

  toggle() {
    const { open } = this.state
    !open ? this.more() : this.less()
  }

  render() {
    const { children, ...props} = this.props
    const { active, open } = this.state
    return (
      <div className={baseClass} style={{ position: 'relative', paddingRight: '6rem' }}>
        <Typography {...props} ref={(el) => {this.element = el}}>
          {children}
        </Typography>
        { active && (
          <HtmlTooltip
            open={open}
            style={{ position: 'absolute', right: -10, top: 0 }}
            title={
              <React.Fragment><Typography color="inherit">{children}</Typography></React.Fragment>
            }
          >
            <ButtonBase component={Link} onClick={this.toggle}>
              <Typography {...props}>{open ? READLESS_TEXT : READMORE_TEXT}</Typography>
            </ButtonBase>
          </HtmlTooltip>
        )}
      </div>
    )
  }
}

export default ReadMore
