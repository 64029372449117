import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import TextField from '@material-ui/core/TextField'

import Button from '@material-ui/core/Button'

import { numberFormatDate } from '../../../../services/dateFormatterService'

import './CaseEditor.scss'

const baseClass = 'dataset-editor'

const headerClass = `${baseClass}__header`

const contentClass = `${baseClass}__content`

const topInputBarClass = `${contentClass}__top-bar`
const datasetNameInputClass = `${topInputBarClass}__name-input`

const datasetDescriptionLabelClass = `${contentClass}__description-label`
const datasetDescriptionTextAreaClass = `${datasetDescriptionLabelClass}__text-area`

const bottomBarClass = `${contentClass}__bottom-bar`
const bottomLeftBarClass = `${bottomBarClass}__left`
const dateWrapperClass = `${bottomLeftBarClass}__date-created-wrapper`
const dateCreatedLabelClass = `${bottomLeftBarClass}__date-created-label`
const dateCreatedClass = `${bottomLeftBarClass}__date-created`

const multiTextRows = 4

const reqStyle = {
  color: 'red',
  display: 'inline-block'
}

const makeRequired = textInput => <>{textInput}<span style={reqStyle}>*</span></>

@autobind class CaseEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      caseName: '',
      caseDescription: '',
    }
  }

  handleNameChange(e) {
    const { value } = e.target
    this.setState({
      caseName: value,
    })
  }


  handleDescriptionChange(e) {
    const { value } = e.target
    this.setState({
      caseDescription: value,
    })
  }

  onActionClick() {
    const { caseName, caseDescription } = this.state
    const { duplicateCaseId, handleDuplicateCase, handleNewCase } = this.props

    if (duplicateCaseId) {
      handleDuplicateCase(caseName, caseDescription)
    } else {
      handleNewCase(caseName, caseDescription)
    }
  }

  render() {
    const { caseName, caseDescription } = this.state
    const { duplicateCaseId } = this.props

    let datasetDate = numberFormatDate(new Date())

    return (
      <div className={baseClass}>

        <div className={headerClass}>
          {duplicateCaseId && (<span>Duplicate Case</span>)}
          {!duplicateCaseId && (<span>New Case</span>)}
        </div>

        <div className={contentClass}>
          <div className={topInputBarClass}>
            <TextField
              id="dataset-name-input"
              label={makeRequired('Case Name')}
              type="text"
              margin="normal"
              variant="outlined"
              className={datasetNameInputClass}
              value={caseName}
              onChange={this.handleNameChange}
            />
          </div>
          <TextField
            fullWidth
            id="dataset-description-input"
            label="Case Description"
            type="text"
            margin="normal"
            variant="outlined"
            multiline
            rows={multiTextRows}
            value={caseDescription}
            onChange={this.handleDescriptionChange}
          />

          <div className={bottomBarClass}>
            <div className={dateWrapperClass}>
              <label className={dateCreatedLabelClass}>
                <span>Date Created</span>
              </label>
              <span className={dateCreatedClass}>{datasetDate}</span>
            </div>
            <div>
              <Button
                disabled={!caseName}
                tabIndex="0"
                onClick={this.onActionClick}
                onKeyPress={null}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
              >
                {duplicateCaseId && (<span>Duplicate Case</span>)}
                {!duplicateCaseId && (<span>Create Case</span>)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CaseEditor
