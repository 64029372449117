import React from 'react'

import img1 from './Units.jpg'

const Units = (
  <div className={'description-container'}>
    <figure>
      <img src={img1} />
      <figcaption> </figcaption>
    </figure>
  </div>
)

export default Units