import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Overview extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Data Categories
        </div>
        <div className="content-row"> 
          Every dataset is composed of multiple data categories (e.g., well tops, well logs, geologic model, or well production histories). This section gives an overview of the specific files that can be uploaded for each data category. For more information regarding the requirements of the data, and helpful guides to prepare it, please refer to the document “SpeedWise Reservoir - Data Preparation.docx.”
        </div>
        <div className="content-row"> 
          Certain modules need only a subset of the data categories. For example, the wellbore diagrams are data that can be used for the recompletions module in order to identify opportunities with mechanical issues, however, these diagrams are not necessary for the vertical or horizontal new drill modules. Table 1 shows a summary of the data categories and files, as well as which are necessary for each of the SpeedWise Reservoir modules.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Overview)
