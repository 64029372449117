import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import Grid from '@material-ui/core/Grid'
import { ClipLoader } from 'react-spinners'
import Select from 'react-select'
import Card from '../../../common/MUICard'
import API from '../../../../../../lib/api-store'
import WellLocation from '../../../components/WellLocation'
import RPOSHistogram from './components/RPOSHistogram'
import { linSpace, downsampleGrid } from '../../../../../../lib/helpers'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class RelativePOS extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shouldRenderDetails: false,
      shouldRenderProduction: false,
      data: [],
      plotLayout: null,
      axis: {},
      spacing: null,
      rpos: null,
      clickedOffTraces: [],
    }
  }

  componentDidMount() {
    const { setCurPage } = this.props
    setCurPage('')
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    const { wellId, selectedZone, caseId } = this.props

    if (wellId !== prevProps.wellId
      || selectedZone !== prevProps.selectedZone
      || caseId !== prevProps.caseId) {
      this.loadData()
    }
  }

  setClickedOffTraces(val) {
    this.setState({
      clickedOffTraces: val,
      refreshWellLocation: true
    })
  }

  onUpdate(axisRange) {
    const { axis } = this.state
    const x1 = axisRange['xaxis.range[0]']
    const x2 = axisRange['xaxis.range[1]']

    const y1 = axisRange['yaxis.range[0]']
    const y2 = axisRange['yaxis.range[1]']

    const autoRangeX = axisRange['yaxis.autorange']
    const autoRangeY = axisRange['xaxis.autorange']

    if (x1 && x2 && y1 && y2) {
      this.setState({
        ...axis,
        axis: {
          xaxis: {
            range: [x1, x2],
          },
          yaxis: {
            range: [y1, y2],
          },
        },
      })
    }

    if (autoRangeX && autoRangeY) {
      this.setState({
        ...axis,
        axis: {
          xaxis: {
            autorange: autoRangeX,
          },
          yaxis: {
            autorange: autoRangeY,
          },
        },
      })
    }
  }

  loadData() {
    const { caseId, caseObj, datasetId, zone } = this.props
    const property = zone === 'All' ? 'rpos' : ''
    const gridZone = zone === 'All' ? 'All' : zone
    const attributes = caseObj.settings.generatePOSMap.ranking.Table   
  
    API.getGridData('spacing', 'All', datasetId, caseId)
      .then((results) => {
        this.setState({ spacing: results })
      })

    API.getGridData('pos', zone, datasetId, caseId)
      .then((results) => {
        this.setState({ rpos: results })
      })
      
    // Load all grid data from the attributes selected in the case settings
    Promise.all(
      attributes.map(attr => (
        API.getGridData(attr.attribute, gridZone, datasetId, caseId)),
      )
    ).then((results) => {
      const filteredResults = results.filter(x => x !== null)
      this.setState({ data: filteredResults })
    })  
  }

  rposMap() {
    let { rpos, plotLayout, axis, clickedOffTraces } = this.state

    if (rpos == null) {
      return null
    } else if (rpos.length > 1) {
      rpos = rpos[0]
    }
    
    const {
      wellData,
      singleOpportunity,
      faultData,
      structureZMatrix,
      structureGridX,
      structureGridY
    } = this.props

    const { definition, property } = rpos
    const { zMatrix } = rpos
    const { iMax, jMax, x1, x2, y1, y2 } = definition
    const gridX = linSpace(x1, x2, iMax)
    const gridY = linSpace(y1, y2, jMax)
    //const { smallGridX, smallGridY, smallZMatrix } = downsampleGrid(structureGridX, structureGridY, zMatrix, 10)
    const singleOpportunityData = singleOpportunity && Object.keys(singleOpportunity).length > 0 ? [singleOpportunity] : []
    const { x, y, i, j } = singleOpportunityData[0]

    return (
      <React.Fragment>
        <Grid item xs={12} md={6}>
          <Card title="RPOS Map">
            <WellLocation
              wellData={wellData}
              zMatrixHeat={rpos.zMatrix}
              gridXHeat={gridX}
              gridYHeat={gridY}
              heatTitle="Structure"
              yMargin={75}
              zMatrixContour={structureZMatrix}
              gridXContour={structureGridX}
              gridYContour={structureGridY}
              faultData={faultData}
              opportunityData={singleOpportunityData}
              selectedNeighborLogs={[]}
              onUpdate={this.onUpdate}
              layout={plotLayout}
              axis={axis}
              clickedOffTraces={clickedOffTraces}
              setClickedOffTraces={this.setClickedOffTraces}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card title="RPOS Histogram">
            <RPOSHistogram 
              data={rpos.zMatrix}
              refLine={rpos.zMatrix[j - 1][i - 1]}
          />
          </Card>
        </Grid>
      </React.Fragment>
    )
  }

  spacingMap() {
    const { spacing, plotLayout, axis, clickedOffTraces} = this.state
    const {
      wellData,
      singleOpportunity,
      faultData,
      structureZMatrix,
      structureGridX,
      structureGridY,
    } = this.props

    if (spacing == null) {
      return null
    }
    
    const { definition, property } = spacing
    const { zMatrix } = spacing
    const { iMax, jMax, x1, x2, y1, y2 } = definition
    const gridX = linSpace(x1, x2, iMax)
    const gridY = linSpace(y1, y2, jMax)
    //const { smallGridX, smallGridY, smallZMatrix } = downsampleGrid(structureGridX, structureGridY, zMatrix, 10)
    const singleOpportunityData = singleOpportunity && Object.keys(singleOpportunity).length > 0 ? [singleOpportunity] : []
    const { x, y, i, j } = singleOpportunityData[0]

    return (
      <React.Fragment>
        <Grid item xs={12} md={6}>
          <Card title="Spacing Map">
            <WellLocation
              wellData={wellData}
              zMatrixHeat={spacing.zMatrix}
              gridXHeat={gridX}
              gridYHeat={gridY}
              heatTitle="Structure"
              yMargin={75}
              zMatrixContour={structureZMatrix}
              gridXContour={structureGridX}
              gridYContour={structureGridY}
              faultData={faultData}
              opportunityData={singleOpportunityData}
              selectedNeighborLogs={[]}
              onUpdate={this.onUpdate}
              layout={plotLayout}
              axis={axis}
              clickedOffTraces={clickedOffTraces}
              setClickedOffTraces={this.setClickedOffTraces}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card title="Spacing Histogram">
            <RPOSHistogram 
              data={spacing.zMatrix}
              refLine={spacing.zMatrix[j - 1][i - 1]}
            />
          </Card>
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    const {
      wellData,
      singleOpportunity,
      faultData,
      handleUpdateZone,
      selectedZone,
      zones,
      structureZMatrix,
      structureGridX,
      structureGridY,
      caseObj,
    } = this.props

    const { data, plotLayout, axis, clickedOffTraces } = this.state
    const options = [{ label: 'All', value: 'All' }, ...zones]

    const attributes = caseObj.settings.generatePOSMap.ranking.Table

    if(!wellData){
      return (
        <div style={{height: "50vh", width: "100%"}}>
          <ClipLoader css={loaderCSS} />
        </div>
      ) 
    }

    return (
      <>
        <div className="zone-selector">
          <label>Select Target Zone</label>
          <Select
            id="zone-select"
            options={options}
            onChange={val => handleUpdateZone(val.value)}
            isMulti={false}
            value={zones.find(z => z.value === selectedZone)}
          />
        </div>
        <div className="relative-pos">
          <Grid className="relative-pos-grid" container spacing={2}>
            { this.rposMap() }
            { this.spacingMap() }

            {attributes.map((attr, idx) => {
              // const title = attr.attribute

              const grid = data.find(e => e.property === attr.attribute)

              if (!grid) {
                return (
                  <Grid item xs={12} md={6}>
                    <Card title={title} subtitle={subtitle}>
                      <ClipLoader css={loaderCSS} />
                    </Card>
                  </Grid>
                )
              }

              const { definition, property } = grid
              const { zMatrix } = grid
              const { iMax, jMax, x1, x2, y1, y2 } = definition
              const gridX = linSpace(x1, x2, iMax)
              const gridY = linSpace(y1, y2, jMax)
               //const { smallGridX, smallGridY, smallZMatrix } = downsampleGrid(structureGridX, structureGridY, zMatrix, 10)
              const singleOpportunityData = singleOpportunity && Object.keys(singleOpportunity).length > 0 ? [singleOpportunity] : []
              const title = `RPOS Attribute ${idx + 1} Map`
              const histTitle = `RPOS Attribute ${idx + 1} Histogram`
              const subtitle = property

              const { x, y, i, j } = singleOpportunityData[0]


              return (
                <React.Fragment>
                  <Grid item xs={12} md={6}>
                    <Card title={title} subtitle={subtitle}>
                      { grid ? (
                        <WellLocation
                          wellData={wellData}
                          zMatrixHeat={grid.zMatrix}
                          gridXHeat={gridX}
                          gridYHeat={gridY}
                          heatTitle="Structure"
                          yMargin={75}
                          zMatrixContour={structureZMatrix}
                          gridXContour={structureGridX}
                          gridYContour={structureGridY}
                          faultData={faultData}
                          opportunityData={singleOpportunityData}
                          selectedNeighborLogs={[]}
                          onUpdate={this.onUpdate}
                          layout={plotLayout}
                          axis={axis}
                          clickedOffTraces={clickedOffTraces}
                          setClickedOffTraces={this.setClickedOffTraces}
                        />
                      ) : <ClipLoader css={loaderCSS} />}
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card title={histTitle} subtitle={subtitle}>
                      { grid ? (
                        <RPOSHistogram 
                          data={grid.zMatrix}
                          refLine={grid.zMatrix[j - 1][i - 1]}
                        />
                      ) : <ClipLoader css={loaderCSS} />}
                    </Card>
                  </Grid>
                </React.Fragment>
              )
            })}


            {/*data.map((grid, idx) => {

              const { definition, property } = grid
              const { zMatrix } = grid
              const { iMax, jMax, x1, x2, y1, y2 } = definition
              const gridX = linSpace(x1, x2, iMax)
              const gridY = linSpace(y1, y2, jMax)
               //const { smallGridX, smallGridY, smallZMatrix } = downsampleGrid(structureGridX, structureGridY, zMatrix, 10)
              const singleOpportunityData = singleOpportunity && Object.keys(singleOpportunity).length > 0 ? [singleOpportunity] : []
              const title = `RPOS Attribute ${idx + 1} Map`
              const subtitle = property

              return (
                <Grid item xs={12} md={6}>
                  <Card title={title} subtitle={subtitle}>
                    <WellLocation
                      wellData={wellData}
                      zMatrixHeat={grid.zMatrix}
                      gridXHeat={gridX}
                      gridYHeat={gridY}
                      heatTitle="Structure"
                      yMargin={75}
                      zMatrixContour={structureZMatrix}
                      gridXContour={structureGridX}
                      gridYContour={structureGridY}
                      faultData={faultData}
                      opportunityData={singleOpportunityData}
                      selectedNeighborLogs={[]}
                      onUpdate={this.onUpdate}
                      layout={plotLayout}
                      axis={axis}
                    />
                  </Card>
                </Grid>
              )
            })*/}
          </Grid>
        </div>
      </>
    )
  }
}


export default RelativePOS
