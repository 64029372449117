import React from 'react'
import { connect } from 'react-redux'

import { closeTooltip } from '../../../../redux/actions/SGOVisualization'

import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Tooltips from './tooltips/index'

import './TooltipDrawer.scss'

class TooltipDrawer extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    const { close } = this.props
    close()
  }

  componentWillUnmount(){
    this.componentDidMount()
  }

  render() {

    const { close, isOpen, tooltipKey, title } = this.props

    console.log('tooltipKey', tooltipKey)
    console.log('Tooltips', Tooltips)
    console.log(Tooltips[tooltipKey])
    return (
      <Drawer
        open={isOpen}
        variant="persistent"
        anchor="right"
        PaperProps={{ style: { position: 'absolute', width: '50%' } }}
      >
        <div>
          <Toolbar
            style={{ justifyContent: 'space-between', paddingLeft: 16, paddingRight: 16 }}
          >
            <Typography variant="h5">{title}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="Close Validation Drawer"
              onClick={close}
            >
              <ChevronRightIcon/>
            </IconButton>
          </Toolbar>
          <div>
            {Tooltips[tooltipKey]}
          </div>
        </div>
      </Drawer>
    )
  }
}

const mapStateToProps = state => {
  const substate = state.get('SGOVisualization')

  return {
    isOpen: substate.get('tooltipOpen'),
    tooltipKey: substate.get('tooltipKey'),
    title: substate.get('tooltipTitle'),
  }
}

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeTooltip())
})

export default connect(mapStateToProps, mapDispatchToProps)(TooltipDrawer)