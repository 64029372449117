import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'

@autobind class WellProduction extends PureComponent {
  render() {
    let { time, oilRate, waterCut, gor } = this.props
    return ( 
      <Plot
        className='plot'
        data={[
          {
            name: 'Oil Rate',
            x: time,
            y: oilRate,
            hovertemplate: '%{y} bbl/d',
            type: 'scatter',
            mode: 'lines+points',
            marker: { color: '#52AB7F' },
          },
          {
            name: 'Water Cut',
            x: time,
            y: waterCut,
            hovertemplate: '%{y}%',
            type: 'scatter',
            mode: 'lines+points',
            yaxis: 'y2',
            marker: { color: '#176A93' },
          },
          {
            name: 'GOR',
            x: time,
            y: gor,
            hovertemplate: '%{y} scf/stb',
            type: 'scatter',
            mode: 'lines+points',
            yaxis: 'y3',
            marker: { color: '#C95646' },
          },
        ]}
        layout={{
          // title: 'Well Production',
          title: '',
          grid: { rows: 3, columns: 1 },
          xaxis: {
            title: 'Time',
            type: 'date',
          },
          yaxis: { 
            title: { 
              text: 'Oil Rate (bbl/d)',
              font: { 
                size: 10
              }
            },
            fixedrange: true,
          },
          yaxis2: { 
            title: { 
              text: 'Water Cut (%)',
              font: { 
                size: 10
              }
            }, 
            range: [0, 100],
            fixedrange: true,
          },
          yaxis3: { 
            title: { 
              text: 'GOR (scf/stb)',
              font: {
                size: 10
              }
            },
            fixedrange: true,
          },
          autosize: true,
          margin: {
            l: 50,
            r: 10,
            b: 30,
            t: 10,
            pad: 0,
          },
        }}
        config={{displaylogo: false, showSendToCloud: true}}
        useResizeHandler
      />
    )
  }
}

export default WellProduction
