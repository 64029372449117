import React, { Component } from 'react'
import autobind from 'autobind-decorator'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import Select from 'react-select'
import Switch from 'rc-switch'
import ReactTooltip from 'react-tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import API from '../../../../lib/api-store'
import TimeStepSettings from '../Components/settingsOutliers/TimeStepSettings'
import CrossZoneCommunicationFactorSettings from '../Components/settingsOutliers/CrossZoneCommunicationFactorSettings'
import FaultTransmissibilityMultiplierSettings from '../Components/settingsOutliers/FaultTransmissibilityMultiplierSettings'
import VertToHorizontalPermeabilityRatioSettings from '../Components/settingsOutliers/VertToHorizontalPermeabilityRatioSettings'
import SWMWellConstraintSettings from '../Components/settingsOutliers/SWMWellConstraintSettings'
import SWMGroupConstraintSettings from '../Components/settingsOutliers/SWMGroupConstraintSettings'

import './SettingsSubGroup.scss'

const set = (obj, path, value) => {
    let schema = obj
    let pList = path.split('.')
    let len = pList.length
    for(let i = 1; i < len - 1; i++) {
        let elem = pList[i]
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem]
    }

    schema[pList[len-1]] = value;

    return obj
}

const getValFromPath = (path, settings) => {
  let item = settings
  let pList = path.split('.')
  let len = pList.length
  for (let i = 1; i < len; i++) {
      let elem = pList[i]
      item = item[elem]
  }
  return item
}

@autobind class SettingsSubGroupSWM extends Component {

  handleSettingsChange(type, action, path, e) {
    const { editCaseObj, caseObj, stepNo } = this.props
    const { caseId } = caseObj
    let val

    if (type === 'numerical') {
      if (action === 'change') {
        val = e.target.value
      } else if (action === 'blur') {
        val = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)  
      }
    } else if (type === 'select') {
      val = e.value
    } else if (type === 'select-multi') {
      val = e ? e.map(item => item.value) : []
    } else if (type === 'bool') {
      val = e
    }else {
      console.log('missing type', type)
    }

    const newCaseObj = {...caseObj}
    let newSettings = set(newCaseObj.settings, path, val)
    newCaseObj.step = 'customize'

    if (newCaseObj.stepNo && stepNo <= newCaseObj.stepNo) {
      if (stepNo === 1) {
        newCaseObj.stepNo = null
        newCaseObj.err = null
        newCaseObj.stepStatus = null
      }
      else {
        newCaseObj.stepNo = stepNo - 1
        newCaseObj.err = null
        newCaseObj.stepStatus = 'successful'
      }
    }

    editCaseObj(newCaseObj)

    if (action === 'change' && type !== 'string' && type !== 'numerical') {
      API.updateCaseSettings(caseId, newCaseObj.settings, stepNo)  
    }
    if (action === 'blur' && (type === 'string' || type === 'numerical')) {
      API.updateCaseSettings(caseId, newCaseObj.settings, stepNo)  
    }
    
  }


 makeSettings() {
    const { caseObj, data, editCaseObj, subCategoryKey, stepNo } = this.props
    console.log(this.props)

    if (subCategoryKey === 'timeSteps') {
      return (
        <TimeStepSettings
          caseObj={caseObj}
          editCaseObj={editCaseObj}
          stepNo={stepNo}
        /> 
      )
    }

    return Object.keys(data.settings).map(settingKey => {
      let setting = data.settings[settingKey]

      // Setting Outliers
      if (settingKey === 'crossZoneCommunicationFactor') {
        return <VertToHorizontalPermeabilityRatioSettings 
          caseObj={caseObj}
          editCaseObj={editCaseObj}
          stepNo={stepNo}
          disabled={false}
        />
      } else if (settingKey === 'vertToHorizontalPermeabilityRatio') {
        return <CrossZoneCommunicationFactorSettings
          caseObj={caseObj}
          editCaseObj={editCaseObj}
          stepNo={stepNo} 
          disabled={false}
        />
      } else if (settingKey === 'faultTransmissibilityMultipliers') {
        return <FaultTransmissibilityMultiplierSettings
          caseObj={caseObj}
          editCaseObj={editCaseObj}
          stepNo={stepNo} 
          disabled={false}
        />
      } else if (settingKey === 'wellConstraints') {
        return <SWMWellConstraintSettings 
          caseObj={caseObj}
          editCaseObj={editCaseObj}
          stepNo={stepNo}
          disabled={false}
        />
      } else if (settingKey === 'injection') {
          return <SWMGroupConstraintSettings 
            caseObj={caseObj}
            editCaseObj={editCaseObj}
            stepNo={stepNo}
            groupName='injection'
            disabled={false}
          />
      } else if (settingKey === 'production') {
          return <SWMGroupConstraintSettings 
            caseObj={caseObj}
            editCaseObj={editCaseObj}
            stepNo={stepNo}
            groupName='production'
            disabled={false}
          />
      } else if (settingKey === 'vrr') {
          return <SWMGroupConstraintSettings 
            caseObj={caseObj}
            editCaseObj={editCaseObj}
            stepNo={stepNo}
            groupName='vrr'
            disabled={false}
          />
      }

      return this.makeSetting(setting)
    })
  }

  makeSetting(obj) {
    const { caseObj } = this.props

   if (obj.dependency && getValFromPath(obj.dependency.path, caseObj.settings) !== obj.dependency.val) {
      return null
    }

    return (
     <div className='setting'>
        <div className="left">
          <Typography className="setting-name" variant="body1">
            {obj.display}
            <a data-tip={obj.description} data-for={obj.display}>
              <FontAwesomeIcon icon="question-circle" className="help-icon" />
            </a>
          </Typography>
          <Typography variant="caption">
            {obj.subDisplay}
          </Typography>
        </div>
        <div className="right">
          { this.makeSettingInput(obj) }
        </div>
        <ReactTooltip id={obj.display} className="customize-settings-tooltip" effect="solid" />
      </div>
    )
  }

  makeSettingInput(obj) {
    const { caseObj, disabled } = this.props
    const { settings } = caseObj

    let val = null
    let labelLength = obj.unit && obj.unit.length ? obj.unit.length : 0

    try {
      let item = settings
      let pList = obj.path.split('.')
      let len = pList.length
      for (let i = 1; i < len; i++) {
          let elem = pList[i]
          item = item[elem]
      }

      val = item

    } catch (e) {
      console.log(e)
    }

    switch (obj.type) {
      case 'numerical':
        return (
          <TextField 
            className="setting-input"
            variant="outlined"
            margin="dense"
            value={val}
            disabled={disabled} 
            InputProps={{
              endAdornment: <InputAdornment position="end">{obj.unit}</InputAdornment>,
            }}
            onChange={e => this.handleSettingsChange('numerical', 'change', obj.path, e)}
            onBlur={e => this.handleSettingsChange('numerical', 'blur', obj.path, e)}
            style={{ minWidth: 75 + (labelLength * 5)}}
          />
        )
      case 'bool':
        return (
          <Switch
            disabled={disabled}
            className='float-right'
            checked={val}
             onChange={e => this.handleSettingsChange('bool', 'change', obj.path, e)}
          />
        )
      case 'categorical': {
        const item = val
        let value

        if (obj.multi) {
          value = item ? obj.options.filter(({ value }) => item.includes(value)) : []
        } else {
          value = obj.options.filter(({ value }) => value === item)
        }

        return (
            <Select
              className="setting-input selector"
              options={obj.options}
              isMulti={obj.multi}
              isDisabled={disabled}
              value={value}
              onChange={e => this.handleSettingsChange(`select${obj.multi ? '-multi' : ''}`, 'change', obj.path, e)}
            />
        )
      }
      default:
        return <div> MISSING type </div>
    }

  }

  render() {
    let { data } = this.props

    return (
          <ExpansionPanel
            className={''}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={data.display}
            >
              <Typography component="h3" variant="h6">{data.display}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="settings-container">
                { this.makeSettings() }
              </div>  
            </ExpansionPanelDetails>
          </ExpansionPanel>
    )
  }
}



export default SettingsSubGroupSWM