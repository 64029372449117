import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import MapGenerationA from '../../../../../../../../../../static/images/userManual/settings/mapGenerationA.png'


@autobind class MapGeneration extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Map Generation
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          This step creates various grids that will be useful for the next step (RPOS modeling). This includes:
        </div>
        <div className="content-row"> 
          • Spacing grid: Defines no-go cells (or areas where placing a well conflicts with other existing wells) based on the user-specified spacing settings.
        </div>
        <div className="content-row"> 
          • Target Thickness Index grid: Specifies areas within each zone that have a target thickness (gross thickness within the oil window) above a certain user-specified threshold. This target thickness is created by taking into account the structure maps for the top and base of the zones, the fluid contacts for each zone, and any additional contact standoff constraints specified by the user.
        </div>
        <div className="content-row"> 
          • Distance-To-Fault Index grid: Specifies areas within each zone that meet a certain distance threshold from existing fault traces in the same zone. The user can specify whether proximity to faults is advantageous or disadvantageous.
        </div>
        <div className="content-row"> 
          Settings for this section 
        </div>
        <div className="content-row"> 
          Region of Interest
        </div>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> X & Y<br/>
          <span className='blue'>Units:</span> Meters<br/>
          <span className='blue'>Comments:</span> Specifies the boundary of the area to look for new drill opportunities.<br/>
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Spacing
        </div>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> Spacing to Existing Wells<br/>
          <span className='blue'>Units:</span> Acres<br/>
          <span className='blue'>Comments:</span> Refers to min acreage to existing wells.<br/>
          <span className='blue'>Setting:</span> Spacing between New Drills<br/>
          <span className='blue'>Units:</span> Acres<br/>
          <span className='blue'>Comments:</span> Refers to min acreage to other new drills.<br/>
        </div>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> Spacing between New Drills<br/>
          <span className='blue'>Units:</span> Acres<br/>
          <span className='blue'>Comments:</span> Refers to min acreage to other new drills.<br/>
        </div>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> Use Trajectory<br/>
          <span className='blue'>Options:</span> Trajectory, Surface, BottomHole<br/>
          <span className='blue'>Comments:</span> Specifies whether to create the spacing grid around the entire trajectory of wells, the surface locations of the wells, or the bottomholes of the wells.<br/>
        </div>
        <div className='image-container'>
          <img src={MapGenerationA} className='tooBig' alt="" />
        </div>
        <div className="content-row" style={{'textAlign': 'center'}}> 
          Yellow shows where wells can be drilled, and blue are shows where wells cannot be drilled. By choosing Trajectory, we are specifying that nothing should be drilled close to the entire trajectory, even if it is close to the head of the well. For Bottom hole, we are specifying that wells can be drilled near the head of the well, because the oil isn’t near the head, and it is at the bottom of the well. Surface hold method specifies that wells can't be drilled around the surface but can drilled near the bottom.
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Faults
        </div>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> Create distance-to-fault (DTF) Index Maps<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> If on, creates an index grid that will be used to limit new drill locations to either a certain distance away from or close to a fault.<br/>
        </div>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> Stay Away<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> If on, sets the index grid to limit new drills to stay away from faults.<br/>
        </div>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> Fault Distance Cutoff<br/>
          <span className='blue'>Options:</span> Distance Cutoff<br/>
          <span className='blue'>Comments:</span> Distance cutoff to faults.<br/>
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Other
        </div>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> Contact Standoff<br/>
          <span className='blue'>Options:</span> Meters<br/>
          <span className='blue'>Comments:</span> Contact standoff for new opportunities.<br/>
        </div>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> Target Thickness Cutoff<br/>
          <span className='blue'>Options:</span> Meters<br/>
          <span className='blue'>Comments:</span> Minimum zone thickness within current oil window.<br/>
        </div>


      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(MapGeneration)
