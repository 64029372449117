import React from 'react'

import img1 from './static/WellGroups1.png'
import img2 from './static/WellGroups2.png'

const WellGroups = (
  <div className={'description-container'}>
    <p>The “Well_Groups.csv” template is a list of wells that you wish to categorize into a group. Multiple groups (GroupSet) can be defined. Each well can appear in multiple groups (GroupSet) but only once per group (GroupSet). For example, you may wish to categorize wells by fault block, well type, area, etc. If you upload this template, all columns need to be filled out.</p>
    <p><b>Content:</b> Groups wells based on user defined criteria.</p>
    <p><b>Utility:</b> This optional template is used in the automated decline curve analysis for clustering and type-curve generation purposes.</p>
    <p><b>File Format:</b> CSV template (downloadable) (example shown in Figure 4).</p>
    <p><b>Units:</b> NA</p>
    <p><b>Other Note:</b> All columns are required. See Table 4 for specifics.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 4. Example of the first few rows of a “Well_Groups.csv.”</figcaption>
    </figure>
    <figure>
      <figcaption>Table 4. Details for the “Well_Groups.csv.”</figcaption>
      <img src={img2} />
    </figure>
  </div>
)

export default WellGroups