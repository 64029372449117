import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Summary from '../Summary/Summary'
import Petrophysical from '../../Petrophysical/Petrophysical'
import ProductionForecast from '../ProductionForecast/ProductionForecast'
import ModelAnalysis from '../../ModelAnalysis/ModelAnalysis'
import RelativePOS from '../RelativePOS/RelativePOS'

const OpportunityRoutes = ({ 
  map,
	user,
  caseId,
  datasetId,
  wellData,
  structureZMatrix,
  structureGridX,
  structureGridY,
  faultData,
  caseObj,
  singleOpportunity,
  time,
  oilRate,
  waterCut,
  gor,
  logData,
  offsetWells,
  selectedWellData,
  neighborLogs,
  analogNeighborData,
  structSectionDataNS,
  structSectionDataEW,
  wellId, 
  gridData,
  zone,
  zones,
  selectedZone,
  updateOpportunity,
  modelSectionData,
  selectedOffsetWells,
  handleSelectNeighbor,
  handleUnselectNeighbor,
  handleUpdateZone,
  setCurPage,
}) => (
  <React.Fragment>
    <Route exact path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId" user={user} render={() => 
      (
        <Summary
          map={map}
          wellData={wellData}
          setCurPage={setCurPage}
          singleOpportunity={singleOpportunity}
          caseObj={caseObj}
          caseId={caseId}
          time={time}
          oilRate={oilRate}
          waterCut={waterCut}
          gor={gor}
          updateOpportunity={updateOpportunity}
          modelSectionData={modelSectionData}
        />
      )}
    />
    <Route path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId/petrophysical" user={user} render={() => 
      (
        <Petrophysical 
          map={map}
          setCurPage={setCurPage}
          caseObj={caseObj}
          singleOpportunity={singleOpportunity}
          logData={logData}
          offsetWells={offsetWells}
          selectedWellData={selectedWellData}
          neighborLogs={neighborLogs}
          selectedOffsetWells={selectedOffsetWells}
          handleSelectNeighbor={handleSelectNeighbor}
          handleUnselectNeighbor={handleUnselectNeighbor}
        />
      )} 
    />
    <Route path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId/model" user={user} render={() => 
      (
        <ModelAnalysis 
          map={map}
          setCurPage={setCurPage}
          wellData={wellData}
          structSectionDataNS={structSectionDataNS}
          structSectionDataEW={structSectionDataEW}
          wellId={wellId}
          gridData={gridData}
          modelSectionData={modelSectionData}
          singleOpportunity={singleOpportunity}
        />
      )} 
    />
    <Route path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId/pos" user={user} render={() => 
      (
        <RelativePOS 
          map={map}
          structureZMatrix={structureZMatrix}
          structureGridX={structureGridX}
          structureGridY={structureGridY}
          faultData={faultData}
          setCurPage={setCurPage}
          wellData={wellData}
          wellId={wellId}
          gridData={gridData}
          caseObj={caseObj}
          singleOpportunity={singleOpportunity}
          caseId={caseId}
          datasetId={datasetId}
          zones={zones}
          zone={zone}
          selectedZone={selectedZone}
          handleUpdateZone={handleUpdateZone}
        />
      )} 
    />
    <Route path="/:application/projects/:projectId/cases/:caseId/visualization/opportunities/:opportunityId/production" user={user} render={() => 
      (
        <ProductionForecast 
          map={map}
          caseId={caseId}
          caseObj={caseObj}
          wellId={wellId}
          setCurPage={setCurPage}
          wellData={wellData}
          singleOpportunity={singleOpportunity}
          analogNeighborData={analogNeighborData}
          handleUpdateZone={handleUpdateZone}
          selectedZone={zone}
        /> 
      )} 
    />
  </React.Fragment>
)

const mapStateToProps = state => ({
  user: state.get('user'),
})

export default withRouter(connect(mapStateToProps)(OpportunityRoutes))
