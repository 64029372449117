import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import {withStyles} from '@material-ui/core/styles'

import Link from '@material-ui/core/Link'

import moment from 'moment'

import Typography from '@material-ui/core/Typography'
import SelectedWellsChip from '../Assessment/SelectedWellsChip'
import SelectWellsDropdown from '../Assessment/SelectWellsDropdown'

import TimelineSlider from '../../../common/TimelineSlider/TimelineSlider'
import { setTimeline } from '../../../../redux/actions/waterfloodCase'

const axisProps = {
  horizontal: {
    offset: (percent) => ({ left: `${percent}%` }),
    leap: (percent) => ({ width: `${percent}%` }),
  },
}

const styles = (theme) => ({
  root: {
    position: 'absolute',
    top: 15,
    width: '100%',
  },
  mark: {
    position: 'absolute',
    width: 1,
    height: 6,
    marginTop: -2,
    borderRadius: 1,
    backgroundColor: '#3b86ff',
  },
})

@autobind class TimeSeriesDataMarker extends Component {
  constructor(props) {
    super(props)
  }

  clamp(value, min, max) {
    return Math.min(Math.max(min, value), max);
  }
  
  valueToPercent(value, min, max) {
    return ((value - min) * 100) / (max - min);
  }
  
  percentToValue(percent, min, max) {
    return (max - min) * percent + min;
  }

  render() {    
    const { markers, classes, min, max } = this.props
    const trackOffset = this.valueToPercent(min, min, max)
    const trackLeap = this.valueToPercent(max, min, max) - trackOffset
    const trackStyle = {
    ...axisProps['horizontal'].offset(trackOffset),
    ...axisProps['horizontal'].leap(trackLeap),
  }
    return (
      <div style={trackStyle} className={classes.root}>
          {markers.map((marker, index) => {
            const percent = this.valueToPercent(marker.value, min, max);
            const style = axisProps['horizontal'].offset(percent);
            return (
                <span 
                  style={style}
                  className={classes.mark}
                />
            )
          })}
      </div>
    )
  }
}

export default withStyles(styles)(TimeSeriesDataMarker)
