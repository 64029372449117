import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class TargetSearch extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Target Search for Horizontals
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          This step uses a global search algorithm to find optimal targets. The main objective is to maximize a given user-provided attribute. By default, the algorithm maximizes the sum of the RPOS attribute along a given target length. Initially, the algorithm identifies a set of target cells from where it will start the search. These are cells that meet a set of user-defined attribute cutoffs (e.g., targets with cells having RPOS higher than the 90th percentile and at least 200 meters away from faults).
        </div>
        <div className="content-row"> 
          <span className='blue'>Model Resampling:</span> Prior to the start of the search, the 3D model is resampled to a uniform structure. This is because input models (exported from Petrel) frequently come in a non-structured, non-uniform 3D grid where cell dimensions are not consistent across all cells, pillars are not truly vertical, and slices along the i or j vectors are not straight planes. Using such a model during the search will make the search incredibly slow because the index lookup (finding the ijk indices of a given xyz point) requires a full 3D search that runs in polynomial time. Because index lookups are used countless times during the search (to identify the properties of the cells penetrated by targets), the inefficiency becomes even more pronounced. To speed up the search, this module will resample the input model to an orthogonal uniform 3D grid where cell dimensions are always consistent. This leads to a little loss of information since some input cells will be aggregated, but the computational and efficiency gains largely compensate for it.
        </div>
        <div className="content-row"> 
          <span className='blue'>Search Constraints:</span> The search algorithm can be fed multiple types of constrains such as a length range, azimuth range, and deviation range. From the initial scan cells, the algorithm will only consider targets that honor these constrains. For each such target, the algorithm also performs a validation step that makes sure it honors various path constrains. These path constrains are also user-provided and determine whether a given target is allowed to cross stratigraphic zones, fault surfaces, or baffle layers. This validation step also checks the target meets the minimum fraction of target cells along its path. For each initial scan cell, the algorithm can save a user-provided number of best targets that maximize or minimize the objective attribute (e.g., maximize the RPOS along the length of the target).
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(TargetSearch)
