import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Help extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          How to get Help and Send Requests
        </div>
        <div className="content-row"> 
          This tool has been designed to be a user-friendly way of putting you, the expert in your field, in control of opportunity generation. To aid you as you drive this tool, we have provided:
        </div>
        <div className="content-row"> 
          <span className='blue'>Video Gallery:</span> The video gallery can be accessed under support, or on the bottom of the home page and include short, how-to tutorials that are generally 1-2 minutes long.
        </div>
        <div className="content-row"> 
          <span className='blue'>SRO User Manual (Online Version):</span> Several user guides have been written to help you understand and execute the process of generating your opportunity inventory. The main user manual is accessible from the top navigation under “Support” or can be found on the bottom of the home page. Information on settings and execution can be found under the “Modules” section of the user manual. The complete manual can also be downloaded under “Resources” (described below).
        </div>
        <div className="content-row"> 
          <span className='blue'>Resources:</span> Resources can be found from the top navigation under Support or near the bottom of the home page. Available resources include:
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>SpeedWise Data Preparation Manual:</span> Shows you what data is necessary to run the modules and also contains helpful tutorials for preparing the data.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>SpeedWise Reservoir Opportunity User Manual (pdf):</span> provides step-by-step instructions for using the software, from data loading to execution to visualizing the results and collaborating on vetting them with others in your asset team.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>QRI Publications</span>
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>QRI RESQML zip file</span>
        </div>
        <div className="content-row"> 
          <span className='blue'>Direct User Support:</span> If you would like to contact someone in user support, you may also do so by clicking the “Support” dropdown menu on the top right.
        </div>
        <div className="content-row"> 
          User support can only be given to users who have registered on Emerson’s Customer Portal. To register for Emerson’s customer support, visit https://pdgm.custhelp.com/app/home/, click on “Sign Up”, and fill out the required information. Once you are a registered user, you can send a request on Emerson’s portal by clicking “Ask a Question,” or by directly emailing Emerson’s customer support email at customersupport@emerson.com.
        </div>
        <div className="content-row"> 
          If you are working with someone from QRI/Emerson support, you may be asked to share your workspace with the support team, so your QRI/Emerson support person will have access your project in order to help you problem-solve or provide suggestions. This will be covered in the section on Workspaces.
        </div>
        <div className="content-row"> 
          <span className='blue'>Popup Guides:</span> Useful hints and guides can be found at every step of the way by clicking on the “?” icons. Some common places in the application that you can find additional guidance or help on are:
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Inside a case workflow:</span>
        </div>
        <div className="content-row"> 
          &ensp;&ensp;◦ Settings – clicking on the question mark will lead you to additional support and documentation for each setting.
        </div>
        <div className="content-row"> 
          &ensp;&ensp;◦ Execution Steps – each question mark can better explain the execution step.
        </div>
        <div className="content-row"> 
          &ensp;&ensp;◦ Visualization – certain visualization charts or maps may have a question mark to better explain its purpose.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Dataset:</span>
        </div>
        <div className="content-row"> 
          &ensp;&ensp;◦ Dataset Files – each file for the dataset category will have a detailed description.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Help)
