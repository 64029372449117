import React from 'react'

const WellboreDiagrams = (
  <div className={'description-container'}>
    <p><b>Content:</b> Wellbore diagrams are graphical representation of the latest state of a well’s physical configuration.</p>
    <p><b>Utility:</b> This is used in the recompletions module to check for wellbore feasibility. It is not required data; if this data is incomplete or missing, then the automated wellbore feasibility step will be skipped for the well(s) missing this data.</p>
    <p><b>File Format:</b> PNG. Each wellbore diagram needs to have the same well name as in “Well_Master.csv.”</p>
    <p><b>Units:</b> NA</p>
  </div>
)

export default WellboreDiagrams