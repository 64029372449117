import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { Route, withRouter, Redirect, Switch, Router } from 'react-router-dom'
import { connect } from 'react-redux'

import Resources from './Resources'
import Videos from './Videos'
import UserManual from '../UserManual/UserManual'
import NavigationTabs from './NavigationTabs'

import './Training.scss'

const baseClass = 'training-module'
const containerClass = `${baseClass}__container`
const navContainerClass = `${baseClass}__nav`

@autobind class Training extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className={baseClass}>
        <div className={containerClass}>
          <div className={navContainerClass}>
            <NavigationTabs />
          </div>
          <Switch>
            <Route path="/training/videos" component={Videos} />
            <Route path="/training/resources" component={Resources} />
            <Route path='/training/manual/' component={UserManual} />
          </Switch>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Training)
