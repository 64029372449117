import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const successfulClass = 'successful'
const failedClass = 'failed'
const currentClass = 'current'

@autobind class Step extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { label, step, successfullyRan, failed, currentlyRunning, settingsDisabled, handleRunModule, disabled, queued, setSelectedStep } = this.props

    const completedStyle = {}
    const currentStyle = {}

    const successfulCompletedStyle = {
      width: '100%',
      background: '#52AB7F',
      borderRadius: '15px',
    }

    const successfulCurrentStyle = {
      width: '0px',
      transition: 'width 0s',
    }

    if (currentlyRunning) {
      completedStyle.width = '10px'
      currentStyle.width = 'calc(100% - 20px)'
    }

    if (failed) {
      currentStyle.width = '0%'
      currentStyle.transition = 'width 0s'
      completedStyle.background = '#EA757A'
      completedStyle.width = '100%'
      completedStyle.borderRadius = '15px'
    }

  
    const runStyle = {
      cursor: 'pointer'
    }
    const runFontStyle = {
    }

    const settingsStyle = {
      cursor: 'pointer'
    }
    const settingsFontStyle = {
    }

    if (disabled) {
      runStyle.color = '#A7A9AE',
      runFontStyle.color = '#A7A9AE',
      runStyle.cursor = 'default'
    }

    return (
      <div className="step">
        <div className="step-header">
          <div className={`step-label ${failed ? failedClass : ''}`}>
            {label}{queued ? ' - In Queue' : ''}
            {failed && (
                <div style={{ cursor: 'pointer' }} onClick={() => setSelectedStep('error')}>
                  <span className="error-icon" >
                    <FontAwesomeIcon icon="exclamation-triangle" />
                  </span>
                  <a className="error-text">Show Errors </a>
                </div>
              )
            }
          </div>
          <div className="action" style={settingsFontStyle}>
            <span style={settingsStyle} onClick={() => setSelectedStep(step)}>
              <FontAwesomeIcon icon="question-circle" className="action-button-icon" />
            </span>
          </div>
        </div>
        <div className="step-progress-bar">
          {
              <React.Fragment>
                <div className="completed" style={successfullyRan ? successfulCompletedStyle : completedStyle}>
                </div>
                <div className={`current recompletion-${step}`} style={successfullyRan ? successfulCurrentStyle : currentStyle}>
                </div>
              </React.Fragment>
          }
        </div>
      </div>
    )
  }
}

          // <div className="action" style={runFontStyle}>
          //   <span style={{paddingRight: '3px'}}>Run</span>
          //   <span style={runStyle} onClick={disabled ? () => console.log('hi') : () => handleRunModule(step, 1)}>
          //     <FontAwesomeIcon icon="play" className="action-button-icon" />
          //   </span>
          // </div>


export default withRouter(Step)
