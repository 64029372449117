import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Select from 'react-select'
import KPI from '../../../common/KPI'
import { dealWithNaN } from '../../../../../../lib/helpers'
import API from '../../../../../../lib/api-store'

import DepthRange from '../../../../../../../static/images/oppDetailsDepthRangeIcon.svg'
import HCPT from '../../../../../../../static/images/oppDetailsHCPTIcon.svg'
import ipNFR from '../../../../../../../static/images/oppDetailsIPNfrIcon.svg'
import MappingRisk from '../../../../../../../static/images/oppDetailsMappingRiskIcon.svg'
import NeighborGOR from '../../../../../../../static/images/oppDetailsNeighborGORIcon.svg'
import NeighborP10 from '../../../../../../../static/images/oppDetailsNeighborP10Icon.svg'
import NeighborP90 from '../../../../../../../static/images/oppDetailsNeighborP90Icon.svg'
import NeighborWCT from '../../../../../../../static/images/oppDetailsNeighborWCTIcon.svg'
import Target from '../../../../../../../static/images/oppDetailsTargetIcon.svg'
import Unp from '../../../../../../../static/images/oppDetailsUnpIcon.svg'
// import Tier from '../../../../../static/images/oppDetailsTierIcon.svg';
import Tier from '../../../../../../../static/images/tier.png'



const tierOptions = [
  { label: 'Low Risk', value: 'Low Risk' },
  { label: 'Mid Risk', value: 'Mid Risk' },
  { label: 'High Risk', value: 'High Risk' },
  { label: 'Discard', value: 'Discard' },
  { label: 'Infeasible', value: 'Infeasible' },
]

@autobind class OpportunityKPIs extends PureComponent {
  handleTierChange(value) {
    const { data, updateOpportunity, caseId } = this.props
    const tier = value.value
    API.changeOpportunityTier(tier, data._id, caseId)
      .then(() => {
        updateOpportunity(tier, data._id)
      })
  }

  render() {
    let { data, caseObj } = this.props

    // TODO REMOVE THIS, find out why data is ever null
    if (!data) {
      data = {}
    }

    const heel = {
      x: data.Trajectory.x[0].toFixed(0),
      y: data.Trajectory.y[0].toFixed(0),
      z: data.Trajectory.z[0].toFixed(0),
    }

    const toe = {
      x: data.Trajectory.x[data.Trajectory.x.length-1].toFixed(0),
      y: data.Trajectory.y[data.Trajectory.y.length-1].toFixed(0),
      z: data.Trajectory.z[data.Trajectory.z.length-1].toFixed(0),
    }

    let risk
    if (!data.risk) risk = 'Unknown'
    else if (data.risk < 50) risk = 'Low Risk'
    else if (data.risk < 80) risk = 'Moderate Risk'
    else risk = 'High Risk'


    // const analogStats = data.analogNeighborStats
    // const currentStats = data.currentNeighborStats
    const method = caseObj.settings.productionForecast.productionForecast.primaryMethod

    let displayedTierOptions = [...tierOptions]

    let tierOption = tierOptions.find(i => i.value == data.tier)
    if (tierOption) {
      displayedTierOptions.push({ label: 'No Tier', value: null})
    }

    return (
      <div className="kpis">
        <div className="KPI">
          <div className="icon">
            <img alt="" src={Tier} style={{ paddingLeft: '3px', height: '34px' }} />
          </div>
          <div className="text">
            Tier
          </div>
          <div className="val" style={{ lineHeight: '15px', paddingTop: '3px' }}>
            <Select
              options={displayedTierOptions}
              onChange={this.handleTierChange}
              isMulti={false}
              value={tierOption}
            />
          </div>
        </div>
        <KPI className="wide" icon={NeighborGOR} header="Heel(x,y,z)" value={`(${heel.x}, ${heel.y}, ${heel.z})`} />
        <KPI className="wide" icon={NeighborGOR} header="Toe(x,y,z)" value={`(${toe.x}, ${toe.y}, ${toe.z})`} />
        <KPI className="" icon={NeighborP10} header="Azimuth" value={`${dealWithNaN(data.azimuth).toFixed(2)}°`} />
        <KPI className="" icon={NeighborP90} header="Deviation" value={`${dealWithNaN(data.deviation).toFixed(2)}°`} />
        <KPI className="" icon={Unp} header="Length" value={`${dealWithNaN(data.length).toFixed(2)} m`} />
        <KPI className="" icon={Target} header="Net Pay" value={`${dealWithNaN(data.netPay).toFixed(2)} m`} />
        <KPI className="" icon={HCPT} header="HCPV" value={`${dealWithNaN(data.hcpv).toFixed(2)} m³`} />
        <KPI className="" icon={ipNFR} header={`IP Forecast - ${method}`} value={`${dealWithNaN(data.predictedIp).toFixed(2)} bbl/d`} />
        <KPI className="" icon={NeighborWCT} header="Neighborhood WCT" value={`${dealWithNaN(data.recentWct * 100).toFixed(1)}%`} />
        <KPI className="" icon={DepthRange} header="GOR" value={`${dealWithNaN(data.recentGor).toFixed(2)} scf/bbl`} />
      </div>
    )
  }
}

export default OpportunityKPIs
