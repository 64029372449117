import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Collapsible from 'react-collapsible'
import Select from 'react-select'

import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import API from '../../../lib/api-store'
import './LicenseRemoveUser.scss'

const baseClass = 'licenseRemoveUser'
const containerClass = `${baseClass}__container`
const headerClass = `${containerClass}__header`
const subHeaderClass = `${containerClass}__subHeader`
const workspaceCard = `${containerClass}__card`
const ownerTasksClass = `${workspaceCard}__owner`
const privateTasksClass = `${workspaceCard}__private`
const sharedTasksClass = `${workspaceCard}__shared`
const toggleClass = `${baseClass}__toggle`
const toggleOptionClass = `${toggleClass}__option`
const toggleOptionTextClass = `${toggleClass}__text`
@autobind class LicenseRemoveUser extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      workspacesWithTasks: [],
      workspaceDeleteToggles: {},
      workspacePrivateToggles: {},
      workspaceSelectedSharedCases: {},
      workspaceSelectedSharedDatasets: {},
      workspaceAgreeDeletePrivate: {},
      workspaceAgreeDeletePublic: {},
      workspaceNewOwner: {},
      loaded: false,
      license: null,

    }
  }

  componentDidMount() {
    this.fetchTasks()
    this.fetchLicense()
  }


  fetchLicense() {
    const { match } = this.props
    const { licenseId } = match.params

    API.getLicense(licenseId)
      .then(license => {
        this.setState({
          license
        })
    })
  }

  fetchTasks() {
    const { workspaceDeleteToggles, workspaceNewOwner, workspacePrivateToggles, workspaceSelectedSharedDatasets,workspaceSelectedSharedCases, workspaceAgreeDeletePublic, workspaceAgreeDeletePrivate } = this.state
    const { match } = this.props
    const { licenseId, userId } = match.params

    API.fetchTasksForRemovingUser(userId, licenseId)
      .then(tasks => {
        tasks.forEach(i => {
          if (!(Object.keys(workspaceDeleteToggles)).includes(i.id)) {
            workspaceDeleteToggles[i.id] = false
          }
          if (!(Object.keys(workspaceNewOwner)).includes(i.id)) {
            workspaceNewOwner[i.id] = null
          }
          if (!(Object.keys(workspacePrivateToggles)).includes(i.id)) {
            workspacePrivateToggles[i.id] = null
          }
          if (!(Object.keys(workspaceSelectedSharedDatasets)).includes(i.id)) {
            workspaceSelectedSharedDatasets[i.id] = []
          }
          if (!(Object.keys(workspaceSelectedSharedCases)).includes(i.id)) {
            workspaceSelectedSharedCases[i.id] = []
          }
          if (!(Object.keys(workspaceAgreeDeletePrivate)).includes(i.id)) {
            workspaceAgreeDeletePrivate[i.id] = false
          }
         if (!(Object.keys(workspaceAgreeDeletePublic)).includes(i.id)) {
            workspaceAgreeDeletePublic[i.id] = false
          }
        })

        this.setState({
          workspacesWithTasks: tasks,
          workspaceDeleteToggles,
          workspacePrivateToggles,
          workspaceNewOwner,
          workspaceSelectedSharedDatasets,
          workspaceSelectedSharedCases,
          workspaceAgreeDeletePrivate,
          workspaceAgreeDeletePublic,
          loaded: true,
        })
      })
  }

  handlePrivateCheck(workspaceId) {
    let  { workspaceAgreeDeletePrivate } = this.state
    let newWorkspaceAgreeDeletePrivate = {...workspaceAgreeDeletePrivate}

    newWorkspaceAgreeDeletePrivate[workspaceId] = !(workspaceAgreeDeletePrivate[workspaceId])

    this.setState({
      workspaceAgreeDeletePrivate: newWorkspaceAgreeDeletePrivate
    })
  }

  handlePublicCheck(workspaceId) {
    let { workspaceAgreeDeletePublic } = this.state
    let newWorkspaceAgreeDeletePublic = {...workspaceAgreeDeletePublic}
    
    newWorkspaceAgreeDeletePublic[workspaceId] = !(workspaceAgreeDeletePublic[workspaceId])
    this.setState({
      workspaceAgreeDeletePublic: newWorkspaceAgreeDeletePublic
    })
  }

  handleDeleteWorkspace(workspaceId) {
    API.deleteProject(workspaceId)
      .then(res => {
        this.fetchTasks()
      })
  }


  handleRemoveUserFromLicense() {
    const { match } = this.props
    const { userId, licenseId } = match.params

    API.removeLicenseUser(userId, licenseId)
      .then( res => {
            this.props.history.push(`/licenses/${licenseId}`)
      })
  }

  handleNewWorkspaceOwnerClick(workspaceId) {
    const { workspaceNewOwner } = this.state
    const { match } = this.props
    const { licenseId } = match.params


    API.changeProjectOwner(workspaceId, workspaceNewOwner[workspaceId], licenseId)
      .then(res => {
        console.log('workspace changed')
        this.fetchTasks()
      })
  }

  handlePrivateAssetsSelection(workspace) {
    const { workspacePrivateToggles } = this.state
    const { match } = this.props
    const { licenseId } = match.params
    const workspaceId = workspace.id

    let datasetIds = workspace.privateDatasets.map(i => i.id)
    let caseIds = workspace.privateCases.map(i => i.id)


    if (workspacePrivateToggles[workspaceId] == 'delete') {
      API.deleteCasesAndDatasets(licenseId, workspaceId, datasetIds, caseIds)
        .then(res => {
          console.log('deleted')
          this.fetchTasks()
        })
    }  else {
      API.markSaveCasesAndDatasets(licenseId, workspaceId, datasetIds, caseIds)
        .then(res => {
          console.log('marked')
          this.fetchTasks()
        })
    }
  }


  handlePublicAssetsSelection(workspace, caseOptions, datasetOptions) {
    const { workspaceSelectedSharedCases, workspaceSelectedSharedDatasets } = this.state
    const { match } = this.props
    const { licenseId } = match.params
    const workspaceId = workspace.id

    console.log(workspaceSelectedSharedDatasets)
    console.log(workspaceSelectedSharedCases)




    console.log(caseOptions)
    console.log(datasetOptions)

    const deleteDatasetIds = workspaceSelectedSharedDatasets[workspaceId].map(i => i.value)
    const deleteCaseIds = workspaceSelectedSharedCases[workspaceId].map(i => i.value)

    const markDatasetIds = datasetOptions.map(i => i.value).filter(i => !(deleteDatasetIds.includes(i)))
    const markCaseIds = caseOptions.map(i => i.value).filter(i => !(deleteCaseIds.includes(i)))
    console.log('eyeye', deleteDatasetIds, deleteCaseIds)
    console.log('eyeye', markDatasetIds, markCaseIds)



    API.deleteCasesAndDatasets(licenseId, workspaceId, deleteDatasetIds, deleteCaseIds)
      .then(res => {
        console.log('deleted')
        API.markSaveCasesAndDatasets(licenseId, workspaceId, markDatasetIds, markCaseIds)
          .then(res => {
            console.log('marked')
            this.fetchTasks()
          })
      })

  }


  handleWorkspaceSelectedCaseChange(e, workspaceId) {
    const { workspaceSelectedSharedCases } = this.state
    let newWorkspaceSelectedSharedCases = {...workspaceSelectedSharedCases}
    if (!e) {
      e = []
    }
    newWorkspaceSelectedSharedCases[workspaceId] = e

    console.log(newWorkspaceSelectedSharedCases)




    this.setState({
      workspaceSelectedSharedCases: newWorkspaceSelectedSharedCases
    })
  } 

  handleWorkspaceSelectedDatasetChange(e, workspaceId) {
    const { workspaceSelectedSharedDatasets } = this.state
    let newWorkspaceSelectedSharedDatasets = {...workspaceSelectedSharedDatasets}
    if (!e) {
      e = []
    }
    newWorkspaceSelectedSharedDatasets[workspaceId] = e

    console.log(newWorkspaceSelectedSharedDatasets)

    this.setState({
      workspaceSelectedSharedDatasets: newWorkspaceSelectedSharedDatasets
    })
  } 

  handleWorkspaceSelectionChange(e, workspaceId) {
    const { workspaceNewOwner } = this.state
    let newWorkspaceNewOwner = {...workspaceNewOwner}
    newWorkspaceNewOwner[workspaceId] = e.value

    this.setState({
      workspaceNewOwner: newWorkspaceNewOwner
    })
  } 

  makeWorkspaceOwnerTask(workspace) {
    const { license, workspaceNewOwner } = this.state
    const { match } = this.props
    const { licenseId, userId } = match.params

    let val = workspaceNewOwner[workspace.id]
    let options = license.currentUsers.map(i => ({
      label: i.name, value: i.id
    })).filter(i => i.value !== userId)

    return (
      <ExpansionPanel
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="mini-header">Assign New Workspace Owner</div>
          <div className="mini-body">Choose from a list of collaborators on this workspace to assign as the new workspace owner.</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="mini-body">
            <Select
              options={options}
              value={options.find(i => i.value == val)}
              onChange={e => this.handleWorkspaceSelectionChange(e, workspace.id)}
            />
          </div>
          <div className="mini-body">
            <Button
              className={'qri-button primary'}
              disabled={!val}
              onClick={() => this.handleNewWorkspaceOwnerClick(workspace.id)}
            >
              Change Owner
            </Button>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  makeDeleteProject(workspace) {
    const { workspaceAgreeDeletePublic, workspaceAgreeDeletePrivate } = this.state
    let workspaceId = workspace.id

    let publicCheck = workspaceAgreeDeletePublic[workspaceId]
    let privateCheck = workspaceAgreeDeletePrivate[workspaceId]

    console.log(publicCheck)
    console.log(privateCheck)
    console.log(workspace)



    return (
      <div className="parent">
        <div className="license-spacing">
          This workspace has the following datasets and cases
        </div>
        <div className="license-spacing">
          {workspace.totalPrivateCases || 0} total private cases, and {workspace.totalPrivateDatasets || 0} total private datasets by users in this workspace
        </div>
        <div className="license-spacing">
          {workspace.totalSharedCases || 0} total shared cases, and {workspace.totalShareDatasets || 0} total shared datasets by users in this workspace
        </div>

        <div className="license-spacing">
          <input
            type='checkbox'
            checked={privateCheck}
            onClick={() => this.handlePrivateCheck(workspace.id)}
          />
          {" I understand that deleting this workspace will delete all private cases and datsets that any user has created in this workspace"}
        </div>
        <div className="license-spacing">
          <input
            type='checkbox'
            checked={publicCheck}
            onClick={() => this.handlePublicCheck(workspace.id)}
          />
          {" I understand that deleting this workspace will delete all shared cases and datsets any user has created in this workspace"}
        </div>
        <Button
          className={'qri-button primary'}
          disabled={!publicCheck || !privateCheck}
          onClick={() => this.handleDeleteWorkspace(workspaceId)}
        >
          Delete Workspace
        </Button>
      </div>
    )
    
  }

  makePrivateToggle(workspaceId) {
    const { workspacePrivateToggles } = this.state 
    const val = workspacePrivateToggles[workspaceId]

    return (
      <div className={toggleClass}>
        <div className={toggleOptionClass}>
          <label>
            <input 
              type="radio"
              value="keep"
              checked={val == 'keep'}
              onChange={() => {
                let newWorkspacePrivateToggles = {...workspacePrivateToggles}
                newWorkspacePrivateToggles[workspaceId] = 'keep'
                this.setState({
                  workspacePrivateToggles: newWorkspacePrivateToggles
                })
              }}
            />
            <span className={toggleOptionTextClass + " license-spacing"}>Keep for the user in case their profile is activated again</span>
            <div className={toggleOptionTextClass + " license-spacing"}>Keeping cases and datasets will count against your total TB usage.  You can delete this any time in the future.</div>
          </label>
        </div>
        <div className={toggleOptionClass}>
          <label>
            <input
              type="radio"
              value="delete"
              checked={val == 'delete'}
              onChange={() => {
                let newWorkspacePrivateToggles = {...workspacePrivateToggles}
                newWorkspacePrivateToggles[workspaceId] = 'delete'
                this.setState({
                  workspacePrivateToggles: newWorkspacePrivateToggles
                })
              }}
            />
            <span className={toggleOptionTextClass}>Permanently delete</span>
            <div className={toggleOptionTextClass}>Permanently deleting cases and datsaets will free up your TB usage, but the user will not be able to access them again in the future if their account is reactivated.</div>
          </label>
        </div>
      </div>
    )
  }

  makeWorkspacePrivateTasks(workspace) {
    const  { workspacePrivateToggles } = this.state
    const val = workspacePrivateToggles[workspace.id]
    let pcLength = workspace.privateCases.length
    let pdLength = workspace.privateDatasets.length
    let pcNoun = pcLength > 1 ? 'cases' : 'case'
    let pdNoun = pdLength > 1 ? 'datasets' : 'dataset'
    let totalNoun = pdLength + pcLength > 1 ? 'these assets' : 'this asset'

    return (
      <ExpansionPanel
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="mini-header">Manage Users Private Cases and Datasets</div>
          <div className="mini-body">This user has private case(s) or dataset(s) that need to be kept or deleted</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="mini-header">
            Private Cases and Datasets
          </div>
          <div className="mini-body">
            This user has { pcLength > 0 && `${pcLength} private ${pcNoun}` }{pdLength >0 && pcLength > 0 && ' and '}{pdLength > 0 && `${pdLength} private ${pdNoun} `}created in this workspace.  What would you like to do with {totalNoun}?
          </div>
          {this.makePrivateToggle(workspace.id)}
          <div className="mini-body">
            <Button
              className={'qri-button primary'}
              disabled={!val}
              onClick={() => this.handlePrivateAssetsSelection(workspace)}
            >
              Complete Task
            </Button>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  makeWorkspaceSharedTasks(workspace) {
    let caseOptions = workspace.sharedCases.map(i => ({label: i.name, value: i.id}))
    let datasetOptions = workspace.sharedDatasets.map(i => ({label: i.name, value: i.id}))
    const { license, workspaceSelectedSharedCases, workspaceSelectedSharedDatasets } = this.state



    let datasetVal = workspaceSelectedSharedDatasets[workspace.id].map(i => i.value)
    let caseVal = workspaceSelectedSharedCases[workspace.id].map(i => i.value)


    console.log(caseVal)
    console.log(caseOptions)
    console.log(caseOptions.filter(i => caseVal.includes(i.value)))
    return (
      <ExpansionPanel
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="mini-header">Manage Users Shared Cases and Datasets</div>
          <div className="mini-body">This user has shared case(s) or dataset(s) that need to be kept or deleted.</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="license-spacing">
            The following cases and/or datasets have been shared with collaborators.  Permanently deleting them will free up the TB{"'"}s usage, but collaborators will lose access.
          </div>
          <div className="license-spacing">
            Shared Case(s)
          </div>
          <div className="license-spacing">
           Select the shared cases that you would like to delete.  Any cases that are not selected below will be kept for collaborators to view.
          </div>
            { caseOptions.length > 0 && (<Select
              options={caseOptions}
              isMulti={true}
              className="license-spacing"
              value={caseOptions.filter(i => caseVal.includes(i.value))}
              onChange={e => this.handleWorkspaceSelectedCaseChange(e, workspace.id)}
            />)}
            { caseOptions.length == 0 && (
              <div className="license-spacing"> No Unhandled Shared Cases </div>
            )}
          <div className="license-spacing">
            Shared Dataset(s)
          </div>
          <div className="license-spacing">
            Select the shared datsets that you would like to delete.  Any datasets that are not selected below will be kept for collaborators to view.
          </div>
            {datasetOptions.length > 0 && (<Select
              options={datasetOptions}
              isMulti={true}
              className="license-spacing"
              value={datasetOptions.filter(i => datasetVal.includes(i.value))}
              onChange={e => this.handleWorkspaceSelectedDatasetChange(e, workspace.id)}
            />)}
            {datasetOptions.length == 0 && (
              <div> No Unhandled Shared Datasets </div>)}
          <div>
            <Button
              className={'qri-button primary'  + " license-spacing"}
              onClick={() => this.handlePublicAssetsSelection(workspace, caseOptions, datasetOptions)}
            >
              Complete Task
            </Button>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }


  makeToggle(workspaceId) {
    const { workspaceDeleteToggles } = this.state 
    const val = workspaceDeleteToggles[workspaceId]


    return (
      <div className={toggleClass}>
        <div className={toggleOptionClass}>
          <label>
            <input 
              type="radio"
              value={'testVal'}
              checked={!val}
              onChange={() => {
                let newWorkspaceDeleteToggles = {...workspaceDeleteToggles}
                newWorkspaceDeleteToggles[workspaceId] = false
                this.setState({
                  workspaceDeleteToggles: newWorkspaceDeleteToggles
                })
              }}
            />
            <span className={toggleOptionTextClass}>Transfer Workspace(s) owner and save or delete Case(s) and Dataset(s)</span>
          </label>
        </div>
        <div className={toggleOptionClass}>
          <label>
            <input
              type="radio"
              value={'testVal2'}
              checked={val}
              onChange={() => {
                let newWorkspaceDeleteToggles = {...workspaceDeleteToggles}
                newWorkspaceDeleteToggles[workspaceId] = true
                this.setState({
                  workspaceDeleteToggles: newWorkspaceDeleteToggles
                })
              }}
            />
            <span className={toggleOptionTextClass}>Delete Workspace Created by User</span>
          </label>
        </div>
      </div>
    )
  }

  makeInside(workspace) {
    const { workspaceDeleteToggles } = this.state

    if (workspaceDeleteToggles[workspace.id]) {
      return this.makeDeleteProject(workspace)
    }

    return (
      <div>
        {workspace.owner && this.makeWorkspaceOwnerTask(workspace)}
        {(workspace.privateDatasets.length > 0 || workspace.privateCases.length > 0) && this.makeWorkspacePrivateTasks(workspace)}
        {(workspace.sharedDatasets.length > 0 || workspace.sharedCases.length > 0) && this.makeWorkspaceSharedTasks(workspace)}
      </div>
    )
  }

  makeTasks() {
    const { workspacesWithTasks, workspaceDeleteToggles } = this.state


    return workspacesWithTasks.map(workspace => {
      return (
          <ExpansionPanel
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography component="h3" variant="h6">{workspace.name}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              { workspace.owner && this.makeToggle(workspace.id)}
              { this.makeInside(workspace) }
            </ExpansionPanelDetails>
          </ExpansionPanel>
      )
    })

  }

  render() {
    const { loaded, workspacesWithTasks } = this.state
    const { match } = this.props
    const { userId, licenseId } = match.params



    return (
      <div className={baseClass}>
        <div className={containerClass}>
          <div className={headerClass}>
            Remove User
          </div>
          {userId && licenseId && (
            <div className={subHeaderClass} >
              The following tasks need to be completed in order to remove {userId} from {licenseId}
            </div>
          )}
          <div className={'task'}>
            {this.makeTasks()}
          </div>
          {(loaded && workspacesWithTasks.length == 0) && (
              <div> No Remaining Tasks </div>
          )}
          <div>
            <Button
              className={'qri-button primary'}
              disabled={!loaded || workspacesWithTasks.length > 0}
              tabIndex="0"
              onClick={this.handleRemoveUserFromLicense}
              onKeyPress={null}
              variant="contained"
              size="large"
              color="primary"
              fullWidth
            >
              <span>Remove User From License</span>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default withRouter(connect(mapStateToProps, null)(LicenseRemoveUser))
