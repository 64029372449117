import React from 'react'
import { ClipLoader } from 'react-spinners'
import { css } from '@emotion/core';

import DownloadOptions from './DownloadOptions'
import DownloadLink from './DownloadLink/DownloadLink'
import './Download.scss'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

const baseClass = `download-csv-route`
const headerClass = `${baseClass}__header`

const Download = (props) => { 

  const { caseObj } = props

  let downloadOptions
  let downloadOld

  if(caseObj){
    downloadOptions = DownloadOptions[caseObj.module]
    downloadOld = caseObj.oldDownload
  }

  //If old case, use old results
  if(downloadOld === true){
    downloadOptions = [
      {
        title: "Results",
        description: "This is the old 'Download Results.csv' file.  New cases will generate more detailed results downloads!",
        downloadType: "old",
      }
    ]
  }

  //Only show SML Training attributes if settings generated it
  // We are now always generating SML Training Attributes
  // if(caseObj){
  //   if(!(caseObj.settings.productionForecast.productionForecast.smlParameters === 1) && !downloadOld) {
  //     downloadOptions = downloadOptions.filter(d => d.downloadType !== 'mlTrainingData')
  //   }
  // }
  
  return (
    <div className={baseClass}>
      <div className={headerClass}>
        Download Options
      </div>
        {/* Have to check if the case object has been retrieved from back-end */}
        {!caseObj && (
          <div className="loading-background">
            <ClipLoader css={loaderCSS}/>
          </div>
        )}
        {!!caseObj && downloadOptions.length > 0 && (
          <>
            {downloadOptions.map(x => <DownloadLink
              {...x}
              caseId={caseObj.caseId}
              old={downloadOld}
              moduleName={caseObj.module}
              key={x.downloadType}
            />)}
          </>
        )}
        {!!caseObj && !downloadOptions.length && (
          <div className="missing-downloads">
            <h3>No Downloads Available</h3>
            <p>{`There are no downloadable files for the ${caseObj.module} module.`}</p>
          </div>
        )}
    </div>
  )
}

export default Download