import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class OtherProductionForecast extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Other Sweetspot Settings
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          <span className='blue'>Setting:</span> Max Number of Target Zones<br/>
          <span className='blue'>Options:</span> Integer<br/>
          <span className='blue'>Comments:</span> Max number of target zones for a single new drill.
        </div>

      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(OtherProductionForecast)
