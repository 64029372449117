import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'

import TableKPI from '../common/TableKPI'
import KPI from '../common/KPI'

@autobind class SummaryKPIs extends PureComponent {
  render() {
    const { data } = this.props
    const wellNames = []
    const recompletions = []
    let recompletionCount = 0
    const horizontal = []
    let horizontalCount = 0
    const newVertical = []
    let newVerticalCount = 0
    const unconventionals = []



    data.forEach((i) => {
      // totalIP += i.predictedIp
      if (!wellNames.includes(i.name)) {
        wellNames.push(i.name)
      }

      if (i.type === 'recompletion') {
        recompletionCount += 1
        if (!recompletions.includes(i.name)) {
          recompletions.push(i.name)
        }
      } else if (i.type === 'horizontalDrill') {
         horizontalCount += 1
         if (!horizontal.includes(i.name)) {
           horizontal.push(i.name)
         }
      } else if (i.type === 'verticalDrill') {
        newVerticalCount += 1
        if (!newVertical.includes(i.name)) {
          newVertical.push(i.name)
        }
      } else if(i.type === 'unconventionalDrill') {
        if (!unconventionals.includes(i.name)) {
          unconventionals.push(i.name)
        }
      }
    })

// <TableKPI className="border-right" header="Total Identified" value={`${wellNames.length} (${data.length})`} />
    return (
      <React.Fragment>
        <div className="kpis">
          {recompletionCount > 0 &&(
            <TableKPI 
              className=""
              header=""
              value={`${recompletionCount} recompletion opportunities found in ${recompletions.length} wells.`} 
            />
          )}
          {horizontalCount > 0 &&(
            <TableKPI 
              className=""
              header=""
              value={`${horizontalCount} horizontal targets found.`} 
            />
          )}
          {newVerticalCount > 0 &&(
            <TableKPI
              className=""
              header=""
              value={`${newVerticalCount} sweet spots found.`} 
            />
          )}
          {unconventionals.length > 0 &&(
            <TableKPI
              className=""
              header=""
              value={`${unconventionals.length } unconventional opportunities found.`} 
            />
          )}
        </div>
      </React.Fragment>
    )
  }
}


export default SummaryKPIs
