
import * as THREE from "three"

let PieObject = null
let radius= 1
let associated_well= null
let radius_reference= 600
let default_piesizepercentage= 100
let nominal_radius= null
let nominal_rate= 1000
let n_segment= 50
let loc= { x: 0, y: 0, z: 0} 
let color= {oil: 0x22FF22, wat: 0x2222FF, inj: 0x22FFFF}
let rate= {oil: 1, water: 1, injection: 1}
let EPS= 0.001
let randZ= 0
let objname= null
let info= null
let SceneVariables= { zExaggeration: 25 }

class PieChart {
  constructor(cfg) {
    cfg = cfg || {}

    this.randZ = cfg.randZ || (Math.random()*5-2.5)
    this.radius = cfg.radius || radius
    this.n_sgement = cfg.n_segment || n_segment
    this.loc = cfg.loc || loc
    this.color = cfg.color || color
    this.rate = cfg.rate || rate
    this.objname = cfg.objname || objname
    this.nominal_radius = cfg.nominal_radius || radius_reference
    this.SceneVariables = cfg.SceneVariables || SceneVariables
    this.n_segment = cfg.n_segment || n_segment
    this.nominal_rate = cfg.nominal_rate || nominal_rate

    this.associated_well = cfg.associated_well || associated_well
    this.name = this.associated_well ? this.associated_well.name : ''

    this.PieObject = this.generatePieObject(this.n_segment)
    this.PieObject.Pie = this
    this.PieObject.objtype = 'wellpie'
    this.PieObject.name = this.associated_well.name

    this.updatePieRate(this.rate)
    this.setHeight()
  }

  // eslint-disable-next-line class-methods-use-this
  generatePieObject(Nsegment) {
    const geometry = new THREE.Geometry()

    for (let i = 0; i < Nsegment; i++) {
      const theta = Math.PI / 2 + 2*Math.PI*i / Nsegment;
      geometry.vertices.push( new THREE.Vector3(Math.cos(theta), Math.sin(theta),0));
    }

    geometry.vertices.push(new THREE.Vector3(0,0,0)); //last point is the Origin

    for (let i = 0; i < Nsegment; i++) {
      geometry.faces.push( new THREE.Face3(Nsegment, i, (i + 1) % Nsegment))
    }

    geometry.faces.forEach((face) => {
      face.vertexColors.push( new THREE.Color().setHex(0x0f00ff))
      face.vertexColors.push( new THREE.Color().setHex(0x0f00ff))
      face.vertexColors.push( new THREE.Color().setHex(0x0f00ff))
    })

    return (new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({
        vertexColors: THREE.VertexColors,
        side: THREE.DoubleSide,
        polygonOffset: true,
        polygonOffsetFactor: -1.0,
        polygonOffsetUnits: -4.0,
        opacity: 1,
    })))
  }

  updatePieRate(rate) {
    let Pie = this.PieObject

    this.rate = rate
    this.rate.total = this.rate.oil + this.rate.water + this.rate.injection

    switch (rate.type) {
      case "U":
        this.info = this.name + " (undrilled)"
        break
      case "S":
        this.info = this.name + " (shut)"
        break
      case "I":
        this.info = this.name + " (Injector)</br>" +
            "Wat Injn = "+ rate.injection.toFixed()  + "[STB/D]"
        break
      default:
        this.info = this.name + " (Producer)</br>" +
            "Oil Prod  = "+ rate.oil.toFixed()        + "[STB/D] </br>" +
            "Wat Prod  = "+ rate.water.toFixed()      + "[STB/D] </br>" +
            "Water cut = "+ (100*rate.water/(rate.water+rate.oil)).toFixed(2) + "%"
    }


    this.setRadius(Math.sqrt(this.rate.total / this.nominal_rate) * this.nominal_radius)

    if (rate.injection > 0) { //injector
      Pie.geometry.faces.forEach((face) => {
        face.vertexColors[0].setHex(this.color.inj)
        face.vertexColors[1].setHex(this.color.inj)
        face.vertexColors[2].setHex(this.color.inj)
      })
    } else if (rate.oil + rate.water > 0) { //producer
      const division = Math.round(rate.oil / rate.total * this.n_segment)
      for (let i = 0; i < division; i++) {
        Pie.geometry.faces[i].vertexColors[0].setHex(this.color.oil)
        Pie.geometry.faces[i].vertexColors[1].setHex(this.color.oil)
        Pie.geometry.faces[i].vertexColors[2].setHex(this.color.oil)
      }

      for (let i = division; i < this.n_segment; i++) {
        Pie.geometry.faces[i].vertexColors[0].setHex(this.color.wat)
        Pie.geometry.faces[i].vertexColors[1].setHex(this.color.wat)
        Pie.geometry.faces[i].vertexColors[2].setHex(this.color.wat)
      }
    }

    Pie.geometry.colorsNeedUpdate = true
  }

  setRadius(Radius) {
    const Pie = this.PieObject

    if (Radius <= this.EPS) {
      this.radius = this.EPS
      //Pie.visible = false
    } else {
      this.radius = Radius
      //Pie.visible = true
    }
    Pie.scale.set(this.radius, this.radius, 1)
  }

  setHeight(zExaggeration) {
    const well = this.associated_well
    const Pie = this.PieObject

    if (zExaggeration) {
      this.SceneVariables.zExaggeration = zExaggeration
    }

    this.loc.x = well.geometry.attributes.position.getX(0) + 1
    this.loc.y = well.geometry.attributes.position.getY(0) + 1
    this.loc.z = well.geometry.attributes.position.getZ(0) - 1
    /*
    if (well.geometry.vertices) {
      this.loc.x = well.geometry.vertices[0].x
      this.loc.y = well.geometry.vertices[0].y
      this.loc.z = well.geometry.vertices[0].z
    }
    */

    Pie.applyMatrix4(new THREE.Matrix4().makeTranslation(this.loc.x, this.loc.y, this.loc.z))
    //Pie.position.set(this.loc.x, this.loc.y, this.loc.z /* this.zExaggeration + this.randZ */)
  }

  setPieSizePercentage(percentage) {
    this.nominal_radius = radius_reference * percentage / default_piesizepercentage
    this.updatePieRate(this.rate)
  }
}

export default PieChart