import React from 'react'

import img1 from './static/WellProductionLog1.png'
import img2 from './static/WellProductionLog2.png'

const WellProductionLog = (
  <div className={'description-container'}>
    <p><b>Content:</b> Stored production log test results.</p>
    <p><b>Utility:</b> For calibrating production data.</p>
    <p><b>Conventions:</b> It must be in mm/dd/yyyy format</p>
    <p><b>File Format:</b> CSV template provided (example shown in Figure 12).</p>
    <p><b>Units:</b> Template indicates what units are needed.</p>
    <p><b>Other Note:</b> Not all columns are required. See Table 12 for specifics.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 12: Example of the first few rows of a “Well_Production_Log.csv.”</figcaption>
    </figure>
    <figure>
      <figcaption>Table 12. Details for the “Well_Production_Log.csv.”</figcaption>
      <img src={img2} />
    </figure>
  </div>
)

export default WellProductionLog