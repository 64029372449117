import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { withRouter } from 'react-router-dom'

import ModifyOld from './ModifyOld'
import ModifySWM from './ModifySWM'


@autobind class Modify extends Component {

  render() {
    const { match, user, caseObj, editCaseObj, setCanContinue, setStep, disabled } = this.props
    const { application } = match.params

    return (
      <React.Fragment>
        {
          application === 'SWM' && <ModifySWM 
            user={user}
            caseObj={caseObj}
            component={Modify}
            editCaseObj={editCaseObj}
            disabled={disabled}
            setCanContinue={setCanContinue}
            setStep={setStep}
          />
        }
        {
          application === 'SGO' && <ModifyOld
            user={user}
            caseObj={caseObj}
            component={Modify}
            editCaseObj={editCaseObj}
            disabled={disabled}
            setCanContinue={setCanContinue}
            setStep={setStep}
          />
        }
      </React.Fragment>
    )
  }
}


export default withRouter(Modify)
