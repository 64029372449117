//Well Info
import Well_Master from './WellInfo/WellMaster'
import Well_Tops from './WellInfo/WellTops'
import Well_Alias from './WellInfo/WellNameAlias'
import Well_Groups from './WellInfo/WellGroups'
import Well_Events from './WellInfo/WellEvents'
import Well_Perforations from './WellInfo/WellPerforations'

//Prod and Inj.
import Well_Production_Monthly from './ProductionAndInjection/WellMonthlyProduction'
import Well_Injection_Monthly from './ProductionAndInjection/WellMonthlyInjection'

//Testing
import Fluid_PVT from './Testing/FluidPVT'
import Well_Pressure_Test_Static from './Testing/WellPressureTestStatic'
import Reservoir_Pressure_Static from './Testing/ReservoirPressureStatic'
import Well_Production_Log from './Testing/WellProductionLog'
import Well_Injection_Log from './Testing/WellInjectionLog'

//Wellbore Diagrams
import Wellbore_Diagrams from './WellboreDiagrams/WellboreDiagrams'

//Well Trajectories
import Well_Trajectories from './WellTrajectories/WellTrajectories'

//Faults
import Fault_Data from './Faults/Faults'

//Logs
import Log_Data from './Logs/Logs'

//Surfaces
import Grid_Data from './Surfaces/Surfaces'

//Model
import Model_Data from './Model/Model'

//Unit Conversion
import Units from './Units/Units'


export default {
  //Well Info
  Well_Master,
  Well_Tops,
  Well_Alias,
  Well_Groups,
  Well_Events,
  Well_Perforations,

  //Prod and Inj.
  Well_Production_Monthly,
  Well_Injection_Monthly,

  //Testing
  Fluid_PVT,
  Well_Pressure_Test_Static,
  Reservoir_Pressure_Static,
  Well_Production_Log,
  Well_Injection_Log,

  //Wellbore Diagrams
  Wellbore_Diagrams,

  //Well Trajectories
  Well_Trajectories,

  //Faults
  Fault_Data,

  //Logs
  Log_Data,

  //Surfaces
  Grid_Data,

  //Model
  Model_Data,

  //Unit Conversion
  Units
}