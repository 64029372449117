import React, { Component } from 'react'
import autobind from 'autobind-decorator'

import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import styled from '@material-ui/core/styles/styled'
import WarningIcon from '@material-ui/icons/Warning'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import './SurfacesSelector.scss'

const containerClass = 'surfaces-selector'
const containerSubgroupClass = `${containerClass}__subgroup`
const containerSubgroupItemClass = `${containerSubgroupClass}--item`


const individualGroupClass = `${containerClass}__group-option`
const selectedGroupClass = `${individualGroupClass}--selected`

const groupIndicatorClass = `${individualGroupClass}__indicator`
const groupSelectedIndicatorClass = `${groupIndicatorClass}--selected`
  //The blue bars on left used to indicate selected group
  //They now indicate a required group

const groupExpandedClass = `${individualGroupClass}--expanded`
const groupContentClass = `${individualGroupClass}__content`
const groupContentTextClass = `${groupContentClass}__text`
const groupContentEmptyClass = `${groupContentClass}__empty-space`
const groupContentStatusClass = `${groupContentClass}__status`

const requiredClass = 'required'

const isGroupRequired = (group) => group.categories[0].required


@autobind class SurfacesSelector extends Component {

  onClickGroup(twoDSubgroup, selectedGroupId, parentGroup) {
    const { onClickGroup } = this.props
    const selectedGroup = twoDSubgroup[selectedGroupId]
    onClickGroup(selectedGroupId, selectedGroup, parentGroup)
  }

  render() {
    const {
      groups,
      selectedGroupId,
      customGridsCreation,
      addCustomGrids,
      datasetId,
      versionId,
      refreshData,
      deleteCustomGrid
    } = this.props

    const twoDSubgroups = groups.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups
    return (
      <>
        {customGridsCreation && (
          <CustomGroupCreationObject
            addCustomGrids={addCustomGrids}
            datasetId={datasetId}
            versionId={versionId}
            selectedGroupId={selectedGroupId}
            onClickGroup={this.onClickGroup}
            refreshData={refreshData}
          />
        )}
        <MenuList
          className={containerClass}
        >
          {Object.keys(twoDSubgroups).map((groupId) => (
            <GroupObject
              groupId={groupId}
              selectedGroupId={selectedGroupId}
              onClickGroup={this.onClickGroup}
              twoDSubgroups={twoDSubgroups}
              deleteCustomGrid={deleteCustomGrid}
            />
          ))}
        </MenuList>
      </>
    )
  }
}

function GroupObject({ groupId, selectedGroupId, onClickGroup, twoDSubgroups, deleteCustomGrid }) {

  const selectedClass = groupId === selectedGroupId ? selectedGroupClass : ''
  const required = isGroupRequired(twoDSubgroups[groupId])
  const { custom, property } = twoDSubgroups[groupId]
  const reqClass = required ? requiredClass : ''

  const removeCustomGrid = (e, category) => {
    console.log('cat to remove', category)
    e.stopPropagation()
    deleteCustomGrid(category)
  }

  return (
    <>
      <MenuItem
        tabIndex="0"
        className={`${individualGroupClass} ${selectedClass} ${reqClass}`}
        onClick={() => onClickGroup(twoDSubgroups, groupId, 'Grid_Data')}
        onKeyPress={null}
        role="button"
      >
        <div className={`${groupIndicatorClass} ${required ? groupSelectedIndicatorClass : ''}`} />
        <div className={groupContentClass}>
          <div className={groupContentTextClass}>
            {twoDSubgroups[groupId].text}
            {required && (<span className="required">*</span>)}
          </div>
          <div className={groupContentEmptyClass} />
          {required && (
            <div className={`${groupContentStatusClass} ${twoDSubgroups[groupId].state.className}`}>
              {twoDSubgroups[groupId].state.text}
            </div>
          )}
        </div>
        {custom && (
          <div>
            <HighlightOffIcon
              color={"error"}
              onClick={(e) => removeCustomGrid(e, property)}
            />
          </div>
        )}
      </MenuItem>
    </>
  )
}

const Warning = styled(WarningIcon)({
  color: '#FED155',
  position: 'relative',
  top: '-2px',
  left: '-5px',
})
const Good = styled(CheckIcon)({
  color: 'green',
  position: 'relative',
  top: '3px',
  left: '-3px'
})
const Bad = styled(CloseIcon)({
  color: 'red',
  position: 'relative',
  top: '3px',
  left: '-3px'
})

function CustomGroupCreationObject(props) {

  const {
    addCustomGrids,
  } = props

  const onChange = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onSubmit = (e) => {
    onChange(e)
    addCustomGrids(e.target.value)
  }

  return (
    <MenuItem>
      <div className={groupContentClass}>
          <div className={groupContentTextClass}>
            <TextField
              className="text-field"
              onChange={onChange}
              onBlur={onSubmit}
              variant="outlined"
              margin="dense"
              placeholder="Type custom grid property here."

            />
          </div>
          <div className={groupContentEmptyClass} />
          <div className={`${groupContentStatusClass}`}>
            <Warning/>
            <div className={`${groupContentStatusClass}__warning-text`}>
              No spaces or special characters allowed. <br/>
              Ex: Isochore_Grids <Good/>
              Isochore Grids <Bad/>
            </div>
          </div>
        </div>
    </MenuItem>
  )
}

export default SurfacesSelector