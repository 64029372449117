import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { withStyles } from '@material-ui/core/styles'
import ReactTable from 'react-table'
import Link from '@material-ui/core/Link'
import { Launch } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'

import { dealWithNaN } from '../../../../lib/helpers'

import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

@autobind class ZoneTargetTable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      reasons: [],
    }
  }

  openReasons(reasons) {
    if (reasons) {
      this.setState({
        reasons,
        isModalOpen: true,
      })
    }
  }

  render() {
    const { zones } = this.props
    const { reasons, isModalOpen } = this.state

    let tableData = zones.map(i => ({
      isTarget: i.isFinalTarget,
      zoneId: i.zoneId,
      reason: i.reason,
      targetIntervalTop: dealWithNaN(i.targetIntervalTop).toFixed(2),
      targetIntervalBase: dealWithNaN(i.targetIntervalBase).toFixed(2),
      netPay: dealWithNaN(i.netPay).toFixed(2),
      hcpt: dealWithNaN(i.hcpt).toFixed(2),
      k: dealWithNaN(i.k).toFixed(2),
      predictedIp: dealWithNaN(i.predictedIp).toFixed(2),
      targetP10IPs: dealWithNaN(i.analogNeighborStats.targetP10).toFixed(1), //ANALOG WELL STATS IS COMING FROM ANALOG DATA
      targetP90IPs: dealWithNaN(i.analogNeighborStats.targetP90).toFixed(1),
      targetP10CNRs: dealWithNaN(i.currentNeighborStats.targetP10).toFixed(1),
      targetP90CNRs: dealWithNaN(i.currentNeighborStats.targetP90).toFixed(1),
      recentGor: dealWithNaN(i.recentGor).toFixed(2),
      recentWct: dealWithNaN(i.recentWct * 100).toFixed(1),
    }))

    tableData = tableData.filter(i => i.isTarget)

    return (
      <div className="table">
        <div>
          <b>Note:</b> Headers in blue are calculated by the engine, not raw data
        </div>
        <div>
          <b>Note:</b> P10 and P90 of neighbors has not been adjusted for thickness or normalized in any way.  Therefore, your forecast may fall outside of the range of neighbor data.
          <br/>
        </div>
        <ReactTable
          className="-striped -hover"
          columns={
            [{
              Header: <div>Zone Id</div>,
              accessor: 'zoneId',
            }, {
              Header: <div>Is Target</div>,
              accessor: 'isTarget',
              id: 'target',
              Cell: ({ value }) => (value ? 'Yes' : 'No'),
            }, {
              Header: <div>Start Depth</div>,
              accessor: 'targetIntervalTop',
            }, {
              Header: <div>End Depth</div>,
              accessor: 'targetIntervalBase',
            }, {
              Header: <div>Pay</div>,
              accessor: 'netPay',
            }, {
              Header: <div>HCPT</div>,
              accessor: 'hcpt',
            }, {
              Header: <div>K</div>,
              accessor: 'k',
            }, {
              Header: <div style={{color: 'blue'}}>IP</div>,
              accessor: 'predictedIp',
            }, {
              Header: <div style={{color: 'blue'}}>P10 of Neighbor IPs*</div>,
              accessor: 'targetP10IPs',
            }, {
              Header: <div style={{color: 'blue'}}>P90 of Neighbor IPs*</div>,
              accessor: 'targetP90IPs',
            }, {
              Header: <div style={{color: 'blue'}}>P10 of Neighbor Current Rates*</div>,
              accessor: 'targetP10CNRs',
            }, {
              Header: <div style={{color: 'blue'}}>P90 of Neighbor Current Rates*</div>,
              accessor: 'targetP90CNRs',
            }, {
              Header: <div style={{color: 'blue'}}>GOR</div>,
              accessor: 'recentGor',
            }, {
              Header: <div style={{color: 'blue'}}>WCT (%)</div>,
              accessor: 'recentWct',
            }, {
              Header: <div>Reasons</div>,
              accessor: 'reason',
              id: 'reason',
              Cell: ({ row }) => (
                <div>
                  {row.reason && row.reason.length ? (
                    <Link
                      component="button"
                      style={{ 'height': '100%', 'width': '100%' }}
                      onClick={() => this.openReasons(row.reason)}
                    >
                      <Tooltip title="View reasons" aria-label="view reasons">
                        <Launch />
                      </Tooltip>
                    </Link>
                  ) : null }
                </div>
              ),
            }]
          }
          showPagination={false}
          data={tableData}
          pageSize={tableData.length}
          defaultSorted={[
            {
              id: 'target',
              desc: true,
            },
          ]}
        />
        <ReasonDialog
          reasons={reasons}
          open={isModalOpen}
          handleClose={() => this.setState({ isModalOpen: false })}
        />
        
      </div>
    )
  }
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const ReasonDialog = ({ reasons, open, handleClose }) => (
  <Dialog
    fullWidth
    maxWidth="lg"
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      Reasons
    </DialogTitle>
    <DialogContent>
      <div>
        <List>
          { reasons.map((reason, idx) => (
            <React.Fragment>
              <ListItem alignItems="flex-start">
                <ListItemText>{reason}</ListItemText>
              </ListItem>
              { reasons.length - 1 !== idx ? <Divider /> : null }
            </React.Fragment>
          ))}
        </List>
      </div>
    </DialogContent>
  </Dialog>
)


export default ZoneTargetTable
