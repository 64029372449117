import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class DrainageAnalysisOverview extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Drainage Radius Correction
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Radius Reduction Factor<br/>
          <span className='blue'>Options:</span> Decimal between 0 and 1<br/>
          <span className='blue'>Comments:</span> Radius reduction factor may be used to decrease all calculated drainage radii. A factor of 0.1 reduces the radius by 10%.<br/>
        </div>
        <div className="content-row"> 
          Min HCPT PNP<br/>
          <span className='blue'>Options:</span> Meters<br/>
          <span className='blue'>Comments:</span> Min hcptPnp to consider for radius calculation (very low values create artificially large radius).<br/>
        </div>
        <div className="content-row"> 
          Max Radius Percentile<br/>
          <span className='blue'>Options:</span> 0 to 1<br/>
          <span className='blue'>Comments:</span> Radiuses are capped at the provided percentile (to limit outliers). For example, if 0.9 is chosen, then any radii higher than the 90th percentile of the distribution will be capped at the value of the 90th percentile. A value of 0.99 is often recommended to eliminate extreme outliers to the distribution.<br/>
        </div>
        <div className="content-row"> 
          Smooth Indexes<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> Flag to activate smoothing (2D Drainage).<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(DrainageAnalysisOverview)
