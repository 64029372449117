import React from 'react'

import img1 from './Logs1.jpg'

const Logs = (
  <div className={'description-container'}>
    <p><b>Content:</b> For information regarding which logs are required, recommended, and optional, as well as how to export logs from Petrel, please refer to the file “Data Preparation” under resources under <b>Appendix: Data Preparation &amp; Quality Control</b> subsection <b>Well Logs.</b></p>
    <p><b>Utility:</b> Well logs are used in many steps of the evaluation including (but necessarily limited to) Engineering Analytics, Data Processing, Generate Net Logs & Calculate Zone Averages, Drainage Analysis, and Collect Workover Opportunities & Rank. Some of the most important uses of logs are to find remaining pay behind pipe and evaluate the drainage of existing production. Therefore, missing well logs will lead to uncertainty in the opportunity inventory. For example, if an existing well does not have well logs but has production, then the tool will not be able to properly evaluate drainage from this well, nor will this be used in certain forecast methods that use thickness (e.g., net pay thickness) normalization.</p>
    <p><b>Conventions: Z: </b> Values of Z need to be negative when below sea level</p>
    <p><b>File Format:</b> LAS files</p>
    <ul>
      <li>Well logs should be exported in a .LAS format from Petrel, one file per well. The name of the file should be the same as the well name. The typical header information in an .LAS file is shown in Figure 18.</li>
      <li>All well logs need to have the same step (sampling interval). A method to ensure that no well logs have a step of zero is outlined in the data preparation section of this report <b>Exporting Well Logs.</b></li>
    </ul>
    <p><b>Units:</b> LAS files contain depth tracks that are in either feet or meters as units. As long as the unit is specified in the well section header of the LAS file, the file will import.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 18: Typical header information for an .LAS file from Petrel, containing all logs for a given well.</figcaption>
    </figure>
  </div>
)

export default Logs