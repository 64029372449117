const monthMap = {
  0: 'JANUARY',
  1: 'FEBRUARY',
  2: 'MARCH',
  3: 'APRIL',
  4: 'MAY',
  5: 'JUNE',
  6: 'JULY',
  7: 'AUGUST',
  8: 'SEPTEMBER',
  9: 'OCTOBER',
  10: 'NOVEMBER',
  11: 'DECEMBER',
}

export function formatTime(unformattedDate) {
  const date = new Date(unformattedDate)
  return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}

export function stringFormatDate(unformattedDate) {
  let date
  if((unformattedDate instanceof Date) === false){
    date = new Date(unformattedDate)
  } else{
    date = unformattedDate
  }

  return `${monthMap[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export function numberFormatDate(unformattedDate) {
  const date = new Date(unformattedDate)
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}