export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

export const STANDARD_HEADERS = {
  'Content-type': 'application/json',
}

export function createGetRequestParams() {
  
  return {
    method: HTTP_METHODS.GET,
    credentials: 'include',
    cache: 'no-store',
  }
}

export function createPostRequestParams(body) {
  if (!body) {
    body = {}
  }
  return {
    method: HTTP_METHODS.POST,
    headers: STANDARD_HEADERS,
    credentials: 'include',
    cache: 'no-store',
    body: JSON.stringify(body),
  }
}

export function createPutRequestParams(body) {


  if (!body) {
    body = {}
  }
  return {
    method: HTTP_METHODS.PUT,
    headers: STANDARD_HEADERS,
    credentials: 'include',
    cache: 'no-store',
    body: JSON.stringify(body),
  }
}

export function createDeleteRequestParams(body) {

  if (!body) {
    body = {}
  }
  return {
    method: HTTP_METHODS.DELETE,
    headers: STANDARD_HEADERS,
    credentials: 'include',
    cache: 'no-store',
    body: JSON.stringify(body),
  }
}

export function createFileUploadPostParams(body) {

  if (!body) {
    body = {}
  }
  return {
    method: HTTP_METHODS.POST,
    headers: {
      'Content-type': 'multipart/form-data',
    },
    credentials: 'include',
    cache: 'no-store',
    body: JSON.stringify(body),
  }
}
