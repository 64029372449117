import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'

import {withStyles} from '@material-ui/core/styles';

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Popper from '@material-ui/core/Popper'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'

import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

const baseClass = 'dataset-header'

const arrowGenerator = (color) => {
  return ({
    '&[x-placement*="bottom"] $arrow': {
      height: 16,
      left: 259,
      width: 32,
      /* height: 25px; */
      position: 'absolute',
      top: -10,
      right: 0,
      /* transform: translateX(50%); */
      overflow: 'hidden',
    /* bottom: 0;
    /*
    top: 0,
    left: 0,
    marginTop: '-0.95em',
    width: '2em',
    height: '1em',
    */
    '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
    },
    },
    '&[x-placement*="bottom"]': {
      marginTop: 18,
    },
    '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.95em',
    width: '2em',
    height: '1em',
    '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
    },
    },
    '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.95em',
    height: '2em',
    width: '1em',
    '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
    },
    },
    '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.95em',
    height: '2em',
    width: '1em',
    '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
    },
    },
  })
}

const styles = {
    root: {
        minWidth: '300px',
    },
    button: {
        borderRadius: 0,
    },
    label: {
        fontSize: '18px',
        color: 'black',
    },
    arrow: {
      position: 'absolute',
      fontSize: 6,
      '&::before': {
        content: '""',
        /*
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        */
        position: 'absolute',
        width: 20,
        height: 20,
        background: 'white',
        transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
        top: '100%',
        left: '50%',
        boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.6)',
      },
    },
    popper: arrowGenerator('white'),
}

const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
        fontSize: 14,
      },
      '& input': {
        fontSize: 14,
        borderRadius: 0,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  }))((props) => <TextField InputProps={{ disableUnderline: true }} {...props} />)

@autobind class DatasetPublishModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valid: true,
      helperText: '',
    }
    this.input = React.createRef();
  }

  handleClose(){
    const { onClose } = this.props
    onClose()
  }

  handlePublish(e){
    e.preventDefault()
    const { onSubmit } = this.props
    if(this.input.current.value && this.input.current.value !== '' ) {
      this.setState({
        valid: true,
        helperText: '',
      })
      onSubmit(this.input.current.value)
    } else {
      this.setState({
        valid: false,
        helperText: 'Version name must not be empty',
      })
    }
  }

  handleArrowRef(reference){
    this.setState({
      arrowRef: reference,
    })
  }

  render() {
    const { valid, helperText, arrowRef } = this.state
    const { open, anchorEl, classes } = this.props

    return (
      <Popper
        className={classes.popper}
        open={open} 
        anchorEl={anchorEl}
        placement="bottom-end"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: true, 
            element: arrowRef,
          },
        }}
      >
        <span className={classes.arrow} ref={this.handleArrowRef} />
        <form onSubmit={this.handlePublish} style={{ marginTop: 6 }}>
          <Card className={classes.root} square>
            <CardContent>
              <FormControl className={classes.root}>
                <InputLabel className={classes.label} shrink htmlFor="version-name">
                Version Name
                </InputLabel>
                <BootstrapInput autocomplete="off" inputRef={this.input} error={!valid} helperText={helperText} id="version-name" />
              </FormControl>
            </CardContent>
            <DialogActions>
              <Button className={classes.button} onClick={this.handleClose} size="large">
                Cancel
              </Button>
              <Button type="submit" className={classes.button} size="large" variant="contained" color="primary">
                Publish
              </Button>
            </DialogActions>
          </Card>
        </form>
      </Popper>
    )
  }
}

export default  withStyles(styles)(DatasetPublishModal)
