import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import { ClipLoader } from 'react-spinners'
import Card from '../../common/Card'

import StructuralXSection from '../../components/StructuralXSection'
import TrajectoryPlot from '../../components/TrajectoryPlot'
import ModelSectionPlot from '../../components/ModelSectionPlot'

import './ModelAnalysis.scss'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class ModelAnalysis extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      shouldRender3d: false,
      shouldRenderStructuralSection: false,
      shouldRenderModel: false,
      onlyShowCurTraj: true,
      showNS: true,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    setTimeout(() => {
      this.setState({
        shouldRender3d: true,
      })  
    }, 200)

    setTimeout(() => {
      this.setState({
        shouldRenderStructuralSection: true,
      })  
    }, 300)

    setTimeout(() => {
      this.setState({
        shouldRenderModel: true,
      })  
    }, 400)  
  }


  handleClickRadio() {
    const { showNS } = this.state
    this.setState({
      showNS: !showNS,
    })
  }

  handleClickCheck() {
    const { onlyShowCurTraj } = this.state
    this.setState({
      onlyShowCurTraj: !onlyShowCurTraj,
    })
  }

  render() {
    const { map } = this.props
    const { modelSectionData, singleOpportunity, wellData, gridData, wellId, structSectionDataNS, structSectionDataEW, } = this.props
    const { shouldRenderModel, onlyShowCurTraj, shouldRender3d, shouldRenderStructuralSection, showNS } = this.state

    const modelSubtitle = modelSectionData ? modelSectionData.propertyId : ''

    return (
      <div className="opportunity-model">
        <div className="top">
          <div className="left">
            {map}
          </div>
          <div className="right">
            <Card title="3D Trajectory">
              <Plot3D
                singleOpportunity={singleOpportunity}
                modelSectionData={modelSectionData}
                gridData={gridData}
                wellData={wellData}
                wellId={wellId}
                onlyShowCurTraj={onlyShowCurTraj}
                shouldRender3d={shouldRender3d}
                handleClickCheck={this.handleClickCheck}
              />
            </Card>
          </div>
        </div>
        <div className="middle">
          <Card title="Structural X-Section">         
          {
            <StructuralSection
              singleOpportunity={singleOpportunity}
              wellData={wellData}
              wellId={wellId}
              showNS={showNS}
              shouldRenderStructuralSection={shouldRenderStructuralSection}
              structSectionDataNS={structSectionDataNS}
              structSectionDataEW={structSectionDataEW}
              modelSectionData={modelSectionData}
              handleClickRadio={this.handleClickRadio}
            />
          }
          </Card>
        </div>
        <div className="bot">
          <Card title="Model Section" subtitle={modelSubtitle}>
          {
            <ModelPlot
              modelSectionData={modelSectionData}
              singleOpportunity={singleOpportunity}
              wellData={wellData}
              shouldRenderModel={shouldRenderModel}
            />
          }
          </Card>
        </div>
      </div>
    )
  }
}

const ModelPlot = ({ modelSectionData, singleOpportunity, wellData, shouldRenderModel }) => {
  if (modelSectionData && Object.keys(modelSectionData).length > 0 && singleOpportunity && wellData && shouldRenderModel) {
    return (
      <ModelSectionPlot
        modelSectionData={modelSectionData}
        opportunityData={singleOpportunity}
        wellData={wellData}
      />
    )
  }
  if (!modelSectionData) {
    return (
      <div>
        This dataset does not contain a 3D Model File.
      </div>
    )
  }
  return (
    <div className="loading-background">
      <ClipLoader css={loaderCSS} />
    </div>
  )
}

// eslint-disable-next-line max-len
const Plot3D = ({ 
  singleOpportunity,
  modelSectionData,
  gridData,
  wellData,
  wellId,
  onlyShowCurTraj,
  shouldRender3d,
  handleClickCheck,
}) => {
  if (singleOpportunity && gridData && wellId && wellData && shouldRender3d) {
    let trajectory = null
    if (singleOpportunity.type === 'verticalDrill') {
      if (modelSectionData && modelSectionData.trajectory && modelSectionData.trajectory.length) {

        trajectory = modelSectionData.trajectory
        /* 
          * Quick temporary fix for possible errors in data.
          * Set trajectory depth from 0 to the bottom target
        */
        const targets = singleOpportunity.targetDepths
        const bottomTarget = targets.length ? targets[targets.length - 1] : targets
        if (bottomTarget) {
          trajectory[0].z = 0
          trajectory[1].z = bottomTarget
        }

      } else {
        trajectory = [
          { x: singleOpportunity.x, y: singleOpportunity.y, z: 0 },
          { x: singleOpportunity.x, y: singleOpportunity.y, z: Math.min(...singleOpportunity.targetDepths)},
        ]       
      }
 
    } else if (singleOpportunity.type === 'horizontalDrill') {
      trajectory = singleOpportunity.Trajectory
      trajectory = [
        { x: trajectory.x[0], y: trajectory.y[0], z: trajectory.z[0] },
        { x: trajectory.x[trajectory.x.length - 1], y: trajectory.y[trajectory.y.length - 1], z: trajectory.z[trajectory.z.length-1] },
      ]
    }
    
    return (
      <React.Fragment>
        <div className="traj-checkbox">
          <input type="checkbox" id="traj" checked={onlyShowCurTraj} onChange={handleClickCheck}/>
          <label className="traj-label">Only Show Current Trajectory</label>
        </div>
        <div className="traj-plot-container">
          <TrajectoryPlot
            onlyShowCurTraj={onlyShowCurTraj}
            gridData={gridData}
            zone={singleOpportunity.zone}
            wellData={wellData}
            wellId={wellId}
            trajectory={trajectory}
          />
        </div>
      </React.Fragment>
    )
  }
  return (
    <div className="loading-background">
      <ClipLoader css={loaderCSS} />
    </div>
  )
}

const StructuralSection = ({ structSectionDataNS, structSectionDataEW, singleOpportunity, wellData, wellId, showNS, modelSectionData, shouldRenderStructuralSection, handleClickRadio }) => {
  if (structSectionDataNS && structSectionDataEW && singleOpportunity && wellData && wellId && shouldRenderStructuralSection) {
    let data = structSectionDataEW

    if (showNS) {
      data = structSectionDataNS
    }

    return (
      <React.Fragment>
        { !(singleOpportunity.type == 'horizontalDrill') &&
          <div className="traj-checkbox">
            <input type="radio" id="traj" checked={showNS} onChange={handleClickRadio}/>
            <label className="traj-label">Show North/South </label>
            <input type="radio" id="traj" checked={!showNS} onChange={handleClickRadio}/>
            <label className="traj-label">Show East/West</label>
          </div>
        }
        <div className="traj-plot-container">
          <StructuralXSection
            structSectionData={data}
            opportunityData={singleOpportunity}
            wellData={wellData}
            wellId={wellId}
            modelSectionData={modelSectionData}
            singleOpportunity={singleOpportunity}
          />
        </div>
      </React.Fragment>
    )
  }
  return (
    <div className="loading-background">
      <ClipLoader css={loaderCSS}/>
    </div>
  )
}

export default ModelAnalysis
