import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import landingImage from '../../../../../static/images/userManual/landingPageImage.png'

@autobind class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="landing-page__title"> 
          User Manual
        </div>
        <div className="landing-page__content">
          Use the navigation panel to learn more about our platform
        </div>
        <div className='landing-page__image'>
          <img className="tooBig" src={landingImage} alt="" />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(LandingPage)