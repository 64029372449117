import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import { ClipLoader } from 'react-spinners'
import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AntSwitch from '../../../../common/Switch/Switch'
import Card from '../../common/Card'
import NeighborBarPlot from '../../components/NeighborBarPlot'
import ScatterChart from '../../components/ScatterChart'
import OpportunityNeighborTable from '../../components/OpportunityNeighborTable'
import OpportunityCurrentNeighborTable from '../../components/OpportunityCurrentNeighborTable'

import './ProductionForecast.scss' 

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

const Neighbors = Object.freeze({
  INITIAL:   { name: "Initial Neighbors", value: 'INITIAL' },
  CURRENT:  { name: "Current Neigbors", value: 'CURRENT' },
});

const xAxisOptions = [
  { label: 'Perforation Length (m)', value: 'perfLength' },
  { label: 'Hydrocarbon Thickness (m)', value: 'pnpHcpt' },
  { label: 'PNP (m)', value: 'pnp' },
  { label: 'Perm', value: 'pnpPerm' },
  { label: 'Initial Pressure', value: 'pnpInitialPressure' },
]

@autobind class ProductionForecast extends PureComponent {
  constructor(props) {
    super(props)
    this.neigborModeEnabled = false
    this.state = {
      neighborsToDisplay: Neighbors.INITIAL,
      shouldRenderNeighborHistory: false,
      shouldRenderNeighborIP: false,
      shouldRenderNeighborWells: false,
      shouldRenderNeighborTable: false,
      sizeBy: null,
      xAxis: 'perfLength',
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    const { analogNeighborData } = this.props
 
    if (analogNeighborData !== prevProps.analogNeighborData) {
      this.loadData()
    }
  }

  loadData() {
    const { singleOpportunity, analogNeighborData } = this.props

    this.neigborModeEnabled = !!singleOpportunity.analogNeighborStats  || !!singleOpportunity.currentNeighborStats

    this.analogNeighborData = singleOpportunity.analogNeighborStats
      ? singleOpportunity.analogNeighbors
      : analogNeighborData
 
    this.currentNeighborData = singleOpportunity.currentNeighborStats
      ? singleOpportunity.currentNeighbors
      : []

    this.setState({
      neighborsToDisplay: Neighbors.INITIAL, 
      neighborData: this.analogNeighborData,
    })

    setTimeout(() => {
      this.setState({
        shouldRenderNeighborTable: true,
      })  
    }, 100)

    setTimeout(() => {
      this.setState({
        shouldRenderNeighborHistory: true,
      })  
    }, 200)

    setTimeout(() => {
      this.setState({
        shouldRenderNeighborIP: true,
      })  
    }, 300)


    setTimeout(() => {
      this.setState({
        shouldRenderNeighborWells: true,
      })  
    }, 400)     
  }

  toggleNeighbors() {
    this.state.neighborsToDisplay === Neighbors.CURRENT
      ? this.setState({ neighborsToDisplay : Neighbors.INITIAL, neighborData: this.analogNeighborData })
      : this.setState({ neighborsToDisplay : Neighbors.CURRENT, neighborData: this.currentNeighborData })
  }

  renderNeighborTable() {
    const { neighborsToDisplay, neighborData } = this.state
    const { singleOpportunity } = this.props
    if (neighborData && this.state.shouldRenderNeighborTable) {
      if (neighborsToDisplay === Neighbors.INITIAL) {
        return <OpportunityNeighborTable data={neighborData} />
     } else {
        return <OpportunityCurrentNeighborTable data={neighborData} />
      }
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderNeighborHistory() {
    const { wellData, zone, singleOpportunity } = this.props

    if (wellData && this.state.shouldRenderNeighborHistory) {
      const zoneTypeCurveData = []
      if (Object.keys(wellData).length > 0) {
        Object.keys(wellData).forEach((key) => {
          const wellZoneData = wellData[key].zoneData[zone]
          if (wellZoneData && wellZoneData.ip_oil && wellZoneData.ip_oil.ip_oil) {
            zoneTypeCurveData.push({
              date: wellZoneData.ip_oil.date.date,
              ip: wellZoneData.ip_oil.ip_oil,
              wellName: key,
            })
          }
        })
      }

      return (<ScatterChart
        data={zoneTypeCurveData}
        textKey="wellName"
        xKey="date"
        yKey="ip"
        xTitle="Date"
        yTitle="IP"
        yUnits="bbl/d"
        refLine={singleOpportunity.predictedIp}
        refLineTitle="IP Forecast"
      />)
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderNeighborIP() {
    const { singleOpportunity } = this.props
    const { neighborsToDisplay, neighborData } = this.state
    if (neighborData && this.state.shouldRenderNeighborIP) {

      const plotData = neighborsToDisplay ===  Neighbors.INITIAL
      ? neighborData.map(i => ({ id: i.id, val: i.targetIP }))
      : neighborData.map(i => ({ id: i.wellId, val: i.oil }))

      return (
        <NeighborBarPlot
          data={plotData}
          refLine={singleOpportunity.predictedIp}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  handleXAxisChange(val) {
    const { sizeBy } = this.state

    let obj = {
      xAxis: val
    }

    if (sizeBy === val) {
      obj.sizeBy = null
    }

    this.setState(obj)
  }


  renderNeighborWells() {
    const { singleOpportunity } = this.props
    const { neighborsToDisplay, xAxis, sizeBy, neighborData } = this.state

    if (neighborData && this.state.shouldRenderNeighborWells) {

      const yKey = neighborsToDisplay ===  Neighbors.INITIAL
      ? "targetIP"
      : "oil"

      const title = neighborsToDisplay ===  Neighbors.INITIAL
      ? "IP"
      : "Oil"

      return (
        <React.Fragment>
          <ScatterChart data={neighborData} xKey={xAxis} yKey={yKey} sizeKey={sizeBy} xTitle='' yTitle={title} yUnits="bbl/d" yMargin={75}/>
          <div style={{'position': 'relative', top: '-60px', width: '440px', margin: 'auto'}}>
            <div style={{'display': 'inline-block', width: '200px', marginRight: '20px'}}>
              <span> X Axis </span>
              <Select
                options={xAxisOptions}
                onChange={(val) => this.handleXAxisChange(val.value)}
                isMulti={false}
                value={xAxisOptions.find(i => i.value === xAxis)}
              />
            </div>
            <div style={{'display': 'inline-block', width: '200px'}}>
              <span> Size By </span>
              <Select
                options={xAxisOptions.filter(i => i.value !== xAxis)}
                onChange={(val) => this.setState({
                  sizeBy: val.value
                })}
                isMulti={false}
                value={xAxisOptions.find(i => i.value === sizeBy) || null}
              />
            </div>
          </div>
        </React.Fragment>
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderNeighborExplanation() {
    const { caseObj } = this.props
    const { neighborsToDisplay } = this.state


    let spatialType = 'radius'
    let spatialScale = 0
    let temporalScale = 0
    let matchWellTypes = false
    const isInitial = neighborsToDisplay === Neighbors.INITIAL
    let useZoneAllocatedRates = false

    if (caseObj) {
      spatialType = caseObj.settings.productionForecast.neighborhoodSettings.type
      spatialScale = caseObj.settings.productionForecast.neighborhoodSettings.spatialScale
      temporalScale = caseObj.settings.productionForecast.neighborhoodSettings.temporalScale
      matchWellTypes = caseObj.settings.productionForecast.neighborhoodSettings.matchWellType
      useZoneAllocatedRates = caseObj.settings.productionForecast.neighborhoodSettings.useZoneAllocatedRates
    }
    

    return (
      <div className='neighbor-explanation-outer'>
        <div className='neighbor-explanation-title'>
          Analog wells are calculated based on the following criteria:
        </div>
        <div className='neighbor-explanation-row'>
          <span>&#8226;</span> {isInitial ? 'Produced' : 'Currently produce' } from the same target zone
        </div>
        { isInitial && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Started producing within the last {temporalScale} years
          </div>
        )}
        { spatialType === 'radius' && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Within the the closest {spatialScale} wells by distance 
          </div>
        )}
        { spatialType !== 'radius' && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Spatially within {spatialScale} points 
          </div>
        )}
        { matchWellTypes && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Share the same well type with target
          </div>
        )}
        { !useZoneAllocatedRates && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Do not require production allocation (wells produce from target zone only)
          </div>
        )}

      </div>
    )
  }

  render() {
    const { map, singleOpportunity } = this.props
    const { neighborsToDisplay } = this.state

    const isInitial = neighborsToDisplay ===  Neighbors.INITIAL
    
    let barPlotTitle = "Analog Wells - Current Oil Rates"
    let scatterPlotTitle = "Analog Wells - Current Oil Rates vs Attributes"

    if (isInitial) {
      barPlotTitle = "Analog Wells - Initial Production Rates (IPs)"
      scatterPlotTitle = "Analog Wells - IP vs Attributes"
    }

    if (singleOpportunity) {

      return (
        <>
          <div className="zone-selector">
            <label>Target Zone: </label>
            <span>{singleOpportunity.zone}</span>
          </div>
          <div className="opportunity-production">
            <div className="top">
              <div className="left">
                { map }
              </div>
              <div className="right">
                <Card title="Zone IP History">
                  { this.renderNeighborHistory() }
                </Card>
              </div>
            </div>
            {this.neigborModeEnabled && (
              <div style={{ padding: '20px 0' }}>
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Initial Neighbors</Grid>
                    <Grid item>
                      <AntSwitch
                        onChange={this.toggleNeighbors}
                        checked={this.state.neighborsToDisplay !== Neighbors.INITIAL}
                        value={this.state.neighborsToDisplay}
                      />
                    </Grid>
                    <Grid item>Current Neighbors</Grid>
                  </Grid>
                </Typography>
              </div>
            )}
            <div className="upper-middle">
              <Card title=''>
                { this.renderNeighborExplanation() }
              </Card>
            </div>
            <div className="middle">
              <div className="left">
                <Card title={barPlotTitle}>
                  { this.renderNeighborIP() }
                </Card>
              </div>
              <div className="right">
                <Card title={scatterPlotTitle}>
                  { this.renderNeighborWells() }
                </Card>          
              </div>
            </div>
            <div className="bot">
              <Card title="Analog Wells - Attribute Table">
                { this.renderNeighborTable() }
              </Card>
            </div>
          </div>
        </>
      )
    }

    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }
}


export default ProductionForecast
