import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core'
import Grid from '@material-ui/core/Grid'
import { ClipLoader } from 'react-spinners'
import Select from 'react-select'
import Typography from '@material-ui/core/Typography'

import Card from '../../../common/Card'
import AntSwitch from '../../../../../common/Switch/Switch'
import API from '../../../../../../lib/api-store'
import NeighborBarPlot from '../../../components/NeighborBarPlot'
import ScatterChart from '../../../components/ScatterChart'
import OpportunityNeighborTable from '../../../components/OpportunityNeighborTable' 
import OpportunityCurrentNeighborTable from '../../../components/OpportunityCurrentNeighborTable'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

const xAxisOptions = [
  { label: 'Perforation Length (m)', value: 'perfLength' },
  { label: 'Hydrocarbon Thickness (m)', value: 'pnpHcpt' },
  { label: 'PNP (m)', value: 'pnp' },
  { label: 'Perm (md)', value: 'pnpPerm' },
  { label: 'Initial Pressure (psi)', value: 'pnpInitialPressure' },
]

const Neighbors = Object.freeze({
  INITIAL: { name: 'Initial Neighbors', value: 'INITIAL' },
  CURRENT: { name: 'Current Neigbors', value: 'CURRENT' },
})

@autobind class ProductionForecast extends PureComponent {
  constructor(props) {
    super(props)
    this.neigborModeEnabled = false
    this.state = {
      shouldRenderNeighborHistory: false,
      shouldRenderNeighborIP: false,
      shouldRenderNeighborWells: false,
      shouldRenderNeighborTable: false,
      xAxis: 'perfLength',
      sizeBy: null,
      neighborData: [],
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    const { wellId, selectedZone, caseId } = this.props

    if (wellId !== prevProps.wellId
      || selectedZone !== prevProps.selectedZone
      || caseId !== prevProps.caseId) {
      this.loadData()
    }
  }

  loadData() {
    const { wellId, selectedZone, caseId, singleOpportunity, analogNeighborData } = this.props

    if (!selectedZone) {
      return
    }


    const actualSingleOpportunity = singleOpportunity.Opportunities.find(i => i.zoneId === selectedZone)


    this.neigborModeEnabled = !!actualSingleOpportunity.analogNeighborStats || !!actualSingleOpportunity.currentNeighborStats

    this.analogNeighborData = actualSingleOpportunity.analogNeighborStats
    ? actualSingleOpportunity.analogNeighbors
    : analogNeighborData
    
    this.currentNeighborData = actualSingleOpportunity.currentNeighborStats
    ? actualSingleOpportunity.currentNeighbors
    : []

    this.setState({ 
      neighborsToDisplay : Neighbors.INITIAL,
      neighborData: this.analogNeighborData 
    })
    
    API.getAnalogNeighborData(wellId, selectedZone, caseId)
      .then((data) => {
        const { analogNeighborData } = data

        this.analogNeighborData = actualSingleOpportunity.analogNeighborStats
          ? actualSingleOpportunity.analogNeighbors
          : analogNeighborData

        this.setState({
          analogNeighborData,
          shouldRenderNeighborTable: true,
          shouldRenderNeighborIP: true,
          shouldRenderNeighborWells: true,
          neighborsToDisplay : Neighbors.INITIAL, 
          neighborData: this.analogNeighborData 
        })
      })

    setTimeout(() => {
      this.setState({
        shouldRenderNeighborHistory: true,
      })
    }, 100)
  }

  toggleNeighbors() {
    this.state.neighborsToDisplay === Neighbors.CURRENT
      ? this.setState({ neighborsToDisplay : Neighbors.INITIAL, neighborData: this.analogNeighborData })
      : this.setState({ neighborsToDisplay : Neighbors.CURRENT, neighborData: this.currentNeighborData })
  }

  renderNeighborTable() {
    const { neighborsToDisplay, neighborData, shouldRenderNeighborTable } = this.state
    const { singleOpportunity } = this.props

    if (singleOpportunity && shouldRenderNeighborTable) {
      if (neighborsToDisplay === Neighbors.INITIAL) {
        return <OpportunityNeighborTable data={neighborData} />
      }
      return <OpportunityCurrentNeighborTable data={neighborData} />
    }

    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }

  renderNeighborHistory() {
    const { wellData, selectedZone } = this.props

    if (wellData && this.state.shouldRenderNeighborHistory) {
      const zoneTypeCurveData = []
      if (Object.keys(wellData).length > 0) {
        Object.keys(wellData).forEach((key) => {
          const wellZoneData = wellData[key].zoneData[selectedZone]
          if (wellZoneData && wellZoneData.ip_oil && wellZoneData.ip_oil.ip_oil) {
            zoneTypeCurveData.push({
              date: wellZoneData.ip_oil.date.date,
              ip: wellZoneData.ip_oil.ip_oil,
              wellName: key,
            })
          }
        })
      }

      return <ScatterChart data={zoneTypeCurveData} textKey="wellName"  xKey="date" yKey="ip" xTitle="Date" yTitle="IP" yUnits="bbl/d" />
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }

  renderNeighborIP() {
    const { singleOpportunity, selectedZone} = this.props
    const { neighborsToDisplay, neighborData, shouldRenderNeighborIP } = this.state

    const opportunityByZone = singleOpportunity
      .Opportunities
      .find(o => o.isFinalTarget && o.zoneId === selectedZone)

    const ip = opportunityByZone ? opportunityByZone.predictedIp : 0





    if (neighborData && singleOpportunity && shouldRenderNeighborIP) {
      const plotData = neighborsToDisplay ===  Neighbors.INITIAL
        ? neighborData.map(i => ({ id: i.id, val: i.targetIP }))
        : neighborData.map(i => ({ id: i.wellId, val: i.oil }))

      return (
        <NeighborBarPlot
          data={plotData}
          refLine={ip}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  handleXAxisChange(val) {
    const { sizeBy } = this.state

    let obj = {
      xAxis: val
    }

    if (sizeBy === val) {
      obj.sizeBy = null
    }

    this.setState(obj)
  }

  renderNeighborWells() {
    const { singleOpportunity } = this.props
    const { neighborsToDisplay, xAxis, sizeBy, neighborData, shouldRenderNeighborWells } = this.state

    if (singleOpportunity && shouldRenderNeighborWells) {

      const yKey = neighborsToDisplay === Neighbors.INITIAL
        ? 'targetIP'
        : 'oil'

      const title = neighborsToDisplay === Neighbors.INITIAL
        ? 'IP (bbl/d)'
        : 'Oil (bbl/d)'

      return (
        <React.Fragment>
          <ScatterChart data={neighborData} xKey={xAxis} yKey={yKey} sizeKey={sizeBy} xTitle='' yTitle={title} yUnits="bbl/d" yMargin={75} newHoverTemplate={true}/>
          <div style={{'position': 'relative', top: '-60px', width: '440px', margin: 'auto'}}>
            <div style={{'display': 'inline-block', width: '200px', marginRight: '20px'}}>
              <span> X Axis </span>
              <Select
                options={xAxisOptions}
                onChange={(val) => this.handleXAxisChange(val.value)}
                isMulti={false}
                value={xAxisOptions.find(i => i.value === xAxis)}
              />
            </div>
            <div style={{'display': 'inline-block', width: '200px'}}>
              <span> Size By </span>
              <Select
                options={xAxisOptions.filter(i => i.value !== xAxis)}
                onChange={(val) => this.setState({
                  sizeBy: val.value
                })}
                isMulti={false}
                value={xAxisOptions.find(i => i.value === sizeBy) || null}
              />
            </div>
          </div>
        </React.Fragment>
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS} />
      </div>
    )
  }

  renderNeighborExplanation() {
    const { caseObj } = this.props
    const { neighborsToDisplay } = this.state


    let spatialType = 'radius'
    let spatialScale = 0
    let temporalScale = 0
    let matchWellTypes = false
    const isInitial = neighborsToDisplay === Neighbors.INITIAL
    let useZoneAllocatedRates = false

    if (caseObj) {
      spatialType = caseObj.settings.productionForecast.neighborhoodSettings.type
      spatialScale = caseObj.settings.productionForecast.neighborhoodSettings.spatialScale
      temporalScale = caseObj.settings.productionForecast.neighborhoodSettings.temporalScale
      matchWellTypes = caseObj.settings.productionForecast.neighborhoodSettings.matchWellType
      useZoneAllocatedRates = caseObj.settings.productionForecast.neighborhoodSettings.useZoneAllocatedRates
    }
    

    return (
      <div className='neighbor-explanation-outer'>
        <div className='neighbor-explanation-title'>
          Analog wells are calculated based on the following criteria:
        </div>
        <div className='neighbor-explanation-row'>
          <span>&#8226;</span> {isInitial ? 'Produced' : 'Currently produce' } from the same target zone
        </div>
        { isInitial && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Started producing within the last {temporalScale} years
          </div>
        )}
        { spatialType === 'radius' && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Within the the closest {spatialScale} wells by distance 
          </div>
        )}
        { spatialType !== 'radius' && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Spatially within {spatialScale} points 
          </div>
        )}
        { matchWellTypes && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Share the same well type with target
          </div>
        )}
        { !useZoneAllocatedRates && (
          <div className='neighbor-explanation-row'>
            <span>&#8226;</span>  Do not require production allocation (wells produce from target zone only)
          </div>
        )}

      </div>
    )
  }

  render() {
    const { handleUpdateZone, selectedZone, zones, map } = this.props
    const { neighborsToDisplay } = this.state

    const isInitial = neighborsToDisplay ===  Neighbors.INITIAL
    
    let barPlotTitle = "Analog Wells - Current Oil Rates"
    let scatterPlotTitle = "Analog Wells - Current Oil Rates vs Attributes"

    if (isInitial) {
      barPlotTitle = "Analog Wells - Initial Production Rates (IPs)"
      scatterPlotTitle = "Analog Wells - IP vs Attributes"
    }

    return (
      <>
        <div className="zone-selector">
          <label>Select Target Zone</label>
          <Select
            id="zone-select"
            options={zones}
            onChange={val => handleUpdateZone(val.value)}
            isMulti={false}
            value={zones.find(z => z.value === selectedZone)}
          />
        </div>
        <div className="opportunity-production">
          <div className="top">
            <div className="left">
              { map }
            </div>
            <div className="right">
              <Card title="Zone IP History">
                { this.renderNeighborHistory() }
              </Card>
            </div>
          </div>
          {this.neigborModeEnabled && (
            <div style={{ padding: '20px 0' }}>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>Initial Neighbors</Grid>
                  <Grid item>
                    <AntSwitch
                      onChange={this.toggleNeighbors}
                      checked={this.state.neighborsToDisplay !== Neighbors.INITIAL}
                      value={this.state.neighborsToDisplay}
                    />
                  </Grid>
                  <Grid item>Current Neighbors</Grid>
                </Grid>
              </Typography>
            </div>
          )}
           <div className="upper-middle">
            <Card title=''>
              { this.renderNeighborExplanation() }
            </Card>
          </div>
          <div className="middle">
            <div className="left">
              <Card title={barPlotTitle}>
                { this.renderNeighborIP() }
              </Card>
            </div>
            <div className="right">
              <Card title={scatterPlotTitle}>
                { this.renderNeighborWells() }
              </Card>          
            </div>
          </div>
          <div className="bot">
            <Card title="Analog Wells - Attribute Table">
              { this.renderNeighborTable() }
            </Card>
          </div>
        </div>
      </>
    )
  }
}


export default ProductionForecast
