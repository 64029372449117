import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class NetPay extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Net Pay Settings
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Generate Net Pay Logs<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Description:</span> If you prefer to generate a net pay log instead of import it, you can set the definition of the net pay log here. After switching this to 'On', set the net pay definition by selecting the logs, cutoffs, and direction for the cutoffs in the rows that appear below.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(NetPay)
