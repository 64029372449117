export const ChickletConfigurations = {
  CURRENT: {
    text: 'CURRENT',
    className: 'chicklet--current',
  },
  IN_PROGRESS: {
    text: 'IN PROGRESS',
    className: 'chicklet--in-progress',
  },
  FAILED: {
    text: 'FAILED',
    className: 'chicklet--failed',
  },
  VALID: {
    text: 'VALID',
    className: 'chicklet--valid',
  },
}

export const ChickletTypes = Object.keys(ChickletConfigurations).reduce((acc, type) => {
  acc[type] = type
  return acc
}, {})
