import React from 'react'

import img1 from './Model1.jpg'

const Model = (
  <div className={'description-container'}>
    <p><b>Content:</b> Either a 2.5D or 3D model is required to get spatial representation of various properties for each zone (e.g. isochores, original hydrocarbon pore volume thickness, porosity, water saturation, etc.). For information regarding which properties are required and optional, 
    as well as how to create and export a 2.5D model (i.e. property grids) from Petrel, please refer to the Data Preparation file under resources to the section <b>Appendix: Data Preparation &amp; Quality Control</b>, subsection <b>Grid Data</b>. If you have a 3D model, required properties include net-to-gross (ntg), 
    porosity (phi), water saturation (sw), and permeability (k) (Table 14). Other properties can also be included if the user would like to incorporate them in the analysis and/or RPOS (relative probability of success, i.e., sweet spot) modeling.</p>
    <p><b>Utility:</b> Models are used in many steps of the evaluation including (but not limited to) Log/Zone Analytics. Another example of the use of models is to create zone-specific relative probability of success (RPOS or sweet spot maps), to identify the best locations for new drills.</p>
    <p><b>File Format:</b> For the 2.5D model, EarthVision grids (ASCII) will be accepted by the platform (same file type as the surfaces). For the 3D model, Gslib (ASCII) will be accepted by the platform. This file will have to be saved with the name “all.” For information regarding how to export the model from Petrel, please refer to section <b>3D Model.</b></p>
    <p><b>Units:</b> The grid needs to be in meters for SRO, however, it can be converted by data processing options when importing into SRO by turning on the setting to convert the appropriate data from feet to meters. For information regarding quality control and exporting of the 3D model, please refer to the file “Data Preparation” under resources to the section <b>Appendix: Data Preparation &amp; Quality Control</b> subsection <b>3D Model</b>.</p>
    <p><b>Important Note:</b> Reservoir zones in SRO are called intervals and are defined in the <b>Well Formation Tops (REQUIRED)</b> input file. One important item to note is that if loading a 2.5D model, there should be properties for each relevant interval, and the exact same names should be used for the intervals as are seen in the tops file.</p>
    <figure>
      <img src={img1} />
      <figcaption>Table 14: List of required and optional 3D model properties.</figcaption>
    </figure>
  </div>
)

export default Model