/* eslint-disable no-loop-func */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import React, { Component, PureComponent } from 'react'
import { fromJS } from 'immutable'
import { connect } from 'react-redux'
import autobind from 'autobind-decorator'
import randomcolor from 'randomcolor'

import SvgIcon from '@material-ui/core/SvgIcon'
import DeleteIcon from '@material-ui/icons/Delete'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import ZoomOut from '@material-ui/icons/ZoomOut'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import AddIcon from '@material-ui/icons/Add'
import SettingsIcon from '@material-ui/icons/Settings'
import HeightIcon from '@material-ui/icons/Height'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'

import LogIcon from '../../../../../static/images/LogIcon.svg'
import TopsIcon from '../../../../../static/images/TopsIcon.svg'
import OppsIcon from '../../../../../static/images/OpportunityIcon.svg'

import jQuery, { data } from 'jquery'
import * as _ from 'underscore'
import { geotoolkit } from '../../../../lib/geotoolkit/geotoolkit.adv'
import '../../../../lib/geotoolkit/geotoolkit.controls.adv'
import '../../../../lib/geotoolkit/geotoolkit.data.adv'
// import '../../../../lib/geotoolkit/geotoolkit.pdf.adv.js'
import '../../../../lib/geotoolkit/geotoolkit.widgets.adv'
import '../../../../lib/geotoolkit/geotoolkit.welllog.adv'
import '../../../../lib/geotoolkit/geotoolkit.welllog.widgets.adv'
import '../../../../lib/geotoolkit/geotoolkit.welllog.multiwell.adv'
// import '../../../../lib/geotoolkit/3dparty/jquery.min.js' 
// import '../../../../lib/geotoolkit/3dparty/jquery-ui.min.js'
import '../../../../lib/geotoolkit/3dparty/jquery.minicolors.min'
import '../../../../lib/geotoolkit/3dparty/color.min'
import '../../../../lib/geotoolkit/3dparty/bootbox.min'
import '../../../../lib/geotoolkit/3dparty/backbone-min'

window.jQuery = jQuery

require('../../../../lib/geotoolkit/3dparty/bootstrap.min.js')
require('../../../../lib/geotoolkit/3dparty/bootstrap-select.min.js')
require('../../../../lib/geotoolkit/3dparty/jquery.ui.touch-punch.min.js')
require('../../../../lib/geotoolkit/3dparty/icheck.min.js')
require('../../../../lib/geotoolkit/3dparty/jquery.dd.min.js')
require('../../../../lib/geotoolkit/geotoolkit.welllog.widgets.ui.min')
require('./CustomTrackPropertyDialog')
require('./CustomCurvePropertyDialog')

const logSettings = {
  'bs': { min: 5, max: 20, fillColor: 'blue' },
  'cali': { min: 5, max: 20, fillColor: 'green' },
  'dt': { min: 0, max: 1, fillColor: 'purple' },
  'gr': { min: 0, max: 100, fillType: 'gradient', fillColor: 'pink', fillBounds: 'belowCutOff', reverse: 1 },
  'k': { min: 0.1, max: 1000, fillColor: 'green', scale: 'log' },
  'mnp': { min: 0, max: 1, fillColor: 'blue' },
  'netPay': { min: 0, max: 1, fillColor: 'green' },
  'netRes': { min: 0, max: 1, fillColor: 'yellow' },
  'netSand': { min: 0, max: 1, fillColor: 'yellow' },
  'netSeal': { min: 0, max: 1, fillColor: 'black' },
  'none': { min: 0, max: 1, fillColor: 'black' },
  'nphi': { min: 0, max: 30, fillColor: 'green' },
  'pe': { min: 0, max: 10, fillColor: 'red' },
  'perfs': { min: 0, max: 1, fillColor: 'red' },
  'phi': { min: 0, max: 0.5, fillType: 'gradient', fillColor: 'hot', reverse: 1 },
  'pnp': { min: 0, max: 1, fillColor: 'red' },
  'resD': { min: 0.2, max: 2000, fillType: 'gradient', fillColor: 'parula', scale: 'log' },
  'resM': { min: 0.2, max: 2000, fillType: 'gradient', fillColor: 'parula', scale: 'log' },
  'resS': { min: 0.2, max: 2000, fillType: 'gradient', fillColor: 'parula', scale: 'log' },
  'rhob': { min: 2, max: 3, fillColor: 'blue' },
  'rockType': { min: 5, max: 20, fillColor: 'purple' },
  'sigma': { min: 0, max: 100, fillColor: 'blue', fillBounds: 'belowCutOff' },
  'sigmaDate': { min: 1980, max: 2017, fillColor: 'purple' },
  'sp': { min: -200, max: 200, fillColor: 'purple' },
  'sstvd': { min: 5, max: 20, fillColor: 'black' },
  'sw': { min: 0, max: 1, fillType: 'gradient', fillColor: 'winter', fillBounds: 'belowCutOff', reverse: 0 },
  'unp': { min: 0, max: 1, fillColor: 'blue' },
  'vcl': { min: 0, max: 1, fillColor: 'yellow', fillBounds: 'belowCutOff' },
  'so': { min: 0, max: 1, fillColor: 'green' },
  'baffles': { min: 0, max: 1, fillColor: 'black' },
}

@autobind class WellLogPlot extends PureComponent {
  componentWillMount() {
    this.defaultProperties = [
      'gr',
      'k',
      'phi',
      'resD',
      'sw',
    ]

    this.LOG_MAGIC_VALUE = -999.25
    this.MAX_TRACK_RESIZE = 15
    this.MIN_TRACK_RESIZE = 8

    this.availableProperties = []
    this.wells = []
    this.combinedData = []
    this.multiWellWidget = null
    this.multiWellZoomLevels = {}
    this.trackPropertyDialogs = {}
    this.curvePropertyDialogs = {}
    this.horizontalScale = 0
    this.isfitToBounds = false
    this.epsilon = 10e-10
    this.initialized = false
    this.disposing = false

    this.setState({
      currentSelection: null,
    })
  }

  componentDidMount() {
    const { data, neighborLogs, selectedOffsetWells } = this.props
    if (data.length || (neighborLogs.length && selectedOffsetWells.length)) {
      this.initialize()
      this.loadTemplate()
      this.setZoomAfterUpdate()
    }
    window.addEventListener('resize', this.onResize)
    window.onbeforeunload = this.saveTemplate
  }

  componentDidUpdate(prevProps) {
    const { neighborLogs, data } = this.props
    
    if (JSON.stringify(neighborLogs) !== JSON.stringify(prevProps.neighborLogs)) {
      this.reinitialize()
    } else if((!prevProps.data || prevProps.data.length === 0) && data.length > 0 ){
      this.reinitialize()
    }
  }

  componentWillUnmount() {
    this.saveTemplate()
    this.saveZoom()
    if (this.initialized) {
      this.disposing = true
      this.multiWellWidget.dispose()
      this.multiWellWidget.clearCache()
    }
    window.removeEventListener('resize', this.onResize)
  }

  saveTemplate() {

    //NOTE local storage/window template is removed when the visualization component unmounts, in the vizualization component

    console.log('saving template...')
    if(this.disposing) return

    //Save MultiWellWidget template
    const multiWellTemplate = this.multiWellWidget.saveTemplate()

    //Set up to get the templates in each well in multiwellwidget
    let wellLogPlots = this.multiWellWidget.getTrackContainer().getChildren()
    let wellLogTemplate = {}

    if(!wellLogPlots || !wellLogPlots.eu || wellLogPlots.eu.length === 0) return

    /*
      Note to future dev debugging:

      When updating the INT software, the direct references to Geotoolkit objects in this component will change. 
      See above: wellLogPlots.eu.  This can change to something like wellLogPlots.ju.  
      I think above is the only direct-reference left in the code.  I tried to remove as many as possible.
      Anyway, change those to the appropriate references if experiencing bugs after updating INT software.
    */
    
    wellLogPlots.forEach(logPlot => {
      if (typeof logPlot.saveTemplate !== 'function') return


      //Get WellLogPlot template and bounds
      const template = logPlot.saveTemplate()
      const visibleBounds = logPlot.getVisibleDepthLimits()

      //Get WellLogPlot child template
      let logPlotChildren = logPlot.getChildren()
      const childTemplate = logPlotChildren.eu.length > 0 ? logPlotChildren.eu[0].saveTemplate() : null

      //Get Track's LogCurve Fills
      let trackCount = logPlot.getTracksCount()
      const tracks = {}

      //Iterate over all the tracks.  Get their LogCurve and Fill info.  Gradients aren't saved in templates.
      for(let i = 0; i < trackCount; i ++){
        let track = logPlot.getTrackAt(i)
        let trackChildren = track.getChildren()
        let logCurves = trackChildren.filter(x => x instanceof geotoolkit.welllog.CompositeLogCurve)

        if(logCurves.length > 0){
          tracks[i] = {}
          tracks[i].curves = {}
        } 
        else continue

        //Get the properties of all the curves on the track.
        for(let j = 0; j < logCurves.length; j ++) {
          let curve = logCurves[j]

          let leftFill = curve.getLeftFill()
          let leftFillStyle = leftFill.getFillStyle()
          const leftIsGradient = leftFillStyle.getStyleType()  === "STYLE_TYPE_GRADIENT" ? true : false
          const leftGradientStyle = leftIsGradient ? leftFillStyle.getColorProvider().getProperties().scale : null
          const leftFillColor = leftFillStyle.getColor()
          const { visible: leftFillVisible } = leftFill.getProperties()
          
          let rightFill = curve.getRightFill()
          let rightFillStyle = rightFill.getFillStyle()
          const rightIsGradient = rightFillStyle.getStyleType() === "STYLE_TYPE_GRADIENT" ? true : false
          const rightGradientStyle = rightIsGradient ? rightFillStyle.getColorProvider().getProperties().scale : null
          const rightFillColor = rightFillStyle.getColor()
          const { visible: rightFillVisible } = rightFill.getProperties()

          tracks[i].curves[j] = {
            prop: curve.name,
            limits: {
              min: curve.getMinimumNormalizationLimit(),
              max: curve.getMaximumNormalizationLimit(),
            },
            left: {
              isGradient: leftIsGradient,
              gradientStyle: leftGradientStyle,
              fillColor: leftFillColor,
              isVisible: leftFillVisible
            },
            right: {
              isGradient: rightIsGradient,
              gradientStyle: rightGradientStyle,
              fillColor: rightFillColor,
              isVisible: rightFillVisible
            }
          }

        }

        //Save all of em
        wellLogTemplate = {
          template,
          childTemplate,
          tracks,
          visibleBounds,
        }
      }
    })

    if(!window.template) window.template = {}

    window.template = {
      multiWellTemplate,
      wellLogTemplate,
    }

    localStorage.setItem('template', JSON.stringify(window.template))
  }

  loadTemplate() {

    // const template = window.template
    let template = localStorage.getItem('template')

    if(!template || this.disposing) return
    template = JSON.parse(template)
    console.log('loading template...')

    let wellLogPlots = this.multiWellWidget.getTrackContainer().getChildren()

    console.log('WLPs', wellLogPlots)

    const { multiWellTemplate, wellLogTemplate } = template

    //Load multiWell template
    this.multiWellWidget.loadTemplate(multiWellTemplate)
    
    console.log('STEP 1')
    wellLogPlots.forEach(logPlot => {

      if(typeof logPlot.saveTemplate !== 'function' || typeof logPlot.getTitle !== 'function') return 
      
      //We will use this later to reset the track ID.
      const wellId = logPlot.getTitle()

      const { template, visibleBounds, childTemplate, tracks } = wellLogTemplate
      console.log('STEP 2')
      //Load WellLogPlot template and set bounds
      logPlot.loadTemplate(template)

      //Load WellLogPlot child template
      // TODO - i have no clue what this function does, but it was breaking with the int
      // update so I only ran it if it works for now -Schaeffer 10/01/2020
      if (logPlot.getChildren().eu) {
        logPlot.getChildren().eu[0].loadTemplate(childTemplate)
      } else {
        console.log('this rando function broke, did it without')        
      }

      console.log('STEP 3')
      //Load track data and log curves
      for(const i in tracks){
        const track = logPlot.getTrackAt(i)

        let logCurves = track.getChildren().filter(x => x instanceof geotoolkit.welllog.CompositeLogCurve)

        if(!logCurves || logCurves.length === 0) continue
        
        let initialProp
        for(const j in logCurves){
          const curve = logCurves[j]
          console.log('STEP 4')
          //Find the data
          const prop = tracks[i].curves[j].prop
          if(j === 0) initialProp = prop
          const raw = this.combinedData.flat().find(entry => entry.wellId === wellId && entry.property === `qgeo_${prop}`)
          if(!raw) continue  //Need to remove header information when there's no data at some point.
          const data = this.createTestData(raw.start, raw.stop, raw.values, prop)
        
          //Set the data
          curve.setData(data)
          
          //Set the normalization limits
          const { min, max } = tracks[i].curves[j].limits
          curve.setNormalizationLimits(min, max)
          console.log('STEP 5')
          //Gotta make log gradients, cause those don't get saved in templates
          const createLogFills = (fillObject, curve) => {
            const { isGradient, gradientStyle, fillColor, isVisible, side } = fillObject
            const settings = logSettings[prop]

            const min = settings.reverse === 1 ? 0 : 1
            const max = settings.reverse === 1 ? 1 : 0

            //Don't need to rebuild fill style if not gradient, it's saved in the template
            //Might as well since we already stored the data.
            const color = !isGradient ?
              new geotoolkit.attributes.FillStyle(fillColor) :
              new geotoolkit.welllog.attributes.LogGradientStyle()
                .setColorProvider(
                  new geotoolkit.util.DiscreteGradientColorProvider({ 'bins': 255 })
                    .setScale(gradientStyle, min, max),
                )
                .setLogData(curve.getDataSource())
                .setTransparency(150)
            
            const options = {
              'visible': isVisible,
              'curve1': curve,
              'fillstyle': color
            }

            //We want right fill to be between the curve and right border, only on right fill
            if(side === 'right') options['curve2'] = 1

            let logFill = new geotoolkit.welllog.LogFill(options)
            return logFill
          }
          
          const { left, right } = tracks[i].curves[j]
          left.side = 'left'
          right.side = 'right'
          
          curve.setLeftFill(createLogFills(left, curve))
          curve.setRightFill(createLogFills(right, curve))
        }

      }
      console.log('STEP 6')
      //reset the track ID'S.
      const trackCount = logPlot.getTracksCount()
      for(let k = 0; k < trackCount; k++){
        logPlot.getTrackAt(k).setId({ 'id': `${wellId}-${k + 1}`, 'wellId': wellId })
      }
    })
  }

  setDefaultCurveSettings(curve, index = 1) {

    const hues = ['green', 'orange', 'red', 'green', 'blue']
    const cname = curve.name
    const settings = logSettings[cname] ? logSettings[cname] : null
    let leftLogRef = null
    let rightLogRef = null

    

    // const randomAlpha = Math.floor(Math.random() * (255 - 204 + 1)) + 204
    const hue = hues[index % hues.length]
    const randomColor = geotoolkit.util.ColorUtil.parseColor(randomcolor({ seed: cname, hue }))
    // randomColor.alpha = 125

    

    const track = curve.getParent()
    const min = settings.min
    const max = settings.max
    let color = settings ? settings.fillColor : randomColor.toRgbaString()
    color = new geotoolkit.attributes.FillStyle(color)
    console.log('set default curve settings')
    console.log('curve:', cname, 'color:', color)
    const fillBounds = settings.fillBounds === 'belowCutOff'
      ? geotoolkit.welllog.LogFill.FillType.Right
      : geotoolkit.welllog.LogFill.FillType.Left

    if (settings.fillType && settings.fillType === 'gradient') {
      const scaleMin = settings.reverse === 1 ? 0 : 1
      const scaleMax = settings.reverse === 1 ? 1 : 0
      color = new geotoolkit.welllog.attributes.LogGradientStyle()
        .setColorProvider(
          new geotoolkit.util.DiscreteGradientColorProvider({ 'bins': 255 })
            .setScale(settings.fillColor, scaleMin, scaleMax),
        )
        .setLogData(curve.getDataSource())
        .setTransparency(150)
    }

    const refLine = fillBounds === geotoolkit.welllog.LogFill.FillType.Right
      ? new geotoolkit.welllog.LogReferenceLine(1)
      : null

    if (settings.scale === 'log') {
      curve.setLogarithmicScale(true)
    }

    if (fillBounds === geotoolkit.welllog.LogFill.FillType.Left) {
      /*
      curve
        .setLeftReferencePointSet((leftLogRef = new geotoolkit.welllog.LogReferenceLine(0.5)))
      leftLogRef.setName('leftReferenceLine')
      */
      curve
        .setLeftFill(new geotoolkit.welllog.LogFill({
          'visible': true,
          'curve1': curve,
          'fillstyle': color,
        }))
        .setRightFill(new geotoolkit.welllog.LogFill({
          'visible': false,
          'curve1': curve,
          'curve2': 1,
        }))
        .setLineStyle({
          'color': color,
          'width': 1,
        })
        .setNormalizationLimits(min, max)
    } else if (fillBounds === geotoolkit.welllog.LogFill.FillType.Right) {
      curve
        .setRightFill(new geotoolkit.welllog.LogFill({
          'visible': true,
          'curve1': curve,
          'curve2': 1,
          'fillstyle': color,
        }))
        .setLeftFill(new geotoolkit.welllog.LogFill({
          'visible': false,
          'curve1': curve,
        }))
        .setLineStyle({
          'color': color,
          'width': 1,
        })
        .setNormalizationLimits(min, max)
    }

    if (track != null) {
      const idx = track.indexOfChild(curve)
      if (leftLogRef !== null) {
        track.insertChild(idx + 1, leftLogRef)
      }
      if (rightLogRef !== null) {
        track.insertChild(idx + 2, rightLogRef)
      }
    }
  }

  reinitialize() {
    if (this.initialized) {
      this.saveTemplate()
      this.saveZoom()
      this.multiWellWidget.dispose()
      this.multiWellWidget.clearCache()
      this.initialize()
      this.loadTemplate()
      this.setZoomAfterUpdate()
    }
  }

  initialize() {
    const { id, data, neighborLogs, selectedOffsetWells, singleOpportunity } = this.props
    const defaultSections = this.props.defaultSections || this.defaultProperties

    this.wells = []
    this.combinedData = []

    this.widget = new geotoolkit.welllog.multiwell.MultiWellWidget({
      'horizontalscrollable': true,
      'verticalscrollable': 'floating',
      'trackcontainer': {
        'border': { 'visible': false },
        //'scaleUnit': geotoolkit.util.UnitFactory.getInstance().getUnit('metre'),
      },
      'border': { 'visible': false },
      'header': {
        'visible': true,
        'height': 100,
      },
      'scroll': {
        'headerverticalscroll': {
          //'type': 'geotoolkit.controls.tools.scroll.CompactVerticalScroll',
          //'size': 11,
          'visible': false,
          'verticallyscrollable': false,
          'options': {
            'resizable': false
          }
        },
      }
    })
      .setLayoutStyle({
        'left': '0',
        'top': '0',
        'right': '0',
        'bottom': '0',
      })

    const widgetPlot = new geotoolkit.plot.Plot({
      'canvasElement': document.getElementById(id),
      'root': this.widget,
      'autoSize': true,
      'autoRootBounds': true
    })

    this.plot = widgetPlot
    this.multiWellWidget = widgetPlot.getRoot()

    //Only for debugging - WY 8/19/20
    window.parent = this
    window.widget = this.widget


    /*
    let nL = []
    if (neighborLogs !== undefined && selectedOffsetWells.length) {
      nL = selectedOffsetWells.map(n => Object.values(neighborLogs[n]))
    }
    */

    if (data[0] && data[0].length) {
      this.combinedData = data.concat(neighborLogs)
    } else {
      this.combinedData = neighborLogs
    }

    //Have to filter neighbor logs because duplicates are coming in...
    let logs = {}

    this.combinedData = this.combinedData.filter(logArray => {
      if(!logs[logArray[0].wellId]) {
        logs[logArray[0].wellId] = true
        return true
      } else if (logs[logArray[0].wellId]) return false
    })
    //----------------------//

    if (this.combinedData.length) {
      this.availableProperties = [...new Set(this.combinedData.map(well => well.map(x => x.property.replace('qgeo_', ''))).flat())]
    }

    this.combinedData.forEach((well, idx) => {
      // const positions = new geotoolkit.util.Range(Math.random() * 100, this.widget.getBounds().getHeight())
      let correlation
      if ((this.wells && this.wells.length > 0) || this.multiWellWidget.getTracksCount() > 0) {
        correlation = this.widget.createTrack(geotoolkit.welllog.multiwell.TrackType.CorrelationTrack, {
          'width': 75,
        })
        if (this.wells) {
          this.wells.push(correlation)
        } else {
          /* this.multiWellWidget.addTrack(correlation)asdf */
        }
        this.multiWellWidget.addTrack(correlation)
      }
      
      const track = this.multiWellWidget.addTrack(geotoolkit.welllog.multiwell.TrackType.WellTrack,
        {
          'title': well[0].wellId,
          'width': 0,
          'range': new geotoolkit.util.Range(0, 700),
          'welllog': {
            'range': new geotoolkit.util.Range(well[0].start, well[0].stop),
          },
        })
      track.addTrack(geotoolkit.welllog.TrackType.IndexTrack)
      track.getTrackContainer().setScaleUnit(geotoolkit.util.UnitFactory.getInstance().getUnit('metre'))

      defaultSections.forEach((prop, index) => {
        const pTrack = track.addTrack(geotoolkit.welllog.TrackType.LinearTrack, geotoolkit.welllog.TrackDirection.Last, 200)
          .setId({ 'id': prop, 'wellId': well[0].wellId })

        const propData = well.find(p => p.property.replace('qgeo_', '') === prop)
        if (propData) {
          const cName = prop
          const curve = this.createCurve(this.createTestData(propData.start, propData.stop, propData.values, cName))

          pTrack.addChild([curve])
          this.setDefaultCurveSettings(curve, index)
        }
      })

      this.wells.push(track)
      this.addNavigation(track)
      this.addDefaultCorrelation(track, well[0])
      this.configureHeaders()
      this.addContactMarkers(track)

      /* Only add the opportunity markers for the first track AKA the opportunity */
      if (idx === 0) {
        this.addOpportunityMarkers(track, singleOpportunity)
      }
    })

    this.addFillCorrelation()

    widgetPlot
      .getRoot()
      .getToolByName('pick')
      .addListener(geotoolkit.controls.tools.Selection.Events.onPick, (sender, eventArgs) => {
        eventArgs.preventDefault()
        if (eventArgs.getSelection().length === 0) {
          return
        }
        this.setState({
          currentSelection: eventArgs.getSelection(),
        })
      })

    widgetPlot.getRoot().on(geotoolkit.controls.tools.Selection.Events.onDoubleClick, (event, sender, eventArgs) => {
      eventArgs.preventDefault()
      if (eventArgs.getSelection().length === 0) {
        return
      }

      const markerSelection = eventArgs.getSelection().find((node) => node instanceof geotoolkit.welllog.LogMarker)
      if (markerSelection) {
        this.multiWellWidget.alignToMarker(markerSelection.getNameLabel())
        return
      }

      this.showPropertiesDialogForSelection(eventArgs.getSelection())
    })

    const toolsContainer = new geotoolkit.controls.tools.ToolsContainer(widgetPlot)
    toolsContainer.add(this.multiWellWidget.getTool())

    this.initGUI()
    this.createNavigationWidget(this.multiWellWidget)
    this.initialized = true
    //this.widget.getTrackContainer().setScaleUnit(geotoolkit.util.UnitFactory.getInstance().getUnit('metre'))
  }

  // eslint-disable-next-line class-methods-use-this
  createCurve(dataSource) {
    const randomAlpha = Math.floor(Math.random() * (255 - 204 + 1)) + 204
    const randomColor = geotoolkit.util.ColorUtil.parseColor(geotoolkit.util.ColorUtil.getRandomColorRgb())
    randomColor.alpha = randomAlpha

    const limits = geotoolkit.util.Math.calculateRoundedLimits(dataSource.getMinValue(), dataSource.getMaxValue())
    return new geotoolkit.welllog.CompositeLogCurve(dataSource)
      .setLineStyle({
        'color': randomColor.toRgbaString(),
        'width': 1,
      })
      .setNormalizationLimits(limits)
  }

  // eslint-disable-next-line class-methods-use-this
  createTestData(from, end, data, name) {
    const newdata = new geotoolkit.welllog.data.LogData(name)
    const depths = []
    const values = []
    const amountOfPoints = data.length
    const stepDistance = (end - from) / amountOfPoints
    for (let i = 0; i < amountOfPoints; i += 1) {
      if (data[i] !== this.LOG_MAGIC_VALUE) {
        depths.push(i * stepDistance + from)
        values.push(data[i])
      }
    }
    newdata.setValues(depths, values)
    return newdata
  }

  // eslint-disable-next-line class-methods-use-this
  configureHeaders() {
    const headerProvider = geotoolkit.welllog.header.LogVisualHeaderProvider.getDefaultInstance()

    // configure Depth ant Time axis header
    const logAxisVisualHeader = headerProvider.getHeaderProvider(geotoolkit.welllog.LogAxis.getClassName())
    logAxisVisualHeader.setHeaderType(geotoolkit.welllog.header.LogAxisVisualHeader.HeaderType.Simple)

    // configure curve header
    const header = new geotoolkit.welllog.header.AdaptiveLogCurveVisualHeader()
      .setElement({
        'ScaleTo': { 'horizontalpos': 'right', 'verticalpos': 'top' },
        'ScaleFrom': { 'horizontalpos': 'left', 'verticalpos': 'top' },
        'Line': { 'horizontalpos': 'center', 'verticalpos': 'center' },
        'Name': { 'horizontalpos': 'center', 'verticalpos': 'top' },
        'Unit': { 'horizontalpos': 'center', 'verticalpos': 'bottom' },
        'Tracking': { 'horizontalpos': 'center', 'verticalpos': 'bottom' },
      })
    headerProvider.registerHeaderProvider(geotoolkit.welllog.CompositeLogCurve.getClassName(), header)
  }

  addOpportunityMarkers(track, opportunity) {
    this.addOpportunityMarker(track, opportunity.startDepthMd, 'OPPORTUNITY START')
    this.addOpportunityMarker(track, opportunity.stopDepthMd, 'OPPORTUNITY END')
  }

  addOpportunityMarker(track, depth, label, lineColor, labelColor, font) {
    if (depth === undefined) {
      return
    }

    if (geotoolkit.interfaceCast(track, geotoolkit.welllog.multiwell.IWellTrack)) {
      const startMarker = new geotoolkit.welllog.LogMarker(depth)
      startMarker.setLineStyle(geotoolkit.attributes.LineStyle.fromObject({
        'color': 'red',
        'width': 2,
      }))
      startMarker.setTextStyle(geotoolkit.attributes.TextStyle.fromObject({
        'color': 'red',
        'alignment': 'center',
        'font': 'bold 12px sans-serif',
      }))
      startMarker.setNameLabel(label)
      startMarker.setNameLabelPosition(geotoolkit.util.AnchorType.TopCenter)
      startMarker.setDepthLabel(depth.toFixed(0))
      startMarker.setDepthLabelPosition(geotoolkit.util.AnchorType.BottomCenter)
      track.getMarkerLayer().addChild(startMarker)
    }
  }

  addContactMarkers(track){
    const { wellData } = this.props

    if(!wellData) return
    
    if(!geotoolkit.interfaceCast(track, geotoolkit.welllog.multiwell.IWellTrack)) return

    const wellId = track.getTitle()
    const data = wellData[wellId]

    if(!data || !data.contacts) return

    data.contacts.forEach(contact => {
      const { zoneId, owc_md, goc_md } = contact
      console.log(zoneId, 'owc', owc_md, 'goc', goc_md)
      if(owc_md) {
        console.log('ADDING OWC')
        this.addContactMarker(track, owc_md, zoneId, 'OWC')
      }
      if(goc_md) {
        console.log('ADDING GOC')
        this.addContactMarker(track, goc_md, zoneId, 'GOC')
      }
    })
  }

  addContactMarker(track, depth, zone, type) {
    if (depth === undefined) {
      return
    }
    const hasMarkerAtDepth = (node) => (node instanceof geotoolkit.welllog.LogMarker) && node.getDepth() === depth
    let existingMarker = geotoolkit.selection.from(track.getMarkerLayer()).where(hasMarkerAtDepth).selectFirst()
    console.log('existing marker', existingMarker)

    if(existingMarker){
      let label = existingMarker.getNameLabel()
      let existingType = label.split(' ').pop()

      console.log('found a marker', label, existingMarker)

      if(type === existingType){
        existingMarker.setNameLabel(zone + ', ' + label)
        return
      }

    }

    const blue = geotoolkit.util.ColorUtil.parseColor('blue')
    const red = geotoolkit.util.ColorUtil.parseColor('red')
    let color = type === 'OWC' ? blue : red
    color.alpha = 170


    if (geotoolkit.interfaceCast(track, geotoolkit.welllog.multiwell.IWellTrack)) {
      const startMarker = new geotoolkit.welllog.LogMarker(depth)
      startMarker.setLineStyle(geotoolkit.attributes.LineStyle.fromObject({
        'color': color,
        'width': 2,
        // 'pattern': [20, 4], //I guess this makes a dash???  geotoolkit.attributes.LineStyle
      }))
      startMarker.setTextStyle(geotoolkit.attributes.TextStyle.fromObject({
        'color': color,
        'alignment': 'center',
        'font': 'bold 12px sans-serif',
      }))
      startMarker.setNameLabel(zone + ' - ' + type)
      startMarker.setNameLabelPosition(geotoolkit.util.AnchorType.TopCenter)
      startMarker.setDepthLabel(depth.toFixed(0))
      startMarker.setDepthLabelPosition(geotoolkit.util.AnchorType.BottomCenter)
      track.getMarkerLayer().addChild(startMarker)
    }
  }

  addTopsCorrelation(track, depth, name, color) {
    if (!depth) {
      return
    }

    const isMarker = (node) => {
      return (node instanceof geotoolkit.welllog.LogMarker) && node.getDepth() === depth
    }
    const isMarkerCorrelation = (node) => {
      return (node instanceof geotoolkit.welllog.multiwell.correlation.CorrelationMarker)
        && (node.getLeftDepth() === depth) && (node.getRightDepth() === depth)
    }
    // for (let i = 0; i < this.multiWellWidget.getTracksCount(); i += 1) {
    // const track = this.multiWellWidget.getTrackAt(i)
    if (geotoolkit.interfaceCast(track, geotoolkit.welllog.multiwell.IWellTrack)) {


      let top = geotoolkit.selection.from(track.getMarkerLayer()).where(isMarker).selectFirst()
      if (!top) {
        top = new geotoolkit.welllog.LogMarker(depth)
        top.setLineStyle(geotoolkit.attributes.LineStyle.fromObject({ 'color': color }))
        top.setTextStyle(geotoolkit.attributes.TextStyle.fromObject({
          'color': 'black',
          'alignment': 'left',
          'font': '12px sans-serif',
        }))
        top.setNameLabel(name)
        top.setNameLabelPosition(geotoolkit.util.AnchorType.TopCenter)
        top.setDepthLabel(depth.toFixed(0))
        top.setDepthLabelPosition(geotoolkit.util.AnchorType.BottomCenter)
        track.getMarkerLayer().addChild(top)
      }
    }
    // }
  }

  addDefaultCorrelation(track, well) {
    const wellEnd = well.end
    const zones = well.zones
    const hues = ['green', 'orange', 'red', 'green', 'blue']
    // const randomAlpha = Math.floor(Math.random() * (255 - 204 + 1)) + 204
    const colors = {
      'R1_Upper': 'green',
      'R1_Lower': 'blue',
      'R2_Upper': 'red',
      'R2_Lower': 'purple',
      'R3_Upper': 'cyan',
      'R3_Lower': 'brown',
      'Base_Sims': 'yellow',
    }

    // let last = false
    // let depthEnd = wellEnd
    for (let i = 0; i < zones.length; i += 1) {
      // last = i === zones.length - 1
      // depthEnd = last ? wellEnd : zones[i + 1].depth

      // this.addFillCorrelation(zones[i].depth, depthEnd, colors[zones[i].name])
      const hue = hues[i % hues.length]
      const randomColor = geotoolkit.util.ColorUtil.parseColor(randomcolor({ seed: zones[i].name, hue }))
      randomColor.alpha = 230
      this.addTopsCorrelation(track, zones[i].depth, zones[i].name, randomColor.toRgbaString())
    }
  }

  addNavigation(well) {
    const crossHairTool = well.getToolByName('cross-hair')
    crossHairTool.getCompositeTools().forEach((compositeTool) => {
      const wellNavigation = new geotoolkit.welllog.multiwell.tools.WellNavigation(well, {
        'width': 100,
      })
      compositeTool.insert(0, wellNavigation)
      this.updateNavigation(wellNavigation, well)
    })
  }

  updateNavigation(wellNavigation, well) {
    wellNavigation.addListener(geotoolkit.welllog.widgets.tools.Navigation.Events.DepthRangeChanged,
      (sender, eventArgs) => {
        const visibleDepthLimits = well.getVisibleDepthLimits()
        const newVisibleDepthLimits = eventArgs.limits
        const heightDiff = newVisibleDepthLimits.getSize() - visibleDepthLimits.getSize()
        if (well.getDepthLimits().intersects(newVisibleDepthLimits) === false) return
        if (heightDiff === 0) {
          this.widget.scrollTrackByDepth(well, visibleDepthLimits.getLow() - newVisibleDepthLimits.getLow())
        } else if (well.getDepthLimits().contains(newVisibleDepthLimits)) {
          well.setVisibleDepthLimits(newVisibleDepthLimits)
        }
      })
  }

  createNavigationWidget(targetWidget) {
    const widget = new geotoolkit.welllog.widgets.WellLogWidget({
      'horizontalscrollable': false,
      'verticalscrollable': false,
      'trackcontainer': {
        'border': { 'visible': false },
      },
      'header': {
        'visible': false,
      },
      'border': { 'visible': true },
    })
      .setDataBinding(null)
    // .setDepthLimits(targetWidget.getDepthLimits())

    widget.addTrack(geotoolkit.welllog.TrackType.IndexTrack)
      .setWidth(100)
      .addChild([
        new geotoolkit.welllog.LogCurve(null),
      ])

    // initializing tools
    widget.setIndexUnit('m')
    // do not allow user to select visuals
    widget.getToolByName('pick')
      .setEnabled(false)
    // do not allow user to pinchzoom index track
    widget.getToolByName('pinchtozoom')
      .setEnabled(false)
    // do not allow user resize tracks
    widget.getToolByName('splitter')
      .setEnabled(false)
    // disable cross-hair
    widget.getToolByName('cross-hair')
      .setEnabled(false)
    // and change scale
    widget.getToolByName('TrackPanning')
      .setEnabled(false)

    window.navigationTool = new geotoolkit.welllog.widgets.tools.Navigation(widget.getToolByName('cross-hair').getManipulatorLayer())
      .setOptions({
        'rangeEdit': false,
      })
    widget.getTool().add(window.navigationTool)
    return widget
  }

  // eslint-disable-next-line class-methods-use-this
  showPropertiesDialogForSelection(selection) {
    const selectionProperties = selection[0].getProperties()
    const titleParams = {
      wellId: selectionProperties.id.wellId,
      trackNumber: selectionProperties.name.split(" ").pop(),
    }
    // const { data } = this.props
    let logCurve = null
    let track = null
    let leftLogRef = null
    let rightLogRef = null
    for (let i = 0; i < selection.length; i += 1) {
      const visual = selection[i]
      if (visual instanceof geotoolkit.welllog.LogCurve) {
        logCurve = visual
      } else if (visual instanceof geotoolkit.welllog.header.LogCurveVisualHeader) {
        logCurve = visual.getVisual()
      } else if (visual instanceof geotoolkit.welllog.LogTrack) {
        track = visual
      }
    }
    if (this.propertyDialog != null) {
      this.propertyDialog.remove()
    }
    if (logCurve) {
      titleParams.curveName = logCurve.name
    }
    const DialogView = geotoolkit.welllog.widgets.ui.PropertiesDialogView
    let dialogModel
    let cd

    let idx
    if (logCurve != null) {
      if (logCurve.getLeftFill() == null) {
        logCurve.setLeftReferencePointSet((leftLogRef = new geotoolkit.welllog.LogReferenceLine(0.0)))
        leftLogRef.setName('leftReferenceLine')
        logCurve.getLeftFill().setVisible(false).setFillType(geotoolkit.welllog.LogFill.FillType.Right)
      }
      if (logCurve.getRightFill() == null) {
        logCurve.setRightReferencePointSet((rightLogRef = new geotoolkit.welllog.LogReferenceLine(1)))
        rightLogRef.setName('rightReferenceLine')
        logCurve.getRightFill().setVisible(false).setFillType(geotoolkit.welllog.LogFill.FillType.Left)
      }

      if (track != null) { // needs to add references line to the track as well
        idx = track.indexOfChild(logCurve)
        if (leftLogRef !== null) {
          track.insertChild(idx + 1, leftLogRef)
        }
        if (rightLogRef !== null) {
          track.insertChild(idx + 2, rightLogRef)
        }
      }

      const { wellId, trackNumber, curveName } = titleParams

      // if (this.curvePropertyDialogs && this.curvePropertyDialogs[wellId] && this.curvePropertyDialogs[wellId][trackNumber] && this.curvePropertyDialogs[wellId][trackNumber][curveName]) {
      //   cd = this.curvePropertyDialogs[wellId][trackNumber][curveName]
      // } else {
        dialogModel = new geotoolkit.welllog.widgets.ui.CustomCurvePropertyDialog().parse(logCurve, logCurve.getLeftFill(), logCurve.getRightFill(), titleParams)
        // dialogModel = new geotoolkit.welllog.widgets.ui.CurvePropertyDialog().parse(logCurve)
        cd = new DialogView({
          'model': dialogModel,
        })
        cd.on('saved', (src) => {
           //dialogModel.save(logCurve)

          this.multiWellWidget.getTrackContainer().getChildren().forEach((trx) => {                //trx = WellLogPlot for a well, has 3-6 tracks on it.
            if (geotoolkit.isInstanceOf(trx, geotoolkit.welllog.multiwell.IWellTrack)) {
              for (let j = 0; j < trx.getTracksCount(); j += 1) {
                if (trx.getTrackAt(j).name === track.name) {

                  trx.getTrackAt(j).getChildren()
                    .filter(x => x.getName() === logCurve.getName())
                    .forEach((c) => {
                      dialogModel.save(c, c.getLeftFill(), c.getRightFill())
                      c.getParent()
                    })
                }
              }
            }
          })

          this.multiWellWidget.clearCache()
          this.multiWellWidget.updateLayout()

          /* dialogModel.save(logCurve, logCurve.getLeftFill(), logCurve.getRightFill()) */
        })
      // }

      //Saving the dialogs to be accessed later.  Different dialogs for different tracks.
      // this.curvePropertyDialogs = this.curvePropertyDialogs || {}
      // this.curvePropertyDialogs[wellId] = this.curvePropertyDialogs[wellId] || {}
      // let wellObject = this.curvePropertyDialogs[wellId]
      // wellObject[trackNumber] = wellObject[trackNumber] || {}
      // let trackObject = wellObject[trackNumber]
      // trackObject[curveName] = cd

      cd.show()

    } else if (track != null && track.getId() !== 'INDEX') { // Filter index track for now

      console.log('TRACK', track)
      let wellId = track.id.wellId
      const wellProperties = this.combinedData.filter(dataArr => dataArr[0].wellId === wellId)[0].map(x => x.property.replace('qgeo_', ''))
      console.log('WELL PROPERTIES', wellProperties)

      dialogModel = new geotoolkit.welllog.widgets.ui.CustomTrackPropertyDialog().parse(track, wellProperties, titleParams)
      cd = new DialogView({
        'model': dialogModel,
      })

      cd.on('saved',
        (src) => {
          const curveTabs = src.model.attributes.tabs[1]
          const curveOptions = curveTabs.attributes.properties.models

          const selectedCurves = curveOptions.filter(x => x.attributes.model.attributes.value === true)

          this.multiWellWidget.getTrackContainer().getChildren().forEach((trx) => {
            if (geotoolkit.isInstanceOf(trx, geotoolkit.welllog.multiwell.IWellTrack)) {
              for (let j = 0; j < trx.getTracksCount(); j += 1) {
                if (trx.getTrackAt(j).name === track.name) {
                  const { wellId } = trx.getTrackAt(j).getId()

                  // Clear all curves before adding new ones
                  trx.getTrackAt(j).getChildren()
                    .filter(x => x instanceof geotoolkit.welllog.CompositeLogCurve)
                    .forEach(t => trx.getTrackAt(j).removeChild(t))

                  // eslint-disable-next-line no-loop-func
                  selectedCurves.forEach((sc) => {
                    const attr = sc.attributes
                    const curveName = 'qgeo_' + attr.title
                    const prop = this.combinedData.flatten().find(entry => entry.wellId === wellId && entry.property === curveName)
                    if (prop) {
                      const cName = prop.property.replace('qgeo_', '')
                      const curveData = this.createTestData(prop.start, prop.stop, prop.values, cName)
                      this.addCurveToTrack(curveData, trx.getTrackAt(j))
                    }
                  })
                  break
                }
              }
            }
          })

          dialogModel.save(track)
        })

      cd.show()
    }
  }

  addFillCorrelation() {
    const colors = {
      'R1_Upper': '#7fbf7f',
      'R1_Lower': '#7f7eff',
      'R2_Upper': '#fe7f7f',
      'R2_Lower': '#bf7fbf',
      'R3_Upper': '#7fffff',
      'R3_Lower': '#d19394',
      'Base_Sims': '#feff7f',
    }

    const isRangeCorrelation = (node) => {
      return (node instanceof geotoolkit.welllog.multiwell.correlation.CorrelationRange)
      /*
      && (node.getLeftDepthRange().getLow() === startDepth) && (node.getRightDepthRange().getLow() === startDepth)
      && (node.getLeftDepthRange().getHigh() === endDepth) && (node.getRightDepthRange().getHigh() === endDepth))
      */
    }
    const hues = ['green', 'orange', 'red', 'green', 'blue']
    for (let i = 0; i < this.multiWellWidget.getTracksCount(); i += 1) {
      const track = this.multiWellWidget.getTrackAt(i)
      if (track instanceof geotoolkit.welllog.multiwell.CorrelationTrack) {
        let leftWell
        let rightWell
        if (i >= 1) {
          leftWell = this.multiWellWidget.getTrackAt(i - 1)
        }
        if (i < this.multiWellWidget.getTracksCount() - 1) {
          rightWell = this.multiWellWidget.getTrackAt(i + 1)
        }
        if (rightWell && leftWell) {
          const correlation = geotoolkit.selection.from(track).where(isRangeCorrelation).selectFirst()
          if (!correlation) {
            const leftDepthMarkers = leftWell.getMarkerLayer().getChildren().toArray()
            const rightDepthMarkers = rightWell.getMarkerLayer().getChildren().toArray()
            let matchingElem
            let leftEnd
            let rightEnd
            // eslint-disable-next-line no-loop-func
            leftDepthMarkers.forEach((rightMark, idx, arr) => {
              matchingElem = rightDepthMarkers[idx]
              if (matchingElem && matchingElem.getNameLabel() === rightMark.getNameLabel()) {
                leftEnd = (idx < arr.length - 1) ? leftDepthMarkers[idx + 1].getDepth() : leftWell.getDepthLimits().Fy
                rightEnd = (idx < arr.length - 1) && rightDepthMarkers[idx + 1] ? rightDepthMarkers[idx + 1].getDepth() : rightWell.getDepthLimits().Fy

                const hue = hues[(idx - 2) % hues.length]
                const randomColor = geotoolkit.util.ColorUtil.parseColor(randomcolor({ seed: rightMark.getNameLabel(), hue }))
                randomColor.alpha = 125
                track.addChild(new geotoolkit.welllog.multiwell.correlation.CorrelationRange(rightMark.getDepth(), matchingElem.getDepth(), leftEnd, rightEnd, {
                  'fillstyle': {
                    'color': randomColor.toRgbaString(),
                  },
                }))
              }
            })
            track.setWells(leftWell, rightWell)
          }
        }
      }
    }
  }

  addTrack(type, direction) {
    if (this.multiWellWidget == null || this.multiWellWidget.getSelectedTrack() == null) {
      return null
    }

    let trackType
    if (type === 'index') {
      trackType = geotoolkit.welllog.TrackType.IndexTrack
    } else if (type === 'log') {
      trackType = geotoolkit.welllog.TrackType.LogTrack
    } else {
      trackType = geotoolkit.welllog.TrackType.LinearTrack
    }

    const selectedTrackWidth = this.multiWellWidget
      .getSelectedTrack()
      .getSelection()[0]
      .getWidth()

    const selection = this.multiWellWidget.getSelectedTrack().getSelection()[0]
    console.log('selection', selection)
    window.selection = selection
    const trxName = this.multiWellWidget.getSelectedTrack().getSelection()[0].name

    this.multiWellWidget.getTrackContainer().getChildren().forEach((trx) => {

      if (geotoolkit.isInstanceOf(trx, geotoolkit.welllog.multiwell.IWellTrack)) {
        
        for (let j = 0; j < trx.getTracksCount(); j += 1) {
          if (trx.getTrackAt(j).name === trxName) {
            trx.getSelection().push(trx.getTrackAt(j))
            trx.addTrack(trackType, direction, selectedTrackWidth)
          }
        }

      }
    })

    this.setTrackNames()
  }

  setTrackNames() {
    //Set accurate track names and ID's for the multiwell widget
    let wellLogPlots = this.multiWellWidget.getTrackContainer().getChildren()

    wellLogPlots.forEach(logPlot => {
      const wellId = logPlot.getTitle()
      if(!geotoolkit.isInstanceOf(logPlot, geotoolkit.welllog.multiwell.IWellTrack)) return
      for(let i = 0; i < logPlot.getTracksCount(); i ++){
        let aTrack = logPlot.getTrackAt(i)
        const newTrackId = `${wellId}-${i}`
        aTrack.setId({ 'id': newTrackId, 'wellId': wellId})
        aTrack.name = `Track # ${i}`
      }
    })
  }

  addCurveToTrack(curveData, track) {
    const logCurve = new geotoolkit.welllog.CompositeLogCurve(curveData)
    track.addChild(logCurve)
    this.setDefaultCurveSettings(logCurve)
  }

  deleteSelection() {
    if (this.multiWellWidget == null || this.multiWellWidget.getSelectedTrack() == null) {
      return
    }
    const selection = this.multiWellWidget.getSelectedTrack().getSelection()[0]
    console.log('selection', selection)
    window.selection = selection
    const trxName = this.multiWellWidget.getSelectedTrack().getSelection()[0].name
    this.multiWellWidget.getTrackContainer().getChildren().forEach((trx) => {
      if (geotoolkit.isInstanceOf(trx, geotoolkit.welllog.multiwell.IWellTrack)) {
        for (let j = 0; j < trx.getTracksCount(); j += 1) {
          if (trx.getTrackAt(j).name === trxName) {
            trx.removeTrack(trx.getTrackAt(j))
          }
        }
      }
    })

    if (this.multiWellWidget.getSelectedTrack() != null) {
      this.multiWellWidget.getSelectedTrack().removeSelection()
    }

    this.setTrackNames()
  }

  getSelectedTop() {
    if (this.multiWellWidget == null || this.multiWellWidget.getSelectedTrack() == null) {
      return null
    }
    let marker = null
    this.multiWellWidget.getSelectedTrack().getSelection()
      .forEach((visual) => {
        if (visual instanceof geotoolkit.welllog.LogMarker) {
          marker = visual
        }
      })
    return marker
  }

  /*
   * Fit the wells within the container.
   * Only 20 wells will be squeezed i
   */
  adjustedFit() {
    const tracks = geotoolkit.selection.from(this.multiWellWidget.getTrackContainer()).where((node) => {
      return node.getVisible() && node instanceof geotoolkit.welllog.LogTrack && node.getCssClass() !== 'INDEX_TRACK'
    }).selectToArray()

    if (tracks.length < 20) {
      this.fitToBounds()
    } else {
      const limits = this.multiWellWidget.getCenterModelLimits()
      const bounds = this.multiWellWidget.getBounds()
      const ratio = (tracks.length * (bounds.getWidth() / 20)) / limits.getWidth()
      const transform = this.widget.getTrackContainer().getLocalTransform()
      const scaleY = transform !== null ? transform.getScaleY() : 1
      this.widget.scale(ratio, Math.abs(scaleY))
      //this.widget.setCenterVisibleModelLimits(this.widget.getCenterModelLimits())
      //this.widget.setDesiredWidth(1500)
    }
  }

  fitToHeight() {
    this.multiWellWidget.alignTracks()
    const transform = this.widget.getTrackContainer().getLocalTransform()
    this.widget.scale(Math.abs(transform.getScaleX(), 1.0 / Math.abs(transform.getScaleY())))
    this.widget.setCenterVisibleModelLimits(this.widget.getCenterModelLimits())
  }

  fitToWidth() {
    //Build this at some point
  }

  fitToTops() {
    geotoolkit.selection.from(this.multiWellWidget.getTrackContainer()).where((node) => {
      return node.getVisible() && geotoolkit.interfaceCast(node, geotoolkit.welllog.multiwell.IWellTrack)
    }).execute((node) => {
      const markers = node.getMarkerLayer().getChildren()
      let min = 0
      let max = 0
      markers.forEach((e) => {
        const d = e.getDepth()
        if (min === 0 || d < min) { min = d }
        if (max === 0 || d > max) { max = d }
      })
      node.setVisibleDepthLimits(min, max, true)
    })
  }

  fitToInterval() {
    const { singleOpportunity } = this.props

    if (singleOpportunity && singleOpportunity.startDepthMd && singleOpportunity.stopDepthMd) {
      geotoolkit.selection.from(this.multiWellWidget.getTrackContainer()).where((node) => {
        return node.getVisible() && geotoolkit.interfaceCast(node, geotoolkit.welllog.multiwell.IWellTrack)
      }).execute((node) => {
        node.setVisibleDepthLimits(singleOpportunity.startDepthMd, singleOpportunity.stopDepthMd, true)
      })
    }


    const start = singleOpportunity.startDepthMd
    const stop = singleOpportunity.stopDepthMd
    this.multiWellWidget.scale(1, 0.8)
    this.multiWellWidget.alignToDepth(((stop - start) / 2) + start, 'center')
  }

  fitToData() {
    geotoolkit.selection.from(this.multiWellWidget.getTrackContainer()).where((node) => {
      return node.getVisible() && geotoolkit.interfaceCast(node, geotoolkit.welllog.multiwell.IWellTrack)
    }).execute((node) => {
      const tracks = geotoolkit.selection.from(node).where((n) => {
        return n.getVisible() && n instanceof geotoolkit.welllog.CompositeLogCurve
      }).selectToArray()

      const newLimits = tracks.reduce((accum, track) => {
        var range = track.getDataRange()
        return {
          min: accum.min === 0 || range.getMinDepth() < accum.min ? range.getMinDepth() : accum.min,
          max: range.getMaxDepth() > accum.max ? range.getMaxDepth() : accum.max,
        }
      }, { min: 9999, max: 0 })

      node.setVisibleDepthLimits(newLimits.min, newLimits.max, true)
    })
  }

  fitToBounds() {
    // index track can be invisible
    const limits = this.multiWellWidget.getCenterModelLimits()
    let width = 0
    geotoolkit.selection.from(this.multiWellWidget.getTrackContainer()).where((node) => {
      return node.getVisible() && node instanceof geotoolkit.welllog.LogTrack && node.getCssClass() === 'INDEX_TRACK'
    }).select((node) => {
      width += node.getWidth()
    })
    // we need to reduce a size
    let reducedLimits = limits.clone().setWidth(limits.getWidth() - width)
    if (this.multiWellWidget.getTrackContainer().getContentsTransform() != null) {
      // transform model limits to bounds
      reducedLimits = this.multiWellWidget.getTrackContainer().getContentsTransform().transformRect(reducedLimits)
    }
    let bounds = this.multiWellWidget.getTrackContainer().getVisibleDeviceLimits()
    bounds = this.multiWellWidget.getTrackContainer().getSceneTransform().inverseTransformRect(bounds)
    // convert model limits to bounds in the parent coordinates
    if (this.multiWellWidget.getTrackContainer().getWorldTransform() != null) {
      bounds = this.multiWellWidget.getTrackContainer().getWorldTransform().transformRect(bounds)
    }
    const tr = geotoolkit.util.Transformation.getRectToRectInstance(reducedLimits, bounds, false, false)
    this.isfitToBounds = true
    this.multiWellWidget.setCenterVisibleModelLimits(Math.abs(tr.getScaleX() + this.epsilon) < 1 ? reducedLimits : limits)
    this.isfitToBounds = false
  }

  zoomIn() {
    if (this.multiWellWidget != null) {
      this.multiWellWidget.scale(this.horizontalScale ? 2.0 : 1.0, 2.0)
    }
  }

  zoomOut() {
    if (this.multiWellWidget != null) {
      this.multiWellWidget.scale(this.horizontalScale ? 0.5 : 1.0, 0.5)
    }
  }


  //Template doens't save zoom, and zoom is saved for every diff opportunity
  saveZoom() {
    if(!window.multiWellZoomLevels) window.multiWellZoomLevels = {}
    geotoolkit.selection.from(this.multiWellWidget.getTrackContainer()).where((node) => {
      return node.getVisible() && geotoolkit.interfaceCast(node, geotoolkit.welllog.multiwell.IWellTrack)
    }).execute((node) => {
      window.multiWellZoomLevels[node.Xl] = node.getVisibleDepthLimits()
    })
  }

  setZoomAfterUpdate() {
    if(!window.multiWellZoomLevels) return
    geotoolkit.selection.from(this.multiWellWidget.getTrackContainer()).where((node) => {
      return node.getVisible() && geotoolkit.interfaceCast(node, geotoolkit.welllog.multiwell.IWellTrack)
    }).execute((node) => {
      let zoom = window.multiWellZoomLevels[node.Xl]
      if (zoom) node.setVisibleDepthLimits(zoom.Ky, zoom.Fy)
    })
  }

  screenshot() {
    if (this.multiWellWidget !== null) {
      const widget = this.multiWellWidget.getRoot()
      console.log(widget)
      const limits = widget.getExportLimits()
      const containerBounds = widget.getBounds()
      const link = document.createElement('a')
      /*
      link.href = geotoolkit.scene.exports.NodeExport.exportToImageUrl(
        widget, limits.width, limits.height, false, false, limits)
      */
      link.href = geotoolkit.scene.exports.NodeExport.exportToImageUrl(
        widget, containerBounds.width/2, containerBounds.height/2, false, false, containerBounds)
      // link.href = geotoolkit.scene.exports.NodeExport
      link.setAttribute('download', 'Well Log Cross Section.png')
      link.target = '_blank'
      link.click()
    }
  }

  fullScreen() {
    this.setState({
      isFullScreen: true,
    }, () => this.onResize())

    if (this.widget) {
      /*
      this.widget.scale(Math.abs(transform.getScaleX(), 1.0 / Math.abs(transform.getScaleY())))
      this.widget.setCenterVisibleModelLimits(this.widget.getCenterModelLimits())
      */
    }
  }

  exitFullScreen() {
    this.setState({
      isFullScreen: false,
    }, () => this.onResize())

    /*
    if(this.widget) {
      this.widget.scale(Math.abs(transform.getScaleX(), 1.0 / Math.abs(transform.getScaleY())))
      this.widget.setCenterVisibleModelLimits(this.widget.getCenterModelLimits())
    }
    */
  }

  onResize() {
    const root = this.widget.getRoot()
    const { Kbb, Gbb } = this.widget.getLayout()

    this.plot.setSize(Kbb, Gbb)
  }

  totalTrackCount() {
    let count = 0
    this.multiWellWidget.getTrackContainer().getChildren().forEach((trx) => {
      if (geotoolkit.isInstanceOf(trx, geotoolkit.welllog.multiwell.IWellTrack)) {
        count += trx.getTracksCount()
      }
    })
    return count
  }

  initGUI() {
    // const totalTracks = this.totalTrackCount()
    // if (totalTracks >= this.MIN_TRACK_RESIZE && totalTracks <= this.MAX_TRACK_RESIZE) {
    this.adjustedFit()
    // }
  }

  render() {
    const { id, data } = this.props
    const { isFullScreen, currentSelection } = this.state

    const screenMode = isFullScreen ? 'full-screen' : 'normal'

    return (
      <div className={screenMode}>
        <Toolbar
          data={data}
          currentSelection={currentSelection}
          showPropertiesDialogForSelection={this.showPropertiesDialogForSelection}
          zoomIn={this.zoomIn}
          zoomOut={this.zoomOut}
          fitToBounds={this.fitToBounds}
          fitToTops={this.fitToTops}
          fitToInterval={this.fitToInterval}
          fitToData={this.fitToData}
          fitToHeight={this.fitToHeight}
          addTrack={this.addTrack}
          isFullScreen={isFullScreen}
          fullScreen={this.fullScreen}
          exitFullScreen={this.exitFullScreen}
          deleteSelection={this.deleteSelection}
          screenshot={this.screenshot}
          onResize={this.onResize}
        />
        <div className="well-log-wrapper">
          <canvas id={id} height="100%" style={{ width: '100%', height: '100%' }} />
        </div>
      </div>
    )
  }
}

const Toolbar = ({
  currentSelection,
  showPropertiesDialogForSelection,
  zoomIn, zoomOut,
  fitToBounds,
  fitToTops,
  fitToHeight,
  fitToInterval,
  fitToData,
  addTrack,
  isFullScreen,
  fullScreen,
  exitFullScreen,
  deleteSelection,
  onResize,
  screenshot }) => {
  return (
    <nav className="navbar navbar-default">
      <div className="container-fluid">
        <div className="nav navbar-header">
          <ul className="nav navbar-toggle" data-toggle="collapse" data-target="#main-navbar" aria-expanded="false">
            <li title="Toggle navigation">
              <span className="glyphicon geotoolkit-icon-list" />
            </li>
          </ul>
        </div>
        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="nav navbar-nav">
            <li title="Screenshot"><button type="button" onClick={screenshot}><PhotoCameraIcon fontSize="large" /></button></li>
            <li title="Zoom in"><button type="button" onClick={zoomIn}><ZoomInIcon fontSize="large" /></button></li>
            <li title="Zoom out"><button type="button" onClick={zoomOut}><ZoomOut fontSize="large" /></button></li>

            <li title="Zoom to Fit Intervals"><button type="button" onClick={fitToInterval}><SvgIcon component={OppsIcon} fontSize="large" viewBox="0 0 30 24" /></button></li>

            <li title="Zoom to Fit Tops"><button type="button" onClick={fitToTops}><SvgIcon component={TopsIcon} fontSize="large" viewBox="0 0 30 24" /></button></li>
            <li title="Zoom to Fit Data"><button type="button" onClick={fitToData}><SvgIcon component={LogIcon} fontSize="large" viewBox="0 0 30 24" /></button></li>
            <li title="Zoom to Fit Width"><button type="button" onClick={fitToHeight}><HeightIcon style={{ transform: 'rotate(90deg)' }} fontSize="large" /></button></li>
            {!isFullScreen && (
              <li title="Full Screen" id="zoombutton"><button type="button" onClick={fullScreen}><FullscreenIcon fontSize="large" /></button></li>
            )}
            {isFullScreen && (
              <li title="Exit Full Screen" id="zoombutton"><button type="button" onClick={exitFullScreen}><FullscreenExitIcon fontSize="large" /></button></li>
            )}
            <li title="Add Track" className="dropdown">
              <button type="button" className="dropdown-toggle" data-toggle="dropdown"><AddIcon fontSize="large" /></button>
              <ul className="dropdown-menu dropdown-menu-right" role="menu">
                <li><button type="button" onClick={e => addTrack('linear')}>Linear</button></li>
                <li><button type="button" onClick={e => addTrack('index')}>Index</button></li>
                <li><button type="button" onClick={e => addTrack('log')}>Logarithmic</button></li>
              </ul>
            </li>
            {currentSelection && (
              <>
                <li title="Delete selection"><button type="button" onClick={deleteSelection}><DeleteIcon fontSize="large" /></button></li>
                <li title="Properties"><button type="button" onClick={e => showPropertiesDialogForSelection(currentSelection)}><SettingsIcon fontSize="large" /></button></li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  )
}



const mapStateToProps = state => ({
  tabInfo: state.getIn(['visualization', 'tabInfo']),
  curIndex: state.getIn(['visualization', 'curIndex']),
})



export default connect(mapStateToProps, null)(WellLogPlot)

