import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Collapsible from 'react-collapsible'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'


import Typography from '@material-ui/core/Typography';


import { RecompletionsSettingsMap, HorizontalsSettingsMap, VerticalsSettingsMap, GlobalSettingsMap, UnconventionalsSettingsMap, UnconventionalGlobalSettingsMap } from '../../../../lib/settingsMap'
import SettingsGroup from './SettingsGroup'

import API from '../../../../lib/api-store'
import MUICard from '../../Visualization/common/MUICard'

import './ModifyOld.scss'

import autopilotModeIcon from '../../../../../static/images/swm/settings/swm-mode-autopilot.svg'
import manualModeIcon from '../../../../../static/images/swm/settings/swm-mode-manual.svg'
import EngAnalytics from '../../../../../static/images/settings/icons/EngAnalytics.svg'
import CreateGrids from '../../../../../static/images/settings/icons/CreateGrids.svg'
import DataIntegration from '../../../../../static/images/settings/icons/DataIntegration.svg'
import DrainageAnalysis from '../../../../../static/images/settings/icons/DrainageAnalysis.svg'
import Filtering from '../../../../../static/images/settings/icons/Filtering.svg'
import GlobalSettings from '../../../../../static/images/settings/icons/GlobalSettings.svg'
import Interference from '../../../../../static/images/settings/icons/Interference.svg'
import LogZoneAnalytics from '../../../../../static/images/settings/icons/LogZoneAnalytics.svg'
import Mapping from '../../../../../static/images/settings/icons/Mapping.svg'
import ProdForecast from '../../../../../static/images/settings/icons/ProdForecast.svg'
import RPOS from '../../../../../static/images/settings/icons/RPOS.svg'
import SearchDomain from '../../../../../static/images/settings/icons/SearchDomain.svg'
import TargetSearch from '../../../../../static/images/settings/icons/TargetSearch.svg'

import { subStepNameMap } from './SettingsGroup'

//TODO - store this in the map
const stepNameMap = {
  global: 'Global',
  engineeringAnalytics: 'Engineering Analytics',
  dataIntegration: 'Data Integration',
  logZoneAnalytics: 'Log Zone Analytics',
  attributeMapping: 'Attribute Mapping',
  drainageAnalysis: 'Drainage Analysis',
  targetSearch: 'Target Search',
  productionForecast: 'Production Forecast',
  confidenceAndGeologicalRisk: 'Confidence And Geological Risk',
  opportunityFiltering: 'Opportunity Filtering',
  zoneSettings: 'Zone Settings',
  logAlias: 'Log Alias',
  logSettings: 'Log Settings',
  userOpps: 'User Opps',
  otherGlobalSettings: 'Other Global Settings',
  generateGrids: 'Map Generation',
  generatePOSMap: 'Sweet Spot Ranking',
  threeDModelGeneration: '3D Model Generation',  // Maybe don't need this -WY
  // confidenceGeologicalRisk: 'Confidence & Geologic Risk',
  searchDomainDefinition: 'Search Domain Definition',
  rposModeling: 'Sweet Spot Ranking',
  interferenceAnalysis: 'Interference Analysis',
  // connectivityAnalysis 'Connectivity Analysis',
  // confidence: 'Confidence',
  filterFinalize: 'Filtering & Finalization',
}

const icons = {
  autopilot: autopilotModeIcon,
  manual: manualModeIcon,
  global: GlobalSettings,
  engineeringAnalytics: EngAnalytics ,
  dataIntegration: DataIntegration ,
  logZoneAnalytics: LogZoneAnalytics ,
  attributeMapping: Mapping ,
  drainageAnalysis: DrainageAnalysis ,
  targetSearch: TargetSearch ,
  productionForecast: ProdForecast ,
  confidenceAndGeologicalRisk: null,
  opportunityFiltering: Filtering,
  zoneSettings: null,
  logAlias: null,
  logSettings: null,
  userOpps: null,
  otherGlobalSettings: GlobalSettings ,
  generateGrids: CreateGrids ,
  generatePOSMap: RPOS,
  searchDomainDefinition: SearchDomain ,
  rposModeling: RPOS,
  interferenceAnalysis: Interference,
  filterFinalize: Filtering,

}

const CategoryIcon = (props) => {
  const { category, className, ...restProps } = props
  const Component = icons[category]
  if(Component) {
    return <Component className={className} />
  }
  return null
}

const settingsMapOptions = {
  'generateWorkovers': RecompletionsSettingsMap,
  'verticals': VerticalsSettingsMap,
  'horizontals': HorizontalsSettingsMap,
  'unconventionals': UnconventionalsSettingsMap,
}

@autobind class ModifyOld extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    // this.refs = [];
    this.state = {
      openCategories: [],
      logOptions: null,
      zoneOptions: null,
      wellGroupSetsToMatchOptions: null,
      horizontalPropOptions: null,
      verticalPropOptions: null,
      selectedCategory: null,
      selectedSubcategory: null,
    }
  }

  componentDidMount() {
    const { caseObj, disabled, match, setStep } = this.props
    const {caseId, projectId, application } = match.params

    if (!disabled) {
      setStep(1, `/${application}/projects/${projectId}/cases/${caseId}/initialize`, `/${application}/projects/${projectId}/cases/${caseId}/execute`)
    }

    this.setState({
      openCategories: [],
    })


    if (caseObj && Object.keys(caseObj).length > 0) {
      this.getSmartOptions(caseObj)
    }
  }

  componentDidUpdate(prevProps) {
    const { caseObj } = this.props


    if (Object.keys(prevProps.caseObj).length === 0  && Object.keys(caseObj).length > 0) {

      this.getSmartOptions(caseObj)
    }
  }
  getSmartOptions(caseObj) {
    const { disabled, setCanContinue } = this.props
    const { versionId, datasetId } = caseObj.dataKey


    if (!disabled) {
      API.getSmartOptions(datasetId, versionId).then((out) => {

        const { logOptions, zoneOptions, horizontalPropOptions, verticalPropOptions, wellGroupSetsToMatchOptions } = out

        let obj = {
          horizontalPropOptions,
          verticalPropOptions
        }

        if (logOptions) {
          obj.logOptions = logOptions
        }

        if (zoneOptions) {
          obj.zoneOptions = zoneOptions
        }

        if (wellGroupSetsToMatchOptions) {
          obj.wellGroupSetsToMatchOptions = wellGroupSetsToMatchOptions
        }

        this.setState(obj)

      })

      if (!disabled && caseObj.dataKey && caseObj.module && caseObj.settingsMode && caseObj.settingsMode !== 'forced-manual') {
        setCanContinue(true)
      } else {
        setCanContinue(false)
      }
    }
  }

  selectCategory(category) {
    this.setState({
      selectedCategory: category,
    })
  }



  setMode(val) {
    const { editCaseObj, match, setCanContinue } = this.props
    const { caseId } = match.params

    API.udateCaseSettingsMode(caseId, val)
      .then(caseObj => {
          // TODO - handle success/err
          editCaseObj(caseObj)
      })
      .catch(err => console.error(err))

    setCanContinue(true)
  }

  makePage() {
    const { caseObj } = this.props
    const mode = caseObj.settingsMode
    const caseModule = caseObj.module
    const disabled = mode === 'forced-manual'

    if (!caseObj) {
      return <div> Loading Case ... </div>
    }

    if (!mode || disabled) {
      return (
        <div className='choose-mode'>
          <MUICard>
            <div className="choose-mode-title">
              How should we get started?
            </div>
            <div className="choose-mode-text">
              <span className="blue">Autopilot Mode</span> is recommended for users that want to quickly accelerate to visualization.
            </div>
            <div className="choose-mode-text">
              <span className="blue">Manual Mode</span> is recommended for users that prefer to customize settings to optimize visualization results.
            </div>
            <div className='choose-mode-options'>
              <div className={`choose-mode-option ${disabled ? 'disabled' : ''}`} onClick={() => disabled ? console.log('test') : this.setMode('autopilot')}>
                <CategoryIcon category="autopilot" className="autopilot-icon"/>
                <div className="icon-text">Autopilot Mode</div>
              </div>
              <div className='choose-mode-option' onClick={() => this.setMode('manual')}>
                <CategoryIcon category="manual" className="manual-icon"/>
                <div className="icon-text">Manual Mode</div>
              </div>
            </div>
            {mode === 'forced-manual' && (
              <div className="choose-mode-text">
                <span className="blue">Autopilot Mode</span> is not available for the selected version.
              </div>
            )}
          </MUICard>
        </div>
      )
    }

    //TODO - move the hardcoded settin overview to its own component
    if (mode === 'autopilot' && !disabled) {
      
      let topZoneDisplay = null
      let temporalScaleDisplay = null
      let zoneScaleDisplay = null
      let zoneScaleType = null
      let neighborhoodPerformanceDisplay = null
      let initialPressureDisplay = null
      let flowAllocationDisplay = null
      let pnpFlaggingMethodDisplay = null
      let minBaffleThicknessDisplay = null
      let includeExistingPerfsDisplay = null

      topZoneDisplay = caseObj.settings.otherGlobalSettings.otherGlobalSettings.topZone

      if(caseObj.settings.productionForecast && caseObj.settings.productionForecast.neighborhoodSettings) {
        temporalScaleDisplay = caseObj.settings.productionForecast.neighborhoodSettings.temporalScale
        zoneScaleDisplay = caseObj.settings.productionForecast.neighborhoodSettings.spatialScale
        zoneScaleType = caseObj.settings.productionForecast.neighborhoodSettings.type
        neighborhoodPerformanceDisplay = caseObj.settings.productionForecast.neighborhoodSettings.neighborhoodPerformance ===  'currentRates' ? 'Current Rates' : 'Initial Rates'
      }
      
      if(caseObj.settings.engineeringAnalytics && caseObj.settings.engineeringAnalytics.flowUnitAllocation) {
        initialPressureDisplay = caseObj.settings.engineeringAnalytics.flowUnitAllocation.initialPressure
        flowAllocationDisplay = caseObj.settings.engineeringAnalytics.flowUnitAllocation.setup === 'kh' ? 'Kh method' : 'Chan method'
      }

      if(caseObj.settings.logZoneAnalytics && caseObj.settings.logZoneAnalytics.pnpSettings) {
        pnpFlaggingMethodDisplay = caseObj.settings.logZoneAnalytics.pnpSettings.trackingMethod
        minBaffleThicknessDisplay = caseObj.settings.logZoneAnalytics.pnpSettings.minBaffleThickness 
        includeExistingPerfsDisplay = caseObj.settings.logZoneAnalytics.pnpSettings.includePerfs ? 'includes' : 'does not include'
      }
      
      let primaryMethodDisplay
      let productionForecastMethodsDisplay = ''

      if (['generateWorkovers', 'verticals'].includes(caseModule)) {

        if (caseObj.settings.productionForecast.productionForecast.shouldUseTC) {
          productionForecastMethodsDisplay += 'Type Curve (TC), '
        }
        if (caseObj.settings.productionForecast.productionForecast.shouldUseSTI) {
          productionForecastMethodsDisplay += 'Spatial Temportal Integration (STI), '
        }
        if (caseObj.settings.productionForecast.productionForecast.shouldUseTNSTI) {
          productionForecastMethodsDisplay += 'Thickness Normalized Spacial Temporal Interpolation (TNSTI), '
        }
        if (caseObj.settings.productionForecast.productionForecast.shouldUseNFR) {
          productionForecastMethodsDisplay += 'Neighborhood Fluid Rates (NFR), '
        }
        if (caseObj.settings.productionForecast.productionForecast.shouldUseTNNFR) {
          productionForecastMethodsDisplay += 'Thickness Normalized Neighborhood Fluid Rates (TNNFR), '
        }
        if (caseObj.settings.productionForecast.productionForecast.shouldUseDARCY) {
          productionForecastMethodsDisplay += 'DARCY, '
        }
        productionForecastMethodsDisplay = productionForecastMethodsDisplay.slice(0, -2)
        primaryMethodDisplay = caseObj.settings.productionForecast.productionForecast.primaryMethod
        
      }


      if (caseModule === 'generateWorkovers') {
        const useStandoffDisplay = caseObj.settings.logZoneAnalytics.mnpSettings.useStandoff
        const contactStandoffDisplay = caseObj.settings.logZoneAnalytics.mnpSettings.contactStandoff
        const useSigmaPNLLogsDisplay = caseObj.settings.logZoneAnalytics.mnpSettings.sigmaPnlLogs
        
        return (
          <div className="autopilot">
            <MUICard>
              <div className="autopilot-outer">
                <div className="autopilot-1">
                  Thanks for trusting Speedwise with your settings, we are selecting the best options for you!
                </div>
                <div className="autopilot-2">
                  We picked a few settings for your review.
                </div>
                <div style={{paddingTop: '24px'}}> Smart Settings Summary </div>
                <div className="autopilot-3">
                  <div className="autopilot-4 blue-background">
                    Log alias table is automatically populated, please review!
                  </div>
                  <div className="autopilot-4">
                    The top zone of this reservoir is {topZoneDisplay}
                  </div>
                  <div className="autopilot-4 blue-background">
                    Initial pressure is set to {initialPressureDisplay}
                  </div>
                  <div className="autopilot-4">
                    Initial production rate is forecasted with the following methods: {productionForecastMethodsDisplay}.  The primary method is {primaryMethodDisplay}.
                  </div>
                  <div className="autopilot-4 blue-background">
                    Temporal Neighborhood includes wells that started producing within the last {temporalScaleDisplay} years
                  </div>
                  <div className="autopilot-4">
                    Probability of Exceedance (POE) is calculated using well {neighborhoodPerformanceDisplay}
                  </div> 
                </div>
                <div style={{paddingTop: '16px'}}> Other Important Settings </div>
                <div className="autopilot-3">
                  <div className="autopilot-4 blue-background">
                    Flow allocation is based on {flowAllocationDisplay} method
                  </div>
                  <div className="autopilot-4">
                    Drained Net Pay is identified using the {pnpFlaggingMethodDisplay} flagging method with a minimum baffle thickness set to {minBaffleThicknessDisplay} meters.
                  </div>
                  <div className="autopilot-4 blue-background">
                    Uncontacted Net Pay identification is constrained with {!useStandoffDisplay && !useSigmaPNLLogsDisplay ? 'nothing' : ''}{useStandoffDisplay ? ` a ${contactStandoffDisplay} meters contact standoff` : ''}{useSigmaPNLLogsDisplay && contactStandoffDisplay ? ' and ' : ''} {useSigmaPNLLogsDisplay ? 'Pulsed Neutron logs' : ''}.
                  </div>
                   <div className="autopilot-4">
                    Spatial neighborhood is defined based on {zoneScaleType === 'points' ? `the closest ${zoneScaleDisplay} wells` : `a radius of ${zoneScaleDisplay} meters`}
                  </div>
                </div>
                <div className="autopilot-5">
                  {"You can review all settings or modify them in "}
                  <span className="autopilot-6" onClick={() => this.setMode('manual')}> 
                    Manual Mode
                  </span>
                </div>
              </div>

            </MUICard>
          </div>
        )
      } else if (caseModule === 'verticals') {
        const spacingWellsDisplay = caseObj.settings.generateGrids.spacing.externalSpacing
        const contactStandoffDisplay = caseObj.settings.generateGrids.other.contactStandoff
        const selectedAttributesDisplay = caseObj.settings.generatePOSMap.ranking.Table.map(i => i.attribute).join(', ')

        return (
          <div className="autopilot">
            <MUICard>
              <div className="autopilot-outer">
                <div className="autopilot-1">
                  Thanks for trusting Speedwise with your settings, we are selecting the best options for you!
                </div>
                <div className="autopilot-2">
                  We picked a few settings for your review.
                </div>
                <div style={{paddingTop: '24px'}}> Smart Settings Summary </div>
                <div className="autopilot-3">
                  <div className="autopilot-4 blue-background">
                    Log alias table is automatically populated, please review!
                  </div>
                  <div className="autopilot-4">
                    The top zone of this reservoir is {topZoneDisplay}
                  </div>
                  <div className="autopilot-4 blue-background">
                    Initial pressure is set to {initialPressureDisplay}
                  </div>
                   <div className="autopilot-4">
                    Initial production rate is forecasted with the following methods: {productionForecastMethodsDisplay}.  The primary method is {primaryMethodDisplay}.
                  </div>
                  <div className="autopilot-4 blue-background">
                    Temporal Neighborhood includes wells that started producing within the last {temporalScaleDisplay} years
                  </div>
                  <div className="autopilot-4">
                    Probability of Exceedance (POE) is calculated using well {neighborhoodPerformanceDisplay}
                  </div>
                  <div className="autopilot-4 blue-background">
                    Minimum spacing to existing wells and between new drills is set to {spacingWellsDisplay}
                  </div>
                  <div className="autopilot-4">
                    Contact standoff is set to {contactStandoffDisplay} as derived from Chans plot
                  </div>
                  <div className="autopilot-4">
                    RPOS maps are generated using the following attributes: {selectedAttributesDisplay}
                  </div>
                </div>
                <div style={{paddingTop: '16px'}}> Other Important Settings </div>
                <div className="autopilot-3">
                  <div className="autopilot-4 blue-background">
                    Flow allocation is based on {flowAllocationDisplay} method
                  </div>
                  <div className="autopilot-4">
                    Drained Net Pay is identified using the {pnpFlaggingMethodDisplay} flagging method with a minimum baffle thickness set to {minBaffleThicknessDisplay} meters.
                  </div>
                  <div className="autopilot-4">
                    Spatial neighborhood is defined based on {zoneScaleType === 'points' ? `the closest ${zoneScaleDisplay} wells` : `a radius of ${zoneScaleDisplay} meters`}
                  </div>
                </div>
                <div className="autopilot-5">
                  {"You can review all settings or modify them in "}
                  <span className="autopilot-6" onClick={() => this.setMode('manual')}> 
                    Manual Mode
                  </span>
                </div>
              </div>

            </MUICard>
          </div>
        )
      } else if (caseModule === 'horizontals') {

        const spacingWellsDisplay = caseObj.settings.searchDomainDefinition.spacingGrids.externalSpacing
        const contactStandoffDisplay = caseObj.settings.rposModeling.faults.contactStandoff
        const selectedAttributesDisplay = caseObj.settings.rposModeling.ranking.Table.map(i => i.attribute).join(', ')
        const stayInZoneDisplay = caseObj.settings.targetSearch.configuration.stayInZone
        const lowerAzimuthRangeDisplay = caseObj.settings.targetSearch.configuration.azimuthRangeLower
        const upperAzimuthRangeDisplay = caseObj.settings.targetSearch.configuration.azimuthRangeUpper
        const loweLengthRangeDisplay = caseObj.settings.targetSearch.configuration.lengthRangeLower
        const uppeLengthRangeDisplay = caseObj.settings.targetSearch.configuration.lengthRangeUpper
        const lowerDeviationRangeDisplay = caseObj.settings.targetSearch.configuration.deviationRangeLower
        const upperDeviationRangeDisplay = caseObj.settings.targetSearch.configuration.deviationRangeUpper
        const primaryMethodDisplay = caseObj.settings.productionForecast.productionForecast.primaryMethod


        return (
          <div className="autopilot">
            <MUICard>
              <div className="autopilot-outer">
                <div className="autopilot-1">
                  Thanks for trusting Speedwise with your settings, we are selecting the best options for you!
                </div>
                <div className="autopilot-2">
                  We picked a few settings for your review.
                </div>
                <div style={{paddingTop: '24px'}}> Smart Settings Summary </div>
                <div className="autopilot-3">
                  <div className="autopilot-4 blue-background">
                    Log alias table is automatically populated, please review!
                  </div>
                  <div className="autopilot-4">
                    The top zone of this reservoir is {topZoneDisplay}
                  </div>
                  <div className="autopilot-4 blue-background">
                    Initial pressure is set to {initialPressureDisplay}
                  </div>
                  <div className="autopilot-4">
                    Temporal Neighborhood includes wells that started producing within the last {temporalScaleDisplay} years
                  </div>
                  <div className="autopilot-4 blue-background">
                    Probability of Exceedance (POE) is calculated using well {neighborhoodPerformanceDisplay}
                  </div>
                  <div className="autopilot-4">
                    Minimum spacing to existing wells and between new drills is set to {spacingWellsDisplay}
                  </div>
                  <div className="autopilot-4 blue-background">
                    Contact standoff is set to {contactStandoffDisplay} as derived from Chans plot
                  </div>
                  <div className="autopilot-4">
                    RPOS Model is generated using the following 3D properties: {selectedAttributesDisplay}
                  </div>
                  <div className="autopilot-4 blue-background">
                    Horizontal target constraints are set to the following: 
                      <br/>    * {stayInZoneDisplay ? 'Target has to fit within the same zone' : 'Target does not have to fit within the same zone'}
                      <br/>    * Target azimuth is between {lowerAzimuthRangeDisplay} and {upperAzimuthRangeDisplay} degrees
                      <br/>    * Target length is between {loweLengthRangeDisplay} and {uppeLengthRangeDisplay} meters
                      <br/>    * Target deviation is between {lowerDeviationRangeDisplay} and {upperDeviationRangeDisplay} degrees
                  </div>
                </div>
                <div style={{paddingTop: '16px'}}> Other Important Settings </div>
                <div className="autopilot-3">
                   <div className="autopilot-4 blue-background">
                    Flow allocation is based on {flowAllocationDisplay} method
                  </div>
                  <div className="autopilot-4">
                    Drained Net Pay is identified using the {pnpFlaggingMethodDisplay} flagging method with a minimum baffle thickness set to {minBaffleThicknessDisplay} meters.
                  </div>
                  <div className="autopilot-4 blue-background">
                    Spatial neighborhood is defined based on {zoneScaleType === 'points' ? `the closest ${zoneScaleDisplay} wells` : `a radius of ${zoneScaleDisplay} meters`}
                  </div>
                  <div className="autopilot-4">
                    Primary forecast method is based on the {primaryMethodDisplay} method.
                  </div>
                </div>
                <div className="autopilot-5">
                  {"You can review all settings or modify them in "}
                  <span className="autopilot-6" onClick={() => this.setMode('manual')}> 
                    Manual Mode
                  </span>
                </div>
              </div>

            </MUICard>
          </div>
        )
      } else if (caseModule === 'unconventionals') {

        const spacingWellsDisplay = caseObj.settings.searchDomainDefinition.spacingGrids.externalSpacing
        // const contactStandoffDisplay = caseObj.settings.rposModeling.faults.contactStandoff
        const selectedAttributesDisplay = caseObj.settings.rposModeling.ranking.Table.map(i => i.attribute).join(', ')
        const stayInZoneDisplay = caseObj.settings.targetSearch.configuration.stayInZone
        const lowerAzimuthRangeDisplay = caseObj.settings.targetSearch.configuration.azimuthRangeLower
        const upperAzimuthRangeDisplay = caseObj.settings.targetSearch.configuration.azimuthRangeUpper
        const loweLengthRangeDisplay = caseObj.settings.targetSearch.configuration.lengthRangeLower
        const uppeLengthRangeDisplay = caseObj.settings.targetSearch.configuration.lengthRangeUpper
        const lowerDeviationRangeDisplay = caseObj.settings.targetSearch.configuration.deviationRangeLower
        const upperDeviationRangeDisplay = caseObj.settings.targetSearch.configuration.deviationRangeUpper
        const primaryMethodDisplay = caseObj.settings.productionForecast.productionForecast.primaryMethod


        return (
          <div className="autopilot">
            <MUICard>
              <div className="autopilot-outer">
                <div className="autopilot-1">
                  Thanks for trusting Speedwise with your settings, we are selecting the best options for you!
                </div>
                <div className="autopilot-2">
                  We picked a few settings for your review.
                </div>
                <div style={{paddingTop: '24px'}}> Smart Settings Summary </div>
                <div className="autopilot-3">
                  {/* <div className="autopilot-4 blue-background">
                    Log alias table is automatically populated, please review!
                  </div> */}
                  {/* <div className="autopilot-4">
                    The top zone of this reservoir is {topZoneDisplay}
                  </div> */}
                  {/* <div className="autopilot-4 blue-background">
                    Initial pressure is set to {initialPressureDisplay}
                  </div> */}
                  <div className="autopilot-4">
                    Temporal Neighborhood includes wells that started producing within the last {temporalScaleDisplay} years
                  </div>
                  {/* <div className="autopilot-4 blue-background">
                    Probability of Exceedance (POE) is calculated using well {neighborhoodPerformanceDisplay}
                  </div> */}
                  <div className="autopilot-4 blue-background">
                    Minimum spacing to existing wells and between new drills is set to {spacingWellsDisplay}
                  </div>
                  {/* <div className="autopilot-4 blue-background">
                    Contact standoff is set to {contactStandoffDisplay} as derived from Chans plot
                  </div> */}
                  <div className="autopilot-4">
                    RPOS Model is generated using the following 3D properties: {selectedAttributesDisplay}
                  </div>
                  <div className="autopilot-4 blue-background">
                    Horizontal target constraints are set to the following: 
                      <br/>    * {stayInZoneDisplay ? 'Target has to fit within the same zone' : 'Target does not have to fit within the same zone'}
                      <br/>    * Target azimuth is between {lowerAzimuthRangeDisplay} and {upperAzimuthRangeDisplay} degrees
                      <br/>    * Target length is between {loweLengthRangeDisplay} and {uppeLengthRangeDisplay} meters
                      <br/>    * Target deviation is between {lowerDeviationRangeDisplay} and {upperDeviationRangeDisplay} degrees
                  </div>
                </div>
                <div style={{paddingTop: '16px'}}> Other Important Settings </div>
                <div className="autopilot-3">
                   {/* <div className="autopilot-4 blue-background">
                    Flow allocation is based on {flowAllocationDisplay} method
                  </div> */}
                  {/* <div className="autopilot-4">
                    Drained Net Pay is identified using the {pnpFlaggingMethodDisplay} flagging method with a minimum baffle thickness set to {minBaffleThicknessDisplay} meters.
                  </div> */}
                  {/* <div className="autopilot-4 blue-background">
                    Spatial neighborhood is defined based on {zoneScaleType === 'points' ? `the closest ${zoneScaleDisplay} wells` : `a radius of ${zoneScaleDisplay} meters`}
                  </div> */}
                  <div className="autopilot-4 blue-background">
                    Primary forecast method is based on the {primaryMethodDisplay} method.
                  </div>
                </div>
                <div className="autopilot-5">
                  {"You can review all settings or modify them in "}
                  <span className="autopilot-6" onClick={() => this.setMode('manual')}> 
                    Manual Mode
                  </span>
                </div>
              </div>

            </MUICard>
          </div>
        )
      }
    }

    return this.makeSettings()

  }
  makeSettings() {
    const { selectedCategory } = this.state
    const { disabled } = this.props

    const title = selectedCategory ? stepNameMap[selectedCategory] : null

    return (
      <div className="settings-groups">
        <div className="settings-groups-sidebar">
          <MUICard>
            { this.makeSettingsMenu() }
          </MUICard>
        </div>
        <div ref={this.myRef} className="settings-groups-main">
          <MUICard title={title}>
          { this.makeGlobalSettingsCategories() }
          { this.makeSettingsCategories() }
          </MUICard>
        </div>
      </div>
    )
  }


  makeGlobalSettingsCategories() {
    // This logic sucks
    const selectedModule = this.props.caseObj.module

    const settingsMap = {
      global: GlobalSettingsMap,
    }

    // Modification of global settings 
    if(selectedModule === 'unconventionals') {
      delete settingsMap.global.logAlias
      delete settingsMap.global.logCleaning
      delete settingsMap.global.logSettings
      delete settingsMap.global.otherGlobalSettings.otherGlobalSettings.logSectionLogs
    }

    return (
      <div className="sub-category-container">
        { this.makeSettingsSubCategories('global', settingsMap, 1) }
      </div>
    )
  }


  makeSettingsMenu() {
    const { selectedCategory, selectedSubcategory } = this.state
    const { caseObj } = this.props
    const { module, stepNo, stepStatus } = caseObj

    const settingsMap = settingsMapOptions[module]
    const settings = caseObj.settings

    console.log('module', module)
    return (
      <React.Fragment>
        <div className="sidebar-header">
          {"Select a Settings Group "}
          <FontAwesomeIcon icon="cog" className="action-button-icon" />
        </div>
        <div className="sidebar-icons">  
          <div className={`sidebar-icon ${selectedCategory === 'global' ? 'selected' : ''}`} onClick={() => this.selectCategory('global')}>
            <CategoryIcon category={'global'} className="sidebar-icon-svg"/>
            <div className={`sidebar-icon-text global`}>
              Global
            </div>
          </div>
          { Object.keys(settingsMap).map(categoryKey => (
            <React.Fragment>
              <div className={`sidebar-icon ${selectedCategory === categoryKey ? 'selected' : ''} ${stepNo === settingsMap[categoryKey].step && stepStatus === 'failed' ? 'failed' : ''}`} onClick={() => this.selectCategory(categoryKey)}>
                <CategoryIcon category={categoryKey} className="sidebar-icon-svg"/>
                <div className={`sidebar-icon-text ${categoryKey}`}>
                  {stepNameMap[categoryKey]}
                </div>
              </div>
            </React.Fragment>

          ))}
        </div>
      </React.Fragment>
    )
  }

  makeSettingsCategories() {
    const { caseObj } = this.props
    const { selectedCategory } = this.state
    const { module } = caseObj

    if (!selectedCategory) {
      return (
        <div className="sub-category-container">
          <div className="no-category-1">
            <CategoryIcon category="manual" className="manual-icon"/>
          </div>
          <div className="no-category-2">
            Manual Mode
          </div>
          <div className="no-category-3">
            Select a group of Settings to begin.
          </div>
        </div>
      )
    }

    const settingsMap = settingsMapOptions[module]
    return Object.keys(settingsMap).map(categoryKey => {
      const stepNo = settingsMap[categoryKey].step

      return (
        <div className="sub-category-container">
          { this.makeSettingsSubCategories(categoryKey, settingsMap, stepNo) }
        </div>
      )
    })
  }

  makeSettingsSubCategories(categoryKey, settingsMap, stepNo) {
    const { selectedCategory, selectedSubcategory, logOptions, zoneOptions, wellGroupSetsToMatchOptions, horizontalPropOptions, verticalPropOptions } = this.state
    const { caseObj, editCaseObj, disabled } = this.props
    const { settings } = caseObj

    let childLogOptions = []
    if (categoryKey === 'global') {
      childLogOptions = logOptions
    }
    
    const subCategories = settingsMap[categoryKey]
    console.log('categoryKey', categoryKey)
    console.log('settingsMap', settingsMap)
    console.log('subs', subCategories)
    if (selectedCategory === categoryKey) {
      return (
        <div>
          <SettingsGroup 
            onChange={(evt) => this.setState({ selectedSubcategory: evt.subcategory })}
            // mRefs={this.refs}
            stepNo={stepNo} 
            settings={settings}
            subCategories={subCategories}
            categoryKey={categoryKey}
            selectedSubcategory={selectedSubcategory}
            caseObj={caseObj}
            settingsMap={settingsMap}
            editCaseObj={editCaseObj}
            logOptions={childLogOptions}
            zoneOptions={zoneOptions}
            wellGroupSetsToMatchOptions={wellGroupSetsToMatchOptions}
            horizontalPropOptions={horizontalPropOptions}
            verticalPropOptions={verticalPropOptions}
            disabled={disabled}
          />
        </div>
      )
    }
    return <div></div>
  }

  render() {
    const { caseObj, disabled } = this.props
    const { selectedCategory} = this.state

    if (!caseObj || Object.keys(caseObj).length === 0) {
      return (
        <div className="initialize">
          <div className="initialize-header"> Loading Case </div>
        </div>
      )
    }

    if (caseObj.stepStatus === 'running' || caseObj.stepStatus === 'queued') {
      return (
        <div className="initialize">
          <div className="initialize-header"> Settings selection is disabled during execution </div>
        </div>
      )
    }

    return (
      <div className="modify">
        {
          caseObj.stepNo && !disabled && (
            <div className="settings-error">
              Changing settings on a prior run step will cause you to have to run the step again, as well as each step following it.  Changes to global settings will require the entire execution to be rerun.
            </div>
          )
        }
        { this.makePage() }
      </div>
    )
  }
}


export default withRouter(ModifyOld)
