import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import _ from 'underscore'

import Select from 'react-select'
import Creatable from 'react-select/creatable'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CategoryTypes } from '../../../lib/datasetMaps/groupConfigurations'
import FileViewer from './FileViewer'
import ContinueModal from '../ContinueModal/ContinueModal'

import {
  deleteAllFilesFromDatasetCategory,
  deleteAllFailedFilesFromDatasetCategory,
} from '../../../services/datasetService'

import './FileUploader.scss'

const baseClass = 'file-uploader'

const topBarClass = `${baseClass}__top-bar`

const uploaderContainerClass = `${baseClass}__uploader-container`
const singleUploadClass = `${uploaderContainerClass}--single`
const multipleUploadClass = `${uploaderContainerClass}--multiple`

const uploadParametersClass = `${uploaderContainerClass}__upload-parameters`
const uploadParameterHeader = `${uploadParametersClass}__header`
const uploadParameterSelection = `${uploadParametersClass}__selection`
const optionClass = `${uploadParametersClass}__option`
const uploadParameterSingle = `${uploadParametersClass}__single`

const indexClass = `${uploaderContainerClass}__step`

const infoClass = `${uploaderContainerClass}__info`
const categoryTextClass = `${infoClass}__category-text`
const optionsClass = `${infoClass}__options`
const fileExtensionClass = `${infoClass}__file--extension`
const requiredTextClass = `${categoryTextClass}__required`
const helperTextClass = `${infoClass}__helper-text`

const browseButtonClass = `${infoClass}__browse-button`
const browseButtonLabelClass = `${infoClass}__browse-button-label`
const browseButtonTextClass = `${browseButtonLabelClass}__text`
const browseButtonInputClass = `${browseButtonLabelClass}__input`

//Modify Modal css in ContinueModal.scss
const modalUploadClass = `cancel-modal__content__children__file-upload`
const modalHeaderClass = `${modalUploadClass}__header`
const modalInputField = `${modalUploadClass}__input`
const modalInputItem = `${modalInputField}__item`

const baseState = {
  showFileUploadModal: false,
  fileUploadModalChildren: [],
  propertiesToConvert: null,
  zoneNames: {},

  //pull these directly from uploadParameters and fill dynaically... later if needed.
  wellNameField: null,
  isFileNameZoneName: null,
  shouldConvertUnits: null,
}


@autobind class FileUploader extends Component {
  constructor(props){
    super(props)
    this.state = baseState    
  }


  onFilesSelected(evt) {
    const files = evt.target.files
    this.callbackWithFiles(files)
    // evt.target.files = null
    // evt.target.value = null
  }

  onDrop(evt) {
    evt.preventDefault()
    evt.stopPropagation()

    const files = evt.dataTransfer.files
    this.callbackWithFiles(files)
  }

  callbackWithFiles(files, uniqueParams) {

    const {
      onFiles,
      category,
      readOnly,
    } = this.props

    if (readOnly) {
      return
    }

    if (!files || files.length === 0) {
      return
    }

    if (category.type === CategoryTypes.SINGLE) {
      files = [files[0]]
    }

    // Check if upload params are filled out.  Add in additional logic as-needed.
    if(!this.checkUploadParametersCompleted(uniqueParams, files, true)){
      return
    }

    

    const uploadParameters = (category.uploadParameters || []).reduce((acc, parameter) => {
      acc[parameter.name] = this.state[parameter.name]
      return acc
    }, {})

    onFiles(files, category.category, uploadParameters, false, uniqueParams)
    this.resetState()
    this.forceUpdate()
  }

  checkUploadParametersCompleted(uniqueParams, files, showAlerts) {
    const { category } = this.props
    if (category.uploadParameters) {
      switch(category.category){
        case 'Model_Data':
          if(this.state.shouldConvertUnits === null || (this.state.shouldConvertUnits && !this.state.propertiesToConvert)) {
            showAlerts ? alert('You must specify parameters before uploading files.') : null// <----- maybe change to continue modal later
            return false
          }
          break
        default:
          for (let param of category.uploadParameters){
            if(this.state[param.name] === null){
              showAlerts ? alert(`You must specify parameters before uploading files.`) : null // <----- maybe change to continue modal later
              return false
            }
          }
      }

      // Check if FILE NAME != ZONE NAME in GRIDS.  This is where the modal gets opened.
      if (category.templateName === 'ExampleGridFile' && this.state.isFileNameZoneName === false && !uniqueParams){
        showAlerts ? this.openFileUploadModal(files) : false
        return !showAlerts
        //Why !showAlerts?
        //If we're not showing alerts, we haven't uploaded anything yet.  SO let it pass because the modal comes up after upload @ callback w/ files
        //If we are showing alerts, we want to not continue untill the user fills out the zone name info.
      }

      return true
    } else {
      return true
    }
  }

  onDragOver(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    //console.log('debounced drag over')
  }

  onDragLeave(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    //console.log('debounced drag leave')
  }


  handleTemplateDownloadClick(name) {
    const link = document.createElement('a')
    link.href = `/templates/${name}`
    link.setAttribute('download', name)
    link.target = '_blank'
    link.click()
  }

  showDeleteFilesModal(){
    const { 
      category,
      renderCustomModal,
      readOnly,
    } = this.props

    let header, children

    if(!readOnly){
      header = "Delete All Files"
      children = `Are you sure you want to delete all files for ${category.text}?`
    } else{
      header = "Delete All Files / Create New Version"
      children = `Are you sure you want to delete all files for ${category.text}?  This action will create a new version.`
    }

    renderCustomModal(header, children, this.deleteAllFiles)
  }

  deleteAllFiles() {
    const {
      datasetId,
      category,
      refreshData,
    } = this.props

    //reload if either failed or successful
    deleteAllFilesFromDatasetCategory(datasetId, category.category).finally(() => {
      refreshData()
    })
  }

  deleteAllFailedFiles() {
    const {
      datasetId,
      category,
      refreshData,
    } = this.props

    //reload if either failed or successful
    deleteAllFailedFilesFromDatasetCategory(datasetId, category.category).finally(() => {
      refreshData()
    })
  }


  /*--- Section: Upload Parameter Functions ---*/

    //Zone Names

  openFileUploadModal(files){
    this.setState({
      files,
      fileUploadModalChildren: this.createFileUploadModalChildren(files),
      showFileUploadModal: true,
    })
  }

  resetState(){
    this.setState(baseState)
  }

  closeAndSend() {
    const { files, zoneNames } = this.state
    const uniqueParams = this.formatZoneNameParams(zoneNames)
    this.callbackWithFiles(files, uniqueParams)
  }

  formatZoneNameParams(zoneNames){
    const uniqueParams = {}
    _.map(zoneNames, (val, fileName) => {
      uniqueParams[fileName] = { 
        zoneName: val,
      }
    })
    return uniqueParams
  }

  handleZoneNameInput(fileName, wellName){
    //This is slow, but it works for now.
    //Every change is re-rendering all uploaded files currently.
    //fixed in shouldComponentUpdate, below.
    const { zoneNames } = this.state
    this.setState({
      zoneNames: {
        ...zoneNames,
        [fileName]: wellName,
      }
    })
  }

  createFileUploadModalChildren(files){
    if(files){
      let textInputs = _.map(files, (file) => {
        return (
          <div className={modalInputItem}>
            <div className={'align-left'}>
              <p>{file.name}</p>
            </div>
            <div className={'align-right'}>
              <TextField
                id={file.name}
                variant="outlined"
                fullWidth
                size='small'
                onChange={(e) => this.handleZoneNameInput(e.target.id, e.target.value)}
              />
            </div>
          </div>
        )
      })
      //Modify Modal css in ContinueModal.scss
      return (
        <div className={modalUploadClass}>
          <div className={modalHeaderClass}>
            <p>In order to upload a file, a zone name must be entered.  Zone names can not be changed unless the file is uploaded again.</p>
          </div>
          <div className={modalInputField}>
            <div className={`${modalInputItem} header`}>
              <span className={'align-left'}>File Name</span><span className={'align-right'}>Zone Name</span>
            </div>
            {textInputs}
          </div>
        </div>
      )
    }
  }

    //General Parameters

  changeCreatableSelection(paramName, selectedArray){
    if (Array.isArray(selectedArray)){
      this.setState({ [paramName]: selectedArray.map(prop => prop.value.toUpperCase()) })
    } else {
      this.setState({ [paramName]: selectedArray })
    }
  }


  changeSelectedValue(paramName, value){

    if(value === "true"){
      value = true
    } else if (value === "false"){
      value = false
    }

    this.setState({ [paramName]: value })
  }

  // formatThreeDArrayData(){
  //   this.setState({['propertiesToConvert']: this.state['propertiesToConvert'].map(e => e.value)})
  // }


  generateParameterSelectorsByType(uploadParameter){

    let optionSelection

    let tooltip = !uploadParameter.helpText ? null : (
      <>
      <a data-tip={uploadParameter.helpText} data-for={uploadParameter.name}>
            <FontAwesomeIcon icon="question-circle" className="help-icon" />
          </a>
      <ReactTooltip id={uploadParameter.name} className="customize-settings-tooltip" effect="solid" />
      </>
    )

    switch(uploadParameter.type){
        case 'dropdown':
          optionSelection = (<Select
                      options={uploadParameter.options}
                      onChange={e => this.changeSelectedValue(uploadParameter.name, e.value)}
                      isMulti={false}
                      value={ { label: this.state[uploadParameter.name], value: this.state[uploadParameter.name] }}
                      key={uploadParameter.name}
                  />)
          break
        case 'radio':
            optionSelection = (<>
                    {
                      uploadParameter.options.map((option) => {
                          return (<>
                              <Radio 
                                  checked={this.state[uploadParameter.name] === option.value}
                                  onChange={e => this.changeSelectedValue(uploadParameter.name, e.target.value)}
                                  value={option.value}
                                  name="radio-button"
                                  inputProps={{ 'aria-label': option.label }}
                                  color="primary"
                                  size="small"
                              />
                              <span
                                onClick={() => this.changeSelectedValue(uploadParameter.name, option.value)}
                                style={{'cursor': 'pointer'}}
                              >
                                {option.label}
                              </span>
                          </>)
                      })
                    }
                </>)
            break
        case 'text':
            //finish building me if needed
            optionSelection = (<>
                <TextField
                    id={uploadParameter.name}
                    variant="outlined"
                    onChange={e => this.changeSelectedValue(uploadParameter, e)}
                />
            </>)
            break
        case 'dropdown-custom':
            optionSelection = (<Creatable
                      options={uploadParameter.options}
                      onChange={e => this.changeCreatableSelection(uploadParameter.name, e)}
                      isDisabled={ uploadParameter.condition ? eval(uploadParameter.condition) : false }
                      isMulti={true}
                      value={eval(uploadParameter.condition) ? null : this.state[uploadParameter]}
                      key={uploadParameter.name}
            />)
            break
        default:
            console.log('ERROR IN generateParameterSelectorsByType: FileUploader 332')
            console.log('Parameter type fell through switch statment.  Check type name:', uploadParameter.type)
    }

    return (
        <div className={optionClass}>
            <p>{uploadParameter.label}<span className={requiredTextClass}> *</span>{tooltip}</p>
            {optionSelection}
        </div>
    )
  }

  /*-----end section-----*/

  //this could introduce some unforseen bugs.  
  shouldComponentUpdate(nextProps, nextState){
    if(this.state.showFileUploadModal !== nextState.showFileUploadModal) { return true }
    if(this.state.zoneNames !== nextState.zoneNames) { return false }
    return true
  }

  render() {
    const {
      files,
      datasetId,
      versionId,
      category,
      uploadingFiles,
      index,
      refreshData,
      readOnly,
      isDatasetValid,
      failedUploads,
      openFileInformationDrawer,
      openFileDescriptionDrawer
    } = this.props

    const { showFileUploadModal, fileUploadModalChildren } = this.state

    const fileExists = files.length > 0
    const containsFailedFiles = files.find((file) => {
      return file.validationComplete && !file.validationPassed
    })

    const uploadParametersCompleted = this.checkUploadParametersCompleted(false)

    return (
      <div className={`${baseClass}`}>
        {category.type === CategoryTypes.ARRAY && (
          <>
            <div className={topBarClass}>
              {files.length > 0 && (
                <Button
                  className="qri-button primary"
                  onClick={this.showDeleteFilesModal}>
                  Delete all files
                </Button>
              )}

              {containsFailedFiles && (
                <Button
                  className="qri-button primary"
                  onClick={this.deleteAllFailedFiles}>
                  Delete Failed files
                </Button>
              )}

              <div
                style={{ 'float': 'right', 'padding-top': '9px' }}
              >
                {files.length} files
              </div>
            </div>
            <div
              className={`${uploaderContainerClass} ${multipleUploadClass} align-vertical`}
              onDragOver={this.onDragOver}
              onDragLeave={this.onDragLeave}
              onDrop={this.onDrop}
            >  
              {/* UPLOAD PARAMETERS */}
              {category.uploadParameters && !readOnly && (
                <div className={uploadParametersClass}>
                  <div className={uploadParameterHeader}>
                    <p><b>Parameters</b></p>
                    <p>In order to upload the files, the following information is needed:</p>
                  </div>
                  <div className={uploadParameterSelection}>
                    {category.uploadParameters.map(this.generateParameterSelectorsByType)}
                  </div>
                </div>
              )}
              <div className={infoClass}>
                <div className={categoryTextClass}>
                  <span><b>{category.text}</b></span>
                  <FontAwesomeIcon
                    icon="question-circle"
                    className="help-icon"
                    style={{ 
                      'height': '13px',
                      'width': '13px',
                      'position': 'relative',
                      'left': '3px',
                      'top': '-2px',
                      'cursor': 'pointer',
                    }}
                    onClick={e => openFileDescriptionDrawer(e, category)}
                  />
                  {category.required && (
                    <span className={requiredTextClass}>
                      <sup style={{ 'padding-top': '0.2em', 'font-size': '0.75em' }}> *Required</sup>
                    </span>
                  )}
                </div>
                {!readOnly && (
                  <>
                    
                    <div className={helperTextClass}>
                      {uploadParametersCompleted ? 'Drag files here or' : <span style={{color: "red"}}>Please specifiy upload parameters</span>}
                    </div>

                    { uploadParametersCompleted && (
                      <input
                        id={`upload-${index}-file`}
                        type="file"
                        multiple
                        className={browseButtonInputClass}
                        onInput={this.onFilesSelected}
                      />
                    )}
                    <label className={browseButtonLabelClass} htmlFor={`upload-${index}-file`}>
                      <Button
                        className={uploadParametersCompleted ? browseButtonClass : browseButtonClass + ' disabled'}
                        variant="outline"
                        component="span"
                        size="large"
                        disabled={!uploadParametersCompleted}
                        style={uploadParametersCompleted ? null : { backgroundColor: '#E0E1E2 !important'}}
                      >
                        Browse
                      </Button>

                    </label>
                  </>
                )}
                {readOnly && (
                  <>
                    <div>
                      No files can be uploaded in readonly mode
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )
        }

        {
          !fileExists && uploadingFiles.length === 0 && failedUploads.length === 0 && category.type === CategoryTypes.SINGLE && (
            <div
              className={`${uploaderContainerClass} ${singleUploadClass} align-vertical`}
              onDragOver={this.onDragOver}
              onDragLeave={this.onDragLeave}
              onDrop={this.onDrop}
            >
              <div className={indexClass}>
                {index}
              </div>
              <div className={infoClass}>
                <div className={categoryTextClass}>
                  <span>{category.text}</span>
                  <FontAwesomeIcon
                    icon="question-circle"
                    className="help-icon"
                    style={{ 
                      'height': '13px',
                      'width': '13px',
                      'position': 'relative',
                      'left': '3px',
                      'top': '-2px',
                      'cursor': 'pointer',
                    }}
                    onClick={e => openFileDescriptionDrawer(e, category)}
                  />
                  {category.required && (
                    <span className={requiredTextClass}>
                      <sup style={{ 'padding-top': '0.2em', 'font-size': '0.75em' }}> *Required</sup>
                    </span>
                  )}
                </div>
                <div className={optionsClass}>
                  <span>
                    <span className={fileExtensionClass}>
                      {category.fileFormat}
                    </span> file -
                </span>
                  <Link
                    onClick={() => this.handleTemplateDownloadClick(category.templateName)}
                    href="#"
                  > DOWNLOAD TEMPLATE
                </Link>
                </div>
              </div>
              { category.uploadParameters && (
                <div className={uploadParametersClass}>
                <div className={uploadParameterHeader}>
                  <p><b>Parameters</b></p>
                  <p>In order to upload the files, the following information is needed:</p>
                </div>
                <div className={uploadParameterSelection}>
                  {category.uploadParameters.map(this.generateParameterSelectorsByType)}
                </div>
              </div>
              )}
              { !category.uploadParameters && (<div></div>) }
              {!readOnly && (
                <>
                  <div className={helperTextClass}>
                    Drag files here or
                </div>
                  <div>
                    <input
                      id={`upload-${index}-file`}
                      type="file"
                      className={browseButtonInputClass}
                      onChange={this.onFilesSelected}
                    />
                    <label className={browseButtonLabelClass} htmlFor={`upload-${index}-file`}>
                      <Button className={browseButtonClass} variant="outline" component="span" size="large">
                        Browse
                      </Button>
                    </label>
                  </div>
                </>
              )}
              {readOnly && (
                <>
                  <div className={helperTextClass}>
                    No file was uploaded for this category
                </div>
                </>
              )}
            </div>
          )
        }

        {
          uploadingFiles.map((file) => {
            return (
              <div>
                <FileViewer
                  openFileDescriptionDrawer={openFileDescriptionDrawer}
                  versionId={versionId}
                  uploadPromise={file.promise}
                  name={file.file.originalFilename}
                  date={file.file.dateCreated}
                  extension={file.file.extension}
                  category={category}
                  required={category.required}
                  datasetId={datasetId}
                />
              </div>
            )
          })
        }

        {
          failedUploads.map((file) => {
            return (
              <div>
                <FileViewer
                  versionId={versionId}
                  name={file.file.originalFilename}
                  date={file.file.dateCreated}
                  extension={file.file.extension}
                  category={category}
                  onReplaceDocument={this.onFilesSelected}
                  required={category.required}
                  failedUpload={true}
                  datasetId={datasetId}
                  openFileDescriptionDrawer={openFileDescriptionDrawer}
                  openFileInformationDrawer={openFileInformationDrawer}
                />
              </div>
            )
          })
        }

        {
          files.map((file) => {
            return (
              <div
                key={file._id}
                file-id={file._id}
              >
                <FileViewer
                  versionId={versionId}
                  name={file.originalFilename}
                  id={file._id}
                  datasetId={datasetId}
                  createdBy={file.createdBy}
                  date={file.dateCreated}
                  extension={file.extension}
                  category={category}
                  categoryType={category.type}
                  templateName={category.templateName}
                  onReplaceDocument={this.onFilesSelected}
                  validationPassed={file.validationPassed}
                  uploadParameters={file.uploadParameters}
                  validationComplete={file.validationComplete}
                  validationError={file.validationError}
                  refreshData={refreshData}
                  readOnly={readOnly}
                  required={category.required}
                  isDatasetValid={isDatasetValid}
                  openFileDescriptionDrawer={openFileDescriptionDrawer}
                  openFileInformationDrawer={openFileInformationDrawer}
                />
              </div>
            )
          })
        }
        <ContinueModal
          showContinueModal={showFileUploadModal}
          onModalCancel={this.resetState}
          onModalContinue={this.closeAndSend}
          header={"File Zone Names"}
          children={fileUploadModalChildren}
        >
        </ContinueModal>
      </div >
    )
  }
}

export default FileUploader
