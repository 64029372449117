import { FileCategories, GridCategories } from '../../../server/validation/validationConstants'

const MODULE_PLATFORMS = {
  SGO: () => ({
    value: 'SGO',
    label: 'Speedwise: Reservoir Opportunity'
  }),
  SWM: () => ({
    value: 'SWM',
    label: 'Speedwise: Waterflood Management'
  })
}

const MODULES = {
  RECOMPLETIONS: {
    labels: { 
      value: 'RECOMPLETIONS', 
      label: 'Recompletions'
    },
    platform: MODULE_PLATFORMS.SGO(),
    requirements: {
      files: {
        WELL_INFO: {
          requiredCategories: [
            'Well_Tops',
            'Well_Master',
            'Well_Perforations',
          ],
        },
        PRODUCTION_AND_INJECTION: {
          requiredCategories: [ 
            'Well_Production_Monthly',
          ],
        },
        TESTING: {
          requiredCategories: [
            'Fluid_PVT',
            'Well_Pressure_Test_Static',
          ],
        },
        WELL_TRAJECTORIES: {
          requiredCategories: [
            'Well_Trajectories',
          ],
        },
        SURFACES: {
          category: 'Grid_Data',
          subgroups: {  
            STRUCTURE_GRIDS: {
              requiredCategories: [
                'structure',
              ],
            },
          },
        },
        LOGS: {
          requiredCategories: [
            'Log_Data',
          ],
        },
        GEOLOGICAL_MODELS: {
          category: 'Model_Data',
          subgroups: {
            THREE_D_MODEL: {
              alternatives: {
                ['Model_Data']: [
                  'netPay',
                  'originalHcpt',
                  'currentHcpt',
                  'phi',
                  'sw',
                  'k',
                ],
              },
              requiredCategories: [
                'Model_Data'
              ],
            },
            TWO_D_MODEL: {
              category: 'Grid_Data',
              subgroups: {
                ORIGINAL_HCPT_GRIDS: {
                  alternatives: {
                    ['originalHcpt']: ['Model_Data']
                  },
                  requiredCategories: [
                    'originalHcpt',
                  ]
                },
                CURRENT_HCPT_GRIDS: {
                  alternatives: {
                    ['currentHcpt']: ['Model_Data']
                  },
                  requiredCategories: [
                    'currentHcpt',
                  ]
                },
                NET_PAY_GRIDS: {
                  alternatives: {
                    ['netPay']: ['Model_Data']
                  },
                  requiredCategories: [
                    'netPay',
                  ]
                },
                POROSITY_GRIDS: {
                  alternatives: {
                    ['phi']: ['Model_Data']
                  },
                  requiredCategories: [
                    'phi',
                  ]
                },
                WATER_SATURATION_GRIDS: {
                  alternatives: {
                    ['sw']: ['Model_Data']
                  },
                  requiredCategories: [
                    'sw',
                  ]
                },
                PERMEABILITY_GRIDS: {
                  alternatives: {
                    ['k']: ['Model_Data']
                  },
                  requiredCategories: [
                    'k',
                  ]
                },
              }
            }
          },
        },
      },
      validations: {
        integration: [
          'grid',
          'wellGrid',
          'wellNames',
          'zoneNames',
          'perforationDeviation',
          'productionInjection',
          'productionPerforation',
          'wellTopsWellInterval',
        ],
        combination: [
          'sda',
          'grid',
          'log',
          'wellTrajectory',
        ],
      },
    },
  },
  NEW_DRILLS: {
    labels: {
      value: 'NEW_DRILLS',
      label: 'Sweet Spots',
    },
    platform: MODULE_PLATFORMS.SGO(),
    requirements: {
      files: {
        WELL_INFO: {
          requiredCategories: [
            'Well_Tops',
            'Well_Master',
            'Well_Perforations',
          ],
        },
        PRODUCTION_AND_INJECTION: {
          requiredCategories: [ 
            'Well_Production_Monthly',
          ],
        },
        TESTING: {
          requiredCategories: [
            'Fluid_PVT',
            'Well_Pressure_Test_Static',
          ],
        },
        WELL_TRAJECTORIES: {
          requiredCategories: [
            'Well_Trajectories',
          ],
        },
        SURFACES: {
          category: 'Grid_Data',
          subgroups: {  
            STRUCTURE_GRIDS: {
              requiredCategories: [
                'structure'
              ],
            },
          },
        },
        LOGS: {
          requiredCategories: [
            'Log_Data'
          ],
        },
        GEOLOGICAL_MODELS: {
          category: 'Model_Data',
          subgroups: {
            THREE_D_MODEL: {
              alternatives: {
                ['Model_Data']: [
                  'netPay',
                  'originalHcpt',
                  'currentHcpt',
                  'phi',
                  'sw',
                  'k',
                ],
              },
              requiredCategories: [
                'Model_Data'
              ],
            },
            TWO_D_MODEL: {
              category: 'Grid_Data',
              subgroups: {
                ORIGINAL_HCPT_GRIDS: {
                  alternatives: {
                    ['originalHcpt']: ['Model_Data']
                  },
                  requiredCategories: [
                    'originalHcpt',
                  ]
                },
                CURRENT_HCPT_GRIDS: {
                  alternatives: {
                    ['currentHcpt']: ['Model_Data']
                  },
                  requiredCategories: [
                    'currentHcpt',
                  ]
                },
                NET_PAY_GRIDS: {
                  alternatives: {
                    ['netPay']: ['Model_Data']
                  },
                  requiredCategories: [
                    'netPay',
                  ]
                },
                POROSITY_GRIDS: {
                  alternatives: {
                    ['phi']: ['Model_Data']
                  },
                  requiredCategories: [
                    'phi',
                  ]
                },
                WATER_SATURATION_GRIDS: {
                  alternatives: {
                    ['sw']: ['Model_Data']
                  },
                  requiredCategories: [
                    'sw',
                  ]
                },
                PERMEABILITY_GRIDS: {
                  alternatives: {
                    ['k']: ['Model_Data']
                  },
                  requiredCategories: [
                    'k',
                  ]
                },
              }
            }
          },
        },
      },
      validations: {
        integration: [
          'grid',
          'wellGrid',
          'wellNames',
          'zoneNames',
          'perforationDeviation',
          'productionInjection',
          'productionPerforation',
          'wellTopsWellInterval'
        ],
        combination: [
          'sda',
          'grid',
          'log',
          'wellTrajectory'
        ]
      }
    }
  },
  HORIZONTALS: {
    labels: {
      value: 'HORIZONTALS',
      label: 'Horizontals'
    },
    platform: MODULE_PLATFORMS.SGO(),
    requirements: {
      files: {
        WELL_INFO: {
          requiredCategories: [
            'Well_Master',
            'Well_Perforations',
            'Well_Tops',
          ],
        },
        PRODUCTION_AND_INJECTION: {
          requiredCategories: [
            'Well_Production_Monthly'
          ],
        },
        TESTING: {
          requiredCategories: [
            'Fluid_PVT',
            'Well_Pressure_Test_Static',
          ],
        },
        WELL_TRAJECTORIES: {
          requiredCategories: [
            'Well_Trajectories'
          ],
        },
        SURFACES: {
          category: 'Grid_Data',
          subgroups: {  
            STRUCTURE_GRIDS: {
              requiredCategories: [
                'structure'
              ],
            },
          },
        },
        LOGS: {
          requiredCategories: [
            'Log_Data'
          ],
        },
        GEOLOGICAL_MODELS: {
          category: 'Model_Data',
          subgroups: {
            THREE_D_MODEL: {
              requiredCategories: [
                'Model_Data'
              ]
            }
          },
        },
      },
      validations: {
        integration: [
          'grid',
          'wellGrid',
          'wellNames',
          'modelWell',
          'zoneNames',
          'perforationDeviation',
          'productionInjection',
          'productionPerforation',
          'wellTopsWellInterval'
        ],
        combination: [
          'sda',
          'grid',
          'log',
          'wellTrajectory'
        ]
      },
    }
  },
  UNCONVENTIONALS: {
    labels: {
      value: 'UNCONVENTIONALS',
      label: 'Unconventionals'
    },
    platform: MODULE_PLATFORMS.SGO(),
    requirements: {
      files: {
        WELL_INFO: {
          requiredCategories: [
            'Well_Master',
            'Well_Completion_Attributes',
          ],
        },
        PRODUCTION_AND_INJECTION: {
          requiredCategories: [
            'Well_Production_Monthly'
          ],
        },
        WELL_TRAJECTORIES: {
          requiredCategories: [
            'Well_Trajectories'
          ],
        },
        SURFACES: {
          category: 'Grid_Data',
          subgroups: {  
            STRUCTURE_GRIDS: {
              requiredCategories: [
                'structure'
              ],
            },
          },
        },
        GEOLOGICAL_MODELS: {
          category: 'Model_Data',
          subgroups: {
            TWO_D_MODEL: {
              category: 'Grid_Data',
              subgroups: {
                ISOCHORE_GRIDS: {
                  requiredCategories: [
                    'isochore'
                  ]
                },
                POROSITY_GRIDS: {
                  requiredCategories: [
                    'phi'
                  ]
                },
              }
            }
          },
        },
      },
      validations: {
        integration: [
          'grid',
          'wellGrid',
          'wellNames',
          // 'modelWell',
          // 'zoneNames',
          // 'perforationDeviation',
          // 'productionInjection',
          // 'productionPerforation',
          // 'wellTopsWellInterval'
        ],
        combination: [
          'sda',
          'grid',
          'log',
          'wellTrajectory'
        ]
      },
    }
  },
  MODEL_BUILDER: {
    labels: {
      value: 'MODEL_BUILDER',
      label: 'Model Builder'
    },
    platform: MODULE_PLATFORMS.SWM(),
    requirements: {
      files: {
        WELL_INFO: {
          requiredCategories: [
            'Well_Master',
            'Well_Tops',
            'Well_Perforations',
          ],
        },
        PRODUCTION_AND_INJECTION: {
          requiredCategories: [
            'Well_Production_Monthly'
          ],
        },
        TESTING: {
          requiredCategories: [
            'Fluid_PVT',
            'Well_Pressure_Test_Static',
          ],
        },
        WELL_TRAJECTORIES: {
          requiredCategories: [
            'Well_Trajectories'
          ],
        },
        SURFACES: {
          category: 'Grid_Data',
          subgroups: {  
            STRUCTURE_GRIDS: {
              requiredCategories: [
                'structure'
              ],
            },
          },
        },
        LOGS: {
          requiredCategories: [
          ],
        },
        GEOLOGICAL_MODELS: {
          category: 'Geological_Models',
          subgroups: {
            TWO_D_MODEL: {
              category: 'Grid_Data',
              subgroups: {
                ISOCHORE_GRIDS: {
                  alternatives: {
                    ['isochore']: ['ntg']
                  },
                  requiredCategories: [
                    'isochore'
                  ],
                },
                NET_PAY_GRIDS: {
                  alternatives: {
                    ['netPay']: ['ntg']
                  },
                  requiredCategories: [
                    'netPay'
                  ],
                },
                POROSITY_GRIDS: {
                  requiredCategories: [
                    'phi'
                  ],
                },
                WATER_SATURATION_GRIDS: {
                  requiredCategories: [
                    'sw'
                  ],
                },
                PERMEABILITY_GRIDS: {
                  requiredCategories: [
                    'k'
                  ],
                },
                NET_TO_GROSS_GRIDS: {
                  alternatives: {
                    ['ntg']: ['netPay', 'isochore']
                  },
                  requiredCategories: [
                    'ntg'
                  ],
                },
              },
            },
          },
        },
      },
      validations: {
        combination: [
          'grid',
          'sda',
        ],
        integration: [
          'grid',
          'wellGrid',
          'wellNames'
        ],
        moduleIntegration: [
          'SWM'
        ]
      },
    },
  }
}

//Also, add options to the parent group's 'alternative' object
//you probably have to make it.  See existing ones in Geological_Models.
const MODULE_FILE_OPTIONS = {
  RECOMPLETIONS: [
    () => {
      const base = JSON.parse(JSON.stringify(MODULES.RECOMPLETIONS.requirements.files))
      delete base.GEOLOGICAL_MODELS.subgroups.THREE_D_MODEL
      return base
    },
    () => {
      const base = JSON.parse(JSON.stringify(MODULES.RECOMPLETIONS.requirements.files))
      delete base.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL
      return base
    }
  ],
  NEW_DRILLS: [
    () => {
      const base = JSON.parse(JSON.stringify(MODULES.NEW_DRILLS.requirements.files))
      delete base.GEOLOGICAL_MODELS.subgroups.THREE_D_MODEL
      return base
    },
    () => {
      const base = JSON.parse(JSON.stringify(MODULES.NEW_DRILLS.requirements.files))
      delete base.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL
      return base
    }
  ],
  HORIZONTALS: [
    () => MODULES.HORIZONTALS.requirements.files
  ],
  UNCONVENTIONALS: [
    () => MODULES.UNCONVENTIONALS.requirements.files
  ],
  MODEL_BUILDER: [
    () => {
      const base = JSON.parse(JSON.stringify(MODULES.MODEL_BUILDER.requirements.files))
      delete base.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups.NET_PAY_GRIDS
      delete base.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups.ISOCHORE_GRIDS
      return base
    },
    () => {
      const base = JSON.parse(JSON.stringify(MODULES.MODEL_BUILDER.requirements.files))
      delete base.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups.NET_TO_GROSS_GRIDS
      return base
    }
  ],
}

//ADDING FILE OPTIONS TO MODULES.requirements.fileOptions
Object.keys(MODULES).forEach(mod => {
  MODULES[mod].requirements.fileOptions = MODULE_FILE_OPTIONS[mod].reduce((acc, option, i) => {
    acc[i] = option()
    return acc
  }, {})
})

const MODULE_METHODS = {
  /* 
    Used in:
    DatasetFileManager.js (Lines 263, 404.  On module filter change, and loadDataset() )
  */
  updateGroupsFileRequirements: (modules, selectedGeoModel, GroupConfiguration) => {

    const configObject = GroupConfiguration.configuration
    
    //error handling
    if(!Array.isArray(modules)) {
      console.log(`Invalid Input: not an array`)
      return
    } else if(modules.length === 0){
      console.log(`Input: empty array`)
    }

    //If the user has created Custom Grids
    //They're just required fuck them.
    //Only if they have 2.5D grids selected tho.
    if(selectedGeoModel === '2.5D'){
      console.log('is2.5d')
      let customCount = GroupConfiguration.customGrids.length
      console.log('customCount', customCount)
      for(let i = 0; i < customCount; i ++) {
        configObject.GEOLOGICAL_MODELS.subgroups.TWO_D_MODEL.subgroups[`CUSTOM_GRIDS_${i}`].categories[0].required = true
      }
      console.log('config object', configObject)
    }
  
    //Do this for each module requirement 'string' in modules array
    modules.forEach(moduleString => {
      const requirements = MODULES[moduleString].requirements.files
      console.log('Iterating over :', moduleString)
      iterateAndToggle(requirements, configObject)
    })

    function iterateAndToggle(requirements, configObject) {
      //Iterate over a MODULE_REQUIREMNT element's groups (SURFACES, LOGS, etc.)
      for(let groupName in requirements){

        let requiredCategories = requirements[groupName].requiredCategories         // Array
        let configObjectCategories = configObject[groupName].categories             // Array

        //This is the 'alternatives' object, implemented for either-or situations.  
        let alternatives = requirements[groupName].alternatives                     // Object

        if(requiredCategories && requiredCategories.length){
          //If there are no subgroups:
          //Iterate over .requiredCategories (array of required files/file-types) and toggle config object where appropriate.
          for(let categoryString of requiredCategories){
            for(let obj of configObjectCategories){
              if(obj.category === categoryString){
                //We have found the object in groupConfigurations.categories array that matches the categoryString we're looking for.
                //Four cases are possible.
                //1)  The file category is not required yet.
                if(obj.required === false && !obj.alternatives){
                  //We have to make it required
                  obj.required = true
                  //If there are alternative files that can be used instead, we add that information as well.
                  if(alternatives && alternatives[categoryString]){
                    obj.alternatives = alternatives[categoryString]
                  }
                }
                //2) The file category is not required, but has alternatives and may have been marked unrequired because alternatives were met.
                //The function 'updateAllGroupStates' in datasetFileManager markes groups as required = false if alternatives are met.
                if(obj.required === false && obj.alternatives){
                  console.log(`${categoryString} case 2`)
                  //If, for this module, it is required and there are no alternatives, we need to mark it as required and remove the alternatives.
                  if(!(alternatives && alternatives[categoryString])){
                    obj.required = true
                    delete obj.alternatives
                  } else if (alternatives && alternatives[categoryString]){
                    //If for this module, it is required AND THERE ARE ALTERNATIVES, add unique alternatives to the obj.alternatives array.
                    alternatives[categoryString].forEach(alt => {
                      // if(!obj.alternatives.includes(alt)){
                      //   obj.alternatives.push(alt)
                      // }
                      console.log('Double check case 2 logic.  moduleRequirements')
                    })
                  }
                }
                //3) The object is required for this module, but there are alternatives
                //Think, if HORIZONTALS and NEW DRILLS are selected.  Model_Data is a hard requirement for Horizontals, but for New Drills there's 2.5D_Model alternative.
                //This checks that.  If we've iterated over New Drills first, there will be an obj.alternatives.
                //Obj is required with alternatives previously, but now is required with no alternatives, no alternatives needs to override alternatives.
                else if(obj.required === true && obj.alternatives){
                  console.log(`${categoryString} case 3`)
                  if(!(alternatives && alternatives[categoryString])){
                    //If for previoius iteration there were alternatives, but now there are no alternatives
                    //Delete alternative options.
                    delete obj.alternatives
                  }
                }
                //4) The object is already required with no alternatives.
                //We can just leave it alone.  Not nessisary to write code for this case.
              }
            }
          }
        } else if(requirements[groupName].subgroups){
          //If subgroups exist, just use this function on the targeted objects (since JSON structure is the same).
          let requiredSubgroups = requirements[groupName].subgroups          // Object
          let configObjectSubgroups = configObject[groupName].subgroups      // Object
          iterateAndToggle(requiredSubgroups, configObjectSubgroups)
        }
      }
    }
    return configObject
  },

  /*
    Used in:
    addEnabledModulesForNewMod.js (Line 405, copy and pasted in)
    datasetController.js (line 748, creating .enabledModules post validation)
    this code is a mess I'm so sorry.  It might be worth it to rebuild this function at this point -WY 5/22/2020

  */
  doesDatasetVersionHaveRequiredFiles: (hasCategories, MODULES) => {
    //Block 1 - building module file requirements into same object structure as hasCategories

    const baseRequirementsObject = Object.values(FileCategories).reduce((acc, cat, i) => {
      acc[cat] = false
      if(cat === FileCategories.GRID_DATA){
        acc[cat] = GridCategories.reduce((obj, gridType) => {
          obj[gridType] = false
          return obj
        }, {})
      }
      return acc
    }, {})

    const addRequirementsToResults = (files, results) => {
      files.forEach(file => results[file] = true)
    }

    const addRequirementsToGrids = (gridFiles, results) => {
      gridFiles.forEach(file => results.Grid_Data[file] = true)
    }

    const buildSubgroups = (parentGroup, results) => {

      const { category, subgroups } = parentGroup

      if(category === 'Grid_Data'){
        Object.values(subgroups).forEach(subcategory => {
          const files = subcategory.requiredCategories
          addRequirementsToGrids(files, results)
        })
      }
      else if(category === 'Model_Data'){
        if(subgroups.THREE_D_MODEL){
          const files = subgroups.THREE_D_MODEL.requiredCategories
          addRequirementsToResults(files, results)
        }
        if(subgroups.TWO_D_MODEL){
          buildSubgroups(subgroups.TWO_D_MODEL, results)
        }
      }
    }

    const flatModuleOptions = {}
    
    Object.keys(MODULES).forEach(mod => {
      
      flatModuleOptions[mod] = {}
      const moduleFileRequirementOptions = MODULES[mod].requirements.fileOptions

      Object.keys(moduleFileRequirementOptions).forEach(num => {
        const option = moduleFileRequirementOptions[num]
        const results = JSON.parse(JSON.stringify(baseRequirementsObject))

        Object.keys(option).forEach(key => {
          const fileGroup = option[key]
          if(fileGroup.requiredCategories){
            const requiredFiles = fileGroup.requiredCategories
            addRequirementsToResults(requiredFiles, results)
          } else if(fileGroup.subgroups){
            buildSubgroups(fileGroup, results)
          }
          // console.log(`results after ${key}`)
          // console.log(results)
        })

        flatModuleOptions[mod][num] = results
      }) 
    })

    // console.log('FLAT MODULE OPTIONS')
    // console.log(flatModuleOptions)

    // Block 2 - Comparing flatModuleOptions to hasCategories

    const compareOptionToHasCategories = (optionRequirements, hasCategories) => {
      let hasFiles = true
      let missingFiles = []      
      Object.keys(optionRequirements).forEach(file => {
        if(typeof optionRequirements[file] === 'boolean'){
          let has = hasCategories[file]
          let required = optionRequirements[file]
          if(required && !has){
            hasFiles = false
            missingFiles.push(file)
          }
        }
        else if(typeof optionRequirements[file] === 'object'){
          //handle subgroups
          Object.keys(optionRequirements[file]).forEach(subgroupFile => {
            let has = hasCategories[file][subgroupFile]
            let required = optionRequirements[file][subgroupFile]
            if(required && !has){
              hasFiles = false
              missingFiles.push(`${file} - ${subgroupFile}`)
            }
          })
        }
      })

      return { hasFiles, missingFiles }
    }

    const allOptionResults = {}

    Object.keys(flatModuleOptions).forEach(mod => {

      allOptionResults[mod] = {}

      Object.keys(flatModuleOptions[mod]).forEach(i => {
        const optionResults = compareOptionToHasCategories(flatModuleOptions[mod][i], hasCategories)
        allOptionResults[mod][i] = optionResults
      })
    })

    //Block 3 - Determining if one of the options is valid for a module.

    const distinct = (value, index, self) => self.indexOf(value) === index  //This is just a function that filters out duplicate entreis in an array

    return Object.keys(allOptionResults).reduce((acc, mod) => {

      let oneOptionIsValid = false
      let unfilteredMissingFiles = []

      Object.keys(allOptionResults[mod]).forEach(i => {
        if(allOptionResults[mod][i].hasFiles === true){
          oneOptionIsValid = true
        }
        else {
          allOptionResults[mod][i].missingFiles.forEach(file => unfilteredMissingFiles.push(file))
        }
      })

      
      if(oneOptionIsValid){
        acc[mod] = { hasFiles: true, missingFiles: null }
      }
      else {
        const missingFiles = unfilteredMissingFiles.filter(distinct)
        acc[mod] = { hasFiles: false, missingFiles }
      }

      return acc

    }, {})
    
  },

  /* 
    Used in:
    addEnabledModulesForNewModule.js

    rename to doesDatasetVersionHaveRequiredIntegrationForModules
  */
  doesDatasetVersionHaveRequiredIntegrations (version, requiredValidations) {
    const { integration, combination } = version
    const moduleIntegration = version.moduleIntegration ? version.moduleIntegration : null
    const validationArray = moduleIntegration === null ? [integration, combination] : [integration, combination, moduleIntegration]
    
    // Array of successful validations of a dataset version.
    const flatValidations = validationArray.reduce((acc, validationCategory) => {
      Object.keys(validationCategory).forEach(type => {
        if(validationCategory[type].successful === true){
          acc.push(type)
        }
      })
      return acc
    }, [])
  
    let valid = true
    let failed = []
    requiredValidations.forEach(integration => {
      if(!flatValidations.includes(integration)){
        valid = false
        failed.push(integration)
      }
    })
  
    failed = failed.length === 0 ? null : failed
  
    return { valid, failed }
  },
}

const MODULE_OPTIONS = Object.keys(MODULES).reduce((acc, key) => {
  acc.push(MODULES[key].labels)
  return acc
}, [])

export {
  MODULES,
  MODULE_METHODS,
  MODULE_OPTIONS
}