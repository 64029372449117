import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class ThicknesNormalized extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Settings for Thickness Normalized
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Normalization Attribute<br/>
          <span className='blue'>Options:</span> PNP, Perf Length, PNP Hcpt<br/>
          <span className='blue'>Comments:</span> Thickness attribute used for normalization.
        </div>
        <div className="content-row"> 
          Expand Neighborhood<br/>
          <span className='blue'>Options:</span> On, Off<br/>
          <span className='blue'>Comments:</span> If on, neighborhood wells missing the normalization attribute will be replaced with other neighborhood wells (when spatial radius is based on N points).
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(ThicknesNormalized)
