import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import Card from '../common/Card'
import WellCatalogTable from '../components/WellCatalogTable'
import './WellCatalog.scss'

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class WellCatalog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shouldRenderCatalog: false,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    setTimeout(() => {
      this.setState({
        shouldRenderCatalog: true,
      })  
    }, 100)     
  }


  renderWellCatalogTable() {
    const { wellData } = this.props

    if (wellData && this.state.shouldRenderCatalog) {
      return <WellCatalogTable data={wellData} />
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }



  render() {
    console.log('render WellCatalog')

    return (
      <div className="well-catalog">
        <Card title="Well Catalog">
          { this.renderWellCatalogTable() }
        </Card>
      </div>
    )
  }
}

export default WellCatalog
