import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import Button from '@material-ui/core/Button'

import { Carousel } from 'react-responsive-carousel'
import API from '../../../lib/api-store'

import ResourceCarousel from './components/ResourceCarousel/ResourceCarousel'
import ProductCarousel from './components/ProductCarousel/ProductCarousel'
import ProductRow from './components/ProductRow/ProductRow'
import Speedwise from './components/LandingBanners/Speedwise'
import Footer from '../../common/Footer/Footer'

import './Home.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

@autobind class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validApplications: []
    }

    this.carouselItems = [
      <Speedwise />,
    ]
  }


  componentDidMount() {
    this.fetchLicenses()

  }

  fetchLicenses() {

    API.getLicenses()
    .then((licenseOptions) => {
      console.log('this is what the backend returned', licenseOptions)
      let validApplications = [... new Set(licenseOptions.map(i => i.applications).flat())] //Unique array
      this.setState({
        validApplications,
      })
    })
  }

  makeSectionHeader(title) {
    return (<div className={'section-header'}>{title}</div>)
  }


  render() {
    const { validApplications } = this.state
    console.log('render home')

    return (
      <div className='home'>
        {/* Carousel */}
        <div className='carousel-container'>
          <Carousel>
            {this.carouselItems}
          </Carousel>
        </div>
        {/* Subscribed Products */}
        <div className='outer-container'>
          {this.makeSectionHeader(`Products You're Subscribed To`)}
          {validApplications.map(x => <ProductRow product={x} />)}
          {this.makeSectionHeader(<span>SpeedWise<sup>®</sup> Resources</span>)}
          <ResourceCarousel />
          {this.makeSectionHeader(<span>SpeedWise<sup>®</sup> Products Suite</span>)}
          <ProductCarousel />
        </div>
        <Footer /> 
      </div>
    )
  }
}

export default withRouter(Home)
