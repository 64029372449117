import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Select from 'react-select'
import KPI from '../common/KPI'
import { dealWithNaN } from '../../../../lib/helpers'
import API from '../../../../lib/api-store'

import DepthRange from '../../../../../static/images/oppDetailsDepthRangeIcon.svg'
import HCPT from '../../../../../static/images/oppDetailsHCPTIcon.svg'
import ipNFR from '../../../../../static/images/oppDetailsIPNfrIcon.svg'
import MappingRisk from '../../../../../static/images/oppDetailsMappingRiskIcon.svg'
import NeighborGOR from '../../../../../static/images/oppDetailsNeighborGORIcon.svg'
import NeighborP10 from '../../../../../static/images/oppDetailsNeighborP10Icon.svg'
import NeighborP90 from '../../../../../static/images/oppDetailsNeighborP90Icon.svg'
import NeighborWCT from '../../../../../static/images/oppDetailsNeighborWCTIcon.svg'
import Target from '../../../../../static/images/oppDetailsTargetIcon.svg'
import Unp from '../../../../../static/images/oppDetailsUnpIcon.svg'
import Tier from '../../../../../static/images/tier.png'



const tierOptions = [
  { label: 'Low Risk', value: 'Low Risk' },
  { label: 'Mid Risk', value: 'Mid Risk' },
  { label: 'High Risk', value: 'High Risk' },
  { label: 'Discard', value: 'Discard' },
  { label: 'Infeasible', value: 'Infeasible' },
]

@autobind class OpportunityKPIs extends PureComponent {
  handleTierChange(value) {
    const { data, updateOpportunity, caseId } = this.props
    const tier = value.value
    API.changeOpportunityTier(tier, data._id, caseId)
      .then(() => {
        updateOpportunity(tier, data._id)
      })
  }

  render() {
    let { data, caseObj } = this.props

    // TODO REMOVE THIS, find out why data is ever null
    if (!data) {
      data = {}
    }

    let mappingRisk
    if (!data.mappingRisk) {
      mappingRisk = 'Unknown'
    } else if (data.mappingRisk < 50) {
      mappingRisk = 'Low Risk'
    } else if (data.mappingRisk < 80) {
      mappingRisk = 'Moderate Risk'
    } else {
      mappingRisk = 'High Risk'
    }

    const analogStats = data.analogNeighborStats
    const currentStats = data.currentNeighborStats
    const method = caseObj.settings.productionForecast.productionForecast.primaryMethod

    let displayedTierOptions = [...tierOptions]

    let tierOption = tierOptions.find(i => i.value == data.tier)
    if (tierOption) {
      displayedTierOptions.push({ label: 'No Tier', value: null})
    }

    return (
      <div className="kpis">
        <div className="KPI">
          <div className="icon">
            <img src={Tier} style={{paddingLeft: '3px', height: '34px'}}/>
          </div>
          <div className="text">
            Tier
          </div>
          <div className="val" style={{lineHeight: '15px', paddingTop: '3px'}}>
            <Select
              options={displayedTierOptions}
              onChange={this.handleTierChange}
              isMulti={false}
              value={tierOption}
            />
          </div>
        </div>
        <KPI className="" tooltip="Target Zone" icon={Target} header="Target Zone" value={data.zone} />
        <KPI className="" icon={MappingRisk} header="Mapping Risk" value={mappingRisk} />
        <KPI className="" icon={DepthRange} header="Depth Range (MD)" value={`${dealWithNaN(data.startDepthMd).toFixed(0)} - ${dealWithNaN(data.stopDepthMd).toFixed(0)} m`} />
        <KPI className="" icon={Unp} header="UNP TVT" value={`${dealWithNaN(data.mnp).toFixed(2)} m`} />
        <KPI className="" icon={HCPT} header="HCPT" value={`${dealWithNaN(data.origHcpt).toFixed(2)} m`} />
        <KPI className="" icon={ipNFR} header={`IP - ${method}`} value={`${dealWithNaN(data.predictedIp).toFixed(0)} bbl/d`} />
        { analogStats && analogStats.length === undefined && (
          <KPI className="" icon={NeighborP10} header="Initial Neighborhood P10 | P90" value={`${dealWithNaN(analogStats.targetP10).toFixed(0)} - ${dealWithNaN(analogStats.targetP90).toFixed(0)} bbl/d`} />
        )}
        { currentStats && currentStats.length === undefined && (
        <KPI className="" icon={NeighborP90} header="Current Neighborhood P10 | P90" value={`${dealWithNaN(currentStats.targetP10).toFixed(0)} - ${dealWithNaN(currentStats.targetP90).toFixed(0)} bbl/d`} />
        )}
        <KPI className="" icon={NeighborGOR} header="Neighborhood GOR" value={`${dealWithNaN(data.recentGOR).toFixed(1)} scf/bbl`} />
        <KPI className="" icon={NeighborWCT} header="Neighborhood WCT" value={`${dealWithNaN(data.recentWCT * 100).toFixed(1)}%`} />
      </div>
    )
  }
}

export default OpportunityKPIs
