import { fromJS } from 'immutable'
import API from '../../lib/api-store'
const initialState = null

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return fromJS(action.user)
    case 'ADD_FAVORITE_CASE':
      return state.update('favoriteCases', cases => cases.push(fromJS(action.value)))
    case 'REMOVE_FAVORITE_CASE':
      return state.set('favoriteCases', state.get('favoriteCases').filter(i => i !== action.value))
    case 'PIN_CASE_OPPORTUNITY': {
      const pinnedOpportunities = state.get('pinnedOpportunities') || []
      return state.set('pinnedOpportunities', pinnedOpportunities.push(fromJS(action.opportunity)))
    }
    case 'UNPIN_CASE_OPPORTUNITY': {
      const pinnedOpportunities = state.get('pinnedOpportunities') || []
      return state.set('pinnedOpportunities', pinnedOpportunities.filter(i => i.get('_id') !== action.opportunityId))
    }
    case 'EDIT_NAME_AND_DESCRIPTION': {
      const stateObj = state.toJS()
      const { cases } = stateObj
      const index = cases.findIndex(caseObj => action.caseId === caseObj.caseId)
      return state.setIn(['cases', index, 'name'], action.name).setIn(['cases', index, 'description'], action.description)
    }
    case 'LOGOUT': {
      //clear all cookies, will it fix the login issue??
      API.clearCookies()
      
      return initialState
    }
    default:
      return state
  }
}

export default user
