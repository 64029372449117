import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Select from 'react-select'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'            
import API from '../../../../lib/api-store'
import Switch from 'rc-switch'

import './ProjectCollaborators.scss'

const baseClass = 'collaborator-editor'

const headerClass = `${baseClass}__header`

const contentClass = `${baseClass}__content`

const leftClass = `${contentClass}__left`
const rightClass = `${contentClass}__right`
const bottomBarClass = `${rightClass}__bottom-bar`

const supportClass = `${rightClass}__support-bar`
const multiTextRows = 4

@autobind class ProjectCollaborators extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collaboratorOptions: [],
      newCollaborators: [],
      collaborators: [],
    }
  }

  componentDidMount() {
    const { selectedProject, project } = this.props

    API.getCollaboratorOptions(selectedProject, project.license)
      .then(options => {
        this.setState({
          collaboratorOptions: options
        })
      })

    API.getCollaborators(selectedProject, project.license)
      .then(collaborators => {
        this.setState({
          collaborators,
          project,
        })
      })
  }

  supportToggle(val) {
    const { selectedProject, project } = this.props
    val = eval(val)
    console.log(typeof val, val)
    API.toggleProjectSupportAccess(selectedProject, val)
      .then(res => {
        if(res.ok) {
          this.setState({
            project: { ...project, supportAccess: val }
          })
        } else {
          alert('Problem toggling support access')
        }
      })
  }


  onNewCollaborator(value) {
    this.setState({
      newCollaborators: value
    })
  }

  onActionClick() {
    const { newCollaborators, collaboratorOptions, collaborators } = this.state
    const { selectedProject } = this.props

    API.addProjectCollaborators(selectedProject, newCollaborators.map(i => i.value))
      .then(() => {
        this.setState({
          collaboratorOptions: collaboratorOptions.filter(i => !newCollaborators.map(i => i.value).includes(i.value)),
          newCollaborators: [],
          collaborators: [...collaborators, ...newCollaborators.map(i => ({name: i.label, email: i.value}))]
        })
      })
  }

  handleRemoveCollaborator(email) {
    const { collaborators, collaboratorOptions } = this.state
    const { selectedProject } = this.props

    API.removeProjectFromUser(selectedProject, email)
      .then(() => {
        const removedUser = collaborators.find(i => i.email === email)
        const newCollaboratorOptions = [...collaboratorOptions]
        newCollaboratorOptions.push({ 
          label: removedUser.name,
          value: removedUser.email 
        })

        this.setState({
          collaboratorOptions: newCollaboratorOptions,
          collaborators: collaborators.filter(i => i.email !== email)
        })
      })
  }

  makeCollaboratorCards() {
    const { collaborators } = this.state
    const { disabled } = this.props

    return collaborators.map(i => {
      return (
        <div className="collaborator-card">
          <div className="name">
            {i.name}
          </div>
          { !disabled && (
            <div className="button-outer" onClick={() => this.handleRemoveCollaborator(i.email)}>
              <FontAwesomeIcon icon="times-circle" className="icon" />
            </div>
            )
          }
        </div>
      )
    })
  }

  render() {
    const { collaboratorOptions, newCollaborators, collaborators, project: stateProject } = this.state
    const { setModalType, disabled, project: propProject } = this.props

    const project = stateProject || propProject

    return (
      <div className={baseClass}>

        <div className={headerClass} onClick={() => setModalType('edit')}>
          <span> {`< Back to Workspace Details`}</span>
        </div>
        <div className={contentClass}>
          <div className={leftClass}>
            <div className={'card-header'}>
              Workspace Owner
            </div>
            <div className="cards">
              <div className="collaborator-card">
                <div className="name">
                  { project.ownerName }
                </div>
              </div>
            </div>
            <div className={'card-header'}>
              Current Collaborators
            </div>
            <div className="cards">
              {this.makeCollaboratorCards()}
            </div>
          </div>

          <div className={rightClass}>
            <div className={'card-header'}>
              Add Collaborators
            </div>
            <div>
              <Select
                className="collaborator-selector"
                options={collaboratorOptions}
                onChange={this.onNewCollaborator}
                isMulti={true}
                value={newCollaborators}
                isDisabled={disabled}
              />
            </div>
            <div className={bottomBarClass}>
              <Button
                disabled={newCollaborators.length < 1}
                tabIndex="0"
                onClick={this.onActionClick}
                onKeyPress={null}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                disabled={disabled}
              >
                <span>Add Collaborators</span>
              </Button>
            </div>
            <div className={supportClass}>
              <span>Allow QRI / Emerson Support Team project access: </span>
                <Switch
                  disabled={disabled}
                  className={`${supportClass}__switch`}
                  onChange={e => this.supportToggle(e)}
                  checked={eval(project.supportAccess)}
                />
            </div>
            { disabled && (
              <div>
                <em>Only Workspace Creaters can add or remove collaborators</em>
              </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectCollaborators
