import { fromJS } from 'immutable'

const initialState = fromJS({
  selectedWells: [],
  caseId: null,
  viewer: {
    showSettings: false,
    busy: false,
    settings: {
      showPieCharts: true,
      pieSize: 80,
      showTrajectories: true,
      property: 'z',
      trimWells: 1,
      showMesh: true,
      transparency: 100,
      verticalScale: 0,
      surface: [],
    },
  },
})

const waterfloodCase = (state = initialState, action) => {
  const showSettings = state.getIn(['viewer', 'showSettings'])
  const showPieCharts = state.getIn(['viewer', 'settings', 'showPieCharts'])
  const showTrajectories = state.getIn(['viewer', 'settings', 'showTrajectories'])

  switch (action.type) {
    case 'WATERFLOOD_RESET':
      return state
        .set('caseId', action.value.caseId)
        .setIn(['viewer', 'settings', 'surface'], fromJS([action.value.defaultSurface]))
        .set('selectedWells', fromJS([]))
    case 'WATERFLOOD_SET_CASEID':
      return state.set('caseId', action.value)
    case 'WATERFLOOD_SET_TIMELINE':
      return state.set('timeline', action.value)
    case 'WATERFLOOD_SET_SELECTEDWELLS':
      return state.set('selectedWells', fromJS(action.value))
    case 'WATERFLOOD_SET_BUSY':
      return state.setIn(['viewer', 'busy'], action.value)
    case 'WATERFLOOD_SETTINGS':
      return state.setIn(['viewer', 'showSettings'], !showSettings)
    case 'WATERFLOOD_DISPLAY_PIE':
      return state.setIn(['viewer', 'settings', 'showPieCharts'], !showPieCharts)
    case 'WATERFLOOD_DISPLAY_TRAJECTORIES':
      return state.setIn(['viewer', 'settings', 'showTrajectories'], !showTrajectories)
    case 'WATERFLOOD_SET_PIESIZE':
      return state.setIn(['viewer', 'settings', 'pieSize'], action.value)
    case 'WATERFLOOD_SET_TRIMWELLS':
      return state.setIn(['viewer', 'settings', 'trimWells'], action.value)
    case 'WATERFLOOD_DISPLAY_MESH':
      return state.setIn(['viewer', 'settings', 'showMesh'], action.value)
    case 'WATERFLOOD_SET_PROPERTY':
      return state.setIn(['viewer', 'settings', 'property'], action.value)
    case 'WATERFLOOD_SET_TRANSPARENCY':
      return state.setIn(['viewer', 'settings', 'transparency'], action.value)
    case 'WATERFLOOD_SET_VSCALE':
      return state.setIn(['viewer', 'settings', 'verticalScale'], action.value)
    case 'WATERFLOOD_SET_SURFACE':
      return state.setIn(['viewer', 'settings', 'surface'], fromJS(action.value))
    default:
      return state
  }
}


export default waterfloodCase
