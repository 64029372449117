const reset = value => ({ type: 'WATERFLOOD_RESET', value })
const setTimeline = value => ({ type: 'WATERFLOOD_SET_TIMELINE', value })
const setSelectedWells = value => ({ type: 'WATERFLOOD_SET_SELECTEDWELLS', value })
const toggleSettings = () => ({ type: 'WATERFLOOD_SETTINGS' })
const togglePieCharts = () => ({ type: 'WATERFLOOD_DISPLAY_PIE' })
const toggleTrajectories = () => ({ type: 'WATERFLOOD_DISPLAY_TRAJECTORIES' })
const setPieSize = value => ({ type: 'WATERFLOOD_SET_PIESIZE', value })
const setTrimWells = value => ({ type: 'WATERFLOOD_SET_TRIMWELLS', value })
const showMesh = value => ({ type: 'WATERFLOOD_DISPLAY_MESH', value })
const setProperty = value => ({ type: 'WATERFLOOD_SET_PROPERTY', value })
const setTransparency = value => ({ type: 'WATERFLOOD_SET_TRANSPARENCY', value })
const setVScale = value => ({ type: 'WATERFLOOD_SET_VSCALE', value })
const setSurface = value => ({ type: 'WATERFLOOD_SET_SURFACE', value })
const setViewerBusy = value => ({ type: 'WATERFLOOD_SET_BUSY', value })

export {
  reset,
  showMesh,
  setVScale,
  setPieSize,
  setSurface,
  setProperty,
  setTimeline,
  setTrimWells,
  toggleSettings,
  setTransparency,
  setSelectedWells,
  toggleTrajectories,
  togglePieCharts,
  setViewerBusy,
}
