import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import Card from '../../common/Card'
import OpportunityKPIs from '../../components/OpportunityKPIs'
import WellProduction from '../../components/WellProduction'

import './Summary.scss' 

const loaderCSS = css`top: calc(50% - 25px); left: calc(50% - 25px); position: relative;`

@autobind class OpportunitySummary extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shouldRenderDetails: false,
      shouldRenderProduction: false,
    }
  }

  componentDidMount() {
    const { setCurPage } = this.props
    setCurPage('')
    this.loadData()
  }

  loadData() {
    setTimeout(() => {
      this.setState({
        shouldRenderDetails: true,
      })  
    }, 100)

    setTimeout(() => {
      this.setState({
        shouldRenderProduction: true,
      })  
    }, 200)
  }

  renderDetails() {
    const { singleOpportunity, updateOpportunity, caseId, caseObj } = this.props
    if (singleOpportunity && this.state.shouldRenderDetails) {
      return  (
        <OpportunityKPIs
          caseId={caseId}
          caseObj={caseObj}
          data={singleOpportunity}
          updateOpportunity={updateOpportunity}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  renderProduction() {
    const { time, oilRate, waterCut, gor } = this.props
    if (time && this.state.shouldRenderProduction) {
      return (
        <WellProduction
          time={time}
          oilRate={oilRate}
          waterCut={waterCut}
          gor={gor}
        />
      )
    }
    return (
      <div className="loading-background">
        <ClipLoader css={loaderCSS}/>
      </div>
    )
  }

  render() {
    const { map } = this.props
    return (
      <div className="opportunity-summary">
        <div className="top">
          <div className="left">
            {map}
          </div>
          <div className="right">
            <Card title="Opportunity Details">
              { this.renderDetails() }
            </Card>
          </div>
        </div>
        <div className="bot">
          <Card title="Well Production">
            { this.renderProduction() }
          </Card>
        </div>
      </div>
    )
  }
}


export default OpportunitySummary
