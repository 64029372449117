import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class LogCleaning extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Log Cleaning
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Includes settings used for cleaning and plotting logs. These include trimming bounds, plotting limits, fill type, fill bounds, fill colors and/or color maps.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Clean Logs:</span> If on, cleans out of bound logs based on the corresponding “Trim Min Value” and “Trim Max Value” which are defined in the “Log Settings” under “Global Settings.”
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Clean with Nulls:</span> If no, out-of-bound values are replaced with nulls. Otherwise, it will be set to the limit.
        </div>
        {/* <div className="content-row"> 
          &ensp;• <span className='blue'>Merge Multi-Log Files:</span> If on, multiple log files per well will be merged as one.
        </div> */}
        <div className="content-row"> 
          &ensp;• <span className='blue'>Resample Interval:</span> Depth interval (meters) used for log resampling.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(LogCleaning)
