import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import ProductionForecastOverviewA from '../../../../../../../../../../static/images/userManual/settings/productionForecastOverviewA.png'

@autobind class ProductionForecastOverview extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Production Forecast Overview
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          This step uses the selected forecast method to generate a prediction for each opportunity’s initial production (IP) rate. Table 13 lists the available forecast methods and summarizes the pros and cons of each one. Table 14 lists all the settings that you can control in order to tailor the evaluation. This step also estimates an EUR using the predicted IP and the decline parameters aggregated from the neighborhood wells.
        </div>
        <div className="content-row"> 
          Important Notes:
        </div>
        <div className="content-row"> 
          • These production forecasts are meant to serve as a first-pass estimate of the relative potential of each opportunity. It is suggested to use these forecasts in conjunction with other attributes of the opportunities (such as risk, calculated in the next step) for ranking purposes. It is also generally recommended to test more than one forecast method and review which is the most appropriate for your reservoir.
        </div>
        <div className="content-row"> 
          prod• The production forecasts for various opportunities is performed on a zone-by-zone basis. To achieve this, statistical and machine learning methods (TC, STI, NFR, TNNFR, and NN) require the result of production allocation. Therefore, incorrect allocation will almost certainly lead to wrong initial rate forecasts when using statistical methods. It is recommended to use PLT data for allocation when available.
        </div>
        <div className='image-container'>
          <img src={ProductionForecastOverviewA} alt="" />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(ProductionForecastOverview)
