import React from 'react'

import img1 from './static/WellPressureTestStatic1.png'
import img2 from './static/WellPressureTestStatic2.png'

const WellPressureTestStatic = (
  <div className={'description-container'}>
    <p><b>Content:</b> Any static pressure measurements taken in the field at the wells are reported in this file.</p>
    <p><b>Utility:</b> To provide well-level static pressure measurements for estimating average reservoir pressure over time.</p>
    <p><b>Conventions: Gauge Depth, MidPerfDepth, DatumDepth: </b> Values below sea level need to be positive</p>
    <p><d>Date:</d> It must be in mm/dd/yyyy format</p>
    <p><b>File Format:</b> CSV template (downloadable) (example shown in Figure 10).</p>
    <p><b>Units:</b> Template indicates what units are needed.</p>
    <p><b>Other Note:</b> There are TWO OPTIONS for required columns. The options are:</p>
    <ol>
      <li>WellName, Completion, Date, and DatumPressure</li>
      <li>WellName, Completion, Date, GaugeDepth, GaugePressure, and DatumDepth</li>
    </ol>
    <p>However, it is encouraged to provide additional information, if available, to provide a complete picture of the pressure data, especially if the data was compiled and manipulated from pressure test reports (MDT, etc.). See Table 10 for specifics.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 10: Example of the first few rows of a “Well_Pressure_Test_Static.csv.”</figcaption>
    </figure>
    <figure>
      <figcaption>Table 10. Details for the “Well_Pressure_Test_Static.csv.”</figcaption>
      <img src={img2} />
    </figure>
  </div>
)

export default WellPressureTestStatic