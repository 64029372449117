import React from 'react'
import QRILogo from '../../../../static/images/QRILogo.png'
import EmersonLogo from '../../../../static/images/emersonLogo.png'
import './Footer.scss'

const baseClass = 'home-footer'

const emersonLink = 'https://www.emerson.com/en-us/news/automation/2003-quantum-reservoir-impact'

const Footer = () => {
  return (
    <div className={baseClass}>
      <img src={QRILogo} />
      <img src={EmersonLogo} />
      <div className={'copyright-stuff'}>
        <div className={'wrapper'} >
          <p>© SpeedWise<sup>®</sup> is a registered trademark and property of Quantum Reservoir Impact, LLC (QRI).  All rights reserved.</p>
          <p>Emerson is the exclusive global distributor for SRO <a href={emersonLink} target="_blank" >under this agreement</a>.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer