import * as THREE from "three"
import PieChart from './PieChart'

class Trajectories {
  constructor(cfg) {
    cfg = cfg || {}

    this.trajectoryData = cfg.trajectoryData || null
    this.reservoir = cfg.reservoir || null

    this.selectedWells = []
    this.wells = []
    this.piecharts = []

    this.bounds = this.getBounds()
    this.build()
  }

  getBounds() {
    const vertices = this.reservoir.xyBoundary
    return vertices.reduce((accum, curr) => {
      return {
        x: {
          min: Math.min(accum.x.min, curr.x),
          max: Math.max(accum.x.max, curr.x),
        },
        y: {
          min: Math.min(accum.y.min, curr.y),
          max: Math.max(accum.y.max, curr.y),
        },
      }
    }, { x: { min: Infinity, max: -Infinity }, y: { min: Infinity, max: -Infinity } })
  }

  build() {
    this.trajectoryData.forEach((wellTrajectory) => {
      const status = wellTrajectory.timeSeriesData[0].status
      const bounds = this.bounds
        
      let color = 0xffffff
      if (status === 'injector') {
        color = 0xadd8e6
      } else if (status === 'producer') {
        color = 0x00008b
      }

      const boundingGeometry = new THREE.PlaneGeometry(bounds.x.max - bounds.x.min, bounds.y.max - bounds.y.min)
      boundingGeometry.computeBoundingBox()
      const bbox = boundingGeometry.boundingBox

      const trajVertices = wellTrajectory.trajectory.map(p => new THREE.Vector3(p.x - this.bounds.x.min + bbox.min.x, p.y + bbox.min.y - this.bounds.y.min, -p.tvd))
      const curve = new THREE.CatmullRomCurve3(trajVertices)
      const points = curve.getPoints(100)
      const g = new THREE.BufferGeometry().setFromPoints(points)
      g.computeBoundingSphere()
      g.boundingSphere.radius *= 2
      const well = new THREE.Line(g.clone(), new THREE.LineBasicMaterial({
        color,
        polygonOffset: true,
        polygonOffsetFactor: -1.0,
        polygonOffsetUnits: -4.0,
      }))
    
      well.name = wellTrajectory._id
      
      const piechart = new PieChart({
        associated_well: well,
        SceneVariables: {},
        objname: 'wellpie',
        rate: {
          oil: wellTrajectory.timeSeriesData[0].oilProdRate,
          water: wellTrajectory.timeSeriesData[0].waterProdRate,
          injection: wellTrajectory.timeSeriesData[0].waterInjRate,
        },
      })


      this.wells.push(well)
      this.piecharts.push(piechart.PieObject)
    })
  }

  setPieSizePercentage(scale) {
    this.piecharts.forEach((pie) => {
      pie.Pie.setPieSizePercentage(scale)
    })
  }

  displayPieCharts(display) {
    this.piecharts.forEach((piechart) => {
      if (this.selectedWells.includes(piechart.name)) {
        piechart.visible = display
      }
    })
  }

  displayTrajectories(display) {
    this.piecharts.forEach((piechart) => {
      if (this.selectedWells.includes(piechart.name)) {
        piechart.Pie.associated_well.visible = display
      }
    })
  }
  
  filter(selectedWells) {
    this.selectedWells = selectedWells
    this.piecharts.forEach((piechart) => {
      if (selectedWells.length > 0 ) {
        if (selectedWells.includes(piechart.name)) {
          piechart.visible = true
          piechart.Pie.associated_well.visible = true
        } else {
          piechart.visible = false
          piechart.Pie.associated_well.visible = false
        }
      } else {
        piechart.visible = true
        piechart.Pie.associated_well.visible = true
      }
    })
  }
}

export default Trajectories
