import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { withRouter } from 'react-router-dom'

import ModuleOption from './ModuleOption'
import './ModuleSelection.scss'

@autobind class ModuleSelection extends Component {

  makeOptions() {
    const { selectedModule, attemptModuleChange, match } = this.props
    const { application } = match.params

    if (application === 'SWM') {
      return (
        <React.Fragment>
          <div className="module-row">
            <ModuleOption
              name='Model Builder'
              selected={selectedModule === 'modelBuilder'}
              value='modelBuilder'
              attemptModuleChange={attemptModuleChange}
              iconName='modelBuilder'
            />
            <ModuleOption
              name='Optimization'
              selected={selectedModule === 'swmOptimization'}
              value='swmOptimization'
              attemptModuleChange={attemptModuleChange}
              iconName='swmOptimization'
            />
            <ModuleOption
              name='Forecast'
              icon='power-off'
              selected={selectedModule === 'TODO'}
              value='TODO'
              attemptModuleChange={attemptModuleChange}
              disabled={true}
            />
          </div>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment> 
        <div className="module-row">
          <ModuleOption
            name='Recompletions'
            icon='arrow-down'
            selected={selectedModule === 'generateWorkovers'}
            value='generateWorkovers'
            attemptModuleChange={attemptModuleChange}
          />
          <ModuleOption
            name='Sweet Spots'
            icon='tint'
            selected={selectedModule === 'verticals'}
            value='verticals'
            attemptModuleChange={attemptModuleChange}
          />
          <ModuleOption
            name='Horizontals'
            icon='bars'
            selected={selectedModule === 'horizontals'}
            value='horizontals'
            attemptModuleChange={attemptModuleChange}
          />
        </div>
        <div className="module-row">
          <ModuleOption
            name='Unconventionals'
            icon='arrow-up'
            selected={selectedModule === 'unconventionals'}
            value='unconventionals'
            attemptModuleChange={attemptModuleChange}
          />
          <ModuleOption
            name='Reactivations'
            icon='power-off'
            selected={selectedModule === 'TODO'}
            value='TODO'
            attemptModuleChange={attemptModuleChange}
            disabled={true}
          />
          {/* <ModuleOption
            name='GeoModel Validation'
            icon='chart-bar'
            selected={selectedModule === 'TODO'}
            value='TODO'
            attemptModuleChange={attemptModuleChange}
            disabled={true}
          /> */}
          <ModuleOption
            name='GeoModel Update'
            icon='arrow-down'
            selected={selectedModule === 'TODO'}
            value='TODO'
            attemptModuleChange={attemptModuleChange}
            disabled={true}
          />
        </div>
      </React.Fragment>
    )
  }
  render() {

    return (
      <div className="module-selection">
        {this.makeOptions()}
      </div>
    )
  }
}

export default withRouter(ModuleSelection)
