  
import * as THREE from "three"

import HoverHelper from './three/HoverHelper'
import Trajectories from './three/Trajectories'
import SurfaceManager from './three/SurfaceManager'
import TrackballControls from './three/TrackballControls'
import * as WaterfloodService from '../services/waterfloodService'

/*
 * All credit goes to looeee. TG for forums.
 * Reference: https://discourse.threejs.org/t/camera-zoom-to-fit-object/936/3
 */ 
const fitCameraToObject = ( camera, object, offset, controls ) => {

  offset = offset || 1.25;

  const boundingBox = new THREE.Box3();

  // get bounding box of object - this will be used to setup controls and camera
  boundingBox.setFromObject( object );

  const size = boundingBox.getSize()

  // get the max side of the bounding box (fits to width OR height as needed )
  const maxDim = Math.max( size.x, size.y, size.z );
  const fov = camera.fov * ( Math.PI / 180 );
  let cameraZ = Math.abs( maxDim / 4 * Math.tan( fov * 2 ) );

  cameraZ *= offset; // zoom out a little so that objects don't fill the screen

  camera.position.z = cameraZ;

  const minZ = boundingBox.min.z;
  const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;

  camera.far = cameraToFarEdge * 100;
  camera.updateProjectionMatrix();
  camera.lookAt(object)
}

class GridViewer  {
  constructor(cfg) {
    cfg = cfg || {}

    this.case = cfg.case || ''
    this.face = cfg.surface || ['KS0']
    this.property = cfg.property || 'perm'


    this.timeline = cfg.timeline || 0
    this.reservoir = cfg.reservoir || null
    this.mount = cfg.mount || null
    this.filteredWells = cfg.filteredWells || []

    this.transparency = cfg.transparency !== undefined ? cfg.transparency : 100
    this.displayMesh = cfg.displayMesh !== undefined ? cfg.displayMesh : true
    this.displayChart = cfg.displayChart !== undefined ? cfg.displayChart : true
    this.displayTrajectories = cfg.displayTrajectories !== undefined ? cfg.displayTrajectories : true

    this.renderer = null

    this.trajectories = []
    this.pieCharts = []
    this.surfaces = []

    this.trajectoryGroup = new THREE.Group()
    this.pieGroup = new THREE.Group()

    this.initRenderer()

    this.buildSurface().then((status) => {
      this.buildTrajectories()
      this.initControls()
    })
  }

  /*
   * Setup renderer
   */
  initRenderer() {
    const scene = new THREE.Scene()
    this.scene = scene

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: false,
      precision: 'highp',
      alpha: true,
    })

    this.surfaceMgr = new SurfaceManager({
      case: this.case,
      surfaces: this.face,
      property: this.property,
      reservoir: this.reservoir,
      displayMesh: this.displayMesh,
      transparency: this.transparency,
    })

    const canvasWidth = this.mount.current.offsetWidth
    const canvasHeight = canvasWidth

    const aspectRatio = canvasWidth / canvasHeight
  
    const fov = 70
    const aspect = aspectRatio // canvasWidth / canvasHeight
    const near = 1
    const far = 300000
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
    this.camera = camera

    scene.add(new THREE.AmbientLight(0xFAFAFA, 1))
    this.scene = scene

    const light = new THREE.DirectionalLight(0xdfebff, 1)
    light.position.set(50, 200, -1000)
    light.position.multiplyScalar(1.3)
    scene.add(light)

    //camera.position.z = 15000
    this.renderer.setSize(canvasWidth, canvasHeight)
    this.renderer.setClearColor(0xffffff, 0.5)
    this.renderer.setPixelRatio(window.devicePixelRatio)

    if (this.mount.current) {
      this.mount.current.appendChild(this.renderer.domElement)
    }
  }

  initControls(){
    const controls = new THREE.TrackballControls(this.camera, this.renderer.domElement)

    this.renderer.render(this.scene, this.camera)

    const canvas = this.renderer.domElement
    canvas.style.maxWidth = '100%'
    this.pickPosition = {x: 0, y: 0}
    const pickHelper = new HoverHelper()
    this.clearPickPosition()

    const textbox = this.createTextbox()
    this.renderer.domElement.parentElement.appendChild(textbox)

    const me = this
    const rendererRef = this.renderer

    let initialized = false;
    const animate = (time) => {
      time *= 0.001
      if (!initialized || me.resizeRendererToDisplaySize(rendererRef)) {
        initialized =  true
        const canvas = rendererRef.domElement;
        me.camera.aspect = canvas.clientWidth / canvas.clientHeight;
        me.camera.updateProjectionMatrix()
      }

      pickHelper.pick(me.pickPosition, me.scene, me.camera, time, textbox, canvas)

      
      requestAnimationFrame(animate)
      controls.update()
      rendererRef.render(me.scene, me.camera)
    }
    animate()

    window.addEventListener('mousemove', (e) => {this.setPickPosition(e, canvas, this)})
  }


  async buildSurface() {
    const me = this
    const status = await this.surfaceMgr.buildSurfaces(this.face)
    me.scene.add(me.surfaceMgr.surfacesGroup)
    const sceneCtr = me.scene.position
    me.surfaceMgr.surfacesGroup.scale.set(0.2, 0.2, 0.2)
    me.surfaceMgr.surfacesGroup.position.set(sceneCtr.x, sceneCtr.y, sceneCtr.z)
    fitCameraToObject(this.camera, this.surfaceMgr.surfacesGroup, 4)
    return status
  }


  async buildTrajectories() {
    const trajectoryData = await WaterfloodService.getCaseTrajectories(this.case, this.timeline)

    this.trajectories = new Trajectories({
      trajectoryData,
      reservoir: this.reservoir,
    })

    this.trajectoryGroup.children = []

    this.trajectories.wells.forEach((well) => {
      this.trajectoryGroup.add(well)
    })

    this.trajectories.piecharts.forEach((pie) => {
      this.trajectoryGroup.add(pie)
    })
    
    const sceneCtr = this.scene.position
    this.trajectoryGroup.scale.set(0.2, 0.2, 0.2)
    this.trajectoryGroup.position.set(sceneCtr.x, sceneCtr.y, sceneCtr.z)

    this.filterWells(this.filteredWells)

    this.scene.add(this.trajectoryGroup)
  }

  clearTrajectories() {
    this.trajectoryGroup.children.forEach((obj) => {
      obj.geometry.dispose()
      obj.material.dispose()
      this.trajectoryGroup.remove(obj)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement
    const width = canvas.clientWidth
    const height = canvas.clientHeight
    const needResize = (canvas.width / 2) !== width || (canvas.height / 2) !== height
    if (needResize) {
      renderer.setSize(width, height, true)
    }
    return needResize
  }

  clearPickPosition() {
    this.pickPosition.x = -100000
    this.pickPosition.y = -100000
  }

  setPickPosition(event, canvas, obj) {
    const pos = obj.getCanvasRelativePosition(event, canvas)
    this.pickPosition.x = (pos.x / canvas.clientWidth) * 2 - 1
    this.pickPosition.y = (pos.y / canvas.clientHeight) * -2 + 1  // note we flip Y
  }

  // eslint-disable-next-line class-methods-use-this
  getCanvasRelativePosition(event, canvas) {
    const rect = canvas.getBoundingClientRect()
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    }
  }

  setPieChart(displayChart) {
    this.displayChart = displayChart
    if (this.trajectories) {
      this.trajectories.displayPieCharts(displayChart)
    }
  }

  setTrajectories(displayTrajectories) {
    this.displayTrajectories = displayTrajectories
    if (this.trajectories) {
      this.trajectories.displayTrajectories(displayTrajectories)
    }
  }

  setPieSize(scale) {
    this.trajectories.setPieSizePercentage(scale)
  }

  updateTimeline(timeline) {
    this.timeline = timeline
    this.clearTrajectories()
    this.buildTrajectories()
  }

  filterWells(selectedWells) {
    this.filteredWells = selectedWells
    this.trajectories.filter(selectedWells)
  }

  //#region Surface Controls

  async updateSurface(surfaces) {
    this.surfaceMgr.updateSurfaces(surfaces).then((status) => {
      return status
    })
  }

  updateTransparency(transparency) {
    this.surfaceMgr.updateTransparency(transparency)
  }

  showMesh(showMesh) {
    this.surfaceMgr.showMesh(showMesh)
  }

  updateProperty(property) {
    this.surfaceMgr.updateProperty(property)
  }

  //#endregion Surface Controls


  // eslint-disable-next-line class-methods-use-this
  createTextbox() {
    const textbox = document.createElement('div')

    textbox.className = 'wellinfo_textbox'
    textbox.style.position = 'absolute'
    textbox.style.background = 'rgba(255, 255, 255, .5)'
    textbox.style.border = '2px solid #CCC'
    textbox.innerHTML = ''
    textbox.validwell = false

    return textbox
  }
}

export default GridViewer
