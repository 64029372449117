import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class InterferenceAnalysis extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Interference Analysis for Horizontals
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          The goal of this step is to select the best set of non-interfering candidates that optimize a given objective function. Interference between two targets is defined when the shortest distance between these two targets (in 3D space) is smaller than a user-defined spacing parameter. A typical objective is to simply maximize production, which is why this step is described after the production forecast in this workflow. However, because the initial set of identified targets can exceed the thousands, the production forecast cannot be run in a realistic time, mainly as a result of the cost of performing pay tracking on all of them.
        </div>
        <div className="content-row"> 
          Therefore, by default, the interference algorithm is set to select the set of non-interfering candidates that maximize RPOS. Note that interference with planned activities (i.e., planned horizontal/deviated) targets will also be accounted for if this information is provided by the client. There is also the option of first performing a connected component analysis and run the optimization problem on each component in order to allow for better exploration of candidates.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(InterferenceAnalysis)
