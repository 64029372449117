import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './LicenseWarning.scss'

const warningLimit = .8

const warningMap = {
  jobs: {
    text: 'Jobs',
    explanation: 'Jobs are needed for execution and will only be counted if execution is successfully complete.'
  },
  jobHours: {
    text: 'Job Hours',
    explanation: 'Job hours are needed for execution, as well as for dataset validations.',
  },
  storage: {
    text: 'Storage (GBs)',
    explanation: 'Storage is used to store case visualization results, as well as dataset files.'
  }
}

@autobind class LicenseWarning extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  makeWarnings(categories, type, usedMap, limitMap) {
    let headerText = type == 'max' ? 'You have used all of the following:' : 'You are almost out of the following:'
    return (
      <div className='warnings-inner'>
        <div className='warnings-inner-header'> 
          {headerText}
        </div>
        {categories.map(category => {
          return this.makeCategory(category, type, usedMap, limitMap)
        })}

        {type == 'max' && (
          <div className='warnings-inner-text2'>
            Execution cannot be ran until more units are purchased
          </div>
        )}
        <div className='warnings-inner-text3'>
          Please request your License or Account Admins to contact support.
        </div>
      </div>
    )
  }


  makeCategory(category, type, usedMap, limitMap) {
    let used = usedMap[category]
    let limit = limitMap[category]
    let text = warningMap[category].text
    let explanation = warningMap[category].explanation

    let perc = Math.min(Math.round(used/limit*100), 100)
    let remaining = 100 - perc

    let curStyle = {width: `${perc}%`}
    if (type == 'max') {
      curStyle['borderRadius'] = '5px'
    }
    return (
      <div className='category-info'>
        <div className='category-info-text'>
          <b>{text}</b> - {used} of {limit} used 
        </div>
        <div className='category-info-perc-bar'>
          <div className={'category-info-perc-bar-cur'} 
            style={curStyle}
          />
          <div className={'category-info-perc-bar-remaining'} 
            style={{width: `${remaining}%`}}
          />
        </div>
        <div className='category-info-explanation'>
          {explanation}
        </div>
      </div>
    )
  }

  render() {
    let { curStorageGB,
      storageLimit,
      jobHoursUsed,
      jobHourLimit,
      jobsUsed,
      jobLimit,
      handleClose
    } = this.props

    console.log('test', handleClose)

    let jobPerc = jobsUsed / jobLimit
    let storagePerc = curStorageGB / storageLimit
    let jobHourPerc = jobHoursUsed / jobLimit

    let maxed = []
    let warnings = []

    if (storagePerc >= 1) {
      maxed.push('storage')
    } else if (storagePerc >= warningLimit) {
      warnings.push('storage')
    }
    
    if (jobPerc >= 1) {
      maxed.push('jobs')
    } else if (jobPerc >= warningLimit) {
      warnings.push('jobs')
    }

    if (jobHourPerc >= 1) {
      maxed.push('jobHours')
    } else if (jobHourPerc >= warningLimit) {
      warnings.push('jobHours')
    }

    console.log('oh baby', maxed, warnings)
    let usedMap = {
      jobs: jobsUsed,
      jobHours: jobHoursUsed,
      storage: curStorageGB
    }

    let limitMap = {
      jobs: jobLimit,
      jobHours: jobHourLimit,
      storage: storageLimit
    }


    return (
      <div className="license-warning">
        <div className='warning-header'>
          <div className='warning-header-icon'> 
            <FontAwesomeIcon icon="exclamation-triangle" />
          </div>
          <div className='warning-header-text'> Warning! </div>
          {handleClose && (
            <span 
                style={{'float': 'right', paddingRight: '15px', lineHeight: '50px', cursor: 'pointer', fontSize: '25px', color: 'black'}} 
                onClick={handleClose}
              >
                  X
            </span> 
          )}
        </div>
        <div className='warning-body'>
          {maxed.length > 0 && (
            this.makeWarnings(maxed, 'max', usedMap, limitMap)
          )}
          {warnings.length > 0 && (
            this.makeWarnings(warnings, 'warning', usedMap, limitMap)
          )}
        </div>
      </div>
    )
  }
}


export default withRouter(LicenseWarning)
