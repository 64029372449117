import React from 'react'

import img1 from './static/WellInjectionLog1.png'
import img2 from './static/WellInjectionLog2.png'

const WellInjectionLog = (
  <div className={'description-container'}>
    <p><b>Content:</b> Stored injection log test results.</p>
    <p><b>Utility:</b> For calibrating injection data.</p>
    <p><b>Conventions:</b> It must be in mm/dd/yyyy format</p>
    <p><b>File Format:</b> CSV template provided (example shown in Figure 13).</p>
    <p><b>Units:</b> Template indicates what units are needed.</p>
    <p><b>Other Note:</b> Not all columns are required. See Table 13 for specifics.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 13: Example of the first few rows of a “Well_Injection_Log.csv.”</figcaption>
    </figure>
    <figure>
      <figcaption>Table 13. Details for the “Well_Injection_Log.csv.”</figcaption>
      <img src={img2} />
    </figure>
  </div>
)

export default WellInjectionLog