import React, { useState } from 'react'

import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import styled from '@material-ui/core/styles/styled'

import './NewErrors.scss'

const baseClass = `dataset-new-errors`
const toggleButton = `${baseClass}__toggle-button`


const Less = styled(ExpandLess)({
  marginBottom: '-6px',
  marginLeft: '-4px',
})
const More = styled(ExpandMore)({
  marginBottom: '-6px',
  marginLeft: '-4px',
})

const Bottom = ({ suggestion, impact, open }) => {
    if(!!suggestion && !!impact) {
      return (<>
        <p><b>Suggestion(s):</b></p>
        <Collapse in={open}>
          <p>{suggestion}</p>
          <p><b>Impact:</b></p>
          <p>{impact}</p>
          <p><br/></p>
        </Collapse>
      </>)
    } else if(!!suggestion && !impact){
      return (<>
        <p><b>Suggestion(s):</b></p>
        <Collapse in={open}>
          <p>{suggestion}</p>
          <p><br/></p>
        </Collapse>
      </>)
    } else if(!suggestion && !!impact){
      return (<>
        <p><b>Impact:</b></p>
        <Collapse in={open}>
          <p>{impact}</p>
          <p><br/></p>
        </Collapse>
      </>)
    } else if(!suggestion && !impact){
      return null
    }
  }

export default function Error({ isCritical, description, suggestion, impact, i }) {

  const [isOpen, setOpen] = useState(false)
  
  return(
    <div className={baseClass}>
        <p><b>{i + 1}) Description:</b></p>
        <p>{description}</p>
        <Bottom
          suggestion={suggestion}
          impact={impact}
          open={isOpen}
        />        
      { (!!suggestion || !!impact) && (
        <a
          onClick={() => setOpen(!isOpen)}
          className={toggleButton}
        >
          {isOpen ? <span>Read Less <Less/></span> : <span>Read More <More/></span>}
        </a>
      )}
      <Divider />
    </div>
  ) 

}