import { fromJS } from 'immutable'

const initialState = fromJS({
  tooltipKey: null,
  tooltipOpen: false,
  tooltipTitle: null,
})

const SGOVisualization = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOOLTIP': {
      const { key, title } = action.payload
      return state.set('tooltipTitle', title)
        .set('tooltipOpen', true)
        .set('tooltipKey', key)
    }
    case "CLOSE_TOOLTIP": {
      return state.set('tooltipOpen', false)
        .set('tooltipKey', null)
        .set('tooltipTitle', null)
    }
    default:
      return state
  }
}

export default SGOVisualization