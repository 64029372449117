import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import Button from '@material-ui/core/Button'

import ResourceMap from './ResourceMap.js'

import "react-multi-carousel/lib/styles.css"
import "./ResourceCarousel.scss"

const ResourceCard = ({title, description, buttonText, link, image, location }) => {

  //OK SO, when you import an SVG, for some reason, it comes in as a FUNCTION
  //to get the image, you have to execute the function.  SO WEIRD!
  //If you have a PNG or something, you need to check.
  return (
    <div className={'resource-card'}>
      <img src={image} />
      <div className={'text'}>
        <div className={'resource-card-header'}><span>{title}</span></div>
        <div className={'resource-card-description'}><span>{description}</span></div>
      </div>
      <NavLink
        to={{
          pathname: link,
          state: { prevPath: location.pathname }
        }}
        className={'button-container'}
      >
        <Button className="qri-button primary">{buttonText}</Button>
      </NavLink>
    </div>
  )  
}

const ResourceCarousel = ({ location }) => {

  const settings = {
    swipeable: false,
    draggable: false,
    infinite: false,
    arrows: false,
    transitionDuration: 700,
  }

  const responsive = {
    // the naming can be any, depends on you.
    desktop: {
      breakpoint: { max: 100000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <>
      <div className={'resource-carousel'}>
        <Carousel responsive={responsive} {...settings}>
          {ResourceMap.map(x => <ResourceCard location={location} {...x}/>)}
        </Carousel>
      </div>
    </>
  )
}

export default withRouter(ResourceCarousel)