import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import Step from './Step'
import API from '../../../../../lib/api-store'

import './StepStatus.scss'

const generateWorkoversSteps = [
  { step: '1', label: 'Initialization'},
  { step: '2', label: 'Engineering Analytics'},
  { step: '3', label: 'Log/Zone Analytics'},
  { step: '4', label: 'Attribute Mapping'},
  { step: '5', label: 'Drainage Analysis'},
  { step: '6', label: 'Target Search'},
  { step: '7', label: 'Production Forecast'},
  { step: '8', label: 'Opportunity Filtering'},
]

const verticalSteps = [
  { step: '1', label: 'Initialization'},
  { step: '2', label: 'Engineering Analytics'},
  { step: '3', label: 'Log/Zone Analytics'},
  { step: '4', label: 'Attribute Mapping'},
  { step: '5', label: 'Drainage Analysis'},
  { step: '6', label: 'Map Generation'},
  { step: '7', label: 'Sweet Spot Ranking'},
  { step: '8', label: 'Target Search'},
  { step: '9', label: 'Production Forecast'},
  { step: '10', label: 'Opportunity Filtering & Finalization'},
]

const horizontalSteps = [
  { step: '1', label: 'Initialization'},
  { step: '2', label: 'Engineering Analytics'},
  { step: '3', label: 'Log/Zone Analytics'},
  { step: '4', label: 'Attribute Mapping'},
  { step: '5', label: 'Search Domain Definition'},
  { step: '6', label: 'Drainage Analysis'},
  { step: '7', label: 'Sweet Spot Ranking'},
  { step: '8', label: 'Target Search'},
  { step: '9', label: 'Interference Analysis'},
  { step: '10', label: 'Production Forecast'},
  { step: '11', label: 'Filtering & Finalization'},
]

const unconventionalSteps = [
  { step: '1', label: 'Initialization'},
  { step: '2', label: 'Engineering Analytics'},
  { step: '3', label: 'Attribute Mapping'},
  { step: '4', label: 'Model Derivation'},
  { step: '5', label: 'Attribute Aggregation' },
  { step: '6', label: 'Search Domain Definition'},
  { step: '7', label: 'Sweet Spot Ranking'},
  { step: '8', label: 'Target Search'},
  { step: '9', label: 'Interference Analysis'},
  { step: '10', label: 'Production Forecast'},
  { step: '11', label: 'Filtering & Finalization'},
]

const modelBuilderSteps = [
  { step: '1', label: 'Flow Unit Allocation'},
  { step: '2', label: 'Build Grid'},
  { step: '3', label: 'Build Model'},
  { step: '4', label: 'Calibrate Model'},
]

const swmOptimizationSteps = [
  { step: '1', label: 'Optimization'},
]

const stepMapOptions = {
  'generateWorkovers': generateWorkoversSteps,
  'verticals': verticalSteps,
  'horizontals': horizontalSteps,
  'unconventionals': unconventionalSteps,
  'modelBuilder': modelBuilderSteps,
  'swmOptimization': swmOptimizationSteps,
}

@autobind class StepStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      license: null,
      project: null,
    }
  }


  componentDidMount() {
    this.fetchLicense()
  }

  fetchLicense() {
    const { match } = this.props
    const { projectId } = match.params

    API.getProject(projectId)
      .then(project => {
        API.getLicense(project.license)
          .then(license => {
            console.log('tsetestest', license)
            this.setState({
              license,
              project
            })
          })
      })
  }

  makeSteps() {
    const  { caseObj, handleRunModule, killExecution, setSelectedStep } = this.props
    let { stepNo, stepStatus, module } = caseObj
    let caseStep = caseObj.step
    stepNo = parseInt(stepNo)
    
    const stepMap = stepMapOptions[module]

  return stepMap.map(i => {
    //TODO - completely change this to something that acutally makes sense... this just grew out of control
    const step = parseInt(i.step)
    const successfullyRan = (stepNo > step) || (stepStatus === 'successful' && stepNo === step)
    const failed = stepStatus === 'failed' && stepNo === step
    const currentlyRunning = stepStatus === 'running' && stepNo === step
    const disabled = (stepStatus=== 'running') || (stepStatus === 'queued') || (stepStatus === 'successful' && step > stepNo + 1) || (stepStatus !== 'successful' && step > stepNo) 
    const settingsDisabled = (stepStatus=== 'running') || (stepStatus === 'queued')
    const queued = (stepStatus === 'queued' && stepNo === step)

    return (
      <Step
        label={i.label}
        step={step}
        handleRunModule={handleRunModule}
        successfullyRan={successfullyRan}
        failed={failed}
        queued={queued}
        currentlyRunning={currentlyRunning}
        disabled={disabled}
        settingsDisabled={settingsDisabled}
        setSelectedStep={setSelectedStep}
      />
    )
  }) 
}
    

  render() {
    let { handleRunModule, handleRunModuleFake, killExecution, caseObj } = this.props
    let { license, project } = this.state
    const { stepStatus, stepNo, step } = caseObj

    let projectStorage = project ? (project.caseS3StorageSize || 0) + (project.processedFileS3StorageSize || 0) + (project.rawFileS3StorageSize || 0) : 0
    let executeDisabled = !license || !project || stepStatus === 'running' || stepStatus === 'queued' || step === 'visualization' || license.jobHoursUsed >= license.jobHourLimit || license.jobsUsed >= license.jobLimit || projectStorage > license.dataStorageLimit || project.jobHoursUsed >= project.jobHourLimit || project.jobsUsed >= project.jobLimit
    let killDisabled = !executeDisabled

    const executeStyle = {}
    const killStyle = {}

    if (executeDisabled) {
      executeStyle.cursor = 'default'
    }


    let stepNoClean = stepNo || 0
    let runRemainingStepNo = stepStatus === 'failed' ? parseInt(stepNoClean) : parseInt(stepNoClean) + 1

    return (
      <div className="step-inner-container">
        <div className="execution-buttons">
          <button
            type="button"
            style={executeStyle}
            className={`execute-button${executeDisabled ? '-disabled' : ''}`}
            disabled={executeDisabled}
            onClick={() => handleRunModule(runRemainingStepNo, 0)}
          >
            { stepNo ? 'Continue Execution' : 'Run Execution'}
          </button>
          <button
            type="button"
            style={executeStyle}
            className={`red execute-button${killDisabled ? '-disabled' : ''}`}
            disabled={killDisabled}
            onClick={() => killExecution()}
          >
            {'Stop Execution'}
          </button>
        </div>
      <div className="step-status">
          { Object.keys(caseObj).length > 0 && (this.makeSteps()) }
        </div>
      </div>
    )
  }
}



export default withRouter(StepStatus)
