import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { stringFormatDate } from '../services/dateFormatterService'

export const getOpportunityLink = (opportunity) => {
  const { case_id, name, zone } = opportunity
  return `/cases/${case_id}/visualization/opportunities/${name}/${zone}`
}

export const numWithCommas = (x) => {
  if (x === 0) {
    return 0
  }
  if (!x) {
    return null
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// This logic is checking if the value is a return of "dealWithNan"
const isValueValid = (value) => {
  if(typeof value === 'number') return true
  if(value === null) return false
  if(!!value.toFixed) return true
}

// CELL TYPES
export const Currency = ({ value }) => <span>{isValueValid(value) ? `$${numWithCommas(value)}` : '-'}</span>

export const Percent = ({ value }) => <span>{isValueValid(value) ? `${value.toFixed(1) }%` : '-'}</span>

export const Integer = ({ value }) => <span>{isValueValid(value) ? numWithCommas(value.toFixed(0)) : 'null'}</span>

export const Numeric = ({ value }) => <span>{isValueValid(value) ? numWithCommas(value.toFixed(1)) : 'null'}</span>

export const Numeric2 = ({ value }) => <span>{isValueValid(value) ? numWithCommas(value.toFixed(2)) : 'null'}</span>

export const Check = ({ value }) => value ? <CheckIcon style={{ margin: 'auto', color: 'green', display: 'block' }} /> : <CloseIcon style={{ margin: 'auto', color: 'red', display: 'block' }} />

export const Checkbox = ({ value }) => <input type="checkbox" className="checkbox" checked={value} />
//Date is an important constructor.
export const DateCell = ({ value }) => <span>{ (value && typeof value.getMonth === 'function') ? stringFormatDate(value) : 'N/A'}</span>

export const formatSeconds = (sec) => {
  const hours = sec / 3600
  const minutes = Math.floor((sec % 3600) / 60)
  const seconds = Math.floor(sec % 60)

  return `${minutes}m ${seconds}s`
}

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}
