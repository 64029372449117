import React, { Component } from 'react'
import autobind from 'autobind-decorator'

import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { StatusConfigurations } from '../../../lib/datasetMaps/groupConfigurations'

import './GroupSelector.scss'

const containerClass = 'group-selector'
const containerSubgroupClass = `${containerClass}__subgroup`
const containerSubgroupItemClass = `${containerSubgroupClass}--item`


const individualGroupClass = `${containerClass}__group-option`
const selectedGroupClass = `${individualGroupClass}--selected`

const groupIndicatorClass = `${individualGroupClass}__indicator`
const groupSelectedIndicatorClass = `${groupIndicatorClass}--selected`
  //The blue bars on left used to indicate selected group
  //They now indicate a required group

const groupExpandedClass = `${individualGroupClass}--expanded`
const groupContentClass = `${individualGroupClass}__content`
const groupContentTextClass = `${groupContentClass}__text`
const groupContentEmptyClass = `${groupContentClass}__empty-space`
const groupContentStatusClass = `${groupContentClass}__status`

const requiredClass = 'required'

const isGroupRequired = (group) => {

  const { subgroups, categories } = group

  if(categories){
    for (let i = 0; i < categories.length; i += 1) {
      const required = categories && categories.find(cat => cat.required) !== undefined
      if (required) {
        return true
      }
    }
  } else if(subgroups){
    let vals = Object.values(subgroups)
    for(let i = 0; i < vals.length; i ++){
      if(isGroupRequired(vals[i])){
        return true
      }
    }
  }

  return false
}

@autobind class GroupSelector extends Component {
  constructor(props){
    super(props)
    this.state = {
      triggeredWarning: false
    }
  }

  onClickGroup(groups, selectedGroupId, parentGroup) {
    const { 
      onClickGroup,
      toggleGeoModels,
      geoOpen,
      handleGeologicalModelsGroupClick,
      filesUploading,
      handleUploadWarning,
    } = this.props

    const triggeredWarning = this.state.triggeredWarning

    const selectedGroup = groups[selectedGroupId]

    if(filesUploading && !triggeredWarning){
      const onContinue = () => this.setState({ triggeredWarning: true }, () => this.onClickGroup(...arguments))
      handleUploadWarning(onContinue, [groups, selectedGroupId, parentGroup])
      return
    }

    if(selectedGroupId === 'GEOLOGICAL_MODELS'){
      if(geoOpen){
        return
      }
      handleGeologicalModelsGroupClick()
    } else if (selectedGroup.subgroups) {
      selectedGroup.isOpen = !selectedGroup.isOpen
      this.forceUpdate()
    } else {
      if(geoOpen){
        toggleGeoModels(false)
      }
      onClickGroup(selectedGroupId, selectedGroup, parentGroup)
    }
  }

  render() {
    const {
      groups,
      selectedGroupId,
    } = this.props


    return (
      <MenuList className={containerClass}>
        {Object.keys(groups).map((groupId) => {
          return (
            createGroupObject(groupId, selectedGroupId, this.onClickGroup, groups)
          )
        })}
      </MenuList>
    )
  }
}

function createGroupObject(groupId, selectedGroupId, onClickGroup, groups, parentGroup) {
  const expandedClass = groups[groupId].isOpen ? groupExpandedClass : ''
  const selectedClass = groupId === selectedGroupId ? selectedGroupClass : ''
  const subgroupClass = parentGroup ? containerSubgroupItemClass : ''

  console.log('Parent Group: ', parentGroup)

  const required = isGroupRequired(groups[groupId])
  const reqClass = required ? requiredClass : ''
  const hasFiles = groups[groupId].hasFiles

  if(!parentGroup && !required){
    groups[groupId].state = groups[groupId].state.text === 'INCOMPLETE' ? StatusConfigurations.IN_PROGRESS : groups[groupId].state
  }
  return (
    <>
      <MenuItem
        tabIndex="0"
        className={`${individualGroupClass} ${selectedClass} ${subgroupClass} ${expandedClass} ${reqClass}`}
        onClick={() => onClickGroup(groups, groupId, parentGroup)}
        onKeyPress={null}
        role="button"
      >
        <div className={`${groupIndicatorClass}  ${required ? groupSelectedIndicatorClass : ''}`} />
        <div className={groupContentClass}>
          <div className={groupContentTextClass}>
            {groups[groupId].text}
            {required && (<span className="required">*</span>)}
            {groups[groupId].subgroups && groupId !== 'GEOLOGICAL_MODELS' ? (
              <span>
                {groups[groupId].isOpen ? <ExpandLess style={{ marginBottom: '-3px' }} /> : <ExpandMore style={{ marginBottom: '-4px' }} />}
              </span>
            ) : null}
          </div>
          <div className={groupContentEmptyClass} />
          {required && (
            <div className={`${groupContentStatusClass} ${groups[groupId].state.className}`}>
              {groups[groupId].state.text}
            </div>
          )}
          {!required && 
           hasFiles && 
           groups[groupId].state.text !== 'COMPLETE' && (<>
            <div className={`${groupContentStatusClass} ${groups[groupId].state.className}`}>
              {groups[groupId].state.text}
            </div>
          </>)}
        </div>
      </MenuItem>
      {groups[groupId].isOpen && groups[groupId].subgroups && (
        <div className={containerSubgroupClass}>
          {Object.keys(groups[groupId].subgroups).map((subgroupId) => {
            return createGroupObject(subgroupId, selectedGroupId, onClickGroup, groups[groupId].subgroups, groups[groupId].category)
          })}
        </div>
      )}
    </>
  )
}

export default GroupSelector
