import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import logSettingsA from '../../../../../../../../../../static/images/userManual/settings/logSettingsA.png'

@autobind class LogSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Log Settings
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Includes settings used for cleaning logs (trimming bounds) for all possible logs.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={logSettingsA} alt="" />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(LogSettings)
