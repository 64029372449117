import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Collapsible from 'react-collapsible'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './DatasetSelection.scss'

@autobind class DatasetSelection extends Component {
  makeDatasets() {
    const { selectedDatasetAndVersion, loadingDatasets } = this.props
    let { datasetOptions } = this.props

    if (!loadingDatasets && (datasetOptions.length === 0 || !datasetOptions)) {
      return null
    }

    let sortedDatasetOptions = []

    datasetOptions.forEach(i => {
      if (i.isUserFavorite) {
        sortedDatasetOptions.push(i)
      }
    })

    datasetOptions.forEach(i => {
      if (!i.isUserFavorite) {
        sortedDatasetOptions.push(i)
      }
    })

    return sortedDatasetOptions.map(i => {
      if (i.isUserFavorite) {
        console.log('YESYEYSYESYEYSYEYSE')
      }

      return (
        <Collapsible
          className="dataset-option-box"
          openedClassName="dataset-option-box"
          trigger={<div>{i.name} <span className='dataset-option-box-span'>{i.versions.length} Versions</span> {i.isUserFavorite ? <span className='fav-heart'> <FontAwesomeIcon icon="heart" className="tab-option-icon" /> </span>: null}</div>}
        >
          {this.makeVersionOptions(i.versions)}
        </Collapsible>
      )
    })
  }

  makeVersionOptions(versions) {
    const { attemptDatasetChange, selectedDatasetAndVersion } = this.props
    return versions.map(i => {
      return (
        <div
          className={`dataset-option ${ selectedDatasetAndVersion && selectedDatasetAndVersion.versionId === i.versionId ? 'selected' : ''}`}
          onClick={() => attemptDatasetChange({ 
            versionId: i.versionId,
            datasetId: i.datasetId,
          })}
        >
          <div className='dataset-option-name'>
            {i.name}
          </div>
          <div className='dataset-option-file-count'>
            {i.fileCount} files
          </div>
        </div>
      )
    })
  }
  render() {

    const { selectedDatasetAndVersion, loadingDatasets } = this.props
    let { datasetOptions } = this.props

    if (!loadingDatasets && (datasetOptions.length === 0 || !datasetOptions)) {
      return (
        <>
          <div className="initialize-header">
            No Valid Datasets Available
          </div>
          <div className="initialize-sub-header">
            This module does not have any compatible valid dataset versions.
            <br/>
            To run this module, create a dataset version that satisfies the module's file requirements.
          </div>
        </>
      )
    } else {
      return (
        <React.Fragment>
          <div className="initialize-header">
            Select a Dataset & Version
          </div>
          <div className="initialize-sub-header"> The following datasets are compatible with the selected modules. </div>
          <div className="dataset-selection">
            {this.makeDatasets()}
          </div>
        </React.Fragment>
      )
    }

    
  }
}

export default DatasetSelection
