import React from 'react'
import styled from '@material-ui/core/styles/styled'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { MODULE_OPTIONS } from '../../../../lib/datasetMaps/moduleRequirements'
import autobind from 'autobind-decorator'


@autobind class CompatibleModules extends React.Component{
  constructor(props){
    super(props)
  
    this.defaultState = {
      invalidOpen: false,
      invalidModulesOpen: null,
      validModules: [],
      invalidModules: [],
    }

    this.state = this.defaultState

  }

  componentDidMount(){
    this.mapEnabledModules()
  }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props) {
      this.mapEnabledModules()
    }
  }

  mapEnabledModules(){
    //Sorting and storing relevent information from selectedVersion.enabledModules
    const { enabledModules } = this.props

    const newValid = []
    const newInvalid = []

    Object.keys(enabledModules).map(mod => {
      let currentModule = enabledModules[mod]
      let info = MODULE_OPTIONS.find(x => x.value === mod)
      currentModule.value = info.value
      currentModule.label = info.label
      currentModule.error = []
      if(currentModule.valid){
        newValid.push(currentModule)
      }
      else if(!currentModule.valid){
        if(!currentModule.files.valid){
          currentModule.error.push(`Missing files:  ${currentModule.files.missing.join(' ')}`)
        }
        if(!currentModule.integrations.valid){
          currentModule.error.push(`Missing or failed integrations:  ${currentModule.integrations.failed.join(' ')}`)
        }
        newInvalid.push(currentModule)
      }
    })
    
    let newInvalidModulesOpen = null

    if(newInvalid.length > 0){
      newInvalidModulesOpen = newInvalid.reduce((acc, mod) => {
        acc[mod.value] = false
        return acc
      }, {})
    }
    console.log('setting state... UwU')
    this.setState({
      validModules: newValid,
      invalidModules: newInvalid,
      invalidModulesOpen: newInvalidModulesOpen,
    })
  }

  //Hook for opening/closing incompatible module dropdown

  toggleOpen() {
    this.setState({ invalidOpen: !this.state.invalidOpen })
  }

  toggleInvlaidModuleErrors(key) {
    const newState = !this.state.invalidModulesOpen[key]
    this.setState({
      invalidModulesOpen: {
        ...this.state.invalidModulesOpen,
        [key]: newState
      }
    })
  }

  resetState() {
    this.setState(this.defaultState)
  }
  
  render(){
    const { invalidOpen, invalidModulesOpen, validModules, invalidModules } = this.state

    //Styling icons and Material-Ui components. 
    const ModuleItem = styled(ListItem)({
      paddingLeft: "15px"
    })
    const ModuleItemText = styled(ListItemText)({
      color: "#42526e"
    })
    const ModuleErrorItem = styled(ListItem)({
      padding: "4px 16px 4px 25px"
    })
    const Error = styled(CancelIcon)({
      marginTop: "7px",
    })
    const Good = styled(CheckCircleIcon)({
      marginTop: "7px"
    })

    return (
      <>
        <List>
          { validModules.length > 0 && (<>
            <ListItem>
              <Typography variant="h6">
                <b>Valid Modules</b>
              </Typography>
            </ListItem>
            <ListItem>
              <List style={{width: '100%'}}>
                {
                  validModules.map(mod => (
                    <>
                      <ModuleItem>
                        <ModuleItemText primary={<b style={{fontSize: "1.2em"}}>{mod.label}</b>} />
                        <ListItemSecondaryAction>
                          <Good color="primary" />
                        </ListItemSecondaryAction>
                      </ModuleItem>
                      <Divider variant="middle" />
                    </>
                  ))
                }
              </List>
            </ListItem>
            </>)}
          { invalidModules.length > 0 && invalidModulesOpen !== null && (<>
              <ListItem button onClick={this.toggleOpen}>
                <Typography variant="h6">
                  <b>See Invalid Modules </b>
                </Typography>
                {invalidOpen ? <ExpandLess/> : <ExpandMore/>}
              </ListItem>
              <ListItem>
              <Collapse in={invalidOpen} timeout="auto" style={{width: '100%'}}>
                <List style={{width: '100%'}}>
                  {
                    invalidModules.map(mod => (
                      <>
                        <ModuleItem button onClick={() => this.toggleInvlaidModuleErrors(mod.value)}>
                          <ModuleItemText primary={<b style={{fontSize: "1.2em"}}>{mod.label}</b>}/>
                          {invalidModulesOpen[mod.value] ? <ExpandLess /> : <ExpandMore/>}
                          <ListItemSecondaryAction>
                            <Error color="error"/>
                          </ListItemSecondaryAction>
                        </ModuleItem>
                        <Divider variant="middle" />
                        <Collapse in={invalidModulesOpen[mod.value]} timeout="auto">
                          {mod.error.map(err => (
                          <>
                            <ListItem>
                              <ListItemText primary={err}/>
                            </ListItem>
                            <Divider variant="middle" />
                          </>))}
                        </Collapse>
                      </>
                    ))
                  }
                </List>
              </Collapse>
              </ListItem>
            </>)}
        </List>
      </>
    )
  }
}

export default CompatibleModules 