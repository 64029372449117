import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PrivateRoute from './CustomRoute/PrivateRoute'

import Assessment from '../components/layout/Waterflood/Assessment/Assessment'
import Surveillance from '../components/layout/Waterflood/Surveillance/Surveillance'

const WaterfloodRoutes = ({ user }) => (
  <React.Fragment>
    <PrivateRoute exact path="/:application/projects/:projectId/cases/:caseId/visualization" user={user} component={Assessment} />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/case" user={user} component={Assessment} />
    <PrivateRoute path="/:application/projects/:projectId/cases/:caseId/visualization/surveillance" user={user} component={Surveillance} />
  </React.Fragment>
)

const mapStateToProps = state => ({
  user: state.get('user'),
})

export default withRouter(connect(mapStateToProps)(WaterfloodRoutes))
