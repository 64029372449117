import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Select from 'react-select'
import { defaultTheme } from 'react-select'

import { setSelectedWells } from '../../../../redux/actions/waterfloodCase'
import { getCaseWells } from '../../../../services/waterfloodService'

const { colors } = defaultTheme;

const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

@autobind class SelectWellsDropdown extends Component {
  constructor(props) {
    super(props)
    const { selectedWells } = this.props
    this.state = { isOpen: false, value: selectedWells, wells: [] }
  }

  componentDidMount() {
    const { caseId } = this.props
    
    getCaseWells(caseId).then((wells) => {
        this.setState({
            wells: wells,
        })
    })
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onSelectChange(e) {
    const { rxSetSelectedWells, selectedWells } = this.props
  
    const values = !!e ? e.map(mod => mod.value) : selectedWells
    rxSetSelectedWells(values)
  }


  render() {
    const { selectedWells } = this.props
    const { isOpen, wells } = this.state
    let options = wells.map(w => ({ 'value': w._id, 'label': w.name }) )

    const stateOptions = options.filter(
      option => !selectedWells.find(op => op === option.value  )
    )

    const newOptions = options.filter(
      option => selectedWells.find(op => op === option.value  )
    )

    options = [...newOptions, ...stateOptions]


    return (
      <Dropdown
        isOpen={isOpen}
        onClose={this.toggleOpen}
        target={
          <Button
            style={{
                borderColor: 'hsl(0,0%,80%)',
                borderRadius: 4,
                borderStyle: 'solid',
                borderWidth: 1,
                width: '100%',
                justifyContent: 'space-between',
            }}
            endIcon={<ChevronDown />}
            onClick={this.toggleOpen}
          >
            {selectedWells.size > 0 ? `Select Wells...` : 'Whole Field'}
          </Button>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={true}
          menuIsOpen
          isMulti={true}
          onChange={this.onSelectChange}
          options={options}
          placeholder="Search..."
          styles={selectStyles}
          tabSelectsValue={false}
          value={selectedWells.map(mod => options.find(i => i.value === mod))}
        />
      </Dropdown>
    );
  }
}

// styled components

const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = p => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
    <Svg height="20" width="20">
        <path
        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
        fill="currentColor"
        fillRule="evenodd"
        />
    </Svg>
);

const mapDispatchToProps = dispatch => ({
  rxSetSelectedWells: value => dispatch(setSelectedWells(value)),
})

const mapStateToProps = state => ({
  selectedWells : state.getIn(['waterfloodCase', 'selectedWells']).toJS(),
})


export default connect(mapStateToProps, mapDispatchToProps)(SelectWellsDropdown)