import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import PopupState, { bindTrigger, bindPopover, bindToggle } from 'material-ui-popup-state';
import { bindPopper } from 'material-ui-popup-state/hooks'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ScheduleIcon from '@material-ui/icons/Schedule'
import MoreIcon from '@material-ui/icons/MoreVert'
import ErrorIcon from '@material-ui/icons/Error';
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import styled from '@material-ui/core/styles/styled'

import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { stringFormatDate, formatTime } from '../../../services/dateFormatterService'

import ContinueModal from '../ContinueModal/ContinueModal'
import Dialog from '../Dialog/Dialog'
import UploadParameters from './uploadParameterConfigurations'

import './FileViewer.scss'
import { Link, Typography, Tooltip } from '@material-ui/core'
import { revalidateFile, generateDownloadUrl, deleteFile } from '../../../services/fileService'
import { bindHover } from 'material-ui-popup-state/core';
import { CategoryTypes } from '../../../lib/datasetMaps/groupConfigurations';

const baseClass = 'file-viewer'
const fileviewerContainerClass = `${baseClass}__container`

const successClass = `${baseClass}__status--success`
const errorClass = `${baseClass}__status--success`
const statusIconClass = `${baseClass}__statusIcon`
const warningsClass = `${baseClass}__warnings`
const successIcon = `${baseClass}__successIcon`

const categoryTitleClass = `${baseClass}__category-title`
const requiredTextClass = `${categoryTitleClass}__required`
const extensionClass = `${baseClass}__extension`
const fileExtensionClass = `${baseClass}__file--extension`
const messageLinkClass = `${baseClass}__message-link`
const dateClass = `${baseClass}__date`
const popperClass = `${baseClass}__popper`
const optionsButtonClass = `${baseClass}__options-btn`

const parameterLabels = Object.keys(UploadParameters).reduce((acc, funcKey) => {
  UploadParameters[funcKey]().forEach(param => {
    acc[param.name] = param.fileLabel
  })
  return acc
}, {})

const Warning = styled(ReportProblemOutlinedIcon)({
  color: 'orange',
  position: 'relative',
  top: '4px',
  marginRight: '7px',
})

const Error = styled(ReportProblemOutlinedIcon)({
  color: 'red',
  position: 'relative',
  top: '4px',
  marginRight: '7px',
})

@autobind class FileViewer extends Component {
  constructor(props) {
    super(props)
    if (props.uploadPromise) {
      props.uploadPromise.progress((percentage) => {
        this.setState({ percentage })
      })
      props.uploadPromise.then((event) => {
        this.setState({ percentage: 100 })
      })
      props.uploadPromise.catch(error => {
        console.log('Error on file upload: ', error)
      })
    }

    this.state = {
      anchorEl: null,
      showPopper: false,
      percentage: 1
    }
  }

  handleTemplateDownloadClick(name) {
    const link = document.createElement('a')
    link.href = `/templates/${name}`
    link.setAttribute('download', name)
    link.target = '_blank'
    link.click()
    this.togglePopper()
  }

  handleDownloadClick(id, name, datasetId, versionId) {
    const link = document.createElement('a')
    link.href = generateDownloadUrl(id, datasetId, versionId)
    link.setAttribute('download', name)
    link.target = '_blank'
    link.click()
    this.togglePopper()
  }

  togglePopper(event) {
    const { showPopper } = this.state
    const anchorEl = !showPopper && !!event ? event.currentTarget : ''
    
    this.setState({
      showPopper: !showPopper,
      anchorEl,
    })
  }

  onDeleteModalCancel() {
    this.setState({
      showContinueModal: false,
    })
  }

  onDeleteModalContinue() {
    this.setState({
      showContinueModal: false,
    })
    this.handleDeleteClick(true)
  }

  handleDeleteClick(bypass) {
    const {
      isDatasetValid,
      datasetId,
      id,
      refreshData,
      showPopper,
    } = this.props

    if (isDatasetValid && !bypass) {
      this.setState({
        showPopper: false,
        showContinueModal: true,
      })
      return;
    }

    if (showPopper) {
      this.togglePopper()
    }

    deleteFile(datasetId, id).then(refreshData)
  }

  generateErrorList(errorMsg) {
    // const messages = errorMsg.split('\n')
    // const unsorted = messages.reduce((acc, val) => {
    //   const vals = val.split('|')
    //   return val ? [...acc, { priority: vals[0], message: vals[1] }] : acc
    // }, [])
    // const sorted = { criticals: [], warnings: [] }
    // unsorted.forEach(error => error.priority === "true" ? sorted.criticals.push(error) : sorted.warnings.push(error))
    
    // const errors = sorted.criticals.length ? <span><u>Errors:</u></span> : null
    // const warnings = sorted.warnings.length ? <span><u>Warnings:</u></span> : null
    // const space = (errors && warnings) ? <br></br> : null
    // return (<ul>
    //   {errors}
    //   {sorted.criticals.map(error => <li>{error.message}</li>)}
    //   {space}
    //   {warnings}
    //   {sorted.warnings.map(error => <li>{error.message}</li>)}
    // </ul>)

    console.log('not happening')
  }

  generateUploadParameterList(uploadParameters){
    console.log('Upload Parameters: ', uploadParameters)
    console.log()
    return (
      <div>
        {Object.keys(uploadParameters).map(param => (<p>{`${parameterLabels[param]}: ${uploadParameters[param]}`}</p>))}
      </div>
    )
  }

  render() {
    const {
      uploadPromise,
      id,
      name,
      createdBy,
      date,
      category,
      categoryType,
      extension,
      validationPassed,
      validationComplete,
      validationError,
      onReplaceDocument,
      uploadParameters,
      datasetId,
      retryUpload,
      templateName,
      refreshData,
      readOnly,
      required,
      versionId,
      failedUpload,
      openFileInformationDrawer,
      openFileDescriptionDrawer,
    } = this.props

    const {
      showContinueModal
    } = this.state


    const { percentage, showPopper, anchorEl } = this.state
    const containerClass = `${baseClass} ${(validationPassed ? successClass : null)}`
    const formattedDate = stringFormatDate(date)
    const formattedTime = formatTime(date)

    let showWarnings = false

    console.log('validationError', validationError)

    if(!validationError){
      showWarnings = false
    } else if(typeof validationError === 'string' && validationError !== ''){
      showWarnings = true
    } else if(Array.isArray(validationError) && validationError.length > 0){
      showWarnings = true
    } else if(typeof validationError === 'object' && Object.keys(validationError).length > 3){
      showWarnings = true
    }

    return (
      <div className={containerClass}>
        <div className={`${fileviewerContainerClass} align-vertical`}>
          <div className={statusIconClass}>
            {uploadPromise && (
              <CircularProgressbar value={percentage} text={`${percentage}%`} />
            )}
            {!uploadPromise && validationPassed ? (
              <Tooltip title="Validation passed">
                <CheckCircleIcon className={successIcon} />
              </Tooltip>
            ) : null}

            {!uploadPromise && validationComplete && !validationPassed ? (
              <Tooltip title="There was an error validating your file">
                <CancelIcon color="error" />
              </Tooltip>
            ) : null}

            {!uploadPromise && !failedUpload && !validationComplete ? (
              <Tooltip title="Validation in progress">
                <ScheduleIcon htmlColor="#ffcc66" />
              </Tooltip>
            ) : null}

            {failedUpload && (
              <Tooltip title="Upload Failed in progress">
                <ErrorIcon color="error" />
              </Tooltip>
            )}

          </div>
          <div>
            <div className={categoryTitleClass}>
              <p>{category.text}
                {category.type === 'SINGLE' && (
                  <FontAwesomeIcon
                    icon="question-circle"
                    className="help-icon"
                    style={{ 
                      'height': '13px',
                      'width': '13px',
                      'position': 'relative',
                      'left': '3px',
                      'top': '-2px',
                      'cursor': 'pointer',
                    }}
                    onClick={e => openFileDescriptionDrawer(e, category)}
                  />
                )}
                {required && (
                  <sup className={requiredTextClass}> *Required</sup>
                )}
              </p>
              <p>{name}</p>
            </div>
            <div className={extensionClass}>
              <span className={fileExtensionClass}>{extension} file</span>
            </div>
          </div>
          <div className={warningsClass}>
            {!uploadPromise && !validationPassed && showWarnings ? (
              <>
                <Typography>
                  <Error color='error' />
                  There was an error validating your file
                  <PopupState variant="popper" popupId={`${id}-error-modal`}>
                    {popupState => (
                      <span>
                        <Link className={messageLinkClass} href="#" onClick={e => openFileInformationDrawer(e, validationError, uploadParameters,name)}>
                          View Error
                        </Link>
                        {/* <Dialog {...bindPopper(popupState)}>
                          {this.generateErrorList(validationError)}
                        </Dialog> */}
                      </span>
                    )}
                  </PopupState>
                </Typography>
              </>
            ) : null}
            {uploadParameters ? (
              <Typography>
                <PopupState variant="popper" popupId={`${id}-upload-parameters`}>
                  {popupState => (
                    <span>
                      <Link className={messageLinkClass} href="#" onClick={e => openFileInformationDrawer(e, validationError, uploadParameters, name)}>
                        View Upload Parameters
                      </Link>
                      {/* <Dialog {...bindPopper(popupState)}>
                        {this.generateUploadParameterList(uploadParameters)}
                      </Dialog> */}
                    </span>
                  )}
                </PopupState>
              </Typography>
            ) : null}
            {!uploadPromise && validationComplete && validationPassed && showWarnings ? (
              <>
                <Typography>
                  <Warning />
                  There was a warning validating your file
                  <PopupState variant="popper" popupId={`${id}-warning-modal`}>
                    {popupState => (
                      <span>
                        <Link className={messageLinkClass} href="#" onClick={e => openFileInformationDrawer(e, validationError, uploadParameters, name)}>
                          View Warning
                        </Link>
                        {/* <Dialog {...bindPopper(popupState)}>
                          {this.generateErrorList(validationError)}
                        </Dialog> */}
                      </span>
                    )}
                  </PopupState>
                </Typography>
              </>
            ) : null}
            {failedUpload && (
              <Typography>
                There was an error uploading your file.  You can 
                <Link href="#" onClick={onReplaceDocument}>
                  <input
                    style={{ display: 'none' }}
                    onChange={onReplaceDocument}
                    id="retry-upload-file"
                    type="file"
                  />
                  <label htmlFor="retry-upload-file"> retry the upload </label>
                </Link>
              </Typography>
            )}
          </div>
          <div className={dateClass}>
            <div>{createdBy}</div>
            <div>{formattedDate} {formattedTime}</div>
          </div>
        </div>
        {!failedUpload && !uploadPromise && (
          <>
            <Button className={optionsButtonClass} onClick={this.togglePopper} >
              <MoreIcon />
            </Button>
            <Menu
              open={showPopper}
              onClose={this.togglePopper}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              {/*  Commented out "Attempt Validation" and "Replace Document" for workflow issues with upload parameters. - Will Yang 1/28/20   */}
              {!readOnly && (
                // [<MenuItem onClick={() => revalidateFile(id).then(refreshData)}>Attempt Validation</MenuItem>,
                  <MenuItem onClick={() => this.handleDeleteClick()}>Delete Document</MenuItem>//]
              )}
              <MenuItem onClick={() => this.handleDownloadClick(id, name, datasetId, versionId)}> Download Document</MenuItem>
              <MenuItem onClick={() => { this.handleTemplateDownloadClick(templateName) }}> Download Template</MenuItem>
              {/* {!readOnly && categoryType !== CategoryTypes.ARRAY && (
                    <MenuItem onClick={this.togglePopper}>
                      <input
                        style={{ display: 'none' }}
                        onChange={onReplaceDocument}
                        id="contained-button-file"
                        type="file"
                      />
                      <label htmlFor="contained-button-file">Replace Document</label></MenuItem>
              )} */}
            </Menu>
          </>
        )}
        <ContinueModal
          showContinueModal={showContinueModal}
          onModalCancel={this.onDeleteModalCancel}
          onModalContinue={this.onDeleteModalContinue}
          header="Alert: New Version"
        >
          <span>
            This version is valid and therefore locked.
          </span>
          <br />
          <span>
            Continuing with this action will create a new version.
          </span>
        </ContinueModal>
      </div >
    )
  }
}
export default FileViewer
