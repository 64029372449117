import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import API from '../../../lib/api-store'
import { logout } from '../../../redux/actions/user'
import speedwiseLogo from '../../../../static/images/speedwise-logo.png';

@autobind class ProductHeader extends Component {
  handleLogout() {
    const { logoutAction } = this.props
    API.logout().then((r) => {
      logoutAction()
    })
  }

  render() {
    return (
      <div className="product-header">
        <div className="header-top">
          <img className="logo" src={speedwiseLogo} alt="" />
          <div className="product-name">
            SpeedWise
            <span className='product-name-registed'>
              <sup>®</sup>
            </span>
            <span className="product-name-span" />
          </div>
          <div style={
            {
            "float": "right",
            "height": "100%",
            "padding": "15px",
            }} 
            onClick={this.handleLogout}>
            <a style={
            {
                "cursor": "pointer",
                "color": "white",
                "fontSize": "15px",
                "fontWeight": "600",
                "lineHeight": "21px",
            }
            }>
            Log Out
            </a>
          </div>
          <div 
          style={
            {
            "float": "right",
            "height": "100%",
            "padding": "15px",
            }} >
            <NavLink to={{pathname: `/licenses`, state: { prevPath: location.pathname }}}><span style={
            {
                "cursor": "pointer",
                "color": "white",
                "fontSize": "15px",
                "fontWeight": "600",
                "lineHeight": "21px",
            }
            }> Licenses</span></NavLink>
          </div>
        </div>
        <div className="header-bot">
          <NavLink exact className='nav-link' to="/"> Applications </NavLink>
          <div className='nav-link disabled'> Integrated Solutions </div>
          <NavLink className='nav-link' to="/training">Training</NavLink>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.get('user'),
})

const mapDispatchToProps = dispatch => ({
  logoutAction: user => dispatch(logout(user)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductHeader))
