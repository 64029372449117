import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Surfaces extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Surfaces
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>1. Structure Grids (required)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Grids representing structure surfaces for each zone. For information regarding what grids are necessary, as well as how to prepare them, please refer to “SpeedWise Reservoir SAAS Data Preparation (Nov2019).docx.”<br/>
          &ensp;• Utility: Structure grids are used in many steps of the evaluation including (but not necessarily limited to) Data Processing, Calculate Zone Averages, Drainage Analysis, etc.<br/>
          &ensp;• File Format: EarthVision grid (ASCII)<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>2. OWC Grids (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Grids representing oil/water contact (OWC) surfaces for each zone.<br/>
          &ensp;• Utility: Oil/water contact (OWC) grids can be used to constrain net pay analysis. Please see Appendix section Log/Zone Analytics (All Modules) for more detail.<br/>
          &ensp;• File Format: EarthVision grid (ASCII)<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>3. GOC Grids (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Grids representing gas/oil contact (GOC) surfaces for each zone.<br/>
          &ensp;• Utility: Gas/oil contact (GOC) grids can be used to constrain net pay analysis. Please see Appendix section Log/Zone Analytics (All Modules) for more detail.<br/>
          &ensp;• File Format: EarthVision grid (ASCII)<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Surfaces)
