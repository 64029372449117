import autobind from 'autobind-decorator'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import SlidingPane from 'react-sliding-pane'
import Modal from 'react-responsive-modal'
import _ from 'underscore'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'

import ProjectNavigation from '../../Project/ProjectNavigation'
import Search from '../../../common/Search/Search'
import TabSelector from '../../../common/TabSelector/TabSelector'
import DatasetCard from './DatasetCard'
import DatasetEditor from '../DatasetEditor/DatasetEditor'
import ViewDataset from './ViewDataset'
import ContinueModal from '../../../common/ContinueModal/ContinueModal'
import DatasetFilter from '../Components/DatasetFilter'

import emptyDatasetIcon from '../../../../../static/images/emptyDataset.svg'
import arrowIcon from '../../../../../static/images/arrow.svg'

import {
  getDatasetsForThisUser,
  getSharedDatasets,
  toggleDatasetShare,
  getFavorites,
  setFavorite,
  deleteFavorite,
  deleteDataset,
  deleteUnvalidatedVersion,
} from '../../../../services/datasetService'

import './DatasetManager.scss'

const baseClass = 'dataset-manager'

const topBarClass = `${baseClass}__top-bar`
const topBarButtonsClass = `${topBarClass}__buttons`
const searchClass = `${topBarClass}__search`
const addNewButtonClass = `${topBarClass}__new-button`

const cardContainerClass = `${baseClass}__card-container`

const modalClass = 'qri-modal'
const modalCloseIconClass = 'qri-modal__close-button'

const TAB_NAMES = {
  MY_DATASETS: 'mydatasets',
  SHARED_DATASETS: 'shareddatasets',
  FAVORITE_DATASETS: 'favoritedatasets',
}

let rawUserDatasets = []
let rawSharedDatasets = []
let rawFavoriteDatasets = []

const icons = {
  emptyDataset: emptyDatasetIcon,
  arrow: arrowIcon,
}

const CategoryIcon = (props) => {
  const { className, category } = props
  const Component = icons[category]
  return <Component className={className} />
}

@autobind class Dataset extends Component {
  constructor(props) {
    super(props)
    const userDatasets = rawUserDatasets
    const sharedDatasets = rawSharedDatasets
    const favoriteDatasets = rawFavoriteDatasets

    const tabs = generateNewTabs(userDatasets, favoriteDatasets, sharedDatasets)
    const selectedTab = tabs[0]

    this.state = {
      tabs,
      selectedTab,

      userDatasets,
      sharedDatasets,
      favoriteDatasets,

      selectedDataset: null,
      showSelectedDataset: false,
      showDatasetEditor: false,
      editedDataset: null,

      showDeleteDatasetContinueModal: false,
      showDeleteVersionContinueModal: false,
    }
  }

  componentDidMount() {
    this.setState({
      userDatasets: [],
      sharedDatasets: [],
      favoriteDatasets: [],
    }, () => this.loadDatasets())
  }

  onClickTab(selectedTab) {
    this.state.selectedTab = selectedTab
    this.setState({ selectedTab })
  }

  onSearchTextChange(evt) {
    const searchText = evt.target.value
    this.setState({ searchText })
  }

  onClickNewDataset() {
    this.setState({
      showDatasetEditor: true,
    })
  }

  onFavoriteToggle(dataset) {
    let { favoriteDatasets } = this.state
    const { sharedDatasets, userDatasets } = this.state
    if (dataset.isFavorite) {
      deleteFavorite(dataset._id).then(() => {
        const favoriteIndex = favoriteDatasets.indexOf(dataset)
        if (favoriteIndex !== -1) {
          favoriteDatasets.splice(favoriteIndex, 1)
        }
        const tabs = generateNewTabs(userDatasets, favoriteDatasets, sharedDatasets)
        dataset.isFavorite = false

        this.setState({
          favoriteDatasets,
          tabs,
        })
      }).catch((error) => {
        console.error(error)
      })
    } else {
      setFavorite(dataset._id).then(() => {
        favoriteDatasets.push(dataset)
        favoriteDatasets = _.uniq(favoriteDatasets, false, d => d._id)
        const tabs = generateNewTabs(userDatasets, favoriteDatasets, sharedDatasets)
        dataset.isFavorite = true

        this.setState({
          favoriteDatasets,
          tabs,
        })
      }).catch((error) => {
        console.error(error)
      })
    }
  }

  onShareToggle(dataset) {
    let { sharedDatasets } = this.state
    const { favoriteDatasets, userDatasets } = this.state
    toggleDatasetShare(dataset).then((result) => {
      dataset.shared = result.shared
      if (result.shared) {
        sharedDatasets.push(dataset)
        sharedDatasets = _.uniq(sharedDatasets)
      } else {
        const sharedIndex = sharedDatasets.indexOf(dataset)
        sharedDatasets.splice(sharedIndex, 1)
      }
      const tabs = generateNewTabs(userDatasets, favoriteDatasets, sharedDatasets)


      this.setState({
        sharedDatasets,
        tabs,
      })
    })
  }

  // Section: Deleteing Datasets/Version and Modal Toggling
  
  onClickDelete(){
    const { editedDataset, selectedDataset } = this.state

    const id = editedDataset._id || selectedDataset._id

    this.toggleDeleteDatasetContinueModal()
    this.closeSidebar()
    this.closeModal()

    deleteDataset(id).then(()=>{
      this.loadDatasets()
    })
    
  }

  toggleDeleteDatasetContinueModal(){
    this.setState({ showDeleteDatasetContinueModal: !this.state.showDeleteDatasetContinueModal })
  }

  onClickDeleteUnvalidatedVersion(){
    const { selectedDataset } = this.state
    
    this.closeSidebar()
    this.toggleDeleteVersionContinueModal()
    deleteUnvalidatedVersion(selectedDataset._id).then(() => {
      this.loadDatasets()
    })
  }

  toggleDeleteVersionContinueModal(){
    this.setState({ showDeleteVersionContinueModal: !this.state.showDeleteVersionContinueModal })
  }

  //end.

  onClickDataset(dataset) {
    console.log(`clicked:`, dataset)
    this.setState({
      selectedDataset: dataset,
      showSelectedDataset: true,
    })
  }

  onClickCopy(dataset, versionId) {
    this.closeSidebar()
    this.setState({
      showDatasetEditor: true,
      copyFromVersion: dataset,
      copyFromVersionId: versionId,
    })
  }

  onClickVersionEdit(datasetId, versionId) {
    const { history, match } = this.props
    const { projectId, application } = match.params

    if (!versionId) {
      history.push(`/${application}/projects/${projectId}/dataset/${datasetId}`)
    } else {
      history.push(`/${application}/projects/${projectId}/dataset/${datasetId}/version/${versionId}`)
    }
  }

  onEditDataset(dataset) {
    console.log(`edited: ${dataset.name}`)
    this.setState({
      showDatasetEditor: true,
      editedDataset: dataset,
    })
  }

  onModalCallback() {
    console.log('do something else')
    this.loadDatasets()
    this.closeModal()
  }

  closeSidebar() {
    this.setState({ showSelectedDataset: false })
  }

  closeModal() {
    this.setState({
      showDatasetEditor: false,
      editedDataset: null,
      copyFromVersion: null,
      copyFromVersionId: null,
    })
  }

  makeEmpty() {
    return (
      <div className='empty-dataset'>
        <CategoryIcon className="empty-dataset-icon" category='emptyDataset'/>
        <div className='empty-dataset-text'> 
          <div className='empty-dataset-text-header'>
            Get started by creating your first dataset
          </div>
        </div>
        <CategoryIcon className="empty-dataset-arrow-icon" category='arrow'/>
      </div>
    )
  }

  loadDatasets() {
    const { match } = this.props
    const { projectId } = match.params
    console.log(projectId)
    const userPromise = getDatasetsForThisUser(projectId).catch((err) => {
      console.log('there was an error getting user datasets')
      console.log(err)
    })

    const sharedPromise = getSharedDatasets(projectId).catch((err) => {
      console.log('there was an error getting public datasets')
      console.log(err)
    })

    const favoritesPromise = getFavorites(projectId).catch((err) => {
      console.log('there was an error getting the favorites')
      console.log(err)
    })

    const allPromises = [
      userPromise,
      sharedPromise,
      favoritesPromise,
    ]

    Promise.all(allPromises).then((results) => {
      const userDatasets = results[0]
      rawUserDatasets = userDatasets
      console.log(userDatasets)
      const sharedDatasets = results[1]
      rawSharedDatasets = sharedDatasets
      const favoriteIds = results[2]

      const allDatasets = [...userDatasets, ...sharedDatasets]

      const favoriteDatasets = _.uniq(allDatasets.filter((dataset) => {
        const favoriteIndex = favoriteIds.indexOf(dataset._id) > -1
        if (favoriteIndex) {
          dataset.isFavorite = true
        } else {
          dataset.isFavorite = false
        }
        return favoriteIndex
      }), false, d => d._id)

      rawFavoriteDatasets = favoriteDatasets

      const tabs = generateNewTabs(userDatasets, favoriteDatasets, sharedDatasets)

      this.setState({
        userDatasets,
        sharedDatasets,
        favoriteDatasets,
        tabs,
        loaded: true,
      })
    })
  }

  setNameFilter(val) {
    this.setState({
      nameFilter: val,
    })
  }
 
  render() {
    const {
      tabs,
      loaded,
      selectedTab,
      userDatasets,
      sharedDatasets,
      favoriteDatasets,
      selectedDataset,
      showSelectedDataset,
      showDatasetEditor,
      editedDataset,
      copyFromVersionId,
      copyFromVersion,
      showDeleteVersionContinueModal,
      showDeleteDatasetContinueModal,
    } = this.state

    let nameFilter = this.state.nameFilter

    const { project, license, match } = this.props
    const { projectId } = match.params

    let selectDatasets

    switch (selectedTab.name) {
      case TAB_NAMES.MY_DATASETS:
        selectDatasets = userDatasets
        break
      case TAB_NAMES.SHARED_DATASETS:
        selectDatasets = sharedDatasets
        break
      case TAB_NAMES.FAVORITE_DATASETS:
        selectDatasets = favoriteDatasets
        break
      default:
        throw new Error('this is impossible')
    }

    let viewedDatasets = selectDatasets.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))

    if(nameFilter) {
      nameFilter = nameFilter.toUpperCase()
      viewedDatasets = viewedDatasets.filter(i => i.name.toUpperCase().includes(nameFilter)
        || i.description && i.description.toUpperCase().includes(nameFilter))
    }


    return (
      <>
        <ProjectNavigation project={project} license={license} />
        <div className={`${baseClass} product-space__content--wrapper`}>
          <TabSelector
            tabs={tabs}
            selectedTab={selectedTab}
            onClickTab={this.onClickTab}
          />

          <div className={topBarClass}>
            {/* <Search
              className={searchClass}
              labelText="Search by Dataset or File Name"
              onChange={this.onSearchTextChange}
            /> */}
            <DatasetFilter
              setNameFilter={this.setNameFilter}
              setStatusFilter={console.log}
              nameFilter={nameFilter}
              statusFilter={null}
            />
            <div className={topBarButtonsClass}>
              <Button
                tabIndex="0"
                onKeyPress={null}
                className={addNewButtonClass}
                onClick={this.onClickNewDataset}
                variant="contained"
                size="large"
                color="primary"
                startIcon={<AddIcon />}>
                New Dataset
              </Button>
            </div>
          </div>

          <div className={cardContainerClass}>
            {
              loaded && selectedTab.name === TAB_NAMES.MY_DATASETS && viewedDatasets.length === 0 && this.makeEmpty()
            }
            {viewedDatasets.map((dataset, index) => {
              return (
                <DatasetCard
                  index={index}
                  dataset={dataset}
                  /*
                  key={dataset._id}
                  id={dataset._id}
                  name={dataset.name}
                  description={dataset.description}
                  dateCreated={dataset.dateCreated}
                  createdBy={`${dataset.createdBy.firstName} ${dataset.createdBy.lastName}`}
                  shared={dataset.shared}
                  finalized="true"
                  step="finalized"
                  // finalized={dataset.finalized}
                  isFavorite={dataset.isFavorite}
                  isShared={dataset.shared}
                  onHistoryClick={() => this.onClickHistory(dataset)}
                  */
                  onItemClick={() => this.onClickDataset(dataset)}
                  onEditClick={() => this.onEditDataset(dataset)}
                  onViewClick={this.onClickVersionEdit}
                  onFavoriteToggle={() => this.onFavoriteToggle(dataset)}
                  onShareToggle={() => this.onShareToggle(dataset)}
                  selectedTab={selectedTab}
                />
              )
            })}
          </div>

          <SlidingPane
            isOpen={showSelectedDataset}
            from="right"
            onRequestClose={this.closeSidebar}
            id="sliding-pane"
          >
            <ViewDataset
              project={project}
              dataset={selectedDataset}
              onClickEdit={this.onClickVersionEdit}
              onClickCopy={this.onClickCopy}
              toggleDeleteDatasetContinueModal={this.toggleDeleteDatasetContinueModal}
              toggleDeleteVersionContinueModal={this.toggleDeleteVersionContinueModal}
            />
          </SlidingPane>

          <Modal
            classNames={{ modal: modalClass, closeIcon: modalCloseIconClass }}
            open={showDatasetEditor}
            center
            onClose={this.closeModal}
          >
            <DatasetEditor
              projectId={projectId}
              copyFromVersion={copyFromVersion}
              copyFromVersionId={copyFromVersionId}
              editDataset={editedDataset}
              toggleDeleteDatasetContinueModal={this.toggleDeleteDatasetContinueModal}
              actionCallback={this.onModalCallback}
              cancelCallback={this.closeModal}
            />
          </Modal>

          {/*Delete Dataset Continue Modal*/}
          <ContinueModal
            showContinueModal={showDeleteDatasetContinueModal}
            onModalCancel={this.toggleDeleteDatasetContinueModal}
            onModalContinue={this.onClickDelete}
            header="Alert: Deleting Dataset"
          >
            <span>
              Continuing with this action will delete the dataset.
            </span>
          </ContinueModal>

          {/*Delete Version Continue Modal*/}
          <ContinueModal
            showContinueModal={showDeleteVersionContinueModal}
            onModalCancel={this.toggleDeleteVersionContinueModal}
            onModalContinue={this.onClickDeleteUnvalidatedVersion}
            header="Alert: Deleting Dataset Version"
          >
            <span>
              Continuing with this action will delete the latest dataset version.
            </span>
          </ContinueModal>
        </div>
      </>
    )
  }
}

function generateNewTabs(newUserDatasets, newFavoriteDatasets, newSharedDatasets) {
  return [
    {
      icon: 'user',
      name: TAB_NAMES.MY_DATASETS,
      text: `My Datasets (${newUserDatasets.length})`,
    },
    {
      icon: 'users',
      name: TAB_NAMES.SHARED_DATASETS,
      text: `Shared Datasets (${newSharedDatasets.length})`,
    },
    {
      icon: 'heart',
      name: TAB_NAMES.FAVORITE_DATASETS,
      text: `Favorites (${newFavoriteDatasets.length})`,
    },
  ]
}

export default withRouter(connect()(Dataset))
