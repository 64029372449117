import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class BasicZoneInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Basic Zone Information
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          Contains zone-specific parameters which may be used in one or more steps in the modules. For settings that apply to all zones, use the entry with zone name ‘all’.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Formation Volume Factor (FVF):</span> This is required for the drainage analysis.
        </div>
        <div className="content-row">
          &ensp;• <span className='blue'>Estimated Ultimate Recovery factor (EURF):</span> This is also required for the drainage analysis.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Oil Water Contact (OWC):</span> If oil/water contact (OWC) grids are not in the dataset, they can be specified here if they are one depth for each zone.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Gas Oil Contact (GOC):</span> If gas/oil contact (GOC) grids are not in the dataset, they can be specified here if they are one depth for each zone.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(BasicZoneInfo)
