import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import casesIntroA from '../../../../../../../static/images/userManual/casesIntroA.png'

@autobind class Intro extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Introduction to Cases
        </div>
        <div className="content-header">
          Cases
        </div>
        <div className="content-row"> 
          A case is an instance of a user executing a certain module (e.g., recompletions, vertical new drills, or horizontal targets) with a specific group of settings and a particular dataset. In that sense, a case can be thought of as one of many experiments that the user can create to explore the impact of different settings and datasets on the generated opportunity inventory.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={casesIntroA} alt="" />
        </div>
        <div className="content-header">
          Why would you have different cases?
        </div>
        <div className="content-row"> 
          For example, in one case you want to look at the mid case model and run it optimistically. Then you’d have a separate case to look at it pessimistically and see which case you prefer. Then you’d have another case to look at the high case model, and then the low case model, etc.
        </div>
        <div className="content-header">
          Basic Information of a Case
        </div>
        <div className="content-row"> 
          For each case you have the person who created the case, the date when it was executed, and a completion indicator that shows whether the case is completed, and therefore you can view the results, or if it is still in progress. You can also choose to mark some cases as your favorite ones, share the results of a case, or go and visualize the resulting opportunity inventory of a case
        </div>
        <div className="content-header">
          Case Details
        </div>
        <div className="content-row"> 
          For more information on each of the cases, you can click on the pen icon and see its “Case Details” pop-up on the right:
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Case Description:</span> Here you can see or edit the goals or background of the specific case. For example, while the overall workspace’s goal may be to review all new oil opportunities in the field, the goal of a specific case may be to find optimistic recompletions within the mid case model.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Case Tags:</span> These are useful key words that you can see or edit that help describe this case. These become useful as you run more cases, so you can more easily search through your cases and find all the cases that have the same tag.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Case ID, Module and Data:</span> These shows the ID of the case, the dataset and version it used.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Settings:</span> The detailed cases settings. When click on each category will be expanded to show the setting values.
        </div>
        <div className="content-row"> 
          &ensp;• <span className='blue'>Case History:</span> This feature will be available in the next update of the platform, and will show the history of the cases, including date changes, whether the case has been duplicated from another case, and when edits were made. This will allow you to understand how the thinking for this case evolved.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Intro)
