import React, { PureComponent } from 'react'

const baseClass = 'alert-bar'

class AlertBar extends PureComponent {
  render() {
    const { children } = this.props
    return <div className={baseClass}>{children}</div>
  }
}

export default AlertBar
