import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'

import Create from './components/Create'
import Remove from './components/Remove'

@autobind class License extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/training/manual/license/create" component={Create} />
          {/* <Route path="/training/manual/license/remove" component={} /> */}
        </Switch>
      </React.Fragment>
    )
  }
}

export default License