import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'

import Settings from '../Recompletion/components/Settings/Settings'
import SettingsOptions from './components/Settings/SettingsOptions'
import Execution from './components/Execution'
import Visualization from './components/Visualization'

@autobind class SweetSpots extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/:application/manual/modules/sweetSpots/settings" component={SettingsOptions} />
          <Route path="/:application/manual/modules/sweetSpots/settings" component={Settings} />
          <Route path="/:application/manual/modules/sweetSpots/execution" component={Execution} />
          <Route path="/:application/manual/modules/sweetSpots/visualization" component={Visualization} />
        </Switch>
      </div>
    )
  }
}




export default SweetSpots
