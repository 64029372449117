import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import Modal from 'react-responsive-modal'
import SlidingPane from 'react-sliding-pane'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'

import ProjectNavigation from '../Project/ProjectNavigation'
import API from '../../../lib/api-store'
import { addCaseToFavorites, removeCaseFromFavorites } from '../../../redux/actions/user'

import CaseFilter from './Components/CaseFilter'
import CaseHeader from './Components/CaseHeader'
import CaseRows from './Components/CaseRows'
import CaseEditor from './Components/CaseEditor'
import CaseEditModal from './Components/CaseEditModal'
import CaseEditModalHeader from './Components/CaseEditModalHeader'

import 'react-sliding-pane/dist/react-sliding-pane.css'

const caseTabName = 'cases'
const sharedTabName = 'shared'
const favoritesTabName = 'favorites'

const modalClass = 'qri-modal'
const modalCloseIconClass = 'qri-modal__close-button'

const filtersClass = 'cases__filters'
const newCaseWrapperClass = 'cases__filters--new'

@autobind class Cases extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sharedCases: [],
      cases: [],

      tab: caseTabName,
      nameFilter: null,
      statusFilter: null,
      isModalOpen: false,
      isEditCaseModalOpen: false,

      editCaseId: null,
      editCaseTab: 'details',
      duplicateCaseId: null,
    }
  }

  componentDidMount() {
    this.setState({
      sharedCases: [],
      cases: [],
    }, () => this.loadCases())
  }

  loadCases() {
    const { projectId } = this.props.match.params
    
    const userPromise = API.getCases(projectId).catch((err) => {
      console.log('there was an error getting user cases')
      console.log(err)
    })

    const sharedPromise = API.getSharedCases(projectId).catch((err) => {
      console.log('there was an error getting public cases')
      console.log(err)
    })

    const allPromises = [
      userPromise,
      sharedPromise,
    ]

    Promise.all(allPromises).then((results) => {
      const userCases = results[0]
      const sharedCases = results[1]

      this.setState({
        cases: userCases,
        sharedCases,
        loaded: true,
      })
    })
  }

  setNameFilter(val) {
    this.setState({
      nameFilter: val,
    })
  }

  setStatusFilter(val) {
    this.setState({
      statusFilter: val,
    })
  }

  closeEditCaseModal() {
    this.setState({
      isEditCaseModalOpen: false,
      editCaseId: null,
    })
  }

  openEditCaseModal(e, caseId) {
    e.stopPropagation()
    this.setState({
      isEditCaseModalOpen: true,
      editCaseId: caseId,
    })
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      duplicateCaseId: null,
    })
  }

  openModal(e, duplicateCaseId) {
    if (e) {
      e.stopPropagation()
    }

    this.setState({
      isModalOpen: true,
      duplicateCaseId,
    })
  }

  handleClickEditModalTab(val) {
    this.setState({
      editCaseTab: val,
    })
  }

  handleClickTab(tab) {
    this.setState({
      tab,
    })
  }

  handleNewCase(newCaseName, newCaseDescription) {
    const { match } = this.props
    const { projectId, application } = match.params

    API.createNewCase(newCaseName, newCaseDescription, projectId, application)
      .then((caseObj) => {
        // TODO - handle success/fail
        this.props.history.push(`/${application}/projects/${projectId}/cases/${caseObj.caseId}/initialize`)
      })
      .catch(err => console.error(err))

    this.closeModal()
  }

  handleDuplicateCase(newCaseName, newCaseDescription) {
    const { duplicateCaseId, cases } = this.state
    const { match } = this.props
    const { projectId, application } = match.params

    API.duplicateCase(duplicateCaseId, newCaseName, newCaseDescription, projectId)
      .then((caseObj) => {
        // TODO - handle success/fail
        this.props.history.push(`/${application}/projects/${projectId}/cases/${caseObj.caseId}/initialize`)
      })
      .catch(err => console.log(err))
  }


  handleShareCase(e, caseId, isShared) {
    e.stopPropagation()
    let { cases, sharedCases } = this.state

    if (cases.map(i => i.caseId).includes(caseId)) {
      if (isShared) {
        const caseIndex = cases.findIndex(i => i.caseId === caseId)
        cases[caseIndex].shared = false
        const newCases = [...sharedCases]
        newCases.splice(caseIndex, 1)

        this.setState({
          sharedCases: newCases,
          cases
        })
        API.unshareCase(caseId)
          .then(() => {
          })
          .catch(err => console.error(err))
      } else {
        const newCases = [...sharedCases]
        const caseIndex = cases.findIndex(i => i.caseId === caseId)
        cases[caseIndex].shared = true
        newCases.push(cases[caseIndex])
        this.setState({
          sharedCases: newCases,
          cases,
        })
        API.shareCase(caseId)
          .then(() => {

          })
          .catch(err => console.error(err))
      }
    } else {
      // TODO - do we even show this on shared cases?
      console.log('YOU CANT DO THAT')
    }
  }

  handleFavoriteCase(e, caseId, isFavorite) {
    const { rxAddFavoriteCase, rxRemoveFavoriteCase } = this.props
    e.stopPropagation()

    if (isFavorite) {
      rxRemoveFavoriteCase(caseId)
      API.removeCaseFromFavorites(caseId)
        .then(() => {
          // TODO - handle success/fail
        })
        .catch(err => console.error(err))
    } else {
      rxAddFavoriteCase(caseId)
      API.addCaseToFavorites(caseId)
        .then(() => {
          // TODO - handle success/fail
        })
        .catch(err => console.error(err))
    }
  }



 render() {
    const {
      tab,
      loaded,
      isModalOpen,
      isEditCaseModalOpen,
      duplicateCaseId,
      editCaseId,
      editCaseTab, 
      nameFilter,
      statusFilter,
    } = this.state
    let { cases, sharedCases } = this.state

    const { 
      userId, 
      favoriteCasesRef,
      project,
      license,
      match,
    } = this.props

    const { application } = match.params
    
    let isEditable = true
    let editCase = cases.find(i => i.caseId === editCaseId)

    if (!editCase) {
      editCase = sharedCases.find(i => i.caseId === editCaseId)
      isEditable = false
    }

    cases = cases.filter(i => i.application === application)
    sharedCases = sharedCases.filter(i => i.application === application)

    const favoriteCases = [
        ...cases.filter(i => favoriteCasesRef ? favoriteCasesRef.includes(i.caseId) : false),
        ...sharedCases.filter(i => userId !== i.userId && (favoriteCasesRef ? favoriteCasesRef.includes(i.caseId) : false)),
      ]

    return (
      <>
        <ProjectNavigation project={project} license={license}/>
        <div className="cases product-space__content--wrapper">
          <div className="overview">
            <CaseHeader
              tab={tab}
              cases={cases}
              sharedCases={sharedCases}
              favoriteCases={favoriteCases}
              handleClickTab={this.handleClickTab}
              caseTabName={caseTabName}
              sharedTabName={sharedTabName}
              favoritesTabName={favoritesTabName}
            />
            <div className={filtersClass}>
              <CaseFilter
                nameFilter={nameFilter}
                statusFilter={statusFilter}
                setNameFilter={this.setNameFilter}
                setStatusFilter={this.setStatusFilter}
              />
              <div className={newCaseWrapperClass}>
                <Button
                  tabIndex="0"
                  onKeyPress={null}
                  className="new-case-button"
                  onClick={(e) => this.openModal(e, null)}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<AddIcon/>}>
                  New Case
                </Button>
              </div>
            </div>
            <CaseRows
              sharedCases={sharedCases}
              favoriteCases={favoriteCases}
              cases={cases}
              tab={tab}
              loaded={loaded}
              caseTabName={caseTabName}
              sharedTabName={sharedTabName}
              favoritesTabName={favoritesTabName}
              handleShareCase={this.handleShareCase}
              handleFavoriteCase={this.handleFavoriteCase}
              handleViewCaseResults={this.handleViewCaseResults}
              openModal={this.openModal}
              openEditCaseModal={this.openEditCaseModal}
              nameFilter={nameFilter}
              statusFilter={statusFilter}
            />
            <Modal
              classNames={{ modal: modalClass, closeIcon: modalCloseIconClass }}
              open={isModalOpen}
              center
              onClose={this.closeModal}
            >
              <CaseEditor
                duplicateCaseId={duplicateCaseId}
                handleDuplicateCase={this.handleDuplicateCase}
                handleNewCase={this.handleNewCase}
              />
            </Modal>
            <SlidingPane
              isOpen={isEditCaseModalOpen}
              from="right"
              width="600px"
              title={(
                <CaseEditModalHeader
                  handleClickEditModalTab={this.handleClickEditModalTab}
                  tab={editCaseTab}
                />
              )}
              onRequestClose={this.closeEditCaseModal}
            >
              <CaseEditModal
                caseObj={editCase}
                favoriteCases={favoriteCases}
                isEditable={isEditable}
                tab={editCaseTab}
                handleShareCase={this.handleShareCase}
                handleFavoriteCase={this.handleFavoriteCase}
                handleViewCaseResults={this.handleViewCaseResults}
                openModal={this.openModal}
                closeEditCaseModal={this.closeEditCaseModal}
                loadCases={this.loadCases}
              />
            </SlidingPane>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  rxAddFavoriteCase: value => dispatch(addCaseToFavorites(value)),
  rxRemoveFavoriteCase: value => dispatch(removeCaseFromFavorites(value))
})

const mapStateToProps = state => ({
  favoriteCasesRef: state.getIn(['user', 'favoriteCases']),
  userId: state.getIn(['user', '_id']),
})



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cases))

