import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'

import io from 'socket.io-client'
import API from '../../../../lib/api-store'
import StepStatus from './components/StepStatus'
import Information from './components/Information'

@autobind class Execute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedStep: null,
      project: null,
      license: null,
    }
  }

  componentDidMount() {
    const { editCaseObj, setCanContinue, setStep, match } = this.props
    const { caseObj } = this.props
    const { caseId, projectId, application } = match.params



    setCanContinue(false)

    setStep(2, `/${application}/projects/${projectId}/cases/${caseId}/modify`, `/${application}/projects/${projectId}/cases/${caseId}/visualization`)

    this.loadCase()
    this.loadProject()

    //initialize listener
    let socket = io({
        query: {
          caseId
        }
    })
    console.log('starting socket io')
    socket.on('job', (obj) => {
      const { caseObj } = this.props
      const { caseId, step, err } = obj
      console.log('message from server', caseId, step, err)

      if (caseObj.caseId === caseId) {
        const newCaseObj = {...caseObj}

        if (step === 'complete') {
          newCaseObj.stepStatus = 'successful'
          newCaseObj.err = null
        } else if (step === 'visualization') {
          setCanContinue(true)
          newCaseObj.stepStatus = 'successful',
          newCaseObj.step = 'visualization'
          newCaseObj.err = null
        } else if (err) {
          newCaseObj.stepStatus = 'failed'
          newCaseObj.step = 'failed'
          newCaseObj.err = err
          this.setSelectedStep('error')
          setCanContinue(false)
        } else {
          newCaseObj.stepNo = step
          newCaseObj.err = null
          newCaseObj.stepStatus = 'running'
          newCaseObj.step = 'running'
          newCaseObj.stepStart = new Date()
          setCanContinue(false)
        }

        editCaseObj(newCaseObj)
      }
    })

    this.setState({
      socket
    })
  }

  componentWillUnmount() {
    this.state.socket.off('job')
  }


  loadCase() {
    const { caseId } = this.props.match.params
    const { setCanContinue } = this.props

    API.getCase(caseId)
      .then(caseObj => {
        if (caseObj.step === 'visualization') {
          setCanContinue(true)
        }

        if (caseObj.stepStatus === 'failed') {
          this.setSelectedStep('error')
        }

        this.setState({
          caseObj
        })
      })
  }


  loadProject() {
    const { match } = this.props
    const { projectId } = match.params


    API.getProject(projectId)
      .then(project => {
        this.setState({
          project
        })

        API.getLicense(project.license)
        .then(license => {
          console.log('herre it is boys', license)
          this.setState({
            license
          })
        })
      })
  }


  handleRunModule(step, isSingularStep) {
    const { caseObj, editCaseObj } = this.props
    const { caseId } = caseObj

    const newCaseObj = {...caseObj}
    newCaseObj.stepStatus = 'queued'
    newCaseObj.err = ''
    newCaseObj.stepNo = step

    editCaseObj(newCaseObj)
    console.log('HANDLE RUN MODULE WTF PLEASE GOD WORK.')
    API.runModule(caseId, step, isSingularStep)
      .then(() => {

      })
  }

  killExecution() {
    const { caseObj, editCaseObj } = this.props
    const { caseId } = caseObj

    API.killExecution(caseId)
  }


  setSelectedStep(val) {
    this.setState({
      selectedStep: val
    })
  }

  render() {
    const { caseObj, editCaseObj, setCanContinue } = this.props
    const { selectedStep, project, license } = this.state

    if (!caseObj) {
      return <div> Loading Case ... </div>
    }

    return (
      <div className="execute">
        <div className="step-container">
          <StepStatus
            caseObj={caseObj}
            handleRunModule={this.handleRunModule}
            killExecution={this.killExecution}
            setSelectedStep={this.setSelectedStep}
          />  
          <Information
            status={caseObj.stepStatus}
            setCanContinue={setCanContinue}
            selectedStep={selectedStep}
            caseObj={caseObj}
            editCaseObj={editCaseObj}
            setSelectedStep={this.setSelectedStep}
            project={project}
            license={license}
          />
        </div>
      </div>
    )
  }
}



export default withRouter(Execute)
