import React from 'react'

import img1 from './static/WellMonthlyProduction1.png'
import img2 from './static/WellMonthlyProduction2.png'

const WellMonthlyProduction = (
  <div className={'description-container'}>
    <p><b>Content:</b> Well-level monthly production rates.</p>
    <p><b>Utility:</b> Production is used in multiple steps of the opportunity generation process. For example, the production is allocated by zone and then used to forecast new opportunities.</p>
    <p><b>Conventions:</b> Date: It must be the last day of the month, and in mm/dd/yyyy format</p>
    <p><b>File Format:</b> CSV template (downloadable) (example shown in Figure 7).</p>
    <p><b>Units: Template</b> indicates what units are needed.</p>
    <p><b>Other Note:</b> Not all columns are required. See Table 7 for specifics.</p>
    <figure>
      <img src={img1} />
      <figcaption>Figure 7: Example of the first few rows of a “Well_Monthly_Production.csv.”</figcaption>
    </figure>
    <figure>
      <figcaption>Table 7. Details for the “Well_Monthly_Production.csv.”</figcaption>
      <img src={img2} />
    </figure>
  </div>
)

export default WellMonthlyProduction