import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Testing extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Testing
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>1. Fluid PVT (required)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: This file provides PVT tables for entities existing in the study.<br/>
          &ensp;• Utility: At least one PVT table for the field is needed to provide phase behavior data for fluid volumes conversion between surface conditions and reservoir conditions.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>2. Static Well Pressure Test (monthly) (required)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Any static pressure measurements at the wells that was taken in the field is to be reported in this file.<br/>
          &ensp;• Utility: To provide well level static pressure measurements for estimating average reservoir pressure over time used for production allocation.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>3. Static Reservoir Pressure (Monthly) (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Static reservoir pressures measured over time.<br/>
          &ensp;• Utility: The average reservoir pressure data will be used for flow unit allocation algorithm to allocation production data to different zones/intervals.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding"> 
          <span className='blue'>4. Well Production Log (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Stored production log test results.<br/>
          &ensp;• Utility: For calibrating production data by zones.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding">  
          <span className='blue'>5. Well Injection Log (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: Stored injection log test results.<br/>
          &ensp;• Utility: For calibrating injection data by zones.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
        <div className="content-row-no-padding">  
          <span className='blue'>6. Well Production Monthly Allocated (optional)</span>
        </div>
        <div className="content-row"> 
          &ensp;• Content: User-allocated production rates.<br/>
          &ensp;• Utility: These user-allocated production rates will be used in the modules, instead of running automated flow unit allocation module.<br/>
          &ensp;• File Format: CSV template provided.<br/>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Testing)
