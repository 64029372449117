import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import styled from '@material-ui/core/styles/styled'

import ExecutionImage from '../../../../../../static/images/execution-image.png';
import { RecompletionsSettingsMap, VerticalsSettingsMap, HorizontalsSettingsMap, modelBuilderSettingsMap, swmOptimizationSettingsMap } from '../../../../../lib/settingsMap'
import SettingsGroup from '../../Customize/SettingsGroup'
import { RecompletionStepInfo, VerticalsStepInfo, HorizontalsStepInfo, ModelBuilderStepInfo, SwmOptimizationStepInfo } from './StepInfo'
import LicenseWarning from './LicenseWarning'
import Error from '../../../../common/Error/Error'

import './Information.scss'

const settingsMapOptions = {
  'generateWorkovers': RecompletionsSettingsMap,
  'verticals': VerticalsSettingsMap,
  'horizontals': HorizontalsSettingsMap,
  'modelBuilder': modelBuilderSettingsMap,
  'swmOptimization': swmOptimizationSettingsMap,
}

const stepInfoOptions = {
  'generateWorkovers': RecompletionStepInfo,
  'verticals': VerticalsStepInfo,
  'horizontals': HorizontalsStepInfo,
  'modelBuilder': ModelBuilderStepInfo,
  'swmOptimization' : SwmOptimizationStepInfo
}

const Less = styled(ExpandLess)({
  marginBottom: '-4px',
  marginLeft: '-2px',
})
const More = styled(ExpandMore)({
  marginBottom: '-4px',
  marginLeft: '-2px',
})

@autobind class Information extends Component {
  constructor(props) {
    super(props)
    this.state = {
      infoTab: 'help',
      stepInfoIndex: 0,
      stackInfoOpen: false,
    }  
  }

  handleCloseInfo() {
    const { caseObj, setSelectedStep } = this.props
    const isError = (caseObj.stepStatus === 'failed')

    // if (isError) {
    //   setSelectedStep('error')
    // } else {
    //   setSelectedStep(null)
    // }
    setSelectedStep(null)
  }

  toggleStackInfoOpen() {
    this.setState({ stackInfoOpen: !this.state.stackInfoOpen })
  }

  makeErrorStack(stack) {
    //Quick fix for some old passed data where stack was stored as obj when only 1 element in array... can be deleted if this bug is confirmed fixed
    if (!Array.isArray(stack)) {
      stack = [stack]
    }
    stack.reverse()

    const { stackInfoOpen } = this.state
    return (
      <div className="stack-info">
        <span className="stack-info__header" onClick={this.toggleStackInfoOpen}>
          Technical Details for Support Personnel {stackInfoOpen ? <Less/> : <More/>}
        </span>
        <Collapse in={stackInfoOpen}>
          {stack.map(i => {
            return (
              <div className="stack-info__item">
                {i.name} (line {i.line})
              </div>   
            )
          })}
        </Collapse>
      </div>
    )
  }

  makeErrors(err) {

    if(typeof err === 'string'){
      return err
    } else if(!Array.isArray(err)){
      err = [err]
    }

    return err.map((err, i) => <Error {...err} i={i} />)
  }

  makeInformationTab() {
    const { infoTab } = this.state
  	const { status, selectedStep, setSelectedStep, caseObj, project, license } = this.props

    console.log('here we go', project, license)
    let curStorageGB = project ? (((project.caseS3StorageSize || 0) + (project.processedFileS3StorageSize || 0) + (project.rawFileS3StorageSize || 0)) / 1024 / 1024 / 1024) : 0
    let storageLimit = license ? license.dataStorageLimit / 1024 / 1024 / 1024 : 5000
    let jobHoursUsed = project ? project.jobHoursUsed : 0
    let jobHourLimit = project ? project.jobHourLimit : 1000
    let jobsUsed = project ? project.jobsUsed : 0
    let jobLimit = project ? project.jobLimit : 5000


 
    let shouldShowWarning = !selectedStep && ((jobsUsed > jobLimit * .8) || (jobHoursUsed > jobHourLimit * .8) || (curStorageGB > storageLimit * .8))



    const text = status === 'running' ? 'Running' : 'Select the execution button to run execution steps'
  	if (shouldShowWarning) {
      return (
        <div className='info'>
          <LicenseWarning
            curStorageGB={curStorageGB}
            storageLimit={storageLimit}
            jobHoursUsed={jobHoursUsed}
            jobHourLimit={jobHourLimit}
            jobsUsed={jobsUsed}
            jobLimit={jobLimit}
            handleCloseInfo={this.handleCloseInfo}
          />
        </div>
      )
    } else if (!selectedStep) {
  		return (
  			<div className='info-static'>
          <span className="info-text">{text}</span>
          <div className="info-image">
            <img src={ExecutionImage} />
          </div>
        </div>
  		)
  	} else if (selectedStep === 'error') {
      return (
        <div className='info'>
          <div className='error-container'>
            <div className='error-header'>
              <div className='error-header-icon'>
                <FontAwesomeIcon icon="exclamation-triangle" />
              </div>
              <div className='error-header-text'>
                Execution Error
              </div>
              <span 
                style={{'float': 'right', paddingRight: '15px', lineHeight: '50px', cursor: 'pointer', fontSize: '25px', color: 'black'}} 
                onClick={() => this.handleCloseInfo()}
              >
                  X
              </span> 
            </div>
            <div className='error-sub-header'>
              This execution step has failed.  We recommend:
            </div>
            <div className='error-sub-text'>
              1. Go back and adjust settings, and then selected "Continue Execution"
            </div> 
            <div className='error-sub-text'>
              2. Check your dataset version used for new validations that may be needed
            </div>
            <div className='error-sub-header'>
              Error Troubleshooting
            </div>
            <div className='error-content'>
              {this.makeErrors(caseObj.err)}
            </div>
            {
              caseObj.errorStack && (
                <div className="error-stack">
                  { this.makeErrorStack(caseObj.errorStack) }
                </div>
              )
            }
          </div>
        </div>
      )
    } else {
      return (
        <div className='info'>
          <div className='button-group'>
            <button
              className={`info-type-button ${infoTab === 'help' ? 'selected' : ''}`}
            >
              Help
            </button>
            <span 
              style={{'float': 'right', paddingRight: '15px', lineHeight: '50px', cursor: 'pointer', fontSize: '25px'}} 
              onClick={() => this.handleCloseInfo()}
            >
                X
            </span> 
          </div>
          { this.makeInside() }
        </div>
      )
    }
  }



  makeInside() {
    const { selectedStep, caseObj, editCaseObj, setCanContinue } = this.props 
    const { infoTab, stepInfoIndex } = this.state
    const { settings , module} = caseObj

    const StepInfo = stepInfoOptions[module]
    const curStepInfo = StepInfo[selectedStep].info


    const stepInfoIndexToUse = curStepInfo[stepInfoIndex] ? stepInfoIndex : 0
    return (

      <React.Fragment>
        <div className='info-categories'>
          { curStepInfo.map((i, index) => (
              <div 
                className={`info-category ${stepInfoIndex === index ? 'selected' : ''}`}
                onClick={() => this.setState({ stepInfoIndex: index })}
              >
                { i.name }
              </div>
            ))}
        </div>
        <div className='info-description'>
          <div className="info-description-title">
            { curStepInfo[stepInfoIndexToUse].name}
          </div>
          <div className="info-description-value">
            { curStepInfo[stepInfoIndexToUse].val}
          </div>
        </div>
      </React.Fragment>
    )

  }

  render() {
    return this.makeInformationTab()
  }
}

export default withRouter(Information)

