import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import HorSweetSpotRankingA from '../../../../../../../../../../static/images/userManual/settings/HorSweetSpotRankingA.png'

@autobind class SweetSpotRanking extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          RPOS Modeling/Ranking Horizontals
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          This step’s objective is to create various 3D properties such as net-to-gross (if user has not provided one), zone index, oil window index, distance-to-fault index, and finally a relative probability of success (RPOS) property that aggregates trends of multiple user-defined attributes (ideally those that are known to impact production). As with the vertical sweet spot grids, this model property can include geologic attributes such as current hydrocarbon pore volume, permeability, fracture intensity, and baffle layers as well as other geoengineering attributes such as spacing, drainage, recent water cut and GOR trends, zone-specific fluid contacts, contact standoffs, and so on. SpeedWise Reservoir automatically includes the domain and spacing attributes (Figure 36). The user can also include any other attribute if it has been imported as a model property.
        </div>
        <div className="content-row"> 
          Note that this step is the 3D equivalent of the RPOS mapping step described in the vertical target identification workflow. Also note that some attributes like WCT and GOR are modeled from production allocation, which limits the data to one value per well per zone. Therefore, the WCT and GOR attributes, are basically upscaled within each zone such as that all layers belonging to the same zone share the same lateral distribution. The RPOS property is calculated as a Hadamard product of the normalized input attribute matrices
        </div>
        <div className='image-container'>
          <img src={HorSweetSpotRankingA} className='tooBig' alt="" />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(SweetSpotRanking)
