import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'



import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import MUICard from '../../../common/MUICard/MUICard'

import ConnectivityGraph from './ConnectivityGraph'
import ConnectivityTable from './ConnectivityTable'

import FlowAllocation from './FlowAllocation'

import GlobalFilters from '../Shared/GlobalFilters'

import { toggleSettings } from '../../../../redux/actions/waterfloodCase'


import '../Assessment/Assessment.scss'

const caseClass = 'waterflood_case'
const headerClass = `${caseClass}-header`
const filtersClass = `${caseClass}--filters-wrapper`


@autobind class Assessment extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {

  }

 render() {
   const { match } = this.props
   const { caseId } = match.params

    return (
      <div className={caseClass}>
        <div className={headerClass}>
          <MUICard className={filtersClass}>
            <GlobalFilters caseId={caseId} />
          </MUICard>
        </div>
        <Grid container spacing={2}>
          <Grid container item xs={6} spacing={0}>
            <ConnectivityGraph caseId={caseId} />
          </Grid>
          <Grid container item xs={3} spacing={0}>
            <ConnectivityTable
              title="Production Allocation"
              caseId={caseId}
              type="producer"
            />
          </Grid>
          <Grid container item xs={3} spacing={0}>
            <ConnectivityTable
              title="Injection Allocation"
              caseId={caseId}
              type="injector"
            />
          </Grid>
          <Grid container item xs={12} spacing={0}>
            <FlowAllocation caseId={caseId} />
          </Grid>
        </Grid>
      </div>
    )
  }
}


const mapDispatchToProps = dispatch => ({
  rxToggleSettings: () => dispatch(toggleSettings()),
})

const mapStateToProps = state => ({
  showSettings: state.getIn(['waterfloodCase', 'viewer', 'showSettings']),
  timeline: state.getIn(['waterfloodCase', 'timeline']),
  selectedWells: state.getIn(['waterfloodCase', 'selectedWells']),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assessment))
