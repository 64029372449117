import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'


@autobind class Importance extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Why is this tool useeful?
        </div>
        <div className="content-row"> 
          As an asset team member, you are responsible for having an evergreen opportunity inventory. This tool allows you to streamline the process of generating that inventory and then allows you to focus your time vetting the opportunities as a team on a user-friendly, multidisciplinary platform. This tool allows you to be the driver, giving you the power and speed necessary to integrate production data with geologic information to do your job effectively.
        </div>
        <div className="content-row"> 
          The process of identifying field development opportunities can be a time-consuming process. This is because it requires a constantly updated static model and a history-matched simulation model, which can carry significant uncertainty. Similarly, analytical workflows/methods to identify opportunities are neither standardized nor automated, are left to the discretion of the engineer/geoscientist, do not guarantee optimal solutions, and are often limited to the confines of one discipline. For example, a geoscientist might approach the problem by analyzing and interpreting the logs and formation tops with no regards to production data. Similarly, an engineer might focus entirely on production data and ignore trends in log or core data. Ultimately, with an analytical approach or using simulation models, the process of identifying opportunities can take months and the cycle time is even slower.
        </div>
        <div className="content-row"> 
          To address this problem, SpeedWise Reservoir (SRO) was developed as a fully automated technology used to identify various field development opportunities by integrating geologic models, petrophysical interpretations, production data, and engineering trends. The identified opportunities include behind-pipe recompletion opportunities, new vertical drill locations, and optimal deviated or horizontal well targets. To identify a list of development opportunities, SRO automates several geologic and engineering workflows that are typically performed manually including remaining pay interpretation, drainage analysis, geoengineering attribute mapping, production forecast, uncertainty quantification, and mechanical feasibility assessment to list a few. For each one of these steps, the user has access to multiple settings that can be used to customize the ways the algorithms operate and to adapt the workflow to unique field-specific challenges.
        </div>
        <div className="content-row"> 
          The main value of SRO resides in three points:
        </div>
        <div className="content-row"> 
          1) Execution time is fast. An initial opportunity inventory can be generated in a matter of minutes.
        </div>
        <div className="content-row"> 
          2) The user can choose from multiple algorithms and methods to fully customize the technology to unique field/reservoir complexities.
        </div>
        <div className="content-row"> 
          3) The core algorithms are data-driven and leave little room to the biases or subjectivity of the user, which allows for a consistent and repeatable analysis.
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Importance)
