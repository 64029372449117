import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import Select from 'react-select'
import TextField from '@material-ui/core/TextField'
import _ from 'underscore'

import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { createDataset, editDataset as editDatasetAction, copyDatasetFromVersion } from '../../../../services/datasetService'
import { numberFormatDate } from '../../../../services/dateFormatterService'

import './DatasetEditor.scss'

const baseClass = 'dataset-editor'

const headerClass = `${baseClass}__header`

const contentClass = `${baseClass}__content`

const topInputBarClass = `${contentClass}__top-bar`
const datasetNameInputClass = `${topInputBarClass}__name-input`

const datasetVersionSelectorClass = `${topInputBarClass}__version-selector`
const datasetVersionLabelClass = `${datasetVersionSelectorClass}__label`

const datasetDescriptionLabelClass = `${contentClass}__description-label`
const datasetDescriptionTextAreaClass = `${datasetDescriptionLabelClass}__text-area`

const bottomBarClass = `${contentClass}__bottom-bar`
const buttonClass = `${bottomBarClass}__buttons`
const bottomLeftBarClass = `${bottomBarClass}__left`
const dateCreatedLabelClass = `${bottomLeftBarClass}__date-created-label`
const dateCreatedClass = `${bottomLeftBarClass}__date-created`

const bottomRightBarClass = `${bottomBarClass}__right`

const multiTextRows = 4

const reqStyle = {
  color: 'red',
  display: 'inline-block'
}

const makeRequired = textInput => <>{textInput}<span style={reqStyle}>*</span></>

@autobind class DatasetEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount(){
    let { editDataset } = this.props

    if (editDataset) {
      this.setState({
        datasetName: editDataset.name,
        datasetDescription: editDataset.description
      })
    }
  }


  onDatasetNameChange(event) {
    this.setState({
      datasetName: event.target.value,
    })
  }

  onVersionSelect(selectedVersion) {
    this.setState({
      selectedVersion,
    })
  }

  onVersionFilter(filterText) {
    console.log(filterText)
  }

  onDatasetDescriptionChange(event) {
    console.log(event.target.value)

    this.setState({
      datasetDescription: event.target.value || '',
    })
  }

  onActionClick() {
    const {
      datasetName,
      datasetDescription,
      shared,
      selectedVersion,
    } = this.state

    const {
      actionCallback,
      copyFromVersion,
      copyFromVersionId,
      editDataset,
      projectId,
    } = this.props

    let displayShared

    if (copyFromVersion) {
      if (!datasetName || datasetName.length === 0) {
        return;
      }
      const versions = [copyFromVersion.latestVersion, ...copyFromVersion.versions].filter(version => version.valid).map((version) => {
        return {
          label: version.publishedName ? version.publishedName : `Version ${version.version}`,
          value: version
        }
      })
      const displaySelectedVersion = selectedVersion || versions.find(version => version.value.versionId === copyFromVersionId) || versions[0]

      copyDatasetFromVersion(copyFromVersion._id, displaySelectedVersion.value.versionId, datasetName, datasetDescription, shared || false).then(actionCallback)
    } else if (editDataset) {
      if ((!datasetName || datasetName.length === 0) && (!editDataset.name || editDataset.name.length === 0)) {
        return;
      }
      displayShared = shared
      if (displayShared === undefined) {
        displayShared = editDataset.shared
      }
      //editDatasetAction(editDataset._id, datasetName || editDataset.name, editDataset.description || datasetDescription, displayShared).then(actionCallback)
      editDatasetAction(editDataset._id, datasetName || editDataset.name , datasetDescription || '', displayShared).then(actionCallback)
    } else {
      if (!datasetName || datasetName.length === 0) {
        return;
      }
      createDataset(datasetName, datasetDescription, projectId, shared || false).then(actionCallback)
    }
  }

  onCancelClick() {
    const { cancelCallback } = this.props
    cancelCallback()
  }

  onShareChange(event) {
    this.setState({
      shared: event.target.checked,
    })
  }

  render() {
    const {
      datasetName,
      datasetDescription,
      shared,
      selectedVersion
    } = this.state

    const {
      copyFromVersion,
      editDataset,
      copyFromVersionId,
      toggleDeleteDatasetContinueModal,
    } = this.props

    let isCopy = false
    let isNew = false
    let isEdit = false
    let versions
    let displaySelectedVersion
    let displayDatasetName
    let displayDatasetDescription
    let datasetDate
    let displayShared

    if (copyFromVersion) {
      versions = [copyFromVersion.latestVersion, ...copyFromVersion.versions].filter(version => version.valid).map((version) => {
        return {
          label: version.publishedName ? version.publishedName : `Version ${version.version}`,
          value: version
        }
      })
      isCopy = true
      displaySelectedVersion = selectedVersion || versions.find(version => version.value.versionId === copyFromVersionId) || versions[0]
      displayDatasetName = datasetName || ''
      displayDatasetDescription = datasetDescription || ''
      datasetDate = numberFormatDate(new Date())
      displayShared = (shared !== undefined && shared) || false
    } else if (editDataset) {
      isEdit = true
      displayDatasetName = datasetName || ''
      displayDatasetDescription = datasetDescription || ''
      datasetDate = numberFormatDate(editDataset.dateCreated)
      displayShared = shared
      if (displayShared === undefined) {
        displayShared = editDataset.shared
      }
    } else {
      isNew = true
      displayDatasetName = datasetName || ''
      displayDatasetDescription = datasetDescription || ''
      datasetDate = numberFormatDate(new Date())
      displayShared = (shared !== undefined && shared) || false
    }

    return (
      <div className={baseClass}>

        <div className={headerClass}>
          {isCopy && (<span>Copy Dataset</span>)}
          {isEdit && (<span>Edit Dataset</span>)}
          {isNew && (<span>New dataset</span>)}
        </div>

        <div className={contentClass}>
          <div className={topInputBarClass}>
            <TextField
              id="dataset-name-input"
              label={makeRequired('Dataset Name')}
              type="text"
              margin="normal"
              variant="outlined"
              className={datasetNameInputClass}
              value={displayDatasetName}
              onChange={this.onDatasetNameChange}
            />

            {isCopy && (
              <div className={datasetVersionSelectorClass}>
                <label className={datasetVersionLabelClass}>
                  <Select
                    className="test"
                    options={versions}
                    onChange={this.onVersionSelect}
                    onInputChange={this.onVersionFilter}
                    isMulti={false}
                    value={displaySelectedVersion}
                  />
                </label>
              </div>
            )}
          </div>
          <TextField
            fullWidth
            id="dataset-description-input"
            label="Dataset Description"
            type="text"
            margin="normal"
            variant="outlined"
            multiline
            rows={multiTextRows}
            value={displayDatasetDescription}
            onChange={this.onDatasetDescriptionChange}
          />

          <div className={bottomBarClass}>

            <div>
              <label className={dateCreatedLabelClass}>
                <span>Date Created</span>
              </label>
              <span className={dateCreatedClass}>{datasetDate}</span>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayShared}
                    onChange={this.onShareChange}
                    value="share"
                    color="primary"
                  />
                }
                label="Share dataset version with team collaborators"
              />
            </div>
            <div className={buttonClass}>
              {isEdit && (<div className="spacer"></div>)}
              {isEdit && (
                <Button
                  tabIndex="1"
                  onKeyPress={null}
                  onClick={() => toggleDeleteDatasetContinueModal()}
                  variant="contained"
                  size="large"
                  color="primary"
                  className="custom-delete"
                  fullWidth
                >
                  Delete Dataset
                </Button>
              )}
              <Button
                tabIndex="0"
                onClick={this.onActionClick}
                onKeyPress={null}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
              >
                {isCopy && (<span>Copy Dataset</span>)}
                {isEdit && (<span>Apply Changes</span>)}
                {isNew && (<span>Create Dataset</span>)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DatasetEditor
