import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

@autobind class HorOtherProductionForecast extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Other Production Forecasts for Horizontal Targets
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          To forecast rates for horizontal wells, a suite of analytical models is available. These include the following models: Joshi, Babu, Furui, and Economides. Their descriptions are provided below:
        </div>
        <div className="content-row"> 
          •<span className='blue'> Joshi Model:</span> The steady-state flow model developed by Joshi (1988) is one of the first and most well-known analytical models for horizontal well inflow. Joshi derived an equation for the flow rate to a horizontal well of length L by adding a solution for the flow resistance in the horizontal plane with the solution for the flow resistance in the vertical plane, and taking into account vertical-to-horizontal anisotropy.
        </div>
        <div className="content-row"> 
          •<span className='blue'> Babu Model:</span> Pseudo steady-state models of inflow performance presumes that the reservoir is bounded by no-flow boundaries and that pressure declines in a uniform fashion in the reservoir. In the Babu and Odeh (1988, 1989) model the physical system is a box-shaped drainage area. They adapt shape factor to account for the drainage area change and also account for partial penetration skin.
        </div>
        <div className="content-row"> 
          •<span className='blue'> Furui Model:</span> Furui et al (2003) is another steady-state analytical flow model for horizontal well inflow. Furui et al. solves the flow problem in the cross-sectional area perpendicular to the wellbore. The model assumes the flow near the well is radial and becomes linear farther from the well. The drainage area for their model is a box-shaped reservoir and the forecast are based on simulation results of a finite element model for incompressible fluid.
        </div>
        <div className="content-row"> 
          •<span className='blue'> Economides Model:</span> Economides et al. (1991) developed a completely general model for one or more horizontal wells or laterals by integrating unit length point sources in no-flow boundary boxes to create an arbitrary well trajectory or trajectories. This modeling approach has enabled many useful models for both transient and pseudo steady-state flow behavior of vertical, deviated, and horizontal wells with and without fractures.
        </div>
        <div className="content-row"> 
          •<span className='blue'> Assumptions and Limitations:</span> The quality and validity of the identified opportunities depends on several key assumptions listed below.
        </div>
        <div className="content-row"> 
          • The stratigraphic formation tops (loaded from SDX® ) have been interpreted accurately and reflect the true stratigraphic character of different formations/zones. For example, incorrect correlation can lead the algorithm to not recognize a layer pinchout.
        </div>
        <div className="content-row"> 
          • The imported petrophysical logs (water saturation, permeability, net pay) are accurately interpreted. The identification of recompletion opportunities is sensitive to these interpretations. Also, for the benefit of consistency, SRO assumes the imported petrophysical interpretations are consistent with the static model.
        </div>
        <div className="content-row"> 
          • The new drill locations are heavily influenced by the imported 2.5D and 3D geologic models. Therefore, it is important to make sure they contain a reasonable spatial distribution of properties and honor the input log data at the well locations. If in doubt regarding the quality of a model, use the geological model validation workflow available in SRO.
        </div>
        <div className="content-row"> 
          • The production forecast for various opportunities is performed on a zone-by-zone basis. To achieve this, statistical and machine learning methods (TC, STI, NFR, TNNFR, and NN) require the result of production allocation. Therefore, incorrect allocation will almost certainly lead to wrong initial rate forecasts when using statistical methods. It is recommended to use PLT data for allocation when available (advise SDX team).
        </div>
        <div className="content-row"> 
          • The volumetric production analysis is not always suitable for heavily fractured reservoirs since oil flowing to the borehole is not always restricted to local areas around the borehole, but can travel long distances along fracture networks. In these instances, it is recommended to load a SpeedWise® Waterflood Management (SWM®) remaining saturation model to assist the algorithm in determining the location of the remaining oil.
        </div>
        <div className="content-row"> 
          • The quality of the assessment of mechanical feasibility for recompletion opportunities depends on the provided keywords. Serious mechanical issues might not be recognized by the algorithm because a limited list of keywords was provided. Also, when wellbore diagrams are digitized from image data, the algorithm might not be able to recognize certain words. So make sure you manually review the wellbore diagrams after the opportunities have been exported.
        </div>
        <div className="content-row"> 
        • The quality of the assessment of mechanical feasibility for recompletion opportunities depends on the provided keywords. Serious mechanical issues might not be recognized by the algorithm because a limited list of keywords was provided. Also, when wellbore diagrams are digitized from image data, the algorithm might not be able to recognize certain words. So make sure you manually review the wellbore diagrams after the opportunities have been exported.
        </div>
        <div className="content-row"> 
        • The input attributes used for RPOS mapping/modeling are currently equally-weighted and are assumed to influence production performance. It is recommended to perform a quick data analysis study to identify key attributes to include in the RPOS mapping.
        </div>



      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(HorOtherProductionForecast)
