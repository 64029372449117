import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import modelBuilderIcon from '../../../../../static/images/modules/modelBuilder.svg'
import swmOptimizationIcon from '../../../../../static/images/modules/swmOptimization.svg'

import './ModuleOption.scss'



const icons = {
  modelBuilder: modelBuilderIcon,
  swmOptimization: swmOptimizationIcon,
}



const CategoryIcon = (props) => {
  const { category, className, ...restProps } = props
  const Component = icons[category]

  if(Component) {
    return <Component className={className}/>
  }
  return null
}


@autobind class ModuleOption extends Component {

  render() {
    const { name, selected, icon, value, attemptModuleChange, disabled, iconName } = this.props

    return (
      <div 
        className={`module-option ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
        onClick={disabled ? null : () => attemptModuleChange(value)}
      >
        <div className="module-icon-container">
        	<div className="module-icon-background">
        		{icon && (
              <FontAwesomeIcon
                icon={icon}
                className="module-icon"
        		  />
            )}
            {!icon && (
              <CategoryIcon category={iconName} className="autopilot-icon"/>
            )}
        	</div>
        </div>
        <div className="module-name">
        	{name}
        </div>
      </div>
    )
  }
}

export default ModuleOption
