import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import Plot from 'react-plotly.js'

let test = {
  0: 25,
  1: 25,
  2: 50,
  3: 50,
}

let test2 = {
  0: 75,
  1: 75,
  2: 100,
  3: 100,
}




@autobind class WellTimeline extends PureComponent {
  render() {
    const { data } = this.props

    const eventsX = []
    const eventsY = []
    // const eventsText = []
    const annotations = []

    console.log('testestestests', data)

    if (data && data.perforations) {
      const sortedData = data.perforations.sort((a, b) => a.dateOpened.matlab_date - b.dateOpened.matlab_date)
      const perfGroups = {}
      const perfCloseGroups = {}

      sortedData.forEach((perf, index) => {
        if (!perfGroups[perf.dateOpened.date]) {
          perfGroups[perf.dateOpened.date] = [index + 1]
        } else {
          perfGroups[perf.dateOpened.date].push(index + 1)
        }

        if (perf.dateClosed) {
          if (!perfCloseGroups[perf.dateClosed.date]) {
            perfCloseGroups[perf.dateClosed.date] = [index + 1]
          } else {
            perfCloseGroups[perf.dateClosed.date].push(index + 1)
          }  
        }
        
      })



      console.log(perfGroups)





      Object.keys(perfGroups).forEach((perf, index) => {
        annotations.push({
          x: perf,
          y: 0,
          xref: 'x',
          yref: 'y',
          text: `Perforation ${perfGroups[perf].join(', ')} Opened`,
          showarrow: true,
          arrowhead: 7,
          ax: 0,
          ay: ((-1) ** index) * test[index % 4],
        })

        eventsX.push(perf)
        eventsY.push(0)
        // eventsText.push(`Perforation ${perfGroups[perf].join(', ')} Opened`)
      })

      Object.keys(perfCloseGroups).forEach((perf, index) => {
        annotations.push({
          x: perf,
          y: 0,
          xref: 'x',
          yref: 'y',
          text: `Perforation ${perfCloseGroups[perf].join(', ')} Closed`,
          showarrow: true,
          arrowhead: 7,
          ax: 0,
          ay: ((-1) ** index) * test2[index % 4],
        })

        eventsX.push(perf)
        eventsY.push(0)
        // eventsText.push(`Perforation ${perfGroups[perf].join(', ')} Opened`)
      })
    }

    const plotData = [{
      x: eventsX,
      y: eventsY,
      mode: 'markers',
      name: 'Events',
    }]

    const btnsToRemove = ['resetScale2d']

    const layout = {
      // title: 'Event Timeline',
      title: '',
      xaxis: {
        title: 'Time',
        type: 'date',
        autosize: true,
      },
      yaxis: {
        range: [-1, 1],
        showticklabels: false,
      },
      annotations,
      showlegend: false,
      margin: {
        l: 10,
        r: 10,
        b: 40,
        t: 10,
        pad: 0,
      },
    }

    return (
      <Plot
        className="plot"
        data={plotData}
        layout={layout}
        config={{displaylogo: false, showSendToCloud: true, modeBarButtonsToRemove: btnsToRemove}}
        useResizeHandler
      />
    )
  }
}

export default WellTimeline
