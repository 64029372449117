import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'

import Intro from './components/Intro'
import Create from './components/Create'
import Categories from './components/Categories/Categories'
import Import from './components/Import'

@autobind class Datasets extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/training/manual/datasets/intro" component={Intro} />
          <Route path="/training/manual/datasets/create" component={Create} />
          <Route path="/training/manual/datasets/categories" component={Categories} />
          <Route path="/training/manual/datasets/import" component={Import} />
        </Switch>
      </React.Fragment>
    )
  }
}




export default Datasets
