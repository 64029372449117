import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autobind from 'autobind-decorator'

import IconButton from '@material-ui/core/IconButton'
import TuneIcon from '@material-ui/icons/Tune'
import GetAppIcon from '@material-ui/icons/GetApp'
import FullscreenIcon from '@material-ui/icons/Fullscreen'

import GridViewer from '../../../../lib/gridViewer'
import { setViewerBusy } from '../../../../redux/actions/waterfloodCase'
import { getCaseReservoir } from '../../../../services/waterfloodService'

import SettingsDrawer from './SettingsDrawer'
import MUICard from '../../../common/MUICard/MUICard'

@autobind class Viewer4D extends PureComponent {
  constructor(props) {
    super(props)
    this.mount = React.createRef()
    this.renderer = null
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const { timeline, surface, property, showMesh, transparency, selectedWells, caseId } = this.props

    getCaseReservoir(caseId).then(async (reservoir) => {
      this.gV = new GridViewer({
        case: caseId,
        mount: this.mount,
        reservoir,
        timeline,
        surface,
        property,
        displayMesh: showMesh,
        transparency,
        filteredWells: selectedWells.toJS(),
      })

      this.setState({
        loading: false,
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { showPieCharts, showTrajectories, pieSize, showMesh, timeline, property, transparency, verticalScale, selectedWells, surface, rxsetViewerBusy } = this.props

    if (this.gV) {
      if (prevProps.surface !== surface) {
        rxsetViewerBusy(true)
        this.gV.updateSurface(surface).then((status) => {
          rxsetViewerBusy(false)
        })
      }

      if (prevProps.showMesh !== showMesh) {
        this.gV.showMesh(showMesh)
      }

      if (prevProps.showPieCharts !== showPieCharts) {
        this.gV.setPieChart(showPieCharts)
      }

      if (prevProps.showTrajectories !== showTrajectories) {
        this.gV.setTrajectories(showTrajectories)
      }

      if (prevProps.pieSize !== pieSize) {
        this.gV.setPieSize(pieSize)
      }

      if (prevProps.timeline !== timeline) {
        this.gV.updateTimeline(timeline)
      }

      if (prevProps.property !== property) {
        this.gV.updateProperty(property)
      }

      if (prevProps.transparency !== transparency) {
        this.gV.updateTransparency(transparency)
      }

      if (prevProps.verticalScale !== verticalScale) {
        this.gV.updateVScale(verticalScale)
      }

      if (prevProps.selectedWells !== selectedWells) {
        this.gV.filterWells(selectedWells.toJS())
      }
    }
  }


  render() {
    const { showSettings, toggleSettings, caseId } = this.props
    const { loading } = this.state
    return (
      <MUICard
        title="4D Viewer"
        busy={loading}
        action={(
          <>
            <IconButton onClick={() => toggleSettings()} aria-label="settings">
              <TuneIcon />
            </IconButton>
            <IconButton aria-label="fullscreen">
              <FullscreenIcon />
            </IconButton>
            <IconButton aria-label="export">
              <GetAppIcon />
            </IconButton>
          </>
        )}
      >
        { showSettings ? (
          <SettingsDrawer caseId={caseId} />
        ) : null }
        <div style={{ position: 'relative' }} ref={this.mount} />
      </MUICard>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  rxsetViewerBusy: value => dispatch(setViewerBusy(value))
})
    
const mapStateToProps = state => ({
  selectedWells: state.getIn(['waterfloodCase', 'selectedWells']),
  timeline: state.getIn(['waterfloodCase', 'timeline']),
  showPieCharts: state.getIn(['waterfloodCase', 'viewer', 'settings', 'showPieCharts']),
  showTrajectories: state.getIn(['waterfloodCase', 'viewer', 'settings', 'showTrajectories']),

  transparency: state.getIn(['waterfloodCase', 'viewer', 'settings', 'transparency']),
  verticalScale: state.getIn(['waterfloodCase', 'viewer', 'settings', 'verticalScale']),

  pieSize: state.getIn(['waterfloodCase', 'viewer', 'settings', 'pieSize']),
  trimWells: state.getIn(['waterfloodCase', 'viewer', 'settings', 'trimWells']),
  showMesh: state.getIn(['waterfloodCase', 'viewer', 'settings', 'showMesh']),
  property: state.getIn(['waterfloodCase', 'viewer', 'settings', 'property']),
  surface: state.getIn(['waterfloodCase', 'viewer', 'settings', 'surface']),
})
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Viewer4D))
