import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter, Switch, Route } from 'react-router-dom'
import autobind from 'autobind-decorator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import API from '../lib/api-store'
import { logout } from '../redux/actions/user'

import speedwiseLogo from '../../static/images/speedwise-logo.png'
import ProductNavigation from './common/Navigation/ProductNavigation/ProductNavigation'
import ApplicationHeader from './common/Header/ApplicationHeader'
import SecondaryHeader from './common/Header/SecondaryHeader'

@autobind class ProductHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { user } = this.props

    // TODO - hooray for duplicating code, move either header to its own component
    return (
      <Switch>
        <Route exact path="/">
            <SecondaryHeader />
        </Route>
        <Route path="/training">
            <SecondaryHeader />
        </Route>
        <Route path="/licenses">
          <SecondaryHeader />
        </Route>
        <Route exact path="/login">
          <div className="product-header">
            <div className='header-top'>
              <img className="logo" src={speedwiseLogo} alt="" />
              <div className="product-name">
                SpeedWise
                <span className='product-name-registed'>
                  <sup>®</sup>
                </span>
                <span className="product-name-span">
                </span>
              </div>
            </div>
          </div>
        </Route>
        <Route path="/:application">
          <SecondaryHeader />
        </Route>
        <Route path="/">
          <ApplicationHeader />
        </Route>
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  user: state.get('user'),
})

export default withRouter(connect(mapStateToProps, null)(ProductHeader))
