import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import visualizationA from '../../../../../../../../static/images/userManual/visualizationA.png'
import visualizationB from '../../../../../../../../static/images/userManual/visualizationB.png'
import visualizationC from '../../../../../../../../static/images/userManual/visualizationC.png'
import visualizationD from '../../../../../../../../static/images/userManual/visualizationD.png'
import visualizationE from '../../../../../../../../static/images/userManual/visualizationE.png'
import visualizationF from '../../../../../../../../static/images/userManual/visualizationF.png'
import visualizationH1 from '../../../../../../../../static/images/userManual/visualizationH1.png'

@autobind class Visualization extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-title"> 
          Introduction to Visualization
        </div>
        <div className="content-row"> 
          Once a case is successfully executed, the results can be accessed through an interactive browser-based visualization panel. Results can also be shared easily with collaborators on the workspace (usually other asset team members or management). This facilitates a cooperative investigation process to vet the opportunities. Some features that allow this include:
        </div>
        <div className="content-row"> 
          &ensp;• <b>Commenting:</b> Every collaborator can leave comments that can be viewed by all other team members. This allows interdisciplinary discussion and review of opportunities.<br/>
          &ensp;• <b>Tiers:</b> Every opportunity can be categorized into tiers, or priorities for investigation or operations. The visualization panel consists of three main sections (all the maps, tables, and charts are interactive and customizable):
        </div>
        <div className='grayLine'/>
        <div className="content-row"> 
          The visualization panel consists of three main sections (all the maps, tables, and charts are interactive and customizable):
        </div>
        <div className="content-row"> 
          <span className='blue'>1. Executive Summary:</span> Provides an overview of all the opportunities identified including map view highlighting location of all the opportunities, distribution of opportunities by geology/production zone, IP distribution, ranking of opportunities based on production gain, etc. It also includes zone filters and zone property maps for the results summary visualization.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={visualizationA} alt="" />
        </div>
        <div className="image-footnote"> 
          Executive Summary of a case shows the locations of the inventory in a map (top left), the number of opportunities by type and a histogram of the potential of the opportunities (top right), a bar chart of the opportunities potential by well (colored by zone) (bottom left), and a pie chart of the potential of the opportunities by zone (bottom right).
        </div>
        <div className="content-row"> 
          <span className='blue'>2. Opportunity Catalog:</span> Provides detailed information (multiple views) for each identified opportunity to facilitate investigation and vetting. Also, collaborators can make comments on each opportunity on the comment section. At any time during the investigation process, the user can place these opportunities in different tiers (infeasible, high risk, low risk, etc.). Some of the views in the opportunity details consist of:
        </div>
        <div className='image-container'>
          <img className="tooBig" src={visualizationB} alt="" />
        </div>
        <div className="image-footnote"> 
          The Opportunity Inventory list can be sorted by different attributes, tiers can be defined, comments that you or other collaborators have written can be viewed, and you can access the specific visualization pages for each opportunity by clicking that opportunity on this page.
        </div>
        <div className="content-row"> 
          <span className='blue'>A. Summary View:</span> Map view highlighting location of opportunity and many other opportunity related details which are varying between different modules.
        </div>
        <div className="content-row"> 
          &ensp;• Opportunity details: include opportunity location, mapping and structural risk, HCPT, Net Pay and IP. It also displays a table which shows the zone target data.<br/>
          &ensp;• The user options for this section include:
        </div>
        <div className="content-row"> 
          &ensp;&ensp;◦ Map Property: Choose the zones (when applicable) and map attribute e.g., structure, isochore, porosity, etc.<br/>
          &ensp;&ensp;◦ Views: Zoom, pan, etc. on map and well production history chart.<br/>
          &ensp;&ensp;◦ Export Image: Download a plot as a png.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={visualizationC} alt="" />
        </div>
        <div className="image-footnote"> 
          The opportunity’s “Summary” tab includes a map view highlighting location of opportunity (top left), a summary table of attributes (top right), and a production history chart by fluid type (bottom).
        </div>
        <div className="content-row"> 
          <span className='blue'>B. Petrophysical Interpretation:</span> Map views with different properties (isochore, structure, porosity, etc.), well log with perforations and highlighted target interval, table of neighborhood wells with their logs, neighborhood well cross section, etc. User options include:
        </div>
        <div className="content-row"> 
          &ensp;• Map Property: Choose the map attribute e.g., structure, isochore, porosity, etc.<br/>
          &ensp;• Cross Section: Add wells to create a cross section.<br/>
          &ensp;• Logs: Add/remove/change the selected well logs shown.<br/>
          &ensp;• Log Templates: Change well log fill colors, lines, ranges, etc.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={visualizationD} alt="" />
        </div>
        <div className="image-footnote"> 
          The opportunity’s “Petrophysical Characterization” tab includes a map view highlighting location of opportunity (top left), a table of logs in offset wells (top right), and an interactive well log viewer (bottom).
        </div>
        <div className="content-row"> 
          <span className='blue'>C. Production Forecast:</span> Map view highlighting opportunity and neighbor wells, zone IP history, histogram of neighborhood initial or current production, cross plot of IP or current production versus various attributes (e.g., perf length and net pay thickness), and a table of offset wells’ attributes. User options include:<br/>
        </div>
        <div className="content-row"> 
          &ensp;• Map Property: Choose the map attribute e.g., structure, isochore, porosity, etc.<br/>
          &ensp;• Analog Wells Cross Plot: Switch the axis between different well attributes and size the IP using different well attributes.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={visualizationE} alt="" />
        </div>
        <div className="image-footnote"> 
          The opportunity's "Production Forecast" tab includes a map view highlighting location of opportunity (top left), a zone type curve (top right), a histogram of neighborhood initial production (middle left), a cross plot of IP or current production versus various attributes (middle right), and a table of offset wells’ attributes (bottom).
        </div>
        <div className="content-row"> 
          <span className='blue'>D. Model Analysis:</span> Map view, 3D trajectory view, structural section (3D view), model section (3D view), etc. User options include:
        </div>
        <div className="content-row"> 
          &ensp;• Map Property: Choose the map attribute e.g., structure, isochore, porosity, etc.<br/>
          &ensp;• Views: Zoom, pan, etc. on map, 3D model, and cross sections.<br/>
          &ensp;• Export Image: Download a plot as a png.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={visualizationF} alt="" />
        </div>
        <div className="image-footnote"> 
          The opportunity’s “Model Analysis” tab includes a map view (top left), 3D trajectory view (top right), east-west or north-south structural section (middle), and model section (bottom).
        </div>
        <div className="content-row"> 
          <span className='blue'>E. Relative POS:</span> Map view of the RPOS property and all contributing attributes that were included in the construction of the RPOS. For each one of these attributes, the distribution histogram is also displayed.
        </div>
        <div className='image-container'>
          <img className="tooBig" src={visualizationH1} alt="" />
        </div>
        <div className="image-footnote"> 
          The Sweet Spot opportunity’s “Relative POS” tab includes multiple map views of model attributes (left) and corresponding histogram (right).
        </div>
        <div className="content-row"> 
          <span className='blue'>3. Well Catalog:</span> Provides a table of detailed information for each historical well. During the investigation process, the user can access basic information of any specific historical well (well ID, location, IP, EUR, and MD).
        </div>
        <div className="content-row"> 
          <span className='blue'>4. Pinned Opportunities:</span> The users can choose to pin any specific opportunity (either from the interactive maps, interactive bar charts, or the opportunity catalog). The pinned opportunity will remain in the dropdown list on the side panel for easy access.
        </div>
        <div className="content-row"> 
          <span className='blue'>5. Settings:</span> The users can visualize the specific settings used for this case execution.
        </div>
        <div className="content-row"> 
          <span className='blue'>6. Download Results:</span> The users can download the detailed opportunity results as CSV files. The neighborhood data could also be downloaded as CSV files as well. These exports can be easily incorporated to third party software (e.g., Spotfire).
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(['user', '_id']),
})

export default connect(mapStateToProps, null)(Visualization)
