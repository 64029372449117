import React, { PureComponent } from 'react'
import autobind from 'autobind-decorator'
import { NavLink, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import API from '../../../lib/api-store'
import KPI from './KPI'
import './ProjectNavigation.scss'

const baseClass = 'project-navigation'
const projectTitleClass = `${baseClass}__project-title`

const contentClass = `${baseClass}__content`
const leftContentClass = `${contentClass}--left`
const rightContentClass = `${contentClass}--right`

const backButtonClass = `${baseClass}__back-btn`
const backButtonIconClass = `${backButtonClass}__icon`

const styles = {
  root: {
    flexGrow: 1,
    maxWidth: 250,
    minHeight: 0,
  },
  indicator: {
    color: '#646464',
  },
  tab: {
    textTransform: 'none',
    color: '#646464',
    fontSize: '18px',
    marginRight: 50,
    minWidth: 75,
    lineHeight: 1,
    minHeight: 0,
    '&$selected': {
      fontWeight: '700',
    },
  },
  selected: {},
}

@autobind class ProjectNavigation extends PureComponent {
  onClickTab(event, route){
    const { history } = this.props

    event.preventDefault()
    history.push(route)
  }


  render() {
    const { project, license, match, classes } = this.props
    const { projectId, application } = match.params

    console.log('im here boys', project)
    let curStorageGB = (((project.caseS3StorageSize || 0) + (project.processedFileS3StorageSize || 0) + (project.rawFileS3StorageSize || 0)) / 1024 / 1024 / 1024)
    let totalGB = license ? license.dataStorageLimit / 1024 / 1024 / 1024 : 'N/A' 
    return (
      <div className={baseClass}>
        <div className={contentClass}>
          <div className={`${leftContentClass}`}>
            <div>
              <div className={projectTitleClass}>{project && project.name}</div>
              <Tabs
                value={location.pathname}
                onChange={(e, val) => this.onClickTab(e, val)}
                aria-label=""
                indicatorColor="primary"
                variant="fullWidth"
                className={classes.root}
                >
                <Tab className={classes.tab} value={`/${application}/projects/${projectId}/cases`} label="Cases" aria-controls="Cases" />
                <Tab className={classes.tab} value={`/${application}/projects/${projectId}/dataset`} label="Datasets" aria-controls="Datasets" />
              </Tabs>
            </div>
          </div>
          <div className={`${rightContentClass} align-vertical`}>
            <KPI name='GB' cur={curStorageGB} total={totalGB} color='#52AB7F' decimals={2}/>
            <KPI name='Jobs' cur={project.jobsUsed} total={project.jobLimit} color='#FFCB4D' decimals={0}/>
            <KPI name='Job Hours' cur={project.jobHoursUsed} total={project.jobHourLimit} color='#0276C0' decimals={2}/>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(ProjectNavigation))
